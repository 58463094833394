import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
type AlertState = {
  severity: 'success' | 'info' | 'warning' | 'error'
  content: string
}

// Define the initial state using that type
const initialState: AlertState = {
  severity: 'info',
  content: '',
}

export const alertSlice = createSlice({
  name: 'alertSlice',
  initialState,
  reducers: {
    AlertAction: (state, action: PayloadAction<AlertState>) => {
      state.severity = action.payload.severity
      state.content = action.payload.content
    },
  },
})

export const { AlertAction } = alertSlice.actions

export default alertSlice.reducer
