//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid } from '@material-ui/core'
//COMPONENTS
import { TextEditor } from '../../index'
import { FormLayout } from '../index'
//TYPES
import { CatalogFaqType, CatalogType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_CATALOG_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type FaqFormPropsType = {
  catalogFaq?: CatalogFaqType
  catalog: CatalogType
  refetch: () => Promise<any>
  handleClose: () => void
}

const FaqForm = ({ catalogFaq, catalog, refetch, handleClose }: FaqFormPropsType) => {
  const [stateCatalog, _] = useState<CatalogType>(catalog)
  const [stateFaq, setStateFaq] = useState<DeepPartial<CatalogFaqType> | undefined>(catalogFaq)
  const [setAlert] = useAlert()
  const [updateCatalog] = useMutation<{ updateCatalog: CatalogType }>(UPDATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCatalog.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let faq = stateCatalog.faq.map((qa) => {
      return { id: qa.id, question: qa.question, answer: qa.answer }
    })

    if (catalogFaq?.id) {
      faq = faq.filter((qa) => qa.id !== catalogFaq.id)
    }

    const catalogInput = {
      id: stateCatalog.id,
      faq: [...faq, { id: stateFaq?.id, question: stateFaq?.question, answer: stateFaq?.answer }],
    }
    await updateCatalog({ variables: { input: catalogInput } })
  }

  return (
    <FormLayout
      type={stateFaq?.id ? 'update' : 'create'}
      title={stateFaq?.id ? 'Mettre à jour la question/réponse' : 'Créer une question/réponse'}
      onSubmit={handleSubmit}
      submitDisabled={stateFaq?.id ? stateFaq === catalogFaq : stateFaq === undefined}
    >
      <Grid item>
        <TextField
          fullWidth
          id="question"
          label="Question"
          variant="outlined"
          defaultValue={catalogFaq?.question || ''}
          onChange={(event) => {
            setStateFaq((prevState) => {
              return {
                ...prevState,
                [event.target.id]: event.target.value,
              }
            })
          }}
          required
        />
      </Grid>
      <Grid item>
        <TextEditor
          text={catalogFaq?.answer}
          onChange={(json) => {
            setStateFaq((prevState) => {
              return {
                ...prevState,
                answer: json,
              }
            })
          }}
        />
      </Grid>
    </FormLayout>
  )
}

export default FaqForm
