//REACT
import { useState } from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
// TYPE
import { DeepPartial } from '../../../utils/types'
import { CourseType, LessonVideoType } from '../../../../../lib/sharedTypes'
import { useParams } from 'react-router'
//GQL
import { COURSE_LESSONS_VIDEO } from '../../../gql/queries'
import { useQuery } from '@apollo/client'

type SelectetLessonVideoFormPropsType = {
  defaultValue?: DeepPartial<LessonVideoType>
  onSelectLesson: (selectedLesson: LessonVideoType) => void
}

const QuestionVideoForm = ({ defaultValue, onSelectLesson }: SelectetLessonVideoFormPropsType) => {
  const { code_name } = useParams<{ code_name: string }>()
  const { data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_LESSONS_VIDEO, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: 'name',
        sort: 'ASC',
      },
    },
  })

  const [selectedLessonVideo, setSelectedLessonVideo] = useState<LessonVideoType>()

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {course && (
          <Autocomplete
            fullWidth
            defaultValue={defaultValue}
            options={course?.video_lessons}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option?.lesson?.name || ''}
            onChange={(_, value) => {
              setSelectedLessonVideo(value as LessonVideoType)
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" label="Leçon vidéo" />
            }}
          />
        )}
      </Grid>
      <Grid item container justifyContent="center">
        <Button
          disabled={!selectedLessonVideo}
          variant="contained"
          color="primary"
          onClick={() => {
            if (selectedLessonVideo) {
              onSelectLesson(selectedLessonVideo)
            }
          }}
        >
          Enregistrer
        </Button>
      </Grid>
    </Grid>
  )
}

export default QuestionVideoForm
