import { useState, useEffect } from 'react'
// COMPONENTS
import { Layout, Title, TabsMenu, Error, Loading } from '../components'
//TYPES
import { CatalogType } from '../../../lib/sharedTypes'
// MATERIAL UI
import { Grid } from '@material-ui/core'
//REACT-ROUTER
import { useParams, useLocation, Outlet, useNavigate } from 'react-router-dom'
//GQL
import { CATALOG_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'

const tabs = ['informations générales', 'gestion des visuels', 'foire aux questions']

const Catalog = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { catalog_id } = useParams()
  const [activeTab, setActiveTab] = useState<string>(tabs[0])

  const {
    loading,
    error,
    data: { catalog } = {},
  } = useQuery<{ catalog: CatalogType }>(CATALOG_QUERY, {
    variables: {
      where: {
        id: {
          eq: catalog_id && parseInt(catalog_id.toString()),
        },
      },
    },
  })

  useEffect(() => {
    const tab = location.pathname.split('/').at(-1)
    switch (tab) {
      case 'general_informations':
        setActiveTab('informations générales')
        break
      case 'visual_management':
        setActiveTab('gestion des visuels')
        break
      case 'faq':
        setActiveTab('foire aux questions')
        break
      default:
        setActiveTab('informations générales')
        break
    }
  }, [])

  const handleTabChange = (tab: string) => {
    switch (tab) {
      case 'informations générales':
        navigate('general_informations')
        break
      case 'gestion des visuels':
        navigate('visual_management')
        break
      case 'foire aux questions':
        navigate('faq')
        break
      default:
        console.error(`Unknown tab ${activeTab}`)
        break
    }
    setActiveTab(tab)
  }

  if (!catalog_id) {
    return (
      <Layout title="Catalogue">
        <Error error="catalog_id inconnu" />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Catalogue">
        <Error error={error} />
      </Layout>
    )
  }

  if (loading || !catalog) {
    return (
      <Layout title="Catalogue">
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout title={catalog.name}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Title title={catalog.name} />
            </Grid>
          </Grid>
          <TabsMenu allowedTabs={tabs} tabs={tabs} activeTab={activeTab} setActiveTab={handleTabChange} />
        </Grid>
        <Outlet />
      </Grid>
    </Layout>
  )
}

export default Catalog
