import { useEffect, useState } from 'react'
// COMPONENTS
import { Layout, Title, TabsMenu, Loading, Error } from '../components'
// MATERIAL UI
import { Grid, Button, useTheme } from '@material-ui/core'
// TYPE
import { ProjectType } from '../../../lib/sharedTypes'
// GQL
import { PROJECT_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'
// REACT-ROUTER
import { useParams, useLocation, Outlet, useNavigate } from 'react-router-dom'
//REDUX
import { useAppSelector } from '../hooks/reducerHooks'

const tabs = ['informations générales', 'Sets', 'Vidéos', 'Rushes', 'Documents', 'Equipe et tournage', 'Schémas']

const Project = () => {
  const user = useAppSelector((state) => state.user.user)
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const { code_name } = useParams()
  const [activeTab, setActiveTab] = useState(tabs[0])
  const {
    loading,
    error,
    data: { project } = {},
  } = useQuery<{
    project: ProjectType
  }>(PROJECT_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  useEffect(() => {
    const tab = location.pathname.split('/').at(-1)
    switch (tab) {
      case 'general_informations':
        setActiveTab('informations générales')
        break
      case 'sets':
        setActiveTab('Sets')
        break
      case 'videos':
        setActiveTab('Vidéos')
        break
      case 'rushes':
        setActiveTab('Rushes')
        break
      case 'documents':
        setActiveTab('Documents')
        break
      case 'team_shootings':
        setActiveTab('Equipe et tournage')
        break
      case 'schemas':
        setActiveTab('Schémas')
        break
      default:
        setActiveTab('informations générales')
        break
    }
  }, [])

  const handleTabChange = (tab: string) => {
    switch (tab) {
      case 'informations générales':
        navigate('general_informations')
        break
      case 'Sets':
        navigate('sets')
        break
      case 'Vidéos':
        navigate('videos')
        break
      case 'Rushes':
        navigate('rushes')
        break
      case 'Documents':
        navigate('documents')
        break
      case 'Equipe et tournage':
        navigate('team_shootings')
        break
      case 'Schémas':
        navigate('schemas')
        break
      default:
        console.error(`Unknown tab ${tab}`)
        break
    }
    setActiveTab(tab)
  }

  if (loading || !project) {
    return (
      <Layout title={code_name || 'Projet'}>
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title={code_name || 'Projet'}>
        <Error error={error} />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Projet">
        <Error error={'Unknown code_name'} />
      </Layout>
    )
  }

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={code_name} />
          </Grid>
          <Grid item>
            <Button
              component="a"
              variant="outlined"
              color="primary"
              onClick={(e: React.MouseEvent) => {
                if (e.metaKey || e.ctrlKey) {
                  window.open(`/formations/${project.course.code_name}`, '_blank')
                } else {
                  navigate(`/formations/${project.course.code_name}`)
                }
              }}
              style={{ marginRight: theme.spacing(1) }}
            >
              Formation
            </Button>
            <Button
              component="a"
              variant="outlined"
              color="primary"
              onClick={(e: React.MouseEvent) => {
                if (e.metaKey || e.ctrlKey) {
                  window.open(`/products/${project.course.product.code_name}`, '_blank')
                } else {
                  navigate(`/products/${project.course.product.code_name}`)
                }
              }}
            >
              Produit
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TabsMenu
            allowedTabs={
              user?.roles.map((r) => r.category as string).includes('FINANCE')
                ? ['informations générales', 'Equipe et tournage']
                : tabs
            }
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            indicatorColor="primary"
            scrollButtons="on"
            variant="scrollable"
          />
        </Grid>
        <Grid item style={{ display: 'flex', flexGrow: 1, width: '100%' }}>
          <Outlet />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Project
