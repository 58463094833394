import React from 'react'
// COMPONENTS
import { TreeItem } from './index'
//MATERIAL UI
import { Grid } from '@material-ui/core'
//TYPES
import { CoursePartType } from '../../../../lib/sharedTypes'

type TreePropsType = {
  code_name: string
  parts: CoursePartType[]
  parent: null | CoursePartType
  movePart: (part: CoursePartType, target: CoursePartType) => void
  switchPart: (part: CoursePartType, target: CoursePartType, dragIndex: number, hoverIndex: number) => void
  addPart: (parent: CoursePartType | null, partName: string) => void
  removePart: (part: CoursePartType) => void
  expandAll: boolean
  refetch: () => Promise<any>
  canDrag: boolean
}

const Tree: React.FC<TreePropsType> = ({
  code_name,
  parts,
  parent,
  movePart,
  switchPart,
  addPart,
  removePart,
  expandAll,
  refetch,
  canDrag,
}: TreePropsType) => {
  return (
    <Grid item xs={12} container spacing={1}>
      {parts.map((part, index) => {
        return (
          <TreeItem
            canDrag={canDrag}
            refetch={refetch}
            code_name={code_name}
            key={part.id}
            index={index}
            parent={parent}
            part={part}
            parts={parts}
            movePart={movePart}
            switchPart={switchPart}
            removePart={removePart}
            addPart={addPart}
            expandAll={expandAll}
          />
        )
      })}
    </Grid>
  )
}

export default Tree
