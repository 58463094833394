import { useState } from 'react'
import { Grid, TextField, Button, useTheme, FormControlLabel, Switch } from '@material-ui/core'
import { TeachingMethodType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from 'src/utils/types'
//GQL
import { useMutation } from '@apollo/client'
import { CREATE_TEACHING_METHOD_MUTATION, UPDATE_TEACHING_METHOD_MUTATION } from '../../../gql/mutations'
//UTILS
import { handleError } from '../../../utils/handleError'
//HOOKS
import { useAlert } from '../../../hooks'

type TeachingMethodFormPropsType = {
  handleClose: () => void
  refetch: () => Promise<any>
  selectedTeachingMethod?: TeachingMethodType
}

const TeachingMethodForm = ({ handleClose, refetch, selectedTeachingMethod }: TeachingMethodFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [teachingMethod, setTeachingMethod] =
    useState<DeepPartial<TeachingMethodType | undefined>>(selectedTeachingMethod)
  const [createTeachingMethod] = useMutation<{
    createTeachingMethod: TeachingMethodType
  }>(CREATE_TEACHING_METHOD_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createTeachingMethod.name} créé` })
      await refetch()
      handleClose()
    },
  })

  const [updateTeachingMethod] = useMutation<{
    updateTeachingMethod: TeachingMethodType
  }>(UPDATE_TEACHING_METHOD_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateTeachingMethod.name} mis à jour` })
      await refetch()
      handleClose()
    },
  })

  return (
    <Grid
      item
      container
      justifyContent="space-around"
      alignItems="center"
      style={{ height: '100%', width: '100%', zIndex: 1000 }}
      spacing={1}
    >
      <Grid style={{ width: '100%' }} container spacing={2}>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="name"
            label="Nom"
            variant="outlined"
            defaultValue={teachingMethod?.name || ''}
            onChange={(event) => {
              setTeachingMethod((prevState) => {
                return {
                  ...prevState,
                  name: event.target.value,
                }
              })
            }}
            required
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            type="number"
            id="position"
            label="Position"
            variant="outlined"
            defaultValue={teachingMethod?.position || 0}
            onChange={(event) => {
              setTeachingMethod((prevState) => {
                return {
                  ...prevState,
                  position: parseInt(event.target.value),
                }
              })
            }}
          />
        </Grid>

        <Grid item style={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Switch
                onClick={() => {
                  setTeachingMethod((prevState) => {
                    return {
                      ...prevState,
                      is_default: !prevState?.is_default,
                    }
                  })
                }}
                checked={teachingMethod?.is_default ? true : false}
              />
            }
            label={teachingMethod?.is_default ? 'Fixe' : 'Variable'}
          />
        </Grid>

        <Grid style={{ width: '100%' }} container justifyContent="center" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                if (teachingMethod?.id) await updateTeachingMethod({ variables: { input: teachingMethod } })
                else await createTeachingMethod({ variables: { input: teachingMethod } })
              }}
            >
              Enregistrer
            </Button>
          </Grid>
          <Grid item style={{ paddingBottom: theme.spacing(1) }}>
            <Button
              variant="outlined"
              style={{ color: theme.palette.error.main, border: `1px solid ${theme.palette.error.main}` }}
              onClick={handleClose}
            >
              Annuler
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TeachingMethodForm
