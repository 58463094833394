// MATERIAL UI
import { Grid, Typography, Divider, Button, useTheme, GridSize, CircularProgress, Box } from '@material-ui/core'

type FormLayoutPropsType = {
  type: 'create' | 'update'
  title: string
  children: React.ReactNode
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  submitDisabled: boolean
  md?: GridSize
  progress?: number
  loading?: boolean
}

const FormLayout = ({
  children,
  title,
  onSubmit,
  submitDisabled,
  type,
  md,
  progress,
  loading,
}: FormLayoutPropsType) => {
  const theme = useTheme()

  return (
    <Grid
      component="form"
      container
      justifyContent="center"
      alignItems="flex-start"
      onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        e.stopPropagation()
        await onSubmit(e)
      }}
      style={{
        backgroundColor: theme.palette.background.default,
        flexGrow: 1,
        padding: theme.spacing(2),
      }}
    >
      <Grid item container direction="column" xs={12} md={md || 8} spacing={2} style={{ minHeight: '100%' }}>
        <Grid item style={{ flexGrow: 0 }}>
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} container direction="column" spacing={2}>
          {children}
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" style={{ flexGrow: 0 }}>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitDisabled || (progress !== undefined && progress !== 0)}
              style={{ width: '100%' }}
            >
              {loading ? (
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="indeterminate"
                    size="1.75rem"
                    id="upload_loading"
                    style={{ color: 'white' }}
                  />
                </Box>
              ) : progress !== undefined && progress !== 0 ? (
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="determinate"
                    size="1.75rem"
                    id="upload_progress"
                    value={progress}
                    style={{ color: 'white' }}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography style={{ fontSize: 8 }} color="textSecondary">{`${Math.round(
                      progress || 0,
                    )}%`}</Typography>
                  </Box>
                </Box>
              ) : type === 'create' ? (
                'Enregistrer'
              ) : (
                'Mettre à jour'
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormLayout
