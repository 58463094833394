import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import type { TokensType } from '../../../../lib/sharedTypes'

// Define a type for the slice state
type authState = {
  connected: boolean
  isLoggedIn: boolean
  accessToken: string
  refreshToken: string
}

// Define the initial state using that type
const initialState: authState = {
  connected: true,
  isLoggedIn: false,
  accessToken: '',
  refreshToken: '',
}

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    logInAction: (state, action: PayloadAction<authState>) => {
      state.isLoggedIn = action.payload.isLoggedIn
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
    setConnected: (state, action: PayloadAction<{ status: boolean }>) => {
      state.connected = action.payload.status
    },
    logOutAction: () => initialState,
    refreshTokensAction: (state, action: PayloadAction<TokensType>) => {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
  },
})

export const { logInAction, logOutAction, refreshTokensAction, setConnected } = authSlice.actions

export default authSlice.reducer
