import { Grid, TableRow, TableCell, makeStyles } from '@material-ui/core'
import { getWeekNumber, getMondays } from '../../utils/calendar'

type WeeksPropsType = {
  startDate: Date
  endDate: Date
}

const useStyles = makeStyles((theme) => ({
  firstCol: {
    left: 0,
    background: theme.palette.background.default,
    zIndex: 1000,
    position: 'sticky',
    padding: theme.spacing(1),
    minWidth: 250,
    maxWidth: 250,
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRight: `2px solid ${theme.palette.action.disabled}`,
    borderBottom: `2px solid ${theme.palette.action.disabled}`,
  },
  column: {
    minWidth: 150,
    maxWidth: 150,
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRight: `2px solid ${theme.palette.action.disabled}`,
    borderBottom: `2px solid ${theme.palette.action.disabled}`,
  },
  currentWeek: {
    minWidth: 150,
    maxWidth: 150,
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  dayBox: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 42,
    width: 28,
  },
}))

const Weeks = ({ startDate, endDate }: WeeksPropsType) => {
  const classes = useStyles()

  const currentWeekNumber = getWeekNumber(new Date())
  return (
    <TableRow>
      <TableCell className={classes.firstCol}>
        <Grid container direction="column">
          <Grid item>Semaine</Grid>
          <Grid>Lundi</Grid>
        </Grid>
      </TableCell>
      {getMondays(startDate, endDate).map((monday) => {
        const weekNumber = getWeekNumber(monday)
        return (
          <TableCell
            className={currentWeekNumber === weekNumber ? classes.currentWeek : classes.column}
            key={`${monday.getTime()}-${Math.random()}`}
          >
            <Grid container direction="column">
              <Grid item> S{getWeekNumber(monday)}</Grid>
              <Grid item>{monday.toLocaleString('fr-FR', { dateStyle: 'short' })}</Grid>
            </Grid>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default Weeks
