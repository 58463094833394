// REACT HOOKS
import { useState } from 'react'
//ICONS
import { MdOutlineCheckCircleOutline, MdHighlightOff, MdMoreVert } from 'react-icons/md'
// MATERIAL UI
import { Grid, useTheme, Menu, MenuItem, IconButton } from '@material-ui/core'
// COMPONENTS
import { DeleteForm } from '../../../components/Forms'
import { Header } from '../../../components/Lesson'
import { CustomTable, Error, Loading, SearchBar, Note, CustomModal } from '../../../components'
// TYPES
import { CourseType, LessonType, SortEnum } from '../../../../../lib/sharedTypes'
import { ColumnType } from '../../../utils/types'
// GQL
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { COURSE_LESSON_ALL_QUERY, COURSE_LESSONS_ALL_SEARCH } from '../../../gql/queries'
import { DELETE_LESSON_MUTATION } from '../../../gql/mutations'
// REACT-ROUTER
import { useNavigate, useParams } from 'react-router'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../../utils/misc'

const LessonAll = () => {
  const theme = useTheme()
  const { code_name } = useParams()
  const navigate = useNavigate()
  const [field, setField] = useState<keyof LessonType>('name')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [take, setTake] = useState<number>(10)
  const [skip, setSkip] = useState<number>(0)
  const [searching, setSearching] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [setAlert] = useAlert()
  const menuOpen = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<LessonType | undefined>(undefined)

  const [deleteLessonMutation] = useMutation<{
    deleteLesson: boolean
  }>(DELETE_LESSON_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetch()
      setAlert({ severity: 'success', content: `Leçon supprimée` })
    },
  })

  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{
    course: CourseType
  }>(COURSE_LESSON_ALL_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
  })

  const [searchLessonQuery, { data: { course: courseSearch = null } = {} }] = useLazyQuery<{ course: CourseType }>(
    COURSE_LESSONS_ALL_SEARCH,
  )

  const searchLessons = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchLessonQuery({
      variables: {
        where: {
          code_name: {
            eq: code_name,
          },
        },
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
      },
    })
  }

  const getLessonType = (lesson: LessonType) => {
    if (lesson.video) return 'video'
    if (lesson.epp) return 'epp'
    if (lesson.document) return 'document'
    if (lesson.quiz) return 'quiz'
    if (lesson.text && lesson.text.type === 'LESSON') return 'text'
    if (lesson.text && lesson.text.type === 'SYNTHESIS') return 'synthèse'
    if (lesson.html) return 'html'
    if (lesson.task) return 'task'
    if (lesson.dactylo) return 'dactylo'
    return 'inconnu'
  }

  const onRowClick = (row: LessonType) => {
    const lessonType = getLessonType(row)
    switch (lessonType) {
      case 'document':
        navigate(`document/${row.id}`)
        break
      case 'video':
        navigate(`video/${row.id}`)
        break
      case 'quiz':
        navigate(`quiz/${row.id}`)
        break
      case 'text':
        navigate(`text/${row.id}`)
        break
      case 'synthèse':
        navigate(`synthese/${row.id}`)
        break
      case 'epp':
        navigate(`epp/${row.id}`)
        break
      case 'task':
        navigate(`task/${row.id}`)
        break
      case 'dactylo':
        navigate(`dactylo/${row.id}`)
        break
      default:
        console.error('Unknow lessonType')
        break
    }
  }

  const columns: ColumnType<LessonType>[] = [
    {
      title: 'Nom de la leçon',
      field: 'name',
      render: (row: LessonType) => row.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Validée',
      field: 'validated',
      render: (row: LessonType) =>
        row.validated ? (
          <MdOutlineCheckCircleOutline style={{ color: theme.palette.success.main }} size="22" />
        ) : (
          <MdHighlightOff style={{ color: theme.palette.error.main }} size="22" />
        ),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Type',
      field: 'type',
      render: (row: LessonType) => getLessonType(row),
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Notes',
      field: 'lesson.note',
      render: (row: LessonType) => <Note lesson={row} refetch={refetch} />,
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: LessonType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: LessonType) => row.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: LessonType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (!code_name) {
    return <Error error={'code_name inconnu'} />
  }

  if (error) {
    return <Error error={error} />
  }

  if (!course || loading) {
    return <Loading />
  }

  return (
    <Grid item container direction="column" style={{ width: '100%' }} spacing={1}>
      <Header refetch={refetch} />
      <Grid item style={{ width: '100%' }}>
        <SearchBar placeholder="Rechercher une leçon" searchQuery={searchLessons} />
      </Grid>
      <Grid style={{ width: '100%' }}>
        <CustomTable<LessonType>
          columns={columns}
          rows={searching && courseSearch ? courseSearch.lessons_search : course.lessons}
          field={field}
          sort={sort}
          take={take}
          skip={skip}
          setTake={setTake}
          setSkip={setSkip}
          setSort={setSort}
          setField={setField}
          rowsCount={searching ? courseSearch?.lessons_search.length : course.lessons_count}
          onRowClick={onRowClick}
        />
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la leçon
        </MenuItem>
      </Menu>
      <CustomModal
        size="xs"
        title="Supprimer la leçon"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteLessonMutation({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default LessonAll
