import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
import { Error } from '../../index'
// TYPE
import { ProductType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type SEOFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const SEOForm = ({ product, refetch, handleClose }: SEOFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)

  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStateProduct((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: stateProduct.id,
      seo_title: stateProduct.seo_title,
      seo_url: stateProduct.seo_url,
      canonical_url: stateProduct.canonical_url,
      seo_meta_description: stateProduct.seo_meta_description,
      breadcrumb_title: stateProduct.breadcrumb_title,
    }
    await updateProductMutation({ variables: { input: productInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les informations SEO"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProduct) === JSON.stringify(product)}
    >
      <Grid item>
        <TextField
          label="Titre SEO"
          name="seo_title"
          value={stateProduct.seo_title}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          label="URL"
          name="seo_url"
          value={stateProduct.seo_url}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          label="URL canonique"
          name="canonical_url"
          value={stateProduct.canonical_url || ''}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          label="Metadescription"
          InputLabelProps={{ shrink: true }}
          name="seo_meta_description"
          value={stateProduct.seo_meta_description}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          label="Breadcrumb title"
          InputLabelProps={{ shrink: true }}
          name="breadcrumb_title"
          value={stateProduct.breadcrumb_title}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Grid>
    </FormLayout>
  )
}

export default SEOForm
