import React, { useEffect } from 'react'
//COMPONENTS
import { Layout, Loading, Error } from '../components'
import { Projects, Courses, GradientCard, UpcomingShootings } from '../components/Dashboard'
// MATERIAL UI
import { Typography, Grid } from '@material-ui/core'
//GQL
import { DASHBOARD_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'
//TYPES
import type { ProductType, UserType } from '../../../lib/sharedTypes'
//REDUX
import { useAppDispatch, useAppSelector } from '../hooks/reducerHooks'
import { setUserAction } from '../store/reducers/userReducer'
// REACT-ROUTER
import { useNavigate } from 'react-router'

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user.user)
  const {
    loading,
    error,
    data: { me, products } = {},
  } = useQuery<{
    me: UserType
    products: ProductType[]
  }>(DASHBOARD_QUERY, {
    variables: {
      where: {
        job: {
          category: {
            eq: 'DIRECTOR',
          },
        },
      },
      take: 10,
      order: {
        sort: 'DESC',
        field: 'created_at',
      },
    },
  })

  useEffect(() => {
    if (me) {
      dispatch(setUserAction(me))
    }
  }, [error, me, dispatch])

  if (loading) {
    return (
      <Layout title="Accueil">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Accueil">
        <Error error={error} />
      </Layout>
    )
  }

  const userRoles = user?.roles.map((role) => role.category.toString())

  const showProjects =
    userRoles?.includes('PROJECT') ||
    userRoles?.includes('ADMIN') ||
    userRoles?.includes('DIRECTOR') ||
    userRoles?.includes('EDITOR')

  const showCourses = userRoles?.includes('PROJECT') || userRoles?.includes('ADMIN')

  return (
    <Layout title="Accueil">
      <Grid container direction="column" spacing={2}>
        {showProjects && (
          <Grid item style={{ width: '100%' }}>
            <Projects />
          </Grid>
        )}
        {showCourses && (
          <Grid item style={{ width: '100%' }}>
            <Courses />
          </Grid>
        )}
        <Grid item style={{ width: '100%' }}>
          <UpcomingShootings />
        </Grid>
        <Grid item>
          <Typography variant="h5">Ça vient de sortir ...</Typography>
        </Grid>
        <Grid item container direction="row" wrap={'nowrap'} spacing={2} style={{ overflow: 'auto' }}>
          {products &&
            products.map((product) => (
              <Grid
                item
                key={product.id}
                style={{
                  minHeight: 150,
                  minWidth: 150,
                  maxHeight: 150,
                  maxWidth: 150,
                }}
              >
                <GradientCard
                  s3_logo={product.s3_logo}
                  s3_banner={product.s3_banner}
                  color={product.color}
                  onClick={(e: React.MouseEvent) => {
                    if (e.metaKey || e.ctrlKey) {
                      window.open(`/products/${product.code_name}`, '_blank')
                    } else {
                      navigate(`/products/${product.code_name}`)
                    }
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Dashboard
