//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid } from '@material-ui/core'
//COMPONENTS
import { Error } from '../../index'
import { FormLayout } from '../index'
//TYPES
import { ProjectDocumentType, ProjectType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { PROJECT_ID_QUERY } from '../../../gql/queries'
import { CREATE_PROJECT_DOCUMENT_MUTATION, UPDATE_PROJECT_DOCUMENT_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { useParams } from 'react-router'

type DocumentFormPropsType = {
  document?: ProjectDocumentType
  refetch: () => Promise<any>
  handleClose: () => void
}

const DocumentForm = ({ document, refetch, handleClose }: DocumentFormPropsType) => {
  const { code_name } = useParams()
  const [stateDocument, setStateDocument] = useState<DeepPartial<ProjectDocumentType> | undefined>(document)
  const [setAlert] = useAlert()
  const [createDocument] = useMutation<{
    createProjectDocument: ProjectDocumentType
  }>(CREATE_PROJECT_DOCUMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createProjectDocument.name} créé` })
      await refetch()
      handleClose()
    },
  })

  const [updateDocument] = useMutation<{
    updateProjectDocument: ProjectDocumentType
  }>(UPDATE_PROJECT_DOCUMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProjectDocument.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const { error, data: { project } = {} } = useQuery<{ project: ProjectType }>(PROJECT_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const projectDocumentInput = {
      id: stateDocument?.id,
      name: stateDocument?.name,
      s3_file: stateDocument?.s3_file,
      project: { id: project?.id },
    }
    if (stateDocument?.id) {
      await updateDocument({
        variables: {
          input: projectDocumentInput,
        },
      })
    } else {
      await createDocument({ variables: { input: projectDocumentInput } })
    }
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <FormLayout
      type={stateDocument?.id ? 'update' : 'create'}
      title={stateDocument?.id ? 'Mettre à jour le document' : 'Créer un document'}
      onSubmit={handleSubmit}
      submitDisabled={stateDocument?.id ? document === stateDocument : stateDocument === undefined}
    >
      <Grid item>
        <TextField
          fullWidth
          id="name"
          label="Nom"
          variant="outlined"
          defaultValue={stateDocument?.name}
          onChange={(event) => {
            setStateDocument((prevState) => {
              return {
                ...prevState,
                [event.target.id]: event.target.value,
              }
            })
          }}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id="s3_file"
          label="URL"
          variant="outlined"
          defaultValue={stateDocument?.s3_file}
          onChange={(event) => {
            setStateDocument((prevState) => {
              return {
                ...prevState,
                [event.target.id]: event.target.value,
              }
            })
          }}
          required
        />
      </Grid>
    </FormLayout>
  )
}

export default DocumentForm
