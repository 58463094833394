import React, { useState } from 'react'
//ASSETS
import NOIMG_card from '../assets/NOIMG_card.jpg'
//COMPONENTS
import UserAvatar from './UserAvatar'
//MATERIAL UI
import {
  Grid,
  Table,
  TableBody,
  TableSortLabel,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
//UTILS
import { titelizeWord } from '../utils/misc'
import { ColumnType } from '../utils/types'
import { UserType, RoleType } from '../../../lib/sharedTypes'

const useStyles = makeStyles((theme) => ({
  rootTable: {
    // width: `calc(100vw  - ${theme.spacing(4)}px)`,
    // overflowX: 'scroll',
  },
  headerCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  directorsCell: {
    display: 'flex',
  },
  directorItem: {
    '&:not(:first-child)': {
      marginLeft: '-15px',
    },
  },
  rowHover: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[700],
    },
  },
  row: {
    '&:hover': {
      background: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[700],
    },
  },
  rowImage: {
    width: '60px',
    height: '60px',
    borderRadius: '4px',
    objectFit: 'cover',
  },
  filmingName: {
    textTransform: 'uppercase',
  },
  sortLabelIcon: {
    '& .MuiTableSortLabel-icon': {
      opacity: '0.2',
    },
  },
  sortLabelIconActive: {
    '& .MuiTableSortLabel-icon': {
      color: 'unset !important',
      opacity: '1 !important',
    },
  },
}))

type CustomTableProps<T> = {
  columns: ColumnType<T>[]
  rows: T[]
  sort?: any
  setSort?: (arg: any) => void
  field?: keyof T | string
  setField?: (arg: keyof T) => void
  take?: number
  setTake?: (arg: number) => void
  rowsCount?: number
  skip?: number
  setSkip?: (arg: number) => void
  onRowClick?: (arg: any, event: React.MouseEvent) => void
  header?: boolean
  size?: 'medium' | 'small'
}

export default function CustomTable<T extends Record<string, any>>({
  columns,
  rows,
  sort,
  setSort,
  field,
  setField,
  take = 10,
  setTake,
  skip = 0,
  setSkip,
  rowsCount = 0,
  onRowClick,
  header = true,
  size = 'medium',
}: CustomTableProps<T>) {
  const theme = useTheme()
  const classes = useStyles()
  const [rowsPerPage, setRowsPerPage] = useState<number>(take)

  const displayCell = (column: ColumnType<T>, row: T) => {
    const field = column.field
    switch (column.type) {
      case 'String':
        return (
          <TableCell style={{ color: 'inherit' }} key={field.toString()}>
            {column.render(row) as string}
          </TableCell>
        )
      case 'Date':
        return (
          <TableCell style={{ color: 'inherit' }} key={field.toString()}>
            {!column.render(row)
              ? '-'
              : new Date(column.render(row) as string).toLocaleString('fr-FR', { dateStyle: 'short' })}
          </TableCell>
        )
      case 'Image':
        return (
          <TableCell style={{ color: 'inherit' }} key={field.toString()}>
            {column.render(row) ? (
              <img
                className={classes.rowImage}
                src={column.render(row) as string}
                alt={field.toString()}
                onError={({ currentTarget }) => {
                  currentTarget.src = NOIMG_card
                }}
                loading="lazy"
              />
            ) : (
              <img
                className={classes.rowImage}
                src={NOIMG_card}
                alt={field.toString()}
                onError={({ currentTarget }) => {
                  currentTarget.src = NOIMG_card
                }}
                loading="lazy"
              />
            )}
          </TableCell>
        )
      case 'Avatars':
        return (
          <TableCell style={{ color: 'inherit' }} key={field.toString()}>
            <Grid container>
              {Object.values(column.render(row) as UserType[])
                .slice(0, 3)
                .map((director, index: number) => (
                  <div key={index} className={classes.directorItem} style={{ zIndex: 3 - index }}>
                    <UserAvatar user={director} key={director.id} />
                  </div>
                ))}
            </Grid>
          </TableCell>
        )
      case 'RoleName':
        return (
          <TableCell style={{ color: 'inherit' }} key={field.toString()}>
            {Object.values(column.render(row) as RoleType[])
              .slice(0, 3)
              .map((role, index: number) => {
                return <Typography key={index}>{titelizeWord(role.category)}</Typography>
              })}
          </TableCell>
        )
      case 'DateRange': {
        const { start, end } = column.render(row) as { start: string; end: string }
        const startDate = new Date(start).toLocaleString('fr-FR', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        const endDate = new Date(end).toLocaleString('fr-FR', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        return (
          <TableCell style={{ color: 'inherit' }} key={field.toString()}>
            {`${startDate} au ${endDate}`}
          </TableCell>
        )
      }
      case 'CustomRender':
        return (
          <TableCell style={{ color: 'inherit' }} key={field.toString()}>
            {column.render(row)}
          </TableCell>
        )
      default:
        return (
          <TableCell style={{ color: 'inherit' }} key={field.toString()}>
            Unknow ColumnType
          </TableCell>
        )
    }
  }

  return (
    <Grid item className={classes.rootTable} id="tableContainer">
      <TableContainer component={Paper}>
        <Table size={size}>
          {header && (
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  return (
                    <TableCell key={`${column.title}${index}`} className={classes.headerCell}>
                      {column.title}
                      {column.sortable && (
                        <TableSortLabel
                          className={field === column.field ? classes.sortLabelIconActive : classes.sortLabelIcon}
                          key={column.title}
                          active={field === column.field}
                          direction={field === column.field ? (sort === 'ASC' ? 'asc' : 'desc') : 'asc'}
                          onClick={() => {
                            setField && setField(column.field)
                            // By default the first sorting of a column is in an ascending order
                            setSort &&
                              (field !== column.field
                                ? setSort('ASC')
                                : sort === 'ASC'
                                ? setSort('DESC')
                                : setSort('ASC'))
                          }}
                        ></TableSortLabel>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  style={{ height: '250px', color: theme.palette.text.disabled }}
                >
                  <Grid container direction="column" justifyContent="center">
                    <Grid item>Introuvable</Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
            {rows.map((row) => (
              <TableRow
                key={row.id as string}
                className={onRowClick ? classes.rowHover : classes.row}
                onClick={(e) => {
                  onRowClick && onRowClick(row, e)
                }}
              >
                {columns.map((column) => {
                  return displayCell(column, row)
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {rowsCount !== 0 && setSkip && setTake && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={rowsCount === 0 ? 0 : Math.floor(skip / rowsPerPage)}
            onPageChange={(_event, newPage) => {
              setSkip(newPage * take)
            }}
            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setRowsPerPage(parseInt(event.target.value, 10))
              setTake(parseInt(event.target.value, 10))
            }}
          />
        )}
      </TableContainer>
    </Grid>
  )
}
