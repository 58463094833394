import { useEffect, useState } from 'react'
//MATERIAL UI
import { Grid, TextField, Button } from '@material-ui/core'
import { CoursePartType } from '../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_COURSE_PART_MUTATION } from '../../gql/mutations'
//UTILS
import { handleError } from '../../utils/handleError'
//HOOKS
import { useAlert } from '../../hooks'
import DurationInput from '../DurationInput'

type AddPartModalPropsType = {
  type: 'part' | 'chapter' | 'lesson'
  part: CoursePartType
  setIsEditPartModalOpen: (x: boolean) => void
  refetch: () => Promise<any>
}

const AddPartModal = ({ type, part, refetch, setIsEditPartModalOpen }: AddPartModalPropsType) => {
  const [statePart, setStatePart] = useState<CoursePartType>(part)
  const [label, setLabel] = useState<string>('')
  const [setAlert] = useAlert()

  const [updateCoursePart] = useMutation<{
    UPDATE_COURSE_PART_MUTATION: CoursePartType
  }>(UPDATE_COURSE_PART_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `${label} mis-à-jour` })
      await refetch()
    },
  })

  useEffect(() => {
    switch (type) {
      case 'part':
        setLabel('Nom de la partie')
        break
      case 'chapter':
        setLabel('Nom du chapitre')
        break
      case 'lesson':
        setLabel('Nom de la leçon')
        break
      default:
        console.error('Unknow part type')
        break
    }
  })

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <TextField
          value={statePart?.name}
          fullWidth
          variant="outlined"
          label={label}
          placeholder={label}
          onChange={(e) => {
            setStatePart((prevState) => {
              return {
                ...prevState,
                name: e.target.value,
              }
            })
          }}
        />
      </Grid>
      {type === 'part' && (
        <Grid item>
          <DurationInput
            format="hh:mm"
            // required={true}
            label="Durée"
            defaultValue={statePart.duration}
            onChange={(duration) => {
              setStatePart((prevState) => {
                return {
                  ...prevState,
                  duration: duration,
                }
              })
            }}
          />
        </Grid>
      )}

      <Grid item container justifyContent="center">
        <Button
          disabled={!statePart.name}
          variant="contained"
          color="primary"
          onClick={async () => {
            await updateCoursePart({
              variables: { input: { id: statePart.id, name: statePart.name, duration: statePart.duration } },
            })
            setIsEditPartModalOpen(false)
          }}
        >
          Modifier
        </Button>
      </Grid>
    </Grid>
  )
}

export default AddPartModal
