export const getWeekNumber = (date: Date): number => {
  // const firstJanuary = new Date(date.getFullYear(), 0, 1)
  // const weekNo = Math.ceil(((date.getTime() - firstJanuary.getTime()) / 86400000 + 1) / 7)
  // return weekNo
  // Copy date so don't modify original
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
  // Get first day of year
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)
  // Return array of year and week number
  return weekNo
}

export const getMondays = (startDate: Date, endDate: Date): Date[] => {
  const mondays = []
  let current = new Date(startDate.toISOString())
  while (current < endDate) {
    mondays.push(new Date(current))
    current = new Date(current.setDate(current.getDate() + 7))
  }
  return mondays
}
