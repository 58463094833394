import { useState } from 'react'
import { DeepPartial } from '../../../../utils/types'
import { LessonType, CourseType, QuizType, QuizCategoryEnum, SummaryStateEnum } from '../../../../../../lib/sharedTypes'
//MATERIAL UI
import { Grid, Button, TextField, CircularProgress, useTheme } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { COURSE_QUIZ_QUERY, LESSON_QUIZ_QUERY } from '../../../../gql/queries'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { CREATE_QUIZ_MUTATION } from '../../../../gql/mutations'
import { useAlert } from '../../../../hooks'
import { handleError } from '../../../../utils/handleError'

type DuplicateQuizPropsType = {
  course: CourseType
  handleClose: () => void
  refetch: () => Promise<void>
}

const DuplicateQuiz = ({ course, handleClose, refetch }: DuplicateQuizPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [selectedQuiz, setSelectedQuiz] = useState<DeepPartial<LessonType>>()
  const [newLessonName, setNewLessonName] = useState('')
  const { data } = useQuery<{
    course: CourseType
  }>(COURSE_QUIZ_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: course.code_name,
        },
      },
      lessonWhere: {
        quiz: {
          id: {
            ne: null,
          },
        },
      },
    },
  })

  const [createQuizMutation] = useMutation<{
    createQuiz: QuizType
  }>(CREATE_QUIZ_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Quiz créé` })
      await refetch()
      handleClose()
    },
  })

  const [getQuiz] = useLazyQuery<{ lesson: LessonType }>(LESSON_QUIZ_QUERY)

  if (!data) {
    return (
      <Grid item container direction="row" justifyContent="center" spacing={1}>
        <CircularProgress color="primary" />
      </Grid>
    )
  }

  if (data) {
    return (
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Autocomplete
            fullWidth
            options={data?.course.lessons}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => {
              if (value) {
                setSelectedQuiz(value)
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" label="Quiz" />
            }}
          />
          <TextField
            style={{ marginTop: theme.spacing(1) }}
            fullWidth
            name="name"
            label="Nom de la nouvelle leçon quiz"
            variant="outlined"
            value={newLessonName}
            onChange={(e) => {
              setNewLessonName(e.target.value)
            }}
            required
          />
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            disabled={!selectedQuiz || !newLessonName}
            variant="contained"
            color="primary"
            onClick={async () => {
              if (selectedQuiz && selectedQuiz?.id && newLessonName) {
                const { data } = await getQuiz({
                  variables: {
                    where: {
                      id: {
                        eq: parseInt(selectedQuiz?.id?.toString()),
                      },
                    },
                    qq_order: {
                      field: 'position',
                      sort: 'ASC',
                    },
                  },
                })
                const quizInput: Record<string, any> = {
                  id: undefined,
                  category: data?.lesson?.quiz?.category || QuizCategoryEnum.NO_IMPACT,
                  allow_retry: data?.lesson?.quiz?.allow_retry,
                  display: data?.lesson?.quiz?.display,
                  quiz_questions: data?.lesson?.quiz?.quiz_questions?.map((qq_question, index) => {
                    return {
                      id: typeof qq_question?.id === 'number' ? null : qq_question?.id,
                      points: qq_question?.points,
                      position: index,
                      lesson_question: { id: qq_question?.lesson_question?.id },
                    }
                  }),
                  lesson: {
                    id: undefined,
                    course: { id: course?.id },
                    validated: data?.lesson?.validated || false,
                    summary_required: data?.lesson?.summary_required || false,
                    name: newLessonName,
                    summary_state: data?.lesson?.summary_state || SummaryStateEnum.VALIDATED,
                    summary: data?.lesson?.summary,
                  },
                }
                await createQuizMutation({ variables: { input: quizInput } })
              }
            }}
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    )
  }
  return <></>
}

export default DuplicateQuiz
