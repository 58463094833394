import { useState } from 'react'
//COMPONENTS
import { Error, Loading, CustomTable, Note } from '../../components'
// MATERIAL UI
import { Grid, Chip } from '@material-ui/core'
//ICONS
import { ImVimeo2 } from 'react-icons/im'
// TYPE
import { LessonVideoType, ProjectType, SortEnum } from '../../../../lib/sharedTypes'
import { ColumnType } from '../../utils/types'
//GQL
import { PROJECT_VIDEOS_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
//UTILS
import { secondsToDHMS } from '../../utils/misc'
import { videosCaptions } from '../../utils/constants'
//ROUTER
import { useNavigate, useParams } from 'react-router'

const Videos = () => {
  const navigate = useNavigate()
  const { code_name } = useParams()
  const [field, setField] = useState<keyof LessonVideoType>('id')
  const [sort, setSort] = useState<keyof typeof SortEnum>('ASC')

  const {
    loading,
    error,
    data: { project: projectVideo } = {},
    refetch,
  } = useQuery<{
    project: ProjectType
  }>(PROJECT_VIDEOS_QUERY, {
    variables: {
      order: {
        field: field,
        sort: sort,
      },
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  const columns: ColumnType<LessonVideoType>[] = [
    {
      title: 'Nombre',
      field: 'number',
      render: (row: LessonVideoType) => row.number,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Nom de la leçon',
      field: 'lesson.name',
      render: (row: LessonVideoType) => row?.lesson?.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Notes',
      field: 'lesson.note',
      render: (row: LessonVideoType) => <Note lesson={row?.lesson} refetch={refetch} />,
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Durée',
      field: 'file_duration',
      render: (row: LessonVideoType) => secondsToDHMS(row.file_duration),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Statut',
      field: 'review_state',
      render: (row: LessonVideoType) => (
        <Chip
          label={videosCaptions[row.review_state].label}
          style={{ backgroundColor: videosCaptions[row.review_state].color }}
          color="primary"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (code_name) {
              navigate(`/projects/${code_name}/sets/${row.video_set.index}/videos/${row.number}`, {
                state: { openModal: true },
              })
            }
          }}
        />
      ),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Vimeo',
      field: 'vimeo_url',
      render: (row: LessonVideoType) => {
        if (row.vimeo_url) {
          return <ImVimeo2 size="30" color="#3E83F5" />
        } else {
          return <ImVimeo2 size="30" color="#e0e0e0" />
        }
      },
      type: 'String',
      sortable: true,
    },
  ]

  if (error || !code_name) {
    return <Error error={error} />
  }

  if (loading || !projectVideo) {
    return <Loading />
  }

  return (
    <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
      <Grid item style={{ width: '100%' }}>
        <CustomTable
          columns={columns}
          rows={projectVideo.project_videos.filter((video) => video.video_set)}
          field={field}
          sort={sort}
          setSort={setSort}
          setField={setField}
          onRowClick={(row: LessonVideoType, e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open(`/projects/${code_name}/sets/${row.video_set.index}/videos/${row.number}`, '_blank')
            } else {
              navigate(`/projects/${code_name}/sets/${row.video_set.index}/videos/${row.number}`)
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
export default Videos
