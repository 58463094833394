import { useState } from 'react'
//COMPONENTS
import { Layout, Title, CustomTable, Error, Loading } from '../components'
//MATERIAL UI
import { Grid, ButtonGroup, Button } from '@material-ui/core'
//GQL
import { PRODUCTION_REPORTS_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'
//TYPES
import { ColumnType } from '../utils/types'
import { ProductionReportType, SortEnum } from '../../../lib/sharedTypes'
//UTILS
import { secondsToDHMS } from '../utils/misc'
//ROUTER
import { useNavigate, useParams } from 'react-router'

const Reports = () => {
  const navigate = useNavigate()
  const { period } = useParams<{ period: string }>()
  if (!['WEEKLY', 'DAILY', 'MONTHLY', undefined].includes(period)) {
    navigate('/reports/WEEKLY')
    //FORCE REFRESH
    navigate(0)
  }

  const [reportPeriod, setReportPeriod] = useState(period || 'WEEKLY')
  const [field, setField] = useState<keyof ProductionReportType>('report_date')
  const [sort, setSort] = useState<SortEnum>(SortEnum.DESC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)

  const {
    loading,
    error,
    data: { productionReports = [], productionReportsCount = 0 } = {},
  } = useQuery<{
    productionReports: ProductionReportType[]
    productionReportsCount: number
  }>(PRODUCTION_REPORTS_QUERY, {
    variables: {
      period: reportPeriod,
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
  })

  if (error) {
    return (
      <Layout title="Rapports">
        <Error error={error} />
      </Layout>
    )
  }

  if (loading) {
    return (
      <Layout title="Rapports">
        <Loading />
      </Layout>
    )
  }

  const columns: ColumnType<ProductionReportType>[] = [
    {
      title: 'Date',
      field: 'report_date',
      render: (row: ProductionReportType) => {
        switch (reportPeriod) {
          case 'DAILY':
            return `Rapport du ${new Date(row.report_date).toLocaleDateString('fr-FR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}`
          case 'WEEKLY': {
            const start = new Date(row.report_date).toLocaleDateString('fr-FR', {
              day: 'numeric',
            })
            const end = new Date(row.report_date)
            end.setDate(end.getDate() + 6)
            let endDate = end.toLocaleDateString('fr-FR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })
            if (new Date() < end) {
              endDate = new Date().toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })
            }
            return `Rapport du ${start} au ${endDate}`
          }
          case 'MONTHLY': {
            const start = new Date(row.report_date).toLocaleDateString('fr-FR', {
              day: 'numeric',
              month: 'long',
              // year: 'numeric',
            })
            const end = new Date(row.report_date)
            end.setMonth(end.getMonth() + 1)
            end.setDate(end.getDate() - 1)
            let endDate = end.toLocaleDateString('fr-FR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })
            if (new Date() < end) {
              endDate = new Date().toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })
            }
            return `Rapport du ${start} au ${endDate}`
          }

          default:
            return 'Invalid date'
        }
      },
      type: 'String',
      sortable: true,
    },
    {
      title: 'Durée filmée',
      field: 'shot_duration',
      render: (row: ProductionReportType) =>
        secondsToDHMS(
          row.project_reports.reduce((acc, el) => {
            acc += el.shot_duration
            return acc
          }, 0),
          false,
        ),
      type: 'String',
      sortable: false,
    },
    {
      title: 'Durée en stock',
      field: 'stock_duration',
      render: (row: ProductionReportType) =>
        secondsToDHMS(
          row.project_reports.reduce((acc, el) => {
            acc += el.stock_duration
            return acc
          }, 0),
          false,
        ),
      type: 'String',
      sortable: false,
    },
    {
      title: 'Durée validée',
      field: 'validated_duration',
      render: (row: ProductionReportType) =>
        secondsToDHMS(
          row.durations.reduce((acc, el) => {
            acc += el.validated_duration
            return acc
          }, 0),
          false,
        ),
      type: 'String',
      sortable: false,
    },
    {
      title: 'Durée mise en ligne',
      field: 'uploaded_duration',
      render: (row: ProductionReportType) =>
        secondsToDHMS(
          row.durations.reduce((acc, el) => {
            acc += el.uploaded_duration
            return acc
          }, 0),
          false,
        ),
      type: 'String',
      sortable: false,
    },
  ]

  return (
    <Layout title="Rapports">
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title="Rapports" />
          </Grid>
        </Grid>
        <Grid item>
          <ButtonGroup color="primary" aria-label="">
            <Button
              color={reportPeriod === 'DAILY' ? 'secondary' : 'primary'}
              onClick={() => {
                setReportPeriod('DAILY')
              }}
            >
              Quotidien
            </Button>
            <Button
              color={reportPeriod === 'WEEKLY' ? 'secondary' : 'primary'}
              onClick={() => {
                setReportPeriod('WEEKLY')
              }}
            >
              Hebdomadaire
            </Button>
            <Button
              color={reportPeriod === 'MONTHLY' ? 'secondary' : 'primary'}
              onClick={() => {
                setReportPeriod('MONTHLY')
              }}
            >
              Mensuel
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CustomTable
            columns={columns}
            rows={productionReports}
            field={field}
            sort={sort}
            take={take}
            skip={skip}
            setField={setField}
            setSort={setSort}
            setTake={setTake}
            setSkip={setSkip}
            rowsCount={productionReportsCount}
            onRowClick={(row: ProductionReportType, e: React.MouseEvent) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(
                  `/reports/${reportPeriod}/${new Date(row.report_date).toISOString().split('T')[0]}`,
                  '_blank',
                )
              } else {
                if (period) {
                  navigate(`${new Date(row.report_date).toISOString().split('T')[0]}`)
                } else {
                  navigate(`${reportPeriod}/${new Date(row.report_date).toISOString().split('T')[0]}`)
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  )
}
export default Reports
