import { useState, useEffect } from 'react'
// MUI
import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
  makeStyles,
  Checkbox,
} from '@material-ui/core'
import { UserAvatar } from '../index'
// TYPES
import {
  NotificationType,
  VideoReviewType,
  VideoReviewCommentType,
  ProjectType,
  LessonVideoType,
  LessonVideoDiagramType,
  ProductType,
  CourseType,
  VideoSetType,
  LessonType,
} from '../../../../lib/sharedTypes'
// UTILS
import { dateToTimeAgo } from '../../utils/misc'
// ICONS
import { BsFillCircleFill } from 'react-icons/bs'
import { MdCheck } from 'react-icons/md'
//GQL
import {
  VIDEO_REVIEW_QUERY,
  VIDEO_REVIEW_COMMENT_QUERY,
  PROJECT_QUERY,
  LESSON_VIDEO_NOTIFICATION_QUERY,
  LESSON_VIDEO_DIAGRAM_NOTIFICATION_QUERY,
  PRODUCT_CODE_NAME_QUERY,
  COURSE_CODE_NAME_QUERY,
  VIDEO_SET_NOTIFICATION_QUERY,
  LESSON_SUMMARY_NOTIFICATION_QUERY,
} from '../../gql/queries'
import { UPDATE_NOTIFICATION_MUTATION } from '../../gql/mutations'
import { useLazyQuery, useMutation } from '@apollo/client'
//REACT-ROUTER
import { useNavigate } from 'react-router-dom'

type NotificationPropsType = {
  notification: NotificationType
  refetch: () => Promise<any>
  handleClose: () => void
  type: 'small' | 'large'
}

const useStyles = makeStyles((theme) => ({
  notificationItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 5,
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.selected,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

const Notification = ({ notification, refetch, handleClose, type }: NotificationPropsType) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const classes = useStyles()
  const [notificationTargetPath, setNotificationTargetPath] = useState<string | null>(null)
  const [updateNotification] = useMutation<{ notification: NotificationType }>(UPDATE_NOTIFICATION_MUTATION)

  const [getNotificationReviewTarget, { data: { videoReview = null } = {} }] = useLazyQuery<{
    videoReview: VideoReviewType
  }>(VIDEO_REVIEW_QUERY, {
    variables: {
      where: {
        id: {
          eq: notification.target_id,
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(
        `/projects/${data.videoReview.lesson_video.video_set.project.code_name}/videos/${data.videoReview?.lesson_video.id}`,
      )
    },
  })

  const [getNotificationReviewCommentTarget, { data: { videoReviewComment = null } = {} }] = useLazyQuery<{
    videoReviewComment: VideoReviewCommentType
  }>(VIDEO_REVIEW_COMMENT_QUERY, {
    variables: {
      where: {
        id: {
          eq: notification.target_id,
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(
        `/projects/${data.videoReviewComment.video_review.lesson_video.video_set.project.code_name}/videos/${data.videoReviewComment.video_review.lesson_video.id}`,
      )
    },
  })

  const [getNotificationLessonVideoDiagramRequestTarget, { data: { lessonVideoDiagram = null } = {} }] = useLazyQuery<{
    lessonVideoDiagram: LessonVideoDiagramType
  }>(LESSON_VIDEO_DIAGRAM_NOTIFICATION_QUERY, {
    variables: {
      where: {
        id: {
          eq: notification.target_id,
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(
        `/projects/${data.lessonVideoDiagram.lesson_video.video_set.project.code_name}/sets/${data.lessonVideoDiagram.lesson_video.video_set.index}/videos/${data.lessonVideoDiagram.lesson_video.number}`,
      )
    },
  })

  const [getNotificationAssignmentTarget] = useLazyQuery<{
    project: ProjectType
  }>(PROJECT_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/projects/${data.project.code_name}`)
    },
  })

  const [getNotificationShootingTarget] = useLazyQuery<{
    project: ProjectType
  }>(PROJECT_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/projects/${data.project.code_name}/team_shootings`)
    },
  })

  const [getNotificationLessonVideoTarget] = useLazyQuery<{
    lessonVideo: LessonVideoType
  }>(LESSON_VIDEO_NOTIFICATION_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(
        `/projects/${data.lessonVideo.video_set.project.code_name}/sets/${data.lessonVideo.video_set.index}/videos/${data.lessonVideo.number}`,
      )
    },
  })

  const [getNotificationLessonSummaryTarget] = useLazyQuery<{
    lesson: LessonType
  }>(LESSON_SUMMARY_NOTIFICATION_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/formations/${data.lesson.course.code_name}/lessons/video/${data.lesson.id}`)
    },
  })

  const [getNotificationProductTeaserTarget] = useLazyQuery<{
    product: ProductType
  }>(PRODUCT_CODE_NAME_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/projects/${data.product.code_name}/teaser`)
    },
  })

  const [getNotificationVideoSet] = useLazyQuery<{
    videoSet: VideoSetType
  }>(VIDEO_SET_NOTIFICATION_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/projects/${data.videoSet.project.code_name}/sets/${data.videoSet.index}`)
    },
  })

  const [getNotificationProductPublished] = useLazyQuery<{
    product: ProductType
  }>(PRODUCT_CODE_NAME_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/products/${data.product.code_name}`)
    },
  })

  const [getNotificationCoursePublished] = useLazyQuery<{
    course: CourseType
  }>(COURSE_CODE_NAME_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/formations/${data.course.code_name}`)
    },
  })

  const [getNotificationGeneratedCourseSynthesis] = useLazyQuery<{
    course: CourseType
  }>(COURSE_CODE_NAME_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/formations/${data.course.code_name}/lessons/synthese`)
    },
  })

  const [getNotificationProgramUpdated] = useLazyQuery<{
    product: ProductType
  }>(PRODUCT_CODE_NAME_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/products/${data.product.code_name}/visual_management`)
    },
  })

  const [getNotificationProductTeaser] = useLazyQuery<{
    project: ProjectType
  }>(PROJECT_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/projects/${data.project.code_name}/teaser`)
    },
  })

  const [getNotificationCoursePart] = useLazyQuery<{
    course: CourseType
  }>(COURSE_CODE_NAME_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(notification.target_id.toString()),
        },
      },
    },
    onCompleted(data) {
      setNotificationTargetPath(`/formations/${data.course.code_name}/plan`)
    },
  })

  const whichLabel = (category: string, firstName: string) => {
    let label
    switch (category) {
      case 'PRODUCTION':
        label = 'Production'
        break
      case 'REVIEW':
        label = `Corrections à apporter`
        break
      case 'INFO':
        label = 'Info'
        break
      case 'COMMENT':
        label = `${firstName} a ajouté un commentaire`
        break
      case 'ASSIGNMENT':
        label = `Mission`
        break
      case 'SHOOTING':
        label = `Tournage`
        break
      case 'LESSON_SUMMARY':
        label = `Résumé leçon`
        break
      case 'LESSON_VIDEO':
        label = `Vidéo`
        break
      case 'LESSON_VIDEO_DIAGRAM':
        label = 'Schéma'
        break
      case 'PRODUCT':
        label = 'Bande-annonce'
        break
      case 'PUBLISHED_PRODUCT':
      case 'PUBLISHED_COURSE':
        label = 'Publication'
        break
      case 'GENERATED_COURSE_SYNTHESIS':
        label = 'Synthèses'
        break
      case 'PROGRAM_UPDATED':
        label = 'Programme'
        break
      case 'VIDEO_SET':
        label = 'Video set'
        break
      case 'PRODUCT_TEASER':
        label = 'Bande-annonce'
        break
      case 'COURSE_PARTS_IMPORT':
        label = 'Import séquencier'
        break
      case 'PRODUCT_THEME':
        label = 'Thème produit'
        break
      default:
        label = ''
        console.error('category de notification inconnue!')
        break
    }
    return label
  }

  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-floating-promises */
    switch (notification.category) {
      case 'REVIEW': {
        if (!notificationTargetPath) {
          getNotificationReviewTarget()
        }
        break
      }
      case 'COMMENT': {
        if (!notificationTargetPath) {
          getNotificationReviewCommentTarget()
        }
        break
      }
      case 'ASSIGNMENT': {
        if (!notificationTargetPath) {
          getNotificationAssignmentTarget()
        }
        break
      }
      case 'SHOOTING': {
        if (!notificationTargetPath) {
          getNotificationShootingTarget()
        }
        break
      }
      case 'LESSON_SUMMARY': {
        if (!notificationTargetPath) {
          getNotificationLessonSummaryTarget()
        }
        break
      }
      case 'LESSON_VIDEO': {
        if (!notificationTargetPath) {
          getNotificationLessonVideoTarget()
        }
        break
      }
      case 'LESSON_VIDEO_DIAGRAM':
        if (!notificationTargetPath) {
          getNotificationLessonVideoDiagramRequestTarget()
        }
        break
      case 'PRODUCT':
        if (!notificationTargetPath) {
          getNotificationProductTeaserTarget()
        }
        break
      case 'PUBLISHED_PRODUCT':
        if (!notificationTargetPath) {
          getNotificationProductPublished()
        }
        break
      case 'PUBLISHED_COURSE':
        if (!notificationTargetPath) {
          getNotificationCoursePublished()
        }
        break
      case 'GENERATED_COURSE_SYNTHESIS':
        getNotificationGeneratedCourseSynthesis()
        break
      case 'PROGRAM_UPDATED':
        getNotificationProgramUpdated()
        break
      case 'VIDEO_SET':
        if (!notificationTargetPath) {
          getNotificationVideoSet()
        }
        break
      case 'PRODUCT_TEASER':
        if (!notificationTargetPath) {
          getNotificationProductTeaser()
        }
        break
      case 'COURSE_PARTS_IMPORT':
        if (!notificationTargetPath) {
          getNotificationCoursePart()
        }
        break
      case 'PRODUCT_THEME':
        if (!notificationTargetPath) {
          setNotificationTargetPath(`/themes/${notification.target_id}`)
        }
        break

      default:
        console.error('Unknown notification category!')
        break
    }
  }, [])

  const handleNavigate = () => {
    if (!notificationTargetPath) return
    switch (notification.category) {
      case 'REVIEW':
        navigate(notificationTargetPath, { state: { openModal: true, timeCode: videoReview?.time_code } })
        break
      case 'COMMENT':
        navigate(notificationTargetPath, {
          state: { openModal: true, timeCode: videoReviewComment?.video_review.time_code },
        })
        break
      case 'LESSON_VIDEO_DIAGRAM':
        navigate(notificationTargetPath, {
          state: { openModal: true, timeCode: lessonVideoDiagram?.time_code },
        })
        break
      default:
        navigate(notificationTargetPath)
        break
    }
  }

  return (
    <ListItem
      className={classes.notificationItem}
      selected={!notification.read_at}
      onClick={async () => {
        handleNavigate()
        if (!notification.read_at) {
          await updateNotification({
            variables: {
              input: {
                id: notification.id,
                read_at: new Date(),
              },
            },
          })
          await refetch()
        }
        handleClose()
      }}
    >
      <ListItemAvatar>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{
            paddingLeft: type === 'large' ? theme.spacing(2) : 'unset',
            paddingRight: type === 'large' ? theme.spacing(2) : 'unset',
          }}
        >
          {type === 'large' && !notification.checked_at && (
            <Checkbox
              disableRipple
              edge="start"
              tabIndex={-1}
              onClick={async (event) => {
                event.stopPropagation()
                if (!notification.checked_at) {
                  await updateNotification({
                    variables: {
                      input: {
                        id: notification.id,
                        read_at: new Date(),
                        checked_at: new Date(),
                      },
                    },
                  })
                  await refetch()
                }
              }}
              defaultChecked={false}
            />
          )}
          {type === 'large' && notification.checked_at && (
            <MdCheck size="22" style={{ marginRight: theme.spacing(1), color: theme.palette.primary.main }} />
          )}

          {!notification.read_at && (
            <BsFillCircleFill
              fontSize="6"
              style={{ marginRight: theme.spacing(1), color: theme.palette.primary.main }}
            />
          )}
          {notification.sender && <UserAvatar user={notification.sender} />}
        </Grid>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={
          notification.checked_at
            ? { style: { textDecoration: 'line-through', padding: 0 } }
            : { style: { padding: 0 } }
        }
        primary={
          <>
            <Typography variant="caption">
              {whichLabel(notification.category, notification?.sender?.first_name)}
            </Typography>
            <Typography
              variant="body2"
              style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre-wrap' }}
            >
              {notification.content}
            </Typography>
          </>
        }
        secondary={
          <Typography variant="caption" style={{ color: theme.palette.text.hint }}>
            {dateToTimeAgo(notification.sent_at)}
          </Typography>
        }
      />
    </ListItem>
  )
}

export default Notification
