//REDUX
import { useAppSelector } from '../hooks/reducerHooks'
//ROUTER
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)
  return isLoggedIn ? children : <Navigate to="/" />
}

export default PrivateRoute
