import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
// COMPONENTS
import { FormLayout } from '../index'
import { Error, DurationInput } from '../../index'
// TYPE
import { CourseType, UserType } from '../../../../../lib/sharedTypes'
//GQL
import { COURSE_MANAGERS_QUERY } from '../../../gql/queries'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_COURSE_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import moment from 'moment'

type GeneralInformationsFormPropsType = {
  course: CourseType
  refetch: () => Promise<any>
  handleClose: () => void
}

const GeneralInformationsForm = ({ course, refetch, handleClose }: GeneralInformationsFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateCourse, setStateCourse] = useState<CourseType>(course)
  const { error: errorManagers, data: { users } = {} } = useQuery<{ users: UserType[] }>(COURSE_MANAGERS_QUERY, {
    variables: {
      where: { job: { category: { in: ['DIRECTOR', 'PROJECT'] } } },
      order: { field: 'username', sort: 'ASC' },
    },
  })

  const [updateCourseMutation] = useMutation<{ updateCourse: CourseType }>(UPDATE_COURSE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCourse.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (key: string, value: string) => {
    setStateCourse((prevState) => {
      return {
        ...prevState,
        ...{ [key]: value },
      }
    })
  }

  if (errorManagers) {
    return <Error error={errorManagers} />
  }

  if (!stateCourse) {
    return <Error error={'Formation inconnue!'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const courseInput = {
      id: stateCourse.id,
      name: stateCourse.name,
      code_name: stateCourse.code_name,
      position: stateCourse.position,
      duration: stateCourse.duration,
      premiere_session: stateCourse.premiere_session || null,
      sub_courses: stateCourse.sub_courses?.map((course) => {
        return { id: course.id }
      }),
      managers: stateCourse.managers?.map((manager) => {
        return { id: manager.id }
      }),
      requirements: stateCourse?.requirements?.map((requirement) => {
        if (typeof requirement.id === 'number') {
          return {
            requires: { id: requirement.course.id },
            completion_rate: requirement.completion_rate,
          }
        } else {
          return {
            ...requirement,
            requires: { id: requirement.requires.id },
          }
        }
      }),
    }
    await updateCourseMutation({ variables: { input: courseInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour la formation"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateCourse) === JSON.stringify(course)}
    >
      <Grid item>
        <TextField
          required
          label="Nom"
          name="name"
          value={stateCourse.name}
          onChange={(e) => {
            e.preventDefault()
            const key = e.target.name
            const value = e.target.value
            handleChange(key, value)
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={4}>
          <TextField
            type="number"
            label="Position"
            name="position"
            disabled={stateCourse.parent === null}
            value={stateCourse.position}
            onChange={(e) => {
              e.preventDefault()
              const key = e.target.name
              const value = e.target.value
              handleChange(key, value)
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <DurationInput
            format="hh"
            label="Durée de la formation"
            defaultValue={stateCourse.duration}
            onChange={(duration) => {
              setStateCourse((prevState) => {
                return {
                  ...prevState,
                  duration: duration,
                }
              })
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            id="premiere_session"
            label="Date de sortie"
            type="date"
            defaultValue={
              stateCourse.premiere_session ? moment(stateCourse.premiere_session).format('YYYY-MM-DD') : undefined
            }
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              event.preventDefault()
              handleChange(event.target.id, event.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Autocomplete
          multiple
          defaultValue={stateCourse.managers}
          options={users || []}
          getOptionSelected={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option?.username}
          onChange={(_, value) => {
            if (value) {
              setStateCourse({
                ...stateCourse,
                managers: value,
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Manager(s)" />
          }}
        />
      </Grid>
    </FormLayout>
  )
}

export default GeneralInformationsForm
