import { useEffect, useState } from 'react'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, TextField, Button, ButtonGroup } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
import { Error, Loading, CustomModal } from '../../index'
import MultiChoiceQuestionForm from './MultiChoiceQuestionForm'
import GapFillQuestionForm from './GapFillQuestionForm'
import RankingQuestionForm from './RankingQuestionForm'
import DuplicateQuestionForm from './DuplicateQuestionForm'
// TYPE
import { DeepPartial } from '../../../utils/types'
import { CourseSkillType, CourseType, LessonQuestionType, LessonType } from '../../../../../lib/sharedTypes'
//GQL
import { COURSE_SKILLS_QUERY, COURSE_LESSONS_QUERY } from '../../../gql/queries'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_LESSON_QUESTION_MUTATION, CREATE_LESSON_QUESTION_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { useParams } from 'react-router'
import { getLessonCategory, getQuestionCategory } from '../../../utils/misc'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'

type QuestionFormPropsType = {
  lesson?: LessonType
  course: CourseType
  question: LessonQuestionType | undefined
  refetch: () => Promise<any>
  handleClose: () => void
}

const QuestionForm = ({ question, course, lesson, refetch, handleClose }: QuestionFormPropsType) => {
  const [questionType, setQuestionType] = useState<'mcQuestion' | 'gapFillQuestion' | 'rankingQuestion' | null>(null)
  const [solutionLessons, setSolutionLessons] = useState<LessonType[]>([])
  const { code_name } = useParams()
  const [propositionImages, setPropositionImages] = useState<Record<string, File | undefined>>({})
  const [setAlert] = useAlert()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [stateQuestion, setStateQuestion] = useState<DeepPartial<LessonQuestionType> | undefined>(question)
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [errorCount, setErrorCount] = useState(0)
  const [duplicateQuestion, setDuplicateQuestion] = useState(false)

  useEffect(() => {
    if (lesson) {
      setStateQuestion({
        ...stateQuestion,
        lesson: lesson,
      })
    }
  }, [])

  useEffect(() => {
    if (question) {
      setQuestionType(getQuestionCategory(question))
    }
  }, [])

  const {
    loading,
    error,
    data: { courseSkills } = {},
  } = useQuery<{ courseSkills: CourseSkillType[] }>(COURSE_SKILLS_QUERY, {
    variables: {
      where: {
        course: {
          code_name: {
            in: course.parent ? [course.code_name, course.parent.code_name] : [course.code_name],
          },
        },
      },
      order: {
        field: 'name',
        sort: 'ASC',
      },
    },
  })

  const {
    loading: lessonLoading,
    error: lessonError,
    data: { lessons, course: coursePlan } = {},
  } = useQuery<{ lessons: LessonType[]; course: CourseType }>(COURSE_LESSONS_QUERY, {
    variables: {
      where: {
        course: {
          code_name: {
            eq: code_name,
          },
        },
      },
      courseWhere: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: 'name',
        sort: 'ASC',
      },
    },
  })

  useEffect(() => {
    if (lessons) {
      setSolutionLessons(lessons)
    }
    if (coursePlan) {
      coursePlan.parts.map((part) => {
        part.sub_parts.map((sub_part) => {
          sub_part.sub_parts.map((ss_part) => {
            setSolutionLessons((prevState) => {
              if (!prevState.map((l) => l.id).includes(ss_part.lesson.id)) {
                return [...prevState, ss_part.lesson]
              }
              return prevState
            })
          })
        })
      })
    }
  }, [lessons, coursePlan])

  const [updateLessonQuestion] = useMutation<{ updateLessonQuestion: LessonQuestionType }>(
    UPDATE_LESSON_QUESTION_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Question mise-à-jour` })
        await refetch()
        handleClose()
      },
    },
  )
  const [createLessonQuestion] = useMutation<{ createLessonQuestion: LessonQuestionType }>(
    CREATE_LESSON_QUESTION_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Question créée` })
        await refetch()
        handleClose()
      },
    },
  )

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const mcQuestion = {
      id: stateQuestion?.mc_question?.id,
      statement: stateQuestion?.mc_question?.statement,
      mc_items: stateQuestion?.mc_question?.mc_items?.map((item) => {
        return {
          id: typeof item?.id === 'string' ? item?.id : undefined,
          statement: item?.statement,
          item_video: item?.item_video ? { id: item?.item_video?.id } : undefined,
          s3_image:
            item?.id && Object.keys(propositionImages).includes(item.id.toString())
              ? propositionImages[item.id]
              : undefined,
          correct: item?.correct,
        }
      }),
    }

    const rankingQuestion = {
      id: stateQuestion?.ranking_question?.id,
      statement: stateQuestion?.ranking_question?.statement,
      ranking_items: stateQuestion?.ranking_question?.ranking_items?.map((item) => {
        return {
          id: typeof item?.id === 'string' ? item?.id : undefined,
          statement: item?.statement,
          correct_position: item?.correct_position,
          displayed_position: item?.displayed_position,
        }
      }),
    }

    const gapFillQuestion = {
      id: stateQuestion?.gapfill_question?.id,
      text: stateQuestion?.gapfill_question?.text,
      gapfill_items: stateQuestion?.gapfill_question?.gapfill_items?.map((item) => {
        return {
          id: item?.id,
          position: item?.position,
          element_id: item?.element_id,
          is_correct: item?.is_correct,
          value: item?.value,
          strict: item?.strict,
        }
      }),
    }
    const questionInput = {
      id: stateQuestion?.id,
      introduction: stateQuestion?.introduction,
      question_video: { id: stateQuestion?.question_video?.id },
      s3_image: selectedFile ? selectedFile : undefined,
      help_text: stateQuestion?.help_text,
      correction_text: stateQuestion?.correction_text,
      correction_video: { id: stateQuestion?.correction_video?.id },
      skills: stateQuestion?.skills?.map((skill) => {
        return { id: skill?.id }
      }),
      lesson: { id: stateQuestion?.lesson?.id },
    } as Record<string, any>

    switch (questionType) {
      case 'mcQuestion':
        questionInput.mc_question = mcQuestion
        break
      case 'gapFillQuestion':
        questionInput.gapfill_question = gapFillQuestion
        break
      case 'rankingQuestion':
        questionInput.ranking_question = rankingQuestion
        break
      default:
        break
    }
    if (stateQuestion?.id) {
      //update
      await updateLessonQuestion({ variables: { input: questionInput } })
    } else {
      //create
      await createLessonQuestion({ variables: { input: questionInput } })
    }
    await refetch()
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])

    const url = URL.createObjectURL(e.target.files[0])
    if (url) {
      setStateQuestion((prevState) => {
        return {
          ...prevState,
          s3_image: url,
        }
      })
    }
  }

  const onSelectPropositionImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = e.target.getAttribute('data-item-id')
    if (e.target.files && itemId) {
      const url = URL.createObjectURL(e.target.files[0])
      setPropositionImages((prevState) => {
        return {
          ...prevState,
          [itemId]: e.target.files?.[0],
        }
      })
      const mc_items = stateQuestion?.mc_question?.mc_items?.map((item) => {
        if (item?.id?.toString() === e.target.getAttribute('data-item-id')) {
          return {
            ...item,
            s3_image: url,
          }
        } else {
          return item
        }
      })
      setStateQuestion((prevState) => {
        return {
          ...prevState,
          mc_question: {
            ...prevState?.mc_question,
            mc_items: mc_items,
          },
        }
      })
    }
  }

  useEffect(() => {
    setStateQuestion((prevState) => {
      return {
        ...prevState,
        mc_question: {
          ...prevState?.mc_question,
          mc_items: prevState?.mc_question?.mc_items?.map((item) => {
            if (item?.id) {
              const imageFile = propositionImages[item.id]
              if (imageFile) {
                const url = URL.createObjectURL(imageFile)
                return {
                  ...item,
                  s3_image: url,
                }
              }
            }
            return item
          }),
        },
      }
    })
  }, [propositionImages])

  const isDisabled = () => {
    switch (questionType) {
      case 'mcQuestion':
        return question?.id
          ? JSON.stringify(stateQuestion) === JSON.stringify(question)
          : !stateQuestion ||
              !(stateQuestion.mc_question?.statement || stateQuestion.question_video) ||
              !stateQuestion?.mc_question ||
              !stateQuestion?.mc_question?.mc_items?.length ||
              !stateQuestion.skills?.length ||
              !stateQuestion.lesson ||
              !stateQuestion?.mc_question?.mc_items?.every((item) => {
                return Boolean(item?.statement)
              }) ||
              !stateQuestion?.mc_question?.mc_items?.some((item) => {
                return item?.correct
              })

      case 'gapFillQuestion':
        return question?.id
          ? JSON.stringify(stateQuestion) === JSON.stringify(question)
          : !stateQuestion ||
              !stateQuestion?.gapfill_question ||
              !stateQuestion?.gapfill_question?.gapfill_items?.length ||
              !stateQuestion.skills?.length ||
              !stateQuestion.lesson ||
              errorCount !== 0
      case 'rankingQuestion':
        return question?.id
          ? JSON.stringify(stateQuestion) === JSON.stringify(question)
          : !stateQuestion ||
              !(stateQuestion.ranking_question?.statement || stateQuestion.question_video) ||
              !stateQuestion?.ranking_question ||
              !stateQuestion?.ranking_question?.ranking_items?.length ||
              !stateQuestion.skills?.length ||
              !stateQuestion.lesson
      default:
        return true
    }
  }

  if (loading || lessonLoading) {
    return <Loading />
  }

  if (lessonError) {
    return <Error error={lessonError} />
  }

  if (!code_name) {
    return <Error error={'code_name inconnu'} />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <FormLayout
      type={question ? 'update' : 'create'}
      title={question ? 'Mettre à jour la question' : 'Ajouter une question'}
      onSubmit={handleSubmit}
      submitDisabled={isDisabled()}
    >
      <ButtonGroup fullWidth>
        <Button
          variant={questionType === 'mcQuestion' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => {
            setQuestionType('mcQuestion')
          }}
        >
          Choix multiples
        </Button>
        <Button
          variant={questionType === 'gapFillQuestion' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => {
            setQuestionType('gapFillQuestion')
          }}
        >
          Texte à trous
        </Button>
        <Button
          variant={questionType === 'rankingQuestion' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => {
            setQuestionType('rankingQuestion')
          }}
        >
          Classement
        </Button>
      </ButtonGroup>
      <Grid item>
        <Button
          disabled={Boolean(question)}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            setDuplicateQuestion(true)
          }}
        >
          Dupliquer question
        </Button>
      </Grid>
      <Grid ref={animateRef}>
        {questionType === 'mcQuestion' && (
          <MultiChoiceQuestionForm
            stateQuestion={stateQuestion}
            setStateQuestion={setStateQuestion}
            onSelectPropositionImg={onSelectPropositionImg}
          />
        )}
        {questionType === 'gapFillQuestion' && !duplicateQuestion && (
          <GapFillQuestionForm
            setErrorCount={setErrorCount}
            stateQuestion={stateQuestion}
            setStateQuestion={setStateQuestion}
          />
        )}
        {questionType === 'rankingQuestion' && (
          <RankingQuestionForm stateQuestion={stateQuestion} setStateQuestion={setStateQuestion} />
        )}
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Autocomplete
          multiple
          style={{ width: '100%' }}
          value={stateQuestion?.skills || []}
          options={courseSkills ? courseSkills : []}
          getOptionSelected={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option?.name || ''}
          onChange={(_, value) => {
            if (value) {
              setStateQuestion({
                ...stateQuestion,
                skills: value,
              })
            }
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                label="Compétence(s)"
                required={!stateQuestion?.skills || stateQuestion?.skills?.length === 0}
              />
            )
          }}
        />
      </Grid>

      <Grid item>
        <Autocomplete
          value={stateQuestion?.lesson || null}
          options={solutionLessons}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) =>
            `${(option as LessonType).name} - ${getLessonCategory(option as LessonType)} - ${
              (option as LessonType)?.course?.code_name
            }`
          }
          onChange={(_, value) => {
            if (value) {
              setStateQuestion({
                ...stateQuestion,
                lesson: value,
              })
            } else {
              setStateQuestion({
                ...stateQuestion,
                lesson: undefined,
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Leçon contenant la solution" required />
          }}
        />
      </Grid>

      <Grid item>
        <TextField
          multiline
          maxRows={3}
          minRows={3}
          label="Complément réponse"
          name="help_text"
          value={stateQuestion?.help_text || ''}
          onChange={(e) => {
            e.preventDefault()
            setStateQuestion((prevState) => {
              return {
                ...prevState,
                ...{ [e.target.name]: e.target.value },
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} container spacing={1}>
        <Button variant="outlined" color="primary" component="label">
          Selectionner une image
          <input hidden name="image" accept=".jpg,.png,.jpeg" type="file" onChange={onSelectFile} />
        </Button>
        <Grid item container xs={12} key={stateQuestion?.s3_image?.toString()}>
          <img
            src={stateQuestion?.s3_image}
            alt={stateQuestion?.s3_image?.toString()}
            loading="lazy"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Grid>
      </Grid>
      <CustomModal
        size="sm"
        title="Selectionner une question"
        open={duplicateQuestion}
        onClose={() => {
          setDuplicateQuestion(false)
        }}
      >
        <DuplicateQuestionForm
          course={course}
          setPropositionImages={setPropositionImages}
          setStateQuestion={setStateQuestion}
          setQuestionType={setQuestionType}
          handleClose={() => {
            setDuplicateQuestion(false)
          }}
        />
      </CustomModal>
    </FormLayout>
  )
}

export default QuestionForm
