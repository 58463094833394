import React, { useState } from 'react'
//COMPONENTS
import { Layout, Title, CustomTable, SearchBar, Loading, Error, CustomModal } from '../components'
import { ProjectForm } from '../components/Forms'
//MATERIAL UI
import { Grid, Button } from '@material-ui/core'
//GQL
import { PROJECTS_QUERY, PROJECTS_SEARCH_QUERY } from '../gql/queries'
import { useQuery, useLazyQuery } from '@apollo/client'
//TYPES
import { ColumnType } from '../utils/types'
import { ProjectType, SortEnum } from '../../../lib/sharedTypes'
//ROUTER
import { useNavigate } from 'react-router'
//UTILS
import { isAuthorized, secondsToDHMS } from '../utils/misc'

const columns: ColumnType<ProjectType>[] = [
  {
    title: 'Code Name',
    field: 'code_name',
    render: (row: ProjectType) => row.code_name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Nom',
    field: 'name',
    render: (row: ProjectType) => row.name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Durée cible',
    field: 'duration_target',
    render: (row: ProjectType) => secondsToDHMS(row.duration_target),
    type: 'String',
    sortable: true,
  },
  {
    title: 'Durée de production',
    field: 'duration_production',
    render: (row: ProjectType) => secondsToDHMS(row.duration_production),
    type: 'String',
    sortable: true,
  },
  {
    title: 'Réalisateur(s)',
    field: 'directors',
    render: (row: ProjectType) => row.members,
    type: 'Avatars',
    sortable: false,
  },
  {
    title: 'Crée le',
    field: 'created_at',
    render: (row: ProjectType) => row.created_at,
    type: 'Date',
    sortable: true,
  },
]

const Projects = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const navigate = useNavigate()
  const [field, setField] = useState<keyof ProjectType>('created_at')
  const [sort, setSort] = useState<SortEnum>(SortEnum.DESC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [searching, setSearching] = useState<boolean>(false)
  const {
    loading,
    error,
    data: { projects = [], projectsCount = 0 } = {},
    refetch,
  } = useQuery<{
    projects: ProjectType[]
    projectsCount: number
  }>(PROJECTS_QUERY, {
    variables: {
      where: {
        job: {
          category: {
            eq: 'DIRECTOR',
          },
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
  })

  const [searchProjectsQuery, { error: searchError, data: { projectsSearch = [] } = {} }] = useLazyQuery<{
    projectsSearch: ProjectType[]
  }>(PROJECTS_SEARCH_QUERY)

  const searchProjects = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchProjectsQuery({
      variables: {
        searchQuery: searchQuery,
        where: {
          job: {
            category: {
              eq: 'DIRECTOR',
            },
          },
        },
        order: {
          field: field,
          sort: sort,
        },
        take: take,
      },
    })
  }

  if (error || searchError) {
    return (
      <Layout title="Projets">
        <Error error={error} />
      </Layout>
    )
  }

  if (searchError) {
    return (
      <Layout title="Projets">
        <Error error={searchError} />
      </Layout>
    )
  }

  if (loading) {
    return (
      <Layout title="Projets">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Projets">
        <Error error={error} />
      </Layout>
    )
  }

  if (searchError) {
    return (
      <Layout title="Projets">
        <Error error={searchError} />
      </Layout>
    )
  }

  return (
    <Layout title="Projets">
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Title title={'Projets'} />
            </Grid>
            <Grid item>
              <Button
                disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'PROJECT', 'DIRECTOR'])}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                Ajouter un projet
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SearchBar placeholder="Rechercher un projet" searchQuery={searchProjects} />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CustomTable
            columns={columns}
            rows={searching ? projectsSearch : projects}
            field={field}
            sort={sort}
            take={take}
            skip={skip}
            setField={setField}
            setSort={setSort}
            setTake={setTake}
            setSkip={setSkip}
            rowsCount={searching ? projectsSearch.length : projectsCount}
            onRowClick={(row: ProjectType, e: React.MouseEvent) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(`/projects/${row.code_name}`, '_blank')
              } else {
                navigate(row.code_name)
              }
            }}
          />
        </Grid>
      </Grid>
      <CustomModal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        animation="slide-up"
      >
        <ProjectForm project={undefined} refetch={refetch} handleClose={() => setOpenModal(false)} />
      </CustomModal>
    </Layout>
  )
}

export default Projects
