//REACT
import { useState } from 'react'
// COMPONENTS
import { LessonInfo } from '../../../components/Lesson'
import { Layout, Title, Error, Loading, CustomModal } from '../../../components'
import { LessonHtmlForm } from '../../..//components/Forms/lesson'
// MATERIAL UI
import { Grid, Typography, Paper, useTheme, makeStyles, Button, Divider } from '@material-ui/core'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
// TYPES
import { LessonType } from '../../../../../lib/sharedTypes'
// GRAPHQL
import { useQuery } from '@apollo/client'
import { LESSON_HTML_QUERY } from '../../../gql/queries'
//UTILS
import { isAuthorized, isCourseManager } from '../../../utils/misc'

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonHtml = () => {
  const { code_name, lesson_id } = useParams()
  const theme = useTheme()
  const classes = useStyles()
  const [updateHTMLModal, setUpdateHTMLModal] = useState(false)
  if (!lesson_id) {
    return (
      <Layout title="Leçon HTML">
        <Error error="lesson_id inconnu" />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Leçon HTML">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  const {
    loading,
    error,
    data: { lesson } = {},
    refetch,
  } = useQuery<{
    lesson: LessonType
  }>(LESSON_HTML_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(lesson_id?.toString()),
        },
      },
      qq_order: {
        field: 'position',
        sort: 'ASC',
      },
    },
  })

  if (loading || !lesson) {
    return (
      <Layout title="Leçon HTML">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Leçon HTML">
        <Error error={error} />
      </Layout>
    )
  }

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item>
          <Title title={code_name} />
        </Grid>
        {/* LESSON */}
        <LessonInfo
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        {/* HTML */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">HTML</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateHTMLModal(true)
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Contenu
                  </Typography>
                </Grid>
                <Grid item style={{ maxHeight: 350, overflow: 'scroll' }}>
                  <div dangerouslySetInnerHTML={{ __html: lesson.html.content }}></div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <CustomModal
        open={updateHTMLModal}
        onClose={() => {
          setUpdateHTMLModal(false)
        }}
        animation="slide-up"
      >
        <LessonHtmlForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setUpdateHTMLModal(false)
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default LessonHtml
