/* eslint-disable @typescript-eslint/restrict-template-expressions */

//REACT
import { useRef } from 'react'
//MATERIAL UI
import { MdDragIndicator } from 'react-icons/md'
import { ThematicType } from '../../../../lib/sharedTypes'
//MATERIAL UI
import { Grid, useTheme, List, ListItem, ListItemText, ListItemIcon, makeStyles } from '@material-ui/core'
//REACT-ROUTER
import { useNavigate, useParams } from 'react-router-dom'
//TYPES
import { DeepPartial } from '../../utils/types'
//DND
import { useDrag, useDrop, XYCoord } from 'react-dnd'
import { Identifier } from 'dnd-core'

type DragItem = {
  index: number
  id: string
  type: string
}

type ThematicCardPropsType = {
  index: number
  thematic: DeepPartial<ThematicType> | undefined
  moveCard: (dragIndex: number, hoverIndex: number, item: DragItem) => void
}

const useStyles = makeStyles((theme) => ({
  thematicCard: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.background.paper,
    },
  },
}))

const ThematicCard = ({ thematic, index, moveCard }: ThematicCardPropsType) => {
  const theme = useTheme()
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { code_name } = useParams<{ code_name: string }>()

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'THEMATIC_CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, item)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'THEMATIC_CARD',
    item: { item: thematic, index: index, type: 'THEMATIC_CARD' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  drag(drop(ref))

  return (
    <Grid
      xs={12}
      item
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.thematicCard}
      style={{
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        opacity: isDragging ? 0 : 1,
      }}
      data-handler-id={handlerId}
      ref={ref}
    >
      <Grid item xs={12}>
        <List style={{ width: '100%' }} disablePadding>
          <ListItem
            ref={drag}
            onClick={(e) => {
              if (code_name && thematic?.id) {
                if (e.metaKey || e.ctrlKey) {
                  window.open(`/apprenticeships/${code_name}/${thematic?.id}/courses`, '_blank')
                } else {
                  navigate(`${thematic?.id}/courses`)
                }
              }
            }}
          >
            <ListItemIcon
              style={{
                cursor: 'grab',
                minWidth: '0px',
                marginRight: theme.spacing(1),
              }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <MdDragIndicator size={22} />
            </ListItemIcon>
            <ListItemText primary={`${thematic?.name} - ${thematic?.duration} heure(s)`} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

export default ThematicCard
