import { useState } from 'react'
import { Grid, TextField, Button, useTheme } from '@material-ui/core'
import { SaleProcessType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from 'src/utils/types'
//GQL
import { useMutation } from '@apollo/client'
import { CREATE_SALE_PROCESS_MUTATION } from '../../../gql/mutations'
//UTILS
import { handleError } from '../../../utils/handleError'
//HOOKS
import { useAlert } from '../../../hooks'

type SaleProcessFormPropsType = {
  handleClose: () => void
  refetch: () => Promise<any>
}

const SaleProcessForm = ({ handleClose, refetch }: SaleProcessFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [saleProcess, setSaleProcess] = useState<DeepPartial<SaleProcessType>>()
  const [createSaleProcess] = useMutation<{
    createSaleProcess: SaleProcessType
  }>(CREATE_SALE_PROCESS_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createSaleProcess.name} créé` })
      await refetch()
      handleClose()
    },
  })

  return (
    <Grid
      item
      container
      justifyContent="space-around"
      alignItems="center"
      style={{ height: '100%', width: '100%', zIndex: 1000 }}
      spacing={1}
    >
      <Grid style={{ width: '100%' }} container spacing={2}>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="name"
            label="Nom"
            variant="outlined"
            defaultValue={''}
            onChange={(event) => {
              setSaleProcess((prevState) => {
                return {
                  ...prevState,
                  name: event.target.value,
                }
              })
            }}
            required
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            multiline
            maxRows={3}
            minRows={3}
            id="description"
            label="Description"
            variant="outlined"
            defaultValue={''}
            onChange={(event) => {
              setSaleProcess((prevState) => {
                return {
                  ...prevState,
                  description: event.target.value,
                }
              })
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="key_salesforce"
            label="Clef SalesForce"
            variant="outlined"
            defaultValue={''}
            onChange={(event) => {
              setSaleProcess((prevState) => {
                return {
                  ...prevState,
                  key_salesforce: event.target.value,
                }
              })
            }}
            required
          />
        </Grid>
        <Grid style={{ width: '100%' }} container justifyContent="center" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                await createSaleProcess({ variables: { input: saleProcess } })
              }}
            >
              Enregistrer
            </Button>
          </Grid>
          <Grid item style={{ paddingBottom: theme.spacing(1) }}>
            <Button
              variant="outlined"
              style={{ color: theme.palette.error.main, border: `1px solid ${theme.palette.error.main}` }}
              onClick={handleClose}
            >
              Annuler
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SaleProcessForm
