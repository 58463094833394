//REACT
import { useState } from 'react'
// COMPONENTS
import { LessonForm } from '../../components/Forms/lesson'
import { CustomModal, TextEditor } from '../../components'
//ICONS
import { MdOutlineCheckCircleOutline, MdHighlightOff } from 'react-icons/md'
//MATERIAL UI
import { Grid, Chip, Typography, Paper, useTheme, makeStyles, Button, Divider } from '@material-ui/core'
//TYPES
import { LessonType } from '../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { GENERATE_LESSON_SUMMARY_MUTATION } from '../../gql/mutations'
//UTILS
import { lessonStatutMapping } from '../../utils/constants'
import { useAlert } from '../../hooks'
import { handleError } from '../../utils/handleError'

type LessonInfoPropsType = {
  lesson: LessonType
  refetch: () => Promise<any>
  hideSummary?: boolean
  disabled: boolean
  openModal?: boolean
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonInfo = ({ lesson, refetch, hideSummary = false, disabled, openModal = false }: LessonInfoPropsType) => {
  const theme = useTheme()
  const classes = useStyles()
  const [setAlert] = useAlert()
  const [updateLessonInfoModal, setUpdateLessonInfoModal] = useState(openModal)

  const [generateLessonSummary] = useMutation<{
    generateLessonSummary: LessonType
  }>(GENERATE_LESSON_SUMMARY_MUTATION, {
    onError(e) {
      const msg = handleError(e)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: 'Résumé en cours de génération' })
    },
  })

  return (
    <Paper className={classes.paper}>
      <Grid item>
        <Grid item container justifyContent="space-between">
          <Typography variant="h5">Leçon</Typography>
          <Grid item>
            {lesson && lesson.video && (
              <Button
                disabled={disabled}
                color="primary"
                variant="outlined"
                onClick={async () => {
                  await generateLessonSummary({ variables: { input: { id: lesson.id } } })
                }}
                style={{ marginRight: 5 }}
              >
                Générer résumé
              </Button>
            )}
            <Button
              disabled={disabled}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateLessonInfoModal(true)
              }}
            >
              Modifier
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
          <Divider />
        </Grid>
        <Grid item container direction="row">
          <Grid item container direction="column" xs={4}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Nom
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{lesson.name}</Typography>
            </Grid>
          </Grid>

          <Grid item container direction="column" xs={3}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Validée
              </Typography>
            </Grid>
            <Grid item>
              {lesson.validated ? (
                <MdOutlineCheckCircleOutline style={{ color: theme.palette.success.main }} size="22" />
              ) : (
                <MdHighlightOff style={{ color: theme.palette.error.main }} size="22" />
              )}
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={4}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Publié le
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {lesson.published_on
                  ? new Date(lesson.published_on).toLocaleString('fr-FR', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })
                  : 'n/a'}
              </Typography>
            </Grid>
          </Grid>

          {!hideSummary && (
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Résumé
                </Typography>
              </Grid>
              {/* key forces quill to rerender on update */}
              <Grid item key={new Date(lesson.updated_at).getTime()}>
                <TextEditor text={lesson.summary} readOnly={true} />
              </Grid>
            </Grid>
          )}
          {!hideSummary && (
            <Grid item container direction="row">
              <Grid item container direction="column" xs={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Revue résumé
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{lesson.summary_review ? lesson.summary_review : 'n/a'}</Typography>
                </Grid>
              </Grid>

              <Grid item container direction="column" xs={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Résumé requis
                  </Typography>
                </Grid>
                <Grid item>
                  {lesson.summary_required ? (
                    <MdOutlineCheckCircleOutline style={{ color: theme.palette.success.main }} size="22" />
                  ) : (
                    <MdHighlightOff style={{ color: theme.palette.error.main }} size="22" />
                  )}
                </Grid>
              </Grid>

              <Grid item container direction="column" xs={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Etat résumé
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    size="small"
                    label={lessonStatutMapping[lesson.summary_state].label}
                    style={{ backgroundColor: lessonStatutMapping[lesson.summary_state].color }}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <CustomModal
        open={updateLessonInfoModal}
        onClose={() => {
          setUpdateLessonInfoModal(false)
        }}
        animation="slide-up"
      >
        <LessonForm
          hideSummary={hideSummary}
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setUpdateLessonInfoModal(false)
          }}
        />
      </CustomModal>
    </Paper>
  )
}

export default LessonInfo
