import { LessonType } from '../../../../lib/sharedTypes'
// MATERIAL UI
import { Button, Grid, TextField } from '@material-ui/core'
import { useState } from 'react'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_LESSON_MUTATION } from '../../gql/mutations'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'

type NoteFormPropsType = {
  lesson: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const NoteForm = ({ lesson, refetch, handleClose }: NoteFormPropsType) => {
  const [stateLesson, setStateLesson] = useState<LessonType>(lesson)
  const [setAlert] = useAlert()
  const [updateLessonMutation] = useMutation<{ updateLesson: LessonType }>(UPDATE_LESSON_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `Note ajoutée à ${data.updateLesson.name}` })
      await refetch()
      handleClose()
    },
  })

  return (
    <Grid container direction="column" item spacing={2}>
      <Grid item>
        <TextField
          style={{ width: '100%' }}
          placeholder="Pense-bête"
          value={stateLesson.note}
          variant="outlined"
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
          onChange={(e) => {
            setStateLesson((prevState) => {
              return {
                ...prevState,
                note: e.target.value,
              }
            })
          }}
          multiline
          minRows={10}
          maxRows={10}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={async (event) => {
            event.stopPropagation()
            event.preventDefault()
            await updateLessonMutation({ variables: { input: { id: stateLesson.id, note: stateLesson.note } } })
          }}
        >
          Enregistrer
        </Button>
      </Grid>
    </Grid>
  )
}

export default NoteForm
