import { useEffect, useState } from 'react'
//MATERIAL UI
import { Grid, TextField, Button, Typography } from '@material-ui/core'
import { CoursePartType } from '../../../../lib/sharedTypes'
import DurationInput from '../DurationInput'

type AddPartModalPropsType = {
  type: 'part' | 'chapter' | 'lesson'
  parent: CoursePartType | null
  addPart: (parent: CoursePartType | null, partName: string, partDuration?: number) => void
  setIsPartModalOpen: (x: boolean) => void
}

const AddPartModal = ({ type, parent, addPart, setIsPartModalOpen }: AddPartModalPropsType) => {
  const [partName, setPartName] = useState<string>('')
  const [partDuration, setPartDuration] = useState<number>()
  const [label, setLabel] = useState<string>('')

  useEffect(() => {
    switch (type) {
      case 'part':
        setLabel('Nom de la partie')
        break
      case 'chapter':
        setLabel('Nom du chapitre')
        break
      case 'lesson':
        setLabel('Nom de la leçon')
        break
      default:
        console.error('Unknow part type')
        break
    }
  })

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          required
          id="outlined-multiline-flexible"
          multiline
          value={partName}
          fullWidth
          variant="outlined"
          label={label}
          placeholder={label}
          onChange={(e) => setPartName(e.target.value)}
        />
      </Grid>
      {type === 'part' && (
        <Grid item>
          <DurationInput
            format="hh:mm"
            required={true}
            label="Durée"
            defaultValue={partDuration}
            onChange={(duration) => {
              setPartDuration(duration)
            }}
          />
          <Typography variant="caption">
            Correspond à la durée déclarée auprès du financeur, par exemple sur OGDPC
          </Typography>
        </Grid>
      )}
      <Grid item container justifyContent="center">
        <Button
          disabled={!partName || (!partDuration && type === 'part')}
          variant="contained"
          color="primary"
          onClick={() => {
            addPart(parent, partName, partDuration)
            setIsPartModalOpen(false)
          }}
        >
          Ajouter
        </Button>
      </Grid>
    </Grid>
  )
}

export default AddPartModal
