// REACT
import { useState } from 'react'
//COMPONENT
import { Header } from '../../../components/Lesson'
import { CustomTable, Error, Loading, CustomModal, SearchBar, Note } from '../../../components'
import { DeleteForm } from '../../../components/Forms'
// GRAPHQL
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { COURSE_TEXT_QUERY, COURSE_LESSONS_TEXT_SEARCH } from '../../../gql/queries'
import { DELETE_LESSON_MUTATION } from '../../../gql/mutations'
//ICON
import { MdMoreVert } from 'react-icons/md'
// MATERIAL UI
import { Grid, Link, IconButton, Menu, MenuItem, Checkbox } from '@material-ui/core'
// REACT-ROUTER
import { useNavigate, useParams, useOutletContext } from 'react-router'
// TYPES
import { CourseType, SortEnum, LessonType } from '../../../../../lib/sharedTypes'
import { ColumnType } from '../../../utils/types'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../../utils/misc'

const LessonsPDF = () => {
  const navigate = useNavigate()
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [field, setField] = useState<keyof LessonType>('id')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [take, setTake] = useState<number>(10)
  const [skip, setSkip] = useState<number>(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedLessons, setSelectedLessons] = useState<number[]>([])
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<LessonType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searching, setSearching] = useState<boolean>(false)
  const { refetchCourse } = useOutletContext<{ refetchCourse: () => Promise<any> }>()
  const [deleteLessonMutation] = useMutation<{
    deleteLessonText: boolean
  }>(DELETE_LESSON_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetch()
      setAlert({ severity: 'success', content: `Leçon synthèse supprimée` })
    },
  })

  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{
    course: CourseType
  }>(COURSE_TEXT_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      lessonWhere: {
        text: {
          type: {
            eq: 'SYNTHESIS',
          },
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
    async onCompleted() {
      setSelectedLessons([])
      await refetchCourse()
    },
  })

  const [searchLessonQuery, { data: { course: courseSearch = null } = {} }] = useLazyQuery<{ course: CourseType }>(
    COURSE_LESSONS_TEXT_SEARCH,
  )

  const searchLessons = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchLessonQuery({
      variables: {
        where: {
          code_name: {
            eq: code_name,
          },
        },
        lessonWhere: {
          text: {
            type: {
              eq: 'SYNTHESIS',
            },
          },
        },
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
      },
    })
  }

  const columns: ColumnType<LessonType>[] = [
    {
      title: '',
      field: 'checkbox',
      render: (row: LessonType) => {
        return (
          <Checkbox
            checked={selectedLessons.includes(row.id)}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              if (selectedLessons.includes(row.id)) {
                setSelectedLessons((prevState) => {
                  return prevState.filter((id) => id.toString() !== row.id.toString())
                })
              } else {
                setSelectedLessons((prevState) => {
                  return [...prevState, row.id]
                })
              }
            }}
          />
        )
      },
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Nom de la leçon',
      field: 'name',
      render: (row: LessonType) => row.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Lien',
      field: 'text.name',
      render: (row: LessonType) => {
        if (row.text.s3_file) {
          return (
            <Link href={row.text.s3_file} target="_blank">
              {row.text.s3_file.split('/').at(-1)}
            </Link>
          )
        } else {
          return '-'
        }
      },
      type: 'CustomRender',
      sortable: true,
    },
    {
      title: 'Notes',
      field: 'note',
      render: (row: LessonType) => <Note lesson={row} refetch={refetch} />,
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: LessonType) => row.text.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: LessonType) => row.text.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: LessonType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (code_name === undefined) {
    return <Error error={'code_name inconnu'} />
  }

  if (error) {
    return <Error error={error} />
  }

  if (loading || !course) {
    return <Loading />
  }

  return (
    <Grid item container direction="column" justifyContent="flex-start" spacing={1} style={{ width: '100%' }}>
      <Header refetch={refetch} selectedLessons={selectedLessons} />
      <Grid item>
        <SearchBar placeholder="Rechercher une leçon synthèse" searchQuery={searchLessons} />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <CustomTable<LessonType>
          columns={columns}
          rows={searching && courseSearch ? courseSearch.lessons_search : course.lessons}
          field={field}
          sort={sort}
          take={take}
          skip={skip}
          setTake={setTake}
          setSkip={setSkip}
          setSort={setSort}
          setField={setField}
          rowsCount={searching ? courseSearch?.lessons_search.length : course.text_lessons_count}
          onRowClick={(row: LessonType, e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open(`/formations/${code_name}/lessons/synthese/${row.id}`, '_blank')
            } else {
              navigate(`/formations/${code_name}/lessons/synthese/${row.id}`)
            }
          }}
        />
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la leçon synthèse
        </MenuItem>
      </Menu>
      <CustomModal
        size="xs"
        title="Supprimer la leçon synthèse"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteLessonMutation({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default LessonsPDF
