//REACT
import { useState, useEffect, Fragment } from 'react'
//COMPONENTS
import { Layout, Title, CustomTable, Note } from '../../../components'
// ICONS
import { FaTrash } from 'react-icons/fa'
// MATERIAL UI
import {
  Grid,
  Chip,
  IconButton,
  Typography,
  Paper,
  useTheme,
  makeStyles,
  Button,
  TextField,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
// REACT-ROUTER
import { useParams, useNavigate } from 'react-router-dom'
// TYPES
import { CourseType, LessonType, SortEnum } from '../../../../../lib/sharedTypes'
import { ColumnType } from '../../../utils/types'
// GRAPHQL
import { useLazyQuery } from '@apollo/client'
//UTILS
import { secondsToDHMS } from '../../../utils/misc'
//UTILS
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { lessonStatutMapping, videosCaptions } from '../../../utils/constants'
import { COURSE_LESSONS_VIDEO_ADVANCED_SEARCH } from '../../../gql/queries'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),

    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  content: {
    margin: '0px!important',
    '&.expanded': {
      margin: '0px!important',
    },
  },
}))

type QueryType = {
  or: {
    field: string | null
    operator: string | null
    value: (OptionType | string)[]
  }[][]
}

type OptionType = {
  value: string
  title: string
}

const filter = createFilterOptions<OptionType | string>()

const LessonSearch = () => {
  const [field, setField] = useState<keyof LessonType>('id')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [take, setTake] = useState<number>(10)
  const [skip, setSkip] = useState<number>(0)
  const navigate = useNavigate()
  const { code_name } = useParams()
  const theme = useTheme()
  const classes = useStyles()
  const [accordionExpanded, setAccordionExpanded] = useState<string[]>([])
  const [expandAll, setExpandAll] = useState<boolean | null>(null)

  useEffect(() => {
    if (code_name) {
      const object = JSON.parse(localStorage.getItem(`lessons_advanced_search`) ?? '{}') as {
        [key: string]: QueryType
      }
      const query = object[code_name]
      if (query) {
        setQuery(query)
      } else {
        setAccordionExpanded(['panel-0'])
      }
    }
  }, [code_name])

  const [query, setQuery] = useState<QueryType>({
    or: [
      [
        {
          field: null,
          operator: null,
          value: [],
        },
      ],
    ],
  })

  const fields = [
    {
      title: 'Numéro',
      code: 'id',
      type: 'String',
    },
    {
      title: 'Nom de la leçon',
      code: 'name',
      type: 'String',
    },
    {
      title: 'Résumé',
      code: 'summary',
      type: 'String',
    },
    {
      title: 'Note',
      code: 'note',
      type: 'String',
    },
    {
      title: 'Transcription',
      code: 'transcription',
      type: 'String',
    },
  ]

  const operators = [
    {
      title: 'Contient',
      code: 'contains',
    },
    {
      title: 'Ne contient pas',
      code: 'not contains',
    },
    {
      title: 'Est égal à',
      code: 'equal',
    },
    {
      title: "N'est pas égal à",
      code: 'not equal',
    },
  ]

  const columns: ColumnType<LessonType>[] = [
    {
      title: 'Numéro',
      field: 'video.number',
      render: (row: LessonType) => row.video?.number,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Nom de la leçon',
      field: 'name',
      render: (row: LessonType) => row.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Durée',
      field: 'video.file_duration',
      render: (row: LessonType) => secondsToDHMS(row.video?.file_duration),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Statut leçon',
      field: 'video.review_state',
      render: (row: LessonType) => (
        <Chip
          label={videosCaptions[row.video.review_state].label}
          style={{ backgroundColor: videosCaptions[row.video.review_state].color }}
          color="primary"
        />
      ),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Statut résumé',
      field: 'summary_state',
      render: (row: LessonType) => (
        <Chip
          label={lessonStatutMapping[row.summary_state].label}
          style={{ backgroundColor: lessonStatutMapping[row.summary_state].color }}
          color="primary"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (code_name) {
              navigate(`/formations/${code_name}/lessons/video/${row.id}`, {
                state: { openModal: 'lesson_info' },
              })
            }
          }}
        />
      ),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Notes',
      field: 'lesson.note',
      render: (row: LessonType) => <Note lesson={row} refetch={refetch} />,
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Creation',
      field: 'video.created_at',
      render: (row: LessonType) => row.video.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'video.updated_at',
      render: (row: LessonType) => row.video.updated_at,
      type: 'Date',
      sortable: true,
    },
  ]

  const [searchLessonQuery, { data: { course: courseSearch = null } = {}, refetch }] = useLazyQuery<{
    course: CourseType
  }>(COURSE_LESSONS_VIDEO_ADVANCED_SEARCH)

  useEffect(() => {
    if (code_name) {
      const object = JSON.parse(localStorage.getItem(`lessons_advanced_search`) ?? '{}') as {
        [key: string]: QueryType
      }
      object[code_name] = query
      localStorage.setItem(`lessons_advanced_search`, JSON.stringify(object))
      searchLessonQuery({
        variables: {
          where: {
            code_name: {
              eq: code_name,
            },
          },
          lessonWhere: {
            video: {
              id: {
                ne: null,
              },
            },
          },
          searchObject: query,
          order: {
            field: field,
            sort: sort,
          },
        },
      })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [query])

  useEffect(() => {
    if (expandAll === true) {
      setAccordionExpanded(
        query.or.map((_, orIndex) => {
          return `panel-${orIndex}`
        }),
      )
    } else if (expandAll === false) {
      setAccordionExpanded([])
    }
  }, [expandAll])

  const filteredLessons = courseSearch?.lessons_advanced_search?.filter((_, index) => {
    if (index >= skip && index < skip + take) {
      return true
    }
    return false
  }) as LessonType[]

  return (
    <Layout title={code_name ?? ''}>
      <Grid container direction="column">
        <Grid item>
          <Title title={code_name} />
        </Grid>
        <Paper
          className={classes.paper}
          style={{
            marginBottom: theme.spacing(2),
          }}
        >
          <Typography
            variant="caption"
            color="primary"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setExpandAll(!expandAll)
            }}
          >
            {expandAll ? 'Réduire tout' : 'Développer tout'}
          </Typography>
          {query.or.map((or, orIndex) => {
            return (
              <MuiAccordion
                key={`accordion-${new Date().getTime()}-or-${orIndex}`}
                expanded={accordionExpanded.some((expanded) => expanded === `panel-${orIndex}`)}
                onChange={() => {
                  setAccordionExpanded((prevState) => {
                    if (prevState.some((expanded) => expanded === `panel-${orIndex}`)) {
                      return prevState.filter((expanded) => expanded !== `panel-${orIndex}`)
                    }
                    return [...prevState, `panel-${orIndex}`]
                  })
                }}
              >
                <AccordionSummary classes={{ content: classes.content }}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid>
                      <Typography className={classes.title}>{orIndex !== 0 ? 'OU' : 'Recherche avancée'}</Typography>
                      {!accordionExpanded.some((expanded) => expanded === `panel-${orIndex}`) && (
                        <Grid>
                          {or.map((and, andIndex, array) => {
                            return (
                              <Fragment key={`${new Date().getTime()}-and-${andIndex}`}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    container
                                    style={{
                                      gap: theme.spacing(1),
                                    }}
                                  >
                                    <Typography>
                                      {fields.find((field) => field.code === and.field)?.title ?? '-'}
                                    </Typography>

                                    <Typography>
                                      {operators
                                        .find((operator) => operator.code === and.operator)
                                        ?.title.toLowerCase() ?? '-'}
                                    </Typography>

                                    <Typography>{and.value.length > 0 ? and.value.join(' ou ') : '-'}</Typography>
                                  </Grid>
                                </Grid>
                                {andIndex !== array.length - 1 && <Typography className={classes.title}>ET</Typography>}
                              </Fragment>
                            )
                          })}
                        </Grid>
                      )}
                    </Grid>

                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        setQuery((prevState) => {
                          const query = JSON.parse(JSON.stringify(prevState)) as QueryType
                          query.or.splice(orIndex, 1)
                          return query
                        })
                      }}
                    >
                      <FaTrash />
                    </IconButton>
                  </Grid>
                </AccordionSummary>

                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(2),
                  }}
                >
                  {or.map((and, andIndex) => {
                    return (
                      <Fragment key={`${new Date().getTime()}-and-${andIndex}`}>
                        <Grid>
                          <Grid container justifyContent="space-between" alignItems="center">
                            <Typography
                              className={classes.title}
                              style={{
                                marginBottom: theme.spacing(2),
                              }}
                            >
                              {andIndex !== 0 ? 'ET' : ''}
                            </Typography>
                          </Grid>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={2}>
                              <Autocomplete
                                key={'field'}
                                id={'field'}
                                options={fields.map((field) => field.title)}
                                defaultValue={fields.find((field) => field.code === and.field)?.title}
                                onChange={(_, value) => {
                                  setQuery((prevState) => {
                                    const query = JSON.parse(JSON.stringify(prevState)) as QueryType
                                    query.or[orIndex][andIndex].field =
                                      fields.find((field) => field.title === value)?.code ?? null
                                    return query
                                  })
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Champs"
                                    placeholder="Ajouter un champ"
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={2}>
                              <Autocomplete
                                key={'operator'}
                                options={operators.map((operator) => operator.title)}
                                defaultValue={operators.find((operator) => operator.code === and.operator)?.title}
                                onChange={(_, value) =>
                                  setQuery((prevState) => {
                                    const query = JSON.parse(JSON.stringify(prevState)) as QueryType
                                    query.or[orIndex][andIndex].operator =
                                      operators.find((operator) => operator.title === value)?.code ?? null
                                    return query
                                  })
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Opérateur"
                                    placeholder="Ajouter un opérateur"
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <Autocomplete
                                multiple
                                freeSolo
                                defaultValue={and.value}
                                options={and.value}
                                onChange={(_, value) =>
                                  setQuery((prevState) => {
                                    const query = JSON.parse(JSON.stringify(prevState)) as QueryType
                                    query.or[orIndex][andIndex].value = value.map((val) =>
                                      typeof val === 'string' ? val : val.value,
                                    )
                                    return query
                                  })
                                }
                                renderTags={(value: (OptionType | string)[], getTagProps) =>
                                  value.map((option: OptionType | string, index: number) => (
                                    <Chip
                                      variant="outlined"
                                      label={typeof option === 'string' ? option : option.value}
                                      {...getTagProps({ index })}
                                      style={{ color: theme.palette.text.primary }}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Valeurs"
                                    placeholder="Ajouter une valeur"
                                  />
                                )}
                                filterOptions={(options: (string | OptionType)[], params) => {
                                  const filtered = filter(options, params)

                                  const { inputValue } = params

                                  const isExisting = options.some((option) => {
                                    if (typeof option === 'string') {
                                      return option === inputValue
                                    }
                                    return option.value === inputValue
                                  })

                                  if (inputValue !== '' && !isExisting) {
                                    filtered.push({ value: inputValue, title: `Ajouter "${inputValue}"` })
                                  }

                                  return filtered
                                }}
                                getOptionLabel={(option: OptionType | string) => {
                                  if (typeof option === 'string') {
                                    return option
                                  }

                                  return `Ajouter "${option.value}"`
                                }}
                              />
                            </Grid>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()

                                setQuery((prevState) => {
                                  const query = JSON.parse(JSON.stringify(prevState)) as QueryType
                                  query.or[orIndex].splice(andIndex, 1)
                                  return query
                                })
                              }}
                            >
                              <FaTrash />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Fragment>
                    )
                  })}
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      setQuery((prevState) => {
                        const query = JSON.parse(JSON.stringify(prevState)) as QueryType
                        query.or[orIndex].push({
                          field: null,
                          operator: null,
                          value: [],
                        })
                        return query
                      })
                    }}
                    style={{
                      width: 'max-content',
                    }}
                  >
                    ET
                  </Button>
                </AccordionDetails>
              </MuiAccordion>
            )
          })}
          <Grid item container>
            <Button
              variant="contained"
              color="default"
              onClick={() => {
                setQuery((prevState) => {
                  const query = JSON.parse(JSON.stringify(prevState)) as QueryType
                  query.or.push([
                    {
                      field: null,
                      operator: null,
                      value: [],
                    },
                  ])
                  return query
                })
                setAccordionExpanded([`panel-${query.or.length}`])
              }}
            >
              Ou
            </Button>
          </Grid>
        </Paper>

        <Grid item style={{ width: '100%' }}>
          <CustomTable<LessonType>
            columns={columns}
            rows={courseSearch?.lessons_advanced_search ? filteredLessons : []}
            field={field}
            sort={sort}
            take={take}
            skip={skip}
            setTake={setTake}
            setSkip={setSkip}
            setSort={setSort}
            setField={setField}
            rowsCount={courseSearch?.lessons_advanced_search?.length}
            onRowClick={(row: LessonType, e: React.MouseEvent) => {
              if (!code_name || !row.id) return
              if (e.metaKey || e.ctrlKey) {
                window.open(`/formations/${code_name}/lessons/video/${row.id}`, '_blank')
              } else {
                navigate(`/formations/${code_name}/lessons/video/${row.id}`)
              }
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default LessonSearch
