//REACT
import React, { useState } from 'react'
//COMPONENTS
import { Error } from '../../index'
import { FormLayout } from '../index'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, TextField } from '@material-ui/core'
//TYPES
import { ProjectType, UserType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { CALENDAR_USERS_QUERY } from '../../../gql/queries'
import { UPDATE_PROJECT_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type TeamFormPropsType = {
  project: ProjectType
  handleClose: () => void
  refetch: () => Promise<any>
}

const TeamForm = ({ project, handleClose, refetch }: TeamFormPropsType) => {
  const [setAlert] = useAlert()
  const [members, setMembers] = useState<UserType[]>([])
  const { error, data: { users } = {} } = useQuery<{ users: UserType[] }>(CALENDAR_USERS_QUERY)

  const [updateProject] = useMutation<{
    updateProject: ProjectType
  }>(UPDATE_PROJECT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Utilisateur ajouté au projet` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const projectInput = {
      id: project.id,
      course: { id: project.course.id },
      members: members?.map((member) => {
        return { id: member?.id }
      }),
    }
    await updateProject({ variables: { input: projectInput } })
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <FormLayout type="create" title="Ajouter un membre" onSubmit={handleSubmit} submitDisabled={members.length === 0}>
      <Grid item>
        {users && (
          <Autocomplete
            id="users"
            options={users.sort((a, b) => a.username.localeCompare(b.username))}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.username}
            onChange={(_, value) => {
              if (value) {
                const newMembers: UserType[] = project?.members
                newMembers?.push(value)
                setMembers(newMembers)
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" />
            }}
          />
        )}
      </Grid>
    </FormLayout>
  )
}

export default TeamForm
