import { useEffect, useState } from 'react'
// MATERIAL UI
import { Grid, MenuItem, TextField, Typography, FormGroup, FormControlLabel, Switch } from '@material-ui/core'
//COMPONENTS
import { QuizDND } from '../../../Lesson'
import { FormLayout } from '../../index'
import { Error, TextEditor } from '../../../index'
//TYPE
import { DeepPartial } from '../../../../utils/types'
import {
  LessonType,
  QuizType,
  CourseType,
  SummaryStateEnum,
  QuizCategoryEnum,
  QuizDisplayEnum,
} from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { COURSE_ID_QUERY } from '../../../../gql/queries'
import { UPDATE_QUIZ_MUTATION, CREATE_QUIZ_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//UTILS
import { handleError } from '../../../../utils/handleError'
import { quizCategories, quizDiplays } from '../../../../utils/constants'
//ROUTER
import { useParams } from 'react-router'

type QuizFormPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const QuizForm = ({ lesson, refetch, handleClose }: QuizFormPropsType) => {
  const { code_name } = useParams()
  const [setAlert] = useAlert()
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const { error: errorCourse, data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })

  useEffect(() => {
    console.log('stateLesson', stateLesson)
  }, [stateLesson])

  const [createQuizMutation] = useMutation<{
    createQuiz: QuizType
  }>(CREATE_QUIZ_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Quiz créé` })
      await refetch()
      handleClose()
    },
  })
  const [updateQuizMutation] = useMutation<{ updateQuiz: QuizType }>(UPDATE_QUIZ_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Quiz mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (errorCourse) {
    return <Error error={errorCourse} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const quizInput: Record<string, any> = {
      id: stateLesson?.quiz?.id,
      category: stateLesson?.quiz?.category || QuizCategoryEnum.NO_IMPACT,
      allow_retry: stateLesson?.quiz?.allow_retry,
      display: stateLesson?.quiz?.display,
      quiz_questions: stateLesson?.quiz?.quiz_questions?.map((qq_question, index) => {
        return {
          id: typeof qq_question?.id === 'number' ? null : qq_question?.id,
          points: qq_question?.points,
          position: index,
          lesson_question: { id: qq_question?.lesson_question?.id },
        }
      }),
    }
    console.log('staeLesson', stateLesson)

    if (stateLesson?.id) {
      console.log('UPDATE', quizInput)
      await updateQuizMutation({ variables: { input: quizInput } })
    } else {
      quizInput.lesson = {
        course: { id: course?.id },
        validated: stateLesson?.validated || false,
        summary_required: stateLesson?.summary_required || false,
        name: stateLesson?.name,
        summary_state: stateLesson?.summary_state || SummaryStateEnum.VALIDATED,
        summary: stateLesson?.summary,
      }
      console.log('CREATE', quizInput)
      await createQuizMutation({ variables: { input: quizInput } })
    }
  }

  return (
    <FormLayout
      type={lesson ? 'update' : 'create'}
      title={lesson ? 'Mettre à jour la leçon quiz' : 'Ajouter une leçon quiz'}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id ? JSON.stringify(stateLesson) === JSON.stringify(lesson) : stateLesson === undefined
      }
      md={12}
    >
      {!stateLesson?.id && (
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6">Leçon</Typography>
          </Grid>
          <Grid item container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                required
                label="Nom"
                name="name"
                value={stateLesson?.name}
                onChange={(event) => {
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      ...{ [event.target.name]: event.target.value },
                    }
                  })
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%' }} key={JSON.stringify(stateLesson?.created_at)}>
            <TextEditor
              placeholder="Ecrire le résumé de la leçon"
              text={stateLesson?.summary}
              onChange={(json) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    ...{ summary: json },
                  }
                })
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container spacing={2} alignItems="center" style={{ height: '100%' }}>
        <Grid item xs={12}>
          <Typography variant="h6">Quiz</Typography>
        </Grid>

        <Grid item container spacing={4} alignItems="center">
          <Grid item xs={4}>
            <TextField
              key={stateLesson?.quiz?.category}
              select
              fullWidth
              id="type"
              label="Type de quiz"
              variant="outlined"
              // defaultValue={stateLesson?.quiz?.category || QuizCategoryEnum.NO_IMPACT}
              value={stateLesson?.quiz?.category}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  getContentAnchorEl: null,
                },
              }}
              onChange={(event) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    ...{
                      quiz: {
                        ...prevState?.quiz,
                        ...{
                          category: event.target.value as QuizCategoryEnum,
                        },
                      },
                    },
                  }
                })
              }}
            >
              {(Object.keys(quizCategories) as Array<keyof typeof QuizCategoryEnum>).map((quizCategory, index) => (
                <MenuItem key={index} value={quizCategory}>
                  {quizCategories[quizCategory].label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              fullWidth
              key={stateLesson?.quiz?.display}
              id="display"
              label="Type d'affichage"
              variant="outlined"
              // defaultValue={stateLesson?.quiz?.display || QuizDisplayEnum.LIST}
              value={stateLesson?.quiz?.display}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  getContentAnchorEl: null,
                },
              }}
              onChange={(event) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    ...{
                      quiz: {
                        ...prevState?.quiz,
                        ...{
                          display: event.target.value as QuizDisplayEnum,
                        },
                      },
                    },
                  }
                })
              }}
            >
              {(Object.keys(quizDiplays) as Array<keyof typeof QuizDisplayEnum>).map((display, index) => (
                <MenuItem key={index} value={display}>
                  {quizDiplays[display].label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={stateLesson?.quiz?.allow_retry === undefined ? true : stateLesson?.quiz?.allow_retry}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setStateLesson((prevState) => {
                        return {
                          ...prevState,
                          quiz: {
                            ...prevState?.quiz,
                            allow_retry: event.target.checked,
                          },
                        }
                      })
                    }}
                    name="allow_retry"
                  />
                }
                label={'Permettre de réessayer le quiz'}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ height: '100%' }} key={JSON.stringify(stateLesson?.quiz?.created_at)}>
          <QuizDND
            lesson={stateLesson as LessonType}
            refetch={refetch}
            onChange={(questions) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  ...{ quiz: { ...prevState?.quiz, quiz_questions: questions } },
                }
              })
            }}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default QuizForm
