//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid, MenuItem, FormGroup, FormControlLabel, Switch } from '@material-ui/core'
//COMPONENTS
import { MultiSelectChips } from '../index'
import { FormLayout } from './index'
//TYPES
import { JobCategoryEnum, RoleCategoryEnum, UserType } from '../../../../lib/sharedTypes'
import { DeepPartial } from '../../utils/types'
//GQL
import { useMutation } from '@apollo/client'
import { CREATE_USER_MUTATION, UPDATE_USER_MUTATION } from '../../gql/mutations'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'

type UserFormPropsType = {
  user?: UserType
  refetch: () => Promise<any>
  handleClose: () => void
}

const UserForm = ({ user, refetch, handleClose }: UserFormPropsType) => {
  const [newUser, setNewUser] = useState<DeepPartial<UserType> | undefined>(user)
  const [setAlert] = useAlert()
  const [createUserMutation] = useMutation<{ createUser: UserType }>(CREATE_USER_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createUser.username} créé` })
      await refetch()
      handleClose()
    },
  })

  const [updateUserMutation] = useMutation<{ updateUser: UserType }>(UPDATE_USER_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateUser.username} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.id]: event.target.value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const userInput = { ...newUser, job: newUser?.job?.category, roles: newUser?.roles?.map((role) => role?.category) }
    if (user?.id) {
      await updateUserMutation({ variables: { input: userInput } })
    } else {
      await createUserMutation({ variables: { input: userInput } })
    }
  }

  return (
    <FormLayout
      type={newUser?.id ? 'update' : 'create'}
      title={newUser?.id ? 'Mettre à jour un utilisateur' : 'Créer un utilisateur'}
      onSubmit={handleSubmit}
      submitDisabled={newUser?.id ? newUser === user : newUser === undefined}
    >
      <Grid item container direction="row" spacing={3}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="first_name"
            label="Prénom"
            variant="outlined"
            defaultValue={newUser?.first_name}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="last_name"
            label="Nom"
            variant="outlined"
            defaultValue={newUser?.last_name}
            onChange={handleChange}
            required
          />
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id="email"
          label="Adresse email"
          variant="outlined"
          type="email"
          defaultValue={newUser?.email}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          select
          fullWidth
          id="job"
          label="Job"
          variant="outlined"
          defaultValue={newUser?.job?.category}
          value={newUser?.job?.category || ''}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
            },
          }}
          onChange={(event) => {
            setNewUser({ ...newUser, job: { category: event.target.value as JobCategoryEnum } })
          }}
          required
        >
          {Object.keys(JobCategoryEnum).map((job) => (
            <MenuItem id="job" key={job} value={job}>
              {job}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item>
        <MultiSelectChips
          multiSelectLabel="Role(s)"
          keyToUpdate="roles"
          labels={Object.keys(RoleCategoryEnum)}
          selectedLabelsList={newUser?.roles ? newUser?.roles?.map((r) => r?.category) : []}
          handleChange={(key, values) => {
            const roles = values.map((roleCat) => {
              return { category: roleCat as RoleCategoryEnum }
            })
            setNewUser({
              ...newUser,
              [key]: roles,
            })
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={newUser?.current}
                onChange={() => {
                  setNewUser({
                    ...newUser,
                    current: !newUser?.current,
                  })
                }}
                name="user.current"
              />
            }
            label={newUser?.current ? 'Employé actuel' : 'Ancien employé'}
          />
        </FormGroup>
      </Grid>

      <Grid item>
        <TextField
          fullWidth
          id="password"
          required={user?.id ? false : true}
          label="Mot de passe"
          type="password"
          variant="outlined"
          onChange={handleChange}
          helperText="Les mots de passe ne sont pas enregistrés en clair, ce qui ne permet pas d’afficher le mot de passe de cet utilisateur, mais il est possible de le changer en utilisant ce formulaire."
        />
      </Grid>
    </FormLayout>
  )
}

export default UserForm
