import { useEffect, useState } from 'react'
// TYPES
import { ColumnType } from 'src/utils/types'
import { ShootingType, SortEnum } from '../../../../lib/sharedTypes'
// COMPONENTS
import { CustomTable, Error } from '../index'
import { Grid, Typography } from '@material-ui/core'
// GQL
import { SHOOTINGS_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router'

const columns: ColumnType<ShootingType>[] = [
  {
    title: '',
    field: 'project.course.s3_banner',
    render: (row: ShootingType) => row.project.course.s3_banner,
    type: 'Image',
    sortable: false,
  },
  {
    title: 'Nom',
    field: 'project.code_name',
    render: (row: ShootingType) => {
      return (
        <Grid>
          <Typography variant="body2">{row.project.code_name}</Typography>
          <Typography variant="caption">{row.project.name}</Typography>
        </Grid>
      )
    },
    type: 'CustomRender',
    sortable: false,
  },
  {
    title: 'Localisation',
    field: 'address',
    render: (row: ShootingType) => row.address,
    type: 'String',
    sortable: false,
  },
  {
    title: 'Equipe',
    field: 'crew',
    render: (row: ShootingType) => row.crew,
    type: 'Avatars',
    sortable: false,
  },
  {
    title: 'Période',
    field: 'starts_at',
    render: (row: ShootingType) => {
      return {
        start: row.starts_at,
        end: row.ends_at,
      }
    },
    type: 'DateRange',
    sortable: false,
  },
]

const UpcomingShootings = () => {
  const navigate = useNavigate()
  const [field, setField] = useState<keyof ShootingType>('starts_at')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [take, _] = useState<number>(20)
  const [today, setToday] = useState<string>()
  const { error, data: { shootings = [] } = {} } = useQuery<{
    shootings: ShootingType[]
  }>(SHOOTINGS_QUERY, {
    variables: {
      where: {
        starts_at: {
          gte: today,
        },
      },
      take: take,
      order: {
        sort: 'ASC',
        field: 'starts_at',
      },
    },
  })

  useEffect(() => {
    setToday(new Date().toISOString())
  }, [])

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid item container xs={12} spacing={1} style={{ width: '100%' }}>
      <Grid item>
        <Typography variant="h5">{shootings ? shootings.length : 'Pas de'} tournages à venir</Typography>
      </Grid>
      {shootings && shootings.length > 0 && (
        <Grid item style={{ width: '100%' }}>
          <CustomTable<ShootingType>
            columns={columns}
            rows={shootings}
            field={field}
            sort={sort}
            take={take}
            setSort={setSort}
            setField={setField}
            rowsCount={shootings.length}
            onRowClick={(row: ShootingType, e) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(`/projects/${row.project.code_name}`, '_blank')
              } else {
                navigate(`/projects/${row.project.code_name}`)
              }
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default UpcomingShootings
