import { useState, useEffect } from 'react'
// MATERIAL UI
import {
  MenuItem,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
  useTheme,
  Button,
} from '@material-ui/core'
//ICONS
import { MdOutlineDelete, MdOutlineAddCircleOutline } from 'react-icons/md'
//COMPONENTS
import { FormLayout } from '../../index'
import { TextEditor, Error } from '../../../index'
//TYPE
import { DeepPartial } from '../../../../utils/types'
import {
  CourseType,
  LessonTaskGradingTypeEnum,
  LessonTaskItemType,
  LessonTaskTypeEnum,
  LessonType,
  LessonTaskType,
} from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { COURSE_ID_QUERY } from '../../../../gql/queries'
import { UPDATE_LESSON_TASK_MUTATION, CREATE_LESSON_TASK_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//ROUTER
import { useParams } from 'react-router'
//UTILS
import { lessonTaskTypes, lessonTaskGradingTypes } from '../../../../utils/constants'
import { handleError } from '../../../../utils/handleError'

type LessonTaskFormPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const LessonTaskForm = ({ lesson, refetch, handleClose }: LessonTaskFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [localDocument, setLocalDocument] = useState<string>()
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const pdfURL = localDocument ? localDocument : stateLesson?.task?.correction_s3_file

  const [updateLessonTaskMutation] = useMutation<{
    updateLessonTask: LessonTaskType
  }>(UPDATE_LESSON_TASK_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon tâche mise-à-jour` })
      await refetch()
      handleClose()
    },
  })
  const [createLessonTaskMutation] = useMutation<{
    createLessonText: LessonTaskType
  }>(CREATE_LESSON_TASK_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon tâche créée` })
      await refetch()
      handleClose()
    },
  })

  useEffect(() => {
    if (!selectedFile) {
      setLocalDocument(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setLocalDocument(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const { error: errorCourse, data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonTaskInput: Record<string, any> = {
      id: stateLesson?.task?.id,
      text: stateLesson?.task?.text,
      correction_s3_file: selectedFile ?? undefined,
      name: stateLesson?.task?.name,
      description: stateLesson?.task?.description,
      validation_threshold: stateLesson?.task?.validation_threshold
        ? parseInt(stateLesson.task.validation_threshold.toString())
        : 0,
      required: stateLesson?.task?.required,
      grading_type: stateLesson?.task?.grading_type,
      type: stateLesson?.task?.type,
      available_after_session_end: stateLesson?.task?.available_after_session_end,
      need_correction_file: stateLesson?.task?.need_correction_file,
      task_items: stateLesson?.task?.task_items?.map((lti) => {
        return {
          ...lti,
          id: typeof lti?.id === 'number' ? undefined : lti?.id,
          updated_at: undefined,
          created_at: undefined,
        }
      }),
    }

    if (stateLesson?.id) {
      await updateLessonTaskMutation({
        variables: {
          input: lessonTaskInput,
        },
      })
    } else {
      lessonTaskInput.lesson = {
        course: { id: course?.id },
        validated: stateLesson?.validated || false,
        summary_required: stateLesson?.summary_required || false,
        name: stateLesson?.name,
        summary_state: stateLesson?.summary_state,
        summary: stateLesson?.summary,
      }
      await createLessonTaskMutation({ variables: { input: lessonTaskInput } })
    }
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  if (errorCourse) {
    return <Error error={errorCourse} />
  }

  return (
    <FormLayout
      type={lesson ? 'update' : 'create'}
      title={lesson ? 'Mettre à jour la leçon tâche' : 'Ajouter une leçon tâche'}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id
          ? JSON.stringify(stateLesson) === JSON.stringify(lesson) && !selectedFile
          : stateLesson === undefined || !stateLesson?.task?.text
      }
    >
      <Grid item container spacing={2} alignItems="center">
        <Grid item container spacing={1} alignItems="center">
          {!stateLesson?.id && (
            <Grid item xs={12}>
              <TextField
                required
                label="Nom"
                name="name"
                defaultValue={stateLesson?.name}
                onChange={(event) => {
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      ...{ [event.target.name]: event.target.value },
                    }
                  })
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          )}
        </Grid>
        <Grid item style={{ width: '100%', height: '100%' }}>
          <TextEditor
            text={stateLesson?.task?.text}
            onChange={(json: any) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  task: {
                    ...prevState?.task,
                    text: json as JSON,
                  },
                }
              })
            }}
          />
        </Grid>
      </Grid>

      <Grid item container justifyContent="center">
        {pdfURL && (
          <object key={pdfURL} data={`${pdfURL}#view=FitV`} width="100%" height="250px">
            <p>{pdfURL}</p>
          </object>
        )}
      </Grid>
      <Grid item container direction="row" alignItems="center">
        <Typography variant="body1" style={{ marginRight: 10 }}>
          Ajouter un PDF de correction
        </Typography>
        <Button style={{ marginRight: 10 }} size="small" color="primary" variant="contained" component="label">
          Selectionner
          <input hidden accept=".pdf" type="file" onChange={onSelectFile} />
        </Button>
        {pdfURL && (
          <Button
            size="small"
            variant="outlined"
            component="label"
            onClick={() => {
              setSelectedFile(undefined)
              setLocalDocument(undefined)
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  task: {
                    ...prevState?.task,
                    correction_s3_file: undefined,
                  },
                }
              })
            }}
          >
            Supprimer
          </Button>
        )}
      </Grid>

      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            label="Nom du dépôt"
            name="name"
            value={stateLesson?.task?.name}
            onChange={(event) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  task: {
                    ...prevState?.task,
                    [event.target.name]: event.target.value,
                  },
                }
              })
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            minRows={2}
            maxRows={2}
            label="Description"
            name="description"
            value={stateLesson?.task?.description}
            onChange={(event) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  task: {
                    ...prevState?.task,
                    [event.target.name]: event.target.value,
                  },
                }
              })
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            label="Seuil de validation (0 - 100)"
            name="validation_threshold"
            value={stateLesson?.task?.validation_threshold}
            onChange={(event) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  task: {
                    ...prevState?.task,
                    [event.target.name]: event.target.value,
                  },
                }
              })
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={6}>
            <TextField
              key={stateLesson?.task?.type}
              select
              fullWidth
              id="type"
              label="Type de tâche"
              variant="outlined"
              value={stateLesson?.task?.type}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  getContentAnchorEl: null,
                },
              }}
              onChange={(event) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    ...{
                      task: {
                        ...prevState?.task,
                        ...{
                          type: event.target.value as LessonTaskTypeEnum,
                        },
                      },
                    },
                  }
                })
              }}
            >
              {(Object.keys(lessonTaskTypes) as Array<keyof typeof LessonTaskTypeEnum>).map((lessonTaskType, index) => (
                <MenuItem key={index} value={lessonTaskType}>
                  {lessonTaskTypes[lessonTaskType].label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              key={stateLesson?.task?.grading_type}
              select
              fullWidth
              id="grading_type"
              label="Type de notation"
              variant="outlined"
              value={stateLesson?.task?.grading_type}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  getContentAnchorEl: null,
                },
              }}
              onChange={(event) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    ...{
                      task: {
                        ...prevState?.task,
                        ...{
                          grading_type: event.target.value as LessonTaskGradingTypeEnum,
                        },
                      },
                    },
                  }
                })
              }}
            >
              {(Object.keys(lessonTaskGradingTypes) as Array<keyof typeof LessonTaskGradingTypeEnum>).map(
                (lessonTaskGradingType, index) => (
                  <MenuItem key={index} value={lessonTaskGradingType}>
                    {lessonTaskGradingTypes[lessonTaskGradingType].label}
                  </MenuItem>
                ),
              )}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={stateLesson?.task?.required || false}
                    onClick={() => {
                      setStateLesson((prevState) => {
                        return {
                          ...prevState,
                          ...{
                            task: {
                              ...prevState?.task,
                              ...{
                                required: !stateLesson?.task?.required,
                              },
                            },
                          },
                        }
                      })
                    }}
                  />
                }
                label={
                  stateLesson?.task?.required ? 'Cette leçon est obligatoire' : "Cette leçon n'est pas obligatoire"
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={stateLesson?.task?.available_after_session_end || false}
                    onClick={() => {
                      setStateLesson((prevState) => {
                        return {
                          ...prevState,
                          ...{
                            task: {
                              ...prevState?.task,
                              ...{
                                available_after_session_end: !stateLesson?.task?.available_after_session_end,
                              },
                            },
                          },
                        }
                      })
                    }}
                  />
                }
                label={
                  stateLesson?.task?.available_after_session_end
                    ? 'Cette leçon est disponible après la fin de la session'
                    : "Cette leçon n'est pas disponible après la fin de la session"
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={stateLesson?.task?.need_correction_file || false}
                    onClick={() => {
                      setStateLesson((prevState) => {
                        return {
                          ...prevState,
                          ...{
                            task: {
                              ...prevState?.task,
                              ...{
                                need_correction_file: !stateLesson?.task?.need_correction_file,
                              },
                            },
                          },
                        }
                      })
                    }}
                  />
                }
                label={
                  stateLesson?.task?.need_correction_file
                    ? "Cette leçon nécessite au correcteur d'ajouter un fichier de correction"
                    : "Cette leçon ne nécessite pas au correcteur d'ajouter un fichier de correction"
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item>
            <Typography variant="h6">Fichier(s)</Typography>
          </Grid>
          <IconButton
            onClick={() => {
              const items = stateLesson?.task?.task_items || []
              items.push({
                id: new Date().getTime(),
                name: '',
                position: items?.length ? items?.length + 1 : 0,
                required: true,
                max_size: 1,
              } as LessonTaskItemType)
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  task: {
                    ...prevState?.task,
                    task_items: items,
                  },
                }
              })
            }}
          >
            <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Grid>
        <Grid container item xs={12} direction="row" style={{ paddingLeft: theme.spacing(2) }}>
          {stateLesson?.task?.task_items?.map((item) => {
            return (
              <Grid
                key={item?.id}
                container
                direction="row"
                alignItems="center"
                spacing={2}
                style={{
                  marginBottom: theme.spacing(2),
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: theme.shape.borderRadius,
                }}
              >
                <Grid item xs={11} container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      size="small"
                      label="Nom"
                      name="name"
                      value={item?.name}
                      onChange={(event) => {
                        const newLessonTaskItems = stateLesson.task?.task_items?.map((lti) => {
                          if (lti?.id === item?.id) {
                            return {
                              ...lti,
                              name: event.target.value,
                            }
                          }
                          return lti
                        })
                        setStateLesson((prevState) => {
                          return {
                            ...prevState,
                            task: {
                              ...prevState?.task,
                              task_items: newLessonTaskItems,
                            },
                          }
                        })
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        type="number"
                        InputProps={{ inputProps: { min: 1, max: 100 } }}
                        label="Taille maximum du fichier en MB"
                        name="max_size"
                        value={item?.max_size || 1}
                        onChange={(event) => {
                          const newLessonTaskItems = stateLesson.task?.task_items?.map((lti) => {
                            if (lti?.id === item?.id) {
                              return {
                                ...lti,
                                max_size: parseInt(event.target.value),
                              }
                            }
                            return lti
                          })
                          setStateLesson((prevState) => {
                            return {
                              ...prevState,
                              task: {
                                ...prevState?.task,
                                task_items: newLessonTaskItems,
                              },
                            }
                          })
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} container justifyContent="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={item?.required}
                              onClick={() => {
                                const newLessonTaskItems = stateLesson.task?.task_items?.map((lti) => {
                                  if (lti?.id === item?.id) {
                                    return {
                                      ...lti,
                                      required: !item?.required,
                                    }
                                  }
                                  return lti
                                })
                                setStateLesson((prevState) => {
                                  return {
                                    ...prevState,
                                    task: {
                                      ...prevState?.task,
                                      task_items: newLessonTaskItems,
                                    },
                                  }
                                })
                              }}
                            />
                          }
                          label={item?.required ? 'Ce fichier est obligatoire' : "Ce fichier n'est pas obligatoire"}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setStateLesson((prevState) => {
                        return {
                          ...prevState,
                          task: {
                            ...prevState?.task,
                            task_items: prevState?.task?.task_items?.filter((lti) => {
                              return lti?.id !== item?.id
                            }),
                          },
                        }
                      })
                    }}
                  >
                    <MdOutlineDelete size={24} />
                  </IconButton>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default LessonTaskForm
