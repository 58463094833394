import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
// TYPE
import { CourseType, VirtualClassRoomType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import {
  CREATE_VIRTUAL_CLASSROOM_ASSIGNMENT_MUTATION,
  UPDATE_VIRTUAL_CLASSROOM_ASSIGNMENT_MUTATION,
} from '../../../gql/mutations'
//UTILS
import { useAlert } from '../../../hooks'
import { handleError } from '../../../utils/handleError'
import { DeepPartial } from 'src/utils/types'

type VirtualClassRoomFormPropsType = {
  course: CourseType
  virtual_classroom?: VirtualClassRoomType
  refetch: () => Promise<any>
  handleClose: () => void
}

const VirtualClassRoomForm = ({ course, virtual_classroom, refetch, handleClose }: VirtualClassRoomFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateVirtualClassRoom, setStateVirtualClassRoom] = useState<DeepPartial<VirtualClassRoomType> | undefined>(
    virtual_classroom,
  )
  const [createVirtualClassRoomMutation] = useMutation<{ createVirtualClassRoom: VirtualClassRoomType }>(
    CREATE_VIRTUAL_CLASSROOM_ASSIGNMENT_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Classe virtuelle créée` })
        await refetch()
        handleClose()
      },
    },
  )
  const [updateVirtualClassRoomMutation] = useMutation<{ updateVirtualClassRoom: VirtualClassRoomType }>(
    UPDATE_VIRTUAL_CLASSROOM_ASSIGNMENT_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Classe virtuelle mise-à-jour` })
        await refetch()
        handleClose()
      },
    },
  )

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (stateVirtualClassRoom?.id) {
      await updateVirtualClassRoomMutation({ variables: { input: stateVirtualClassRoom } })
    } else {
      await createVirtualClassRoomMutation({
        variables: { input: { ...stateVirtualClassRoom, course: { id: course.id } } },
      })
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateVirtualClassRoom((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.id]: event.target.id === 'duration' ? parseInt(event.target.value) : event.target.value },
      }
    })
  }

  return (
    <FormLayout
      type={virtual_classroom ? 'update' : 'create'}
      title={virtual_classroom ? 'Mettre à jour la classe virtuelle' : 'Ajouter une classe virtuelle'}
      onSubmit={handleSubmit}
      submitDisabled={
        virtual_classroom
          ? !stateVirtualClassRoom
          : JSON.stringify(virtual_classroom) === JSON.stringify(stateVirtualClassRoom)
      }
    >
      <Grid item container spacing={2}>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="name"
            label="Nom"
            variant="outlined"
            value={stateVirtualClassRoom?.name}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TextField
            multiline
            minRows={2}
            maxRows={2}
            fullWidth
            id="description"
            label="Description"
            variant="outlined"
            value={stateVirtualClassRoom?.description}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="duration"
            label="Durée"
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={stateVirtualClassRoom?.duration}
            onChange={handleChange}
            required
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default VirtualClassRoomForm
