import { useState } from 'react'
import { DurationInput } from '..'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, Typography, TextField, Button, makeStyles, alpha, Switch, FormControlLabel } from '@material-ui/core'
// TYPE
import {
  FundingOrganismType,
  CatalogType,
  ProductCatalogFoType,
  ProductType,
  SaleProcessType,
} from '../../../../lib/sharedTypes'
// GQL
import { PRODUCT_CATALOGS_FO_QUERY, SALE_PROCESSES_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
//COMPONENTS
import CustomModal from '../CustomModal'
import CatalogRow from './CatalogRow'
//UTILS
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  deleteBTN: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.1),
    },
  },
}))

type CatalogFundingOrganismTablePropsType = {
  product: ProductType
  type: 'display' | 'edit'
  onChange: (product: ProductType) => void
}

const CatalogFundingOrganismTable = ({ product, type, onChange }: CatalogFundingOrganismTablePropsType) => {
  const classes = useStyles()
  const [errors, setErrors] = useState({
    code_name: '',
  })
  const [modal, setModal] = useState(false)
  const [editingCFO, setEditingCFO] = useState<ProductCatalogFoType | undefined>()
  const { data: { catalogs, fundingOrganisms } = {} } = useQuery<{
    catalogs: CatalogType[]
    fundingOrganisms: FundingOrganismType[]
  }>(PRODUCT_CATALOGS_FO_QUERY)

  const { error: errorsaleProcesses, data: { saleProcesses } = {} } = useQuery<{ saleProcesses: SaleProcessType[] }>(
    SALE_PROCESSES_QUERY,
  )

  const generateDefaultAliasCodeName = () => {
    let code_name = `${product.code_name}`
    const fo_code = fundingOrganisms?.filter((fo) => fo.id == editingCFO?.funding_organism.id)[0].code
    if (fo_code) {
      code_name = `${code_name}:${fo_code}`
    }

    const p_code = editingCFO?.profession?.code
    if (p_code) {
      code_name = `${code_name}:${p_code}`
      return code_name.toLowerCase()
    }
    const c_code = catalogs?.filter((c) => c.id == editingCFO?.catalog.id)[0].code
    if (c_code) {
      code_name = `${code_name}:${c_code}`
    }

    return code_name.toLowerCase()
  }

  if (errorsaleProcesses || !saleProcesses) {
    return <></>
  }

  if (!fundingOrganisms) {
    return <></>
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={2}></Grid>
      <Grid item container wrap="nowrap" xs={10}>
        {fundingOrganisms?.map((fo) => {
          return (
            <Grid
              item
              key={fo.id}
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                width: `${Math.round(100 / fundingOrganisms.length)}%`,
              }}
            >
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {fo.name.toUpperCase()}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
      <Grid container direction="column">
        {catalogs
          ?.filter((catalog) => {
            return product.product_catalog_positions.map((pcp) => pcp.catalog.id).includes(catalog.id)
          })
          ?.map((catalog, index) => {
            return (
              <CatalogRow
                key={catalog.id}
                catalog={catalog}
                product={product}
                index={index}
                type={type}
                onChange={onChange}
                setEditingCFO={setEditingCFO}
                setModal={setModal}
                fundingOrganisms={fundingOrganisms}
              />
            )
          })}
      </Grid>
      <CustomModal
        title={
          editingCFO
            ? `${editingCFO.catalog.name} - ${editingCFO.funding_organism.name}${
                editingCFO?.profession?.name ? ` - ${editingCFO?.profession?.name}` : ''
              }`
            : 'Personnaliser'
        }
        size="sm"
        open={modal}
        onClose={() => {
          setModal(false)
          setEditingCFO(undefined)
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid item>
              <FormControlLabel
                control={<Switch color="primary" checked={editingCFO?.is_alias === true} />}
                onClick={() => {
                  setEditingCFO((prevState) => {
                    if (!prevState) return prevState
                    return {
                      ...prevState,
                      is_alias: !prevState.is_alias,
                      code_name: !prevState.code_name ? generateDefaultAliasCodeName() : prevState.code_name,
                    }
                  })
                }}
                label={
                  <>
                    <Typography variant="body1">
                      {editingCFO?.is_alias === true
                        ? 'Cette version est un alias'
                        : 'Cette version correspond à une autre formation sur le LMS'}
                    </Typography>
                  </>
                }
              />
              <Grid item>
                {editingCFO?.is_alias === true ? (
                  <div style={{ marginBottom: '1rem' }}>
                    <Typography variant="body2" style={{ marginBottom: '.5rem' }}>
                      Les ventes seront enregistrées avec le code ci-dessous mais les sessions suivies seront associées
                      au produit parent (<code>{product.code_name}</code>).
                    </Typography>
                    <Typography variant="body2">
                      <i>Exemple : les formations inter-pro FIFPL qui partage la même structure</i>
                    </Typography>
                  </div>
                ) : (
                  <div style={{ marginBottom: '1rem' }}>
                    <Typography variant="body2" style={{ marginBottom: '.5rem' }}>
                      Les ventes et les sessions seront enregistrées avec le produit indiqué ci-dessous.{' '}
                      <b>Ce produit doit avoir été créé manuellement sur le LMS.</b>
                    </Typography>
                    <Typography variant="body2">
                      <i>
                        Exemple : les formations DPC 2325 qui ont une structure différente générée manuellement à partir
                        des versions standard.
                      </i>
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              name="code_name"
              label="Nom de code"
              variant="outlined"
              value={editingCFO?.code_name || ''}
              onChange={(event) => {
                setEditingCFO((prevState) => {
                  if (!prevState) return prevState
                  return {
                    ...prevState,
                    code_name: event.target.value,
                  }
                })
              }}
              onBlur={(e) => {
                if (e.target.value.toLowerCase() === product.code_name.toLowerCase() || e.target.value === '') {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      code_name: 'Veuillez entrer un nom de code différent de celui du produit',
                    }
                  })
                } else {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      code_name: '',
                    }
                  })
                }
              }}
              error={Boolean(errors.code_name)}
              helperText={errors.code_name}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              label="Prix affiché"
              type="number"
              name="listing_price"
              defaultValue={editingCFO?.listing_price || ''}
              onChange={(event) => {
                setEditingCFO((prevState) => {
                  if (!prevState) return prevState
                  return {
                    ...prevState,
                    listing_price: parseFloat(event.target.value),
                  }
                })
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Prix minimum"
              type="number"
              name="minimum_selling_price"
              defaultValue={editingCFO?.minimum_selling_price || ''}
              onChange={(event) => {
                setEditingCFO((prevState) => {
                  if (!prevState) return prevState
                  return {
                    ...prevState,
                    minimum_selling_price: parseFloat(event.target.value),
                  }
                })
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item>
            <DurationInput
              format="hh"
              label="Durée du produit"
              defaultValue={editingCFO?.duration || 0}
              onChange={(duration) => {
                setEditingCFO((prevState) => {
                  if (!prevState) return prevState
                  return {
                    ...prevState,
                    duration: duration,
                  }
                })
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              name="release_date"
              label="Date de sortie"
              type="date"
              defaultValue={
                editingCFO?.release_date ? moment(editingCFO?.release_date).format('YYYY-MM-DD') : undefined
              }
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={(e) => {
                setEditingCFO((prevState) => {
                  if (!prevState) return prevState
                  return {
                    ...prevState,
                    release_date: new Date(e.target.value),
                  }
                })
              }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              fullWidth
              multiple={true}
              defaultValue={editingCFO?.sale_processes}
              options={saleProcesses}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option?.name || ''}
              onChange={(_, value) => {
                if (value) {
                  setEditingCFO((prevState) => {
                    if (!prevState) return prevState
                    return {
                      ...prevState,
                      sale_processes: value,
                    }
                  })
                }
              }}
              renderInput={(params) => {
                return <TextField {...params} variant="outlined" label="Processus de vente" />
              }}
            />
          </Grid>
          <Grid item container justifyContent="center" alignItems="center" spacing={4}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                disabled={!Object.values(errors).every((val) => val === '') || !editingCFO?.code_name}
                onClick={() => {
                  const newCFOS = product.catalog_funding_organisms.map((cfo) => {
                    if (cfo.id === editingCFO?.id) {
                      return editingCFO
                    } else {
                      return cfo
                    }
                  })
                  onChange({
                    ...product,
                    catalog_funding_organisms: newCFOS,
                  })
                  setModal(false)
                  setEditingCFO(undefined)
                }}
              >
                Valider
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className={classes.deleteBTN}
                onClick={() => {
                  setModal(false)
                  setEditingCFO(undefined)
                  setErrors({ code_name: '' })
                }}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </Grid>
  )
}

export default CatalogFundingOrganismTable
