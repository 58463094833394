// import { useCallback } from 'react'
// MATERIAL UI
import { Grid, useTheme } from '@material-ui/core'
// TYPE
import { DeepPartial } from '../../../utils/types'
import { LessonQuestionType, GapfillItemType } from '../../../../../lib/sharedTypes'
import { TextEditorJS } from '../../../components'
//HOOKS
import { useAlert } from '../../../hooks'

type GapFillQuestionFormInputType = {
  stateQuestion: DeepPartial<LessonQuestionType> | undefined
  setStateQuestion: React.Dispatch<React.SetStateAction<DeepPartial<LessonQuestionType> | undefined>>
  setErrorCount: React.Dispatch<React.SetStateAction<number>>
}

const GapFillQuestionForm = ({ stateQuestion, setStateQuestion, setErrorCount }: GapFillQuestionFormInputType) => {
  const [setAlert] = useAlert()
  const theme = useTheme()
  type BlockType = {
    id: string
    type: string
    data: { text: string; level?: number }
    tunes: { textVariant: string }
  }

  type Content = {
    blocks: BlockType[]
    time: number
    version: string
  }

  const parseContent = (content: Content) => {
    let html = ''
    const items: DeepPartial<GapfillItemType>[] = []
    let errorCount = 0
    for (let index = 0; index < content?.blocks?.length; index++) {
      const block = content.blocks[index]
      const paragraph = document.createElement('p')
      paragraph.innerHTML = block.data.text
      console.log(paragraph.querySelectorAll('span').length)
      Array.from(paragraph.querySelectorAll('span')).map((span) => {
        const id = span.getAttribute('id')
        if (id) {
          const propositions = span.getAttribute('data-tooltip')?.split(',')
          if (
            propositions &&
            propositions?.filter((p) => {
              return p.trim() === span.innerText.trim().toString()
            }).length !== 1
          ) {
            errorCount += 1
            setAlert({ severity: 'error', content: 'Les propositions doivent contenir la réponse!' })
          }
          propositions?.map((p, index) => {
            if (p !== 'undefined') {
              const existingItem = stateQuestion?.gapfill_question?.gapfill_items?.filter((gapfill_item) => {
                return (
                  gapfill_item?.element_id?.toString() === id.toString() &&
                  gapfill_item?.value?.replaceAll('\n', ' ').trim() === p.replaceAll('\n', ' ').trim()
                )
              })
              if (p.trim().length !== 0) {
                items.push({
                  id: existingItem?.[0]?.id,
                  position: index,
                  value: p.replaceAll('\n', ' ').trim(),
                  is_correct: span.innerText.trim().toString() === p.trim().toString(),
                  element_id: id,
                  strict: true,
                })
              }
            }
          })
        }
      })
      html = html + '\n' + `<p>${paragraph.innerHTML}</p>`
    }
    setErrorCount(errorCount)
    return { text: `<div>${html}</div>`, items: items }
  }

  return (
    <Grid
      item
      xs={12}
      style={{
        borderRadius: 5,
        margin: theme.spacing(1),
      }}
    >
      <Grid item style={{ width: '100%', height: '100%' }}>
        <TextEditorJS
          holderId={`gapfill_question_form`}
          type="gapfill"
          content={stateQuestion?.gapfill_question?.text}
          onChange={(content: any) => {
            const { text, items } = parseContent(content as Content)
            setStateQuestion((prevState) => {
              return {
                ...prevState,
                gapfill_question: {
                  ...prevState?.gapfill_question,
                  text: text,
                  gapfill_items: items,
                },
              }
            })
          }}
          legacy={stateQuestion?.gapfill_question?.text}
        />
      </Grid>
    </Grid>
  )
}
export default GapFillQuestionForm
