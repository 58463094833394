import { useEffect, useState } from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'
//REDUX
import { useAppDispatch } from '../hooks/reducerHooks'
import { AlertAction } from '../store/reducers/alertReducer'

type AlertPropsType = {
  severity: 'success' | 'info' | 'warning' | 'error'
  content: string
}

const Alert = ({ severity, content }: AlertPropsType) => {
  const [open, setOpen] = useState<boolean>(true)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setOpen(true)
  }, [severity, content])

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    dispatch(AlertAction({ severity: 'info', content: '' }))
  }

  if (!content) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <MuiAlert
        style={{ minWidth: 250 }}
        severity={severity}
        variant="filled"
        onClose={(event) => {
          handleClose(event, '')
        }}
      >
        {content}
      </MuiAlert>
    </Snackbar>
  )
}

export default Alert
