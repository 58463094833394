/* eslint-disable */
import { useCallback, useRef, useState } from 'react'
// MATERIAL UI
import { Checkbox, Divider, Grid, IconButton, TextField, Typography, useTheme } from '@material-ui/core'
//ICONS
import { MdOutlineAddCircleOutline, MdOutlineDelete } from 'react-icons/md'
// COMPONENTS
import { Error } from '../../index'
import { FormLayout } from '../index'
// TYPE
import { ProductType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'
//UTILS
import { ProductFormationSteps } from 'src/utils/types'
import { handleError } from '../../../utils/handleError'
//DND
import { DndProvider, useDrag, useDrop, XYCoord } from 'react-dnd'
import { Identifier } from 'dnd-core'
import { HTML5Backend } from 'react-dnd-html5-backend'

type FormationStepsFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const FormationStepsForm = ({ product, refetch, handleClose }: FormationStepsFormPropsType) => {
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: stateProduct.id,
      formation_steps: (stateProduct.formation_steps as ProductFormationSteps[]).map((step, i) => {
        return {
          ...step,
          position: i,
        }
      }),
    }
    await updateProductMutation({ variables: { input: productInput } })
  }

  const swap = (arr: any[] | undefined, from: number, to: number) => {
    if (!arr) return
    if (!arr[to] || !arr[from]) return arr
    const temp = arr[from]
    arr[from] = arr[to]
    arr[to] = temp
    return arr
  }

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number, item: any) => {
      switch (item.type) {
        case 'FORMATION_STEP_CARD':
          const formation_steps = swap(stateProduct.formation_steps as ProductFormationSteps[], dragIndex, hoverIndex)
          setStateProduct((prevState) => {
            return {
              ...prevState,
              formation_steps: (formation_steps as ProductFormationSteps[])?.map((step, i) => {
                return {
                  ...step,
                  position: i,
                }
              }),
            }
          })
          break
        default:
          break
      }
    },
    [stateProduct, setStateProduct],
  )
  console.log(JSON.stringify(stateProduct.formation_steps), JSON.stringify(product.formation_steps))

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les étapes de formation"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProduct.formation_steps) === JSON.stringify(product.formation_steps)}
    >
      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h6">Étapes de formation</Typography>
        </Grid>
        <IconButton
          onClick={() => {
            const formation_steps = (stateProduct.formation_steps as ProductFormationSteps[]) ?? []
            formation_steps.push({
              id: new Date().getTime(),
              name: '',
              duration: '',
              is_online: false,
              is_pause: false,
            } as ProductFormationSteps)

            setStateProduct((prevState) => {
              return {
                ...prevState,
                formation_steps: formation_steps,
              }
            })
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Grid>
      <Grid item container direction="column" spacing={2} ref={animateRef}>
        <DndProvider backend={HTML5Backend}>
          {(stateProduct.formation_steps as ProductFormationSteps[])?.map((formation_step, index) => {
            return (
              <FormationStepCard
                formation_step={formation_step}
                index={index}
                setStateProduct={setStateProduct}
                stateProduct={stateProduct}
                moveCard={moveCard}
                key={formation_step.id}
              />
            )
          })}
        </DndProvider>
      </Grid>
    </FormLayout>
  )
}

type DragItem = {
  index: number
  id: string
  type: string
}

const FormationStepCard = ({
  formation_step,
  stateProduct,
  setStateProduct,
  index,
  moveCard,
}: {
  formation_step: ProductFormationSteps
  stateProduct: ProductType
  setStateProduct: React.Dispatch<React.SetStateAction<ProductType>>
  index: number
  moveCard: (dragIndex: number, hoverIndex: number, item: any) => void
}) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'FORMATION_STEP_CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, item)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'FORMATION_STEP_CARD',
    item: { item: formation_step, index: formation_step.id, type: 'FORMATION_STEP_CARD' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  drag(drop(ref))

  return (
    <Grid
      item
      container
      direction="column"
      style={{
        opacity: isDragging ? 0 : 1,
      }}
      data-handler-id={handlerId}
      ref={ref}
    >
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography>{`Étape #${index + 1}`}</Typography>
        </Grid>

        <Grid item>
          <IconButton
            color="primary"
            onClick={() => {
              setStateProduct((prevState) => {
                return {
                  ...prevState,
                  formation_steps: (prevState.formation_steps as ProductFormationSteps[]).filter((step) => {
                    return step.id !== formation_step.id
                  }),
                }
              })
            }}
          >
            <MdOutlineDelete size={24} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid style={{ marginBottom: theme.spacing(2) }}>
        <Divider />
      </Grid>
      <Grid item container spacing={1} direction="row">
        <Grid item container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label={`Nom`}
              name="name"
              value={formation_step.name}
              onChange={(e) => {
                e.preventDefault()

                const formation_steps = (stateProduct.formation_steps as ProductFormationSteps[]).map((job, i) => {
                  if (i === index) {
                    return {
                      ...job,
                      name: e.target.value,
                    }
                  } else {
                    return job
                  }
                })

                setStateProduct((prevState) => {
                  return {
                    ...prevState,
                    formation_steps: formation_steps,
                  }
                })
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={`Durée`}
              name="duration"
              value={formation_step.duration}
              onChange={(e) => {
                e.preventDefault()

                const formation_steps = (stateProduct.formation_steps as ProductFormationSteps[]).map((job, i) => {
                  if (i === index) {
                    return {
                      ...job,
                      duration: e.target.value,
                    }
                  } else {
                    return job
                  }
                })

                setStateProduct((prevState) => {
                  return {
                    ...prevState,
                    formation_steps: formation_steps,
                  }
                })
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item container alignItems="center">
            <Typography>En ligne</Typography>
            <Checkbox
              checked={formation_step.is_online}
              onChange={(e) => {
                const formation_steps = (stateProduct.formation_steps as ProductFormationSteps[]).map((job, i) => {
                  if (i === index) {
                    return {
                      ...job,
                      is_online: e.target.checked,
                    }
                  } else {
                    return job
                  }
                })

                setStateProduct((prevState) => {
                  return {
                    ...prevState,
                    formation_steps: formation_steps,
                  }
                })
              }}
            />
          </Grid>

          <Grid item container alignItems="center">
            <Typography>Pause</Typography>
            <Checkbox
              checked={formation_step.is_pause}
              onChange={(e) => {
                const formation_steps = (stateProduct.formation_steps as ProductFormationSteps[]).map((job, i) => {
                  if (i === index) {
                    return {
                      ...job,
                      is_pause: e.target.checked,
                    }
                  } else {
                    return job
                  }
                })

                setStateProduct((prevState) => {
                  return {
                    ...prevState,
                    formation_steps: formation_steps,
                  }
                })
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormationStepsForm
