import { ApolloError } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
// import { AlertAction } from '../store/reducers/alertReducer'

export const handleError = (error: ApolloError): string => {
  if (error.networkError) {
    if ((error.networkError as Record<string, any>).statusCode === 413) {
      return 'Fichier trop volumineux!'
    } else {
      const networkError = error.networkError as NetworkError
      const networkGraphQLErrorsPresent =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        networkError && 'result' in networkError && networkError.result.errors.length > 0
      if (networkGraphQLErrorsPresent) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return networkError.result.errors[0].message
      }
    }
  }
  // store.dispatch(AlertAction({ severity: 'error', content: 'Fichier trop volumineux!' }))
  if (error.graphQLErrors.length !== 0) {
    return error.graphQLErrors[0].message
  }
  if (error.message) {
    return error.message
  }
  return 'Erreur inconnue'
}
