import { useState } from 'react'
// MATERIAL UI
import { Divider, Grid, IconButton, TextField, Typography, useTheme } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
// TYPE
import { CatalogType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_CATALOG_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { CatalogOpportunity } from 'src/utils/types'
import { MdOutlineAddCircleOutline, MdOutlineDelete } from 'react-icons/md'

type OpportunityFormPropsType = {
  catalog: CatalogType
  refetch: () => Promise<any>
  handleClose: () => void
}

const OpportunityForm = ({ catalog, refetch, handleClose }: OpportunityFormPropsType) => {
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [setAlert] = useAlert()
  const theme = useTheme()
  const [stateCatalog, setStateCatalog] = useState<CatalogType>(catalog)

  const [updateCatalog] = useMutation<{ updateCatalog: CatalogType }>(UPDATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCatalog.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCatalog((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const catalogInput = {
      id: stateCatalog.id,
      opportunity_title: stateCatalog.opportunity_title,
      opportunity_text: stateCatalog.opportunity_text,
      opportunity_list: stateCatalog.opportunity_list,
    }
    await updateCatalog({ variables: { input: catalogInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les informations des opportunités"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateCatalog) === JSON.stringify(catalog)}
    >
      <Grid item>
        <TextField
          fullWidth
          name="opportunity_title"
          label="Titre du bloc opportunité"
          variant="outlined"
          value={stateCatalog?.opportunity_title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="opportunity_text"
          label="Description du bloc opportunité"
          variant="outlined"
          value={stateCatalog?.opportunity_text || ''}
          onChange={handleChange}
          required
          multiline
        />
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h6">Opportunités</Typography>
        </Grid>
        <IconButton
          onClick={() => {
            const opportunity_list = (stateCatalog.opportunity_list ?? []) as CatalogOpportunity[]
            opportunity_list.push({
              id: new Date().getTime(),
              name: '',
              href: '',
            } as CatalogOpportunity)

            setStateCatalog((prevState) => {
              return {
                ...prevState,
                opportunity_list: opportunity_list,
              }
            })
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Grid>
      <Grid item container direction="column" spacing={2} ref={animateRef}>
        {stateCatalog.opportunity_list &&
          (stateCatalog.opportunity_list as CatalogOpportunity[]).map((opportunity, index) => {
            return (
              <Grid key={index} item container direction="column">
                <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography>{`Opportunité #${index + 1}`}</Typography>
                  </Grid>

                  <Grid item>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setStateCatalog((prevState) => {
                          return {
                            ...prevState,
                            opportunity_list: (prevState.opportunity_list as CatalogOpportunity[]).filter((sk) => {
                              return sk.id !== opportunity.id
                            }),
                          }
                        })
                      }}
                    >
                      <MdOutlineDelete size={24} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: theme.spacing(2) }}>
                  <Divider />
                </Grid>
                <Grid item container direction="row" spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      label={`Nom`}
                      name="name"
                      value={opportunity.name}
                      onChange={(e) => {
                        e.preventDefault()
                        const opportunity_list = (stateCatalog.opportunity_list as CatalogOpportunity[]).map(
                          (opportunity, i) => {
                            if (i === index) {
                              return {
                                ...opportunity,
                                name: e.target.value,
                              }
                            } else {
                              return opportunity
                            }
                          },
                        )

                        setStateCatalog((prevState) => {
                          return {
                            ...prevState,
                            opportunity_list: opportunity_list,
                          }
                        })
                      }}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={5}
                      maxRows={5}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Lien"
                      name="opportunity_list"
                      value={opportunity.href}
                      onChange={(e) => {
                        e.preventDefault()
                        const opportunity_list = (stateCatalog.opportunity_list as CatalogOpportunity[]).map(
                          (oppportunity, i) => {
                            if (i === index) {
                              return {
                                ...oppportunity,
                                href: e.target.value,
                              }
                            } else {
                              return oppportunity
                            }
                          },
                        )

                        setStateCatalog((prevState) => {
                          return {
                            ...prevState,
                            opportunity_list: opportunity_list,
                          }
                        })
                      }}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={5}
                      maxRows={5}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
      </Grid>
    </FormLayout>
  )
}

export default OpportunityForm
