//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid, MenuItem } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../index'
//TYPES
import { ProductType, UserType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { EDITORS_QUERY } from '../../../gql/queries'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type TeaserEditorPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  setVideoTeaserEditorModal: (x: boolean) => void
}

const TeaserEditor = ({ product, refetch, setVideoTeaserEditorModal }: TeaserEditorPropsType) => {
  const [newProduct, setNewProduct] = useState<DeepPartial<ProductType> | undefined>(product)
  const [setAlert] = useAlert()
  const { data: { users: editors = [] } = {} } = useQuery<{ users: UserType[] }>(EDITORS_QUERY, {
    variables: {
      where: {
        job: {
          category: {
            eq: 'EDITOR',
          },
        },
      },
      order: {
        field: 'username',
        sort: 'ASC',
      },
    },
  })

  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      setVideoTeaserEditorModal(false)
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: newProduct?.id,
      teaser_uploader: { id: newProduct?.teaser_uploader?.id },
    }
    if (newProduct?.id) {
      await updateProductMutation({ variables: { input: productInput } })
    }
  }

  return (
    <FormLayout
      type={'update'}
      title={'Mettre à jour le monteur de la bande-annonce'}
      onSubmit={handleSubmit}
      submitDisabled={false}
    >
      <Grid item>
        <TextField
          fullWidth
          variant="outlined"
          id="editor"
          select
          label="Monteur"
          defaultValue={newProduct?.teaser_uploader}
          value={newProduct?.teaser_uploader?.id || ''}
          onChange={(event) => {
            setNewProduct({
              ...product,
              teaser_uploader: editors.find((e) => e.id.toString() === event.target.value.toString()),
            })
          }}
        >
          {editors.map((editor) => (
            <MenuItem id="editor" key={editor.id} value={editor.id}>
              {editor.username}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </FormLayout>
  )
}

export default TeaserEditor
