// MATERIAL UI
import { Grid } from '@material-ui/core'
//REACT-ROUTER
import { Outlet, useOutletContext } from 'react-router-dom'

const Lessons = () => {
  const { refetchCourse } = useOutletContext<{ refetchCourse: () => Promise<any> }>()

  return (
    <Grid container direction="column" justifyContent="flex-start" spacing={1} style={{ width: '100%' }}>
      <Grid item style={{ width: '100%' }}>
        <Outlet context={{ refetchCourse: refetchCourse }} />
      </Grid>
    </Grid>
  )
}

export default Lessons
