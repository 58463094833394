// MATERIAL UI
import { Box, Grid, Typography, useTheme } from '@material-ui/core'
//TYPE
import { VideoSetType } from '../../../../lib/sharedTypes'
import { VideosCaptionsType } from '../../utils/types'
import { videosCaptions } from '../../utils/constants'

type GaugeSetVideosProps = {
  videoSet: VideoSetType
}

const GaugeSetVideos = ({ videoSet }: GaugeSetVideosProps) => {
  const theme = useTheme()
  const videos_count = videoSet.lesson_videos.length
  if (videos_count === 0) {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '32px',
          background: theme.palette.action.disabled,
          color: 'white',
          margin: '10px auto',
          borderRadius: '10px 10px 10px 10px',
        }}
      >
        Le set n’a pas encore été initialisé
      </Box>
    )
  }
  const statuses = Object.keys(videosCaptions).filter(
    (key) => videoSet.lesson_videos.filter((video) => video.review_state === key).length > 0,
  )
  return (
    <Grid container>
      {statuses.map((key, index) => {
        let borderRadiusVal: string = '0px'
        if (statuses.length === 1) {
          borderRadiusVal = '10px 10px 10px 10px'
        } else {
          if (index === 0) {
            borderRadiusVal = '10px 0px 0px 10px'
          }
          if (index === statuses.length - 1) {
            borderRadiusVal = '0px 10px 10px 0px'
          }
        }
        const video_review_state_count = videoSet.lesson_videos.filter((video) => video.review_state === key).length
        return (
          <Grid
            key={key}
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{
              borderRadius: borderRadiusVal,
              height: '32px',
              width: `${(video_review_state_count / videos_count) * 100}%`,
              backgroundColor: videosCaptions[key as keyof VideosCaptionsType].color,
            }}
          >
            <Typography variant="body1" style={{ color: 'white' }}>
              {video_review_state_count}
            </Typography>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default GaugeSetVideos
