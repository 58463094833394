/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState } from 'react'
//ASSETS
import defaultTeacher from '../assets/defaultTeacher.png'
// COMPONENTS
import { TeacherForm, ImageForm } from '../components/Forms/teacher'
import { Layout, Title, Loading, Error, CustomModal, CustomTable } from '../components'
// MATERIAL UI
import { Grid, Button, Divider, Typography, makeStyles, useTheme, Paper, Chip } from '@material-ui/core'
// TYPE
// TYPE
import { ColumnType } from '../utils/types'
import { ProductType, TeacherType } from '../../../lib/sharedTypes'
// GQL
import { TEACHER_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const columns: ColumnType<ProductType>[] = [
  {
    title: 'Nom',
    field: 'name',
    render: (row: ProductType) => row.title,
    type: 'String',
    sortable: false,
  },
  {
    title: 'Nom de code',
    field: 'start',
    render: (row: ProductType) => row.code_name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Creation',
    field: 'created_at',
    render: (row: ProductType) => row.created_at,
    type: 'Date',
    sortable: true,
  },
  {
    title: 'Modification',
    field: 'updated_at',
    render: (row: ProductType) => row.updated_at,
    type: 'Date',
    sortable: true,
  },
]

const Teacher = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const theme = useTheme()
  const { teacher_id } = useParams()
  const [updateModal, setUpdateModal] = useState('')

  if (!teacher_id) {
    return (
      <Layout title="Formateur">
        <Error error={'Unknown teacher_id'} />
      </Layout>
    )
  }
  const {
    loading,
    error,
    data: { teacher } = {},
    refetch,
  } = useQuery<{
    teacher: TeacherType
  }>(TEACHER_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(teacher_id.toString()),
        },
      },
    },
  })

  if (loading) {
    return (
      <Layout title={teacher_id || 'Formateur'}>
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title={teacher_id || 'Formateur'}>
        <Error error={error} />
      </Layout>
    )
  }
  return (
    <Layout title={`${teacher?.first_name} ${teacher?.last_name}`}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item>
          <Title title={`${teacher?.first_name} ${teacher?.last_name}`} />
        </Grid>
        <Paper className={classes.paper}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Description</Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('description')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item container direction="row">
              <Grid item container direction="column" xs={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Prénom
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{teacher?.first_name}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Nom de famille
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{teacher?.last_name}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Score de push
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{teacher?.push_score}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item container direction="row">
              <Grid item container direction="column" xs={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Téléphone fixe
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{teacher?.phone_number}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Téléphone mobile
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{teacher?.mobile_number}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={4}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Email
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{teacher?.email}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Titre
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{teacher?.title}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Introduction
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{teacher?.introduction}</Typography>
            </Grid>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Points forts du CV
              </Typography>
            </Grid>
            <Grid item>
              {teacher?.resume_highlights?.map((rh, index) => (
                <Chip key={index} size="small" label={rh} color="primary" style={{ margin: theme.spacing(1) }} />
              ))}
            </Grid>
          </Grid>
        </Paper>
        {/* PROFILE PIC */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Photo de profil</Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('s3_picture')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container justifyContent="center">
              <img
                style={{
                  objectFit: 'cover',
                  maxWidth: '100%',
                  padding: theme.spacing(1),
                }}
                src={`${teacher?.s3_picture}?${new Date().getTime()}`} //force refresh
                onError={({ currentTarget }) => {
                  currentTarget.src = defaultTeacher
                }}
                loading="lazy"
                alt="S3_picture"
              />
            </Grid>
          </Grid>
        </Paper>
        {/* PRODUITS */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Produits</Typography>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              {teacher && (
                <CustomTable
                  columns={columns}
                  rows={teacher.products}
                  rowsCount={teacher.products.length}
                  onRowClick={(row: ProductType, e) => {
                    if (e.metaKey || e.ctrlKey) {
                      window.open(`/products/${row.code_name}`, '_blank')
                    } else {
                      navigate(`/products/${row.code_name}`)
                    }
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <CustomModal
        open={updateModal === 'description'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <TeacherForm
          teacher={teacher}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 's3_picture'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ImageForm
          teacher={teacher}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default Teacher
