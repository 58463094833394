//REACT
import { useEffect, useState } from 'react'
//COMPONENTS
import { FileForm, LessonSynthesisForm } from '../../../components/Forms/lesson'
import { LessonInfo, LessonRessources } from '../../../components/Lesson'
import { Layout, Title, Error, Loading, CustomModal, TextEditorJS } from '../../../components'
// MATERIAL UI
import { Grid, Typography, Paper, useTheme, makeStyles, Button, Divider, Link } from '@material-ui/core'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
// TYPES
import { LessonType } from '../../../../../lib/sharedTypes'
// GRAPHQL
import { useMutation, useQuery } from '@apollo/client'
import { LESSON_TEXT_QUERY } from '../../../gql/queries'
import { REGENERATE_PDF_LESSON_TEXT_MUTATION } from '../../../gql/mutations'
//UTILS
import { isAuthorized, isCourseManager } from '../../../utils/misc'
import { useAlert } from '../../../hooks'
import { handleError } from '../../../utils/handleError'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonSynthesis = () => {
  const [setAlert] = useAlert()
  const { code_name, lesson_id } = useParams()
  const [updateModal, setUpdateModal] = useState('')
  const theme = useTheme()
  const classes = useStyles()

  if (!lesson_id) {
    return (
      <Layout title="Leçon synthèse">
        <Error error="lesson_id inconnu" />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Leçon synthèse">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  const {
    loading,
    error,
    data: { lesson } = {},
    refetch,
  } = useQuery<{
    lesson: LessonType
  }>(LESSON_TEXT_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(lesson_id?.toString()),
        },
      },
    },
  })

  useEffect(() => {
    const pdf = document.getElementsByTagName('object')
    if (pdf.length !== 0 && lesson) {
      pdf[0].data = lesson.text.s3_file
    }
  }, [lesson])

  const [regeneratePdfLessonTextMutation] = useMutation<{
    regeneratePdfLessonText: LessonType
  }>(REGENERATE_PDF_LESSON_TEXT_MUTATION, {
    onError(e) {
      const msg = handleError(e)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: 'PDF synthèse en cours de génération' })
    },
  })

  if (loading || !lesson) {
    return (
      <Layout title="Leçon synthèse">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Leçon synthèse">
        <Error error={error} />
      </Layout>
    )
  }

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item>
          <Title title={code_name} />
        </Grid>
        {/* LESSON */}
        <LessonInfo
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        {/*LESSON_PDF */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Synthèse</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('synthese')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Téléchargeable
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{lesson.text.downloadable ? 'Oui' : 'Non'}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Contenu
                </Typography>
              </Grid>
              <Grid
                item
                key={new Date(lesson.text.updated_at).getTime()}
                style={{ maxHeight: 350, overflow: 'scroll' }}
              >
                <TextEditorJS content={lesson?.text?.content} isReadOnly={true} holderId={`lesson-text-${lesson.id}`} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* PDF */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Grid
                item
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
              >
                <Typography variant="h5" style={{ marginRight: 10 }}>
                  Fichier
                </Typography>
                <Link href={lesson.text.s3_file} target="_blank">
                  Lien
                </Link>
              </Grid>
              <Grid>
                <Button
                  style={{ marginRight: theme.spacing(1) }}
                  disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                  color="secondary"
                  variant="outlined"
                  onClick={async () => {
                    await regeneratePdfLessonTextMutation({ variables: { input: { id: lesson.text.id } } })
                  }}
                >
                  Regénerer
                </Button>
                <Button
                  style={{ marginRight: theme.spacing(1) }}
                  disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setUpdateModal('file')
                  }}
                >
                  Modifier
                </Button>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container justifyContent="center">
              {lesson.text.s3_file && (
                <object data={`${lesson.text.s3_file}#view=FitV`} width="100%" height="500">
                  <p>{lesson.text.s3_file}</p>
                </object>
              )}
            </Grid>
          </Grid>
        </Paper>
        {/* RESSOURCES */}
        <LessonRessources
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
      </Grid>
      <CustomModal
        open={updateModal === 'file'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <FileForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'synthese'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <LessonSynthesisForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default LessonSynthesis
