//REACT
import React, { useState, useEffect } from 'react'
// MATERIAL UI
import { Grid, Button } from '@material-ui/core'
//COMPONENTS
import { Error } from '../../index'
import { FormLayout } from '../index'
//TYPES
import { ProductType, VideoStateEnum } from '../../../../../lib/sharedTypes'
//GQL
import { useQuery, useMutation } from '@apollo/client'
import { PRODUCT_TEASER_UPLOAD_TOKEN } from '../../../gql/queries'
import { PRODUCT_TEASER_UPLOADED_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { useParams } from 'react-router'
import { WalterMediaUpload } from '../../../utils/walterMedia'

type TeaserFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const TeaserForm = ({ product, refetch, handleClose }: TeaserFormPropsType) => {
  const [setAlert] = useAlert()
  const [progress, setProgress] = useState(0)
  const { code_name } = useParams()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [localTeaser, setLocalTeaser] = useState<string>()
  const { error, data: { productTeaserUploadToken } = {} } = useQuery<{ productTeaserUploadToken: string }>(
    PRODUCT_TEASER_UPLOAD_TOKEN,
    {
      variables: { code_name: code_name },
    },
  )

  useEffect(() => {
    if (!selectedFile) {
      setLocalTeaser(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setLocalTeaser(objectUrl)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const [productTeaserVideo] = useMutation<{
    productTeaserVideo: boolean
  }>(PRODUCT_TEASER_UPLOADED_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Bande-annonce mise-en-ligne` })
      await refetch()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (productTeaserUploadToken && selectedFile) {
      const uploader = new WalterMediaUpload(productTeaserUploadToken)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const interval = setInterval(() => {
        setProgress(uploader.progress * 100)
      }, 500)

      try {
        await uploader.uploadFile(selectedFile)
        await productTeaserVideo({
          variables: { input: { id: product?.id, teaser_review_state: VideoStateEnum.VALIDATED } },
        })
        await refetch()
        setAlert({ severity: 'success', content: 'Fichier mis-en-ligne' })
        handleClose()
        clearInterval(interval)
        setProgress(0)
      } catch (error) {
        setAlert({ severity: 'error', content: 'Erreur lors de la mise en ligne' })
      }
    }
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour la bande annonce"
      progress={progress}
      onSubmit={handleSubmit}
      submitDisabled={!localTeaser}
    >
      <Grid item container justifyContent="center">
        <video src={localTeaser || product.teaser_walter_media_url} width="100%" height="480" controls />
      </Grid>
      <Grid container justifyContent="center">
        <Button color="primary" variant="contained" component="label">
          Selectionner
          <input hidden accept=".avi,.mp4,.mov,.flv,.wmv" type="file" onChange={onSelectFile} />
        </Button>
      </Grid>
    </FormLayout>
  )
}

export default TeaserForm
