import { Navigate } from 'react-router-dom'
//TYPES
import type { RoleType } from '../../../lib/sharedTypes'
//REDUX
import { useAppSelector } from '../hooks/reducerHooks'

const ProtectedRoute = ({ children, roles }: { children: JSX.Element; roles: string[] }): JSX.Element => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)
  const user = useAppSelector((state) => state.user.user)
  const userRoles = user?.roles.map((role: RoleType) => role.category as string)
  const intersection = roles.filter((role) => userRoles?.includes(role))
  return isLoggedIn && intersection.length !== 0 ? children : <Navigate to="/" />
}

export default ProtectedRoute
