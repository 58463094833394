/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Embed from '@editorjs/embed'
// import List from '@editorjs/list'
import Table from '@editorjs/table'
import Header from '@editorjs/header'
import Delimiter from '@editorjs/delimiter'
import NestedList from '@editorjs/nested-list'
// import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import TextVariantTune from './TextVariantTune/TextVariantTune'
import LaTeX from './LaTeX'

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  table: Table,
  embed: {
    class: Embed,
    inlineToolbar: true,
    // change regex of vimeo
    config: {
      services: {
        bunny: {
          regex: /^https:\/\/iframe.mediadelivery.net\/(.*)/,
          embedUrl: 'https://iframe.mediadelivery.net/<%= remote_id %>',
          html: '<iframe style="width:100%;" height="320" frameborder="0"></iframe>',
          height: 320,
          width: 580,
        },
        vimeoTwo: {
          regex: /^https:[/]*vimeo.com[/](.*)/,
          embedUrl: 'https://player.vimeo.com/video/<%= remote_id %>',
          html: '<iframe style="width:100%;" height="320" frameborder="0"></iframe>',
          height: 320,
          width: 580,
          id: (groups: Array<string>) => {
            const ids = groups[0].split('/')
            return ids.join('?h=')
          },
        },
      },
    },
  },
  list: {
    class: NestedList,
    inlineToolbar: true,
    config: {
      defaultStyle: 'unordered',
    },
  },
  // warning: Warning,
  // code: Code,
  // linkTool: {
  //   class: LinkTool,
  //   config: {
  //     endpoint: 'http://localhost:4000/api',
  //   },
  // },
  // image: Image,
  // raw: Raw,
  header: {
    class: Header,
    config: {
      placeholder: 'Header',
      levels: [2, 3],
      defaultLevel: 2,
    },
  },
  // quote: Quote,
  // marker: Marker,
  // checklist: CheckList,
  delimiter: {
    class: Delimiter,
    // keyboard input for delimiter
    shortcut: 'CMD+SHIFT+M',
  },
  // inlineCode: InlineCode,
  simpleImage: SimpleImage,
  textVariant: TextVariantTune,
  Math: LaTeX,
}
