import { useState } from 'react'
import { Grid, Typography, Paper, useTheme, Button, makeStyles, Divider } from '@material-ui/core'
import { ProductType } from '../../../../lib/sharedTypes'
import { PRODUCT_PARTS_QUERY } from '../../gql/queries'
import { useQuery, useMutation } from '@apollo/client'
import { int2roman } from '../../utils/misc'
import { isAuthorized } from '../../utils/misc'
import { IMPORT_PARTS_PRODUCT } from '../../gql/mutations'
import { handleError } from '../../utils/handleError'
import { useAlert } from '../../hooks'
import { PlanForm } from '../../components/Forms/product'
import { CustomModal } from '../../components'

type ProductPartsPropsType = {
  productId: number
  refetch: () => Promise<any>
}

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const ProductParts = ({ productId, refetch }: ProductPartsPropsType) => {
  const theme = useTheme()
  const [updateModal, setUpdateModal] = useState('')
  const [setAlert] = useAlert()
  const classes = useStyles()
  const { data: { product } = {} } = useQuery<{ product: ProductType }>(PRODUCT_PARTS_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(`${productId}`),
        },
      },
    },
  })

  const [importPartsMutation] = useMutation<{ importParts: ProductType }>(IMPORT_PARTS_PRODUCT, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `Parties de ${data.importParts.code_name} importées` })
      await refetch()
    },
  })

  if (!product) return <></>
  return (
    <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
      <Grid item>
        <Grid item container justifyContent="space-between">
          <Typography variant="h5">Programme de la formation</Typography>
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              onClick={async () => {
                await importPartsMutation({ variables: { input: { id: product?.id } } })
              }}
              style={{ marginRight: theme.spacing(1) }}
            >
              Importer
            </Button>
            <Button
              color="primary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              onClick={() => {
                setUpdateModal('plan')
              }}
            >
              Modifier
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item style={{ marginBottom: theme.spacing(1) }}>
        <Grid item>
          <Typography variant="body1" color="textSecondary" className={classes.title}>
            Introduction du programme
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{product.program_introduction || '-'}</Typography>
        </Grid>
      </Grid>

      <Grid item container direction="row" style={{ maxHeight: 350, overflow: 'scroll', whiteSpace: 'nowrap' }}>
        {product.parts
          .sort((a, b) => a.position - b.position)
          .map((part, i) => {
            return (
              <Grid item xs={12} key={part.id}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Partie {int2roman(i + 1)}. {part.name}
                </Typography>
                {part.sub_parts
                  .sort((a, b) => a.position - b.position)
                  .map((sub_part, index) => {
                    return (
                      <Typography key={sub_part.id} variant="body1">
                        Chapitre {String.fromCharCode(96 + index + 1).toUpperCase()}. {sub_part.name}
                      </Typography>
                    )
                  })}
              </Grid>
            )
          })}
      </Grid>
      <CustomModal
        open={updateModal === 'plan'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <PlanForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
    </Paper>
  )
}

export default ProductParts
