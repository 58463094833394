import { Grid, Chip, TableRow, TableCell, makeStyles } from '@material-ui/core'
import { getWeekNumber, getMondays } from '../../utils/calendar'
import { stringToColor } from '../../utils/misc'
import { CALENDAR_SHOOTINGS_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
import { ShootingType } from '../../../../lib/sharedTypes'

type WeekShootingsPropsType = {
  startDate: Date
  endDate: Date
}

const useStyles = makeStyles((theme) => ({
  firstCol: {
    left: 0,
    background: theme.palette.background.default,
    zIndex: 1000,
    position: 'sticky',
    padding: theme.spacing(1),
    minWidth: 250,
    maxWidth: 250,
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRight: `2px solid ${theme.palette.action.disabled}`,
    borderBottom: `2px solid ${theme.palette.action.disabled}`,
  },
  column: {
    minWidth: 150,
    maxWidth: 150,
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRight: `2px solid ${theme.palette.action.disabled}`,
    borderBottom: `2px solid ${theme.palette.action.disabled}`,
  },
  currentWeek: {
    minWidth: 150,
    maxWidth: 150,
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  dayBox: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 42,
    width: 28,
  },
}))

const WeekShootings = ({ startDate, endDate }: WeekShootingsPropsType) => {
  const classes = useStyles()
  const currentWeekNumber = getWeekNumber(new Date())
  const { data: { shootings = [] } = {} } = useQuery<{ shootings: ShootingType[] }>(CALENDAR_SHOOTINGS_QUERY, {
    variables: {
      where: {
        starts_at: {
          between: {
            from: startDate.toISOString(),
            to: endDate.toISOString(),
          },
        },
        ends_at: {
          between: {
            from: startDate.toISOString(),
            to: endDate.toISOString(),
          },
        },
      },
    },
  })

  return (
    <TableRow>
      <TableCell className={classes.firstCol}>Tournages</TableCell>
      {getMondays(startDate, endDate).map((monday) => {
        const weekNumber = getWeekNumber(monday)
        return (
          <TableCell
            className={currentWeekNumber === weekNumber ? classes.currentWeek : classes.column}
            key={monday.getTime()}
          >
            <Grid container direction="row" justifyContent="center" spacing={1}>
              {shootings
                .filter((shooting) => {
                  return (
                    new Date(shooting.starts_at).getFullYear() === monday.getFullYear() &&
                    (getWeekNumber(new Date(shooting.starts_at)) === weekNumber ||
                      getWeekNumber(new Date(shooting.ends_at)) === weekNumber)
                  )
                })
                .map((shooting) => {
                  return (
                    <Grid item key={shooting.id}>
                      <Chip
                        size="small"
                        label={shooting.project.code_name}
                        style={{
                          backgroundColor: stringToColor(shooting.project.code_name),
                          fontSize: 9,
                          color: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  )
                })}
            </Grid>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default WeekShootings
