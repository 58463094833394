//REACT
import React, { useState } from 'react'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Grid, Button, useTheme, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
//COMPONENTS
import { Error } from '../../index'
import { FormLayout } from '../index'
//TYPES
import { LessonType, ResourceType, CourseType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { COURSE_LESSONS_QUERY } from '../../../gql/queries'
import { useQuery } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { CREATE_RESOURCE_MUTATION, UPDATE_RESOURCE_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { useParams } from 'react-router'

type ResourceFormPropsType = {
  course: CourseType
  resource?: ResourceType
  lessons?: LessonType[]
  refetch: () => Promise<any>
  handleClose: () => void
}

const ResourceForm = ({ course, resource, lessons, refetch, handleClose }: ResourceFormPropsType) => {
  const theme = useTheme()
  const { code_name } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [stateResources, setStateResources] = useState<DeepPartial<ResourceType>[] | undefined>(
    resource ? [resource] : [],
  )
  const [selectedFiles, setSelectedFiles] = useState<File[]>()
  const [setAlert] = useAlert()
  const { error, data: { lessons: CourseLessons } = {} } = useQuery<{ lessons: LessonType[] }>(COURSE_LESSONS_QUERY, {
    variables: {
      where: {
        course: {
          code_name: {
            eq: code_name,
          },
        },
      },
      order: {
        field: 'name',
        sort: 'ASC',
      },
    },
  })

  const [createResourceMutation] = useMutation<{
    createResource: ResourceType
  }>(CREATE_RESOURCE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createResource.name} créé` })
    },
  })

  const [updateResourceMutation] = useMutation<{
    updateResource: ResourceType
  }>(UPDATE_RESOURCE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateResource.name} mis-à-jour` })
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    if (stateResources) {
      await Promise.all(
        stateResources.map((stateResource, index) => {
          const resourceInput: Record<string, any> = {
            course: { id: course.id },
            name: stateResource.name,
            comment: stateResource.comment,
            pinned: stateResource.pinned,
            lessons: stateResource?.lessons?.map((lesson) => {
              return { id: lesson?.id }
            }),
          }

          if (selectedFiles && selectedFiles[index]) {
            resourceInput.s3_file = selectedFiles[index]
          }

          if (resource) {
            resourceInput.id = resource.id
            return updateResourceMutation({ variables: { input: resourceInput } })
          } else {
            return createResourceMutation({ variables: { input: resourceInput } })
          }
        }),
      )
      setLoading(false)
      await refetch()
      handleClose()
    }
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    setSelectedFiles((prevState) => {
      if (e.target.files) {
        if (resource) {
          return Object.values(e.target.files)
        } else {
          if (prevState) {
            return [...prevState, ...Object.values(e.target.files)]
          } else {
            return Object.values(e.target.files)
          }
        }
      }
      return prevState
    })

    setStateResources((prevState) => {
      if (e.target.files) {
        if (prevState) {
          if (resource) {
            const file = Object.values(e.target.files)[0]
            return [
              {
                ...prevState[0],
                s3_file: URL.createObjectURL(file),
                name: file.name
                  .split('.')
                  .slice(0, file.name.split('.').length - 1)
                  .join(''),
              },
            ]
          } else {
            return [
              ...prevState,
              ...Object.values(e.target.files).map((file) => {
                return {
                  name: file.name
                    .split('.')
                    .slice(0, file.name.split('.').length - 1)
                    .join(''),
                  comment: '',
                  lessons: lessons
                    ? lessons.map((l) => {
                        return { id: l.id, name: l.name }
                      })
                    : [],
                }
              }),
            ]
          }
        } else {
          return Object.values(e.target.files).map((file) => {
            return {
              name: file.name
                .split('.')
                .slice(0, file.name.split('.').length - 1)
                .join(''),
              comment: '',
              lessons: [],
            }
          })
        }
      }
      return prevState
    })
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <FormLayout
      type={resource ? 'update' : 'create'}
      loading={loading}
      title={resource ? 'Mettre à jour la ressource' : 'Ajouter des ressources'}
      onSubmit={handleSubmit}
      submitDisabled={resource ? stateResources?.[0] === resource : stateResources?.length === 0}
    >
      <Grid item container spacing={2} alignItems="center">
        <Grid item>
          {!resource && (
            <Button variant="outlined" color="primary" component="label">
              Selectionner
              <input hidden accept="*" type="file" onChange={onSelectFile} multiple={true} />
            </Button>
          )}
          {resource && (
            <Button variant="outlined" color="primary" component="label">
              Selectionner un autre fichier
              <input hidden accept="*" type="file" onChange={onSelectFile} multiple={false} />
            </Button>
          )}
        </Grid>
        <Grid container direction="column" justifyContent="center">
          {stateResources &&
            stateResources.map((stateResource, index) => {
              return (
                <Grid
                  item
                  key={index}
                  container
                  style={{
                    border: `1px solid ${theme.palette.action.disabled}`,
                    borderRadius: 5,
                    padding: theme.spacing(1),
                    margin: theme.spacing(1),
                  }}
                  spacing={1}
                >
                  <Grid item xs={8} container spacing={1} direction="column">
                    <Grid item>
                      <TextField
                        label="Nom"
                        id="name"
                        value={stateResource.name}
                        onChange={(event) => {
                          setStateResources((prevState) => {
                            if (!prevState) return []
                            return prevState.map((r, i) => {
                              if (i === index) {
                                return { ...r, name: event.target.value }
                              }
                              return r
                            })
                          })
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        multiline
                        minRows={3}
                        maxRows={3}
                        label="Commentaire"
                        id="comment"
                        defaultValue={stateResource.comment}
                        onChange={(event) => {
                          setStateResources((prevState) => {
                            if (!prevState) return []
                            return prevState.map((r, i) => {
                              if (i === index) {
                                return { ...r, comment: event.target.value }
                              }
                              return r
                            })
                          })
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <Autocomplete
                        multiple
                        value={stateResource?.lessons ?? []}
                        options={CourseLessons ?? []}
                        getOptionSelected={(option, value) => option?.id === value?.id}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(_, value) => {
                          if (value) {
                            setStateResources((prevState) => {
                              if (!prevState) return []
                              return prevState.map((r, i) => {
                                if (i === index) {
                                  return { ...r, lessons: value }
                                }
                                return r
                              })
                            })
                          }
                        }}
                        renderInput={(params) => {
                          return <TextField {...params} variant="outlined" label="Leçon(s)" />
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={stateResource.pinned || undefined}
                              onClick={() => {
                                setStateResources((prevState) => {
                                  if (!prevState) return []
                                  return prevState.map((r, i) => {
                                    if (i === index) {
                                      return { ...r, pinned: !stateResource.pinned }
                                    }
                                    return r
                                  })
                                })
                              }}
                            />
                          }
                          label="Cette ressource sera disponible depuis le dashboard de la formation"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    {stateResources[0].s3_file ? (
                      (selectedFiles && selectedFiles[0].name.endsWith('pdf')) ||
                      stateResources[0].s3_file.endsWith('pdf') ? (
                        <object type="application/pdf" data={stateResources[0].s3_file} width="100%" height="100%" />
                      ) : (
                        <img
                          src={stateResources[0].s3_file}
                          loading="lazy"
                          style={{
                            width: '100%',
                            maxHeight: '250px',
                            objectFit: 'contain',
                          }}
                        />
                      )
                    ) : selectedFiles ? (
                      selectedFiles[index]?.name.endsWith('pdf') ? (
                        <object
                          type="application/pdf"
                          data={URL.createObjectURL(selectedFiles[index])}
                          width="100%"
                          height="100%"
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(selectedFiles[index])}
                          loading="lazy"
                          style={{
                            width: '100%',
                            maxHeight: '250px',
                            objectFit: 'contain',
                          }}
                        />
                      )
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default ResourceForm
