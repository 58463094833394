//REACT
import { useState } from 'react'
//COMPONENTS
import { CustomTable } from '../../components'
// MATERIAL UI
import { Grid } from '@material-ui/core'
//GQL
import { PROJECTS_TABLE_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
//TYPES
import { ColumnType } from '../../utils/types'
import { ProjectType, SortEnum } from '../../../../lib/sharedTypes'
//UTILS
import { secondsToDHMS } from '../../utils/misc'

const columns: ColumnType<ProjectType>[] = [
  {
    title: 'Code Name',
    field: 'code_name',
    render: (row: ProjectType) => row.code_name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Durée formation',
    field: 'course.duration',
    render: (row: ProjectType) => secondsToDHMS(row.course.duration),
    type: 'String',
    sortable: true,
  },
  {
    title: 'Durée production',
    field: 'duration_production',
    render: (row: ProjectType) => secondsToDHMS(row.duration_production),
    type: 'String',
    sortable: false,
  },
  {
    title: 'Durée cible',
    field: 'duration_target',
    render: (row: ProjectType) => secondsToDHMS(row.duration_target),
    type: 'String',
    sortable: false,
  },
  {
    title: 'Tournage',
    field: 'shooting',
    render: (row: ProjectType) =>
      row.shootings.every((shooting) => new Date(shooting.ends_at) < new Date()) ? 'Terminé' : 'En cours',
    type: 'String',
    sortable: false,
  },
  {
    title: 'Date de sortie',
    field: 'premiere_session',
    render: (row: ProjectType) => row.course.premiere_session,
    type: 'Date',
    sortable: false,
  },
  {
    title: 'Réalisateurs',
    field: 'members',
    render: (row: ProjectType) => row.members,
    type: 'Avatars',
    sortable: false,
  },
]

const ProjectsTable = () => {
  const [field, setField] = useState<keyof ProjectType>('created_at')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const { data: { projects = [] } = {} } = useQuery<{ projects: ProjectType[] }>(PROJECTS_TABLE_QUERY, {
    variables: {
      order: {
        field: field,
        sort: sort,
      },
    },
  })

  return (
    <Grid item style={{ width: '100%' }}>
      <CustomTable<ProjectType>
        columns={columns}
        rows={projects}
        field={field}
        setField={setField}
        sort={sort}
        setSort={setSort}
      />
    </Grid>
  )
}

export default ProjectsTable
