//REACT
import { useState } from 'react'
//COMPONENTS
import { IframeForm, EppForm } from '../../../components/Forms/lesson'
import { LessonInfo, LessonRessources } from '../../../components/Lesson'
import { Layout, Title, Error, Loading, CustomModal } from '../../../components'
// MATERIAL UI
import { Grid, Typography, Paper, useTheme, makeStyles, Button, Divider, Link } from '@material-ui/core'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
// TYPES
import { LessonType } from '../../../../../lib/sharedTypes'
// GRAPHQL
import { useQuery } from '@apollo/client'
import { LESSON_EPP_QUERY } from '../../../gql/queries'
//UTILS
import { isAuthorized, isCourseManager } from '../../../utils/misc'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonEPP = () => {
  const { code_name, lesson_id } = useParams()
  const [updateModal, setUpdateModal] = useState('')
  const theme = useTheme()
  const classes = useStyles()

  if (!lesson_id) {
    return (
      <Layout title="Leçon quiz">
        <Error error="lesson_id inconnu" />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Leçon epp">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  const {
    loading,
    error,
    data: { lesson } = {},
    refetch,
  } = useQuery<{
    lesson: LessonType
  }>(LESSON_EPP_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(lesson_id?.toString()),
        },
      },
    },
  })

  if (loading || !lesson) {
    return (
      <Layout title="Leçon epp">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Leçon epp">
        <Error error={error} />
      </Layout>
    )
  }

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item>
          <Title title={code_name} />
        </Grid>
        {/* LESSON */}
        <LessonInfo
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        {/* EPP  */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">EPP</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('episodes')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid container direction="row" xs={12}>
              <Grid item container xs={6} direction="column" style={{ marginBottom: theme.spacing(1) }}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Critères d'inclusion
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{lesson.epp.inclusion_criteria || '-'}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={6} direction="column" style={{ marginBottom: theme.spacing(1) }}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Critères d'exclusion
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{lesson.epp.exclusion_criteria || '-'}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Grille au format PDF{' '}
                  {lesson.epp.s3_grid_pdf ? (
                    <Link href={lesson.epp.s3_grid_pdf} target="_blank">
                      Lien
                    </Link>
                  ) : null}
                </Typography>
              </Grid>
              {lesson.epp.s3_grid_pdf ? (
                <object type="application/pdf" data={lesson.epp.s3_grid_pdf} width="100%" height="250px" />
              ) : (
                '-'
              )}
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item container spacing={1} style={{ maxHeight: 500, overflowY: 'auto' }}>
                {lesson.epp.episodes
                  .sort((a, b) => a.position - b.position)
                  .map((episode) => {
                    return (
                      <Grid item key={episode.id} xs={12}>
                        <Paper style={{ border: `2px solid ${theme.palette.divider}` }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            direction="row"
                            style={{ padding: theme.spacing(1) }}
                          >
                            <Grid item>
                              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                {episode.title}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container direction="column">
                            {episode.checkpoints
                              .sort((a, b) => a.position - b.position)
                              .map((checkpoint) => {
                                return (
                                  <Grid
                                    item
                                    container
                                    key={checkpoint.id}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    style={{
                                      minHeight: 50,
                                      padding: theme.spacing(1),
                                    }}
                                  >
                                    <Grid item>
                                      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                        {checkpoint.position + 1} - {checkpoint.statement}
                                      </Typography>
                                      <Typography variant="caption">{checkpoint.advice}</Typography>
                                    </Grid>
                                  </Grid>
                                )
                              })}
                          </Grid>
                        </Paper>
                      </Grid>
                    )
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* EPP VIDEO IFRAME  */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">EPP video explicative</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('epp_video_iframe')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>

            <Grid item container style={{ position: 'relative', height: '350px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: lesson.epp.epp_video_iframe
                    ? lesson.epp.epp_video_iframe.replace('1920', '640').replace('1080', '320')
                    : lesson.epp.epp_video_iframe,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        {/* EPP GRID IFRAME  */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Vidéo explicative de la grille d’audit clinique</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('grid_video_iframe')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container style={{ position: 'relative', height: '350px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: lesson.epp.grid_video_iframe
                    ? lesson.epp.grid_video_iframe.replace('1920', '640').replace('1080', '320')
                    : lesson.epp.grid_video_iframe,
                }}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* RESSOURCES */}
        <LessonRessources
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        <CustomModal
          open={updateModal === 'episodes'}
          onClose={() => {
            setUpdateModal('')
          }}
          animation="slide-up"
        >
          <EppForm
            lesson={lesson}
            refetch={refetch}
            handleClose={() => {
              setUpdateModal('')
            }}
          />
        </CustomModal>
        <CustomModal
          open={updateModal === 'epp_video_iframe'}
          onClose={() => {
            setUpdateModal('')
          }}
          animation="slide-up"
        >
          <IframeForm
            type="epp_video_iframe"
            lesson={lesson}
            refetch={refetch}
            handleClose={() => {
              setUpdateModal('')
            }}
          />
        </CustomModal>
        <CustomModal
          open={updateModal === 'grid_video_iframe'}
          onClose={() => {
            setUpdateModal('')
          }}
          animation="slide-up"
        >
          <IframeForm
            type="grid_video_iframe"
            lesson={lesson}
            refetch={refetch}
            handleClose={() => {
              setUpdateModal('')
            }}
          />
        </CustomModal>
      </Grid>
    </Layout>
  )
}

export default LessonEPP
