//REACT
import { useState, useRef, useEffect } from 'react'
//COMPONENTS
import { LessonInfo, LessonRessources } from '../../../components/Lesson'
import { DeleteForm } from '../../../components/Forms'
import { LessonVideoDiagramForm } from '../../../components/Forms/lesson'
import { Layout, Title, Error, Loading, CustomModal } from '../../../components'
// MATERIAL UI
import { Grid, Typography, Paper, useTheme, makeStyles, Button, Divider } from '@material-ui/core'
import TextEditor from '../../../components/TextEditor'
// REACT-ROUTER
import { useParams, useLocation } from 'react-router-dom'
// TYPES
import { LessonType, LessonVideoDiagramType } from '../../../../../lib/sharedTypes'
// GRAPHQL
import { useQuery, useMutation } from '@apollo/client'
import { LESSON_DACTYLO_QUERY } from '../../../gql/queries'
import { DELETE_LESSON_VIDEO_DIAGRAM_MUTATION } from '../../../gql/mutations'
//UTILS
import { handleError } from '../../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../../utils/misc'
//HOOKS
import { useAlert } from '../../../hooks'
import LessonDactyloForm from '../../../components/Forms/lesson/dactylo/LessonDactyloForm'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

type LocationState = {
  state: {
    openModal: string
  }
}

const LessonDactylo = () => {
  const { code_name, lesson_id } = useParams()
  const videoRef = useRef<HTMLVideoElement>(null)
  const theme = useTheme()
  const classes = useStyles()
  const [lessonModal, setLessonModal] = useState('')
  const [lessonVideoModal, setLessonVideoModal] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<LessonVideoDiagramType | undefined>(undefined)
  const [setAlert] = useAlert()
  const location = useLocation()
  const [deleteLessonVideoDiagramMutation] = useMutation<{
    deleteLessonVideoDiagram: boolean
  }>(DELETE_LESSON_VIDEO_DIAGRAM_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Schéma supprimé` })
    },
  })

  if (!lesson_id) {
    return (
      <Layout title="Leçon vidéo">
        <Error error="lesson_id inconnu" />
      </Layout>
    )
  }

  const {
    loading,
    error,
    data: { lesson } = {},
    refetch,
  } = useQuery<{
    lesson: LessonType
  }>(LESSON_DACTYLO_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(lesson_id?.toString()),
        },
      },
    },
  })

  if (!code_name) {
    return (
      <Layout title="Leçon vidéo">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  useEffect(() => {
    const { state } = location as LocationState
    if (state && state.openModal) {
      setLessonModal(state.openModal)
    }
  }, [location])

  useEffect(() => {
    if (videoRef.current && lesson) {
      videoRef.current.currentTime = lesson.video.thumbnail_timecode
    }
  }, [videoRef, lesson])

  if (loading || !lesson) {
    return (
      <Layout title="Leçon vidéo">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Leçon vidéo">
        <Error error={error} />
      </Layout>
    )
  }

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Title title={code_name} />
        </Grid>
        <LessonInfo
          openModal={lessonModal === 'lesson_info'}
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />

        {/* DACTYLO */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Dactylo</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setLessonVideoModal('lesson_dactylo_form')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <Grid item container direction="column" xs={6}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Vidéo
                  </Typography>
                </Grid>
                <Grid item>
                  <video
                    src={`${lesson?.dactylo?.lesson_video?.walter_media_url}?${new Date().getTime()}`}
                    width="100%"
                    id="video"
                    controls
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={6}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Transcript
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ maxHeight: '375px', overflow: 'auto' }}>
                    <TextEditor type="dactylo" readOnly={true} text={lesson.dactylo.transcript} />
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Permettre de pauser la vidéo
                  </Typography>
                </Grid> */}
                {/* <Grid item>
                  {lesson.dactylo.pausable ? (
                    <MdOutlineCheckCircleOutline style={{ color: theme.palette.success.main }} size="22" />
                  ) : (
                    <MdHighlightOff style={{ color: theme.palette.error.main }} size="22" />
                  )}
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* RESSOURCES */}

        <LessonRessources
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
      </Grid>
      <CustomModal
        open={lessonVideoModal === 'lesson_dactylo_form'}
        onClose={() => {
          setLessonVideoModal('')
        }}
        animation="slide-up"
      >
        <LessonDactyloForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setLessonVideoModal('')
          }}
        />
      </CustomModal>

      <CustomModal
        open={lessonVideoModal === 'lesson_video_diagram'}
        onClose={() => {
          setLessonVideoModal('')
          setSelectedRow(undefined)
        }}
        animation="slide-up"
      >
        <LessonVideoDiagramForm
          diagram={selectedRow}
          video={lesson.video}
          refetch={refetch}
          handleClose={() => {
            setLessonVideoModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer le schéma"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteLessonVideoDiagramMutation({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default LessonDactylo
