import { useEffect, useState } from 'react'
// ASSETS
import NOIMG_banner from '../../../assets/NOIMG_banner.jpg'
// TYPE
import { CatalogType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_CATALOG_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { Button, Grid, Typography } from '@material-ui/core'
import FormLayout from '../FormLayout'

type PdfForm = {
  catalog: CatalogType
  refetch: () => Promise<any>
  handleClose: () => void
}

const PdfForm = ({ catalog, refetch, handleClose }: PdfForm) => {
  const [setAlert] = useAlert()
  const [stateCatalog, setStateCatalog] = useState<CatalogType>(catalog)
  const [localFile, setLocalFile] = useState<string>()
  const [selectedFile, setSelectedFile] = useState<File>()

  const [updateCatalog] = useMutation<{ updateCatalog: CatalogType }>(UPDATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCatalog.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  useEffect(() => {
    if (!selectedFile) {
      setLocalFile(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setLocalFile(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(event.target.files[0])

    setStateCatalog((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const catalogInput = {
      id: stateCatalog.id,
      catalog_pdf_s3link: selectedFile,
    }
    await updateCatalog({ variables: { input: catalogInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour le PDF du catalogue"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateCatalog) === JSON.stringify(catalog)}
    >
      <Grid item container justifyContent="center">
        {localFile ? (
          <iframe
            src={localFile}
            title="PDF Preview"
            style={{
              width: '100%',
              height: '350px',
            }}
          />
        ) : stateCatalog?.catalog_pdf_s3link ? (
          <object
            data={catalog?.catalog_pdf_s3link}
            style={{
              width: '100%',
              height: '350px',
            }}
          >
            <p>{catalog?.catalog_pdf_s3link}</p>
          </object>
        ) : (
          <img
            style={{
              objectFit: 'cover',
              maxWidth: '100%',
              maxHeight: '350px',
            }}
            src={NOIMG_banner}
            loading="lazy"
            alt="catalog_pdf_s3link"
          />
        )}
      </Grid>
      <Grid item container alignItems="center" direction="column">
        <Button color="primary" variant="contained" component="label">
          Sélectionner un fichier PDF
          <input
            name="catalog_pdf_s3link"
            accept="application/pdf"
            type="file"
            style={{ display: 'none' }}
            onChange={onSelectFile}
          />
        </Button>

        <Typography variant="caption" color="textSecondary">
          Seuls les fichiers PDF sont autorisés.
        </Typography>
      </Grid>
    </FormLayout>
  )
}

export default PdfForm
