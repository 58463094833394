//REACT
import { useEffect, useState, useRef } from 'react'
//COMPONENTS
import { VideoReviewModal } from '../../components/Project'
import { Layout, Title, Error, Loading, CustomModal, Note } from '../../components'
// MATERIAL UI
import {
  Button,
  Grid,
  Chip,
  Typography,
  alpha,
  useTheme,
  Box,
  CircularProgress,
  IconButton,
  Link,
  TextField,
} from '@material-ui/core'
//ICONS
import { MdOutlineModeEdit } from 'react-icons/md'
import { ImVimeo2 } from 'react-icons/im'
//TYPE
import { VideosCaptionsType } from '../../utils/types'
import { LessonVideoType, VideoStateEnum, LessonType } from '../../../../lib/sharedTypes'
//GQL
import {
  LESSON_VIDEO_UPLOADED_MUTATION,
  UPDATE_LESSON_VIDEO_MUTATION,
  UPLOAD_LESSON_VIDEO_TO_VIMEO,
  UPDATE_LESSON_MUTATION,
} from '../../gql/mutations'
import { PROJECT_LESSON_VIDEO_QUERY, LESSON_VIDEO_UPLOAD_TOKEN } from '../../gql/queries'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
//UTILS
import { secondsToDHMS, isAuthorized, isCourseManager, isSetEditor } from '../../utils/misc'
import { handleError } from '../../utils/handleError'
import { videosCaptions } from '../../utils/constants'
import { WalterMediaUpload } from '../../utils/walterMedia'
//ROUTER
import { useParams, useLocation } from 'react-router'
//HOOKS
import { useAlert } from '../../hooks'
import { useAppSelector } from '../../hooks/reducerHooks'

type LocationState = {
  state: {
    openModal: boolean
    timeCode: number
  }
}

const Video = () => {
  const user = useAppSelector((state) => state.user.user)
  const theme = useTheme()
  const location = useLocation()
  const { code_name, set_index, video_number } = useParams()
  const [statusModal, setStatusModal] = useState(false)
  const [editLessonNameModal, setEditLessonNameModal] = useState(false)
  const [newLessonName, setNewLessonName] = useState('')
  const [isVideoReviewModalOpen, setVideoReviewModalOpen] = useState(false)
  const [progress, setProgress] = useState(0)
  const [setAlert] = useAlert()
  const videoRef = useRef<HTMLVideoElement>(null)

  if (!set_index || !code_name || !video_number) {
    return <Error error={'code_name ou video_number inconnu!'} />
  }

  const [updateLessonMutation] = useMutation<{ updateLesson: LessonType }>(UPDATE_LESSON_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Nom modifié` })
      await refetch()
      setEditLessonNameModal(false)
    },
  })

  const {
    error,
    data: { lessonVideo } = {},
    refetch,
  } = useQuery<{
    lessonVideo: LessonVideoType
  }>(PROJECT_LESSON_VIDEO_QUERY, {
    variables: {
      where: {
        video_set: {
          project: {
            code_name: {
              eq: code_name,
            },
          },
          index: {
            eq: parseInt(set_index),
          },
        },
        number: {
          eq: parseInt(video_number),
        },
      },
    },
    onCompleted(data) {
      setNewLessonName(data.lessonVideo.lesson.name)
    },
  })

  const [getToken] = useLazyQuery<{ lessonVideoUploadToken: string }>(LESSON_VIDEO_UPLOAD_TOKEN, {
    variables: { id: lessonVideo?.id },
  })

  useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.addEventListener('loadedmetadata', () => {
        video.currentTime = lessonVideo?.thumbnail_timecode || 0
      })
    }
  }, [videoRef])

  useEffect(() => {
    const { state } = location as LocationState
    if (state) {
      if (state.openModal) {
        setVideoReviewModalOpen(true)
      }
    }
  }, [location])

  const [lessonVideoUploaded] = useMutation<{
    lessonVideoUploaded: boolean
  }>(LESSON_VIDEO_UPLOADED_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Vidéo mise-en-ligne` })
      await refetch()
    },
  })

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { data } = await getToken({
      variables: {
        id: lessonVideo?.id,
      },
    })

    if (e.target.files && data && data.lessonVideoUploadToken) {
      const uploader = new WalterMediaUpload(data.lessonVideoUploadToken)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const interval = setInterval(() => {
        setProgress(uploader.progress * 100)
      }, 500)
      try {
        await uploader.uploadFile(e.target.files[0])
        await lessonVideoUploaded({
          variables: { input: { id: lessonVideo?.id, user: { id: user?.id } } },
        })
        setAlert({ severity: 'success', content: 'Fichier mis-en-ligne' })
        await refetch()
        clearInterval(interval)
        setProgress(0)
      } catch (error) {
        setAlert({ severity: 'error', content: 'Erreur lors de la mise en ligne' })
      }
    }
  }

  const [uploadLessonVideoToVimeo, { loading: vimeoUploadLoading }] = useMutation<{
    uploadProductTeaserToVimeo: LessonVideoType
  }>(UPLOAD_LESSON_VIDEO_TO_VIMEO, {
    onError(e) {
      const msg = handleError(e)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      lessonVideo?.vimeo_lastsync
        ? setAlert({ severity: 'success', content: 'Vidéo remplacée sur Viméo' })
        : setAlert({ severity: 'success', content: 'Vidéo mise-en-ligne sur Viméo' })
      await refetch()
    },
  })

  const [updateLessonVideo] = useMutation<{
    updateLessonVideo: LessonVideoType
  }>(UPDATE_LESSON_VIDEO_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Statut mis-à-jour` })
      await refetch()
    },
  })

  if (error) {
    return (
      <Layout title="Video">
        <Error error={error} />
      </Layout>
    )
  }

  if (!lessonVideo) {
    return (
      <Layout title="Video">
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout title={`${code_name} - ${video_number}`}>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Title title={`${code_name} - Set ${lessonVideo?.video_set.index} - Video ${lessonVideo.number}`} />
        </Grid>
        <Grid item>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              backgroundColor: alpha(videosCaptions[lessonVideo?.review_state].color, 0.2),
              borderRadius: '5px',
              padding: theme.spacing(1),
            }}
          >
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item style={{ paddingRight: theme.spacing(1) }}>
                  <Chip
                    label={videosCaptions[lessonVideo.review_state].label}
                    style={{ backgroundColor: videosCaptions[lessonVideo.review_state].color }}
                    color="primary"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="caption" style={{ color: videosCaptions[lessonVideo.review_state].color }}>
                    {videosCaptions[lessonVideo.review_state].description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {['WAITING', 'TO_DISCOVER', 'TO_CORRECT', 'TO_CHECK'].includes(lessonVideo.review_state) && (
                <Button
                  disabled={
                    !(
                      isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) ||
                      isCourseManager(lessonVideo.video_set.project.course) ||
                      isSetEditor(lessonVideo.video_set)
                    )
                  }
                  size="small"
                  variant="outlined"
                  style={{ borderColor: videosCaptions[lessonVideo.review_state].color, width: 250 }}
                  component="label"
                >
                  {progress !== undefined && progress !== 0 ? (
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        variant="determinate"
                        size="1.75rem"
                        id="upload_progress"
                        value={progress}
                        style={{ color: 'white' }}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography style={{ fontSize: 8 }} color="textSecondary">{`${Math.round(
                          progress || 0,
                        )}%`}</Typography>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      {lessonVideo.review_state === 'WAITING' ? 'Ajouter la vidéo' : 'Uploader une nouvelle vidéo'}
                      <input hidden accept=".avi,.mp4,.mov,.flv,.wmv" type="file" onChange={onSelectFile} />
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <video
            src={`${lessonVideo.walter_media_url}?${new Date().getTime()}`}
            width="100%"
            height="640px"
            id="video"
            controls
            ref={videoRef}
          />
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">
                        <Link
                          href={`/formations/${lessonVideo.video_set.project?.course?.code_name}/lessons/video/${lessonVideo?.lesson?.id}`}
                        >
                          Leçon vidéo n°{lessonVideo?.number} - {lessonVideo?.lesson?.name}
                        </Link>
                        <IconButton
                          style={{ padding: theme.spacing(1) }}
                          onClick={() => {
                            setEditLessonNameModal(true)
                          }}
                        >
                          <MdOutlineModeEdit size="20" style={{ cursor: 'pointer' }} />
                        </IconButton>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        <Link
                          href={`/projects/${lessonVideo.video_set.project?.code_name}/sets/${lessonVideo?.video_set.index}`}
                        >
                          Set n°{lessonVideo?.video_set.index}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item container direction="row" alignItems="center" spacing={1}>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            window.open(lessonVideo.vimeo_url, '_blank')
                          }}
                        >
                          {lessonVideo.vimeo_url ? (
                            <ImVimeo2 size="22" color="#3E83F5" />
                          ) : (
                            <ImVimeo2 size="22" color="#e0e0e0" />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Note lesson={lessonVideo.lesson} refetch={refetch} size="26px" />
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                          {lessonVideo && lessonVideo.vimeo_lastsync
                            ? `Dernière publication: ${new Date(lessonVideo.vimeo_lastsync).toLocaleString()}`
                            : 'Non publié'}
                        </Typography>
                      </Grid>
                      <Grid item>-</Grid>
                      <Grid item>{secondsToDHMS(lessonVideo.file_duration)}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    style={{ width: 140 }}
                    variant="outlined"
                    color="primary"
                    startIcon={<ImVimeo2 />}
                    disabled={
                      lessonVideo.review_state !== 'VALIDATED' ||
                      !(
                        isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) ||
                        isCourseManager(lessonVideo.video_set.project.course)
                      )
                    }
                    onClick={async () => {
                      await uploadLessonVideoToVimeo({ variables: { input: { id: lessonVideo.id } } })
                    }}
                  >
                    {vimeoUploadLoading ? (
                      <CircularProgress variant="indeterminate" size="1.5rem" color="primary" id="upload_progress" />
                    ) : lessonVideo.vimeo_url ? (
                      'Republier'
                    ) : (
                      'Publier'
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={
                      !(
                        isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) ||
                        isCourseManager(lessonVideo.video_set.project.course) ||
                        isSetEditor(lessonVideo.video_set)
                      )
                    }
                    variant="outlined"
                    color="primary"
                    onClick={() => setStatusModal(true)}
                  >
                    Changer le statut
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setVideoReviewModalOpen(true)
                    }}
                  >
                    Accès revue
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomModal
        title="Changer le statut de la vidéo"
        size="xs"
        open={statusModal}
        onClose={() => setStatusModal(false)}
      >
        <Grid container spacing={4} justifyContent="space-around">
          {Object.keys(VideoStateEnum)
            .filter((key) => key !== lessonVideo.review_state && key !== 'WAITING')
            .filter((key) => {
              if (lessonVideo?.video_set?.editor?.id.toString() === user?.id?.toString()) {
                if (key === 'TO_CHECK') return true
                return false
              } else {
                return true
              }
            })
            .map((key, index) => {
              return (
                <Grid item key={index}>
                  <Button
                    disabled={lessonVideo.review_state === key}
                    variant="outlined"
                    style={{
                      borderColor: videosCaptions[key as keyof VideosCaptionsType].color,
                      color: videosCaptions[key as keyof VideosCaptionsType].color,
                      width: 150,
                    }}
                    onClick={async () => {
                      await updateLessonVideo({ variables: { input: { id: lessonVideo.id, review_state: key } } })
                      setStatusModal(false)
                    }}
                  >
                    {videosCaptions[key as keyof VideosCaptionsType].label}
                  </Button>
                </Grid>
              )
            })}
        </Grid>
      </CustomModal>
      <CustomModal animation="slide-up" open={isVideoReviewModalOpen} onClose={() => setVideoReviewModalOpen(false)}>
        <VideoReviewModal
          type="lesson_video"
          video={lessonVideo}
          reviews={lessonVideo.reviews}
          diagrams={lessonVideo.video_diagrams}
          refetch={async () => {
            await refetch()
          }}
          timeCode={(location as LocationState).state?.timeCode}
        />
      </CustomModal>

      <CustomModal
        size="xs"
        title="Modifier le nom de la leçon"
        open={editLessonNameModal}
        onClose={() => {
          setEditLessonNameModal(false)
        }}
      >
        <Grid container spacing={1}>
          <TextField
            fullWidth
            name="name"
            label="Nom"
            variant="outlined"
            value={newLessonName}
            onChange={(e) => {
              setNewLessonName(e.target.value)
            }}
            required
          />

          <Grid item container style={{ width: '100%' }} justifyContent="center" spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  await updateLessonMutation({
                    variables: { input: { id: lessonVideo.lesson.id, name: newLessonName } },
                  })
                }}
              >
                Enregistrer
              </Button>
            </Grid>
            <Grid item style={{ paddingBottom: theme.spacing(1) }}>
              <Button
                variant="outlined"
                style={{ color: theme.palette.error.main, border: `1px solid ${theme.palette.error.main}` }}
                onClick={() => {
                  setEditLessonNameModal(false)
                }}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </Layout>
  )
}
export default Video
