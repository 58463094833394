//REACT
import { useState } from 'react'
// COMPONENTS
import { VideoForm } from '../../components/Forms/project'
import { CustomTable, Layout, Title, Loading, Error, CustomModal, Note } from '../../components'
// MATERIAL UI
import { Grid, Chip, Button, Paper, useTheme, Typography } from '@material-ui/core'
//ICONS
import { ImVimeo2 } from 'react-icons/im'
// TYPE
import { LessonVideoType, ProjectType, SortEnum } from '../../../../lib/sharedTypes'
import { ColumnType } from '../../utils/types'
// GQL
import { VIDEO_SET_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
// REACT-ROUTER
import { useNavigate, useParams } from 'react-router-dom'
//UTILS
import { secondsToDHMS, isAuthorized, isCourseManager, isSetEditor } from '../../utils/misc'
import { videosCaptions } from '../../utils/constants'

const Set = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { code_name, set_index } = useParams()
  const [open, setOpen] = useState(false)
  const [selectedVideo] = useState<LessonVideoType>()
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [field, setField] = useState<keyof LessonVideoType>('number')
  const {
    loading,
    error,
    data: { project } = {},
    refetch,
  } = useQuery<{
    project: ProjectType
  }>(VIDEO_SET_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      video_set_where: {
        index: {
          eq: set_index && parseInt(`${set_index}`),
        },
      },
      order: {
        sort: sort,
        field: field,
      },
    },
  })

  const columns: ColumnType<LessonVideoType>[] = [
    {
      title: 'Numéro de vidéo',
      field: 'number',
      render: (row: LessonVideoType) => row.number,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Durée',
      field: 'file_duration',
      render: (row: LessonVideoType) => secondsToDHMS(row.file_duration),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Nom de la leçon',
      field: 'lesson.name',
      render: (row: LessonVideoType) => row?.lesson?.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Statut',
      field: 'review_status',
      render: (row: LessonVideoType) => (
        <Chip
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (code_name && set_index) {
              navigate(`/projects/${code_name}/sets/${set_index}/videos/${row.number}`, { state: { openModal: true } })
            }
          }}
          label={videosCaptions[row.review_state].label}
          style={{ backgroundColor: videosCaptions[row.review_state].color }}
          color="primary"
        />
      ),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Notes',
      field: 'lesson.note',
      render: (row: LessonVideoType) => <Note lesson={row.lesson} refetch={refetch} />,
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Vimeo',
      field: 'vimeo_url',
      render: (row: LessonVideoType) => {
        if (row.vimeo_url) {
          return <ImVimeo2 size="30" color="#3E83F5" />
        } else {
          return <ImVimeo2 size="30" color="#e0e0e0" />
        }
      },
      type: 'String',
      sortable: true,
    },
  ]

  if (loading || !project) {
    return (
      <Layout title={code_name || 'VideoSet'}>
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title={code_name || 'VideoSet'}>
        <Error error={error} />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Set">
        <Error error={'Code name inconnu'} />
      </Layout>
    )
  }

  if (!set_index) {
    return (
      <Layout title="Set">
        <Error error={'Index inconnu'} />
      </Layout>
    )
  }

  const videoSet = project.video_sets[0]
  if (!videoSet) {
    return (
      <Layout title="Set">
        <Error error={'VideoSet inconnu'} />
      </Layout>
    )
  }
  const lessonsTotalDuration = videoSet.lesson_videos.reduce((acc, el) => {
    acc += el.file_duration
    return acc
  }, 0)

  return (
    <Layout title={`${code_name} - Set ${videoSet.index}`}>
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={`Set ${videoSet.index}`} />
          </Grid>
          <Grid item>
            <Button
              disabled={
                !(
                  isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) ||
                  isCourseManager(project.course) ||
                  isSetEditor(videoSet)
                )
              }
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Ajouter une vidéo
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Paper elevation={3} style={{ padding: theme.spacing(1) }}>
            <Grid container justifyContent="space-between">
              <Typography variant="body1">
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Monteur:
                </Typography>
                {videoSet?.editor ? `${videoSet?.editor?.first_name} ${videoSet?.editor?.last_name}` : '-'}
              </Typography>
              <Typography variant="body1">
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Durée des vidéos:
                </Typography>
                {secondsToDHMS(lessonsTotalDuration)}
              </Typography>
              <Typography variant="body1">
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Durée des rushes:
                </Typography>
                {secondsToDHMS(videoSet.duration)}
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <CustomTable
            columns={columns}
            rows={videoSet.lesson_videos}
            field={field}
            sort={sort}
            setSort={setSort}
            setField={setField}
            rowsCount={videoSet.lesson_videos.length}
            onRowClick={(row: LessonVideoType, e: React.MouseEvent) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(`/projects/${code_name}/sets/${set_index}/videos/${row.number}`, '_blank')
              } else {
                navigate(`/projects/${code_name}/sets/${set_index}/videos/${row.number}`)
              }
            }}
          />
        </Grid>
      </Grid>
      <CustomModal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        animation="slide-up"
      >
        <VideoForm
          video={selectedVideo}
          refetch={refetch}
          handleClose={() => {
            setOpen(false)
          }}
          video_set_id={project.video_sets[0].id.toString()}
        />
      </CustomModal>
    </Layout>
  )
}

export default Set
