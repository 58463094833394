//REACT
import React, { useState, useEffect } from 'react'
// ASSETS
import NOIMG_banner from '../../../assets/NOIMG_banner.jpg'
import NOIMG_card from '../../../assets/NOIMG_card.jpg'
import NOIMG_logo from '../../../assets/NOIMG_logo.svg'
// MATERIAL UI
import { Grid, Button, useTheme } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../index'
//TYPES
import { CourseType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_COURSE_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
import { handleError } from '../../../utils/handleError'

type ImageFormPropsType = {
  type: 'banner' | 'card' | 'logo'
  course: CourseType
  refetch: () => Promise<any>
  handleClose: () => void
}

const ImageForm = ({ course, type, refetch, handleClose }: ImageFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [localImg, setLocalImg] = useState<string>()
  const defaultUrl = type === 'banner' ? NOIMG_banner : type === 'logo' ? NOIMG_logo : NOIMG_card
  const s3Url = type === 'banner' ? course.s3_banner : type === 'logo' ? course.s3_logo : course.s3_card
  const imgUrl = localImg ? localImg : s3Url

  useEffect(() => {
    if (!selectedFile) {
      setLocalImg(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setLocalImg(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const [updateCourseMutation] = useMutation<{ updateCourse: CourseType }>(UPDATE_COURSE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `${type} uploadé` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    switch (type) {
      case 'banner':
        await updateCourseMutation({
          variables: {
            input: {
              id: course.id,
              s3_banner: selectedFile,
            },
          },
        })
        break
      case 'logo':
        await updateCourseMutation({
          variables: {
            input: {
              id: course.id,
              s3_logo: selectedFile,
            },
          },
        })
        break
      case 'card':
        await updateCourseMutation({
          variables: {
            input: {
              id: course.id,
              s3_card: selectedFile,
            },
          },
        })
        break
      default:
        break
    }
  }
  return (
    <FormLayout
      type="update"
      title={
        type === 'banner'
          ? 'Mettre à jour la bannière'
          : type === 'card'
          ? 'Mettre à jour la carte'
          : 'Mettre à jour le logo'
      }
      onSubmit={handleSubmit}
      submitDisabled={!localImg}
    >
      <Grid item container justifyContent="center">
        <img
          style={{
            objectFit: 'contain',
            maxWidth: '100%',
            height: 400,
            background: type === 'logo' ? theme.palette.grey[800] : 'unset',
          }}
          src={imgUrl}
          onError={({ currentTarget }) => {
            currentTarget.src = defaultUrl
          }}
          loading="lazy"
          alt="S3_picture"
        />
      </Grid>
      <Grid container justifyContent="center">
        <Button color="primary" variant="contained" component="label">
          Selectionner
          <input hidden accept=".jpg,.jpeg,.png,.svg" type="file" onChange={onSelectFile} />
        </Button>
      </Grid>
    </FormLayout>
  )
}

export default ImageForm
