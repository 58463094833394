import { useState, useCallback, useEffect } from 'react'
// COMPONENTS
import FaqDND from '../../components/Product/FaqDND'
import { DeleteForm } from '../../components/Forms'
import { FaqForm } from '../../components/Forms/product'
import { Loading, Error, CustomModal } from '../../components'
// MATERIAL UI
import { Grid, Button, Divider, Typography, useTheme, Paper, Menu, MenuItem } from '@material-ui/core'
// TYPE
import { ProductType, ProductFaqType } from '../../../../lib/sharedTypes'
// GQL
import { PRODUCT_FAQ_QUERY } from '../../gql/queries'
import { UPDATE_PRODUCT_MUTATION } from '../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
// REACT-ROUTER
import { useOutletContext, useParams } from 'react-router-dom'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
import { isAuthorized } from '../../utils/misc'
//DND
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const FAQ = () => {
  const { refetchProduct } = useOutletContext<{ refetchProduct: () => Promise<any> }>()
  const theme = useTheme()
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [updateModal, setUpdateModal] = useState('')
  const [selectedFaq, setSelectedFaq] = useState<ProductFaqType | undefined>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const menuOpen = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState(false)
  const [productFAQ, setProductFAQ] = useState<ProductFaqType[]>([])
  const [updateproduct] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
    },
  })

  if (!code_name) {
    return <Error error={'Unknown code_name'} />
  }

  const {
    loading,
    error,
    data: { product } = {},
    refetch,
  } = useQuery<{
    product: ProductType
  }>(PRODUCT_FAQ_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
    async onCompleted(data) {
      setProductFAQ(data.product.seo_faq.sort((a, b) => a.position - b.position))
      await refetchProduct()
    },
  })

  const handleMoveDND = useCallback((dragIndex: number, hoverIndex: number) => {
    setProductFAQ((prevFAQs) =>
      update(prevFAQs, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevFAQs[dragIndex]],
        ],
      }),
    )
  }, [])

  useEffect(() => {
    if (product && !isDragging && JSON.stringify(productFAQ) !== JSON.stringify(product.seo_faq)) {
      const productInput = {
        id: product.id,
        seo_faq: productFAQ.map((qa, index) => {
          return { id: qa.id, question: qa.question, answer: qa.answer, position: index }
        }),
      }
      updateproduct({ variables: { input: productInput } })
        .then(() => {
          console.log('SEO_FAQ UPDATED')
        })
        .catch((err) => console.error(err))
    }
  }, [productFAQ, isDragging])

  if (loading || !product) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column" style={{ height: '100%', flexGrow: 1, display: 'flex' }}>
      {/* FAQ */}
      <Paper
        style={{
          padding: theme.spacing(2),
          marginTop: theme.spacing(2),
          flexGrow: 1,
          display: 'flex',
          width: '100%',
        }}
      >
        <Grid item style={{ width: '100%' }}>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Questions/réponses</Typography>
            <Button
              color="primary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'PROJECT', 'SEO'])}
              onClick={() => {
                setUpdateModal('faq')
              }}
            >
              Ajouter
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item style={{ overflowY: 'auto' }}>
            <DndProvider backend={HTML5Backend}>
              {productFAQ.length !== 0 &&
                productFAQ.map((qa, index) => {
                  return (
                    <FaqDND
                      qa={qa}
                      id={qa.id}
                      setIsDragging={(isDragging) => {
                        setIsDragging(isDragging)
                      }}
                      index={index}
                      moveCard={handleMoveDND}
                      key={qa.id}
                      setSelectedFaq={() => setSelectedFaq(qa)}
                      setAnchorEl={(event) => {
                        setAnchorEl(event.currentTarget)
                      }}
                    />
                  )
                })}
            </DndProvider>
          </Grid>
        </Grid>
      </Paper>
      <CustomModal
        open={updateModal === 'faq'}
        onClose={() => {
          setUpdateModal('')
          setSelectedFaq(undefined)
        }}
        animation="slide-up"
      >
        <FaqForm
          product={product}
          productFaq={selectedFaq}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
            setSelectedFaq(undefined)
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer la question/réponse"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            const productInput = {
              id: product.id,
              seo_faq: productFAQ
                .filter((qa) => qa.id !== selectedFaq?.id)
                .map((qa) => {
                  return { id: qa.id, question: qa.question, answer: qa.answer }
                }),
            }

            await updateproduct({ variables: { input: productInput } })
            setSelectedFaq(undefined)
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedFaq(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedFaq(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          disabled={!isAuthorized(['ADMIN', 'PROJECT', 'SEO'])}
          onClick={() => {
            setUpdateModal('faq')
            setAnchorEl(null)
          }}
        >
          Modifier la question/réponse
        </MenuItem>
        <MenuItem
          disabled={!isAuthorized(['ADMIN', 'PROJECT', 'SEO'])}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la question/réponse
        </MenuItem>
      </Menu>
    </Grid>
  )
}

export default FAQ
