import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, MenuItem } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
import { Error } from '../../../index'
//CONSTANTS
import { videosCaptions } from '../../../../utils/constants'
//TYPE
import { LessonType, VideoStateEnum, RoleType, LessonVideoType } from '../../../../../../lib/sharedTypes'
import { VideosCaptionsType } from '../../../../utils/types'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_LESSON_VIDEO_MUTATION } from '../../../../gql/mutations'
import { useAlert } from '../../../../hooks'
//REDUX
import { useAppSelector } from '../../../../hooks/reducerHooks'
//UTILS
import { handleError } from '../../../../utils/handleError'

type LessonVideoFormPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const LessonVideoForm = ({ lesson, refetch, handleClose }: LessonVideoFormPropsType) => {
  const [setAlert] = useAlert()
  const user = useAppSelector((state) => state.user.user)
  const userRoles = user && user.roles ? user.roles.map((role: RoleType) => role.category as string) : []
  const [stateLesson, setStateLesson] = useState<LessonType | undefined>(lesson)
  const [updateLessonVideoMutation] = useMutation<{
    updateLessonVideo: LessonVideoType
  }>(UPDATE_LESSON_VIDEO_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `Leçon vidéo ${data.updateLessonVideo.number} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (!stateLesson) {
    return <Error error={'Leçon inconnue'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonVideoInput = {
      id: stateLesson.video.id,
      review_state: stateLesson.video.review_state,
      vimeo_url: stateLesson.video.vimeo_url,
    }
    await updateLessonVideoMutation({ variables: { input: lessonVideoInput } })
  }

  return (
    <FormLayout
      type={stateLesson?.id ? 'update' : 'create'}
      title={stateLesson?.id ? 'Mettre à jour la leçon vidéo' : 'Créer une leçon vidéo'}
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateLesson) === JSON.stringify(lesson)}
    >
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            id="video.vimeo_status"
            label="Statut revue"
            variant="outlined"
            defaultValue={stateLesson.video.review_state}
            value={stateLesson.video.review_state || ''}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              },
            }}
            onChange={(event) => {
              setStateLesson((prevState) => {
                if (!prevState) return prevState
                return {
                  ...prevState,
                  ...{ video: { ...prevState.video, review_state: event.target.value as VideoStateEnum } },
                }
              })
            }}
            required
          >
            {Object.keys(VideoStateEnum).map((status) => (
              <MenuItem id="status" key={status} value={status}>
                {videosCaptions[status as keyof VideosCaptionsType].label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Vimeo URL"
            id="video.vimeo_url"
            defaultValue={stateLesson?.video.vimeo_url}
            onChange={(event) => {
              setStateLesson((prevState) => {
                if (!prevState) return prevState
                return {
                  ...prevState,
                  ...{ video: { ...prevState.video, vimeo_url: event.target.value } },
                }
              })
            }}
            variant="outlined"
            fullWidth
            disabled={userRoles.includes('ADMIN') ? false : true}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default LessonVideoForm
