// REACT
import { useState, useEffect } from 'react'
// MATERIAL UI
import { MdMoreVert, MdOutlineCheckCircleOutline, MdHighlightOff } from 'react-icons/md'
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Button,
  Chip,
  Paper,
  Link,
  makeStyles,
  useTheme,
} from '@material-ui/core'

// COMPONENTS
import { DeleteForm } from '../../components/Forms'
import { CustomTable } from '../../components'
import { CatalogFoSection, Managers, ProductParts } from '../../components/Product'
import {
  GeneralInformationsForm,
  SEOForm,
  SkillForm,
  CertificationForm,
  PostPublicationForm,
  OpportunityForm,
  FormationStepsForm,
} from '../../components/Forms/product'
import { Loading, Error, CustomModal, TextEditor } from '../../components'
// GQL
import { DELETE_PRODUCT_POST_PUBLICATION } from '../../gql/mutations'
import { PRODUCT_INFO_QUERY } from '../../gql/queries'
import { useQuery, useMutation } from '@apollo/client'
// TYPE
import { ProductType, ProductPostPublicationType } from '../../../../lib/sharedTypes'
// UTILS
import { secondsToDHMS, isAuthorized } from '../../utils/misc'
import { ColumnType, ProductFormationSteps, ProductJobOpportunity } from '../../utils/types'
import { handleError } from '../../utils/handleError'
// REACT ROUTER
import { useOutletContext, useParams } from 'react-router'
import { useLocation } from 'react-router'
import { Alert } from '@material-ui/lab'
//HOOKS
import { useAlert } from '../../hooks'

type LocationState = {
  state: {
    openModal: string
  }
}

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const GeneralInformations = () => {
  const { refetchProduct } = useOutletContext<{ refetchProduct: () => Promise<any> }>()
  const { code_name } = useParams()
  const classes = useStyles()
  const theme = useTheme()
  const [updateModal, setUpdateModal] = useState('')
  const location = useLocation()
  const [isFinancedByCPF, setIsFinancedByCPF] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<ProductPostPublicationType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [setAlert] = useAlert()

  const [deleteProductPostPublication] = useMutation<{ deleteVirtualClassroom: boolean }>(
    DELETE_PRODUCT_POST_PUBLICATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Publication supprimée` })
        await refetch()
      },
    },
  )

  const columns: ColumnType<ProductPostPublicationType>[] = [
    {
      title: 'Version',
      field: 'publication_version',
      render: (row) => row.publication_version,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Code produit',
      field: 'new_product_code',
      render: (row) => row.new_product_code,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Type',
      field: 'type',
      render: (row) => row.type,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Slug forum',
      field: 'forum_slug',
      render: (row) => row.forum_slug,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Classe virtuelle',
      field: 'has_cv',
      render: (row) =>
        row.has_cv ? (
          <MdOutlineCheckCircleOutline style={{ color: theme.palette.success.main }} size="22" />
        ) : (
          <MdHighlightOff style={{ color: theme.palette.error.main }} size="22" />
        ),
      type: 'String',
      sortable: false,
    },
    {
      title: 'Durée FC',
      field: 'fc_duration',
      render: (row) => row.fc_duration,
      type: 'String',
      sortable: false,
    },
    {
      title: '',
      field: 'id',
      render: (row) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]
  const {
    loading,
    error,
    data: { product } = {},
    refetch,
  } = useQuery<{ product: ProductType }>(PRODUCT_INFO_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
    async onCompleted() {
      await refetchProduct()
    },
  })

  useEffect(() => {
    const { state } = location as LocationState
    if (state && state.openModal) {
      setUpdateModal(state.openModal)
    }
  }, [location])

  if (loading || !product) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column" style={{ width: '100%' }}>
      {/* DESCRIPTION */}
      <Paper className={classes.paper} style={{ width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Description</Typography>
            <Button
              color="primary"
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              variant="outlined"
              onClick={() => {
                setUpdateModal('description')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item container direction="row" spacing={2}>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Nom de code
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{product.code_name}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Durée de la formation
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{product.duration ? secondsToDHMS(product.duration) : '-'}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Affichage showcase
                </Typography>
              </Grid>

              <Grid item>
                {product.status === 'AVAILABLE' ? (
                  <Chip
                    size="small"
                    label={'Affiché'}
                    style={{
                      margin: theme.spacing(1),
                      backgroundColor: theme.palette.success.main,
                      color: theme.palette.success.contrastText,
                    }}
                  />
                ) : (
                  <Chip
                    size="small"
                    label={'Non affiché'}
                    style={{
                      margin: theme.spacing(1),
                      backgroundColor: theme.palette.error.main,
                      color: theme.palette.error.contrastText,
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Inscription autonome
                </Typography>
              </Grid>
              <Grid item>
                {product.available_for_self_registration ? (
                  <Chip
                    size="small"
                    label={'Disponible'}
                    style={{
                      margin: theme.spacing(1),
                      backgroundColor: theme.palette.success.main,
                      color: theme.palette.success.contrastText,
                    }}
                  />
                ) : (
                  <Chip
                    size="small"
                    label={'Indisponible'}
                    style={{
                      margin: theme.spacing(1),
                      backgroundColor: theme.palette.error.main,
                      color: theme.palette.error.contrastText,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Titre
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{product.title}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Sous-titre
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{product.subtitle}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Titre SMS
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{!product.short_name ? '-' : product.short_name}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Compte démo
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {!product.demo_formation_code ? '-' : product.demo_formation_code}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Date de sortie
                </Typography>
              </Grid>
              <Grid item>
                {product.release_date ? (
                  new Date(product.release_date).toLocaleString('fr-FR', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                ) : (
                  <Alert severity="warning">Requis pour publier la fiche produit</Alert>
                )}
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Format
                </Typography>
              </Grid>
              <Grid item>{product.format}</Grid>
            </Grid>
            <Grid item container direction="column" sm={3}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Classe virtuelle
                </Typography>
              </Grid>
              <Grid item>{product.has_cv ? 'Oui' : 'Non'}</Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Public adressé
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{product.public}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Prérequis
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{product.prerequisite}</Typography>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Description de la formation
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{product.preamble}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item container direction="column" sm={6}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Le produit est affiché avec le macaron "Nouveau"
              </Typography>
            </Grid>
            <Grid item>{product.marked_as_new ? 'Oui' : 'Non'}</Grid>
          </Grid>
          <Grid item container direction="column" sm={6}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Typologie de la formation
              </Typography>
            </Grid>
            <Grid item>{product.epp_available ? 'Programme intégré' : 'Formation continue'}</Grid>
          </Grid>
        </Grid>

        <Grid item container direction="row">
          <Grid item container direction="column" xs={6}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Formateur(s)
              </Typography>
            </Grid>
            <Grid item>
              {product.teachers.map((teacher, index) => (
                <Chip
                  key={index}
                  size="small"
                  label={teacher.first_name + ' ' + teacher.last_name}
                  color="primary"
                  style={{ margin: theme.spacing(1) }}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={6}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Chef(s) de projet
              </Typography>
            </Grid>
            <Managers productId={product.id} />
          </Grid>
          <Grid item container>
            <Grid item container direction="column" sm={12}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Fiches produit en alias
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{product.aliases ? product.aliases.join(', ') : '-'}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Méthodes pédagogiques
                </Typography>
              </Grid>
              <Grid item>
                {product.teaching_methods.length > 0 &&
                  product.teaching_methods.map((method, index) => (
                    <Chip
                      key={index}
                      size="small"
                      label={method.name}
                      color="primary"
                      style={{ margin: theme.spacing(1) }}
                    />
                  ))}
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={6}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Push score
                </Typography>
              </Grid>
              <Grid item>{product.push_score}</Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Produits recommandés sur la fiche
              </Typography>
            </Grid>
            <Grid item>
              {product.recommendations.length > 0 &&
                product.recommendations.map((reco, index) => (
                  <Chip
                    key={index}
                    size="small"
                    label={reco.code_name}
                    color="primary"
                    style={{ margin: theme.spacing(1) }}
                  />
                ))}
            </Grid>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Public
              </Typography>
            </Grid>
            <Grid item>
              {product.public_keywords?.map((pk, index) => (
                <Chip key={index} size="small" label={pk} color="primary" style={{ margin: theme.spacing(1) }} />
              ))}
            </Grid>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Points forts de la formation
              </Typography>
            </Grid>
            <Grid item>
              {product.key_strengths_formation?.map((ksf, index) => (
                <Chip key={index} size="small" label={ksf} color="primary" style={{ margin: theme.spacing(1) }} />
              ))}
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Points forts Walter Learning
              </Typography>
            </Grid>
            <Grid item>
              {product.key_strengths_walter?.map((ksw, index) => (
                <Chip key={index} size="small" label={ksw} color="primary" style={{ margin: theme.spacing(1) }} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* EXPERTISES ET FINANCEMENTS */}
      <CatalogFoSection code_name={product.code_name} refetch={refetch} setIsFinancedByCPF={setIsFinancedByCPF} />
      {/* SEO & REF */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">SEO & Référencement</Typography>
            <Button
              color="primary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              onClick={() => {
                setUpdateModal('seo')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item container direction="row">
            <Grid item container direction="column" sm={6}>
              <Grid item>
                <Typography variant="body1" color="textSecondary" className={classes.title}>
                  Titre SEO
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{product.seo_title}</Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column" sm={6}>
              <Grid item>
                <Typography variant="body1" color="textSecondary" className={classes.title}>
                  URL
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {product.seo_url ? (
                    product.seo_url
                  ) : (
                    <Alert severity="warning">Requis pour publier la fiche produit</Alert>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction="row">
            <Grid item container direction="column" sm={6}>
              <Grid item>
                <Typography variant="body1" color="textSecondary" className={classes.title}>
                  URL canonique
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{product.canonical_url || '-'}</Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column" sm={6}>
              <Grid item>
                <Typography variant="body1" color="textSecondary" className={classes.title}>
                  Breadcrumb title
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {product.breadcrumb_title ? (
                    product.breadcrumb_title
                  ) : (
                    <Alert severity="warning">Requis pour publier la fiche produit</Alert>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="body1" color="textSecondary" className={classes.title}>
              Metadescription
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{product.seo_meta_description}</Typography>
          </Grid>
        </Grid>
      </Paper>
      {/* PLAN */}
      <ProductParts productId={product.id} refetch={refetch} />
      {/* GOALS */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Compétences</Typography>
            <Button
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('skill')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="body1" color="textSecondary" className={classes.title}>
              Titre des compétences
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{product.skills_title}</Typography>
          </Grid>
        </Grid>

        <Grid item container direction="column">
          <Grid item>
            <Typography variant="body1" color="textSecondary" className={classes.title}>
              Présentation des compétences
            </Typography>
          </Grid>
          <Grid item key={new Date(product.updated_at).getTime()}>
            <TextEditor text={product.skills_introduction} readOnly={true} />
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          {product.skills
            .sort((a, b) => a.position - b.position)
            .map((skill, index) => {
              return (
                <Grid key={index} item container direction="column" spacing={3}>
                  <Grid item container direction="row" spacing={3}>
                    <Grid item container direction="column" xs={5}>
                      <Grid item>
                        <Typography variant="body1" color="textSecondary" className={classes.title}>
                          Compétence n°{index + 1}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">{skill.name}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={7}>
                      <Grid item>
                        <Typography variant="body1" color="textSecondary" className={classes.title}>
                          Présentation
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">{skill.description}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Divider />
                  </Grid>
                </Grid>
              )
            })}
        </Grid>
      </Paper>
      {/* OPPORTUNITIES */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Débouchés</Typography>
            <Button
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('opportunity')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          {(product.job_opportunities as ProductJobOpportunity[])?.map((opportunity, index) => {
            return (
              <Grid key={index} item container direction="column" spacing={3}>
                <Grid item container direction="row" spacing={3}>
                  <Grid item container direction="column" xs={5}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        Débouché n°{index + 1}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{opportunity.name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" xs={7}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        Description
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{opportunity.description}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Divider />
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Paper>
      {/* FORMATION STEPS */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Étapes de la formation</Typography>
            <Button
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('formation_steps')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          {(product.formation_steps as ProductFormationSteps[])?.map((opportunity, index) => {
            return (
              <Grid key={index} item container direction="column" spacing={3}>
                <Grid item container direction="row" spacing={3}>
                  <Grid item container direction="column" xs={3}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        Étape n°{index + 1}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{opportunity.name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" xs={3}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        Durée
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{opportunity.duration}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" xs={3}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        En ligne
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{opportunity.is_online ? 'Oui' : 'Non'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" xs={3}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        Pause
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{opportunity.is_pause ? 'Oui' : 'Non'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Divider />
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Paper>
      {/* CERTIFICATION */}
      {isFinancedByCPF && (
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Certifications</Typography>
              <Button
                color="primary"
                disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
                variant="outlined"
                onClick={() => {
                  setUpdateModal('certification')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item container direction="column" xs={4}>
              <Grid item>
                <Typography variant="body1" color="textSecondary" className={classes.title}>
                  Nom de la certification
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{product?.certification?.name}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={4}>
              <Grid item>
                <Typography variant="body1" color="textSecondary" className={classes.title}>
                  Organisme de certification
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{product?.certification?.certifier?.full_name}</Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={4}>
              <Grid item>
                <Typography variant="body1" color="textSecondary" className={classes.title}>
                  Code de la certification (RSXXXX)
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{product?.certification?.code}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" color="textSecondary" className={classes.title}>
                Lien vers france compétence
              </Typography>
            </Grid>
            <Grid item>
              {product?.certification?.href && (
                <Link href={product?.certification?.href}>{product?.certification?.href}</Link>
              )}
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="body1" color="textSecondary" className={classes.title}>
              Modalité de la certification
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{product?.certification?.text}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="textSecondary" className={classes.title}>
              Message
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{product?.certification?.message}</Typography>
          </Grid>
        </Paper>
      )}
      {/* POST PUBLICATION */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%', maxWidth: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Post publication</Typography>
            <Button
              color="primary"
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              variant="outlined"
              onClick={() => {
                setUpdateModal('post_publication')
              }}
            >
              Ajouter
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item direction="row">
          <CustomTable columns={columns} rows={product.post_publications} />
        </Grid>
      </Paper>

      <CustomModal
        open={updateModal === 'description'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <GeneralInformationsForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>

      <CustomModal
        open={updateModal === 'seo'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <SEOForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'skill'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <SkillForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'opportunity'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <OpportunityForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'formation_steps'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <FormationStepsForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'certification'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <CertificationForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'post_publication'}
        onClose={() => {
          setUpdateModal('')
          setSelectedRow(undefined)
        }}
        animation="slide-up"
      >
        <PostPublicationForm
          product={product}
          productPostPublication={selectedRow}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
            setSelectedRow(undefined)
          }}
        />
      </CustomModal>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            setUpdateModal('post_publication')
          }}
        >
          Modifier la publication
        </MenuItem>
        <MenuItem
          disabled={!isAuthorized(['ADMIN', 'PRODUCTION'])}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la publication
        </MenuItem>
      </Menu>
      <CustomModal
        size="xs"
        title={`Supprimer la publication`}
        open={deleteModal}
        onClose={() => {
          setDeleteModal(true)
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteProductPostPublication({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            setAnchorEl(null)
            await refetch()
          }}
          onCancel={() => {
            setDeleteModal(false)
            setAnchorEl(null)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default GeneralInformations
