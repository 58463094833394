import { useEffect, useState } from 'react'
//MATERIAL-UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, alpha, Button, makeStyles, TextField, CircularProgress } from '@material-ui/core'
//GQL
import { COURSES_QUERY, COURSE_PLAN_QUERY } from '../../../gql/queries'
import { IMPORT_COURSE_PARTS_MUTATION } from '../../../gql/mutations'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
//TYPES
import { CoursePartType, CourseType, LessonType } from '../../../../../lib/sharedTypes'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

const useStyles = makeStyles((theme) => ({
  deleteBTN: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.1),
    },
  },
  popper: {
    zIndex: 10000,
  },
}))

type ImportCoursePartsPropsType = {
  course: CourseType
  handleClose: () => void
  refetch: () => Promise<any>
}

const ImportCourseParts = ({ handleClose, refetch, course }: ImportCoursePartsPropsType) => {
  const classes = useStyles()
  const [setAlert] = useAlert()
  const [selectedLessons, setSelectedLessons] = useState<LessonType[]>()
  const [selectedCourse, setSelectedCourse] = useState<CourseType>()
  const [selectedCoursePlan, setSelectedCoursePlan] =
    useState<{ part: CoursePartType; sub_part: CoursePartType; ss_part: CoursePartType }[]>()

  const { data: { courses = [] } = {} } = useQuery<{
    courses: CourseType[]
  }>(COURSES_QUERY, {
    variables: {
      order: {
        field: 'CODE_NAME',
        sort: 'ASC',
      },
    },
  })

  const [getCoursePlan] = useLazyQuery<{
    course: CourseType
  }>(COURSE_PLAN_QUERY, {
    onCompleted(data) {
      const lessonplan = data.course.parts.reduce(
        (acc: { part: CoursePartType; sub_part: CoursePartType; ss_part: CoursePartType }[], part) => {
          part?.sub_parts?.map((sub_part) => {
            sub_part.sub_parts.map((ss_part) => {
              acc.push({
                part: part,
                sub_part: sub_part,
                ss_part: ss_part,
              })
            })
          })
          return acc
        },
        [],
      )
      setSelectedCoursePlan(lessonplan)
    },
  })

  useEffect(() => {
    if (selectedCourse?.id) {
      getCoursePlan({
        variables: {
          where: {
            id: {
              eq: parseInt(`${selectedCourse?.id}`),
            },
          },
          order: {
            field: 'position',
            sort: 'ASC',
          },
        },
      })
        .then((resp) => {
          console.log('resp', resp)
        })
        .catch((err) => console.error(err))
    }
  }, [selectedCourse])

  const [importCourseParts, { loading }] = useMutation<{ importCourseParts: boolean }>(IMPORT_COURSE_PARTS_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Séquencier en cours d'import` })
      await refetch()
      handleClose()
    },
  })

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Autocomplete
          fullWidth
          classes={{ popper: classes.popper }}
          options={courses}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option?.code_name || ''}
          onChange={(_, value) => {
            if (value) {
              setSelectedCourse(value)
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} style={{ zIndex: 10000 }} variant="outlined" label="Formation" />
          }}
        />
      </Grid>
      <Grid item>
        {selectedCoursePlan && (
          <Autocomplete
            multiple
            fullWidth
            limitTags={2}
            groupBy={(option) => option.sub_part.name}
            classes={{ popper: classes.popper }}
            options={selectedCoursePlan}
            getOptionSelected={(option, value) => option.ss_part.lesson.id === value.ss_part.lesson.id}
            getOptionLabel={(option) => option.ss_part.lesson.name || ''}
            onChange={(_, value) => {
              if (value) {
                setSelectedLessons(value.map((option) => option?.ss_part?.lesson))
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} style={{ zIndex: 10000 }} variant="outlined" label="Leçons" />
            }}
          />
        )}
      </Grid>

      <Grid
        item
        container
        justifyContent="space-around"
        alignItems="center"
        style={{ height: '100%', width: '100%' }}
        spacing={1}
      >
        <Grid item>
          <Button
            variant="outlined"
            disabled={!selectedCourse}
            style={{ width: 110 }}
            color="primary"
            onClick={async () => {
              await importCourseParts({
                variables: {
                  input: {
                    from: { id: selectedCourse?.id },
                    to: { id: course.id },
                    selectedLessons: selectedLessons?.map((l) => {
                      return { id: l.id }
                    }),
                  },
                },
              })
            }}
          >
            {!loading ? 'Importer' : <CircularProgress variant="indeterminate" size="1.5rem" id="upload_progress" />}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" className={classes.deleteBTN} onClick={handleClose}>
            Annuler
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ImportCourseParts
