//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, FormGroup, FormControlLabel, Switch, MenuItem } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
//TYPES
import { DeepPartial } from '../../../../utils/types'
import { LessonDocumentType, LessonType, LessonDocumentTypeEnum } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_LESSON_DOCUMENT_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//UTILS
import { handleError } from '../../../../utils/handleError'

type PdfFormPropsType = {
  lesson?: LessonType
  refetch: () => void
  handleClose: () => void
}

const PdfForm = ({ lesson, refetch, handleClose }: PdfFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const [updateLessonDocumentMutation] = useMutation<{
    updateLessonDocument: LessonDocumentType
  }>(UPDATE_LESSON_DOCUMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Leçon document mise-à-jour` })
      refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonDocumentInput: Record<string, any> = {
      id: stateLesson?.document?.id,
      downloadable: stateLesson?.document?.downloadable,
      type: stateLesson?.document?.type,
    }
    await updateLessonDocumentMutation({
      variables: {
        input: lessonDocumentInput,
      },
    })
  }

  return (
    <FormLayout
      type={'update'}
      title={'Mettre à jour la leçon document'}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id ? JSON.stringify(stateLesson) === JSON.stringify(lesson) : stateLesson === undefined
      }
    >
      <Grid item container spacing={2}>
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateLesson?.document?.downloadable}
                  onChange={(event) => {
                    setStateLesson((prevState) => {
                      return {
                        ...prevState,
                        document: {
                          ...prevState?.document,
                          downloadable: event.target.checked,
                        },
                      }
                    })
                  }}
                  name="downloadable"
                />
              }
              label={stateLesson?.document?.downloadable ? 'Document téléchargeable' : 'Document non-téléchargeable'}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            id="job"
            label="Type de document"
            variant="outlined"
            defaultValue={stateLesson?.document?.type}
            value={stateLesson?.document?.type || ''}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              },
            }}
            onChange={(event) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  document: {
                    ...prevState?.document,
                    type: event.target.value as LessonDocumentTypeEnum,
                  },
                }
              })
            }}
            required
          >
            {Object.keys(LessonDocumentTypeEnum).map((type) => (
              <MenuItem id="type" key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default PdfForm
