//REACT
import { useState } from 'react'
//MATERIAL UI
import {
  Grid,
  Typography,
  useTheme,
  IconButton,
  LinearProgress,
  Slider,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core'
import React from 'react'
//ICONS
import {
  MdCheck,
  MdOutlinePlayCircleOutline,
  MdOutlinePauseCircleOutline,
  MdVolumeUp,
  MdVolumeDown,
  MdVolumeOff,
  MdOutlineSettings,
} from 'react-icons/md'
//TYPES
import { LocalReviewType } from '../../../utils/types'
import { LessonVideoDiagramType } from '../../../../../lib/sharedTypes'
//UTILS
import { secondsToDHMS, stringToColor } from '../../../utils/misc'

type VideoControlsPropsType = {
  video: HTMLVideoElement
  isPlaying: boolean
  progress: number
  handleSkip: (pos: number) => void
  level: number
  setLevel: (level: number) => void
  duration: number
  reviews: LocalReviewType[]
  diagrams: LessonVideoDiagramType[]
  reviewsFilter: string[]
}

const useStyles = makeStyles((theme) => ({
  linearProgressContainer: {
    flexGrow: 1,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  linearProgress: {
    cursor: 'pointer',
    '&:hover': {
      height: 6,
    },
  },
  reviewDot: {
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    bottom: 0,
    height: '14px',
    width: '14px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  diagramDot: {
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    bottom: 0,
    height: '14px',
    width: '14px',
    background: theme.palette.secondary.main,
    // borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.secondary.light,
    },
  },
}))

const VideoControls = ({
  video,
  isPlaying,
  progress,
  handleSkip,
  level,
  setLevel,
  duration,
  reviews,
  diagrams,
  reviewsFilter,
}: VideoControlsPropsType) => {
  const theme = useTheme()
  const classes = useStyles()
  const [playbackRate, setPlayBackRate] = useState(1)
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null)
  return (
    <Grid item style={{ width: '100%' }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item style={{ paddingRight: theme.spacing(1) }}>
          {isPlaying ? (
            <IconButton
              onClick={() => {
                video.pause()
              }}
              style={{ padding: 0 }}
            >
              <MdOutlinePauseCircleOutline size="32" style={{ color: theme.palette.primary.main, padding: 0 }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={async () => {
                await video.play()
              }}
              style={{ padding: 0 }}
            >
              <MdOutlinePlayCircleOutline size="32" style={{ color: theme.palette.primary.main, padding: 0 }} />
            </IconButton>
          )}
        </Grid>
        <Grid item style={{ flexGrow: 6 }}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item className={classes.linearProgressContainer}>
              {reviews
                .filter((review) => {
                  return review.time_code <= duration
                })
                .filter((review) => {
                  if (review.state) {
                    return reviewsFilter.includes(review.state)
                  }
                  return true
                })
                .map((review, index) => {
                  return (
                    <div
                      key={index}
                      className={classes.reviewDot}
                      style={{
                        left: `calc(${(review.time_code / duration) * 100}% - 7px)`,
                        background: stringToColor(JSON.stringify(review)),
                      }}
                      onClick={() => {
                        handleSkip(review.time_code)
                      }}
                    />
                  )
                })}
              {diagrams.map((diagram, index) => {
                return (
                  <div
                    key={index}
                    className={classes.diagramDot}
                    style={{
                      left: `calc(${(diagram.time_code / duration) * 100}% - 7px)`,
                    }}
                    onClick={() => {
                      handleSkip(diagram.time_code)
                    }}
                  />
                )
              })}
              <LinearProgress
                variant="determinate"
                value={progress}
                className={classes.linearProgress}
                onClick={(event) => {
                  const rect = event.currentTarget.getBoundingClientRect()
                  handleSkip(((event.clientX - rect.left) / rect.width) * duration)
                }}
              />
            </Grid>
            <Grid item style={{ paddingLeft: theme.spacing(1) }}>
              <Typography variant="caption">{secondsToDHMS(video.currentTime)}</Typography> /
              <Typography variant="caption">{secondsToDHMS(duration)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ padding: 0, paddingRight: theme.spacing(1), paddingLeft: theme.spacing(1) }}>
          <MdOutlineSettings
            size="24"
            style={{ color: theme.palette.primary.main, cursor: 'pointer', padding: 0 }}
            onClick={(event: React.MouseEvent<SVGElement>) => {
              setAnchorEl(event.currentTarget)
            }}
          />
        </Grid>
        <Grid item style={{ flexGrow: 1, padding: 0 }}>
          <Grid container alignItems="center" justifyContent="space-between" style={{ padding: 0 }}>
            <Grid item>
              {level === 0 ? (
                <MdVolumeOff
                  size="24"
                  style={{ color: theme.palette.primary.main, cursor: 'pointer', padding: 0 }}
                  onClick={() => {
                    setLevel(25)
                  }}
                />
              ) : (
                <MdVolumeDown
                  size="24"
                  style={{ color: theme.palette.primary.main, cursor: 'pointer', padding: 0 }}
                  onClick={() => {
                    setLevel(0)
                  }}
                />
              )}
            </Grid>
            <Grid item style={{ flexGrow: 1, paddingRight: theme.spacing(2), paddingLeft: theme.spacing(2) }}>
              <Slider
                min={0}
                max={100}
                value={level}
                onChange={(_, newValue) => {
                  setLevel(newValue as number)
                }}
                aria-labelledby="continuous-slider"
              />
            </Grid>
            <Grid item>
              <MdVolumeUp
                onClick={() => {
                  setLevel(100)
                }}
                size="24"
                style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {[3, 2.5, 2, 1.5, 1.25, 1, 0.75, 0.5].map((speed) => {
          return (
            <MenuItem
              key={speed}
              onClick={() => {
                setPlayBackRate(speed)
                video.playbackRate = speed
                setAnchorEl(null)
              }}
            >
              <Grid item container alignItems="center" style={{ width: 20, paddingRight: theme.spacing(1) }}>
                {playbackRate === speed && <MdCheck size="20" />}
              </Grid>

              {speed === 1 ? 'Standard' : `${speed}x`}
            </MenuItem>
          )
        })}
      </Menu>
    </Grid>
  )
}

export default VideoControls
