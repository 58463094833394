//REDUX
import { useAppDispatch } from './reducerHooks'
import { AlertAction } from '../store/reducers/alertReducer'

type UseAlertPropsType = {
  severity: 'success' | 'info' | 'warning' | 'error'
  content: string
}

const useAlert = () => {
  const dispatch = useAppDispatch()

  const setAlert = ({ severity, content }: UseAlertPropsType) => {
    dispatch(AlertAction({ severity, content }))
  }

  return [setAlert]
}

export default useAlert
