import { ApolloClient, from, InMemoryCache, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import jwtDecode from 'jwt-decode'
//UTILS
import config from './config'
//REDUX
import { store } from '../store/store'
import { refreshTokensAction, logOutAction } from '../store/reducers/authReducer'
//TYPES
import type { TokensType, DecodedToken } from '../../../lib/sharedTypes'
//UPLOAD
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js'
import { RetryLink } from '@apollo/client/link/retry'

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      console.log('retry', error)
      return !!error
    },
  },
})

type refreshTokensResponseType = {
  errors: string
  data: { refreshTokens: TokensType }
}

const refreshTokens = async () => {
  const state = store.getState()
  const refreshToken = state.auth.refreshToken
  const request = await fetch(config.API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `query refreshTokens($refreshToken: String!) {
        refreshTokens(refreshToken: $refreshToken) {
          accessToken
          refreshToken
        }
      }
    `,
      variables: {
        refreshToken,
      },
    }),
  })
  const resp = (await request.json()) as refreshTokensResponseType
  if (resp.errors !== undefined) {
    store.dispatch(logOutAction())
  } else {
    store.dispatch(refreshTokensAction({ ...resp.data.refreshTokens }))
  }
}

const getAuthHeaders = async (): Promise<string> => {
  let state = store.getState()
  let accessToken = state.auth.accessToken
  if (accessToken) {
    const decodedAccessToken: DecodedToken = jwtDecode(accessToken)
    const accessTokenValid = decodedAccessToken.exp * 1000 > new Date().getTime()
    if (!accessTokenValid) {
      await refreshTokens()
    }
  }
  state = store.getState()
  accessToken = state.auth.accessToken
  return accessToken
}

// const httpLink = createHttpLink({
//   uri: config.API_URL,
//   credentials: 'same-origin',
// })

const authLink = setContext(async (_, { headers }) => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      'apollo-require-preflight': true,
      Authorization: await getAuthHeaders(),
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }): void => {
  if (graphQLErrors) {
    console.log(`graphQLErrors: ${JSON.stringify(graphQLErrors)}`)
    // Bugsnag.notify(`graphQLErrors: ${JSON.stringify(graphQLErrors)}`)
  }
  if (networkError) {
    console.log(`networkError: ${JSON.stringify(networkError)}`)
  }
  // if (!graphQLErrors && networkError && (networkError as Record<string, any>).statusCode !== 413) {
  //   store.dispatch(setConnected({ status: false }))
  // }
})

const filterObject = (obj: Record<string, any>, key: string) => {
  delete obj[key]
  for (const value of Object.values(obj)) {
    if (value instanceof Object) {
      filterObject(value as Record<string, any>, key)
    }
  }
  return obj
}

const cleanTypeName = new ApolloLink((operation, forward) => {
  //delete __typename field when mutating
  if (operation.variables) {
    operation.variables = filterObject(operation.variables, '__typename')
  }
  return forward(operation).map((data) => {
    return data
  })
})

const uploadLink = createUploadLink({
  uri: config.API_URL,
  credentials: 'same-origin',
})

export const apolloClient = new ApolloClient({
  link: from([cleanTypeName, retryLink, errorLink, authLink, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'none',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'none',
    },
    mutate: {
      fetchPolicy: 'network-only',
      errorPolicy: 'none',
    },
  },
})
