import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
import { DurationInput, MultiSelectChips, Error } from '../../index'
// TYPE
import { ProjectType, UserType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { CALENDAR_USERS_QUERY } from '../../../gql/queries'
import { UPDATE_PROJECT_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type GeneralInformationsFormPropsType = {
  project: ProjectType
  refetch: () => Promise<any>
  handleClose: () => void
}

const GeneralInformationsForm = ({ project, refetch, handleClose }: GeneralInformationsFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateProject, setStateProject] = useState<ProjectType>(project)
  const { error, data: { users } = {} } = useQuery<{ users: UserType[] }>(CALENDAR_USERS_QUERY, {
    variables: {
      where: {
        job: {
          category: {
            eq: 'DIRECTOR',
          },
        },
      },
    },
  })

  const [updateProject] = useMutation<{ updateProject: ProjectType }>(UPDATE_PROJECT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProject.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const projectInput = {
      id: stateProject.id,
      code_name: stateProject.code_name,
      name: stateProject.name,
      duration_production: stateProject.duration_production,
      duration_target: stateProject.duration_target,
      course: { id: stateProject?.course?.id },
      members: stateProject?.members?.map((member) => {
        return { id: member?.id }
      }),
    }
    await updateProject({ variables: { input: projectInput } })
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour le projet"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProject) === JSON.stringify(project)}
    >
      <Grid item>
        <TextField
          fullWidth
          name="name"
          label="Nom"
          variant="outlined"
          value={stateProject?.name || ''}
          onChange={(event) => {
            setStateProject((prevState) => {
              return {
                ...prevState,
                ...{ [event.target.name]: event.target.value },
              }
            })
          }}
          required
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <DurationInput
            label="Durée cible"
            defaultValue={stateProject?.duration_target}
            onChange={(duration) => {
              setStateProject((prevState) => {
                return {
                  ...prevState,
                  duration_target: duration,
                }
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DurationInput
            format="ddd"
            label="Durée de production"
            defaultValue={stateProject?.duration_production}
            onChange={(duration) => {
              setStateProject((prevState) => {
                return {
                  ...prevState,
                  duration_production: duration,
                }
              })
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <MultiSelectChips
          multiSelectLabel="Réalisateur(s)"
          keyToUpdate="members"
          labels={users ? users?.map((user) => user.username) : []}
          selectedLabelsList={
            stateProject?.members
              ? stateProject?.members?.filter((user) => user.job.category === 'DIRECTOR').map((user) => user?.username)
              : []
          }
          handleChange={(_, values) => {
            const value = users?.find((user) => values.includes(user.username))
            if (value) {
              setStateProject((prevState) => {
                return {
                  ...prevState,
                  members: [...prevState.members, value],
                }
              })
            }
          }}
        />
      </Grid>
    </FormLayout>
  )
}

export default GeneralInformationsForm
