import { Box, Card, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import NOIMG_banner from '../../assets/NOIMG_banner.jpg'
import NOIMG_logo from '../../assets/NOIMG_logo.svg'

type GradientCardProps = {
  s3_logo: string
  s3_banner: string
  color: string
  onClick?: (e: React.MouseEvent) => void
}

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardImg: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    objectFit: 'cover',
  },
  cardTypo: {
    position: 'absolute',
    width: '70%',
    height: '100%',
    zIndex: 3,
  },
  cardBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
}))

const GradientCard = ({ s3_logo, s3_banner, color, onClick }: GradientCardProps) => {
  const classes = useStyles()
  const [gradientColor, setGradientColor] = useState<string>(color)
  return (
    <Card className={classes.card} onClick={(e) => onClick && onClick(e)}>
      {s3_logo ? (
        <img
          className={classes.cardTypo}
          alt="Logotype"
          src={s3_logo}
          onError={({ currentTarget }) => {
            currentTarget.src = NOIMG_logo
          }}
        />
      ) : (
        <img
          className={classes.cardTypo}
          alt="Logotype"
          src={NOIMG_logo}
          onError={({ currentTarget }) => {
            currentTarget.src = NOIMG_logo
          }}
        />
      )}

      <Box
        className={classes.cardBox}
        style={{ background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${gradientColor} 100%)` }}
      />
      {s3_banner ? (
        <img
          className={classes.cardImg}
          alt="Card"
          src={s3_banner}
          onError={({ currentTarget }) => {
            currentTarget.src = NOIMG_banner
            setGradientColor('#8197BE')
          }}
        />
      ) : (
        <img
          className={classes.cardImg}
          alt="Card"
          src={NOIMG_banner}
          onError={({ currentTarget }) => {
            currentTarget.src = NOIMG_banner
            setGradientColor('#8197BE')
          }}
        />
      )}
    </Card>
  )
}

export default GradientCard
