//REACT
import { SetStateAction, useEffect, useRef, Dispatch } from 'react'
//MUI
import { Grid, Typography, makeStyles, useTheme, Paper, IconButton } from '@material-ui/core'
import { ProductFaqType } from '../../../../lib/sharedTypes'
//ICON
import { MdMoreVert, MdDragIndicator } from 'react-icons/md'
//COMPONENTS
import { TextEditor } from '../../components'
//DND
import type { Identifier, XYCoord } from 'dnd-core'
import { useDrag, useDrop } from 'react-dnd'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

type FaqDNDPropsType = {
  qa: ProductFaqType
  id: number
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  setIsDragging: Dispatch<SetStateAction<boolean>>
  setSelectedFaq: () => void
  setAnchorEl: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

type DragItem = ProductFaqType & {
  index: number
}

const FaqDND = ({ qa, id, index, moveCard, setIsDragging, setAnchorEl, setSelectedFaq }: FaqDNDPropsType) => {
  const theme = useTheme()
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'PRODUCT_ORDER',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'PRODUCT_ORDER',
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  useEffect(() => {
    setIsDragging(isDragging)
  }, [isDragging])

  return (
    <Paper
      key={id}
      ref={ref}
      data-handler-id={handlerId}
      style={{
        borderRadius: '10px',
        marginBottom: 10,
        opacity: opacity,
      }}
    >
      <Grid container direction="row">
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          style={{
            width: '2%',
            borderRadius: '10px 0px 0px 10px',
            cursor: 'grab',
            background: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
          }}
        >
          <Grid item>
            <MdDragIndicator />
          </Grid>
        </Grid>
        <Grid item key={qa.id} style={{ width: '98%' }}>
          <Paper style={{ padding: theme.spacing(1), border: `1px solid ${theme.palette.divider}` }}>
            <Grid item container justifyContent="space-between">
              <Typography variant="body1" className={classes.title}>
                Question n°{qa.position ? qa.position + 1 : index + 1}{' '}
                <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                  Dernière mise-à-jour:{' '}
                  {new Date(qa.updated_at).toLocaleString('fr-FR', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </Typography>
              </Typography>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation()
                  setAnchorEl(event)
                  setSelectedFaq()
                }}
              >
                <MdMoreVert size="20" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body2">{qa.question}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Réponse
              </Typography>
            </Grid>
            <Grid item>
              <TextEditor text={qa.answer} readOnly={true} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FaqDND
