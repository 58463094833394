// REACT
import { useEffect, useRef, useState } from 'react'
// MATERIAL UI
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  Checkbox,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}))

type MultiSelectChipsPropsType = {
  required?: boolean
  multiSelectLabel: string // Name to display above the multiselect
  keyToUpdate: string // Name of the attribute to update
  selectedLabelsList: (string | undefined)[] | undefined // List of selected labels to display
  labels: string[] // All labels
  handleChange: (key: string, value: Array<string>) => void // Function to update the state
  rightChildren?: JSX.Element | null
  rightClick?: (label: string) => void
  sort?: boolean
}

const MultiSelectChips = ({
  required = false,
  keyToUpdate,
  multiSelectLabel,
  selectedLabelsList,
  labels,
  handleChange,
  rightChildren,
  rightClick,
  sort = true,
}: MultiSelectChipsPropsType) => {
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>()
  const [maxWidth, setMaxWidth] = useState<number>(0)

  useEffect(() => {
    if (ref.current) {
      if (maxWidth === 0) {
        setMaxWidth(ref.current.offsetWidth - 32)
      }
    }
  }, [ref])

  sort ? labels.sort() : labels
  return (
    <FormControl required={required} fullWidth variant="outlined">
      <InputLabel>{multiSelectLabel}</InputLabel>
      <Select
        ref={ref}
        fullWidth
        multiple
        value={selectedLabelsList}
        onChange={(e) => {
          e.preventDefault()
          const value = e.target.value as Array<string>
          handleChange(keyToUpdate, value)
        }}
        input={<OutlinedInput label={multiSelectLabel} />}
        renderValue={(selected) => (
          <div style={{ overflow: 'scroll', maxWidth: maxWidth }}>
            {(selected as string[]).map((value: string, index: number) => (
              <Chip key={index} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              maxWidth: maxWidth,
            },
          },
        }}
      >
        {labels.map((label, index) => (
          <MenuItem value={label} key={index}>
            <Checkbox checked={selectedLabelsList && selectedLabelsList.indexOf(label) > -1} />
            <ListItemText primary={label} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
            {rightChildren && rightClick && (
              <div
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  rightClick(label)
                }}
              >
                {rightChildren}
              </div>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultiSelectChips
