//REACT
import { useState } from 'react'
// COMPONENTS
import { CatalogForm } from '../components/Forms'
import { Layout, Title, Error, Loading, CustomTable, SearchBar, CustomModal } from '../components'
// MATERIAL UI
import { Grid, Button } from '@material-ui/core'
//GQL
import { CATALOGS_PAGE_QUERY, CATALOGS_SEARCH_QUERY } from '../gql/queries'
import { useQuery, useLazyQuery } from '@apollo/client'
//TYPES
import { ColumnType } from '../utils/types'
import { CatalogType, SortEnum } from '../../../lib/sharedTypes'
//ROUTER
import { useNavigate } from 'react-router'

const columns: ColumnType<CatalogType>[] = [
  {
    title: 'Catalogue',
    field: 'name',
    render: (row: CatalogType) => row.name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Catalogue parent',
    field: 'parent',
    render: (row: CatalogType) => (row.parent ? row.parent.name : ''),
    type: 'String',
    sortable: true,
  },
  {
    title: 'Créé le',
    field: 'created_at',
    render: (row: CatalogType) => row.created_at,
    type: 'Date',
    sortable: true,
  },
  {
    title: 'Modification',
    field: 'updated_at',
    render: (row: CatalogType) => row.updated_at,
    type: 'Date',
    sortable: true,
  },
]

const Catalogs = () => {
  const navigate = useNavigate()
  const [field, setField] = useState<keyof CatalogType>('name')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [searching, setSearching] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const {
    loading,
    error,
    data: { catalogs = [], catalogsCount = 0 } = {},
    refetch,
  } = useQuery<{
    catalogs: CatalogType[]
    catalogsCount: number
  }>(CATALOGS_PAGE_QUERY, {
    variables: {
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
  })

  const [searchCatalogsQuery, { error: searchError, data: { catalogsSearch = [] } = {} }] = useLazyQuery<{
    catalogsSearch: CatalogType[]
  }>(CATALOGS_SEARCH_QUERY)

  const searchCatalogs = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchCatalogsQuery({
      variables: {
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
      },
    })
  }

  if (error) {
    return (
      <Layout title="Catalogue(s)">
        <Error error={error} />
      </Layout>
    )
  }

  if (searchError) {
    return (
      <Layout title="Catalogue(s)">
        <Error error={searchError} />
      </Layout>
    )
  }

  if (loading) {
    return (
      <Layout title="Catalogue(s)">
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout title={'Catalogue(s)'}>
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={'Catalogue(s)'} />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenModal(true)
              }}
            >
              Ajouter un catalogue
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <SearchBar placeholder="Rechercher un catalogue" searchQuery={searchCatalogs} />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CustomTable
            columns={columns}
            rows={searching ? catalogsSearch : catalogs}
            field={field}
            sort={sort}
            skip={skip}
            take={take}
            setSort={setSort}
            setField={setField}
            rowsCount={searching ? catalogsSearch.length : catalogsCount}
            setSkip={setSkip}
            setTake={setTake}
            onRowClick={(row: CatalogType, e) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(`/catalogues/${row.id}`, '_blank')
              } else {
                navigate(row.id)
              }
            }}
          />
        </Grid>
      </Grid>
      <CustomModal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        animation="slide-up"
      >
        <CatalogForm catalog={undefined} refetch={refetch} handleClose={() => setOpenModal(false)} />
      </CustomModal>
    </Layout>
  )
}

export default Catalogs
