import { Grid, alpha, Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  deleteBTN: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.1),
    },
  },
}))

type DeleteFormPropsType = {
  onDelete: () => void
  onCancel: () => void
}

const DeleteForm = ({ onDelete, onCancel }: DeleteFormPropsType) => {
  const classes = useStyles()
  return (
    <Grid
      item
      container
      justifyContent="space-around"
      alignItems="center"
      style={{ height: '100%', width: '100%' }}
      spacing={1}
    >
      <Grid item>
        <Button variant="outlined" className={classes.deleteBTN} onClick={onDelete}>
          Supprimer
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          Annuler
        </Button>
      </Grid>
    </Grid>
  )
}

export default DeleteForm
