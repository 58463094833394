import { useState } from 'react'
//TYPES
import {
  VideoReviewType,
  VideoReviewCommentType,
  RoleCategoryEnum,
  CourseType,
  UserType,
} from '../../../../../lib/sharedTypes'
//ICONS
import { HiOutlineTrash } from 'react-icons/hi'
import { MdExpandMore, MdOutlineAddComment, MdModeEditOutline } from 'react-icons/md'
//MATERIAL UI
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Chip,
  Collapse,
  TextField,
  Button,
  useTheme,
} from '@material-ui/core'
//COMPONENENTS
import { DeleteForm } from '../../Forms'
import { UserAvatar, CustomModal } from '../../index'
//UTILS
import { reviewStateMapping } from '../../../utils/constants'
import { handleError } from '../../../utils/handleError'
import { dateToTimeAgo, secondsToDHMS, stringToColor } from '../../../utils/misc'
//GQL
import { useMutation } from '@apollo/client'
import {
  CREATE_VIDEO_REVIEW_COMMENT_MUTATION,
  UPDATE_VIDEO_REVIEW_MUTATION,
  DELETE_VIDEO_REVIEW_MUTATION,
  DELETE_VIDEO_REVIEW_COMMENT_MUTATION,
} from '../../../gql/mutations'
//HOOKS
import { useAlert, useAppSelector } from '../../../hooks'
//UTILS
import { isAuthorized, isCourseManager } from '../../../utils/misc'

type CommentCardPropsType = {
  comment: VideoReviewCommentType
  refetch: () => Promise<any>
}

const CommentCard = ({ comment, refetch }: CommentCardPropsType) => {
  const user = useAppSelector((state) => state.user.user)
  const theme = useTheme()
  const created_at = new Date(comment.created_at)
  const localCreatedAt = new Date(created_at.getTime() - created_at.getTimezoneOffset() * 60000)
  const [deleteModal, setDeleteModal] = useState(false)
  const [setAlert] = useAlert()
  const [deleteVideoReviewComment] = useMutation<{ deleteVideoReviewComment: boolean }>(
    DELETE_VIDEO_REVIEW_COMMENT_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Commentaire supprimé` })
        await refetch()
      },
    },
  )

  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: theme.spacing(1) }}
          avatar={<UserAvatar user={comment.author} />}
          action={
            user?.id === comment?.author?.id ||
            user?.roles.map((role) => role.category).includes(RoleCategoryEnum.ADMIN) ? (
              <IconButton
                onClick={() => {
                  setDeleteModal(true)
                }}
              >
                <HiOutlineTrash size={22} style={{ color: theme.palette.error.main }} />
              </IconButton>
            ) : (
              <></>
            )
          }
          subheader={`${dateToTimeAgo(localCreatedAt)}`}
          title={`${comment?.author?.first_name} ${comment?.author?.last_name}`}
        />
        <CardContent style={{ padding: theme.spacing(1) }}>
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {comment.content}
          </Typography>
        </CardContent>
      </Card>
      <CustomModal
        size="xs"
        title="Supprimer le commentaire"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteVideoReviewComment({
              variables: { input: { id: comment?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </>
  )
}

type ReviewCardPropsType = {
  review: VideoReviewType
  editor: UserType
  course: CourseType
  refetch: () => Promise<any>
  handleSkip: (time_code: number) => void
  displayed: boolean
}

const ReviewCard = ({ review, refetch, handleSkip, displayed, course, editor }: ReviewCardPropsType) => {
  const user = useAppSelector((state) => state.user.user)
  const [editedReviewContent, setEditedReviewContent] = useState(review.content)
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [editMode, setEditMode] = useState(false)
  const [commentsExpanded, setCommentsExpanded] = useState(false)
  const [commentExpanded, setCommentExpanded] = useState(false)
  const [comment, setComment] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [createVideoReviewComment] = useMutation<{
    createVideoReviewComment: VideoReviewCommentType
  }>(CREATE_VIDEO_REVIEW_COMMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createVideoReviewComment.content} créé` })
      await refetch()
    },
  })

  const [deleteVideoReview] = useMutation<{ deleteVideoReview: boolean }>(DELETE_VIDEO_REVIEW_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Revue supprimée` })
      await refetch()
    },
  })

  const [updateVideoReview] = useMutation<{
    updateVideoReview: VideoReviewType
  }>(UPDATE_VIDEO_REVIEW_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetch()
    },
  })

  const handleSubmit = async () => {
    const videoReviewCommentInput = {
      author: { id: user?.id },
      video_review: { id: review?.id },
      content: comment,
    }
    await createVideoReviewComment({ variables: { input: videoReviewCommentInput } })
    await refetch()
    setCommentExpanded(false)
  }

  return (
    <Card
      style={{
        cursor: 'pointer',
        background: displayed ? theme.palette.action.focus : 'unset',
        border: `2px solid ${stringToColor(JSON.stringify(review))}`,
      }}
      onClick={() => {
        handleSkip(review.time_code)
      }}
    >
      <CardHeader
        style={{ padding: theme.spacing(1) }}
        avatar={<UserAvatar user={review.reviewer} />}
        title={`${review?.reviewer?.first_name} ${review?.reviewer?.last_name}`}
        subheader={`${secondsToDHMS(review.time_code)} - ${review.comments.length} commentaire(s)`}
        action={
          <Chip
            label={reviewStateMapping[review.state].text}
            variant="outlined"
            size="small"
            style={{
              margin: theme.spacing(1),
              borderColor: reviewStateMapping[review.state].color,
              color: reviewStateMapping[review.state].color,
            }}
            onClick={async () => {
              if (user?.id.toString() === editor?.id.toString()) {
                if (['CREATED', 'TO_BE_CORRECTED'].includes(review.state)) {
                  await updateVideoReview({
                    variables: { input: { id: review?.id, state: 'CORRECTED' } },
                  })
                } else {
                  await updateVideoReview({
                    variables: { input: { id: review?.id, state: 'TO_BE_CORRECTED' } },
                  })
                }
              } else {
                await updateVideoReview({
                  variables: { input: { id: review?.id, state: reviewStateMapping[review.state].next } },
                })
              }
            }}
          />
        }
      />
      <CardContent style={{ padding: theme.spacing(1) }}>
        {editMode ? (
          <TextField
            fullWidth
            multiline
            minRows={2}
            variant="outlined"
            value={editedReviewContent}
            style={{ padding: 0 }}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => {
              event.stopPropagation()
              setEditedReviewContent(event.target.value)
            }}
          />
        ) : (
          <Typography variant="body2" style={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }}>
            {review.content}
          </Typography>
        )}
      </CardContent>
      {editMode ? (
        <CardActions disableSpacing>
          <Grid item container spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={review.content === editedReviewContent}
                onClick={async () => {
                  await updateVideoReview({
                    variables: { input: { id: review?.id, content: editedReviewContent } },
                  })
                  setEditMode(false)
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  setEditMode(false)
                }}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      ) : (
        <CardActions disableSpacing>
          <IconButton
            aria-label="show more"
            disabled={review.comments.length === 0}
            onClick={() => {
              if (review.comments.length !== 0) {
                setCommentExpanded(false)
                setCommentsExpanded(!commentsExpanded)
              }
            }}
            style={{
              padding: theme.spacing(1),
              transform: !commentsExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
            }}
          >
            <MdExpandMore size="20" />
          </IconButton>

          {(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR', 'DESIGN']) ||
            isCourseManager(course) ||
            user?.id.toString() === editor?.id.toString()) && (
            <IconButton
              style={{ padding: theme.spacing(1) }}
              onClick={() => {
                setCommentsExpanded(false)
                setCommentExpanded(!commentExpanded)
              }}
            >
              <MdOutlineAddComment size="20" />
            </IconButton>
          )}
          {user?.id === review?.reviewer?.id && (
            <Grid item>
              <IconButton
                style={{ padding: theme.spacing(1) }}
                onClick={(e) => {
                  e.stopPropagation()
                  setCommentExpanded(false)
                  setEditMode(true)
                }}
              >
                <MdModeEditOutline size={22} />
              </IconButton>
            </Grid>
          )}
          {(user?.id === review?.reviewer?.id || isAuthorized(['ADMIN', 'PRODUCTION']) || isCourseManager(course)) && (
            <IconButton
              style={{ padding: theme.spacing(1) }}
              onClick={(e) => {
                e.stopPropagation()
                setDeleteModal(true)
              }}
            >
              <HiOutlineTrash size={22} />
            </IconButton>
          )}
        </CardActions>
      )}

      <Collapse in={commentsExpanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          spacing={1}
          style={{ overflow: 'auto', background: theme.palette.action.hover, padding: theme.spacing(1) }}
        >
          {review.comments
            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            .map((comment) => {
              return (
                <Grid item key={comment.id}>
                  <CommentCard comment={comment} refetch={refetch} />
                </Grid>
              )
            })}
        </Grid>
      </Collapse>
      <Collapse in={commentExpanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
        <Grid container spacing={1} direction="column" style={{ padding: theme.spacing(1) }}>
          <Grid item>
            <TextField
              label="Commentaire"
              variant="standard"
              fullWidth
              focused
              onChange={(e) => {
                setComment(e.target.value)
              }}
            />
          </Grid>
          <Grid item container spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={comment.length === 0}
                onClick={async () => {
                  await handleSubmit()
                }}
              >
                Répondre
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  setComment('')
                  setCommentExpanded(false)
                }}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
      <CustomModal
        size="xs"
        title="Supprimer la revue"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteVideoReview({
              variables: { input: { id: review?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Card>
  )
}

export default ReviewCard
