import React, { useEffect, useRef, useState } from 'react'
//COMPONENTS
import { LessonVideoDiagramForm } from '../Forms/lesson'
import { VideoControls, ReviewCard } from './VideoReview'
import { Error, UserAvatar, CustomModal } from '../index'
import { DeleteForm } from '../Forms'
//MATERIAL UI
import {
  Grid,
  Typography,
  useTheme,
  Divider,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  IconButton,
  alpha,
  makeStyles,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
//TYPES
import { LocalReviewType } from '../../utils/types'
import {
  LessonVideoDiagramType,
  LessonVideoType,
  ProductType,
  VideoDiagramStateEnum,
  VideoReviewStateEnum,
  VideoReviewType,
} from '../../../../lib/sharedTypes'
//UTILS
import { isAuthorized, isCourseManager, secondsToDHMS, stringToColor } from '../../utils/misc'
//GQL
import { useMutation } from '@apollo/client'
import {
  CREATE_VIDEO_REVIEW_MUTATION,
  DELETE_VIDEO_REVIEW_MUTATION,
  UPDATE_LESSON_VIDEO_MUTATION,
} from '../../gql/mutations'
//HOOKS
import { useAlert, useAppSelector } from '../../hooks'
//UTILS
import { reviewStateMapping } from '../../utils/constants'
import { handleError } from '../../utils/handleError'
//ICONS
import { HiOutlineTrash } from 'react-icons/hi'
import { LessonVideo, LessonVideoDiagram } from '../../../../server/src/entities'

type VideoReviewModalPropsType = {
  type: 'teaser' | 'lesson_video'
  video: ProductType | LessonVideoType
  reviews: VideoReviewType[]
  diagrams: LessonVideoDiagramType[]
  refetch: () => Promise<any>
  timeCode?: number
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  textarea: {
    resize: 'both',
  },
}))

const VideoReviewModal = ({ type, video, reviews, diagrams, refetch, timeCode }: VideoReviewModalPropsType) => {
  const classes = useStyles()
  const inputRef = useRef<HTMLTextAreaElement>()
  const user = useAppSelector((state) => state?.user?.user)
  const theme = useTheme()
  const observerRef = useRef<ResizeObserver | null>(null)
  const [canvasDimensions, setCanvasDimension] = useState({ width: 0, height: 0 })
  const videoRef = useRef<HTMLVideoElement>(null)
  const [progress, setProgress] = useState(0)
  const [level, setLevel] = useState(50)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [duration, setDuration] = useState(0)
  const [dateTime, setDateTime] = useState(new Date().getTime())
  const [setAlert] = useAlert()
  const [newReview, setNewReview] = useState<LocalReviewType>()
  const [localReviews, setLocalReviews] = useState<LocalReviewType[]>(reviews as LocalReviewType[])
  const [reviewContent, setReviewContent] = useState<string>('')
  const [reviewsFilter, setReviewsFilter] = useState<string[]>(Object.keys(VideoReviewStateEnum))
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedReview, setSelectedReview] = useState<number | undefined>()
  const [schemaModal, setSchemaModal] = useState(false)
  const [selectedDiagram, setSelectedDiagram] = useState<LessonVideoDiagram>()

  const [createVideoReview] = useMutation<{
    createVideoReview: VideoReviewType
  }>(CREATE_VIDEO_REVIEW_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetch()
    },
  })

  const [deleteVideoReview] = useMutation<{ deleteVideoReview: boolean }>(DELETE_VIDEO_REVIEW_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Revue supprimée` })
      await refetch()
    },
  })

  const [updateLessonVideo] = useMutation<{
    updateLessonVideo: LessonVideoType
  }>(UPDATE_LESSON_VIDEO_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Miniature capturée` })
      await refetch()
    },
  })

  useEffect(() => {
    setDateTime(new Date().getTime())
    const video = videoRef.current
    observerRef.current = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width
      const height = entries[0].contentRect.height
      setCanvasDimension({ width: width, height: height })
    })

    if (video) {
      video.addEventListener('loadedmetadata', (event) => {
        const target = event.target as HTMLVideoElement
        setDuration(target.duration)
      })
      window.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'ArrowRight') {
          handleSkip(video.currentTime + 10)
        }
        if (event.key === 'ArrowLeft') {
          handleSkip(video.currentTime - 10)
        }
      })
      if (timeCode) {
        handleSkip(timeCode)
      }
      setLocalReviews(reviews as LocalReviewType[])
      setCanvasDimension({ width: video.clientWidth, height: video.clientHeight })
      observerRef.current.observe(video)
    }
    return () => {
      observerRef.current && observerRef.current.disconnect()
    }
  }, [])

  useEffect(() => {
    setLocalReviews(reviews as LocalReviewType[])
  }, [video])

  const getReviewsToDisplay = (reviews: LocalReviewType[], currentProgress: number) => {
    const resp = reviews
      .filter((review) => {
        if (review.state) {
          return reviewsFilter.includes(review.state)
        }
        return true
      })
      .filter((review) => {
        const reviewPos = (review.time_code / duration) * 100
        return (
          review.time_code <= duration &&
          currentProgress >= Math.floor(reviewPos) &&
          currentProgress <= Math.ceil(reviewPos + 0.1)
        )
      })
    return resp
  }

  const handleSkip = (pos: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = pos
    }
    setProgress((pos / duration) * 100)
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = level / 100
    }
  }, [level])

  const course =
    type === 'lesson_video' ? (video as LessonVideoType).video_set.project.course : (video as ProductType).courses[0]

  const isEditor =
    type === 'lesson_video'
      ? (video as LessonVideoType).video_set?.editor?.id?.toString() === user?.id.toString()
      : (video as ProductType).teaser_uploader

  const handleCanvasClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR', 'DESIGN']) || isCourseManager(course) || isEditor) {
      const rect = event.currentTarget.getBoundingClientRect()
      const video = videoRef.current
      const x = event.nativeEvent.offsetX / rect.width
      const y = event.nativeEvent.offsetY / rect.height
      if (video && user) {
        const newReview = {
          pos_x: x,
          pos_y: y,
          time_code: video.currentTime,
          reviewer: user,
          content: '',
        }
        setNewReview(newReview)
        setLocalReviews((prevState) => {
          return [...prevState, newReview]
        })
        video.pause()
      }
    }
  }

  const handleSubmit = async (newReview: LocalReviewType) => {
    const videoReviewInput: Record<string, any> = {
      ...newReview,
      reviewer: { id: newReview.reviewer.id },
    }

    if (type === 'lesson_video') {
      videoReviewInput.lesson_video = { id: video.id }
    } else {
      videoReviewInput.product = { id: video.id }
    }
    await createVideoReview({ variables: { input: videoReviewInput } })
    setReviewContent('')
    setNewReview(undefined)
  }

  const addSchemaBTN = (progress: number) => {
    const diagram = diagrams.filter((diagram) => {
      const diagramPos = (diagram.time_code / duration) * 100
      return progress >= Math.floor(diagramPos) && progress <= Math.ceil(diagramPos + 0.1)
    })
    if (diagram.length === 1 && type === 'lesson_video') {
      return (
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          style={{
            position: 'absolute',
            top: 0,
            right: 100,
            margin: theme.spacing(1),
            color: 'white',
            backgroundColor: alpha(theme.palette.secondary.main, 0.5),
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (videoRef.current) {
              videoRef.current.pause()
              setSelectedDiagram(diagram[0])
              setSchemaModal(true)
            }
          }}
        >
          Ajouter schéma
        </Button>
      )
    } else {
      return (
        (isAuthorized(['ADMIN', 'PRODUCTION']) || isCourseManager(course)) &&
        type === 'lesson_video' && (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            style={{
              position: 'absolute',
              top: 0,
              right: 100,
              margin: theme.spacing(1),
              color: 'white',
              backgroundColor: alpha(theme.palette.secondary.main, 0.5),
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (videoRef.current) {
                videoRef.current.pause()
                setSelectedDiagram({
                  time_code: videoRef.current.currentTime,
                  state: VideoDiagramStateEnum.REQUESTED,
                } as LessonVideoDiagram)
                setSchemaModal(true)
              }
            }}
          >
            Schéma
          </Button>
        )
      )
    }
  }

  if (!video) {
    return <Error error="video inconnue!" />
  }

  const videoUrl =
    type === 'lesson_video'
      ? `${(video as LessonVideoType)?.walter_media_url}?${dateTime}`
      : `${(video as ProductType)?.teaser_walter_media_url}?${dateTime}`

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      xs={12}
      style={{
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        flexGrow: 1,
        maxHeight: `calc(100vh - 64px)`,
        minHeight: `calc(100vh - 64px)`,
        overflow: 'scroll',
      }}
    >
      <Grid item container>
        <Grid item xs={9}>
          <Grid item style={{ position: 'relative' }}>
            <Grid
              style={{
                zIndex: 2,
                position: 'absolute',
                height: canvasDimensions.height,
                width: canvasDimensions.width,
                cursor: 'pointer',
              }}
              onClick={handleCanvasClick}
            >
              {addSchemaBTN(progress)}

              <>
                {(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR', 'EDITOR']) || isCourseManager(course)) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      margin: theme.spacing(1),
                      color: 'white',
                      backgroundColor: alpha(theme.palette.primary.main, 0.5),
                    }}
                    onClick={async (e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (videoRef.current) {
                        videoRef.current.pause()
                        const timecode = videoRef?.current?.currentTime
                        await updateLessonVideo({
                          variables: {
                            input: {
                              id: video.id,
                              thumbnail_timecode: timecode,
                            },
                          },
                        })
                      }
                    }}
                  >
                    Miniature
                  </Button>
                )}
              </>
              {getReviewsToDisplay(localReviews, progress).map((review, index) => {
                if (review.pos_y === 0 && review.pos_x === 0) {
                  review.pos_y = 0.5
                  review.pos_x = 0.5
                }
                const top = review.pos_y <= 0.5 ? review.pos_y * canvasDimensions.height : 'unset'
                const bottom = review.pos_y > 0.5 ? (1 - review.pos_y) * canvasDimensions.height : 'unset'
                const left = review.pos_x <= 0.5 ? review.pos_x * canvasDimensions.width : 'unset'
                const right = review.pos_x > 0.5 ? (1 - review.pos_x) * canvasDimensions.width : 'unset'

                if (review.content) {
                  return (
                    <div
                      style={{
                        pointerEvents: 'initial',
                        cursor: 'default',
                        height: '16px',
                        width: '16px',
                        border: '1px solid white',
                        background: stringToColor(JSON.stringify(review)),
                        borderRadius: '50%',
                        top: top,
                        left: left,
                        bottom: bottom,
                        right: right,
                        position: 'absolute',
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  )
                } else {
                  return (
                    <Grid
                      key={index}
                      style={{
                        width: 250,
                        display: 'flex',
                        position: 'absolute',
                        cursor: 'default',
                        pointerEvents: 'initial',
                        top: top,
                        left: left,
                        bottom: bottom,
                        right: right,
                        padding: 2,
                      }}
                      alignItems={review.pos_x > 0.5 ? 'flex-end' : 'flex-start'}
                      direction={review.pos_y > 0.5 ? 'column-reverse' : 'column'}
                      container
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Grid item>
                        <div
                          style={{
                            pointerEvents: 'initial',
                            cursor: 'default',
                            height: '16px',
                            width: '16px',
                            border: '1px solid white',
                            background: theme.palette.primary.main,
                            borderRadius: '50%',
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Grid>
                      <Grid item>
                        <Card
                          style={{
                            opacity: review.content ? 0.6 : 0.8,
                            flexGrow: 1,
                            padding: theme.spacing(1),
                          }}
                        >
                          <CardHeader
                            titleTypographyProps={{ variant: 'caption' }}
                            style={{ padding: theme.spacing(0) }}
                            avatar={<UserAvatar user={review.reviewer} />}
                            action={
                              (user?.id === review?.reviewer?.id && review?.content && review?.content) ||
                              isAuthorized(['ADMIN', 'PRODUCTION']) ||
                              isCourseManager(course) ? (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setSelectedReview(review?.id)
                                    setDeleteModal(true)
                                  }}
                                >
                                  <HiOutlineTrash size={22} style={{ color: theme.palette.error.main }} />
                                </IconButton>
                              ) : (
                                <></>
                              )
                            }
                            title={`${review?.reviewer?.first_name} ${review?.reviewer?.last_name}`}
                            subheader={`${secondsToDHMS(review.time_code)}`}
                          />

                          <CardContent style={{ padding: 0, maxHeight: 150, overflow: 'scroll' }}>
                            {review.content ? (
                              <Typography variant="body1">{review.content}</Typography>
                            ) : (
                              <TextField
                                inputRef={inputRef}
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={3}
                                variant="outlined"
                                inputProps={{ className: classes.textarea }}
                                style={{ padding: 0 }}
                                onClick={(event) => event.stopPropagation()}
                                onKeyDown={(e) => {
                                  if (e.code === 'ArrowLeft') {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (inputRef?.current) {
                                      inputRef.current.selectionStart -= 1
                                      inputRef.current.selectionEnd -= 1
                                    }
                                  }
                                  if (e.code === 'ArrowRight') {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (inputRef?.current) {
                                      inputRef.current.selectionStart += 1
                                      inputRef.current.selectionEnd = inputRef.current.selectionStart
                                    }
                                  }
                                }}
                                onChange={(event) => {
                                  event.stopPropagation()
                                  setReviewContent(event.target.value)
                                }}
                              />
                            )}
                          </CardContent>
                          {!review.content && (
                            <CardActions style={{ paddingBottom: 0, paddingLeft: 0 }}>
                              <Grid item container spacing={1}>
                                <Grid item>
                                  <Button
                                    disabled={reviewContent.length === 0}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={async (event) => {
                                      event.stopPropagation()
                                      setNewReview((prevState) => {
                                        if (prevState) {
                                          return { ...prevState, content: reviewContent }
                                        }
                                        return prevState
                                      })

                                      if (newReview && reviewContent !== '') {
                                        await handleSubmit({ ...newReview, content: reviewContent })
                                      }
                                    }}
                                  >
                                    Valider
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      const reviewIndex = reviews.findIndex((r) => {
                                        return (
                                          r.pos_x === review.pos_x &&
                                          r.pos_y === review.pos_y &&
                                          r.time_code === review.time_code &&
                                          r.reviewer === review.reviewer
                                        )
                                      })
                                      setLocalReviews(reviews.filter((_, index) => index !== reviewIndex))
                                      setReviewContent('')
                                      setNewReview(undefined)
                                    }}
                                  >
                                    Annuler
                                  </Button>
                                </Grid>
                              </Grid>
                            </CardActions>
                          )}
                        </Card>
                      </Grid>
                    </Grid>
                  )
                }
              })}
            </Grid>

            <video
              src={videoUrl}
              width="100%"
              id="video"
              ref={videoRef}
              controls={false}
              onPause={() => {
                setIsPlaying(false)
              }}
              onPlay={() => {
                setIsPlaying(true)
              }}
              onTimeUpdate={(e: React.SyntheticEvent<HTMLVideoElement>) => {
                const target = e.target as HTMLVideoElement
                const currentPosition = (target.currentTime / target.duration) * 100
                setProgress(currentPosition)
              }}
              onProgress={(e: React.SyntheticEvent<HTMLVideoElement>) => {
                const target = e.target as HTMLVideoElement
                if (isNaN(target.duration)) return
                setDuration(target.duration)
              }}
            />
            {videoRef.current && (
              <VideoControls
                video={videoRef.current}
                isPlaying={isPlaying}
                progress={progress}
                handleSkip={handleSkip}
                level={level}
                setLevel={setLevel}
                duration={duration}
                reviews={localReviews}
                diagrams={diagrams}
                reviewsFilter={reviewsFilter}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={3} style={{ overflow: 'auto', padding: theme.spacing(1) }}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item>
                  {type === 'lesson_video' && (
                    <Typography variant="body1">
                      Vidéo n°{(video as LessonVideo).number} | {localReviews.length} revue(s)
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    multiple
                    limitTags={1}
                    value={reviewsFilter}
                    options={Object.keys(reviewStateMapping)}
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) => reviewStateMapping[option].text}
                    onChange={(_, value) => {
                      if (value) {
                        setReviewsFilter(value)
                      }
                    }}
                    renderInput={(params) => {
                      return <TextField {...params} variant="outlined" label="Statut" />
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item style={{ maxHeight: canvasDimensions.height, overflow: 'scroll' }} container spacing={1}>
              {reviews
                .sort((a, b) => a.time_code - b.time_code)
                .filter((review) => {
                  return reviewsFilter.includes(review.state)
                })
                .map((review) => {
                  return (
                    <Grid item key={review.id} style={{ width: '100%' }}>
                      <ReviewCard
                        editor={
                          type === 'teaser'
                            ? (video as ProductType).teaser_uploader
                            : (video as LessonVideoType).video_set.editor
                        }
                        course={course}
                        review={review}
                        refetch={refetch}
                        handleSkip={handleSkip}
                        displayed={
                          progress >= Math.floor((review.time_code / duration) * 100) &&
                          progress <= Math.ceil((review.time_code / duration) * 100 + 0.1)
                        }
                      />
                    </Grid>
                  )
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomModal
        size="xs"
        title="Supprimer la revue"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            if (selectedReview) {
              await deleteVideoReview({
                variables: { input: { id: selectedReview } },
              })
              await refetch()
            }
            setDeleteModal(false)
          }}
          onCancel={() => {
            setDeleteModal(false)
          }}
        />
      </CustomModal>
      {type === 'lesson_video' && (
        <CustomModal
          animation="slide-up"
          open={schemaModal}
          onClose={() => {
            setSchemaModal(false)
          }}
        >
          <LessonVideoDiagramForm
            video={video as LessonVideoType}
            handleClose={() => {
              setSchemaModal(false)
            }}
            diagram={selectedDiagram}
            refetch={refetch}
          />
        </CustomModal>
      )}
    </Grid>
  )
}

export default VideoReviewModal
