//REACT
import React, { useState, useEffect } from 'react'
// MATERIAL UI
import {
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  MenuItem,
  Typography,
  Button,
  useTheme,
} from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
import { Error } from '../../../index'
//TYPES
import { DeepPartial } from '../../../../utils/types'
import { LessonDocumentType, LessonType, CourseType, LessonDocumentTypeEnum } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { COURSE_ID_QUERY } from '../../../../gql/queries'
import { UPDATE_LESSON_DOCUMENT_MUTATION, CREATE_LESSON_DOCUMENT_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//ROUTER
import { useParams } from 'react-router'
//UTILS
import { handleError } from '../../../../utils/handleError'

type PdfFormPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const PdfForm = ({ lesson, refetch, handleClose }: PdfFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [localDocument, setLocalDocument] = useState<string>()
  const pdfURL = localDocument ? localDocument : lesson?.document?.s3_file
  const { error: errorCourse, data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })
  const [createLessonDocumentMutation] = useMutation<{
    createLessonDocument: LessonDocumentType
  }>(CREATE_LESSON_DOCUMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createLessonDocument.lesson.name} créé` })
      await refetch()
      handleClose()
    },
  })
  const [updateLessonDocumentMutation] = useMutation<{
    updateLessonDocument: LessonDocumentType
  }>(UPDATE_LESSON_DOCUMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Document uploadé` })
      await refetch()
      handleClose()
    },
  })

  if (errorCourse) {
    return <Error error={errorCourse} />
  }

  useEffect(() => {
    if (!selectedFile) {
      setLocalDocument(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setLocalDocument(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonDocumentInput: Record<string, any> = {
      id: stateLesson?.document?.id,
      s3_file: selectedFile,
      downloadable: stateLesson?.document?.downloadable,
      type: stateLesson?.document?.type,
    }
    if (stateLesson?.id) {
      await updateLessonDocumentMutation({
        variables: {
          input: lessonDocumentInput,
        },
      })
    } else {
      lessonDocumentInput.lesson = {
        course: { id: course?.id },
        validated: stateLesson?.validated || false,
        summary_required: stateLesson?.summary_required || false,
        name: stateLesson?.name,
        summary_state: stateLesson?.summary_state,
        summary: stateLesson?.summary,
      }
      await createLessonDocumentMutation({ variables: { input: lessonDocumentInput } })
    }
  }

  return (
    <FormLayout
      type={lesson ? 'update' : 'create'}
      title={lesson ? 'Mettre à jour la leçon document' : 'Ajouter une leçon document'}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id ? JSON.stringify(stateLesson) === JSON.stringify(lesson) : stateLesson === undefined
      }
    >
      {!stateLesson?.id && (
        <Grid item container spacing={2} alignItems="center">
          <Grid item container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                required
                label="Nom"
                name="name"
                defaultValue={stateLesson?.name}
                onChange={(event) => {
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      ...{ [event.target.name]: event.target.value },
                    }
                  })
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item container justifyContent="center">
        {pdfURL ? (
          <object data={`${pdfURL}#view=FitV`} width="100%" height="500px">
            <p>{pdfURL}</p>
          </object>
        ) : (
          <Grid
            item
            style={{
              width: '100%',
              height: 250,
              display: 'flex',
              border: `1px solid ${theme.palette.action.disabled}`,
              borderRadius: 5,
              background: theme.palette.background.default,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6" style={{ fontStyle: 'italic' }}>
              Selectionner un document
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="center">
        <Button color="primary" variant="contained" component="label">
          Selectionner
          <input hidden accept=".pdf" type="file" onChange={onSelectFile} />
        </Button>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateLesson?.document?.downloadable}
                  onChange={(event) => {
                    setStateLesson((prevState) => {
                      return {
                        ...prevState,
                        document: {
                          ...prevState?.document,
                          downloadable: event.target.checked,
                        },
                      }
                    })
                  }}
                  name="downloadable"
                />
              }
              label={stateLesson?.document?.downloadable ? 'Document téléchargeable' : 'Document non-téléchargeable'}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            id="job"
            label="Type de document"
            variant="outlined"
            defaultValue={stateLesson?.document?.type}
            value={stateLesson?.document?.type || ''}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              },
            }}
            onChange={(event) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  document: {
                    ...prevState?.document,
                    type: event.target.value as LessonDocumentTypeEnum,
                  },
                }
              })
            }}
            required
          >
            {Object.keys(LessonDocumentTypeEnum).map((type) => (
              <MenuItem id="type" key={type} value={type}>
                {type === 'SYNTHESIS' ? 'Synthèse' : 'Leçon'}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default PdfForm
