import { useState } from 'react'
// MATERIAL UI
import {
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from '@material-ui/core'
//ICONS
// COMPONENTS
import { FormLayout } from '../index'
// TYPE
import {
  ProductPostPublicationType,
  ProductPostPublicationVersionsEnum,
  ProductType,
} from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import {
  CREATE_PRODUCT_POST_PUBLICATION_MUTATION,
  UPDATE_PRODUCT_POST_PUBLICATION_MUTATION,
} from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { DeepPartial } from 'src/utils/types'

type PostPublicationFormPropsType = {
  product: ProductType
  productPostPublication?: ProductPostPublicationType
  refetch: () => Promise<any>
  handleClose: () => void
}

const PostPublicationForm = ({
  product,
  productPostPublication,
  refetch,
  handleClose,
}: PostPublicationFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [postPublication, setPostPublication] = useState<DeepPartial<ProductPostPublicationType>>(
    productPostPublication ?? {
      publication_version: ProductPostPublicationVersionsEnum.VERSION_2325_V3,
      type: 'pi',
      has_cv: false,
      forum_slug: `${product.code_name.toLowerCase()}_2325_3`,
      new_product_code: `${product.code_name.toLowerCase()}_2325_3`,
    },
  )

  const [createProductPostPublication] = useMutation<{ createProductPostpublication: ProductPostPublicationType }>(
    CREATE_PRODUCT_POST_PUBLICATION_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Publication ajoutée` })
        await refetch()
        handleClose()
      },
    },
  )

  const [updateProductPostPublicatio] = useMutation<{ updateProductPostpublication: ProductPostPublicationType }>(
    UPDATE_PRODUCT_POST_PUBLICATION_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Publication modifiée` })
        await refetch()
        handleClose()
      },
    },
  )

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (postPublication?.id) {
      await updateProductPostPublicatio({ variables: { input: postPublication } })
    } else {
      await createProductPostPublication({
        variables: {
          input: {
            product: { id: product.id },
            ...postPublication,
            fc_duration: postPublication.type === 'epp' ? 1 : postPublication.fc_duration,
          },
        },
      })
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostPublication((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.id]: event.target.id === 'fc_duration' ? parseInt(event.target.value) : event.target.value },
      }
    })
  }

  return (
    <FormLayout
      type={productPostPublication ? 'update' : 'create'}
      title={productPostPublication ? 'Mettre à jour une publication' : 'Ajouter une publication'}
      onSubmit={handleSubmit}
      submitDisabled={false}
    >
      {/* publication_version */}
      <Grid item style={{ width: '100%' }}>
        <TextField
          fullWidth
          id="publication_version"
          label="Version"
          disabled
          variant="outlined"
          value={postPublication?.publication_version}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel style={{ paddingRight: theme.spacing(1) }}>Type</InputLabel>
          <Select
            variant="outlined"
            label="Type"
            fullWidth
            id="type"
            value={postPublication.type}
            onChange={(event) => {
              if (event.target.value) {
                setPostPublication((prevState) => {
                  return {
                    ...prevState,
                    fc_duration: event.target.value === 'epp' ? undefined : prevState.fc_duration,
                    ...{
                      forum_slug: `${product.code_name.toLowerCase()}_2325_3`,
                      new_product_code:
                        event.target.value === 'fc'
                          ? `${product.code_name.toLowerCase()}_2325_${event.target.value as string}`
                          : event.target.value === 'epp'
                          ? `${product.code_name.toLowerCase()}_2325_3_epp`
                          : `${product.code_name.toLowerCase()}_2325_3`,
                      type: event.target.value as string,
                    },
                  }
                })
              }
            }}
          >
            <MenuItem value={'pi'}>Programme intégré</MenuItem>
            <MenuItem value={'fc'}>Formation continue</MenuItem>
            <MenuItem value={'epp'}>Epp seul</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <TextField
          fullWidth
          id="new_product_code"
          label="Nouveau code produit"
          variant="outlined"
          value={postPublication?.new_product_code}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <TextField
          disabled={postPublication.type === 'epp'}
          type="number"
          fullWidth
          id="fc_duration"
          label="Durée de la formation continue"
          variant="outlined"
          value={postPublication?.fc_duration}
          onChange={handleChange}
        />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <TextField
          fullWidth
          id="forum_slug"
          label="Slug forum"
          variant="outlined"
          value={postPublication?.forum_slug}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={postPublication?.has_cv}
                onChange={() => {
                  setPostPublication((prevState) => {
                    return {
                      ...prevState,
                      has_cv: !prevState?.has_cv,
                    }
                  })
                }}
              />
            }
            label={
              postPublication?.has_cv
                ? 'La formation contient une classe virtuelle'
                : 'La formation ne contient pas de classe virtuelle'
            }
          />
        </FormGroup>
      </Grid>
    </FormLayout>
  )
}

export default PostPublicationForm
