import { Grid, Typography } from '@material-ui/core'
import Bugsnag from '@bugsnag/js'

type ErrorProps = {
  error: any
}

export default ({ error }: ErrorProps) => {
  Bugsnag.notify(`App uncaught error: ${error as string}`)
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <Typography variant="h6" color="error">
          Oops, something went wrong!
        </Typography>
      </Grid>
      {!import.meta.env.PROD && (
        <Grid item>
          <Typography variant="body1" style={{ wordBreak: 'break-word' }}>
            {JSON.stringify(error)}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
