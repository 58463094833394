// REACT-HOOKS
import { FC, useRef } from 'react'
// MATERIAL UI
import { Grid, Paper, useTheme, Typography } from '@material-ui/core'
// DND
import { useDrag, useDrop } from 'react-dnd'
import type { Identifier } from 'dnd-core'
// ICON
import { MdCheckCircle } from 'react-icons/md'
// TYPES
import { LessonQuestionType } from '../../../../lib/sharedTypes'

type CardProps = ItemType & { isDraggable: boolean }

type ItemType = {
  qq_question: LessonQuestionType
  index: number
  moveCard: (dragIndex: number, hoverIndex: number, idTarget: Identifier | null, idItem: Identifier | null) => void
}

const QuestionCardShort: FC<CardProps> = ({ qq_question, index, moveCard, isDraggable }: CardProps) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<ItemType, void, { handlerId: Identifier | null }>({
    accept: ['question_card_short', 'question_card'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop(item: ItemType, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex && monitor.getItemType() === 'question_card_short') {
        return
      }

      // Determine rectangle on screen
      // const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      // const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      // const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return
      // }

      // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return
      // }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, 'question_card_short', monitor.getItemType())

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const id = qq_question.id
  const [{ isDragging }, drag] = useDrag({
    canDrag: isDraggable,
    type: 'question_card_short',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const lessonQuestionType = (lesson_question: LessonQuestionType) => {
    if (lesson_question.mc_question) {
      const correctCount = lesson_question?.mc_question?.mc_items.filter((mc_item) => mc_item.correct).length
      if (correctCount > 1) {
        return 'Choix multiples'
      } else {
        return 'Choix unique'
      }
    }
    if (lesson_question.gapfill_question) {
      return 'Texte à trous'
    }
    if (lesson_question.ranking_question) {
      return 'Classement'
    }
    return 'Type de question inconnu'
  }

  return (
    <Grid item ref={ref} data-handler-id={handlerId}>
      <Paper
        elevation={3}
        style={{
          borderRadius: '4px',
          opacity: isDragging ? 0 : 1,
          padding: theme.spacing(1),
          cursor: isDraggable ? 'grab' : 'default',
        }}
      >
        <Grid container direction="row"></Grid>
        <Grid container alignItems="center" direction="row">
          <MdCheckCircle size="22" color={isDraggable ? theme.palette.warning.main : theme.palette.success.main} />
          <Typography variant="caption" style={{ marginLeft: 5 }}>
            Question - {lessonQuestionType(qq_question)}
          </Typography>
        </Grid>
        <Typography variant="caption">
          {qq_question.mc_question ? (
            qq_question.mc_question.statement
          ) : qq_question.ranking_question ? (
            qq_question.ranking_question.statement
          ) : (
            <div dangerouslySetInnerHTML={{ __html: qq_question.gapfill_question?.text }}></div>
          )}
        </Typography>
      </Paper>
    </Grid>
  )
}

export default QuestionCardShort
