/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState } from 'react'
//ASSETS
import NOIMG_banner from '../../assets/NOIMG_banner.jpg'
// COMPONENTS
import { ColorForm, PdfForm, ScreenShotForm } from '../../components/Forms/catalog'
import { Loading, Error, CustomModal } from '../../components'
// MATERIAL UI
import { Grid, Button, Divider, Typography, makeStyles, useTheme, Paper } from '@material-ui/core'
// TYPE
import { CatalogType } from '../../../../lib/sharedTypes'
// GQL
import { CATALOG_VISUAL_MANAGEMENT_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
// REACT-ROUTER
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const VisualManagement = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { catalog_id } = useParams()
  const [updateModal, setUpdateModal] = useState('')

  if (!catalog_id) {
    return <Error error={'Unknown catalog_id'} />
  }
  const {
    loading,
    error,
    data: { catalog } = {},
    refetch,
  } = useQuery<{
    catalog: CatalogType
  }>(CATALOG_VISUAL_MANAGEMENT_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(catalog_id.toString()),
        },
      },
    },
  })

  if (loading || !catalog) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column">
      {/* SCREENSHOT */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Screenshot</Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('screenshot')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            <img
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                maxHeight: '350px',
                padding: theme.spacing(1),
              }}
              src={`${catalog?.screenshots_s3link}?${new Date().getTime()}`} //force refresh
              onError={({ currentTarget }) => {
                currentTarget.src = NOIMG_banner
              }}
              loading="lazy"
              alt="screenshots_s3link"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* PDF */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">PDF</Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('pdf')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            {catalog?.catalog_pdf_s3link ? (
              <Grid item container justifyContent="center">
                {catalog?.catalog_pdf_s3link && (
                  <object
                    data={`${catalog?.catalog_pdf_s3link}?update=${new Date().getTime()}#view=FitV`}
                    width="100%"
                    height="500"
                  >
                    <p>{catalog?.catalog_pdf_s3link}</p>
                  </object>
                )}
              </Grid>
            ) : (
              <img
                style={{
                  objectFit: 'cover',
                  maxWidth: '100%',
                  maxHeight: '350px',
                  padding: theme.spacing(1),
                }}
                src={NOIMG_banner}
                loading="lazy"
                alt="catalog_pdf_s3link"
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      {/* COLOR */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Couleur</Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('color')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item container>
              <Grid item style={{ width: '100%', height: 250, backgroundColor: catalog?.color }}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <CustomModal
        open={updateModal === 'color'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ColorForm
          catalog={catalog}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'pdf'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <PdfForm
          catalog={catalog}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'screenshot'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ScreenShotForm
          catalog={catalog}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default VisualManagement
