import { Grid, Chip, TableRow, TableCell, makeStyles } from '@material-ui/core'
import { getWeekNumber, getMondays } from '../../utils/calendar'
import { stringToColor } from '../../utils/misc'
import { CALENDAR_PROJECTS_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
import { ProjectType } from '../../../../lib/sharedTypes'
import './calendar.css'

type WeekCoursesPropsType = {
  startDate: Date
  endDate: Date
}

const useStyles = makeStyles((theme) => ({
  firstCol: {
    left: 0,
    background: theme.palette.background.default,
    zIndex: 1000,
    position: 'sticky',
    padding: theme.spacing(1),
    minWidth: 250,
    maxWidth: 250,
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRight: `2px solid ${theme.palette.action.disabled}`,
    borderBottom: `2px solid ${theme.palette.action.disabled}`,
  },
  column: {
    minWidth: 150,
    maxWidth: 150,
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRight: `2px solid ${theme.palette.action.disabled}`,
    borderBottom: `2px solid ${theme.palette.action.disabled}`,
  },
  currentWeek: {
    minWidth: 150,
    maxWidth: 150,
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  dayBox: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 42,
    width: 28,
  },
}))

const WeekCourses = ({ startDate, endDate }: WeekCoursesPropsType) => {
  const classes = useStyles()
  const currentWeekNumber = getWeekNumber(new Date())
  const { data: { projects = [] } = {} } = useQuery<{ projects: ProjectType[] }>(CALENDAR_PROJECTS_QUERY)
  return (
    <TableRow>
      <TableCell className={classes.firstCol}>Publication LMS</TableCell>
      {getMondays(startDate, endDate).map((monday) => {
        const weekNumber = getWeekNumber(monday)
        return (
          <TableCell
            className={currentWeekNumber === weekNumber ? classes.currentWeek : classes.column}
            key={monday.getTime()}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              spacing={1}
              className="hide-scroll-bar"
              style={{ height: '80px', overflow: 'scroll' }}
            >
              {projects
                .filter((project) => {
                  return (
                    project.course.premiere_session &&
                    new Date(project.course.premiere_session).getFullYear() === monday.getFullYear() &&
                    getWeekNumber(new Date(project.course.premiere_session)) === weekNumber
                  )
                })
                .map((project) => {
                  return (
                    <Grid item key={project.id}>
                      <Chip
                        size="small"
                        label={project.code_name}
                        style={{
                          backgroundColor: stringToColor(project.code_name),
                          fontSize: 9,
                          color: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  )
                })}
            </Grid>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default WeekCourses
