//REACT
import { useState } from 'react'
//ASSETS
import NOIMG_banner from '../../assets/NOIMG_banner.jpg'
import NOIMG_logo from '../../assets/NOIMG_logo.svg'
// MATERIAL UI
import {
  Grid,
  Chip,
  Typography,
  Card,
  CardActionArea,
  makeStyles,
  useTheme,
  Box,
  useMediaQuery,
} from '@material-ui/core'
//TYPES
import type { CourseType } from '../../../../lib/sharedTypes'
// COMPONENTS
import { UserAvatar } from '..'
// REACT-ROUTER
import { useNavigate } from 'react-router'

type CourseCardType = {
  course: CourseType
}

const useStyles = makeStyles(() => ({
  card: {
    flexGrow: 1,
    cursor: 'pointer',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': {
      transform: 'scale3d(1.025, 1.025, 1)',
    },
  },
  directorItem: {
    '&:not(:first-child)': {
      marginLeft: '-15px',
    },
  },
  cardLeft: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
  },
  cardImg: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    // objectFit: 'cover',
  },
  cardTypo: {
    position: 'absolute',
    width: '70%',
    height: '100%',
    zIndex: 3,
  },
  cardBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
}))

const CourseCard = ({ course }: CourseCardType) => {
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
  const [gradientColor, setGradientColor] = useState<string>(course.color)

  return (
    <Grid
      item
      xs={12}
      sm={6}
      onClick={(e: React.MouseEvent) => {
        if (e.metaKey || e.ctrlKey) {
          window.open(`/formations/${course.code_name}`, '_blank')
        } else {
          navigate(`/formations/${course.code_name}`)
        }
      }}
      style={{ display: 'flex' }}
    >
      <Card className={classes.card} elevation={3}>
        <CardActionArea style={{ height: '100%' }}>
          <Grid container direction="row" wrap="nowrap" style={{ height: '100%' }}>
            <Grid
              item
              style={{
                minWidth: mdUp ? 175 : 115,
                maxWidth: mdUp ? 175 : 115,
                flexGrow: 1,
                display: 'flex',
              }}
            >
              <Card className={classes.cardLeft}>
                {course.s3_logo ? (
                  <img
                    className={classes.cardTypo}
                    alt="Logotype"
                    src={course.s3_logo}
                    onError={({ currentTarget }) => {
                      currentTarget.src = NOIMG_logo
                    }}
                  />
                ) : (
                  <img className={classes.cardTypo} alt="Logotype" src={NOIMG_logo} />
                )}
                <Box
                  className={classes.cardBox}
                  style={{ background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${gradientColor} 100%)` }}
                />
                {course.s3_banner ? (
                  <img
                    className={classes.cardImg}
                    alt="Card"
                    src={course.s3_banner}
                    onError={({ currentTarget }) => {
                      currentTarget.src = NOIMG_banner
                      setGradientColor('#8197BE')
                    }}
                  />
                ) : (
                  <img className={classes.cardImg} alt="Card" src={NOIMG_banner} />
                )}
              </Card>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              style={{
                whiteSpace: 'nowrap',
                flexGrow: 1,
                height: '100%',
                padding: theme.spacing(1),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Grid item style={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="nowrap"
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Grid item style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {course.code_name}
                  </Grid>
                  <Grid item>
                    <Chip
                      size="small"
                      variant="outlined"
                      key={course.published_on ? 'Publiée' : 'En production'}
                      label={course.published_on ? 'Publiée' : 'En production'}
                      style={{
                        color: course.published_on ? theme.palette.success.main : theme.palette.info.main,
                        border: `1px solid ${
                          course.published_on ? theme.palette.success.main : theme.palette.info.main
                        }`,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Typography variant="caption" noWrap>
                    {course.name}
                  </Typography>
                </Grid>

                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant="body2">Managers: </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
                      {course.managers &&
                        course.managers.length > 0 &&
                        course.managers.map((manager, index) => {
                          return (
                            <div key={index} className={classes.directorItem} style={{ zIndex: 3 - index }}>
                              <UserAvatar user={manager} key={manager.id} />
                            </div>
                          )
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default CourseCard
