import { useState } from 'react'
//COMPONENTS
import NavBar from './NavBar'
import Drawer from './Drawer'
import Footer from './Footer'
//MATERIAL UI
import clsx from 'clsx'
import { Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'
//MISC
import { Helmet } from 'react-helmet'

const DRAWER_WIDTH = '240px'

type LayoutPropsType = {
  children: React.ReactNode
  title: string
  menuClosedByDefault?: boolean
}

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: '100vh',
    display: 'flex',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
    [theme.breakpoints.up('md')]: {
      marginLeft: DRAWER_WIDTH,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
      minHeight: `calc(100vh - 64px)`,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '56px',
      minHeight: `calc(100vh - 56px)`,
    },
    padding: 20,
  },
  contentShift: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
      minHeight: `calc(100vh - 64px)`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
  footer: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: DRAWER_WIDTH,
  },
  footer_shift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

const Layout = (props: LayoutPropsType) => {
  const theme = useTheme()
  const classes = useStyles()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
  const [drawerOpen, setDrawerOpen] = useState(mdUp)

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Grid>
        <NavBar {...props} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} mdUp={mdUp} />
        <Drawer
          {...props}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          drawerWidth={DRAWER_WIDTH}
          mdUp={mdUp}
        />
        <Grid
          className={clsx(classes.content, { [classes.contentShift]: !drawerOpen })}
          id={drawerOpen ? 'content' : 'contentShift'}
        >
          {props.children}
        </Grid>
        <Grid className={classes.footer}>
          <Footer />
        </Grid>
      </Grid>
    </>
  )
}

export default Layout
