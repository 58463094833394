import { useState } from 'react'
//MATERIAL-UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, alpha, Button, makeStyles, TextField, CircularProgress } from '@material-ui/core'
//GQL
import { COURSES_QUERY } from '../../../gql/queries'
import { IMPORT_RESOURCES_MUTATION } from '../../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
//TYPES
import { CourseType } from '../../../../../lib/sharedTypes'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

const useStyles = makeStyles((theme) => ({
  deleteBTN: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.1),
    },
  },
  popper: {
    zIndex: 10000,
  },
}))

type ImportResourceFormPropsType = {
  course: CourseType
  handleClose: () => void
  refetch: () => Promise<any>
}

const ImportResourceForm = ({ handleClose, refetch, course }: ImportResourceFormPropsType) => {
  const classes = useStyles()
  const [setAlert] = useAlert()
  const [selectedCourse, setSelectedCourse] = useState<CourseType>()
  const { data: { courses = [] } = {} } = useQuery<{
    courses: CourseType[]
  }>(COURSES_QUERY, {
    variables: {
      order: {
        field: 'CODE_NAME',
        sort: 'ASC',
      },
    },
  })

  const [importResources, { loading }] = useMutation<{ importResources: boolean }>(IMPORT_RESOURCES_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Resources importées` })
      await refetch()
      handleClose()
    },
  })

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Autocomplete
          fullWidth
          classes={{ popper: classes.popper }}
          options={courses}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option?.code_name || ''}
          onChange={(_, value) => {
            if (value) {
              setSelectedCourse(value)
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} style={{ zIndex: 10000 }} variant="outlined" label="Formation" />
          }}
        />
      </Grid>

      <Grid
        item
        container
        justifyContent="space-around"
        alignItems="center"
        style={{ height: '100%', width: '100%' }}
        spacing={1}
      >
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: 110 }}
            disabled={!selectedCourse}
            onClick={async () => {
              await importResources({
                variables: { input: { from: { id: selectedCourse?.id }, to: { id: course.id } } },
              })
            }}
          >
            {!loading ? 'Importer' : <CircularProgress variant="indeterminate" size="1.5rem" id="upload_progress" />}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" className={classes.deleteBTN} onClick={handleClose}>
            Annuler
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ImportResourceForm
