import { useState } from 'react'
//COMPONENTS
import { DocumentForm } from '../../components/Forms/project'
import { DeleteForm } from '../../components/Forms'
import { CustomTable, Error, Loading, CustomModal } from '../../components'
//ICON
import { MdMoreVert } from 'react-icons/md'
// MATERIAL UI
import { Grid, Button, Link, IconButton, MenuItem, Menu } from '@material-ui/core'
// TYPE
import { ColumnType } from '../../utils/types'
import { SortEnum, ProjectType, ProjectDocumentType } from '../../../../lib/sharedTypes'
//GQL
import { PROJECT_DOCUMENTS_QUERY } from '../../gql/queries'
import { DELETE_PROJECT_DOCUMENT_MUTATION } from '../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
//ROUTER
import { useParams } from 'react-router'
//UTILS
import { handleError } from '../../utils/handleError'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { isAuthorized, isCourseManager } from '../../utils/misc'

const Documents = () => {
  const { code_name } = useParams()
  const [setAlert] = useAlert()
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [field, setField] = useState<keyof ProjectDocumentType>('name')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<ProjectDocumentType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [documentModal, setDocumentModal] = useState(false)
  const [deleteProjectDocument] = useMutation<{
    deleteProjectDocument: boolean
  }>(DELETE_PROJECT_DOCUMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Document supprimé` })
    },
  })

  const {
    loading,
    error,
    data: { project } = {},
    refetch,
  } = useQuery<{
    project: ProjectType
  }>(PROJECT_DOCUMENTS_QUERY, {
    variables: {
      order: {
        field: field,
        sort: sort,
      },
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  const columns: ColumnType<ProjectDocumentType>[] = [
    {
      title: 'Lien vers le document',
      field: 's3_file',
      render: (row: ProjectDocumentType) => (
        <Link href={row.s3_file} target="_blank">
          {row.name}
        </Link>
      ),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: ProjectDocumentType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: ProjectDocumentType) => row.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: ProjectDocumentType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (error || !code_name) {
    return <Error error={error} />
  }

  if (loading || !project) {
    return <Loading />
  }

  return (
    <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
      <Grid item container justifyContent="flex-end" style={{ width: '100%' }}>
        <Button
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR', 'DESIGN']) || isCourseManager(project.course))}
          variant="outlined"
          color="primary"
          onClick={() => {
            setSelectedRow(undefined)
            setDocumentModal(true)
          }}
        >
          Ajouter un document
        </Button>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <CustomTable
          columns={columns}
          rows={project.documents}
          field={field}
          sort={sort}
          setSort={setSort}
          setField={setField}
          rowsCount={project.documents.length}
        />
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) || isCourseManager(project.course))}
          onClick={() => {
            setDocumentModal(true)
            setAnchorEl(null)
          }}
        >
          Modifier le document
        </MenuItem>
        <MenuItem
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) || isCourseManager(project.course))}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer le document
        </MenuItem>
      </Menu>
      <CustomModal
        size="xs"
        title="Supprimer le document"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteProjectDocument({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
      <CustomModal
        open={documentModal}
        onClose={() => {
          setDocumentModal(false)
        }}
        animation="slide-up"
      >
        <DocumentForm
          document={selectedRow}
          refetch={refetch}
          handleClose={() => {
            setDocumentModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}
export default Documents
