import { Grid, CircularProgress } from '@material-ui/core'

type LoadingPropsType = {
  size?: string
}

export default ({ size }: LoadingPropsType) => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress color="primary" size={size || '40px'} />
    </Grid>
  )
}
