import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
// TYPE
import { CatalogType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_CATALOG_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type SeoFormPropsType = {
  catalog: CatalogType
  refetch: () => Promise<any>
  handleClose: () => void
}

const SeoForm = ({ catalog, refetch, handleClose }: SeoFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateCatalog, setStateCatalog] = useState<CatalogType>(catalog)

  const [updateCatalog] = useMutation<{ updateCatalog: CatalogType }>(UPDATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCatalog.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCatalog((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const catalogInput = {
      id: stateCatalog.id,
      seo_url: stateCatalog.seo_url,
      seo_title: stateCatalog.seo_title,
      seo_meta_description: stateCatalog.seo_meta_description,
    }
    await updateCatalog({ variables: { input: catalogInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les informations SEO"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateCatalog) === JSON.stringify(catalog)}
    >
      <Grid item>
        <TextField
          fullWidth
          name="seo_url"
          label="URL"
          variant="outlined"
          value={stateCatalog?.seo_url || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="seo_title"
          label="Titre"
          variant="outlined"
          value={stateCatalog?.seo_title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="seo_meta_description"
          label="Description"
          variant="outlined"
          value={stateCatalog?.seo_meta_description || ''}
          onChange={handleChange}
          required
        />
      </Grid>
    </FormLayout>
  )
}

export default SeoForm
