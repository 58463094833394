//MATERIAL UI
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Breadcrumbs, Link } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'
//UTILS
import { titelizeWord } from '../utils/misc'

type titleProps = {
  title?: string
}

const useStyles = makeStyles(() => ({
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const Title = ({ title = undefined }: titleProps) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const breadCrumbs = decodeURI(location.pathname).replace('%20', '_').split('/').slice(1)

  return (
    <Grid container direction="column" style={{ marginBottom: theme.spacing(2), zIndex: 10 }}>
      {title && <Typography variant="h5">{title}</Typography>}
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" className={classes.link} href="/">
          Walter
        </Link>
        {breadCrumbs.map((bread, index) => {
          let url = breadCrumbs.slice(0, index + 1).join('/')
          if (url.startsWith('projects') && url.endsWith('videos')) {
            url = url.split('/').slice(0, -1).join('/')
          }
          if (url.includes('MONTHLY') || url.includes('WEEKLY') || url.includes('DAILY')) {
            url = 'reports/MONTHLY'
          }
          if (url.includes('WEEKLY')) {
            url = 'reports/WEEKLY'
          }
          if (url.includes('DAILY')) {
            url = 'reports/DAILY'
          }
          return (
            <Link
              className={classes.link}
              key={index}
              color={index === breadCrumbs.length - 1 ? 'primary' : 'inherit'}
              onClick={(e: React.MouseEvent) => {
                if (e.metaKey || e.ctrlKey) {
                  window.open('/' + url, '_blank')
                } else {
                  navigate('/' + url)
                }
              }}
            >
              {titelizeWord(bread)}
            </Link>
          )
        })}
      </Breadcrumbs>
    </Grid>
  )
}

export default Title
