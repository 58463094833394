//REACT
import React, { useState } from 'react'
//COMPONENTS
import { DurationInput, MultiSelectChips, Error } from '../index'
import { FormLayout } from './index'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, TextField } from '@material-ui/core'
//TYPES
import { ProjectType, UserType, CourseType } from '../../../../lib/sharedTypes'
import { DeepPartial } from '../../utils/types'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { CALENDAR_USERS_QUERY, COURSES_QUERY } from '../../gql/queries'
import { CREATE_PROJECT_MUTATION } from '../../gql/mutations'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
//ROUTER
import { useNavigate } from 'react-router'

type ProjectFormPropsType = {
  project?: ProjectType
  handleClose: () => void
  refetch: () => Promise<any>
}

const ProjectForm = ({ project, handleClose, refetch }: ProjectFormPropsType) => {
  const [setAlert] = useAlert()
  const navigate = useNavigate()
  const [newProject, setNewProject] = useState<DeepPartial<ProjectType> | undefined>(project)
  const [directors, setDirectors] = useState<UserType[]>([])
  const [editors, setEditors] = useState<UserType[]>([])
  const [others, setOthers] = useState<UserType[]>([])
  const { error, data: { users } = {} } = useQuery<{ users: UserType[] }>(CALENDAR_USERS_QUERY, {
    variables: {
      where: {
        job: {
          category: {
            in: ['DIRECTOR', 'EDITOR', 'OTHER'],
          },
        },
      },
    },
  })
  const { error: errorCourses, data: { courses } = {} } = useQuery<{ courses: CourseType[] }>(COURSES_QUERY, {
    variables: {
      order: { field: 'code_name', sort: 'ASC' },
    },
  })

  const [createProject] = useMutation<{
    createProject: ProjectType
  }>(CREATE_PROJECT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createProject.code_name} créé` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const members: Array<{ id: number }> = []
    directors.map((director) => members.push({ id: director.id }))
    editors.map((editor) => members.push({ id: editor.id }))
    others.map((other) => members.push({ id: other.id }))

    const projectInput = {
      ...newProject,
      course: { id: newProject?.course?.id },
      members: members,
    }
    if (newProject?.id) {
      // await updateProjectMutation({ variables: { input: projectInput } })
    } else {
      const { data } = await createProject({ variables: { input: projectInput } })
      if (data) {
        navigate(data.createProject.code_name)
      }
    }
  }

  if (error) {
    return <Error error={error} />
  }

  if (errorCourses) {
    return <Error error={errorCourses} />
  }

  return (
    <FormLayout type="create" title="Créer un projet" onSubmit={handleSubmit} submitDisabled={newProject === undefined}>
      {/* <Grid item>
        <TextField
          fullWidth
          name="name"
          label="Nom"
          variant="outlined"
          value={newProject?.name || ''}
          onChange={(event) => {
            setNewProject((prevState) => {
              return {
                ...prevState,
                ...{ [event.target.name]: event.target.value },
              }
            })
          }}
          required
        />
      </Grid> */}
      {/* <Grid item>
        <TextField
          fullWidth
          name="code_name"
          label="Nom de code"
          variant="outlined"
          value={newProject?.code_name || ''}
          onChange={(event) => {
            setNewProject((prevState) => {
              return {
                ...prevState,
                ...{ [event.target.name]: event.target.value },
              }
            })
          }}
          required
        />
      </Grid> */}
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <DurationInput
            format="hh"
            required={true}
            label="Durée cible"
            defaultValue={newProject?.duration_target}
            onChange={(duration) => {
              setNewProject((prevState) => {
                return {
                  ...prevState,
                  duration_target: duration,
                }
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DurationInput
            required={true}
            label="Durée de production"
            format="ddd"
            defaultValue={newProject?.duration_production}
            onChange={(duration) => {
              setNewProject((prevState) => {
                return {
                  ...prevState,
                  duration_production: duration,
                }
              })
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        {courses && (
          <Autocomplete
            fullWidth
            defaultValue={newProject?.course}
            options={courses}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option?.code_name || ''}
            onChange={(_, value) => {
              if (value) {
                setNewProject((prevState) => {
                  return {
                    ...prevState,
                    course: value,
                  }
                })
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} required variant="outlined" label="Formation" />
            }}
          />
        )}
      </Grid>
      <Grid item>
        <MultiSelectChips
          required={true}
          multiSelectLabel="Réalisateur(s)"
          keyToUpdate="directors"
          labels={users ? users?.filter((user) => user.job.category === 'DIRECTOR').map((user) => user.username) : []}
          selectedLabelsList={directors.map((user) => user.username)}
          handleChange={(_, values) => {
            const value = users?.filter((user) => values.includes(user.username))
            if (value) setDirectors(value)
          }}
        />
      </Grid>
      <Grid item>
        <MultiSelectChips
          multiSelectLabel="Monteur(s)"
          keyToUpdate="editors"
          labels={users ? users?.filter((user) => user.job.category === 'EDITOR').map((user) => user.username) : []}
          selectedLabelsList={editors.map((user) => user.username)}
          handleChange={(_, values) => {
            const value = users?.filter((user) => values.includes(user.username))
            if (value) setEditors(value)
          }}
        />
      </Grid>
      <Grid item>
        <MultiSelectChips
          multiSelectLabel="Autre(s)"
          keyToUpdate="others"
          labels={users ? users?.filter((user) => user.job.category === 'OTHER').map((user) => user.username) : []}
          selectedLabelsList={others.map((user) => user.username)}
          handleChange={(_, values) => {
            const value = users?.filter((user) => values.includes(user.username))
            if (value) setOthers(value)
          }}
        />
      </Grid>
    </FormLayout>
  )
}

export default ProjectForm
