import { useState } from 'react'
//COMPONENTS
import { LinkResourceForm, ResourceForm } from '../../components/Forms/lesson'
import { DeleteForm } from '../../components/Forms'
import { CustomTable, CustomModal } from '../../components'
//ICON
import { MdMoreVert } from 'react-icons/md'
//MATERIAL UI
import {
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Paper,
  useTheme,
  makeStyles,
  Button,
  Divider,
  Link,
} from '@material-ui/core'
//TYPES
import { ColumnType } from '../../utils/types'
import { LessonType, ResourceType } from '../../../../lib/sharedTypes'
//GQL
import { DELETE_RESOURCE_MUTATION } from '../../gql/mutations'
import { useMutation } from '@apollo/client'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'

type LessonRessourcesPropsType = {
  lesson: LessonType
  refetch: () => Promise<any>
  disabled: boolean
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonRessources = ({ lesson, refetch, disabled }: LessonRessourcesPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState(false)
  const [resourceModal, setResourceModal] = useState(false)
  const [linkResourceModal, setLinkResourceModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<ResourceType | undefined>(undefined)
  const [deleteResourceMutation] = useMutation<{ deleteResource: boolean }>(DELETE_RESOURCE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Ressource supprimée` })
      await refetch()
    },
  })

  const ressourcesColumns: ColumnType<ResourceType>[] = [
    {
      title: 'Nom',
      field: 'name',
      render: (row: ResourceType) => (
        <Link href={row.s3_file || ''} target="_blank">
          {row.name}
        </Link>
      ),
      type: 'String',
      sortable: false,
    },
    {
      title: 'Commentaire',
      field: 'comment',
      render: (row: ResourceType) => row.comment,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: ResourceType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: ResourceType) => row.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: ResourceType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  return (
    <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
      <Grid item>
        <Grid item container justifyContent="space-between">
          <Typography variant="h5">Ressources</Typography>
          <Grid item>
            <Button
              disabled={disabled}
              color="primary"
              variant="outlined"
              style={{ marginRight: theme.spacing(1) }}
              onClick={() => {
                setSelectedRow(undefined)
                setResourceModal(true)
              }}
            >
              Ajouter
            </Button>
            <Button
              disabled={disabled}
              color="secondary"
              variant="outlined"
              onClick={() => {
                setLinkResourceModal(true)
              }}
            >
              Lier
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
          <Divider />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CustomTable<ResourceType> columns={ressourcesColumns} rows={lesson.resources} field="name" />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setResourceModal(true)
            setAnchorEl(null)
          }}
        >
          Modifier la ressource
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la ressource
        </MenuItem>
      </Menu>
      <CustomModal
        open={linkResourceModal}
        onClose={() => {
          setLinkResourceModal(false)
        }}
        animation="slide-up"
      >
        <LinkResourceForm
          course={lesson.course}
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setLinkResourceModal(false)
          }}
        />
      </CustomModal>

      <CustomModal
        open={resourceModal}
        onClose={() => {
          setResourceModal(false)
        }}
        animation="slide-up"
      >
        <ResourceForm
          course={lesson.course}
          resource={selectedRow}
          lessons={[lesson]}
          refetch={refetch}
          handleClose={() => {
            setResourceModal(false)
            setSelectedRow(undefined)
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer la ressource"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteResourceMutation({
              variables: { input: { id: selectedRow?.id, lessons: [{ id: lesson.id }] } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Paper>
  )
}

export default LessonRessources
