import { useCallback, useState } from 'react'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, TextField, Typography } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
// TYPE
import { CatalogType, ProductCatalogPosType, ProductType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_CATALOG_MUTATION } from '../../../gql/mutations'
import { PRODUCTS_QUERY } from '../../../gql/queries'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { DeepPartial } from '../../../utils/types'
// DND
import { ProductDNDItem } from './ProductDNDItem'
import update from 'immutability-helper'

type ProductFormPropsType = {
  catalog: CatalogType
  refetch: () => Promise<any>
  handleClose: () => void
}

const ProductForm = ({ catalog, refetch, handleClose }: ProductFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateCatalog, setStateCatalog] = useState<DeepPartial<CatalogType>>(catalog)
  const [stateProductCatalogPosition, setStateProductCatalogPosition] = useState<ProductCatalogPosType[]>(
    catalog.product_catalog_positions.sort((a, b) => {
      return a.position - b.position
    }) || [],
  )
  const { data: { products: products = [] } = {} } = useQuery<{ products: ProductType[] }>(PRODUCTS_QUERY)

  const [updateCatalog] = useMutation<{ updateCatalog: CatalogType }>(UPDATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCatalog.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCatalog((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const catalogInput = {
      id: stateCatalog.id,
      course_top_title: stateCatalog.course_top_title,
      product_top_1: stateCatalog.product_top_1 ? { id: stateCatalog.product_top_1.id } : { id: null },
      product_top_2: stateCatalog.product_top_2 ? { id: stateCatalog.product_top_2.id } : { id: null },
      product_top_3: stateCatalog.product_top_3 ? { id: stateCatalog.product_top_3.id } : { id: null },
      products: stateCatalog?.products?.map((product) => {
        return { id: product?.id }
      }),
      product_catalog_positions: stateProductCatalogPosition.map((productCatalogPosition) => {
        return {
          id: productCatalogPosition.id,
          position: productCatalogPosition.position,
          product: { id: productCatalogPosition.product.id },
        }
      }),
    }
    await updateCatalog({ variables: { input: catalogInput } })
  }

  const handleMoveDND = useCallback((dragIndex: number, hoverIndex: number) => {
    setStateProductCatalogPosition((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les produits"
      onSubmit={handleSubmit}
      submitDisabled={
        JSON.stringify(stateCatalog) === JSON.stringify(catalog) &&
        JSON.stringify(stateProductCatalogPosition) === JSON.stringify(catalog.product_catalog_positions)
      }
    >
      <Grid item>
        <TextField
          fullWidth
          name="course_top_title"
          label="Titre"
          variant="outlined"
          value={stateCatalog?.course_top_title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id="product_top_1"
          defaultValue={stateCatalog.product_top_1}
          options={products}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option?.code_name || ''}
          onChange={(_, value) => {
            if (value) {
              setStateCatalog({
                ...stateCatalog,
                product_top_1: value,
              })
            } else {
              setStateCatalog({
                ...stateCatalog,
                product_top_1: undefined,
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Top 1" />
          }}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id="product_top_2"
          defaultValue={stateCatalog.product_top_2}
          options={products}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option?.code_name || ''}
          onChange={(_, value) => {
            if (value) {
              setStateCatalog({
                ...stateCatalog,
                product_top_2: value,
              })
            } else {
              setStateCatalog({
                ...stateCatalog,
                product_top_2: undefined,
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Top 2" />
          }}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id="product_top_3"
          defaultValue={stateCatalog.product_top_3}
          options={products}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option?.code_name || ''}
          onChange={(_, value) => {
            if (value) {
              setStateCatalog({
                ...stateCatalog,
                product_top_3: value,
              })
            } else {
              setStateCatalog({
                ...stateCatalog,
                product_top_3: undefined,
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Top 3" />
          }}
        />
      </Grid>

      <Grid item>
        <Autocomplete
          id="products"
          freeSolo
          filterSelectedOptions
          options={products.filter(
            (product) => !stateProductCatalogPosition.map((pcp) => pcp.product.id).includes(product.id),
          )}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option?.code_name || ''}
          renderTags={() => null}
          onChange={(_, value) => {
            if (value) {
              setStateProductCatalogPosition([
                ...stateProductCatalogPosition,
                { product: value } as ProductCatalogPosType,
              ])
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Produits" />
          }}
        />
      </Grid>

      <Grid item>
        <Typography variant="h6">Position des produits</Typography>
        <Grid
          container
          style={{
            gap: 5,
            marginTop: 15,
          }}
        >
          {stateProductCatalogPosition.map((pcp, index) => (
            <ProductDNDItem
              id={pcp.id}
              index={index}
              moveCard={handleMoveDND}
              removeCard={(index: number) =>
                setStateProductCatalogPosition((prevState) => update(prevState, { $splice: [[index, 1]] }))
              }
              key={pcp.id}
              text={`${pcp.product.code_name}`}
            />
          ))}
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default ProductForm
