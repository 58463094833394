import { useState } from 'react'
//COMPONENTS
import { Loading, Error } from '../index'
import { Notification } from '../Notifications/index'
// MATERIAL UI
import { Grid, Typography, IconButton, Divider } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
// ICONS
import { MdExpandMore, MdExpandLess } from 'react-icons/md'
// TYPES
import { NotificationType } from '../../../../lib/sharedTypes'
//GQL
import { NOTIFICATIONS_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
//REDUX
import { useAppSelector } from '../../hooks/reducerHooks'

type AllNotifsModalPropsType = {
  handleClose: () => void
}

const AllNotifsModal = ({ handleClose }: AllNotifsModalPropsType) => {
  const theme = useTheme()
  const user = useAppSelector((state) => state.user.user)
  const [seeCheckedNotifs, setSeeCheckedNotifs] = useState<boolean>(false)
  const {
    loading,
    error,
    data: { notifications = [] } = {},
    refetch,
  } = useQuery<{
    notifications: NotificationType[]
  }>(NOTIFICATIONS_QUERY, {
    variables: {
      where: {
        target: {
          id: {
            eq: user && parseInt(`${user.id}`),
          },
        },
      },
      order: {
        sort: 'DESC',
        field: 'sent_at',
      },
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  const checkedNotifications = notifications.filter((notification) => notification.checked_at)
  const uncheckedNotifications = notifications.filter((notification) => !notification.checked_at)

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      style={{
        backgroundColor: theme.palette.background.default,
        flexGrow: 1,
        padding: theme.spacing(2),
      }}
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
        spacing={2}
        style={{ minHeight: '100%' }}
      >
        <Grid container item direction="column" spacing={1} xs={8}>
          <Grid item>
            <Typography variant="h4">Toutes mes notifications</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Retrouvez dans cet espace toutes vos notifications. Une fois faites, vous pouvez les checker pour déplacer
              les notifications dans tâches terminées. Les commentaires sont automatiquement mis dans tâches terminées
              une fois lus.
            </Typography>
          </Grid>

          <Grid item>
            <Grid>
              <Typography variant="h6">
                {`${uncheckedNotifications.length} notification${
                  uncheckedNotifications.length > 1 ? 's' : ''
                } et tâche${uncheckedNotifications.length > 1 ? 's' : ''}  à faire`}
              </Typography>
              <Grid style={{ maxHeight: 350, overflowX: 'hidden' }}>
                {uncheckedNotifications.map((notification) => {
                  return (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      refetch={refetch}
                      handleClose={handleClose}
                      type="large"
                    />
                  )
                })}
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item style={{ flexGrow: 1 }}>
              <Divider />
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(1), paddingLeft: theme.spacing(1) }}>
              <Typography variant="caption">
                {checkedNotifications.length} tâche{checkedNotifications.length > 1 ? 's' : ''} effectuée
                {checkedNotifications.length > 1 ? 's' : ''}
              </Typography>
              <IconButton onClick={() => setSeeCheckedNotifs(!seeCheckedNotifs)} style={{ padding: 0 }}>
                {seeCheckedNotifs ? <MdExpandLess /> : <MdExpandMore />}
              </IconButton>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Divider />
            </Grid>
          </Grid>

          <Grid style={{ maxHeight: 350, overflowX: 'auto' }}>
            {seeCheckedNotifs &&
              checkedNotifications.map((notification) => {
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    refetch={refetch}
                    handleClose={handleClose}
                    type="large"
                  />
                )
              })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AllNotifsModal
