import { useState } from 'react'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, TextField } from '@material-ui/core'
// COMPONENTS
import { CatalogFundingOrganismTable } from '../../Product'
import { FormLayout } from '../index'
import { Error } from '../../index'
// TYPE
import { ProductType, CatalogType, ProductCatalogPosType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { CATALOGS_QUERY } from '../../../gql/queries'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type CatalogFundingOrganismFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const CatalogFundingOrganismForm = ({ product, refetch, handleClose }: CatalogFundingOrganismFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const { data: { catalogs: catalogs = [] } = {} } = useQuery<{ catalogs: CatalogType[] }>(CATALOGS_QUERY)
  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: stateProduct.id,
      action_number: isFinancedByANDPC ? stateProduct.action_number : null,
      cpf_action_string: isFinancedByCPF ? stateProduct.cpf_action_string : null,
      catalogs: stateProduct.product_catalog_positions.map((pcp) => {
        return {
          id: pcp.catalog.id,
        }
      }),
      catalog_funding_organisms: stateProduct.catalog_funding_organisms.map((cfo) => {
        return {
          code_name: cfo.code_name,
          listing_price: cfo.listing_price,
          minimum_selling_price: cfo.minimum_selling_price,
          duration: cfo.duration,
          release_date: cfo.release_date,
          is_alias: cfo.is_alias,
          catalog: { id: cfo.catalog.id },
          funding_organism: { id: cfo.funding_organism.id },
          profession: { id: cfo?.profession?.id },
          sale_processes: cfo?.sale_processes?.map((sp) => {
            return { id: sp.id }
          }),
        }
      }),
    }
    await updateProductMutation({ variables: { input: productInput } })
  }

  const isFinancedByANDPC =
    stateProduct?.catalog_funding_organisms.filter((cfo) => {
      return cfo.funding_organism.name.toLowerCase() === 'andpc'
    }).length !== 0

  const isFinancedByCPF =
    stateProduct?.catalog_funding_organisms.filter((cfo) => {
      return cfo.funding_organism.name.toLowerCase() === 'cpf'
    }).length !== 0

  return (
    <FormLayout
      md={12}
      type="update"
      title="Mettre à jour les catalogues / organismes de financement"
      onSubmit={handleSubmit}
      submitDisabled={stateProduct === product}
    >
      <Grid item>
        <Autocomplete
          multiple
          defaultValue={product.product_catalog_positions.map((pcp) => pcp.catalog)}
          options={catalogs || []}
          getOptionSelected={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option?.name}
          onChange={(_, value) => {
            if (value) {
              const catalogIds = value.map((c) => c.id)
              setStateProduct((prevState) => {
                return {
                  ...prevState,
                  catalog_funding_organisms: prevState.catalog_funding_organisms.filter((cfo) =>
                    catalogIds.includes(cfo.catalog.id),
                  ),
                  product_catalog_positions: value.map((catalog) => {
                    return {
                      catalog: { id: catalog.id },
                    }
                  }) as ProductCatalogPosType[],
                }
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Catalogue(s)" />
          }}
        />
      </Grid>
      {isFinancedByANDPC && (
        <Grid item>
          <TextField
            fullWidth
            id="action_number"
            label="Numéro d'action DPC"
            variant="outlined"
            value={stateProduct?.action_number || ''}
            onChange={(e) => {
              setStateProduct((prevState) => {
                return {
                  ...prevState,
                  action_number: e.target.value,
                }
              })
            }}
            required
          />
        </Grid>
      )}

      {isFinancedByCPF && (
        <Grid item>
          <TextField
            fullWidth
            id="cpf_action_string"
            label="Numéro d'action CPF"
            variant="outlined"
            value={stateProduct?.cpf_action_string || ''}
            onChange={(e) => {
              setStateProduct((prevState) => {
                return {
                  ...prevState,
                  cpf_action_string: e.target.value,
                }
              })
            }}
            required
          />
        </Grid>
      )}
      <Grid item>
        <CatalogFundingOrganismTable
          product={stateProduct}
          type="edit"
          onChange={(product) => {
            setStateProduct(product)
          }}
        />
      </Grid>
    </FormLayout>
  )
}

export default CatalogFundingOrganismForm
