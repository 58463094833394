//REACT
import React, { useState, useEffect } from 'react'
// MATERIAL UI
import { Grid, TextField, MenuItem, Typography, Button, useTheme } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
import { Error } from '../../../index'
//TYPES
import { DeepPartial } from '../../../../utils/types'
import { LessonType, CourseType, LessonHtmlTypeEnum, LessonHtmlType } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { COURSE_ID_QUERY } from '../../../../gql/queries'
import { UPDATE_LESSON_HTML_MUTATION, CREATE_LESSON_HTML_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//ROUTER
import { useParams } from 'react-router'
//UTILS
import { handleError } from '../../../../utils/handleError'
import { lessonHtmlTypeMapping } from '../../../../utils/constants'

type LessonHtmlFormPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const LessonHtmlForm = ({ lesson, refetch, handleClose }: LessonHtmlFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const [selectedFile, setSelectedFile] = useState<File>()
  const { error: errorCourse, data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })
  const [createLessonHtmlMutation] = useMutation<{
    createLessonHtml: LessonHtmlType
  }>(CREATE_LESSON_HTML_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createLessonHtml.lesson.name} créé` })
      await refetch()
      handleClose()
    },
  })
  const [updateLessonHtmlMutation] = useMutation<{
    updateLessonHtml: LessonHtmlType
  }>(UPDATE_LESSON_HTML_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon HTML mise-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (errorCourse) {
    return <Error error={errorCourse} />
  }

  useEffect(() => {
    if (!selectedFile) {
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonHtmlInput: Record<string, any> = {
      id: stateLesson?.html?.id,
      content: selectedFile,
      type: stateLesson?.html?.type,
    }
    if (stateLesson?.id) {
      await updateLessonHtmlMutation({
        variables: {
          input: lessonHtmlInput,
        },
      })
    } else {
      lessonHtmlInput.lesson = {
        course: { id: course?.id },
        validated: stateLesson?.validated || false,
        summary_required: stateLesson?.summary_required || false,
        name: stateLesson?.name,
        summary_state: stateLesson?.summary_state,
        summary: stateLesson?.summary,
      }
      await createLessonHtmlMutation({ variables: { input: lessonHtmlInput } })
    }
  }

  return (
    <FormLayout
      type={lesson ? 'update' : 'create'}
      title={lesson ? 'Mettre à jour la leçon HTML' : 'Ajouter une leçon HTML'}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id
          ? JSON.stringify(stateLesson) === JSON.stringify(lesson) && !selectedFile
          : stateLesson === undefined
      }
    >
      {!stateLesson?.id && (
        <Grid item container spacing={2} alignItems="center">
          <Grid item container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                required
                label="Nom"
                name="name"
                defaultValue={stateLesson?.name}
                onChange={(event) => {
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      ...{ [event.target.name]: event.target.value },
                    }
                  })
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item container justifyContent="center">
        <Grid
          item
          style={{
            width: '100%',
            height: 150,
            display: 'flex',
            border: `1px solid ${theme.palette.action.disabled}`,
            borderRadius: 5,
            background: theme.palette.background.default,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6" style={{ fontStyle: 'italic' }}>
            {selectedFile ? (
              <p>{selectedFile?.name}</p>
            ) : (
              <Button color="primary" variant="contained" component="label">
                Selectionner
                <input hidden accept=".xls,.xlsx" type="file" onChange={onSelectFile} />
              </Button>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          {!lesson && (
            <TextField
              select
              fullWidth
              id="job"
              label="Type de leçon HTML"
              variant="outlined"
              defaultValue={stateLesson?.html?.type}
              value={stateLesson?.html?.type || ''}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  getContentAnchorEl: null,
                },
              }}
              onChange={(event) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    html: {
                      ...prevState?.html,
                      type: event.target.value as LessonHtmlTypeEnum,
                    },
                  }
                })
              }}
              required
            >
              {Object.keys(LessonHtmlTypeEnum).map((type) => (
                <MenuItem id="type" key={type} value={type}>
                  {lessonHtmlTypeMapping[type as keyof typeof LessonHtmlTypeEnum]}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default LessonHtmlForm
