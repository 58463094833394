//REACT
import React, { useState } from 'react'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Grid } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../index'
//TYPES
import { ProductThemeType, ProductType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_PRODUCT_THEME_MUTATION, UPDATE_PRODUCT_THEME_MUTATION } from '../../../gql/mutations'
import { PRODUCTS_QUERY } from '../../../gql/queries'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type TeacherFormPropsType = {
  productTheme?: ProductThemeType
  refetch: () => void
  handleClose: () => void
}

const ProductThemeForm = ({ productTheme, refetch, handleClose }: TeacherFormPropsType) => {
  const [value, setValue] = useState('')

  const { data: { products } = {} } = useQuery<{ products: ProductType[] }>(PRODUCTS_QUERY, {
    variables: {
      where: {
        product_theme: {
          id: { eq: null },
        },
      },
      order: { field: 'code_name', sort: 'ASC' },
    },
  })

  const [stateProductTheme, setStateProductTheme] = useState<DeepPartial<ProductThemeType> | undefined>(productTheme)
  const [setAlert] = useAlert()

  const [createProductTheme] = useMutation<{
    createProductTheme: ProductThemeType
  }>(CREATE_PRODUCT_THEME_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createProductTheme.code_name} créé` })
      refetch()
      handleClose()
    },
  })

  const [updateProductTheme] = useMutation<{
    updateProductTheme: ProductThemeType
  }>(UPDATE_PRODUCT_THEME_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProductTheme.code_name} mis-à-jour` })
      refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productThemeInput = {
      id: stateProductTheme?.id,
      code_name: stateProductTheme?.code_name,
      title: stateProductTheme?.title,
      description: stateProductTheme?.description,
      selling_point: stateProductTheme?.selling_point,
      default_product: stateProductTheme?.default_product ? { id: stateProductTheme?.default_product.id } : undefined,
      products: stateProductTheme?.products?.map((p) => {
        return { id: p?.id }
      }),
      catchphrase: stateProductTheme?.catchphrase,
      position: stateProductTheme?.position ? parseInt(`${stateProductTheme?.position}`) : undefined,
    }
    if (stateProductTheme?.id) {
      await updateProductTheme({ variables: { input: productThemeInput } })
    } else {
      await createProductTheme({ variables: { input: productThemeInput } })
    }
  }

  return (
    <FormLayout
      type={stateProductTheme?.id ? 'update' : 'create'}
      title={stateProductTheme?.id ? 'Mettre à jour un theme' : 'Créer un theme'}
      onSubmit={handleSubmit}
      submitDisabled={stateProductTheme?.id ? stateProductTheme === productTheme : stateProductTheme === undefined}
    >
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="code_name"
            label="Nom de code"
            variant="outlined"
            defaultValue={stateProductTheme?.code_name}
            onChange={(event) => {
              setStateProductTheme((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="title"
            label="Titre"
            variant="outlined"
            defaultValue={stateProductTheme?.title}
            onChange={(event) => {
              setStateProductTheme((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
          />
        </Grid>
        {stateProductTheme?.id && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="position"
              label="Position"
              variant="outlined"
              defaultValue={stateProductTheme?.position}
              onChange={(event) => {
                setStateProductTheme((prevState) => {
                  return {
                    ...prevState,
                    [event.target.id]: event.target.value,
                  }
                })
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {products && (
            <Autocomplete
              fullWidth
              defaultValue={stateProductTheme?.default_product}
              options={products}
              getOptionSelected={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.code_name || ''}
              onChange={(_, value) => {
                if (value) {
                  setStateProductTheme({
                    ...stateProductTheme,
                    default_product: { id: value.id },
                  })
                }
              }}
              renderInput={(params) => {
                return <TextField required {...params} variant="outlined" label="Produit par défaut" />
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="description"
            label="Description"
            multiline
            minRows={5}
            maxRows={10}
            variant="outlined"
            defaultValue={stateProductTheme?.description}
            onChange={(event) => {
              setStateProductTheme((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="selling_point"
            label="Arguments de vente"
            multiline
            minRows={5}
            maxRows={5}
            variant="outlined"
            defaultValue={stateProductTheme?.selling_point}
            onChange={(event) => {
              setStateProductTheme((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="catchphrase"
            label="Phrase d'accroche"
            multiline
            minRows={2}
            maxRows={2}
            inputProps={{ maxLength: 150 }}
            variant="outlined"
            defaultValue={stateProductTheme?.catchphrase}
            onChange={(event) => {
              setStateProductTheme((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {products && (
            <Autocomplete
              fullWidth
              onInputChange={(_, value, reason) => reason !== 'reset' && setValue(value)}
              inputValue={value}
              disableCloseOnSelect={true}
              multiple={true}
              defaultValue={stateProductTheme?.products}
              options={products}
              getOptionSelected={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.code_name || ''}
              onChange={(_, value) => {
                if (value) {
                  setStateProductTheme({
                    ...stateProductTheme,
                    products: value.map((p) => {
                      return { id: p?.id }
                    }),
                  })
                }
              }}
              renderInput={(params) => {
                return <TextField {...params} variant="outlined" label="Produits" />
              }}
            />
          )}
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default ProductThemeForm
