import React, { useState } from 'react'
// MATERIAL UI
import Rating from '@material-ui/lab/Rating'
import { Grid, TextField, Typography } from '@material-ui/core'
//ICONS
// COMPONENTS
import { FormLayout } from '../index'
// TYPE
import { DeepPartial } from '../../../utils/types'
import { ProductType, ProductReviewType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_REVIEW_MUTATION, CREATE_PRODUCT_REVIEW_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type ProductReviewPropsType = {
  product?: ProductType
  product_review?: ProductReviewType
  refetch: () => Promise<any>
  handleClose: () => void
}

const ReviewForm = ({ product, product_review, refetch, handleClose }: ProductReviewPropsType) => {
  const [setAlert] = useAlert()
  const [stateReview, setStateReview] = useState<DeepPartial<ProductReviewType> | undefined>(product_review)
  const [updateProductReview] = useMutation<{ updateProductReview: ProductReviewType }>(
    UPDATE_PRODUCT_REVIEW_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Avis mis-à-jour` })
        await refetch()
        handleClose()
      },
    },
  )

  const [createProductReview] = useMutation<{ createProductReview: ProductReviewType }>(
    CREATE_PRODUCT_REVIEW_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Avis ajouté` })
        await refetch()
        handleClose()
      },
    },
  )

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput: Record<string, any> = {
      id: stateReview?.id,
      first_name: stateReview?.first_name,
      last_name: stateReview?.last_name,
      feedback: stateReview?.feedback,
      rating: stateReview?.rating,
      written_at: stateReview?.written_at,
    }
    if (stateReview?.id) {
      await updateProductReview({ variables: { input: productInput } })
    } else {
      productInput.product = { id: product?.id }
      await createProductReview({ variables: { input: productInput } })
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStateReview((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      }
    })
  }

  return (
    <FormLayout
      type={stateReview?.id ? 'update' : 'create'}
      title={stateReview?.id ? "Mettre à jour l'avis client" : 'Ajouter un avis client'}
      onSubmit={handleSubmit}
      submitDisabled={
        product_review
          ? stateReview === product_review
          : !(
              stateReview &&
              stateReview.first_name &&
              stateReview?.last_name &&
              stateReview?.feedback &&
              stateReview?.rating &&
              stateReview?.written_at
            )
      }
    >
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <TextField
            label="Prénom"
            name="first_name"
            defaultValue={stateReview?.first_name}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Nom"
            name="last_name"
            defaultValue={stateReview?.last_name}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          multiline
          minRows={5}
          maxRows={5}
          label="Avis"
          name="feedback"
          defaultValue={stateReview?.feedback}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          required
        />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <Typography component="legend">Note</Typography>
          <Rating
            name="rating"
            value={stateReview?.rating}
            onChange={(_, value) => {
              setStateReview((prevState) => {
                if (!value) return prevState
                return {
                  ...prevState,
                  rating: parseInt(value?.toString()),
                }
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            name="written_at"
            label="Horaire de l'avis"
            type="datetime-local"
            defaultValue={product_review?.written_at ? product_review?.written_at.toString().split('.')[0] : undefined}
            InputLabelProps={{
              shrink: true,
            }}
            required
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default ReviewForm
