//REACT
import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, Typography, IconButton, useTheme, Divider, Button } from '@material-ui/core'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'
//ICONS
import { MdOutlineDelete, MdOutlineAddCircleOutline } from 'react-icons/md'
// TYPE
import { DeepPartial } from '../../../utils/types'
import { LessonQuestionType, LessonVideoType } from '../../../../../lib/sharedTypes'
import { RankingItem } from '../../../../../server/src/entities'
//COMPONENTS
import QuestionVideoForm from './QuestionVideoForm'
import { CustomModal } from '../../'

type RankingQuestionFormInputType = {
  stateQuestion: DeepPartial<LessonQuestionType> | undefined
  setStateQuestion: React.Dispatch<React.SetStateAction<DeepPartial<LessonQuestionType> | undefined>>
}

const RankingQuestionForm = ({ stateQuestion, setStateQuestion }: RankingQuestionFormInputType) => {
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [selectLessonVideo, setSelectLessonVideo] = useState(false)

  return (
    <Grid
      item
      xs={12}
      style={{
        border: `1px solid ${theme.palette.action.disabled}`,
        borderRadius: 5,
        margin: theme.spacing(1),
        padding: theme.spacing(2),
      }}
    >
      <Grid item xs={12}>
        <TextField
          required
          multiline
          maxRows={3}
          minRows={3}
          label="Intitulé de la question"
          name="ranking_question.statement"
          defaultValue={stateQuestion?.ranking_question?.statement}
          onChange={(e) => {
            e.preventDefault()
            setStateQuestion((prevState) => {
              return {
                ...prevState,
                ranking_question: {
                  ...prevState?.ranking_question,
                  statement: e.target.value,
                },
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
          }}
        >
          <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
            ou
          </Typography>
        </Grid>
        {stateQuestion?.question_video ? (
          <Grid container direction="row" style={{ position: 'relative' }}>
            <Grid item xs={12}>
              <video
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                src={`${stateQuestion.question_video?.walter_media_url}?${new Date().getTime()}`}
                width="100%"
                height="300px"
                id="video"
                controls
              />
            </Grid>

            <IconButton
              color="primary"
              style={{ position: 'absolute', top: 1, right: 0 }}
              onClick={() => {
                setStateQuestion((prevState) => {
                  return {
                    ...prevState,
                    question_video: undefined,
                  }
                })
              }}
            >
              <MdOutlineDelete size={24} />
            </IconButton>
          </Grid>
        ) : (
          <Grid item xs={12} container justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              component="label"
              onClick={() => {
                setSelectLessonVideo(true)
              }}
            >
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Selectionner une leçon vidéo
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h6">Propositions</Typography>
        </Grid>
        <IconButton
          onClick={() => {
            const ranking_items = stateQuestion?.ranking_question?.ranking_items || []
            ranking_items.push({
              id: new Date().getTime(), //ensures unicity
              displayed_position: ranking_items.length + 1,
              correct_position: ranking_items.length + 1,
            } as DeepPartial<RankingItem>)

            setStateQuestion((prevState) => {
              return {
                ...prevState,
                ranking_question: {
                  ...prevState?.ranking_question,
                  ranking_items: ranking_items,
                },
              }
            })
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Grid>
      <Grid item container direction="column" spacing={2} alignItems="stretch" ref={animateRef}>
        {stateQuestion?.ranking_question?.ranking_items
          ?.sort((a, b) => (a?.correct_position ?? 0) - (b?.correct_position ?? 0))
          .map((ranking_item, index) => {
            return (
              <Grid key={index} item container direction="column" alignItems="stretch">
                <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography>{`Proposition ${index + 1}`}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setStateQuestion((prevState) => {
                          return {
                            ...prevState,
                            ranking_question: {
                              ...prevState?.ranking_question,
                              ranking_items: prevState?.ranking_question?.ranking_items?.filter((r) => {
                                return r?.id !== ranking_item?.id
                              }),
                            },
                          }
                        })
                      }}
                    >
                      <MdOutlineDelete size={24} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: theme.spacing(2) }}>
                  <Divider />
                </Grid>
                <Grid item container direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                  <Grid item xs={9}>
                    <TextField
                      required
                      label={`Proposition`}
                      name="ranking_question.statement"
                      value={ranking_item?.statement || ''}
                      onChange={(event) => {
                        const ranking_items = stateQuestion.ranking_question?.ranking_items?.map((item, i) => {
                          if (i === index) {
                            return {
                              ...item,
                              statement: event.target.value,
                            }
                          } else {
                            return item
                          }
                        })
                        setStateQuestion((prevState) => {
                          return {
                            ...prevState,
                            ranking_question: {
                              ...prevState?.ranking_question,
                              ranking_items: ranking_items,
                            },
                          }
                        })
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="ranking_item.displayed_position"
                      label="Position affichée"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: stateQuestion?.ranking_question?.ranking_items?.length || 1,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={ranking_item?.displayed_position || index + 1}
                      onChange={(event) => {
                        console.log('event.target.value', event.target.value)
                        const ranking_items = stateQuestion.ranking_question?.ranking_items?.map((item, i) => {
                          if (i === index) {
                            console.log('ITEM', item)
                            return {
                              ...item,
                              displayed_position: parseInt(event.target.value),
                            }
                          } else {
                            return item
                          }
                        })
                        setStateQuestion((prevState) => {
                          return {
                            ...prevState,
                            ranking_question: {
                              ...prevState?.ranking_question,
                              ranking_items: ranking_items,
                            },
                          }
                        })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
      </Grid>
      <CustomModal
        size="xs"
        title="Sélectionner une leçon vidéo"
        open={selectLessonVideo}
        onClose={() => {
          setSelectLessonVideo(false)
        }}
      >
        <QuestionVideoForm
          defaultValue={stateQuestion?.question_video}
          onSelectLesson={(selectedLessonVideo: LessonVideoType) => {
            setStateQuestion((prevState) => {
              return {
                ...prevState,
                question_video: selectedLessonVideo,
              }
            })
            setSelectLessonVideo(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}
export default RankingQuestionForm
