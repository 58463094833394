//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid, MenuItem } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from './index'
//TYPES
import { CatalogType } from '../../../../lib/sharedTypes'
import { DeepPartial } from '../../utils/types'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { CATALOGS_QUERY } from '../../gql/queries'
import { CREATE_CATALOG_MUTATION } from '../../gql/mutations'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'

type CatalogFormPropsType = {
  catalog?: CatalogType
  refetch: () => Promise<any>
  handleClose: () => void
}

const CatalogForm = ({ catalog, refetch, handleClose }: CatalogFormPropsType) => {
  const [newCatalog, setNewCatalog] = useState<DeepPartial<CatalogType> | undefined>(catalog)
  const [setAlert] = useAlert()
  const { data: { catalogs: catalogs = [] } = {} } = useQuery<{ catalogs: CatalogType[] }>(CATALOGS_QUERY)

  const [createCatalog] = useMutation<{
    createCatalog: CatalogType
  }>(CREATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createCatalog.name} créé` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const newCatalogInput = {
      ...newCatalog,
      parent: newCatalog?.parent ? { id: newCatalog?.parent?.id } : null,
      position: newCatalog?.position ? parseInt(newCatalog?.position.toString()) : 0,
    }
    if (newCatalog?.id) {
      // await updateCatalog({ variables: { input: newCatalogInput } })
    } else {
      await createCatalog({ variables: { input: newCatalogInput } })
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCatalog((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  return (
    <FormLayout
      type="create"
      title="Créer un catalogue"
      onSubmit={handleSubmit}
      submitDisabled={newCatalog === undefined}
    >
      <Grid item>
        <TextField
          fullWidth
          name="name"
          label="Nom"
          variant="outlined"
          value={newCatalog?.name || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="title"
          label="Titre"
          variant="outlined"
          value={newCatalog?.title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          multiline
          minRows={5}
          maxRows={5}
          name="content"
          label="Contenu"
          variant="outlined"
          value={newCatalog?.content || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="breadcrumb"
          label="Breadcrumb"
          variant="outlined"
          value={newCatalog?.breadcrumb || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          type="number"
          inputProps={{ min: 0 }}
          name="position"
          label="Position"
          variant="outlined"
          value={newCatalog?.position || 0}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="seo_url"
          label="URL SEO"
          variant="outlined"
          value={newCatalog?.seo_url || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="reviews_subtitle"
          label="Titre avis clients"
          variant="outlined"
          value={newCatalog?.reviews_subtitle || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="courses_list_title"
          label="Titre liste des formations"
          variant="outlined"
          value={newCatalog?.courses_list_title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="courses_list_subtitle"
          label="Sous-titre liste des formations"
          variant="outlined"
          value={newCatalog?.courses_list_subtitle || ''}
          onChange={handleChange}
          required
        />
      </Grid>

      <Grid item>
        <TextField
          fullWidth
          name="online_course_title"
          label="Titre formation en ligne"
          variant="outlined"
          value={newCatalog?.online_course_title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="funding_subtitle"
          label="Sous-titre financement"
          variant="outlined"
          value={newCatalog?.funding_subtitle || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="qa_title"
          label="Titre FAQ"
          variant="outlined"
          value={newCatalog?.qa_title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          select
          fullWidth
          id="parent"
          label="Catalogue parent"
          variant="outlined"
          defaultValue={newCatalog?.parent?.name}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
            },
          }}
          onChange={(event) => {
            const parentCatalog = catalogs.find((catalog) => catalog.id.toString() === event.target.value.toString())
            setNewCatalog({ ...newCatalog, parent: parentCatalog })
          }}
        >
          {catalogs.map((catalog) => (
            <MenuItem id="job" key={catalog.id} value={catalog.id}>
              {catalog.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </FormLayout>
  )
}

export default CatalogForm
