//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid } from '@material-ui/core'
//COMPONENTS
import { Error } from '../../index'
import { FormLayout } from '../index'
//TYPES
import { LessonVideoType, ProjectType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { PROJECT_ID_QUERY } from '../../../gql/queries'
import { CREATE_LESSON_VIDEO_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { useParams } from 'react-router'

type VideoFormPropsType = {
  video_set_id: string
  video?: LessonVideoType
  refetch: () => void
  handleClose: () => void
}

const VideoForm = ({ video, video_set_id, refetch, handleClose }: VideoFormPropsType) => {
  const { code_name } = useParams()
  const [newVideo, setNewVideo] = useState<DeepPartial<LessonVideoType> | undefined>(video)
  const [setAlert] = useAlert()
  const [createVideoMutation] = useMutation<{
    createLessonVideo: LessonVideoType
  }>(CREATE_LESSON_VIDEO_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createLessonVideo.lesson.name} créé` })
      refetch()
      handleClose()
    },
  })

  const { error, data: { project } = {} } = useQuery<{ project: ProjectType }>(PROJECT_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const videoInput = {
      id: newVideo?.id,
      lesson: {
        name: newVideo?.lesson?.name,
        note: newVideo?.lesson?.note,
      },
      project: { id: project?.id },
      video_set: { id: video_set_id },
    }
    if (newVideo?.id) {
      // await updateVideoMutation({ variables: { input: videoInput } })
    } else {
      await createVideoMutation({ variables: { input: videoInput } })
    }
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <FormLayout
      type={newVideo?.id ? 'update' : 'create'}
      title={newVideo?.id ? 'Mettre à jour une video' : 'Créer une video'}
      onSubmit={handleSubmit}
      submitDisabled={newVideo?.id ? video === newVideo : newVideo === undefined}
    >
      <Grid item>
        <TextField
          fullWidth
          id="name"
          label="Nom"
          variant="outlined"
          defaultValue={newVideo?.lesson?.name}
          onChange={(event) => {
            setNewVideo((prevState) => {
              return {
                ...prevState,
                ...{
                  lesson: {
                    ...prevState?.lesson,
                    [event.target.id]: event.target.value,
                  },
                },
              }
            })
          }}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id="note"
          label="Note"
          variant="outlined"
          defaultValue={newVideo?.lesson?.note}
          onChange={(event) => {
            setNewVideo((prevState) => {
              return {
                ...prevState,
                ...{
                  lesson: {
                    ...prevState?.lesson,
                    [event.target.id]: event.target.value,
                  },
                },
              }
            })
          }}
          multiline
          minRows={3}
          maxRows={3}
        />
      </Grid>
    </FormLayout>
  )
}

export default VideoForm
