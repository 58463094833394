import { useState } from 'react'
//MATERIAL UI
import { InputBase, Grid } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
//ICONS
import { MdSearch } from 'react-icons/md'

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    flex: 1,
  },
  searchInputContainer: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `solid 1px ${theme.palette.text.disabled}`,
    borderBottom: `solid 1px ${theme.palette.text.disabled}`,
  },
  searchInputContainerFocused: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `solid 1px ${theme.palette.primary.main}`,
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  input: {
    padding: '0px',
    height: '70px',
  },
}))

type SearchBarProps = {
  placeholder: string
  searchQuery: (arg: string) => void
  endAdornment?: React.ReactNode
}

const SearchBar = ({ placeholder, searchQuery, endAdornment }: SearchBarProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const [focused, setFocus] = useState<boolean>(false)

  return (
    <Grid container direction="column" className={classes.searchContainer}>
      <Grid className={focused ? classes.searchInputContainerFocused : classes.searchInputContainer}>
        <MdSearch size="24px" style={{ marginRight: theme.spacing(1) }} />
        <InputBase
          fullWidth
          placeholder={placeholder}
          onFocus={() => {
            setFocus(true)
          }}
          onBlur={() => {
            setFocus(false)
          }}
          classes={{ input: classes.input }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => {
            searchQuery(e.target.value)
          }}
          endAdornment={endAdornment}
        />
      </Grid>
    </Grid>
  )
}

export default SearchBar
