//REACT
import React, { useState, useEffect } from 'react'
// MATERIAL UI
import { TextField, Grid, Button, Typography, MenuItem } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
//TYPES
import { LessonVideoType, LessonVideoDiagramType, VideoDiagramStateEnum } from '../../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../../utils/types'
//GQL
import { useMutation } from '@apollo/client'
import { CREATE_LESSON_VIDEO_DIAGRAM_MUTATION, UPDATE_LESSON_VIDEO_DIAGRAM_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//UTILS
import { handleError } from '../../../../utils/handleError'

type DiagramFormPropsType = {
  diagram?: LessonVideoDiagramType
  video?: LessonVideoType
  refetch: () => Promise<any>
  handleClose: () => void
}

const DiagramForm = ({ diagram, video, refetch, handleClose }: DiagramFormPropsType) => {
  const [stateDiagram, setStateDiagram] = useState<DeepPartial<LessonVideoDiagramType> | undefined>(diagram)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [localDiagram, setLocalDiagram] = useState<string>()
  const [setAlert] = useAlert()
  const diagramURL = localDiagram ? localDiagram : stateDiagram?.s3_file

  const [createLessonVideoDiagramMutation] = useMutation<{
    createLessonVideoDiagram: LessonVideoDiagramType
  }>(CREATE_LESSON_VIDEO_DIAGRAM_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createLessonVideoDiagram.name} créé` })
      await refetch()
      handleClose()
    },
  })

  const [updateLessonVideoDiagramMutation] = useMutation<{
    updateLessonVideoDiagram: LessonVideoDiagramType
  }>(UPDATE_LESSON_VIDEO_DIAGRAM_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateLessonVideoDiagram.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStateDiagram((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.id]: event.target.value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const diagramInput: Record<string, any> = {
      name: stateDiagram?.name,
      comment: stateDiagram?.comment,
      time_code: stateDiagram?.time_code ? parseFloat(stateDiagram?.time_code.toString()) : parseFloat('0'),
      lesson_video: { id: video?.id },
      state: stateDiagram?.state,
    }

    if (selectedFile) {
      diagramInput.s3_file = selectedFile
    }

    if (diagram?.id) {
      diagramInput.id = diagram.id
      await updateLessonVideoDiagramMutation({ variables: { input: diagramInput } })
    } else {
      await createLessonVideoDiagramMutation({ variables: { input: diagramInput } })
    }
  }

  useEffect(() => {
    if (!selectedFile) {
      setLocalDiagram(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setLocalDiagram(objectUrl)
    setStateDiagram((prevState) => {
      return {
        ...prevState,
        ...{ s3_file: objectUrl },
      }
    })
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  return (
    <FormLayout
      type={stateDiagram?.id ? 'update' : 'create'}
      title={stateDiagram?.id ? 'Mettre à jour le schéma' : 'Demander un schéma'}
      onSubmit={handleSubmit}
      submitDisabled={stateDiagram?.id ? stateDiagram === diagram : stateDiagram === undefined}
    >
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            required
            label="Nom"
            id="name"
            defaultValue={stateDiagram?.name}
            onChange={(event) => {
              handleChange(event)
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            minRows={3}
            maxRows={3}
            label="Commentaire"
            id="comment"
            defaultValue={stateDiagram?.comment}
            onChange={(event) => {
              handleChange(event)
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            id=""
            label="Statut schéma"
            variant="outlined"
            defaultValue={stateDiagram?.state}
            value={stateDiagram?.state || ''}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              },
            }}
            onChange={(event) => {
              setStateDiagram((prevState) => {
                return {
                  ...prevState,
                  ...{ state: event.target.value as VideoDiagramStateEnum },
                }
              })
            }}
            required
          >
            {Object.keys(VideoDiagramStateEnum).map((status) => (
              <MenuItem id="status" key={status} value={status}>
                {status === 'REQUESTED' ? 'Demandé' : status}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {diagram?.id && (
          <Grid item xs={12} container spacing={1}>
            <Grid item>
              <Button variant="outlined" color="primary" component="label">
                Selectionner
                <input hidden name="diagram" accept=".jpg,.png,.svg,.jpeg" type="file" onChange={onSelectFile} />
              </Button>
            </Grid>
            <Grid container justifyContent="center">
              {diagramURL ? (
                <img
                  src={diagramURL}
                  alt={diagramURL.toString()}
                  loading="lazy"
                  style={{
                    width: '100%',
                    maxHeight: '250px',
                    objectFit: 'contain',
                  }}
                />
              ) : (
                <Typography variant="h6" style={{ fontStyle: 'italic' }}>
                  Selectionner un schéma
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </FormLayout>
  )
}

export default DiagramForm
