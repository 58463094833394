//REACT
import { useState } from 'react'
//COMPONENT
import { FormLayout } from '../index'
//MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Grid } from '@material-ui/core'
//TYPES
import { CourseType, LessonType } from '../../../../../lib/sharedTypes'
// GQL
import { COURSE_RESOURCES_QUERY } from '../../../gql/queries'
import { UPDATE_LESSON_MUTATION } from '../../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
//UTILS
import { handleError } from '../../../utils/handleError'
import { useAlert } from '../../../hooks'

type LinkResourceFormPropsType = {
  course: CourseType
  lesson: LessonType
  handleClose: () => void
  refetch: () => Promise<void>
}

const LinkResourceForm = ({ course, lesson, handleClose, refetch }: LinkResourceFormPropsType) => {
  const [stateLesson, setStateLesson] = useState(lesson)
  const [setAlert] = useAlert()

  const [updateLessonMutation] = useMutation<{ updateLesson: LessonType }>(UPDATE_LESSON_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateLesson.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonInput = {
      id: stateLesson.id,
      resources: stateLesson.resources.map((r) => {
        return { id: r.id }
      }),
    }
    await updateLessonMutation({ variables: { input: lessonInput } })
  }

  const { data: { course: courseResource } = {} } = useQuery<{ course: CourseType }>(COURSE_RESOURCES_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(`${course.id}`),
        },
      },
    },
  })

  return (
    <FormLayout
      type={'update'}
      title={'Lier des ressources'}
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(lesson.resources) === JSON.stringify(stateLesson.resources)}
    >
      <Grid item>
        <Autocomplete
          multiple
          defaultValue={stateLesson.resources}
          options={courseResource?.resources || []}
          getOptionSelected={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option?.name}
          onChange={(_, value) => {
            if (value) {
              setStateLesson({
                ...stateLesson,
                resources: value,
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Ressource(s)" />
          }}
        />
      </Grid>
    </FormLayout>
  )
}

export default LinkResourceForm
