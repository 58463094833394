//MATERIAL UI
import { Grid, Tabs, Tab, Divider, useTheme } from '@material-ui/core'

type TabsMenuProps = {
  tabs: string[]
  allowedTabs: string[]
  activeTab: string
  setActiveTab: (arg: string) => void
  indicatorColor?: 'primary' | 'secondary' | undefined
  scrollButtons?: 'off' | 'auto' | 'on' | 'desktop' | undefined
  variant?: 'scrollable' | 'standard' | 'fullWidth' | undefined
}

const TabsMenu = ({
  tabs,
  allowedTabs,
  activeTab,
  setActiveTab,
  indicatorColor = 'primary',
  scrollButtons = 'on',
  variant = 'scrollable',
}: TabsMenuProps) => {
  const theme = useTheme()
  return (
    <Grid style={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(1), minWidth: '100%' }}>
      <Divider />
      <Tabs
        value={tabs.indexOf(activeTab)}
        indicatorColor={indicatorColor}
        scrollButtons={scrollButtons}
        variant={variant}
      >
        {tabs.map((tab, index) => {
          return (
            <Tab
              disabled={!allowedTabs.includes(tab)}
              key={index}
              label={tab}
              onClick={() => {
                setActiveTab(tab)
              }}
            />
          )
        })}
      </Tabs>
    </Grid>
  )
}

export default TabsMenu
