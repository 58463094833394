//REACT
import { useEffect, useState } from 'react'
// MATERIAL UI
import { Grid, FormControl, InputLabel, Select, Button, MenuItem, useTheme, makeStyles, alpha } from '@material-ui/core'
//REACT-ROUTER
import { useNavigate } from 'react-router-dom'
//COMPONENTS
import DuplicateQuiz from '../../components/Forms/lesson/quiz/DuplicateQuizForm'
import { CustomModal, Error, Loading } from '../../components'
import { DeleteForm } from '../../components/Forms'
import {
  LessonPDFForm,
  LessonTextForm,
  LessonTaskForm,
  QuizForm,
  EppForm,
  LessonSynthesisForm,
  LessonHtmlForm,
} from '../../components/Forms/lesson'
import { useParams } from 'react-router'
import { useLocation } from 'react-router'
//ICONS
import { RiFileExcel2Line } from 'react-icons/ri'
//TYPES
import { CourseType } from '../../../../lib/sharedTypes'
//GQL
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { COURSE_ID_QUERY, DOWNLOAD_LESSON_HTML_EXCEL } from '../../gql/queries'
import {
  VALIDATE_COURSE_LESSONS_MUTATIONS,
  GENERATE_COURSE_SYNTHESIS_MUTATIONS,
  VALIDATE_COURSE_LESSONS_SUMMARIES_MUTATIONS,
  DELETE_LESSONS_MUTATION,
  REGISTER_DELAYED_JOB,
} from '../../gql/mutations'
import { useAlert } from '../../hooks'
//UTILS
import { isAuthorized, isCourseManager } from '../../utils/misc'
import { handleError } from '../../utils/handleError'
import LessonDactyloForm from '../Forms/lesson/dactylo/LessonDactyloForm'

const lessonsTabs = [
  'Toutes',
  'Leçons vidéos',
  'Leçons textuelles',
  'Leçons EPP',
  'Leçons documents',
  'Leçons quiz',
  'Leçons synthèses',
  'Leçons HTML',
  'Leçons tâches',
  'Leçons dactylo',
]

const useStyles = makeStyles((theme) => ({
  excelBTN: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover': {
      background: alpha(theme.palette.success.main, 0.1),
    },
  },
}))

type HeaderPropsType = {
  refetch: () => Promise<any>
  selectedLessons?: number[]
}

const Header = ({ refetch, selectedLessons }: HeaderPropsType) => {
  const theme = useTheme()
  const classes = useStyles()
  const [setAlert] = useAlert()
  const location = useLocation()
  const { code_name } = useParams()
  const navigate = useNavigate()
  const [duplicateQuiz, setDuplicateQuiz] = useState(false)
  const [selectedLessonType, setSelectedLessonType] = useState(lessonsTabs[0])
  const [createModal, setCreateModal] = useState('')
  const [deleteMultipleModal, setDeleteMultipleModal] = useState(false)
  const { data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })

  const [deleteLessonsMutation] = useMutation<{
    deleteLessonsText: boolean
  }>(DELETE_LESSONS_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetch()
      setAlert({ severity: 'success', content: `Leçon(s) supprimée(s)` })
    },
  })

  const [validateCourseLessons] = useMutation<{
    validateCourseLessons: CourseType
  }>(VALIDATE_COURSE_LESSONS_MUTATIONS, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `Leçons de ${data.validateCourseLessons.code_name} validées` })
      await refetch()
    },
  })

  const [validateCourseLessonsSummaries] = useMutation<{
    validateCourseLessonsSummaries: CourseType
  }>(VALIDATE_COURSE_LESSONS_SUMMARIES_MUTATIONS, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `Résumés de ${data.validateCourseLessonsSummaries.code_name} validés` })
      await refetch()
    },
  })

  const [generateCourseSynthesis] = useMutation<{
    generateCourseSynthesis: CourseType
  }>(GENERATE_COURSE_SYNTHESIS_MUTATIONS, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({
        severity: 'success',
        content: `Synthèse de ${data.generateCourseSynthesis.code_name} en cours de génération`,
      })
      await refetch()
    },
  })

  const [downloadLessonHtmlExcel] = useLazyQuery<{
    downloadLessonHtmlExcel: string
  }>(DOWNLOAD_LESSON_HTML_EXCEL, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      window.open(data.downloadLessonHtmlExcel, '_blank')
    },
  })

  useEffect(() => {
    const tab = location.pathname.split('/').at(-1)
    switch (tab) {
      case 'lessons':
        setSelectedLessonType('Toutes')
        break
      case 'video':
        setSelectedLessonType('Leçons vidéos')
        break
      case 'text':
        setSelectedLessonType('Leçons textuelles')
        break
      case 'epp':
        setSelectedLessonType('Leçons EPP')
        break
      case 'document':
        setSelectedLessonType('Leçons documents')
        break
      case 'quiz':
        setSelectedLessonType('Leçons quiz')
        break
      case 'synthese':
        setSelectedLessonType('Leçons synthèses')
        break
      case 'html':
        setSelectedLessonType('Leçons HTML')
        break
      case 'task':
        setSelectedLessonType('Leçons tâches')
        break
      case 'dactylo':
        setSelectedLessonType('Leçons dactylo')
        break
      default:
        console.error(`Unknown tab`)
        break
    }
  }, [])

  const [registerDelayedJob] = useMutation<{
    registerDelayedJob: boolean
  }>(REGISTER_DELAYED_JOB, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: 'Les résumés manquants seront générés cette nuit.' })
    },
  })

  const handleRegisterDelayedJob = async () => {
    const date = new Date()
    date.setHours(21, 0, 0)
    await registerDelayedJob({
      variables: {
        input: { category: 'GENERATE_SUMMARIES', data: { course: { id: course?.id } }, execution_datetime: date },
      },
    })
  }

  if (!code_name) {
    return <Error error="code_name inconnu" />
  }

  if (!course) {
    return <Loading />
  }

  const handleLessonChange = (tab: string) => {
    switch (tab) {
      case 'Toutes':
      case 'lessons':
        navigate(`/formations/${code_name}/lessons`)
        break
      case 'Leçons vidéos':
        navigate(`/formations/${code_name}/lessons/video`)
        break
      case 'Leçons textuelles':
        navigate(`/formations/${code_name}/lessons/text`)
        break
      case 'Leçons EPP':
        navigate(`/formations/${code_name}/lessons/epp`)
        break
      case 'Leçons documents':
        navigate(`/formations/${code_name}/lessons/document`)
        break
      case 'Leçons quiz':
        navigate(`/formations/${code_name}/lessons/quiz`)
        break
      case 'Leçons synthèses':
        navigate(`/formations/${code_name}/lessons/synthese`)
        break
      case 'Leçons HTML':
        navigate(`/formations/${code_name}/lessons/html`)
        break
      case 'Leçons tâches':
        navigate(`/formations/${code_name}/lessons/task`)
        break
      case 'Leçons dactylo':
        navigate(`/formations/${code_name}/lessons/dactylo`)
        break
      default:
        console.error(`Unknown tab ${selectedLessonType}`)
        break
    }
    setSelectedLessonType(tab)
  }

  const handleCreateModal = () => {
    switch (selectedLessonType) {
      case 'Leçons textuelles':
        setCreateModal('text')
        break
      case 'Leçons EPP':
        setCreateModal('epp')
        break
      case 'Leçons documents':
        setCreateModal('pdf')
        break
      case 'Leçons quiz':
        setCreateModal('quiz')
        break
      case 'Leçons synthèses':
        setCreateModal('synthese')
        break
      case 'Leçons HTML':
        setCreateModal('html')
        break
      case 'Leçons tâches':
        setCreateModal('task')
        break
      case 'Leçons dactylo':
        setCreateModal('dactylo')
        break
      default:
        console.error(`Unknown tab ${selectedLessonType}`)
        break
    }
  }

  return (
    <Grid item>
      <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <FormControl variant="outlined" style={{ minWidth: 170 }}>
            <InputLabel>Type de leçon</InputLabel>
            <Select
              fullWidth
              value={selectedLessonType}
              onChange={(event) => {
                handleLessonChange(event.target.value as string)
              }}
              label="Type de leçon"
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                PaperProps: {
                  style: {
                    maxHeight: 56 * 5.5 + 10,
                  },
                },
              }}
            >
              {lessonsTabs.map((lesson) => {
                return (
                  <MenuItem key={lesson} value={lesson}>
                    {lesson}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {['Toutes'].includes(selectedLessonType) && (
            <Grid>
              <Button
                style={{ marginRight: theme.spacing(1) }}
                color="secondary"
                variant="outlined"
                onClick={async () => {
                  if (course?.id) {
                    await validateCourseLessonsSummaries({ variables: { input: { id: course.id } } })
                  }
                }}
              >
                Valider tous les résumés
              </Button>
              <Button
                style={{ marginRight: theme.spacing(1) }}
                color="primary"
                variant="outlined"
                onClick={async () => {
                  if (course?.id) {
                    await validateCourseLessons({ variables: { input: { id: course.id } } })
                  }
                }}
              >
                Valider toutes les leçons
              </Button>
            </Grid>
          )}
          {selectedLessonType === 'Leçons synthèses' && selectedLessons && selectedLessons?.length !== 0 && (
            <Button
              variant="outlined"
              onClick={() => {
                setDeleteMultipleModal(true)
              }}
              style={{
                margin: theme.spacing(1),
                color: theme.palette.error.main,
                borderColor: theme.palette.error.main,
              }}
              disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
            >
              Supprimer {selectedLessons?.length} leçon{selectedLessons?.length > 1 && 's'}
            </Button>
          )}
          {selectedLessonType === 'Leçons synthèses' && (
            <Button
              color="secondary"
              variant="outlined"
              onClick={async () => {
                if (course?.id) {
                  await generateCourseSynthesis({ variables: { input: { id: course.id } } })
                }
              }}
              style={{ margin: theme.spacing(1) }}
              disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
            >
              Générer toutes les synthèses
            </Button>
          )}
          {selectedLessonType === 'Leçons HTML' && (
            <Button
              startIcon={<RiFileExcel2Line />}
              variant="outlined"
              onClick={async () => {
                if (course?.id) {
                  await downloadLessonHtmlExcel({ variables: { where: { id: { eq: parseFloat(`${course.id}`) } } } })
                }
              }}
              className={classes.excelBTN}
              style={{ margin: theme.spacing(1) }}
              disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
            >
              Télécharger modèle
            </Button>
          )}
          {!['Toutes', 'Leçons vidéos'].includes(selectedLessonType) && (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleCreateModal}
              disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
            >
              Ajouter une{' '}
              {selectedLessonType === 'Leçons synthèses' ? 'synthèse' : selectedLessonType.replace(/s/g, '')}
            </Button>
          )}
          {['Leçons quiz'].includes(selectedLessonType) && (
            <>
              <Button
                variant="outlined"
                color="primary"
                style={{ margin: theme.spacing(1) }}
                onClick={() => {
                  setDuplicateQuiz(true)
                }}
              >
                Dupliquer leçon
              </Button>
              <CustomModal
                size="sm"
                title="Selectionner une leçon"
                open={duplicateQuiz}
                onClose={() => {
                  setDuplicateQuiz(false)
                }}
              >
                <DuplicateQuiz
                  refetch={refetch}
                  course={course}
                  handleClose={() => {
                    setDuplicateQuiz(false)
                  }}
                />
              </CustomModal>
            </>
          )}
          {['Leçons vidéos'].includes(selectedLessonType) && (
            <>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleRegisterDelayedJob}
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
              >
                Générer tous les résumés
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <CustomModal
        open={createModal === 'task'}
        onClose={() => {
          setCreateModal('')
        }}
        animation="slide-up"
      >
        <LessonTaskForm
          refetch={refetch}
          handleClose={() => {
            setCreateModal('')
          }}
        />
      </CustomModal>

      <CustomModal
        open={createModal === 'text'}
        onClose={() => {
          setCreateModal('')
        }}
        animation="slide-up"
      >
        <LessonTextForm
          refetch={refetch}
          handleClose={() => {
            setCreateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={createModal === 'html'}
        onClose={() => {
          setCreateModal('')
        }}
        animation="slide-up"
      >
        <LessonHtmlForm
          refetch={refetch}
          handleClose={() => {
            setCreateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={createModal === 'pdf'}
        onClose={() => {
          setCreateModal('')
        }}
        animation="slide-up"
      >
        <LessonPDFForm
          refetch={refetch}
          handleClose={() => {
            setCreateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={createModal === 'quiz'}
        onClose={() => {
          setCreateModal('')
        }}
        animation="slide-up"
      >
        <QuizForm
          refetch={refetch}
          handleClose={() => {
            setCreateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={createModal === 'epp'}
        onClose={() => {
          setCreateModal('')
        }}
        animation="slide-up"
      >
        <EppForm
          refetch={refetch}
          handleClose={() => {
            setCreateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={createModal === 'synthese'}
        onClose={() => {
          setCreateModal('')
        }}
        animation="slide-up"
      >
        <LessonSynthesisForm
          refetch={refetch}
          handleClose={() => {
            setCreateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={createModal === 'dactylo'}
        onClose={() => {
          setCreateModal('')
        }}
        animation="slide-up"
      >
        <LessonDactyloForm
          refetch={refetch}
          handleClose={() => {
            setCreateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer les leçons"
        open={deleteMultipleModal}
        onClose={() => {
          setDeleteMultipleModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteLessonsMutation({
              variables: {
                input: selectedLessons?.map((id) => {
                  return { id: id }
                }),
              },
            })
            setDeleteMultipleModal(false)
            await refetch()
          }}
          onCancel={() => {
            setDeleteMultipleModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default Header
