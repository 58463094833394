import React from 'react'
// MATERIAL UI
import { Grid, Typography, Paper } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
//ASSETS
import NOIMG_card from '../../assets/NOIMG_card.jpg'
//TYPES
import { ProjectType } from '../../../../lib/sharedTypes'
//UTILS
import { secondsToDHMS } from '../../utils/misc'

const useStyles = makeStyles(() => ({
  image: {
    width: '100px',
    height: '100px',
    borderRadius: '5px',
  },
}))

type ProjectProgressionPropsType = {
  project: ProjectType
}

const ProjectProgression = ({ project }: ProjectProgressionPropsType) => {
  const classes = useStyles()
  const theme = useTheme()
  const totalVideosDuration = project.video_sets.reduce((acc, el) => {
    el.lesson_videos.map((video) => {
      acc += video.file_duration
    })
    return acc
  }, 0)
  const validatedVideosDuration = project.video_sets.reduce((acc, el) => {
    el.lesson_videos.map((video) => {
      if (['VALIDATED'].includes(video.review_state)) {
        acc += video.file_duration
      }
    })
    return acc
  }, 0)
  const publishedVideosDuration = project.video_sets.reduce((acc, el) => {
    el.lesson_videos.map((video) => {
      if (video.vimeo_id) {
        acc += video.file_duration
      }
    })
    return acc
  }, 0)
  return (
    <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
      <Grid container direction="row" justifyContent="space-between" spacing={1}>
        <Grid item>
          <img
            src={project.course.s3_banner}
            alt={project.course.code_name}
            onError={({ currentTarget }) => {
              currentTarget.src = NOIMG_card
            }}
            loading="lazy"
            className={classes.image}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6">Durée des vidéos</Typography>
          <Typography variant="h5">{secondsToDHMS(totalVideosDuration)}</Typography>
          <Typography variant="caption">XhXX à atteindre</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">Durée validée</Typography>
          <Typography variant="h5">{secondsToDHMS(validatedVideosDuration)}</Typography>
          <Typography variant="caption"> Dont {secondsToDHMS(publishedVideosDuration)} en ligne</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">Synchronisation</Typography>
          <Typography variant="h5">XhXX</Typography>
          <Typography variant="caption">Accéder à l'outil</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">Protection des données</Typography>
          <Typography variant="h5">XhXX</Typography>
          <Typography variant="caption">Protéger les données</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default React.memo(ProjectProgression)
