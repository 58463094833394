//REACT
import { useState, useRef, useEffect } from 'react'
//COMPONENTS
import { LessonInfo, LessonRessources, LessonQuestions } from '../../../components/Lesson'
import { DeleteForm } from '../../../components/Forms'
import { LessonVideoForm, ThumbnailForm, LessonVideoDiagramForm } from '../../../components/Forms/lesson'
import { Layout, Title, Error, Loading, CustomTable, CustomModal } from '../../../components'
//ICON
import { MdMoreVert, MdContentCopy } from 'react-icons/md'
// MATERIAL UI
import {
  Grid,
  Chip,
  IconButton,
  Typography,
  Paper,
  useTheme,
  makeStyles,
  Button,
  Divider,
  Link,
  Menu,
  MenuItem,
} from '@material-ui/core'

// REACT-ROUTER
import { useParams, useLocation } from 'react-router-dom'
// TYPES
import { LessonType, LessonVideoDiagramType } from '../../../../../lib/sharedTypes'
import { ColumnType } from '../../../utils/types'
// GRAPHQL
import { useQuery, useMutation } from '@apollo/client'
import { LESSON_VIDEO_QUERY } from '../../../gql/queries'
import { DELETE_LESSON_VIDEO_DIAGRAM_MUTATION } from '../../../gql/mutations'
//UTILS
import { handleError } from '../../../utils/handleError'
import { videosCaptions } from '../../../utils/constants'
import { isAuthorized, isCourseManager, secondsToDHMS } from '../../../utils/misc'
//HOOKS
import { useAlert } from '../../../hooks'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

type LocationState = {
  state: {
    openModal: string
  }
}

const LessonVideo = () => {
  const { code_name, lesson_id } = useParams()
  const videoRef = useRef<HTMLVideoElement>(null)
  const theme = useTheme()
  const classes = useStyles()
  const [lessonModal, setLessonModal] = useState('')
  const [lessonVideoModal, setLessonVideoModal] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<LessonVideoDiagramType | undefined>(undefined)
  const [setAlert] = useAlert()
  const location = useLocation()
  const [deleteLessonVideoDiagramMutation] = useMutation<{
    deleteLessonVideoDiagram: boolean
  }>(DELETE_LESSON_VIDEO_DIAGRAM_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Schéma supprimé` })
    },
  })

  if (!lesson_id) {
    return (
      <Layout title="Leçon vidéo">
        <Error error="lesson_id inconnu" />
      </Layout>
    )
  }

  const {
    loading,
    error,
    data: { lesson } = {},
    refetch,
  } = useQuery<{
    lesson: LessonType
  }>(LESSON_VIDEO_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(lesson_id?.toString()),
        },
      },
    },
  })

  const lessonVideoDiagramsColumns: ColumnType<LessonVideoDiagramType>[] = [
    {
      title: 'Name',
      field: 'name',
      render: (row: LessonVideoDiagramType) => (
        <Link href={row.s3_file} target="_blank">
          {row.name}
        </Link>
      ),
      type: 'String',
      sortable: false,
    },
    {
      title: 'Time code',
      field: 'time_code',
      render: (row: LessonVideoDiagramType) => row.time_code,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Commentaire',
      field: 'comment',
      render: (row: LessonVideoDiagramType) => row.comment,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Statut',
      field: 'state',
      render: (row: LessonVideoDiagramType) => row.state,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Créé par',
      field: 'created_by',
      render: (row: LessonVideoDiagramType) => row?.created_by?.username,
      type: 'String',
      sortable: false,
    },
    {
      title: '',
      field: 'id',
      render: (row: LessonVideoDiagramType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (!code_name) {
    return (
      <Layout title="Leçon vidéo">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  useEffect(() => {
    const { state } = location as LocationState
    if (state && state.openModal) {
      setLessonModal(state.openModal)
    }
  }, [location])

  useEffect(() => {
    if (videoRef.current && lesson) {
      videoRef.current.currentTime = lesson.video.thumbnail_timecode
    }
  }, [videoRef, lesson])

  if (loading || !lesson) {
    return (
      <Layout title="Leçon vidéo">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Leçon vidéo">
        <Error error={error} />
      </Layout>
    )
  }

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Title title={code_name} />
        </Grid>
        <LessonInfo
          openModal={lessonModal === 'lesson_info'}
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        {/* VIDEO */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Video</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setLessonVideoModal('lesson_video_form')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container direction="row">
              <Grid item container direction="column" xs={3}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Statut
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={videosCaptions[lesson.video.review_state].label}
                    style={{ backgroundColor: videosCaptions[lesson.video.review_state].color }}
                    color="primary"
                    size="small"
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={3}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Numéro
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{lesson.video.number}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={3}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Taille
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{lesson.video.file_size}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={3}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Durée
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{secondsToDHMS(lesson.video.file_duration)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid item container direction="column" xs={6}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Chemin
                  </Typography>
                </Grid>
                <Grid item>{lesson.video.file_path}</Grid>
              </Grid>
              <Grid item container direction="column" xs={6}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Iframe
                  </Typography>
                </Grid>
                <Grid item container direction="row" alignItems="center" style={{ width: '100%' }}>
                  <Grid item style={{ width: '90%' }}>
                    <Typography
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {lesson.video.vimeo_iframe}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <IconButton
                      onClick={async () => {
                        await navigator.clipboard.writeText(lesson.video.vimeo_iframe)
                      }}
                    >
                      <MdContentCopy size={20} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container direction="row">
              <Grid item container direction="column" xs={12} md={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Vimeo URL
                  </Typography>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <Typography noWrap variant="body2">
                    {lesson.video.vimeo_url ? (
                      <Link
                        href={lesson.video.vimeo_url}
                        target="_blank"
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {lesson.video.vimeo_url}
                      </Link>
                    ) : (
                      'n/a'
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container direction="column" xs={12} md={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Statut Viméo
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip label={lesson.video.vimeo_status} color="primary" size="small" />
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} md={4}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Vimeo dernière synchronisation
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {lesson.video.vimeo_lastsync
                      ? new Date(lesson.video.vimeo_lastsync).toLocaleString('fr-FR', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                      : 'n/a'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1) }}>
              <video
                src={`${lesson.video.walter_media_url}?${new Date().getTime()}`}
                width="100%"
                id="video"
                controls
              />
            </Grid>
          </Grid>
        </Paper>
        {/* THUMBNAIL */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Miniature</Typography>
              <Button
                color="primary"
                variant="outlined"
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                onClick={() => {
                  setLessonVideoModal('vimeo_thumbnail')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container justifyContent="center">
              <video
                src={lesson.video.walter_media_url}
                height="480"
                width="100%"
                id="video"
                controls={false}
                ref={videoRef}
              />
            </Grid>
          </Grid>
        </Paper>
        {/* RESSOURCES */}
        <LessonQuestions
          course={lesson.course}
          lessonParam={lesson}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        {/* RESSOURCES */}
        <LessonRessources
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        {/* DIAGRAMS */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Schémas</Typography>
              <Button
                color="primary"
                variant="outlined"
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                onClick={() => {
                  setLessonVideoModal('lesson_video_diagram')
                }}
              >
                Ajouter
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item>
              <CustomTable<LessonVideoDiagramType>
                columns={lessonVideoDiagramsColumns}
                rows={lesson.video.video_diagrams}
                field="name"
              />
            </Grid>
          </Grid>
        </Paper>
        {/* TRANSCRIPTION */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Transcription</Typography>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item style={{ overflowY: 'auto', height: '200px' }}>
              <Typography variant="body2">{lesson.transcription ?? 'Transcription indisponible'}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <CustomModal
        open={lessonVideoModal === 'lesson_video_form'}
        onClose={() => {
          setLessonVideoModal('')
        }}
        animation="slide-up"
      >
        <LessonVideoForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setLessonVideoModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={lessonVideoModal === 'vimeo_thumbnail'}
        onClose={() => {
          setLessonVideoModal('')
        }}
        animation="slide-up"
      >
        <ThumbnailForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setLessonVideoModal('')
          }}
        />
      </CustomModal>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setLessonVideoModal('lesson_video_diagram')
            setAnchorEl(null)
          }}
        >
          Modifier le schéma
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer le schéma
        </MenuItem>
      </Menu>
      <CustomModal
        open={lessonVideoModal === 'lesson_video_diagram'}
        onClose={() => {
          setLessonVideoModal('')
          setSelectedRow(undefined)
        }}
        animation="slide-up"
      >
        <LessonVideoDiagramForm
          diagram={selectedRow}
          video={lesson.video}
          refetch={refetch}
          handleClose={() => {
            setLessonVideoModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer le schéma"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteLessonVideoDiagramMutation({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default LessonVideo
