//COMPONENTS
import { CourseCard } from './index'
import { Error, Loading } from '../index'
import { useAppSelector } from '../../hooks/reducerHooks'
// MATERIAL UI
import { Typography, Grid } from '@material-ui/core'
//GQL
import { DASHBOARD_COURSES_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
//TYPES
import { CourseType } from '../../../../lib/sharedTypes'

const Courses = () => {
  const user = useAppSelector((state) => state.user.user)

  const {
    loading,
    error,
    data: { courses } = {},
  } = useQuery<{
    courses: CourseType[]
  }>(DASHBOARD_COURSES_QUERY, {
    variables: {
      where: {
        published_on: {
          eq: null,
        },
        managers: {
          email: {
            in: [user?.email],
          },
        },
      },
      order: {
        sort: 'DESC',
        field: 'created_at',
      },
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid item container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="h5">
          Mes {courses?.length} formation
          {courses?.length !== undefined && courses?.length > 1 && 's'} en cours
        </Typography>
      </Grid>
      <Grid container item spacing={2}>
        {courses?.map((course) => {
          return <CourseCard course={course} key={course.id} />
        })}
      </Grid>
    </Grid>
  )
}

export default Courses
