import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, IconButton, Typography, Divider, useTheme } from '@material-ui/core'
//ICONS
import { MdOutlineDelete, MdOutlineAddCircleOutline } from 'react-icons/md'
// COMPONENTS
import { FormLayout } from '../index'
import { Error, TextEditor } from '../../index'
// TYPE
import { ProductType, ProductSkillType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'
//UTILS
import { handleError } from '../../../utils/handleError'

type SkillFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const SkillForm = ({ product, refetch, handleClose }: SkillFormPropsType) => {
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: stateProduct.id,
      skills_title: stateProduct.skills_title,
      skills_introduction: stateProduct.skills_introduction,
      skills: stateProduct.skills.map((skill, index) => {
        if (typeof skill.id === 'number') {
          return {
            name: skill.name,
            description: skill.description,
            position: index,
          }
        } else {
          return { ...skill, position: index }
        }
      }),
    }
    await updateProductMutation({ variables: { input: productInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les compétences"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProduct) === JSON.stringify(product)}
    >
      <Grid item>
        <TextField
          fullWidth
          name="skills_title"
          label="Titre des compétences"
          variant="outlined"
          value={stateProduct?.skills_title || ''}
          onChange={(e) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                skills_title: e.target.value,
              }
            })
          }}
        />
      </Grid>
      <Grid item>
        <TextEditor
          placeholder="Présentation des compétences"
          text={stateProduct.skills_introduction}
          onChange={(json) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                skills_introduction: json,
              }
            })
          }}
        />
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h6">Compétences</Typography>
        </Grid>
        <IconButton
          onClick={() => {
            const skills = stateProduct.skills
            skills.push({
              id: new Date().getTime(),
              name: '',
              description: '',
            } as ProductSkillType)
            setStateProduct((prevState) => {
              return {
                ...prevState,
                skills: skills,
              }
            })
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Grid>
      <Grid item container direction="column" spacing={2} ref={animateRef}>
        {stateProduct.skills
          .sort((a, b) => a.position - b.position)
          .map((skill, index) => {
            return (
              <Grid key={index} item container direction="column">
                <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography>{`Compétence #${index + 1}`}</Typography>
                  </Grid>

                  <Grid item>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setStateProduct((prevState) => {
                          return {
                            ...prevState,
                            skills: prevState.skills.filter((sk) => {
                              return sk.id !== skill.id
                            }),
                          }
                        })
                      }}
                    >
                      <MdOutlineDelete size={24} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: theme.spacing(2) }}>
                  <Divider />
                </Grid>
                <Grid item container direction="row" spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      label={`Nom`}
                      name="name"
                      value={skill.name}
                      onChange={(e) => {
                        e.preventDefault()
                        const skills = stateProduct.skills.map((skill, i) => {
                          if (i === index) {
                            return {
                              ...skill,
                              name: e.target.value,
                            }
                          } else {
                            return skill
                          }
                        })

                        setStateProduct((prevState) => {
                          return {
                            ...prevState,
                            skills: skills,
                          }
                        })
                      }}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={5}
                      maxRows={5}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Description"
                      name="skills"
                      value={skill.description}
                      onChange={(e) => {
                        e.preventDefault()
                        const skills = stateProduct.skills.map((skill, i) => {
                          if (i === index) {
                            return {
                              ...skill,
                              description: e.target.value,
                            }
                          } else {
                            return skill
                          }
                        })

                        setStateProduct((prevState) => {
                          return {
                            ...prevState,
                            skills: skills,
                          }
                        })
                      }}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={5}
                      maxRows={5}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
      </Grid>
    </FormLayout>
  )
}

export default SkillForm
