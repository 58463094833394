import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, Switch, FormGroup, FormControlLabel, MenuItem } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../index'
import { Error, TextEditor } from '../../index'
//TYPE
import { LessonType, SummaryStateEnum } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_LESSON_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { lessonStatutMapping } from '../../../utils/constants'

type LessonFormPropsType = {
  lesson: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
  hideSummary: boolean
}

const LessonForm = ({ lesson, refetch, handleClose, hideSummary = false }: LessonFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateLesson, setStateLesson] = useState<LessonType>(lesson)

  const [updateLessonMutation] = useMutation<{ updateLesson: LessonType }>(UPDATE_LESSON_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateLesson.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (key: string, value: string | boolean | object) => {
    setStateLesson((prevState) => {
      if (!prevState) return prevState
      return {
        ...prevState,
        ...{ [key]: value },
      }
    })
  }

  if (!stateLesson) {
    return <Error error={'Leçon inconnue'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonInput = {
      id: stateLesson.id,
      name: stateLesson.name,
      validated: stateLesson.validated,
      summary_state: stateLesson.summary_state,
      summary_required: stateLesson.summary_required,
      summary_review: stateLesson.summary_review,
      summary: stateLesson.summary,
    }
    await updateLessonMutation({ variables: { input: lessonInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour la leçon"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateLesson) === JSON.stringify(lesson)}
    >
      <Grid item container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <TextField
            required
            label="Nom"
            name="name"
            value={stateLesson.name}
            onChange={(event) => {
              handleChange(event.target.name, event.target.value)
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateLesson.validated}
                  onChange={(event) => {
                    handleChange(event.target.name, event.target.checked)
                  }}
                  name="validated"
                />
              }
              label={stateLesson.validated ? 'Leçon validée' : 'Leçon non-validée'}
            />
          </FormGroup>
        </Grid>
      </Grid>
      {!hideSummary && (
        <Grid item container spacing={1} alignItems="center">
          <Grid item xs={8}>
            <TextField
              select
              fullWidth
              id="summary_state"
              label="Statut résumé"
              variant="outlined"
              defaultValue={stateLesson.summary_state}
              value={stateLesson.summary_state || ''}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  getContentAnchorEl: null,
                },
              }}
              onChange={(event) => {
                setStateLesson((prevState) => {
                  if (!prevState) return prevState
                  return {
                    ...prevState,
                    ...{ summary_state: event.target.value as SummaryStateEnum },
                  }
                })
              }}
              required
            >
              {Object.keys(SummaryStateEnum).map((status) => (
                <MenuItem id="statut" key={status} value={status}>
                  {lessonStatutMapping[status as keyof typeof lessonStatutMapping].label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={stateLesson.summary_required}
                    onChange={(event) => {
                      handleChange(event.target.name, event.target.checked)
                    }}
                    name="summary_required"
                  />
                }
                label={stateLesson.summary_required ? 'Résumé requis' : 'Résumé non-requis'}
              />
            </FormGroup>
          </Grid>
        </Grid>
      )}

      <Grid item>
        {!hideSummary && (
          <TextField
            label="Revue résumé"
            name="summary_review"
            value={stateLesson.summary_review || ''}
            onChange={(event) => {
              handleChange(event.target.name, event.target.value)
            }}
            variant="outlined"
            multiline
            minRows={3}
            maxRows={3}
            fullWidth
          />
        )}
      </Grid>
      <Grid item>
        {!hideSummary && (
          <TextEditor
            text={stateLesson.summary}
            onChange={(json) => {
              handleChange('summary', json)
            }}
          />
        )}
      </Grid>
    </FormLayout>
  )
}

export default LessonForm
