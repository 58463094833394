import { useState } from 'react'
//MATERIAL UI
import { Grid, TextField, Typography } from '@material-ui/core'
//COMPONENTS
import { Error, TextEditorJS } from '../../index'
import { FormLayout } from '../index'
// TYPE
import { DeepPartial } from '../../../utils/types'
import { CourseSkillType, CourseType, LessonQuestionType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { COURSE_LESSON_QUESTIONS_QUERY } from '../../../gql/queries'
import { UPDATE_COURSE_SKILL_MUTATION, CREATE_COURSE_SKILL_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
//ROUTER
import { useParams } from 'react-router'

type SkillFormPropsType = {
  skill: CourseSkillType | undefined
  refetch: () => void
  handleClose: () => void
}

const SkillForm = ({ skill, refetch, handleClose }: SkillFormPropsType) => {
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [stateSkill, setStateSkill] = useState<DeepPartial<CourseSkillType> | undefined>(skill)
  const { error, data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_LESSON_QUESTIONS_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })
  const questions = skill?.lesson_questions.reduce((acc: LessonQuestionType[], question) => {
    if (!acc.includes(question)) {
      acc.push(question)
    }
    return acc
  }, [])

  const [updateCourseSkill] = useMutation<{ updateCourseSkill: CourseSkillType }>(UPDATE_COURSE_SKILL_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Compétence mise-à-jour` })
      refetch()
      handleClose()
    },
  })
  const [createCourseSkill] = useMutation<{ createCourseSkill: CourseSkillType }>(CREATE_COURSE_SKILL_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Compétence créée` })
      refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const skillInput = {
      id: stateSkill?.id,
      name: stateSkill?.name,
      course: { id: course?.id },
    }
    if (stateSkill?.id) {
      //update
      await updateCourseSkill({ variables: { input: skillInput } })
    } else {
      //create
      await createCourseSkill({ variables: { input: skillInput } })
    }
  }

  if (error) {
    return <Error error={error} />
  }
  return (
    <FormLayout
      type={skill ? 'update' : 'create'}
      title={skill ? 'Mettre à jour la compétence' : 'Ajouter une compétence'}
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateSkill) === JSON.stringify(skill)}
    >
      <Grid item>
        <TextField
          label="Nom"
          name="name"
          defaultValue={stateSkill?.name}
          onChange={(e) => {
            e.preventDefault()
            setStateSkill((prevState) => {
              return {
                ...prevState,
                ...{ [e.target.name]: e.target.value },
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item style={{ overflow: 'scroll', maxHeight: '450px' }}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Questions liées
        </Typography>
        {questions?.map((question, index) => {
          return (
            <Grid key={question.id} style={{ marginBottom: '5px' }}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Question n°{index + 1}
              </Typography>
              <Typography variant="body2">
                {question?.mc_question?.statement || question?.ranking_question?.statement || (
                  <TextEditorJS
                    holderId={`lesson_questions_${question.id}`}
                    isReadOnly={true}
                    type="gapfill"
                    legacy={question?.gapfill_question?.text}
                  />
                )}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    </FormLayout>
  )
}

export default SkillForm
