import {
  LessonHtmlTypeEnum,
  QuizCategoryEnum,
  QuizDisplayEnum,
  VideoStateEnum,
  LessonTaskTypeEnum,
  LessonTaskGradingTypeEnum,
} from '../../../lib/sharedTypes'

type CaptionType = {
  label: string
  description: string
  color: string
}

export type VideosCaptionsType = {
  [key in VideoStateEnum]: CaptionType
}

export const videosCaptions: VideosCaptionsType = {
  WAITING: {
    label: 'À venir',
    description: 'La vidéo n’est pas encore initiée.',
    color: '#A2A2A2',
  },
  TO_DISCOVER: {
    label: 'À découvrir',
    description: 'Le monteur a exporté sa première version de la vidéo pour la faire valider.',
    color: '#6236FF',
  },
  TO_CORRECT: {
    label: 'À corriger',
    description: 'Le monteur doit procéder à la correction des erreurs constatées dans la dernière revue de la vidéo.',
    color: '#E2005F',
  },
  TO_CHECK: {
    label: 'À vérifier',
    description:
      'Le monteur a exporté une nouvelle version de la vidéo corrigeant les erreurs constatées lors de la dernière revue.',
    color: '#FA8000',
  },
  VALIDATED: {
    label: 'Validée',
    description: 'La vidéo a été validée et est prête à être publiée sur Viméo.',
    color: '#03835C',
  },
}

export const reviewStateMapping: Record<string, Record<string, string>> = {
  CREATED: {
    next: 'CORRECTED',
    color: '#ff9800',
    text: 'Nouveau',
  },
  CORRECTED: {
    next: 'VALIDATED',
    color: '#2196f3',
    text: 'Corrigé',
  },
  VALIDATED: {
    next: 'TO_BE_CORRECTED',
    color: '#4caf50',
    text: 'Validé',
  },
  TO_BE_CORRECTED: {
    next: 'CORRECTED',
    color: '#f44336',
    text: 'A corriger',
  },
}

export const categoryMapping = {
  USER: 'Utilisateur',
  ADMIN: 'Administrateur',
  PRODUCTION: 'Production',
  PROJECT: 'Projet',
  DIRECTOR: 'Réalisateur',
  EDITOR: 'Monteur',
  SALE: 'Commercial',
  SEO: 'SEO',
  DESIGN: 'Design',
}

export const jobMapping = {
  DIRECTOR: 'Réalisateur',
  EDITOR: 'Monteur',
  PROJECT: 'Projet',
  OTHER: 'Autre',
}

export const lessonStatutMapping = {
  WAITING: {
    label: 'En attente',
    color: '#A2A2A2',
  },
  REVIEWING: {
    label: 'En revue',
    color: '#6236FF',
  },
  EDITING: {
    label: 'Edition',
    color: '#E2005F',
  },
  CORRECTED: {
    label: 'Corrigée',
    color: '#FA8000',
  },
  VALIDATED: {
    label: 'Validée',
    color: '#03835C',
  },
  GENERATED: {
    label: 'Généré',
    color: '#00A6ED',
  },
}

type LabelObject = {
  label: string
}

export type QuizCategories = {
  [key in QuizCategoryEnum]: LabelObject
}

export const quizCategories: QuizCategories = {
  NO_IMPACT: {
    label: 'Sans impact',
  },
  ECF: {
    label: 'ECF',
  },
  EXPRESSION: {
    label: "Niveau d'expression",
  },
  POSITIONING: {
    label: 'Test de positionnement',
  },
  FINAL_EVALUATION: {
    label: 'Évaluation finale',
  },
}

// export enum LessonTaskTypeEnum {
//   OTHER = 'OTHER',
//   EPP = 'EPP',
//   ECF = 'ECF',
// }

// export enum LessonTaskGradingTypeEnum {
//   GRADING_VALIDATION = 'GRADING_VALIDATION',
//   GRADING_PERCENTAGE = 'GRADING_PERCENTAGE',
//   GRADING_DPC = 'GRADING_DPC',
// }

export type LessonTaskTypeCategories = {
  [key in LessonTaskTypeEnum]: LabelObject
}

export type LessonTaskGradingTypeCategories = {
  [key in LessonTaskGradingTypeEnum]: LabelObject
}

export const lessonTaskTypes: LessonTaskTypeCategories = {
  OTHER: {
    label: 'Autre',
  },
  ECF: {
    label: 'ECF',
  },
  EPP: {
    label: 'EPP',
  },
}

export const lessonTaskGradingTypes: LessonTaskGradingTypeCategories = {
  GRADING_VALIDATION: {
    label: 'Validé/Non-validé',
  },
  GRADING_PERCENTAGE: {
    label: 'Note sur 100',
  },
  GRADING_DPC: {
    label: 'Validé/À améliorer',
  },
}

export type QuizDisplays = {
  [key in QuizDisplayEnum]: LabelObject
}
export const quizDiplays: QuizDisplays = {
  LIST: {
    label: 'Liste',
  },
  CARD: {
    label: 'Cartes',
  },
}

export type LessonHtmlTypeMappingType = {
  [key in LessonHtmlTypeEnum]: string
}

export const lessonHtmlTypeMapping: LessonHtmlTypeMappingType = {
  LESSONS_OVERVIEW: 'Aperçu leçons',
}
