//REACT
import { useState } from 'react'
//COMPONENTS
import ProductThemeForm from '../components/Forms/productTheme/productThemeForm'
import { DeleteForm } from '../components/Forms'
import { Layout, Title, CustomTable, SearchBar, Error, Loading, CustomModal } from '../components'
//ICON
import { Grid, Button, Menu, MenuItem } from '@material-ui/core'
//GQL
import { PRODUCT_THEMES_PAGE_QUERY, PRODUCT_THEMES_SEARCH_QUERY } from '../gql/queries'
import { DELETE_TEACHER_MUTATION } from '../gql/mutations'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
//TYPES
import { SortEnum, ProductThemeType } from '../../../lib/sharedTypes'
import { ColumnType } from '../utils/types'
//HOOKS
import { useAlert } from '../hooks'
//UTILS
import { handleError } from '../utils/handleError'
import { useNavigate } from 'react-router'

const columns: ColumnType<ProductThemeType>[] = [
  {
    title: 'Nom de code',
    field: 'code_name',
    render: (row: ProductThemeType) => row.code_name,
    type: 'String',
    sortable: false,
  },
  {
    title: 'Titre',
    field: 'title',
    render: (row: ProductThemeType) => row.title,
    type: 'String',
    sortable: false,
  },
  {
    title: 'Position',
    field: 'position',
    render: (row: ProductThemeType) => row.position,
    type: 'String',
    sortable: false,
  },
  {
    title: 'Produit par défaut',
    field: 'default_product.code_name',
    render: (row: ProductThemeType) => row?.default_product?.code_name,
    type: 'String',
    sortable: false,
  },
  {
    title: 'Nombre de produits',
    field: 'name',
    render: (row: ProductThemeType) => row.products.length,
    type: 'String',
    sortable: false,
  },
  {
    title: 'Créé le',
    field: 'created_at',
    render: (row: ProductThemeType) => row.created_at,
    type: 'Date',
    sortable: true,
  },
  {
    title: 'Modifié le',
    field: 'updated_at',
    render: (row: ProductThemeType) => row.updated_at,
    type: 'Date',
    sortable: true,
  },
]

const Themes = () => {
  const navigate = useNavigate()
  const [setAlert] = useAlert()
  const [field, setField] = useState<keyof ProductThemeType>('code_name')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<ProductThemeType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [productThemeModal, setProductThemeModal] = useState(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [deleteTeacher] = useMutation<{
    deleteTeacher: boolean
  }>(DELETE_TEACHER_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Theme supprimé` })
    },
  })
  const {
    loading,
    error,
    data: { productThemes = [], productThemesCount = 0 } = {},
    refetch,
  } = useQuery<{
    productThemes: ProductThemeType[]
    productThemesCount: number
  }>(PRODUCT_THEMES_PAGE_QUERY, {
    variables: {
      order: {
        field: 'position',
        sort: 'ASC',
      },
      skip: skip,
      take: take,
    },
  })

  const [searchProductThemesQuery, { error: searchError, data: { productThemesSearch = [] } = {} }] = useLazyQuery<{
    productThemesSearch: ProductThemeType[]
  }>(PRODUCT_THEMES_SEARCH_QUERY)

  const searchProductThemes = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchProductThemesQuery({
      variables: {
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
        take: take,
      },
    })
  }

  if (error) {
    return (
      <Layout title="Themes">
        <Error error={error} />
      </Layout>
    )
  }

  if (searchError) {
    return (
      <Layout title="Themes">
        <Error error={searchError} />
      </Layout>
    )
  }

  if (loading) {
    return (
      <Layout title="Themes">
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout title="Themes">
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={'Themes'} />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setSelectedRow(undefined)
                setProductThemeModal(true)
              }}
            >
              Ajouter un theme
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <SearchBar placeholder="Rechercher un theme" searchQuery={searchProductThemes} />
        </Grid>

        <Grid item style={{ width: '100%' }}>
          <CustomTable
            columns={columns}
            rows={searching ? productThemesSearch : productThemes}
            field={field}
            sort={sort}
            skip={skip}
            take={take}
            setSort={setSort}
            setField={setField}
            rowsCount={searching ? productThemesSearch.length : productThemesCount}
            setSkip={setSkip}
            setTake={setTake}
            onRowClick={(row: ProductThemeType, e: React.MouseEvent) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(`/themes/${row.id}`, '_blank')
              } else {
                navigate(row.id)
              }
            }}
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer le theme
        </MenuItem>
      </Menu>

      <CustomModal
        size="xs"
        title="Supprimer le theme"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
          setSelectedRow(undefined)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteTeacher({ variables: { input: { id: selectedRow?.id } } })
            setDeleteModal(false)
            setSearching(false)
            await refetch()
            setSelectedRow(undefined)
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
            setSearching(false)
          }}
        />
      </CustomModal>
      <CustomModal
        open={productThemeModal}
        onClose={() => {
          setSearching(false)
          setProductThemeModal(false)
        }}
        animation="slide-up"
      >
        <ProductThemeForm
          productTheme={selectedRow}
          refetch={refetch}
          handleClose={() => {
            setSearching(false)
            setProductThemeModal(false)
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default Themes
