//REACT
import { useState } from 'react'
//MATERIAL UI
import { Chip, useTheme, Grid } from '@material-ui/core'
//TYPES
import { LessonType } from '../../../lib/sharedTypes'
//REACT ICONS
import { MdOutlineStickyNote2 } from 'react-icons/md'
//COMPONENT
import { NoteForm } from './Forms'
import { CustomModal } from './index'

type NotePropsType = {
  lesson: LessonType
  refetch: () => Promise<any>
  size?: string
}

const Note = ({ lesson, refetch, size }: NotePropsType) => {
  const theme = useTheme()
  const [noteModal, setNoteModal] = useState(false)
  return (
    <Grid
      style={{
        height: size || 30,
        width: size || 30,
      }}
    >
      {lesson?.note ? (
        <Chip
          label={lesson?.note}
          color="primary"
          size="medium"
          style={{ maxWidth: 150, overflow: 'scroll' }}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            setNoteModal(true)
          }}
        />
      ) : (
        <MdOutlineStickyNote2
          size={size || '30'}
          style={{ color: theme.palette.primary.main }}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            setNoteModal(true)
          }}
        />
      )}
      <CustomModal
        size="xs"
        title={lesson?.note ? 'Modifier la note' : 'Ajouter une note'}
        open={noteModal}
        onClose={() => {
          setNoteModal(false)
        }}
      >
        <NoteForm
          lesson={lesson}
          handleClose={() => {
            setNoteModal(false)
          }}
          refetch={refetch}
        />
      </CustomModal>
    </Grid>
  )
}

export default Note
