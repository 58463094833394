//REACT
import { useState } from 'react'
//COMPONENTS
import { FileForm, DocumentForm } from '../../../components/Forms/lesson'
import { LessonInfo, LessonRessources } from '../../../components/Lesson'
import { Layout, Title, Error, Loading, CustomModal } from '../../../components'
// MATERIAL UI
import { Grid, Typography, Paper, useTheme, makeStyles, Button, Divider } from '@material-ui/core'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
// TYPES
import { LessonType } from '../../../../../lib/sharedTypes'
// GRAPHQL
import { useQuery } from '@apollo/client'
import { LESSON_DOCUMENT_QUERY } from '../../../gql/queries'
//UTILS
import { isAuthorized, isCourseManager } from '../../../utils/misc'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonPDF = () => {
  const { code_name, lesson_id } = useParams()
  const [updateModal, setUpdateModal] = useState('')
  const theme = useTheme()
  const classes = useStyles()
  if (!lesson_id) {
    return (
      <Layout title="Leçon document">
        <Error error="lesson_id inconnu" />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Leçon document">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  const {
    loading,
    error,
    data: { lesson } = {},
    refetch,
  } = useQuery<{
    lesson: LessonType
  }>(LESSON_DOCUMENT_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(lesson_id?.toString()),
        },
      },
    },
  })

  if (loading || !lesson) {
    return (
      <Layout title="Leçon document">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Leçon document">
        <Error error={error} />
      </Layout>
    )
  }
  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item>
          <Title title={code_name} />
        </Grid>
        {/* LESSON */}
        <LessonInfo
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        {/*LESSON_PDF */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Document</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('document')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Téléchargeable
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{lesson.document.downloadable ? 'Oui' : 'Non'}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Type
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{lesson.document.type}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* PDF */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Fichier</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('file')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container justifyContent="center">
              <object data={`${lesson.document.s3_file}#view=FitV`} width="100%" height="500">
                <p>{lesson.document.s3_file}</p>
              </object>
            </Grid>
          </Grid>
        </Paper>
        {/* RESSOURCES */}
        <LessonRessources
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
      </Grid>
      <CustomModal
        open={updateModal === 'file'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <FileForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'document'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <DocumentForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default LessonPDF
