// REACT
import { useRef } from 'react'
//MATERIAL UI
import { MdDragIndicator, MdExpandLess } from 'react-icons/md'
import { DisciplineType } from '../../../../lib/sharedTypes'
//ICONS
import { MdExpandMore } from 'react-icons/md'
//MATERIAL UI
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core'
import ThematicCard from './ThematicCard'
//DND
import { useDrag, useDrop, XYCoord } from 'react-dnd'
import { Identifier } from 'dnd-core'
//TYPES
import { DeepPartial } from '../../utils/types'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'

type DisciplineCardPropsType = {
  discipline: DeepPartial<DisciplineType> | undefined
  index: number
  closed: boolean
  setClosedCards: React.Dispatch<React.SetStateAction<(number | undefined)[]>>
  moveCard: (dragIndex: number, hoverIndex: number, item: DragItem) => void
}

type DragItem = {
  index: number
  id: string
  type: string
}

const DisciplineCard = ({ discipline, index, closed, setClosedCards, moveCard }: DisciplineCardPropsType) => {
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'DISCIPLINE_CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, item)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'DISCIPLINE_CARD',
    item: { item: discipline, index: index, type: 'DISCIPLINE_CARD' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  drag(drop(ref))

  return (
    <Grid
      item
      container
      direction="row"
      style={{
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
        opacity: isDragging ? 0 : 1,
      }}
      ref={ref}
      data-handler-id={handlerId}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        style={{
          width: '2%',
          flexGrow: 1,
          borderRadius: '10px 0px 0px 10px',
          cursor: 'grab',
          background: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
        }}
        id="12"
        ref={drag}
      >
        <Grid item>
          <MdDragIndicator size={22} />
        </Grid>
      </Grid>
      <Grid style={{ width: '98%', background: theme.palette.background.default, padding: theme.spacing(1) }}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {discipline?.name} - {discipline?.duration} heure(s)
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                if (closed) {
                  setClosedCards((prevState) => {
                    return prevState.filter((id) => id !== discipline?.id)
                  })
                } else {
                  setClosedCards((prevState) => {
                    return [...prevState, discipline?.id]
                  })
                }
              }}
            >
              {closed ? <MdExpandMore /> : <MdExpandLess />}
            </IconButton>
          </Grid>
        </Grid>
        <Grid ref={animateRef}>
          {!closed &&
            discipline?.thematics?.map((thematic, themIndex) => {
              return <ThematicCard key={thematic?.id} thematic={thematic} index={themIndex} moveCard={moveCard} />
            })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DisciplineCard
