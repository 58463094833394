import { useState } from 'react'
//COMPONENTS
import { DeleteForm } from '../../components/Forms'
import { CustomTable, CustomModal, Error, Loading, TextEditorJS } from '../../components'
//ICON
import { MdMoreVert } from 'react-icons/md'
//MATERIAL UI
import {
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Paper,
  useTheme,
  makeStyles,
  Button,
  Divider,
} from '@material-ui/core'
//TYPES
import { ColumnType } from '../../utils/types'
import { CourseType, LessonQuestionType, LessonType } from '../../../../lib/sharedTypes'
//GQL
import { useQuery, useMutation } from '@apollo/client'
import { LESSON_QUESTIONS_QUERY } from '../../gql/queries'
import { DELETE_LESSON_QUESTION_MUTATION } from '../../gql/mutations'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
import { QuestionForm } from '../Forms/course'

type LessonQuestionsPropsType = {
  course: CourseType
  lessonParam: LessonType
  disabled: boolean
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonQuestions = ({ lessonParam, disabled, course }: LessonQuestionsPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState(false)
  const [questionModal, setQuestionModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<LessonQuestionType | undefined>(undefined)

  const {
    loading,
    error,
    refetch,
    data: { lessonQuestions, lessonQuestionsCount = 0 } = {},
  } = useQuery<{
    lessonQuestions: LessonQuestionType[]
    lessonQuestionsCount: number
  }>(LESSON_QUESTIONS_QUERY, {
    variables: {
      where: {
        lesson: {
          id: {
            eq: parseInt(`${lessonParam.id}`),
          },
        },
      },
      order: {
        field: 'created_at',
        sort: 'DESC',
      },
      orderItems: {
        field: 'position',
        sort: 'ASC',
      },
    },
  })

  const [deleteQuestionMutation] = useMutation<{ deleteQuestion: boolean }>(DELETE_LESSON_QUESTION_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Question supprimée` })
      await refetch()
    },
  })

  const questionsColumns: ColumnType<LessonQuestionType>[] = [
    {
      title: 'Enoncé',
      field: 'mc_question.statement',
      render: (row: LessonQuestionType) =>
        row?.mc_question?.statement ||
        row?.ranking_question?.statement || (
          <TextEditorJS
            holderId={`lesson_questions_${row.id}`}
            isReadOnly={true}
            type="gapfill"
            legacy={row?.gapfill_question?.text}
          />
        ),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: LessonQuestionType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: LessonQuestionType) => row.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: LessonQuestionType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (loading || !lessonQuestions) {
    return (
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Questions</Typography>
            <Grid item>
              <Button
                disabled={disabled}
                color="primary"
                variant="outlined"
                style={{ marginRight: theme.spacing(1) }}
                onClick={() => {
                  setSelectedRow(undefined)
                  setQuestionModal(true)
                }}
              >
                Ajouter
              </Button>
            </Grid>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Loading />
          </Grid>
        </Grid>
      </Paper>
    )
  }
  if (error) {
    return <Error error={error} />
  }

  return (
    <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
      <Grid item>
        <Grid item container justifyContent="space-between">
          <Typography variant="h5">Questions</Typography>
          <Grid item>
            <Button
              disabled={disabled}
              color="primary"
              variant="outlined"
              style={{ marginRight: theme.spacing(1) }}
              onClick={() => {
                setSelectedRow(undefined)
                setQuestionModal(true)
              }}
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
          <Divider />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CustomTable<LessonQuestionType>
            columns={questionsColumns}
            rows={lessonQuestions}
            rowsCount={lessonQuestionsCount}
            field="name"
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setQuestionModal(true)
            setAnchorEl(null)
          }}
        >
          Modifier la question
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la question
        </MenuItem>
      </Menu>

      <CustomModal
        open={questionModal}
        onClose={() => {
          setQuestionModal(false)
        }}
        animation="slide-up"
      >
        <QuestionForm
          lesson={lessonParam}
          course={course}
          question={selectedRow}
          refetch={refetch}
          handleClose={() => {
            setQuestionModal(false)
            setSelectedRow(undefined)
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer la question"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteQuestionMutation({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Paper>
  )
}

export default LessonQuestions
