import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, MenuItem } from '@material-ui/core'
//ICONS
// COMPONENTS
import { FormLayout } from '../index'
import { Error } from '../../index'
// TYPE
import { ProductType, CertifierType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { CERTIFIERS_QUERY } from '../../../gql/queries'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type CertificationFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const CertificationForm = ({ product, refetch, handleClose }: CertificationFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const { error, data: { certifiers } = {} } = useQuery<{ certifiers: CertifierType[] }>(CERTIFIERS_QUERY, {
    variables: {
      order: {
        sort: 'ASC',
        field: 'full_name',
      },
    },
  })
  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (error) {
    return <Error error={error} />
  }

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: stateProduct.id,
      certification: {
        ...stateProduct?.certification,
        certifier: { id: stateProduct?.certification.certifier.id },
      },
    }
    await updateProductMutation({ variables: { input: productInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les certifications"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProduct) === JSON.stringify(product)}
    >
      <Grid item>
        {certifiers && (
          <TextField
            label="Organisme de certification"
            name="certification.certifier"
            value={stateProduct?.certification?.certifier?.id || ''}
            onChange={(e) => {
              e.preventDefault()
              setStateProduct((prevState) => {
                const certifier = certifiers.find((certifier) => certifier.id.toString() === e.target.value.toString())
                if (certifier) {
                  return {
                    ...prevState,
                    certification: {
                      ...prevState.certification,
                      certifier: certifier,
                    },
                  }
                }
                return prevState
              })
            }}
            variant="outlined"
            fullWidth
            select
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              },
            }}
          >
            {certifiers?.map((certifier) => {
              return (
                <MenuItem key={certifier.id} value={certifier.id}>
                  {certifier.full_name}
                </MenuItem>
              )
            })}
          </TextField>
        )}
      </Grid>
      <Grid item>
        <TextField
          required
          label="Nom de la certification"
          name="certification.name"
          defaultValue={stateProduct?.certification?.name}
          onChange={(e) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                certification: {
                  ...prevState.certification,
                  name: e.target.value,
                },
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          required
          label="Code de la certification (RSXXXX)"
          name="certification.code"
          defaultValue={stateProduct?.certification?.code}
          onChange={(e) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                certification: {
                  ...prevState.certification,
                  code: e.target.value,
                },
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          required
          label="Lien vers france compétence"
          name="certification.href"
          defaultValue={stateProduct?.certification?.href}
          onChange={(e) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                certification: {
                  ...prevState.certification,
                  href: e.target.value,
                },
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          required
          label="Modalité de certification"
          name="certification.text"
          defaultValue={stateProduct?.certification?.text}
          onChange={(e) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                certification: {
                  ...prevState.certification,
                  text: e.target.value,
                },
              }
            })
          }}
          variant="outlined"
          fullWidth
          multiline
          minRows={5}
          maxRows={5}
        />
      </Grid>
      <Grid item>
        <TextField
          required
          label="Message"
          name="certification.message"
          defaultValue={stateProduct?.certification?.message}
          onChange={(e) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                certification: {
                  ...prevState.certification,
                  message: e.target.value,
                },
              }
            })
          }}
          variant="outlined"
          fullWidth
          multiline
          minRows={5}
          maxRows={5}
        />
      </Grid>
    </FormLayout>
  )
}

export default CertificationForm
