//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid, MenuItem } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../index'
//TYPES
import { UserType, VideoSetType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { EDITORS_QUERY } from '../../../gql/queries'
import { CREATE_VIDEOSET_MUTATION, UPDATE_VIDEOSET_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type SetFormPropsType = {
  video_set?: VideoSetType
  project_code_name: string
  refetch: () => Promise<any>
  setVideoSetFormModal: (x: boolean) => void
}

const SetForm = ({ video_set, project_code_name, refetch, setVideoSetFormModal }: SetFormPropsType) => {
  const [newVideoSet, setNewVideoSet] = useState<DeepPartial<VideoSetType> | undefined>(video_set)
  const [setAlert] = useAlert()
  const { data: { users: editors = [] } = {} } = useQuery<{ users: UserType[] }>(EDITORS_QUERY, {
    variables: {
      where: {
        job: {
          category: {
            eq: 'EDITOR',
          },
        },
      },
      order: {
        field: 'username',
        sort: 'ASC',
      },
    },
  })
  const [createVideoSetMutation] = useMutation<{
    createVideoSet: VideoSetType
  }>(CREATE_VIDEOSET_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `Set ${data.createVideoSet.index} créé` })
      await refetch()
      setVideoSetFormModal(false)
    },
  })
  const [updateVideoSetMutation] = useMutation<{
    updateVideoSet: VideoSetType
  }>(UPDATE_VIDEOSET_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `Set ${data.updateVideoSet.index} mis-à-jour` })
      await refetch()
      setVideoSetFormModal(false)
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const videoSetInput = {
      id: newVideoSet?.id,
      // name: newVideoSet?.name,
      editor: { id: newVideoSet?.editor?.id },
      project: { code_name: project_code_name },
    }
    if (newVideoSet?.id) {
      await updateVideoSetMutation({ variables: { input: videoSetInput } })
    } else {
      await createVideoSetMutation({ variables: { input: videoSetInput } })
    }
  }

  return (
    <FormLayout
      type={newVideoSet?.id ? 'update' : 'create'}
      title={newVideoSet?.id ? 'Mettre à jour un video set' : 'Créer un video set'}
      onSubmit={handleSubmit}
      submitDisabled={newVideoSet?.id ? video_set === newVideoSet : false}
    >
      <Grid item>
        <TextField
          fullWidth
          variant="outlined"
          id="editor"
          select
          label="Monteur"
          defaultValue={newVideoSet?.editor?.username}
          value={newVideoSet?.editor?.id || ''}
          onChange={(event) => {
            setNewVideoSet({
              ...newVideoSet,
              editor: editors.find((e) => e.id.toString() === event.target.value.toString()),
            })
          }}
        >
          {editors.map((editor) => (
            <MenuItem id="editor" key={editor.id} value={editor.id}>
              {editor.username}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </FormLayout>
  )
}

export default SetForm
