import { authReducer, userReducer, alertReducer } from './reducers'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import thunk from 'redux-thunk'

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  alert: alertReducer,
})

const persistConfig = {
  key: 'root',
  storage: storage,
}

const pReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: pReducer,
  devTools: import.meta.env.PROD,
  middleware: [thunk],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
