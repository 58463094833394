import { useState } from 'react'
//MATERIAL UI
import { Grid, Card, CardContent, Typography, useTheme, Link, Chip } from '@material-ui/core'
//COMPONENTS
import { SummaryForm } from '../../components/Forms/lesson'
import { Error, TextEditor, CustomModal } from '../../components'
//TYPES
import { CourseType, LessonType } from '../../../../lib/sharedTypes'
//GQL
import { COURSE_PLAN_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
//REACT-ROUTER
import { useParams } from 'react-router'
//UTILS
import { int2roman } from '../../utils/misc'
import { lessonStatutMapping } from '../../utils/constants'

const Summaries = () => {
  const { code_name } = useParams()
  const theme = useTheme()
  const [selectedLesson, setSelectedLesson] = useState<LessonType | null>(null)

  if (!code_name) {
    return <Error error={'code_name inconnu'} />
  }

  const { data: { course } = {}, refetch } = useQuery<{
    course: CourseType
  }>(COURSE_PLAN_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: 'position',
        sort: 'ASC',
      },
    },
  })

  return (
    <Grid container direction="column" justifyContent="flex-start" spacing={1}>
      <Grid item container justifyContent="flex-end">
        {course?.parts.map((part) => {
          return (
            <Grid key={part.id} item xs={12}>
              <Card elevation={3} style={{ margin: theme.spacing(1) }}>
                <CardContent>
                  <Typography variant="h4">
                    Partie {int2roman(part.position + 1)}. {part?.name}
                  </Typography>
                  {part?.sub_parts.map((sub_part) => {
                    return (
                      <Grid key={sub_part.id} item style={{ marginBottom: theme.spacing(1) }}>
                        <Typography key={sub_part.id} variant="h6">
                          Chapitre {String.fromCharCode(96 + sub_part.position + 1).toUpperCase()}. {sub_part?.name}
                        </Typography>
                        {sub_part?.sub_parts.map((ss_part) => {
                          return (
                            <Grid key={ss_part.id}>
                              <Link
                                variant="body1"
                                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                onClick={() => {
                                  setSelectedLesson(ss_part?.lesson)
                                }}
                              >
                                {ss_part.position + 1}. {ss_part?.lesson?.name}
                                {ss_part.lesson.summary_required && (
                                  <Chip
                                    size="small"
                                    label={lessonStatutMapping[ss_part.lesson.summary_state].label}
                                    style={{
                                      backgroundColor: lessonStatutMapping[ss_part.lesson.summary_state].color,
                                      marginLeft: 5,
                                    }}
                                    color="primary"
                                  />
                                )}
                              </Link>
                              <TextEditor text={ss_part.lesson.summary} readOnly={true} key={ss_part.lesson.version} />
                            </Grid>
                          )
                        })}
                      </Grid>
                    )
                  })}
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
      <CustomModal
        open={selectedLesson !== null}
        onClose={() => {
          setSelectedLesson(null)
        }}
        animation="slide-up"
      >
        {selectedLesson && (
          <SummaryForm
            lesson={selectedLesson}
            refetch={refetch}
            handleClose={() => {
              setSelectedLesson(null)
            }}
          />
        )}
      </CustomModal>
    </Grid>
  )
}

export default Summaries
