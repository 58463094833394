// REACT
import { useState } from 'react'
// ICON
import { MdMoreVert } from 'react-icons/md'
// MATERIAL UI
import { Grid, Menu, MenuItem, Button, Link, IconButton, useTheme } from '@material-ui/core'
// COMPONENTS
import { ImportResourceForm } from '../../components/Forms/course'
import { ResourceForm } from '../../components/Forms/lesson'
import { DeleteForm } from '../../components/Forms'
import { CustomTable, Loading, Error, CustomModal, SearchBar } from '../../components'
// REACT-ROUTER
import { useParams, useOutletContext } from 'react-router'
// TYPE
import { CourseType, SortEnum, ResourceType } from '../../../../lib/sharedTypes'
import { ColumnType } from '../../utils/types'
// GQL
import { COURSE_RESOURCES_QUERY, COURSE_RESOURCES_SEARCH_QUERY } from '../../gql/queries'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { DELETE_RESOURCE_MUTATION } from '../../gql/mutations'
// HOOKS
import { useAlert } from '../../hooks'
// UTILS
import { handleError } from '../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../utils/misc'

const Resources = () => {
  const theme = useTheme()
  const { refetchCourse } = useOutletContext<{ refetchCourse: () => Promise<any> }>()
  const [setAlert] = useAlert()
  const [field, setField] = useState<keyof ResourceType>('id')
  const [sort, setSort] = useState<keyof typeof SortEnum>('ASC')
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const { code_name } = useParams()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<ResourceType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [resourceModal, setResourceModal] = useState(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [importModal, setImportModal] = useState<boolean>(false)

  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{ course: CourseType }>(COURSE_RESOURCES_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      take: take,
      skip: skip,
    },
    async onCompleted() {
      await refetchCourse()
    },
  })

  const [deleteResource] = useMutation<{ deleteResource: boolean }>(DELETE_RESOURCE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Ressource supprimée` })
      await refetch()
    },
  })

  const [searchResourcesQuery, { data: { course: courseSearch = null } = {} }] = useLazyQuery<{
    course: CourseType
  }>(COURSE_RESOURCES_SEARCH_QUERY)

  const searchResources = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchResourcesQuery({
      variables: {
        where: {
          code_name: {
            eq: code_name,
          },
        },
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
      },
    })
  }

  const columns: ColumnType<ResourceType>[] = [
    {
      title: 'Nom',
      field: 'name',
      render: (row: ResourceType) => (
        <Link href={row.s3_file} target="_blank">
          {row.name}
        </Link>
      ),
      type: 'String',
      sortable: true,
    },

    {
      title: 'Creation',
      field: 'created_at',
      render: (row: ResourceType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: ResourceType) => row.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: ResourceType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (!code_name) {
    return <Error error="code_name inconnu" />
  }

  if (loading) {
    return <Loading />
  }

  if (!course) {
    return <Error error={'Course error'} />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column" justifyContent="flex-start" spacing={1}>
      <Grid item container justifyContent="flex-end">
        <Button
          color="secondary"
          variant="outlined"
          disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
          onClick={() => {
            setImportModal(true)
          }}
          style={{ marginRight: theme.spacing(1) }}
        >
          Importer
        </Button>
        <Button
          disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
          color="primary"
          variant="outlined"
          onClick={() => {
            setSelectedRow(undefined)
            setResourceModal(true)
          }}
        >
          Ajouter
        </Button>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <SearchBar placeholder="Rechercher une ressource" searchQuery={searchResources} />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <CustomTable<ResourceType>
          columns={columns}
          rows={searching && courseSearch ? courseSearch.resources_search : course.resources}
          field={field}
          sort={sort}
          take={take}
          skip={skip}
          setTake={setTake}
          setSkip={setSkip}
          setSort={setSort}
          setField={setField}
          rowsCount={courseSearch ? courseSearch?.resources_search.length : course.resources_count}
        />

        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => {
            setAnchorEl(null)
            setSelectedRow(undefined)
          }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
            onClick={() => {
              setResourceModal(true)
              setAnchorEl(null)
            }}
          >
            Modifier la ressource
          </MenuItem>
          <MenuItem
            disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
            onClick={() => {
              setDeleteModal(true)
              setAnchorEl(null)
            }}
          >
            Supprimer la ressource
          </MenuItem>
        </Menu>
        <CustomModal
          open={resourceModal}
          onClose={() => {
            setResourceModal(false)
          }}
          animation="slide-up"
        >
          <ResourceForm
            course={course}
            resource={selectedRow}
            lessons={selectedRow?.lessons}
            refetch={refetch}
            handleClose={() => {
              setResourceModal(false)
              setSelectedRow(undefined)
            }}
          />
        </CustomModal>
        <CustomModal
          size="xs"
          title="Supprimer la ressource"
          open={deleteModal}
          onClose={() => {
            setDeleteModal(false)
          }}
        >
          <DeleteForm
            onDelete={async () => {
              await deleteResource({
                variables: {
                  input: {
                    id: selectedRow?.id,
                    lessons: selectedRow?.lessons.map((lesson) => {
                      return { id: lesson.id }
                    }),
                  },
                },
              })
              setDeleteModal(false)
              await refetch()
            }}
            onCancel={() => {
              setSelectedRow(undefined)
              setDeleteModal(false)
            }}
          />
        </CustomModal>
        <CustomModal
          size="xs"
          title="Importer les resources"
          open={importModal}
          onClose={() => {
            setImportModal(false)
          }}
        >
          <ImportResourceForm
            course={course}
            handleClose={() => {
              setImportModal(false)
            }}
            refetch={refetch}
          />
        </CustomModal>
      </Grid>
    </Grid>
  )
}

export default Resources
