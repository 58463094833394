// REACT HOOKS
import { useState } from 'react'
// ICON
import { MdMoreVert } from 'react-icons/md'
// MATERIAL UI
import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core'
// REACT-ROUTER
import { useNavigate, useParams, useOutletContext } from 'react-router'
// COMPONENTS
import { DeleteForm } from '../../../components/Forms'
import { Header } from '../../../components/Lesson'
import { CustomTable, Error, Loading, CustomModal, SearchBar, Note } from '../../../components'
// TYPES
import { CourseType, LessonEppType, SortEnum, LessonType } from '../../../../../lib/sharedTypes'
import { ColumnType } from '../../../utils/types'
// GRAPHQL
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { COURSE_EPP_QUERY, COURSE_LESSONS_EPP_SEARCH } from '../../../gql/queries'
import { DELETE_LESSON_MUTATION } from '../../../gql/mutations'
// HOOKS
import { useAlert } from '../../../hooks'
// UTILS
import { handleError } from '../../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../../utils/misc'

const LessonEPP = () => {
  const navigate = useNavigate()
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [field, setField] = useState<keyof LessonType>('id')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [take, setTake] = useState<number>(10)
  const [skip, setSkip] = useState<number>(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<LessonType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searching, setSearching] = useState<boolean>(false)
  const { refetchCourse } = useOutletContext<{ refetchCourse: () => Promise<any> }>()

  const [deleteLessonMutation] = useMutation<{
    deleteLesson: boolean
  }>(DELETE_LESSON_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetch()
      setAlert({ severity: 'success', content: `Leçon EPP supprimée` })
    },
  })

  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{
    course: CourseType
  }>(COURSE_EPP_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      lessonWhere: {
        epp: {
          id: {
            ne: null,
          },
        },
      },
      order: {
        field: 'position',
        sort: 'ASC',
      },
      skip: skip,
      take: take,
    },
    async onCompleted() {
      await refetchCourse()
    },
  })

  const [searchLessonQuery, { data: { course: courseSearch = null } = {} }] = useLazyQuery<{ course: CourseType }>(
    COURSE_LESSONS_EPP_SEARCH,
  )

  const searchLessons = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchLessonQuery({
      variables: {
        where: {
          code_name: {
            eq: code_name,
          },
        },
        lessonWhere: {
          epp: {
            id: {
              ne: null,
            },
          },
        },
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
      },
    })
  }

  const columns: ColumnType<LessonType>[] = [
    {
      title: 'Nom de la leçon',
      field: 'name',
      render: (row: LessonType) => row.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Notes',
      field: 'lesson.note',
      render: (row: LessonType) => <Note lesson={row} refetch={refetch} />,
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: LessonType) => row.epp.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: LessonType) => row.epp.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: LessonType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (!code_name) {
    return <Error error={'code_name inconnu'} />
  }

  if (error) {
    return <Error error={error} />
  }

  if (!course || loading) {
    return <Loading />
  }

  return (
    <Grid item container direction="column" justifyContent="flex-start" spacing={1} style={{ width: '100%' }}>
      <Header refetch={refetch} />
      <Grid item>
        <SearchBar placeholder="Rechercher une leçon EPP" searchQuery={searchLessons} />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <CustomTable<LessonType>
          columns={columns}
          rows={searching && courseSearch ? courseSearch.lessons_search : course.lessons}
          field={field}
          sort={sort}
          take={take}
          skip={skip}
          setTake={setTake}
          setSkip={setSkip}
          setSort={setSort}
          setField={setField}
          rowsCount={searching ? courseSearch?.lessons_search.length : course.epp_lessons_count}
          onRowClick={(row: LessonEppType, e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open(`/formations/${code_name}/lessons/epp/${row.id}`, '_blank')
            } else {
              navigate(row.id)
            }
          }}
        />
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la leçon EPP
        </MenuItem>
      </Menu>
      <CustomModal
        size="xs"
        title="Supprimer la leçon EPP"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteLessonMutation({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default LessonEPP
