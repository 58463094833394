//REACT
import { useEffect, useState } from 'react'
// MATERIAL UI
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import MaskedInput from 'react-text-mask'

type FormatType = 'hh' | 'hh:mm' | 'dd:hh:mm' | 'ddd'

type DurationInputPropsType = {
  label: string
  onChange: (duration: number) => void
  defaultValue: number | undefined
  format?: FormatType
  required?: boolean
}

const maskMapping: Record<string, any> = {
  hh: [/[0-9]/, /[0-9]/],
  'hh:mm': [/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/],
  'dd:hh:mm': [/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/],
  ddd: [/[0-9]/, /[0-9]/, /[0-9]/],
}

const TextMaskCustom = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { inputRef, placeholder, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        inputRef(ref ? ref.inputElement : null)
      }}
      placeholder={(placeholder as string).toUpperCase()}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      mask={maskMapping[placeholder as string]}
      placeholderChar={'\u2000'}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      guide={props?.value ? true : false}
      showMask
    />
  )
}

const stringToSeconds = (string: string, format: FormatType) => {
  switch (format) {
    case 'hh': {
      const hours = parseInt(string)
      const hoursinSeconds = hours * 3600
      return hoursinSeconds
    }
    case 'hh:mm': {
      const hours = parseInt(string.split(':')[0])
      const minutes = parseInt(string.split(':')[1])
      const hoursinSeconds = hours * 3600
      const minutesinSeconds = minutes * 60
      return hoursinSeconds + minutesinSeconds
    }
    case 'dd:hh:mm': {
      const days = parseInt(string.split(':')[0])
      const hours = parseInt(string.split(':')[1])
      const minutes = parseInt(string.split(':')[2])
      const daysinSeconds = days * 3600 * 24
      const hoursinSeconds = hours * 3600
      const minutesinSeconds = minutes * 60
      return daysinSeconds + hoursinSeconds + minutesinSeconds
    }
    case 'ddd': {
      const days = parseInt(string)
      return days * 3600 * 24
    }
    default:
      console.error('Unknown duration format')
      return 0
  }
}

const secondsToString = (seconds: number, format: FormatType) => {
  switch (format) {
    case 'hh': {
      const hours = `0${Math.floor(seconds / 3600)}`.slice(-2)
      return `${hours}`
    }
    case 'hh:mm': {
      const hours = `0${Math.floor((seconds % 86400) / 3600)}`.slice(-2)
      const minutes = `0${Math.floor((seconds % 3600) / 60)}`.slice(-2)
      return `${hours}:${minutes}`
    }
    case 'dd:hh:mm': {
      const days = Math.floor(seconds / 86400)
      const hours = `0${Math.floor((seconds % 86400) / 3600)}`.slice(-2)
      const minutes = `0${Math.floor((seconds % 3600) / 60)}`.slice(-2)
      return `${days}:${hours}:${minutes}`
    }
    case 'ddd': {
      const days = Math.floor(seconds / 86400)
      return `${days}`
    }
    default:
      console.error('Unknown duration format')
      return ''
  }
}

const DurationInput = ({
  label,
  onChange,
  defaultValue,
  required = false,
  format = 'hh:mm',
}: DurationInputPropsType) => {
  const [duration, setDuration] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (defaultValue) {
      const durationInString = secondsToString(defaultValue, format)
      setDuration(durationInString)
    }
  }, [])

  useEffect(() => {
    if (duration) {
      const durationInSeconds = stringToSeconds(duration, format)
      onChange(durationInSeconds)
    }
  }, [duration])

  return (
    <FormControl variant="outlined" style={{ display: 'flex', flexGrow: 1 }}>
      <InputLabel htmlFor="component-outlined">{required ? `${label} *` : label}</InputLabel>
      <OutlinedInput
        required={required}
        label={required ? `${label} *` : label}
        name={required ? `${label} *` : label}
        value={duration}
        placeholder={format}
        onChange={(event) => {
          setDuration(event.target.value)
        }}
        id="duration-masked-input"
        inputComponent={TextMaskCustom}
      />
    </FormControl>
  )
}

export default DurationInput
