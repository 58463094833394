import { useState, useRef } from 'react'
import {
  Step,
  Stepper,
  StepLabel,
  Grid,
  Button,
  useTheme,
  Typography,
  makeStyles,
  Theme,
  Chip,
  Card,
  CardContent,
  Link,
  CircularProgress,
  alpha,
} from '@material-ui/core'
import { MdOutlineInfo } from 'react-icons/md'
import { CourseSkillType, CourseType, LessonType, QuizType } from '../../../../../lib/sharedTypes'
import { int2roman, secondsToDHMS } from '../../../utils/misc'
import { handleError } from '../../../utils/handleError'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { useNavigate, NavigateFunction } from 'react-router'
import { PUBLISH_COURSE_MUTATION } from '../../../gql/mutations'
import { COURSE_PLAN_QUERY, COURSE_CHECK_STEP_4_QUERY } from '../../../gql/queries'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router'
import { CustomTable } from '../../index'
import { ColumnType } from '../../../utils/types'
import { getLessonCategory } from '../../../utils/misc'
import { useAlert } from '../../../hooks'

type CourseChecksPropsType = {
  course: CourseType
  handleClose: () => void
  refetch: () => Promise<any>
}

const steps = ['Informations', 'Formateurs', 'Visuels', 'Séquencier', 'QCM', 'Finalisation']

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const Step0 = (
  course: CourseType,
  theme: Theme,
  classes: ClassNameMap,
  navigate: NavigateFunction,
  handleClose: () => void,
) => {
  return (
    <Grid item xs={12} style={{ border: `1px solid ${theme.palette.action.disabled}`, borderRadius: 5, padding: 0 }}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        style={{ borderBottom: `1px solid ${theme.palette.action.disabled}`, padding: theme.spacing(2) }}
      >
        <Grid item>
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Nom de code
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{course.code_name}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={(e: React.MouseEvent) => {
              handleClose()
              if (e.metaKey || e.ctrlKey) {
                window.open(`/formations/${course.code_name}/general_informations`, '_blank')
              } else {
                navigate(`/formations/${course.code_name}/general_informations`, {
                  state: { openModal: 'description' },
                })
              }
            }}
          >
            Modifier
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        style={{ borderBottom: `1px solid ${theme.palette.action.disabled}`, padding: theme.spacing(2) }}
      >
        <Grid item>
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Nom de la formation
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{course.name}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={(e: React.MouseEvent) => {
              handleClose()
              if (e.metaKey || e.ctrlKey) {
                window.open(`/formations/${course.code_name}/general_informations`, '_blank')
              } else {
                navigate(`/formations/${course.code_name}/general_informations`, {
                  state: { openModal: 'description' },
                })
              }
            }}
          >
            Modifier
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        style={{ borderBottom: `1px solid ${theme.palette.action.disabled}`, padding: theme.spacing(2) }}
      >
        <Grid item>
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Durée de la formation
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{secondsToDHMS(course.duration)}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={(e: React.MouseEvent) => {
              handleClose()
              if (e.metaKey || e.ctrlKey) {
                window.open(`/formations/${course.code_name}/general_informations`, '_blank')
              } else {
                navigate(`/formations/${course.code_name}/general_informations`, {
                  state: { openModal: 'description' },
                })
              }
            }}
          >
            Modifier
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: theme.spacing(2) }}
      >
        <Grid item>
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Catalogue(s)
            </Typography>
          </Grid>
          <Grid item>
            {course.product.product_catalog_positions.map((pcp) => {
              return (
                <Chip
                  key={pcp.catalog.id}
                  label={pcp.catalog.name}
                  color="primary"
                  style={{ margin: theme.spacing(1) }}
                />
              )
            })}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={(e: React.MouseEvent) => {
              handleClose()
              if (e.metaKey || e.ctrlKey) {
                window.open(`/products/${course.product.code_name}/general_informations`, '_blank')
              } else {
                navigate(`/products/${course.product.code_name}/general_informations`, {
                  state: { openModal: 'description' },
                })
              }
            }}
          >
            Modifier
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Step1 = (
  course: CourseType,
  theme: Theme,
  _classes: ClassNameMap,
  navigate: NavigateFunction,
  handleClose: () => void,
) => {
  return (
    <Grid item>
      {course.product.teachers.map((teacher) => {
        return (
          <Card
            elevation={3}
            key={teacher.id}
            style={{ display: 'flex', flexDirection: 'row', height: '250px', margin: theme.spacing(1) }}
          >
            <img
              width="250px"
              height="100%"
              src={teacher.s3_picture}
              alt="Teacher Profile picture"
              style={{ objectFit: 'cover' }}
            />
            <CardContent style={{ overflow: 'scroll' }}>
              <Grid container style={{ height: '100%' }}>
                <Grid item xs={12} style={{ flexGrow: 1 }}>
                  <Typography variant="h4">{`${teacher.first_name} ${teacher.last_name}`}</Typography>
                  <Typography variant="body2">{teacher.introduction}</Typography>
                </Grid>
                <Grid item xs={12} container>
                  <Grid container justifyContent="flex-end" alignItems="flex-end">
                    <Button
                      variant="outlined"
                      onClick={(e: React.MouseEvent) => {
                        handleClose()
                        if (e.metaKey || e.ctrlKey) {
                          window.open(`/teachers/${teacher.id}`, '_blank')
                        } else {
                          navigate(`/teachers/${teacher.id}`)
                        }
                      }}
                    >
                      Modifier
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
      })}
    </Grid>
  )
}

const Step2 = (
  course: CourseType,
  theme: Theme,
  _classes: ClassNameMap,
  navigate: NavigateFunction,
  handleClose: () => void,
) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Card elevation={3} style={{ margin: theme.spacing(1) }}>
          <Grid item container justifyContent="center">
            <Grid item>
              <img
                src={course.product.s3_banner}
                alt="Bannière de la formation"
                style={{ objectFit: 'contain', maxHeight: 350 }}
              />
            </Grid>
          </Grid>
          <CardContent style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={8}>
              <Typography variant="h4">Bannière de la formation</Typography>
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={(e: React.MouseEvent) => {
                  handleClose()
                  if (e.metaKey || e.ctrlKey) {
                    window.open(`/formations/${course.code_name}/general_informations`, '_blank')
                  } else {
                    navigate(`/formations/${course.code_name}/general_informations`, {
                      state: { openModal: 'banner' },
                    })
                  }
                }}
              >
                Modifier
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3} style={{ margin: theme.spacing(1) }}>
          <Grid item container justifyContent="center">
            <Grid item>
              <img
                src={course.product.s3_card}
                alt="Carte de la formation"
                style={{ objectFit: 'contain', maxHeight: 350 }}
              />
            </Grid>
          </Grid>
          <CardContent style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={8}>
              <Typography variant="h4">Carte de la formation</Typography>
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={(e: React.MouseEvent) => {
                  handleClose()
                  if (e.metaKey || e.ctrlKey) {
                    window.open(`/formations/${course.code_name}/general_informations`, '_blank')
                  } else {
                    navigate(`/formations/${course.code_name}/general_informations`, {
                      state: { openModal: 'card' },
                    })
                  }
                }}
              >
                Modifier
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3} style={{ margin: theme.spacing(1) }}>
          <Grid item container justifyContent="center" style={{ background: '#424242' }}>
            <Grid item>
              <img
                src={course.product.s3_logo}
                alt="Logo de la formation"
                style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '150px' }}
              />
            </Grid>
          </Grid>

          <CardContent style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={8}>
              <Typography variant="h4">Logo de la formation</Typography>
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={(e: React.MouseEvent) => {
                  handleClose()
                  if (e.metaKey || e.ctrlKey) {
                    window.open(`/formations/${course.code_name}/general_informations`, '_blank')
                  } else {
                    navigate(`/formations/${course.code_name}/general_informations`, {
                      state: { openModal: 'logo' },
                    })
                  }
                }}
              >
                Modifier
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

const Step3 = (
  course: CourseType,
  theme: Theme,
  _classes: ClassNameMap,
  navigate: NavigateFunction,
  handleClose: () => void,
  duplicates?: LessonType[],
) => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {duplicates?.length !== 0 && (
        <Grid item xs={12} style={{ margin: theme.spacing(1) }}>
          <Typography color="error">Leçons dupliquées:</Typography>
          {duplicates?.map((l) => {
            return (
              <Grid item key={l.id}>
                <Typography variant="body1">- {l.name}</Typography>
              </Grid>
            )
          })}
        </Grid>
      )}
      <Grid item xs={12}>
        <Card elevation={3} style={{ margin: theme.spacing(1) }}>
          <CardContent>
            <Typography variant="h5">Introduction: {course.introduction ? course.introduction.name : '-'}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3} style={{ margin: theme.spacing(1) }}>
          <CardContent>
            <Typography variant="h5">Evaluation: {course.evaluation ? course.evaluation.name : '-'}</Typography>
          </CardContent>
        </Card>
      </Grid>
      {course.parts.map((part) => {
        return (
          <Grid key={part.id} item xs={12}>
            <Card elevation={3} style={{ margin: theme.spacing(1) }}>
              <CardContent>
                <Typography variant="h5">
                  Partie {int2roman(part.position + 1)}. {part.name}
                </Typography>
                {part.sub_parts.map((sub_part) => {
                  return (
                    <Grid key={sub_part.id} item style={{ marginBottom: theme.spacing(1) }}>
                      <Typography key={sub_part.id} variant="h6">
                        Chapitre {String.fromCharCode(96 + sub_part.position + 1).toUpperCase()}. {sub_part.name}
                      </Typography>
                      {sub_part.sub_parts.map((ss_part) => {
                        return (
                          <Typography key={ss_part.id} variant="body1">
                            {ss_part.position + 1}.{' '}
                            <Typography variant="caption">{getLessonCategory(ss_part.lesson)}</Typography>{' '}
                            <Link
                              href={`/formations/${course.code_name}/lessons/${getLessonCategory(
                                ss_part.lesson,
                              ).toLowerCase()}/${ss_part?.lesson?.id}`}
                              target="_blank"
                            >
                              {ss_part?.lesson?.name}
                            </Link>
                          </Typography>
                        )
                      })}
                    </Grid>
                  )
                })}
              </CardContent>
            </Card>
          </Grid>
        )
      })}
      <Button
        variant="outlined"
        onClick={(e: React.MouseEvent) => {
          handleClose()
          if (e.metaKey || e.ctrlKey) {
            window.open(`/formations/${course.code_name}/plan`, '_blank')
          } else {
            navigate(`/formations/${course.code_name}/plan`)
          }
        }}
      >
        Modifier le séquencier
      </Button>
    </Grid>
  )
}

const Step4 = (
  course: CourseType,
  theme: Theme,
  _classes: ClassNameMap,
  navigate: NavigateFunction,
  handleClose: () => void,
  coursePlan: CourseType,
) => {
  const getLocation = (quiz: QuizType) => {
    const lessonId = quiz.lesson.id
    const part = coursePlan.parts.filter((part) => {
      const resp = part.sub_parts
        .map((sub_part) => {
          return sub_part.sub_parts.find((ss_part) => {
            return ss_part.lesson.id === lessonId
          })
        })
        .filter((el) => el)
      return resp.length !== 0
    })

    if (part.length !== 0) {
      const chapter = part[0].sub_parts.filter((sub_part) => {
        return sub_part.sub_parts.find((ss_part) => {
          return ss_part.lesson.id === lessonId
        })
      })
      if (chapter.length !== 0) {
        return `Partie ${int2roman(part[0].position + 1)} Chapitre ${String.fromCharCode(
          96 + chapter[0].position + 1,
        ).toUpperCase()}`
      }
      return 'Chapitre Inconnu!'
    }
    if (coursePlan?.evaluation?.id === lessonId) return 'Evaluation'
    return 'Inconnu!'
  }

  const getQuestionCount = (skill: CourseSkillType) => {
    return coursePlan?.evaluation?.quiz?.quiz_questions.filter((qq) => {
      const ids = qq.lesson_question.skills.map((skill) => skill.name.trim())
      return ids.includes(skill.name.trim())
    }).length
  }

  const skillsColumns: ColumnType<CourseSkillType>[] = [
    {
      title: 'Nom',
      field: 'name',
      render: (row: CourseSkillType) => row.name,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Questions correspondantes',
      field: 'lesson_questions',
      render: (row: CourseSkillType) => row.lesson_questions.length,
      type: 'String',
      sortable: false,
    },
  ]

  const quizColumns: ColumnType<QuizType>[] = [
    {
      title: 'Nom',
      field: 'name',
      render: (row: QuizType) => {
        return (
          <Link href={`/formations/${course.code_name}/lessons/quiz/${row.lesson.id}`} target="_blank">
            {row.lesson.name}
          </Link>
        )
      },
      type: 'String',
      sortable: false,
    },
    {
      title: 'Emplacement',
      field: 'Emplacement',
      render: (row: QuizType) => getLocation(row),
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Nombre de questions',
      field: 'quiz_questions',
      render: (row: QuizType) => row.quiz_questions.length,
      type: 'String',
      sortable: false,
    },
  ]

  const evalColumns: ColumnType<CourseSkillType>[] = [
    {
      title: 'Nom',
      field: 'name',
      render: (row: CourseSkillType) => row.name,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Nombre de questions',
      field: 'quiz_questions',
      render: (row: CourseSkillType) =>
        getQuestionCount(row) < 2 ? (
          <Typography color="error">Moins de 2 questions</Typography>
        ) : (
          <Typography>{getQuestionCount(row)}</Typography>
        ),
      type: 'String',
      sortable: false,
    },
  ]

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Card elevation={3} style={{ margin: theme.spacing(1) }}>
          <CardContent>
            <Grid container justifyContent="space-between" style={{ marginBottom: theme.spacing(1) }}>
              <Grid item>
                <Typography variant="h5">Compétences</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={(e: React.MouseEvent) => {
                    handleClose()
                    if (e.metaKey || e.ctrlKey) {
                      window.open(`/formations/${course.code_name}/skills`, '_blank')
                    } else {
                      navigate(`/formations/${course.code_name}/skills`)
                    }
                  }}
                >
                  Modifier
                </Button>
              </Grid>
            </Grid>
            <Grid>
              <CustomTable columns={skillsColumns} rows={course.skills} size="small" />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3} style={{ margin: theme.spacing(1) }}>
          <CardContent>
            <Grid container justifyContent="space-between" style={{ marginBottom: theme.spacing(1) }}>
              <Grid item>
                <Typography variant="h5">Quiz</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={(e: React.MouseEvent) => {
                    handleClose()
                    if (e.metaKey || e.ctrlKey) {
                      window.open(`/formations/${course.code_name}/lessons/quiz`, '_blank')
                    } else {
                      navigate(`/formations/${course.code_name}/lessons/quiz`)
                    }
                  }}
                >
                  Modifier
                </Button>
              </Grid>
            </Grid>
            <Grid>
              <CustomTable<QuizType> columns={quizColumns} rows={course.quiz_lessons} size="small" />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3} style={{ margin: theme.spacing(1) }}>
          <CardContent>
            <Grid container justifyContent="space-between" style={{ marginBottom: theme.spacing(1) }}>
              <Grid item>
                <Typography variant="h5">Evaluation</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={(e: React.MouseEvent) => {
                    handleClose()
                    if (e.metaKey || e.ctrlKey) {
                      window.open(`/formations/${course.code_name}/lessons/quiz/${coursePlan.evaluation.id}`, '_blank')
                    } else {
                      navigate(`/formations/${course.code_name}/lessons/quiz/${coursePlan.evaluation.id}`)
                    }
                  }}
                >
                  Modifier
                </Button>
              </Grid>
            </Grid>
            <Grid>
              <CustomTable
                columns={evalColumns}
                rows={
                  coursePlan?.evaluation?.quiz?.quiz_questions.reduce((acc: CourseSkillType[], qq) => {
                    qq.lesson_question.skills.map((skill) => {
                      if (!acc.map((el) => el.name.trim()).includes(skill.name.trim())) {
                        acc.push(skill)
                      }
                    })
                    return acc
                  }, []) || []
                }
                size="small"
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

const Step5 = (
  _course: CourseType,
  theme: Theme,
  _classes: ClassNameMap,
  _navigate: NavigateFunction,
  _handleClose: () => void,
) => {
  return (
    <Grid
      item
      container
      alignItems="center"
      xs={12}
      style={{ background: alpha(theme.palette.info.main, 0.8), borderRadius: 5, padding: theme.spacing(1) }}
    >
      <Grid item style={{ marginRight: theme.spacing(1) }}>
        <MdOutlineInfo size="48" style={{ color: 'white' }} />
      </Grid>
      <Grid>
        <Typography variant="body1" style={{ color: 'white' }}>
          La publication d'une formation sur le LMS nécessite la publication préalable de la fiche produit de la
          formation.
        </Typography>
        <Typography variant="body1" style={{ color: 'white' }}>
          La publication se fera sur l'instance staging et production!
        </Typography>
      </Grid>
    </Grid>
  )
}

const CourseChecks = ({ course, handleClose, refetch }: CourseChecksPropsType) => {
  const classes = useStyles()
  const [setAlert] = useAlert()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement | null>(null)
  const { code_name } = useParams<{ code_name: string }>()
  const [publishCourse, { loading: publishLoading }] = useMutation<{ publishCourse: CourseType }>(
    PUBLISH_COURSE_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted(data) {
        setAlert({ severity: 'success', content: `${data.publishCourse.code_name} en cours de publication` })
        await refetch()
        handleClose()
      },
    },
  )

  const { data: { course: coursePlan } = {} } = useQuery<{
    course: CourseType
  }>(COURSE_PLAN_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: 'position',
        sort: 'ASC',
      },
    },
  })

  const { data: { course: courseQCM } = {} } = useQuery<{
    course: CourseType
  }>(COURSE_CHECK_STEP_4_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  const lessons = coursePlan?.parts
    .map((part) => {
      return part.sub_parts
        .map((sub_part) => {
          return sub_part.sub_parts
            .map((ss_part) => {
              return ss_part.lesson
            })
            .flat()
        })
        .flat()
    })
    .flat()

  const duplicates = lessons?.filter((l, index) => !l.epp && lessons.indexOf(l) !== index)

  const getStep = (activeStep: number, course: CourseType) => {
    switch (activeStep) {
      case 0:
        return Step0(course, theme, classes, navigate, handleClose)
      case 1:
        return Step1(course, theme, classes, navigate, handleClose)
      case 2:
        return Step2(course, theme, classes, navigate, handleClose)
      case 3:
        if (!coursePlan) return <></>
        return Step3(coursePlan, theme, classes, navigate, handleClose, duplicates)
      case 4:
        if (!courseQCM || !coursePlan) return <></>
        return Step4(courseQCM, theme, classes, navigate, handleClose, coursePlan)
      case 5:
        return Step5(course, theme, classes, navigate, handleClose)
      default:
        console.error('Unknown step')
        return <></>
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      style={{
        backgroundColor: theme.palette.background.default,
        flexGrow: 1,
        padding: theme.spacing(2),
      }}
      ref={ref}
    >
      <Grid item container xs={10} spacing={1}>
        <Grid item xs={12}>
          <Stepper
            activeStep={activeStep}
            style={{ backgroundColor: theme.palette.background.default, paddingRight: 0, paddingLeft: 0 }}
          >
            {steps.map((label, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Veuillez vérifier que les informations suivantes sont bien correctes avant de continuer
          </Typography>
        </Grid>

        {getStep(activeStep, course)}

        <Grid item container xs={12} justifyContent="flex-end" style={{ marginTop: theme.spacing(2) }}>
          <Button
            style={{ marginRight: theme.spacing(1) }}
            disabled={activeStep === 0}
            variant="outlined"
            color="primary"
            onClick={() => {
              const newStep = activeStep - 1
              setActiveStep(newStep)
            }}
          >
            Retour
          </Button>
          <Button
            variant="contained"
            style={{
              color: 'white',
              background:
                duplicates?.length !== 0 && activeStep === 3
                  ? theme.palette.action.disabled
                  : activeStep === steps.length - 1
                  ? theme.palette.success.main
                  : theme.palette.primary.main,
            }}
            disabled={duplicates?.length !== 0 && activeStep === 3}
            onClick={async () => {
              if (activeStep === steps.length - 1) {
                await publishCourse({ variables: { input: { id: course?.id } } })
              } else {
                const newStep = activeStep + 1
                setActiveStep(newStep)
                if (ref.current) {
                  ref.current.scrollIntoView({ behavior: 'auto', block: 'start' })
                }
              }
            }}
          >
            {activeStep === steps.length - 1 ? (
              publishLoading ? (
                <CircularProgress
                  variant="indeterminate"
                  size="1.5rem"
                  style={{ color: 'white' }}
                  id="upload_progress"
                />
              ) : course.published_on ? (
                'Republier'
              ) : (
                'Publier'
              )
            ) : (
              'Suivant'
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CourseChecks
