import { useState } from 'react'
//ICON
import { MdMoreVert } from 'react-icons/md'
//MATERIAL UI
import { Grid, Menu, MenuItem, Button, IconButton, useTheme } from '@material-ui/core'
//COMPONENTS
import { ImportSkillForm } from '../../components/Forms/course'
import { SkillForm } from '../../components/Forms/course'
import { DeleteForm } from '../../components/Forms'
import { CustomTable, Loading, Error, CustomModal, SearchBar } from '../../components'
// TYPE
import { CourseType, SortEnum, CourseSkillType } from '../../../../lib/sharedTypes'
import { ColumnType } from '../../utils/types'
// GQL
import { COURSE_COURSE_SKILLS_QUERY, COURSE_COURSE_SKILLS_SEARCH_QUERY } from '../../gql/queries'
import { DELETE_COURSE_SKILL_MUTATION } from '../../gql/mutations'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
//REACT-ROUTER
import { useParams, useOutletContext } from 'react-router'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../utils/misc'

const Skills = () => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [importModal, setImportModal] = useState<boolean>(false)

  const { refetchCourse } = useOutletContext<{ refetchCourse: () => Promise<any> }>()
  const { code_name } = useParams()
  const [field, setField] = useState<keyof CourseSkillType>('id')
  const [sort, setSort] = useState<keyof typeof SortEnum>('ASC')
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<CourseSkillType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [skillModal, setSkillModal] = useState(false)
  const [searching, setSearching] = useState<boolean>(false)

  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{ course: CourseType }>(COURSE_COURSE_SKILLS_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      take: take,
      skip: skip,
    },
    async onCompleted() {
      await refetchCourse()
    },
  })

  const [deleteCourseSkill] = useMutation<{ deleteCourseSkill: boolean }>(DELETE_COURSE_SKILL_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Compétence supprimée` })
      await refetch()
    },
  })

  const [searchSkillsQuery, { data: { course: courseSearch = null } = {} }] = useLazyQuery<{
    course: CourseType
  }>(COURSE_COURSE_SKILLS_SEARCH_QUERY)

  const searchSkills = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchSkillsQuery({
      variables: {
        where: {
          code_name: {
            eq: code_name,
          },
        },
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
      },
    })
  }

  const columns: ColumnType<CourseSkillType>[] = [
    {
      title: 'Nom de la compétence',
      field: 'name',
      render: (row: CourseSkillType) => row.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: CourseSkillType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: CourseSkillType) => row.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Nombre de questions',
      field: 'lesson_questions_count',
      render: (row: CourseSkillType) => row.lesson_questions.length,
      type: 'String',
      sortable: false,
    },
    {
      title: '',
      field: 'id',
      render: (row: CourseSkillType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (!code_name) {
    return <Error error={error} />
  }

  if (loading || !course) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column" justifyContent="flex-start" spacing={1}>
      <Grid item container justifyContent="flex-end">
        <Button
          color="secondary"
          variant="outlined"
          disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
          onClick={() => {
            setImportModal(true)
          }}
          style={{ marginRight: theme.spacing(1) }}
        >
          Importer
        </Button>
        <Button
          disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
          color="primary"
          variant="outlined"
          onClick={() => {
            setSelectedRow(undefined)
            setSkillModal(true)
          }}
        >
          Ajouter une compétence
        </Button>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <SearchBar placeholder="Rechercher une compétence" searchQuery={searchSkills} />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <CustomTable<CourseSkillType>
          columns={columns}
          rows={searching && courseSearch ? courseSearch.skills_search : course.skills}
          field={field}
          sort={sort}
          take={take}
          skip={skip}
          setTake={setTake}
          setSkip={setSkip}
          setSort={setSort}
          setField={setField}
          rowsCount={
            searching ? courseSearch?.skills_search.length : course.skills_search?.length ?? course.skills.length
          }
          onRowClick={(row: CourseSkillType) => {
            if (isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION'])) {
              setSelectedRow(row)
              setSkillModal(true)
            }
          }}
        />
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        <MenuItem
          disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer
        </MenuItem>
      </Menu>

      <CustomModal
        size="xs"
        title="Supprimer la compétence"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteCourseSkill({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
      <CustomModal
        open={skillModal}
        onClose={() => {
          setSkillModal(false)
        }}
        animation="slide-up"
      >
        <SkillForm
          skill={selectedRow}
          refetch={refetch}
          handleClose={() => {
            setSkillModal(false)
            setSelectedRow(undefined)
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Importer les compétences"
        open={importModal}
        onClose={() => {
          setImportModal(false)
        }}
      >
        <ImportSkillForm
          course={course}
          handleClose={() => {
            setImportModal(false)
          }}
          refetch={refetch}
        />
      </CustomModal>
    </Grid>
  )
}

export default Skills
