//REACT
import { useState } from 'react'
// MATERIAL UI
import {
  Grid,
  TextField,
  Typography,
  IconButton,
  useTheme,
  Divider,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  makeStyles,
} from '@material-ui/core'
//COMPONENTS
import QuestionVideoForm from './QuestionVideoForm'
import { CustomModal } from '../../'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'
//ICONS
import { MdOutlineDelete, MdOutlineAddCircleOutline } from 'react-icons/md'
// TYPE
import { DeepPartial } from '../../../utils/types'
import { MCItemType, LessonQuestionType, LessonVideoType } from '../../../../../lib/sharedTypes'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#f1f1f1',
    width: '100%',
    height: '100%',
    // background: 'rgba(0,0,0,0.25)',
    transition: '.5s ease',
    opacity: 0,
    textAlign: 'center',
    padding: 5,
    '&:hover': {
      opacity: 1,
    },
  },
  deleteBTN: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
}))

type MultiChoiceQuestionFormInputType = {
  stateQuestion: DeepPartial<LessonQuestionType> | undefined
  setStateQuestion: React.Dispatch<React.SetStateAction<DeepPartial<LessonQuestionType> | undefined>>
  onSelectPropositionImg: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const MultiChoiceQuestionForm = ({
  stateQuestion,
  setStateQuestion,
  onSelectPropositionImg,
}: MultiChoiceQuestionFormInputType) => {
  const [selectQuestionLessonVideo, setSelectQuestionLessonVideo] = useState(false)
  const [selectItemLessonVideo, setSelectItemLessonVideo] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const [selectedItem, setSelectedItem] = useState<DeepPartial<MCItemType> | undefined>()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()

  return (
    <Grid
      item
      xs={12}
      style={{
        border: `1px solid ${theme.palette.action.disabled}`,
        borderRadius: 5,
        margin: theme.spacing(1),
        padding: theme.spacing(2),
      }}
    >
      <Grid item xs={12}>
        <TextField
          required
          multiline
          maxRows={3}
          minRows={3}
          label="Intitulé de la question"
          name="mc_question.statement"
          defaultValue={stateQuestion?.mc_question?.statement}
          onChange={(e) => {
            e.preventDefault()
            setStateQuestion((prevState) => {
              return {
                ...prevState,
                mc_question: {
                  ...prevState?.mc_question,
                  statement: e.target.value,
                },
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
          }}
        >
          <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
            ou
          </Typography>
        </Grid>
        {stateQuestion?.question_video ? (
          <Grid container direction="row" style={{ position: 'relative' }}>
            <Grid item xs={12}>
              <video
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                src={`${stateQuestion.question_video.walter_media_url}?${new Date().getTime()}`}
                width="100%"
                height="300px"
                id="video"
                controls
              />
            </Grid>

            <IconButton
              color="primary"
              style={{ position: 'absolute', top: 1, right: 0 }}
              onClick={() => {
                setStateQuestion((prevState) => {
                  return {
                    ...prevState,
                    question_video: undefined,
                  }
                })
              }}
            >
              <MdOutlineDelete size={24} />
            </IconButton>
          </Grid>
        ) : (
          <Grid item xs={12} container justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              component="label"
              onClick={() => {
                setSelectQuestionLessonVideo(true)
              }}
            >
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Selectionner une leçon vidéo
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h6">Propositions</Typography>
        </Grid>
        <IconButton
          onClick={() => {
            const mc_items = stateQuestion?.mc_question?.mc_items || []
            mc_items.push({
              id: new Date().getTime(), //ensures unicity
              statement: '',
              correct: false,
              s3_image: '',
            } as MCItemType)

            setStateQuestion((prevState) => {
              return {
                ...prevState,
                mc_question: {
                  ...prevState?.mc_question,
                  mc_items: mc_items,
                },
              }
            })
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Grid>
      <Grid item container direction="column" spacing={2} alignItems="stretch" ref={animateRef}>
        {stateQuestion?.mc_question?.mc_items?.map((item, index) => {
          return (
            <Grid key={index} item container direction="column" alignItems="stretch">
              <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>{`Proposition ${index + 1}`}</Typography>
                </Grid>

                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setStateQuestion((prevState) => {
                        return {
                          ...prevState,
                          mc_question: {
                            ...prevState?.mc_question,
                            mc_items: prevState?.mc_question?.mc_items?.filter((r) => {
                              return r?.id !== item?.id
                            }),
                          },
                        }
                      })
                    }}
                  >
                    <MdOutlineDelete size={24} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid style={{ marginBottom: theme.spacing(2) }}>
                <Divider />
              </Grid>
              <Grid container direction="row">
                <Grid item xs={10}>
                  <Grid item xs={12} container direction="column" alignItems="stretch">
                    <Grid item xs={12}>
                      <TextField
                        required
                        label={`Proposition`}
                        name="mc_question.statement"
                        value={item?.statement}
                        onChange={(event) => {
                          const mc_items = stateQuestion.mc_question?.mc_items?.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                statement: event.target.value,
                              }
                            } else {
                              return item
                            }
                          })
                          setStateQuestion((prevState) => {
                            return {
                              ...prevState,
                              mc_question: {
                                ...prevState?.mc_question,
                                mc_items: mc_items,
                              },
                            }
                          })
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                        ou
                      </Typography>
                    </Grid>
                    <Grid item direction="row" container spacing={1} alignItems="center">
                      <Grid item container xs={12} justifyContent="center" spacing={1}>
                        <Grid item>
                          {!item?.s3_image && !item?.item_video && (
                            <Button variant="outlined" color="primary" component="label">
                              <Typography variant="body2" style={{ textAlign: 'center' }}>
                                Selectionner une image
                              </Typography>
                              <input
                                hidden
                                data-item-id={item?.id}
                                name="image"
                                accept=".jpg,.png,.jpeg"
                                type="file"
                                onChange={onSelectPropositionImg}
                              />
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          {!item?.s3_image && !item?.item_video && (
                            <Button
                              variant="outlined"
                              color="primary"
                              component="label"
                              onClick={() => {
                                setSelectedItem(item)
                                setSelectItemLessonVideo(true)
                              }}
                            >
                              <Typography variant="body2" style={{ textAlign: 'center' }}>
                                Selectionner une vidéo
                              </Typography>
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container item justifyContent="center">
                        {item?.item_video && (
                          <Grid container direction="row" style={{ position: 'relative' }}>
                            <Grid item xs={12}>
                              <video
                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                src={`${item.item_video.walter_media_url}?${new Date().getTime()}`}
                                width="100%"
                                height="300px"
                                id="video"
                                controls
                              />
                            </Grid>

                            <IconButton
                              color="primary"
                              style={{ position: 'absolute', top: 1, right: 0 }}
                              onClick={() => {
                                const mc_items = stateQuestion?.mc_question?.mc_items?.map((el) => {
                                  if (el?.id === item?.id) {
                                    return {
                                      ...el,
                                      item_video: undefined,
                                    }
                                  } else {
                                    return el
                                  }
                                })
                                setStateQuestion((prevState) => {
                                  return {
                                    ...prevState,
                                    mc_question: {
                                      ...prevState?.mc_question,
                                      mc_items: mc_items,
                                    },
                                  }
                                })
                              }}
                            >
                              <MdOutlineDelete size={24} />
                            </IconButton>
                          </Grid>
                        )}
                        {item?.s3_image && (
                          <Grid item className={classes.container}>
                            <Grid item className={classes.overlay}>
                              <Button
                                component="label"
                                variant="contained"
                                size="small"
                                color="primary"
                                data-item-id={item?.id}
                                style={{ marginBottom: '5px' }}
                              >
                                Changer
                                <input
                                  hidden
                                  data-item-id={item?.id}
                                  name="image"
                                  accept=".jpg,.png,.jpeg"
                                  type="file"
                                  onChange={onSelectPropositionImg}
                                />
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                className={classes.deleteBTN}
                                onClick={() => {
                                  const mc_items = stateQuestion.mc_question?.mc_items?.map((item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        s3_image: '',
                                      }
                                    } else {
                                      return item
                                    }
                                  })
                                  setStateQuestion((prevState) => {
                                    return {
                                      ...prevState,
                                      mc_question: {
                                        ...prevState?.mc_question,
                                        mc_items: mc_items,
                                      },
                                    }
                                  })
                                }}
                              >
                                Supprimer
                              </Button>
                            </Grid>
                            <img
                              src={item?.s3_image}
                              alt={item?.s3_image?.toString()}
                              loading="lazy"
                              style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container direction="row" xs={2} justifyContent="center" alignItems="center">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={item?.correct}
                          onChange={(event) => {
                            const mc_items = stateQuestion.mc_question?.mc_items?.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  correct: event.target.checked,
                                }
                              } else {
                                return item
                              }
                            })
                            setStateQuestion((prevState) => {
                              return {
                                ...prevState,
                                mc_question: {
                                  ...prevState?.mc_question,
                                  mc_items: mc_items,
                                },
                              }
                            })
                          }}
                          name="mc_item.correct"
                        />
                      }
                      label={item?.correct ? 'Vrai' : 'Faux'}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <CustomModal
        size="xs"
        title="Sélectionner une leçon vidéo"
        open={selectQuestionLessonVideo}
        onClose={() => {
          setSelectQuestionLessonVideo(false)
        }}
      >
        <QuestionVideoForm
          defaultValue={stateQuestion?.question_video}
          onSelectLesson={(selectedLessonVideo: LessonVideoType) => {
            setStateQuestion((prevState) => {
              return {
                ...prevState,
                question_video: selectedLessonVideo,
              }
            })
            setSelectQuestionLessonVideo(false)
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Sélectionner une leçon vidéo"
        open={selectItemLessonVideo}
        onClose={() => {
          setSelectItemLessonVideo(false)
        }}
      >
        <QuestionVideoForm
          defaultValue={selectedItem?.item_video}
          onSelectLesson={(selectedLessonVideo: LessonVideoType) => {
            const mc_items = stateQuestion?.mc_question?.mc_items?.map((item) => {
              if (item?.id === selectedItem?.id) {
                return {
                  ...item,
                  item_video: selectedLessonVideo,
                }
              } else {
                return item
              }
            })
            setStateQuestion((prevState) => {
              return {
                ...prevState,
                mc_question: {
                  ...prevState?.mc_question,
                  mc_items: mc_items,
                },
              }
            })
            setSelectedItem(undefined)
            setSelectItemLessonVideo(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}
export default MultiChoiceQuestionForm
