//COMPONENTS
import { Notification } from './index'
// MATERIAL UI
import { Button, Menu, Grid } from '@material-ui/core'
// TYPES
import { NotificationType } from '../../../../lib/sharedTypes'
//GQL
import { UPDATE_NOTIFICATIONS_MUTATION } from '../../gql/mutations'
import { useMutation } from '@apollo/client'

type MenuNotifsPropsType = {
  notifications: NotificationType[]
  refetch: () => Promise<any>
  anchorEl: HTMLElement | null
  open: boolean
  handleClose: () => void
  setIsModalOpen: (x: boolean) => void
}

const MenuNotifs = ({ anchorEl, open, handleClose, notifications, refetch, setIsModalOpen }: MenuNotifsPropsType) => {
  const [updateNotifications] = useMutation<{ notifications: NotificationType[] }>(UPDATE_NOTIFICATIONS_MUTATION)

  return (
    <Menu
      MenuListProps={{ disablePadding: true }}
      disableScrollLock={true}
      anchorEl={anchorEl}
      id="notifications-menu"
      open={open}
      PaperProps={{
        elevation: 0,
        style: {
          marginLeft: '-40px',
          overflow: 'visible',
          marginTop: '40px',
          padding: 0,
        },
      }}
      onClose={handleClose}
    >
      <Grid>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={async () => {
            handleClose()
            if (notifications.filter((notif) => notif.read_at === null)?.length !== 0) {
              await updateNotifications({
                variables: {
                  input: {
                    ids: notifications.filter((notif) => notif.read_at === null).map((notif) => notif.id),
                    read_at: new Date(),
                  },
                },
              })
              await refetch()
            }
          }}
        >
          Tout marquer comme lu
        </Button>
        <Grid style={{ maxHeight: 400, maxWidth: 350, overflow: 'auto' }}>
          {notifications.map((notification) => {
            return (
              <Notification
                key={notification.id}
                notification={notification}
                refetch={refetch}
                handleClose={handleClose}
                type="small"
              />
            )
          })}
        </Grid>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => {
            handleClose()
            setIsModalOpen(true)
          }}
        >
          Voir toutes les notifications
        </Button>
      </Grid>
    </Menu>
  )
}

export default MenuNotifs
