//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid } from '@material-ui/core'
//COMPONENTS
import { TextEditor } from '../../index'
import { FormLayout } from '../index'
//TYPES
import { ProductFaqType, ProductType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type FaqFormPropsType = {
  productFaq?: ProductFaqType
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const FaqForm = ({ productFaq, product, refetch, handleClose }: FaqFormPropsType) => {
  const [stateProduct, _] = useState<ProductType>(product)
  const [stateFaq, setStateFaq] = useState<DeepPartial<ProductFaqType> | undefined>(productFaq)
  const [setAlert] = useAlert()
  const [updateProduct] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let seo_faq = stateProduct.seo_faq.map((qa) => {
      return { id: qa.id, question: qa.question, answer: qa.answer }
    })

    if (productFaq?.id) {
      seo_faq = seo_faq.filter((qa) => qa.id !== productFaq.id)
    }

    const productInput = {
      id: stateProduct.id,
      seo_faq: [...seo_faq, { id: stateFaq?.id, question: stateFaq?.question, answer: stateFaq?.answer }],
    }
    await updateProduct({ variables: { input: productInput } })
  }

  return (
    <FormLayout
      type={stateFaq?.id ? 'update' : 'create'}
      title={stateFaq?.id ? 'Mettre à jour la question/réponse' : 'Créer une question/réponse'}
      onSubmit={handleSubmit}
      submitDisabled={stateFaq?.id ? stateFaq === productFaq : stateFaq === undefined}
    >
      <Grid item>
        <TextField
          fullWidth
          id="question"
          label="Question"
          variant="outlined"
          defaultValue={productFaq?.question || ''}
          onChange={(event) => {
            setStateFaq((prevState) => {
              return {
                ...prevState,
                [event.target.id]: event.target.value,
              }
            })
          }}
          required
        />
      </Grid>
      <Grid item>
        <TextEditor
          text={productFaq?.answer}
          onChange={(json) => {
            setStateFaq((prevState) => {
              return {
                ...prevState,
                answer: json,
              }
            })
          }}
        />
      </Grid>
    </FormLayout>
  )
}

export default FaqForm
