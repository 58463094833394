import { useState } from 'react'
//COMPONENTS
import { DeleteForm } from '../../components/Forms'
import { ShootingForm, TeamForm } from '../../components/Forms/project'
import { CustomTable, CustomModal, Error, Loading } from '../../components'
//ICON
import { MdMoreVert } from 'react-icons/md'
// MATERIAL UI
import { Grid, IconButton, Menu, MenuItem, Typography, useTheme, Button, Divider, Paper } from '@material-ui/core'
//GQL
import { PROJECT_SHOOTINGS_QUERY } from '../../gql/queries'
import { DELETE_SHOOTING_MUTATION, UPDATE_PROJECT_MUTATION } from '../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
// TYPES
import { ProjectType, SortEnum, ShootingType, UserType } from '../../../../lib/sharedTypes'
import { ColumnType } from '../../utils/types'
//ROUTER
import { useParams } from 'react-router'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../utils/misc'

const TeamAndShooting = () => {
  const { code_name } = useParams()
  const [setAlert] = useAlert()
  const theme = useTheme()
  const [teamModal, setTeamModal] = useState(false)
  const [teamAnchorEl, setTeamAnchorEl] = useState<null | HTMLElement>(null)
  const teamMenuOpen = Boolean(teamAnchorEl)
  const [teamSelectedRow, setTeamSelectedRow] = useState<UserType | undefined>(undefined)
  const [teamDeleteModal, setTeamDeleteModal] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<ShootingType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [shootingModal, setShootingModal] = useState(false)
  const [sort, setSort] = useState<keyof typeof SortEnum>('ASC')
  const [field, setField] = useState<keyof ShootingType>('starts_at')
  const [deleteShooting] = useMutation<{
    deleteShooting: boolean
  }>(DELETE_SHOOTING_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Tournage supprimé` })
    },
  })
  const {
    loading,
    error,
    data: { project } = {},
    refetch,
  } = useQuery<{
    project: ProjectType
  }>(PROJECT_SHOOTINGS_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  const [updateProject] = useMutation<{
    updateProject: ProjectType
  }>(UPDATE_PROJECT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Utilisateur supprimé du projet` })
      await refetch()
    },
  })

  const columns: ColumnType<ShootingType>[] = [
    {
      title: 'Début',
      field: 'starts_at',
      render: (row: ShootingType) =>
        new Date(row.starts_at).toLocaleDateString('fr', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
      type: 'String',
      sortable: false,
    },
    {
      title: 'Fin',
      field: 'ends_at',
      render: (row: ShootingType) =>
        new Date(row.ends_at).toLocaleDateString('fr', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
      type: 'String',
      sortable: false,
    },
    {
      title: 'Adresse',
      field: 'address',
      render: (row: ShootingType) => row.address,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Equipe',
      field: 'crew',
      render: (row: ShootingType) => row.crew,
      type: 'Avatars',
      sortable: false,
    },
    {
      title: 'Enseignants',
      field: 'teachers',
      render: (row: ShootingType) => row.teachers,
      type: 'Avatars',
      sortable: false,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: ShootingType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: ShootingType) => row.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: ShootingType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  const membersColumns: ColumnType<UserType>[] = [
    {
      title: 'Prénom',
      field: 'first_name',
      render: (row: UserType) => row.first_name,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Nom',
      field: 'last_name',
      render: (row: UserType) => row.last_name,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Job',
      field: 'job.category',
      render: (row: UserType) => row.job.category,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: UserType) => row.created_at,
      type: 'Date',
      sortable: false,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: UserType) => row.updated_at,
      type: 'Date',
      sortable: false,
    },
    {
      title: '',
      field: 'id',
      render: (row: UserType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setTeamAnchorEl(event.currentTarget)
            setTeamSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (error || !code_name) {
    return <Error error={error} />
  }

  if (loading || !project) {
    return <Loading />
  }

  return (
    <Grid container direction="column" spacing={1}>
      {/* MEMBERS */}
      <Paper style={{ padding: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Equipe</Typography>
            <Button
              color="primary"
              variant="outlined"
              disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) || isCourseManager(project.course))}
              onClick={() => {
                setTeamSelectedRow(undefined)
                setTeamModal(true)
              }}
            >
              Ajouter
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item>
            <CustomTable
              columns={membersColumns}
              rows={project?.members}
              field={'job.category' as unknown as keyof UserType}
              sort={'ASC'}
              rowsCount={project?.members?.length}
            />
          </Grid>
        </Grid>
      </Paper>
      {/* SHOOTINGS */}
      <Paper style={{ marginTop: theme.spacing(2), padding: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Tournages</Typography>
            <Button
              disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) || isCourseManager(project.course))}
              color="primary"
              variant="outlined"
              onClick={() => {
                setSelectedRow(undefined)
                setShootingModal(true)
              }}
            >
              Ajouter
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item>
            <CustomTable
              columns={columns}
              rows={project?.shootings}
              field={field}
              sort={sort}
              setSort={setSort}
              setField={setField}
              rowsCount={project?.shootings?.length}
            />
          </Grid>
        </Grid>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setShootingModal(true)
            setAnchorEl(null)
          }}
        >
          Modifier le tournage
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer le tournage
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={teamAnchorEl}
        open={teamMenuOpen}
        onClose={() => {
          setTeamAnchorEl(null)
          setTeamSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setTeamDeleteModal(true)
            setTeamAnchorEl(null)
          }}
        >
          Supprimer le membre
        </MenuItem>
      </Menu>

      <CustomModal
        open={shootingModal}
        onClose={() => {
          setShootingModal(false)
        }}
        animation="slide-up"
      >
        <ShootingForm shooting={selectedRow} refetch={refetch} handleClose={() => setShootingModal(false)} />
      </CustomModal>
      <CustomModal
        open={teamModal}
        onClose={() => {
          setTeamModal(false)
        }}
        animation="slide-up"
      >
        <TeamForm
          project={JSON.parse(JSON.stringify(project)) as ProjectType}
          refetch={refetch}
          handleClose={() => setTeamModal(false)}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer le tournage"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteShooting({ variables: { input: { id: selectedRow?.id } } })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer le membre de l'équipe"
        open={teamDeleteModal}
        onClose={() => {
          setTeamDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            const projectInput = {
              id: project.id,
              course: { id: project.course.id },
              members: project?.members
                ?.filter((member) => member.id !== teamSelectedRow?.id)
                .map((member) => {
                  return { id: member?.id }
                }),
            }

            await updateProject({ variables: { input: projectInput } })
            setTeamDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setTeamSelectedRow(undefined)
            setTeamDeleteModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}
export default TeamAndShooting
