//REACT
import { useState } from 'react'
//COMPONENTS
import { LessonTextForm } from '../../../components/Forms/lesson'
import { LessonInfo, LessonRessources } from '../../../components/Lesson'
import { Layout, Title, Error, Loading, TextEditorJS, CustomModal } from '../../../components'
// MATERIAL UI
import { Grid, Typography, Paper, useTheme, makeStyles, Button, Divider } from '@material-ui/core'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
// TYPES
import { LessonType } from '../../../../../lib/sharedTypes'
// GRAPHQL
import { useQuery } from '@apollo/client'
import { LESSON_TEXT_QUERY } from '../../../gql/queries'
//UTILS
import { isAuthorized, isCourseManager } from '../../../utils/misc'
// CONVERT QUILL DELTA
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonText = () => {
  const { code_name, lesson_id } = useParams()
  const theme = useTheme()
  const [updateTextModal, setUpdateTextModal] = useState(false)
  const classes = useStyles()
  if (!lesson_id) {
    return (
      <Layout title="Leçon texte">
        <Error error="lesson_id inconnu" />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Leçon texte">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  const {
    loading,
    error,
    data: { lesson } = {},
    refetch,
  } = useQuery<{
    lesson: LessonType
  }>(LESSON_TEXT_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(lesson_id?.toString()),
        },
      },
    },
  })

  if (loading || !lesson) {
    return (
      <Layout title="Leçon texte">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Leçon texte">
        <Error error={error} />
      </Layout>
    )
  }

  let html = ''
  if ((lesson?.text?.content as Record<string, any>)?.ops) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const converter = new QuillDeltaToHtmlConverter((lesson?.text?.content as Record<string, any>)?.ops, {})
    html = converter.convert()
  }

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item>
          <Title title={code_name} />
        </Grid>
        {/* LESSON */}
        <LessonInfo
          lesson={lesson}
          refetch={refetch}
          hideSummary={true}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        {/* TEXT */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Texte</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateTextModal(true)
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Contenu
                  </Typography>
                </Grid>
                <Grid
                  item
                  key={new Date(lesson.text.updated_at).getTime()}
                  style={{ maxHeight: 350, overflow: 'scroll' }}
                >
                  <TextEditorJS
                    content={lesson?.text?.content}
                    isReadOnly={true}
                    holderId={`lesson-text-${lesson.id}`}
                    legacy={html}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* RESSOURCES */}
        <LessonRessources
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
      </Grid>
      <CustomModal
        open={updateTextModal}
        onClose={() => {
          setUpdateTextModal(false)
        }}
        animation="slide-up"
      >
        <LessonTextForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setUpdateTextModal(false)
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default LessonText
