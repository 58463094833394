import React, { useEffect, useState } from 'react'
//COMPONENTS
import { SetForm, TeaserEditorForm } from '../../components/Forms/project'
import { GaugeSetVideos } from '../../components/Project'
import { DeleteForm } from '../../components/Forms'
import { UserAvatar, CustomModal, Loading, Error } from '../../components'
//ICONS
import { ImVimeo2 } from 'react-icons/im'
import { FiTarget } from 'react-icons/fi'
import { FcInfo } from 'react-icons/fc'
import { MdMoreVert, MdLocalMovies } from 'react-icons/md'
// TYPE
import { ProjectType, VideoStateEnum, VideoSetType, UserType } from '../../../../lib/sharedTypes'
// MATERIAL UI
import { Autocomplete } from '@material-ui/lab'
import {
  makeStyles,
  useTheme,
  Chip,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Grid,
  TableContainer,
  Table,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Typography,
  Card,
  CardContent,
  TextField,
} from '@material-ui/core'
//HOOKS
import { useAlert } from '../../hooks'
//GQL
import { PROJECT_SETS_QUERY } from '../../gql/queries'
import { DELETE_VIDEOSET_MUTATION, UPLOAD_ALL_LESSON_VIDEO_TO_VIMEO } from '../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
//UTILS
import { handleError } from '../../utils/handleError'
import { secondsToDHMS } from '../../utils/misc'
import { videosCaptions } from '../../utils/constants'
import { useAppSelector } from '../../hooks/reducerHooks'
//ROUTER
import { useNavigate, useParams } from 'react-router'
//UTILS
import { isAuthorized, isCourseManager } from '../../utils/misc'

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[700],
    },
  },
}))

const Sets = () => {
  const user = useAppSelector((state) => state.user.user)
  const [videoTeaserEditorModal, setVideoTeaserEditorModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<UserType[]>()
  const [editors, setEditors] = useState<UserType[]>()
  const { code_name } = useParams()
  const [setAlert] = useAlert()
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [videoSetFormModal, setVideoSetFormModal] = useState(false)
  const [deleteVideoSetModal, setDeleteVideoSetModal] = useState(false)
  const [selectedVideoSet, setSelectedVideoSet] = useState<VideoSetType | undefined>(undefined)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const {
    loading,
    error,
    data: { project } = {},
    refetch,
  } = useQuery<{
    project: ProjectType
  }>(PROJECT_SETS_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  useEffect(() => {
    const monteurs = project?.video_sets.reduce((acc: UserType[], videoSet) => {
      if (videoSet.editor && !acc.map((el) => el.id).includes(videoSet.editor.id)) {
        acc.push(videoSet.editor)
      }
      return acc
    }, [])
    setEditors(monteurs)
  }, [project])

  useEffect(() => {
    if (user && editors) {
      const users = editors?.map((e) => e.id.toString()).includes(user?.id.toString()) ? [user] : editors
      setSelectedUsers(users)
    }
  }, [user, editors])

  const [deleteVideoSetMutation] = useMutation<{ deleteVideoSet: boolean }>(DELETE_VIDEOSET_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `VideoSet supprimé` })
    },
  })

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedVideoSet(undefined)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, videoSet: VideoSetType) => {
    setAnchorEl(event.currentTarget)
    setSelectedVideoSet(videoSet)
  }

  const [uploadAllLessonVideoToVimeo] = useMutation<{
    uploadAllLessonVideoToVimeo: boolean
  }>(UPLOAD_ALL_LESSON_VIDEO_TO_VIMEO, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Toutes les vidéos sont en cours de mise-en-ligne sur Viméo` })
    },
  })

  const totalDuration =
    project?.video_sets.reduce((acc: number, el) => {
      const videoSetDuration = el?.lesson_videos?.reduce((acc2, video) => {
        acc2 += video.file_duration
        return acc2
      }, 0)
      acc += videoSetDuration
      return acc
    }, 0) || 0

  const totalPublishedDuration =
    project?.video_sets.reduce((acc: number, el) => {
      const videoSetDuration = el?.lesson_videos?.reduce((acc2, video) => {
        if (video.vimeo_id) {
          acc2 += video.file_duration
        }
        return acc2
      }, 0)
      acc += videoSetDuration
      return acc
    }, 0) || 0

  const totalValidatedDuration =
    project?.video_sets.reduce((acc: number, el) => {
      const videoSetDuration = el?.lesson_videos?.reduce((acc2, video) => {
        if (video.vimeo_id || video.review_state === 'VALIDATED') {
          acc2 += video.file_duration
        }
        return acc2
      }, 0)
      acc += videoSetDuration
      return acc
    }, 0) || 0

  if (error || !code_name) {
    return <Error error={error} />
  }

  if (loading || !project) {
    return <Loading />
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}
      >
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Grid container direction="row" alignItems="center" spacing={1}>
                {(Object.keys(videosCaptions) as Array<keyof typeof VideoStateEnum>).map((status) => {
                  return (
                    <Grid item key={status}>
                      <Chip
                        label={videosCaptions[status].label}
                        style={{ backgroundColor: videosCaptions[status].color }}
                        color="primary"
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid item>
              <FcInfo
                onClick={() => {
                  setOpen(true)
                }}
                size="20"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                style={{ width: 180 }}
                variant="outlined"
                color="primary"
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) || isCourseManager(project.course))}
                startIcon={<ImVimeo2 />}
                onClick={async () => {
                  if (!project) return
                  await uploadAllLessonVideoToVimeo({
                    variables: { input: { id: project.id } },
                  })
                }}
              >
                {totalPublishedDuration === totalDuration ? 'Tout Republier' : 'Tout Publier'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={
                  !(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR', 'EDITOR']) || isCourseManager(project.course))
                }
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSelectedVideoSet(undefined)
                  setVideoSetFormModal(true)
                }}
              >
                Ajouter un set
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: theme.spacing(1) }}>
            <Grid item>
              <Typography variant="h5">Avancement</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Card>
                  <CardContent style={{ padding: theme.spacing(1) }}>
                    <Grid container>
                      <Grid item>
                        <Typography variant="h6">Durée de la formation</Typography>
                        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                          {project && secondsToDHMS(project.duration_target, false)}
                        </Typography>
                        <Typography variant="body2">
                          {project?.video_sets.filter((vs) => vs?.lesson_videos?.length !== 0)?.length}{' '}
                          <Typography component="span" variant="body2" style={{ fontWeight: 'bold' }}>
                            / {project?.video_sets?.length} sets
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item style={{ flexGrow: 1, display: 'flex' }}>
                        <Grid container alignItems="center" justifyContent="flex-end">
                          <Grid item>
                            <FiTarget size="70" style={{ color: theme.palette.text.secondary }} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent style={{ padding: theme.spacing(1) }}>
                    <Grid container>
                      <Grid item>
                        <Typography variant="h6">Total actuel</Typography>
                        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                          {totalDuration < 60 ? secondsToDHMS(totalDuration) : secondsToDHMS(totalDuration, false)}
                        </Typography>
                        <Typography variant="body2">{`dont ${
                          totalPublishedDuration < 60
                            ? secondsToDHMS(totalPublishedDuration)
                            : secondsToDHMS(totalPublishedDuration, false)
                        } en ligne et ${
                          totalValidatedDuration < 60
                            ? secondsToDHMS(totalValidatedDuration)
                            : secondsToDHMS(totalValidatedDuration, false)
                        } validées`}</Typography>
                      </Grid>
                      <Grid item style={{ flexGrow: 1, display: 'flex' }}>
                        <Grid container alignItems="center" justifyContent="flex-end">
                          <Grid item>
                            <MdLocalMovies size="70" style={{ color: theme.palette.text.secondary }} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: theme.spacing(1) }}>
        {user && editors && (
          <Autocomplete
            multiple
            limitTags={2}
            defaultValue={editors?.map((e) => e.id.toString()).includes(user?.id.toString()) ? [user] : editors}
            options={editors}
            getOptionSelected={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.username || ''}
            onChange={(_, value) => {
              if (value) {
                setSelectedUsers(value)
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" label="Monteur(s)" />
            }}
          />
        )}
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          <Table>
            <TableBody component="tbody">
              {project?.course?.product && (
                <TableRow
                  className={classes.row}
                  style={{ height: 66 }}
                  onClick={(e: React.MouseEvent) => {
                    if (project?.course?.product) {
                      if (e.metaKey || e.ctrlKey) {
                        window.open(`/projects/${project?.course?.product?.code_name}/teaser`, '_blank')
                      } else {
                        navigate(`/projects/${project?.course?.product?.code_name}/teaser`)
                      }
                    }
                  }}
                >
                  <TableCell align="left" colSpan={2} style={{ padding: theme.spacing(0.5) }}>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      Bande-annonce
                    </Typography>
                  </TableCell>
                  <TableCell align="left" width="80%" style={{ height: '100%', padding: theme.spacing(0.5) }}>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item style={{ height: '100%' }}>
                        <Typography variant="body2">
                          {project?.course?.product?.teaser_vimeo_url ? (
                            <IconButton
                              onClick={() => {
                                window.open(project?.course?.product?.teaser_vimeo_url, '_blank')
                              }}
                            >
                              <ImVimeo2 size="30" color="#3E83F5" />
                            </IconButton>
                          ) : (
                            <ImVimeo2 size="30" color="#e0e0e0" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item style={{ height: '100%' }}>
                        <Chip
                          label={videosCaptions[project?.course?.product?.teaser_review_state].label}
                          style={{
                            backgroundColor: videosCaptions[project?.course?.product?.teaser_review_state].color,
                            color: 'white',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell style={{ padding: theme.spacing(0.5) }}>
                    {project?.course?.product.teaser_uploader && (
                      <UserAvatar user={project?.course?.product.teaser_uploader} />
                    )}
                  </TableCell>
                  <TableCell style={{ padding: theme.spacing(0.5) }}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        setVideoTeaserEditorModal(true)
                      }}
                    >
                      <MdMoreVert size="20" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )}
              {project?.video_sets
                .filter((videoSet) => {
                  return (
                    videoSet.editor === null ||
                    selectedUsers?.map((user) => user.id.toString()).includes(videoSet.editor.id.toString())
                  )
                })
                .sort((a, b) => a.index - b.index)
                .map((videoSet, index) => {
                  const lessonsTotalDuration = videoSet.lesson_videos.reduce((acc, el) => {
                    acc += el.file_duration
                    return acc
                  }, 0)
                  const duration = secondsToDHMS(lessonsTotalDuration, false)
                  return (
                    <TableRow
                      key={index}
                      className={classes.row}
                      onClick={(e: React.MouseEvent) => {
                        if (e.metaKey || e.ctrlKey) {
                          window.open(`/projects/${code_name}/sets/${videoSet.index}`, '_blank')
                        } else {
                          navigate(`/projects/${code_name}/sets/${videoSet.index}`)
                        }
                      }}
                    >
                      <TableCell align="left" width="10%" style={{ padding: theme.spacing(0.5) }}>
                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                          VideoSet {videoSet.index}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" width="10%" style={{ padding: theme.spacing(0.5) }}>
                        <Typography variant="body2" color="textPrimary">
                          {duration ? duration : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" width="80%" style={{ height: '100%', padding: theme.spacing(0.5) }}>
                        <GaugeSetVideos videoSet={videoSet} />
                      </TableCell>
                      <TableCell style={{ padding: theme.spacing(0.5) }}>
                        {videoSet.editor && <UserAvatar user={videoSet.editor} />}
                      </TableCell>
                      <TableCell style={{ padding: theme.spacing(0.5) }}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            setSelectedVideoSet(videoSet)
                            handleClick(e, videoSet)
                          }}
                        >
                          <MdMoreVert size="20" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose}>
        <MenuItem
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) || isCourseManager(project.course))}
          onClick={() => {
            setVideoSetFormModal(true)
            setAnchorEl(null)
          }}
        >
          Modifier le set
        </MenuItem>
        <MenuItem
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) || isCourseManager(project.course))}
          onClick={() => {
            setDeleteVideoSetModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer le set
        </MenuItem>
      </Menu>
      <CustomModal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        title="Liste des statuts"
      >
        <Grid container item>
          <Grid container item justifyContent="space-around" spacing={1}>
            {(Object.keys(videosCaptions) as Array<keyof typeof VideoStateEnum>).map((status, index) => {
              return (
                <Grid container item direction="row" alignItems="center" key={index}>
                  <Grid item xs={2}>
                    <Chip
                      label={videosCaptions[status].label}
                      style={{ backgroundColor: videosCaptions[status].color }}
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>{videosCaptions[status].description}</Typography>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </CustomModal>
      <CustomModal
        open={videoSetFormModal}
        onClose={() => {
          setVideoSetFormModal(false)
        }}
        animation="slide-up"
      >
        <SetForm
          video_set={selectedVideoSet}
          project_code_name={code_name}
          refetch={refetch}
          setVideoSetFormModal={setVideoSetFormModal}
        />
      </CustomModal>
      <CustomModal
        open={videoTeaserEditorModal}
        onClose={() => {
          setVideoTeaserEditorModal(false)
        }}
        animation="slide-up"
      >
        <TeaserEditorForm
          setVideoTeaserEditorModal={setVideoTeaserEditorModal}
          product={project.course.product}
          refetch={refetch}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer le set"
        open={deleteVideoSetModal}
        onClose={() => {
          setDeleteVideoSetModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteVideoSetMutation({ variables: { input: { id: selectedVideoSet?.id } } })
            setDeleteVideoSetModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedVideoSet(undefined)
            setDeleteVideoSetModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default Sets
