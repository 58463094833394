// REACT-HOOKS
import { FC, useRef, useState } from 'react'
// MATERIAL UI
import {
  Divider,
  Grid,
  Paper,
  useTheme,
  Typography,
  alpha,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Chip,
} from '@material-ui/core'
//COMPONENTS
import { CustomModal } from '../index'
import { QuestionForm } from '../Forms/course'
// DND
import { useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
// ICON
import { MdCheckCircle, MdDragIndicator } from 'react-icons/md'
// TYPES
import { CourseType, QuizQuestionType } from '../../../../lib/sharedTypes'
import { TextEditorJS } from '../../components'

type CardProps = ItemType & { removeCard: (index: number) => void }

type ItemType = {
  qq_question: QuizQuestionType
  course: CourseType
  refetch: () => Promise<any>
  index: number
  moveCard: (dragIndex: number, hoverIndex: number, idTarget: Identifier | null, idItem: Identifier | null) => void
  onPointsChange: (points: number) => void
}

const QuestionCard: FC<CardProps> = ({
  qq_question,
  index,
  moveCard,
  removeCard,
  onPointsChange,
  course,
  refetch,
}: CardProps) => {
  const theme = useTheme()
  const [questionModal, setQuestionModal] = useState(false)
  const lessonQuestionType = () => {
    if (qq_question.lesson_question.mc_question) {
      const correctCount = qq_question.lesson_question?.mc_question?.mc_items.filter(
        (mc_item) => mc_item.correct,
      ).length
      if (correctCount > 1) {
        return 'Choix multiples'
      } else {
        return 'Choix unique'
      }
    }
    if (qq_question.lesson_question.gapfill_question) {
      return 'Texte à trous'
    }
    if (qq_question.lesson_question.ranking_question) {
      return 'Classement'
    }
    return 'Type de question inconnu'
  }
  const ref = useRef<HTMLDivElement>(null)
  const [questionPoints, setQuestionPoints] = useState<number>(qq_question.points)
  const [{ handlerId }, drop] = useDrop<ItemType, void, { handlerId: Identifier | null }>({
    accept: ['question_card', 'question_card_short'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: ItemType, monitor) {
      if (monitor.getItemType() === 'question_card_short') return
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex, 'question_card', monitor.getItemType())

      item.index = hoverIndex
    },
    drop(item: ItemType, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex && monitor.getItemType() === 'question_card') {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      const fixIndex = monitor.getItemType() === 'question_card_short' ? (hoverClientY > hoverMiddleY ? 1 : 0) : 0
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && monitor.getItemType() !== 'question_card_short') {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && monitor.getItemType() !== 'question_card_short') {
        return
      }
      moveCard(dragIndex, hoverIndex + fixIndex, 'question_card', monitor.getItemType())

      item.index = hoverIndex
    },
  })
  const id = qq_question.id
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'question_card',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  preview(drop(ref))

  return (
    <Grid item xs={12} ref={ref} data-handler-id={handlerId}>
      <Paper elevation={3} style={{ borderRadius: '10px', opacity: isDragging ? 0 : 1 }}>
        <Grid container direction="row">
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            style={{
              width: '2%',
              borderRadius: '10px 0px 0px 10px',
              cursor: 'grab',
              background: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
            }}
            ref={drag}
          >
            <Grid item>
              <MdDragIndicator />
            </Grid>
          </Grid>
          <Grid item style={{ width: '98%', paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Grid item container justifyContent="space-between" direction="row" style={{ padding: theme.spacing(1) }}>
              <Grid item xs={10}>
                <Typography
                  component={Link}
                  variant="caption"
                  target="_blank"
                  style={{ cursor: 'pointer' }}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    e.stopPropagation()
                    // setSelectedRow(question)
                    setQuestionModal(true)
                  }}
                >
                  Question {qq_question.position + 1} - {lessonQuestionType()}
                </Typography>
              </Grid>
              <Grid item container xs={2} justifyContent="flex-end" alignItems="center">
                <InputLabel style={{ paddingRight: theme.spacing(1) }}>Point(s)</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={questionPoints}
                  label="Points"
                  onChange={(e) => {
                    setQuestionPoints(e.target.value as number)
                    onPointsChange(e.target.value as number)
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Typography variant="h6">
              {qq_question?.lesson_question?.mc_question?.statement ||
                qq_question?.lesson_question?.ranking_question?.statement}
            </Typography>
            {qq_question.lesson_question?.question_video && (
              <video
                src={`${qq_question.lesson_question?.question_video.walter_media_url}?${new Date().getTime()}`}
                height="200px"
                id="video"
                controls
              />
            )}

            <Divider />
            <Grid container direction="row" style={{ display: 'flex' }} wrap="nowrap">
              <Grid container direction="column" style={{ flexGrow: 1, display: 'flex' }}>
                {qq_question.lesson_question.gapfill_question && qq_question.lesson_question.gapfill_question.id && (
                  <TextEditorJS
                    holderId={`question_card_dnd_${qq_question.lesson_question.gapfill_question.id}`}
                    isReadOnly={true}
                    type="gapfill"
                    legacy={qq_question.lesson_question.gapfill_question.text}
                  />
                )}
                {qq_question.lesson_question.ranking_question &&
                  qq_question?.lesson_question?.ranking_question?.ranking_items
                    .sort((a, b) => a.correct_position - b.correct_position)
                    .map((ranking_item) => {
                      return (
                        <Grid
                          item
                          container
                          key={ranking_item.id}
                          alignItems="center"
                          justifyContent="space-between"
                          style={{
                            minHeight: 50,
                            padding: theme.spacing(1),
                          }}
                        >
                          <Grid item>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                              <Chip
                                size="small"
                                label={ranking_item.correct_position}
                                style={{
                                  minWidth: '24px',
                                  color: 'white',
                                  marginRight: theme.spacing(1),
                                  background: theme.palette.success.main,
                                }}
                              />
                              {ranking_item.statement}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="caption"
                              style={{ marginRight: theme.spacing(1), color: theme.palette.text.disabled }}
                            >
                              Position affichée
                            </Typography>
                            <Chip
                              size="small"
                              label={ranking_item.displayed_position}
                              color="primary"
                              style={{ color: 'white', background: theme.palette.warning.main, minWidth: '24px' }}
                            />
                          </Grid>
                        </Grid>
                      )
                    })}
                {qq_question?.lesson_question?.mc_question &&
                  qq_question.lesson_question.mc_question.mc_items.map((mc_item) => {
                    return (
                      <Grid
                        item
                        container
                        key={mc_item.id}
                        alignItems="center"
                        justifyContent="space-between"
                        style={{
                          minHeight: 50,
                          padding: theme.spacing(1),
                          borderLeft: mc_item.correct ? '2px solid green' : 'none',
                          backgroundColor: mc_item.correct ? alpha(theme.palette.success.main, 0.2) : 'none',
                        }}
                      >
                        <Grid item>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            {mc_item.statement}
                          </Typography>
                        </Grid>
                        <Grid>
                          <img
                            src={mc_item?.s3_image}
                            alt={mc_item?.s3_image?.toString()}
                            loading="lazy"
                            style={{
                              maxWidth: '250px',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          {mc_item.correct && <MdCheckCircle size="22" color={theme.palette.success.main} />}
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
              <Grid item style={{ display: 'flex' }}>
                {qq_question?.lesson_question?.s3_image && (
                  <img
                    src={qq_question?.lesson_question?.s3_image}
                    style={{ display: 'block', objectFit: 'cover', maxHeight: '100%', maxWidth: '150px' }}
                  />
                )}
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              justifyContent="flex-end"
              style={{ padding: theme.spacing(1) }}
              onClick={() => removeCard(index)}
            >
              <Typography variant="body1" style={{ cursor: 'pointer', color: theme.palette.info.main }}>
                Retirer du quiz
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <CustomModal
        open={questionModal}
        onClose={() => {
          setQuestionModal(false)
        }}
        animation="slide-up"
      >
        <QuestionForm
          handleClose={() => {
            setQuestionModal(false)
          }}
          question={qq_question.lesson_question}
          course={course}
          refetch={refetch}
        />
      </CustomModal>
    </Grid>
  )
}

export default QuestionCard
