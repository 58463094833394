//REACT
import { useState } from 'react'
//COMPONENTS
import { TeacherForm } from '../components/Forms/teacher'
import { DeleteForm } from '../components/Forms'
import { Layout, Title, CustomTable, SearchBar, Error, Loading, CustomModal } from '../components'
//ICON
import { Grid, Button, Menu, MenuItem } from '@material-ui/core'
//GQL
import { TEACHERS_PAGE_QUERY, TEACHERS_SEARCH_QUERY } from '../gql/queries'
import { DELETE_TEACHER_MUTATION } from '../gql/mutations'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
//TYPES
import { TeacherType, SortEnum } from '../../../lib/sharedTypes'
import { ColumnType } from '../utils/types'
//HOOKS
import { useAlert } from '../hooks'
//UTILS
import { handleError } from '../utils/handleError'
import { useNavigate } from 'react-router'

const columns: ColumnType<TeacherType>[] = [
  {
    title: '',
    field: 's3_picture',
    render: (row: TeacherType) => row.s3_picture,
    type: 'Image',
    sortable: false,
  },
  {
    title: 'Email',
    field: 'email',
    render: (row: TeacherType) => row.email,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Titre',
    field: 'title',
    render: (row: TeacherType) => row.title,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Prénom',
    field: 'first_name',
    render: (row: TeacherType) => row.first_name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Nom',
    field: 'last_name',
    render: (row: TeacherType) => row.last_name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Créé le',
    field: 'created_at',
    render: (row: TeacherType) => row.created_at,
    type: 'Date',
    sortable: true,
  },
  {
    title: 'Modifié le',
    field: 'updated_at',
    render: (row: TeacherType) => row.updated_at,
    type: 'Date',
    sortable: true,
  },
]

const Teachers = () => {
  const navigate = useNavigate()
  const [setAlert] = useAlert()
  const [field, setField] = useState<keyof TeacherType>('last_name')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<TeacherType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [teacherModal, setTeacherModal] = useState(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [deleteTeacher] = useMutation<{
    deleteTeacher: boolean
  }>(DELETE_TEACHER_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Formateur supprimé` })
    },
  })
  const {
    loading,
    error,
    data: { teachers = [], teachersCount = 0 } = {},
    refetch,
  } = useQuery<{
    teachers: TeacherType[]
    teachersCount: number
  }>(TEACHERS_PAGE_QUERY, {
    variables: {
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
  })

  const [searchTeachersQuery, { error: searchError, data: { teachersSearch = [] } = {} }] = useLazyQuery<{
    teachersSearch: TeacherType[]
  }>(TEACHERS_SEARCH_QUERY)

  const searchTeachers = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchTeachersQuery({
      variables: {
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
        take: take,
      },
    })
  }

  if (error) {
    return (
      <Layout title="Formateurs">
        <Error error={error} />
      </Layout>
    )
  }

  if (searchError) {
    return (
      <Layout title="Formateurs">
        <Error error={searchError} />
      </Layout>
    )
  }

  if (loading) {
    return (
      <Layout title="Formateurs">
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout title="Formateurs">
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={'Formateurs'} />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setSelectedRow(undefined)

                setTeacherModal(true)
              }}
            >
              Ajouter un formateur
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <SearchBar placeholder="Rechercher un formateur" searchQuery={searchTeachers} />
        </Grid>

        <Grid item style={{ width: '100%' }}>
          <CustomTable
            columns={columns}
            rows={searching ? teachersSearch : teachers}
            field={field}
            sort={sort}
            skip={skip}
            take={take}
            setSort={setSort}
            setField={setField}
            rowsCount={searching ? teachersSearch.length : teachersCount}
            setSkip={setSkip}
            setTake={setTake}
            onRowClick={(row: TeacherType, e: React.MouseEvent) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(`/teachers/${row.id}`, '_blank')
              } else {
                navigate(row.id)
              }
            }}
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer le formateur
        </MenuItem>
      </Menu>

      <CustomModal
        size="xs"
        title="Supprimer le formateur"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
          setSelectedRow(undefined)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteTeacher({ variables: { input: { id: selectedRow?.id } } })
            setDeleteModal(false)
            setSearching(false)
            await refetch()
            setSelectedRow(undefined)
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
            setSearching(false)
          }}
        />
      </CustomModal>
      <CustomModal
        open={teacherModal}
        onClose={() => {
          setSearching(false)
          setTeacherModal(false)
        }}
        animation="slide-up"
      >
        <TeacherForm
          teacher={selectedRow}
          refetch={refetch}
          handleClose={() => {
            setSearching(false)
            setTeacherModal(false)
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default Teachers
