import { useEffect, useState } from 'react'
//COMPONENTS
import { Layout, Title, CustomTable, CustomModal, SearchBar, UserAvatar, Loading, Error } from '../components'
import { UserForm } from '../components/Forms'
//MATERIAL UI
import { Grid, Button } from '@material-ui/core'
//GQL
import { USERS_QUERY, USERS_SEARCH_QUERY } from '../gql/queries'
import { useQuery, useLazyQuery } from '@apollo/client'
//TYPES
import { ColumnType } from '../utils/types'
import { UserType, SortEnum } from '../../../lib/sharedTypes'

const columns: ColumnType<UserType>[] = [
  {
    title: '',
    field: 'user',
    render: (row: UserType) => <UserAvatar user={row} />,
    type: 'CustomRender',
    sortable: false,
  },
  {
    title: "Nom d'utilisateur",
    field: 'username',
    render: (row: UserType) => row.username,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Adresse email',
    field: 'email',
    render: (row: UserType) => row.email,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Nom',
    field: 'last_name',
    render: (row: UserType) => row.last_name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Prénom',
    field: 'first_name',
    render: (row: UserType) => row.first_name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Rôle',
    field: 'roles',
    render: (row: UserType) => row.roles,
    type: 'RoleName',
    sortable: false,
  },
]

const Settings = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [field, setField] = useState<keyof UserType>('first_name')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [searching, setSearching] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<UserType | undefined>(undefined)

  const {
    loading,
    error,
    data: { users = [], usersCount = 0 } = {},
    refetch,
  } = useQuery<{
    users: UserType[]
    usersCount: number
  }>(USERS_QUERY, {
    variables: {
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
  })

  const [searchUsersQuery, { error: searchError, data: { usersSearch = [] } = {} }] = useLazyQuery<{
    usersSearch: UserType[]
  }>(USERS_SEARCH_QUERY)

  const searchUsers = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchUsersQuery({
      variables: {
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
        take: take,
      },
    })
  }

  useEffect(() => {
    if (openModal === false) {
      setSelectedUser(undefined)
    }
  }, [openModal])

  const onRowClick = (row: UserType) => {
    if (searching) {
      setSelectedUser(usersSearch.find((user) => user.id === row.id))
      setOpenModal(true)
    } else {
      setSelectedUser(users.find((user) => user.id === row.id))
      setOpenModal(true)
    }
  }

  if (loading) {
    return (
      <Layout title="Paramètres">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Paramètres">
        <Error error={error} />
      </Layout>
    )
  }

  if (searchError) {
    return (
      <Layout title="Paramètres">
        <Error error={searchError} />
      </Layout>
    )
  }

  return (
    <Layout title="Paramètres">
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={'Paramètres et gestion'} />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenModal(true)
              }}
            >
              Ajouter un utilisateur
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <SearchBar placeholder="Rechercher un utilisateur" searchQuery={searchUsers} />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CustomTable<UserType>
            columns={columns}
            rows={searching ? usersSearch : users}
            field={field}
            sort={sort}
            take={take}
            skip={skip}
            setField={setField}
            setSort={setSort}
            setTake={setTake}
            setSkip={setSkip}
            rowsCount={searching ? usersSearch.length : usersCount}
            onRowClick={onRowClick}
          />
        </Grid>
      </Grid>

      <CustomModal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        animation="slide-up"
      >
        <UserForm user={selectedUser} refetch={refetch} handleClose={() => setOpenModal(false)} />
      </CustomModal>
    </Layout>
  )
}

export default Settings
