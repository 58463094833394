import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, Typography, IconButton, useTheme } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
//ICON
import { HiOutlineTrash } from 'react-icons/hi'
// TYPE
import { ProductType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_PARTS_MUTATION } from '../../../gql/mutations'
//UTILS
import { useAlert } from '../../../hooks'
import { handleError } from '../../../utils/handleError'
import { int2roman } from '../../../utils/misc'

type PlanFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const PlanForm = ({ product, refetch, handleClose }: PlanFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const [updateProductParts] = useMutation<{ updateProductParts: boolean }>(UPDATE_PRODUCT_PARTS_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Plan mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const updateProductsPartsInput = stateProduct.parts.reduce((acc: { id: number; name: string }[], part) => {
      acc.push({ id: part.id, name: part.name })
      part.sub_parts.map((sub_part) => {
        acc.push({ id: sub_part.id, name: sub_part.name })
      })
      return acc
    }, [])

    await updateProductParts({
      variables: {
        input: {
          id: stateProduct.id,
          program_introduction: stateProduct.program_introduction,
          parts: updateProductsPartsInput,
        },
      },
    })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour le plan"
      onSubmit={handleSubmit}
      submitDisabled={
        stateProduct.parts === product.parts && stateProduct.program_introduction === product.program_introduction
      }
    >
      <Grid item>
        <TextField
          multiline
          minRows={5}
          maxRows={5}
          required
          label="Introduction du programme"
          name="program_introduction"
          value={stateProduct.program_introduction}
          onChange={(e) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                program_introduction: e.target.value,
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item container direction="row" spacing={2} style={{ maxWidth: '100vw' }}>
        {stateProduct.parts
          .sort((a, b) => a.position - b.position)
          .map((part, i) => {
            return (
              <Grid item xs={12} key={part.id}>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Partie {int2roman(i + 1)}.
                    </Typography>
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      value={part.name}
                      variant="outlined"
                      onChange={(e) => {
                        const newParts = stateProduct.parts.map((p) => {
                          if (p.id === part.id) {
                            p.name = e.target.value
                          }
                          return p
                        })
                        setStateProduct((prevState) => {
                          return {
                            ...prevState,
                            parts: newParts,
                          }
                        })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        setStateProduct((prevState) => {
                          return {
                            ...prevState,
                            parts: prevState.parts.filter((p) => part.id !== p.id),
                          }
                        })
                      }}
                    >
                      <HiOutlineTrash size="22" style={{ color: theme.palette.error.main, cursor: 'pointer' }} />
                    </IconButton>
                  </Grid>
                </Grid>
                {part.sub_parts
                  .sort((a, b) => a.position - b.position)
                  .map((sub_part, index) => {
                    return (
                      <Grid key={sub_part.id} container direction="row" alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography variant="body1">
                            Chapitre {String.fromCharCode(96 + index + 1).toUpperCase()}.
                          </Typography>
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                          <TextField
                            size="small"
                            fullWidth
                            value={sub_part.name}
                            variant="outlined"
                            onChange={(e) => {
                              const newParts = stateProduct.parts.map((p) => {
                                if (p.id === part.id) {
                                  p.sub_parts.map((sp) => {
                                    if (sp.id === sub_part.id) {
                                      sp.name = e.target.value
                                    }
                                    return sp
                                  })
                                }
                                return p
                              })
                              setStateProduct((prevState) => {
                                return {
                                  ...prevState,
                                  parts: newParts,
                                }
                              })
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation()
                              setStateProduct((prevState) => {
                                return {
                                  ...prevState,
                                  parts: prevState.parts.map((p) => {
                                    return {
                                      ...p,
                                      sub_parts: p.sub_parts.filter((sp) => sp.id !== sub_part.id),
                                    }
                                  }),
                                }
                              })
                            }}
                          >
                            <HiOutlineTrash size="22" style={{ color: theme.palette.error.main, cursor: 'pointer' }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
            )
          })}
      </Grid>
    </FormLayout>
  )
}

export default PlanForm
