import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, useTheme, Button } from '@material-ui/core'
// TYPE
import { DeepPartial } from '../../../utils/types'
import { WriterType } from '../../../../../lib/sharedTypes'
//GQL
import { CREATE_WRITER_MUTATION } from '../../../gql/mutations'
import { useMutation } from '@apollo/client'
//UTILS
import { useAlert } from '../../../hooks'
import { handleError } from '../../../utils/handleError'

type WriterPropsType = {
  refetch: () => Promise<any>
  handleClose: () => void
}

const Writer = ({ refetch, handleClose }: WriterPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [newWriter, setNewWriter] = useState<DeepPartial<WriterType>>()
  const [createWriterMutation] = useMutation<{
    createWriter: WriterType
  }>(CREATE_WRITER_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createWriter.first_name} créé` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewWriter((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.id]: event.target.value },
      }
    })
  }

  return (
    <Grid
      item
      container
      justifyContent="space-around"
      alignItems="center"
      style={{ height: '100%', width: '100%', zIndex: 1000 }}
      spacing={1}
    >
      <Grid style={{ width: '100%' }} container spacing={2}>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="first_name"
            label="Prénom"
            variant="outlined"
            defaultValue={newWriter?.first_name}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="last_name"
            label="Nom"
            variant="outlined"
            defaultValue={newWriter?.last_name}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="email"
            label="Adresse email"
            variant="outlined"
            type="email"
            defaultValue={newWriter?.email}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="password"
            label="Mot de passe"
            variant="outlined"
            type="email"
            defaultValue={newWriter?.password}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid style={{ width: '100%' }} container justifyContent="center" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                await createWriterMutation({ variables: { input: newWriter } })
              }}
            >
              Enregistrer
            </Button>
          </Grid>
          <Grid item style={{ paddingBottom: theme.spacing(1) }}>
            <Button
              variant="outlined"
              style={{ color: theme.palette.error.main, border: `1px solid ${theme.palette.error.main}` }}
              onClick={handleClose}
            >
              Annuler
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Writer
