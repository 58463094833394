import { ProductType } from '../../../../lib/sharedTypes'
// REACT
import { useState } from 'react'
// MATERIAL UI
import { Grid, Typography, Divider, Button, Chip, Paper, makeStyles, useTheme } from '@material-ui/core'
// UTILS
import { isAuthorized } from '../../utils/misc'
//COMPONENT
import { CatalogFundingOrganismTable } from './index'
import { CatalogFundingOrganismForm } from '../../components/Forms/product'
import { CustomModal, Loading, Error } from '../../components'
//GQL
import { useQuery } from '@apollo/client'
import { PRODUCT_CAT_FO_QUERY } from '../../gql/queries'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

type CatalogFoSectionPropsType = {
  code_name: string
  refetch: () => Promise<any>
  setIsFinancedByCPF: (bool: boolean) => void
}

const CatalogFoSection = ({ code_name, refetch, setIsFinancedByCPF }: CatalogFoSectionPropsType) => {
  const classes = useStyles()
  const theme = useTheme()
  const [updateModal, setUpdateModal] = useState('')

  const {
    loading,
    error,
    data: { product } = {},
    refetch: refetch2,
  } = useQuery<{ product: ProductType }>(PRODUCT_CAT_FO_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
    async onCompleted(data) {
      await refetch()
      const isFinancedByCPF =
        data.product?.catalog_funding_organisms.filter((cfo) => {
          return cfo.funding_organism.name.toLowerCase() === 'cpf'
        }).length !== 0
      setIsFinancedByCPF(isFinancedByCPF)
    },
  })

  if (loading || !product) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  const isFinancedByANDPC =
    product?.catalog_funding_organisms.filter((cfo) => {
      return cfo.funding_organism.name.toLowerCase() === 'andpc'
    }).length !== 0

  const isFinancedByCPF =
    product?.catalog_funding_organisms.filter((cfo) => {
      return cfo.funding_organism.name.toLowerCase() === 'cpf'
    }).length !== 0

  return (
    <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
      <Grid item>
        <Grid item container justifyContent="space-between">
          <Typography variant="h5">Catalogues et financements</Typography>
          <Button
            color="primary"
            variant="outlined"
            disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT', 'SALE'])}
            onClick={() => {
              setUpdateModal('expertise_financing')
            }}
          >
            Modifier
          </Button>
        </Grid>
        <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
          <Divider />
        </Grid>

        <Grid item container direction="column">
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Catalogues
            </Typography>
          </Grid>
          <Grid item>
            {product.product_catalog_positions.map((pcp) => {
              return (
                <Chip
                  key={pcp.catalog.id}
                  size="small"
                  label={pcp.catalog.name}
                  color="primary"
                  style={{ margin: theme.spacing(1) }}
                />
              )
            })}
          </Grid>
        </Grid>
        {isFinancedByANDPC && (
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Numéro d'action DPC
              </Typography>
            </Grid>
            <Grid item>{product.action_number ? product.action_number : '-'}</Grid>
          </Grid>
        )}

        {isFinancedByCPF && (
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Numéro d'action CPF
              </Typography>
            </Grid>
            <Grid item>{product.cpf_action_string ? product.cpf_action_string : '-'}</Grid>
          </Grid>
        )}
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Organismes de financement
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CatalogFundingOrganismTable
              onChange={() => {
                console.log('display')
              }}
              product={product}
              type="display"
            />
          </Grid>
        </Grid>
      </Grid>
      <CustomModal
        open={updateModal === 'expertise_financing'}
        onClose={async () => {
          await refetch2()
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <CatalogFundingOrganismForm
          product={product}
          refetch={refetch2}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
    </Paper>
  )
}

export default CatalogFoSection
