//REACT
import { useRef } from 'react'
//ICONS
import { HiOutlineTrash } from 'react-icons/hi'
//MATERIAL UI
import { Grid, Typography, Divider, Paper, useTheme, IconButton } from '@material-ui/core'
import { LessonType } from '../../../../lib/sharedTypes'
//REACT DND
import { useDrop } from 'react-dnd'
import { Identifier } from 'dnd-core'

type IntroEvalPropsType = {
  type: 'introduction' | 'evaluation'
  lesson: LessonType | null | undefined
  setLesson: () => void
}

const IntroEval = ({ lesson, type, setLesson }: IntroEvalPropsType) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId, isOver, canDrop }, drop] = useDrop<
    { item: LessonType | null | undefined; type: string },
    { item: LessonType | null | undefined; type: string },
    { handlerId: Identifier | null; isOver: boolean; canDrop: boolean }
  >(
    {
      accept: ['lesson'],
      drop: () => {
        return { item: lesson, type: type }
      },
      collect: (monitor) => {
        const itemType = monitor.getItemType()
        let canDrop = false
        if (itemType === 'lesson') {
          canDrop = true
        }
        return {
          handlerId: monitor.getHandlerId(),
          isOver: monitor.isOver(),
          canDrop: canDrop,
        }
      },
    },
    [],
  )

  drop(ref)

  return (
    <Grid item xs={12}>
      <Paper
        elevation={1}
        data-handler-id={handlerId}
        ref={ref}
        style={{
          padding: theme.spacing(1),
          border: canDrop && isOver ? '1px solid green' : 'unset',
          borderRadius: '10px 10px 10px 10px',
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item style={{ width: 120 }}>
                <Typography variant="h6">
                  {type.charAt(0).toUpperCase()}
                  {type.slice(1)}
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="body1">{lesson?.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {lesson && (
              <IconButton
                style={{ padding: theme.spacing(1) }}
                onClick={(e) => {
                  e.stopPropagation()
                  setLesson()
                }}
              >
                <HiOutlineTrash size="22" style={{ color: theme.palette.error.main, cursor: 'pointer' }} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default IntroEval
