import { useState } from 'react'
//COMPONENTS
import { ProductForm } from '../components/Forms'
import { Layout, Title, SearchBar, CustomTable, Loading, Error, CustomModal } from '../components'
//GQL
import { PRODUCTS_QUERY, PRODUCTS_SEARCH_QUERY } from '../gql/queries'
import { useQuery, useLazyQuery } from '@apollo/client'
// MATERIAL UI
import { Grid, Button } from '@material-ui/core'
//TYPES
import { ColumnType } from '../utils/types'
import { ProductType, SortEnum } from '../../../lib/sharedTypes'
//ROUTER
import { useNavigate } from 'react-router'
import { isAuthorized } from '../utils/misc'

const columns: ColumnType<ProductType>[] = [
  {
    title: '',
    field: 's3_card',
    render: (row: ProductType) => row.s3_card,
    type: 'Image',
    sortable: false,
  },
  {
    title: 'Code Name',
    field: 'code_name',
    render: (row: ProductType) => row.code_name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Titre',
    field: 'title',
    render: (row: ProductType) => row.title,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Créé le',
    field: 'created_at',
    render: (row: ProductType) => row.created_at,
    type: 'Date',
    sortable: true,
  },
]

const Apprentiecship = () => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [field, setField] = useState<keyof ProductType>('created_at')
  const [sort, setSort] = useState<SortEnum>(SortEnum.DESC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [searching, setSearching] = useState<boolean>(false)
  const {
    loading,
    error,
    data: { products = [], productsCount = 0 } = {},
    refetch,
  } = useQuery<{
    products: ProductType[]
    productsCount: number
  }>(PRODUCTS_QUERY, {
    variables: {
      where: {
        is_apprenticeship: {
          eq: true,
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
  })

  const [searchApprentiecshipQuery, { error: searchError, data: { productsSearch = [] } = {} }] = useLazyQuery<{
    productsSearch: ProductType[]
  }>(PRODUCTS_SEARCH_QUERY, {
    variables: {
      where: {
        is_apprenticeship: {
          eq: true,
        },
      },
    },
  })

  const searchApprentiecship = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchApprentiecshipQuery({
      variables: {
        where: {
          is_apprenticeship: {
            eq: true,
          },
        },
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
        take: take,
      },
    })
  }

  if (error) {
    return (
      <Layout title="Apprentissage">
        <Error error={error} />
      </Layout>
    )
  }

  if (searchError) {
    return (
      <Layout title="Apprentissage">
        <Error error={searchError} />
      </Layout>
    )
  }

  if (loading) {
    return (
      <Layout title="Apprentissage">
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout title="Apprentissage">
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={'Apprentissage'} />
          </Grid>
          <Grid item>
            <Button
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION'])}
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenModal(true)
              }}
            >
              Ajouter un apprentissage
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <SearchBar placeholder="Rechercher un apprentissage" searchQuery={searchApprentiecship} />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CustomTable
            columns={columns}
            rows={searching ? productsSearch : products}
            field={field}
            sort={sort}
            skip={skip}
            take={take}
            setSort={setSort}
            setField={setField}
            rowsCount={searching ? productsSearch.length : productsCount}
            setSkip={setSkip}
            setTake={setTake}
            onRowClick={(row: ProductType, e) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(`/apprenticeship/${row.code_name}`, '_blank')
              } else {
                navigate(row.code_name)
              }
            }}
          />
        </Grid>
      </Grid>
      <CustomModal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        animation="slide-up"
      >
        <ProductForm
          type={'apprenticeship'}
          product={undefined}
          refetch={refetch}
          handleClose={() => setOpenModal(false)}
        />
      </CustomModal>
    </Layout>
  )
}

export default Apprentiecship
