// REACT HOOKS
import { useState, useCallback, useRef, useEffect } from 'react'
// MATERIAL UI
import {
  Grid,
  Typography,
  useTheme,
  Divider,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core'
// ICONS
import { MdSearch } from 'react-icons/md'
// COMPONENTS
import { Error, Loading } from '../index'
import { QuestionCard, QuestionCardShort, EmptyCard } from './index'
// TYPES
import { CourseType, LessonQuestionType, LessonType, QuizQuestionType } from '../../../../lib/sharedTypes'
// DND
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import type { Identifier } from 'dnd-core'
// REACT-ROUTER
import { useParams } from 'react-router'
// GRAPHQL
import { COURSE_QUIZ_QUESTIONS_QUERY, SHORT_LESSON_QUESTIONS_QUERY } from '../../gql/queries'
import { useQuery, useLazyQuery } from '@apollo/client'
//UTILS
import { int2roman } from '../../utils/misc'

type QuizDNDProps = {
  onChange: (questions: QuizQuestionType[]) => void
  lesson: LessonType | undefined
  refetch: () => Promise<any>
}

const QuizDND = ({ lesson, onChange, refetch }: QuizDNDProps) => {
  const theme = useTheme()
  const { code_name } = useParams()
  const [searchQuery, setSearchQuery] = useState<string | null>(null)
  const [selectedChapterId, setSelectedChapterId] = useState<number>(-1)
  const [lessonsQuestions, setLessonsQuestions] = useState<LessonQuestionType[]>([])
  const [notFilteredlessonsQuestions, setNotFilteredLessonsQuestions] = useState<LessonQuestionType[]>([])
  const [questions, setQuestions] = useState<QuizQuestionType[] | []>(lesson?.quiz?.quiz_questions || [])
  const [mainQuestionsHeight, setMainQuestionsHeight] = useState<number>(0)
  const observerRef = useRef<ResizeObserver | null>(null)
  const mainQuestionsRef = useRef(null)

  useEffect(() => {
    if (onChange && lesson?.quiz.quiz_questions !== questions) {
      onChange(questions)
    }
  }, [questions])

  const {
    loading,
    error,
    data: { course } = {},
  } = useQuery<{
    course: CourseType
  }>(COURSE_QUIZ_QUESTIONS_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: 'position',
        sort: 'ASC',
      },
    },
    async onCompleted(data) {
      const lessonIds = data?.course?.parts
        .sort((a, b) => a.position - b.position)
        .reduce((acc: number[], part) => {
          part.sub_parts
            .sort((a, b) => a.position - b.position)
            .map((sub_part) => {
              sub_part.sub_parts
                .sort((a, b) => a.position - b.position)
                .map((ss_part) => {
                  acc.push(parseInt(ss_part.lesson.id.toString()))
                })
            })
          return acc
        }, [])

      const questions = await getLessonQuestionQuery({
        variables: {
          where: {
            id: {
              in: lessonIds,
            },
          },
          order: {
            field: 'position',
            sort: 'ASC',
          },
        },
      })
        .then((resp) => {
          return resp?.data?.lessons
            .reduce((acc: LessonQuestionType[], lesson) => {
              lesson.lesson_questions.map((question) => {
                acc.push(question)
              })
              return acc
            }, [])
            .sort((a, b) => {
              return lessonIds.indexOf(parseInt(`${a.lesson.id}`)) - lessonIds.indexOf(parseInt(`${b.lesson.id}`))
            })
        })
        .catch((err) => {
          console.error(err)
        })

      if (questions) {
        setLessonsQuestions(questions)
        setNotFilteredLessonsQuestions(questions)
      }
    },
  })

  const [getLessonQuestionQuery] = useLazyQuery<{ lessons: LessonType[] }>(SHORT_LESSON_QUESTIONS_QUERY)

  useEffect(() => {
    if (!course) return
    let selectedChapterQuestionIds: string[] | undefined
    let filteredQuestionIds: string[] | undefined

    if (selectedChapterId !== -1) {
      selectedChapterQuestionIds = course.parts.reduce((acc: string[], part) => {
        part.sub_parts.map((sub_part) => {
          if (sub_part.id.toString() === selectedChapterId.toString()) {
            sub_part.sub_parts.map((ss_part) => {
              ss_part?.lesson?.lesson_questions?.map((question) => {
                acc.push(question.id.toString())
              })
            })
          }
        })
        return acc
      }, [])
    }
    if (searchQuery) {
      filteredQuestionIds = notFilteredlessonsQuestions
        .filter((question) => {
          return (
            question?.lesson?.name.includes(searchQuery.toLowerCase()) ||
            question?.help_text?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            question?.mc_question?.statement.toLowerCase().includes(searchQuery.toLowerCase()) ||
            question?.gapfill_question?.text.toLowerCase().includes(searchQuery.toLowerCase()) ||
            question?.ranking_question?.statement.toLowerCase().includes(searchQuery.toLowerCase())
          )
        })
        .map((question) => question.id.toString())
    }

    const filteredQuestions = notFilteredlessonsQuestions
      .filter((question) => {
        if (selectedChapterQuestionIds) {
          return selectedChapterQuestionIds?.includes(question.id.toString())
        }
        return true
      })
      .filter((question) => {
        if (filteredQuestionIds) {
          return filteredQuestionIds?.includes(question.id.toString())
        } else {
          return true
        }
      })

    setLessonsQuestions(filteredQuestions)
  }, [selectedChapterId, searchQuery])

  useEffect(() => {
    observerRef.current = new ResizeObserver((entries) => {
      const height = entries[0].contentRect.height
      setMainQuestionsHeight(height)
    })
    if (mainQuestionsRef.current) {
      observerRef.current.observe(mainQuestionsRef.current)
    }

    return () => {
      observerRef.current && observerRef.current.disconnect()
    }
  }, [course])

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number, idTarget: Identifier | null, idItem: Identifier | null) => {
      if ((idTarget === 'question_card' || idTarget === 'empty_card') && idItem === 'question_card_short') {
        setQuestions((prevQuestions) =>
          update(prevQuestions, {
            $splice: [
              [
                hoverIndex,
                0,
                {
                  id: new Date().getTime(),
                  lesson_question: lessonsQuestions[dragIndex],
                  position: prevQuestions.length,
                  points: 1,
                } as unknown as QuizQuestionType,
              ],
            ],
          }),
        )
      } else if (idTarget === 'question_card_short' && idItem === 'question_card') {
        setQuestions((prevQuestions) => update(prevQuestions, { $splice: [[dragIndex, 1]] }))
      } else if (idTarget === 'question_card' && idItem === 'question_card') {
        setQuestions((prevQuestions) =>
          update(prevQuestions, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevQuestions[dragIndex]],
            ],
          }),
        )
      }
    },
    [lessonsQuestions, searchQuery, selectedChapterId],
  )

  if (error) {
    return <Error error={error} />
  }

  if (loading || !course) {
    return <Loading />
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid item container direction="row" xs={12} spacing={1}>
        <Grid item xs={9} container ref={mainQuestionsRef}>
          <Grid container direction="column">
            <Grid container direction="column">
              <Typography variant="body1">{questions.length} questions</Typography>
            </Grid>
            <Grid item container style={{ minHeight: '500px' }}>
              <Grid item container direction="row" spacing={1} justifyContent="center" alignItems="center">
                {questions.map((qq_question, index) => {
                  return (
                    <QuestionCard
                      course={course}
                      refetch={refetch}
                      qq_question={qq_question}
                      key={qq_question.id}
                      index={index}
                      onPointsChange={(points) => {
                        const questionIndex = questions.findIndex((question) => question.id === qq_question.id)
                        const updatedQuestion = {
                          ...questions[questionIndex],
                          points: points,
                        }
                        const newQuestions = [...questions]
                        newQuestions[questionIndex] = updatedQuestion
                        setQuestions(newQuestions)
                      }}
                      moveCard={moveCard}
                      removeCard={(index: number) =>
                        setQuestions((prevQuestions: QuizQuestionType[]) =>
                          update(prevQuestions, { $splice: [[index, 1]] }),
                        )
                      }
                    />
                  )
                })}
                {questions.length === 0 && <EmptyCard moveCard={moveCard} index={Infinity} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          direction="column"
          justifyContent="flex-start"
          container
          wrap="nowrap"
          style={{
            overflow: 'auto',
            minHeight: '100%',
            maxHeight: mainQuestionsHeight,
          }}
        >
          <Grid item container direction="column" spacing={1}>
            <Typography variant="h6">Questions</Typography>
            <Grid item>
              <TextField
                label="Rechercher une question"
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdSearch size={22} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel>Séquencier</InputLabel>
                <Select
                  native
                  value={selectedChapterId}
                  onChange={(e) => {
                    setSelectedChapterId(-1)
                    setSelectedChapterId(parseInt(e.target.value as string))
                  }}
                >
                  <option value={-1}>Tous les chapitres</option>
                  {course?.parts.map((part) => {
                    return (
                      <optgroup key={part.id} label={`Partie ${int2roman(part.position + 1)}. ${part.name}`}>
                        {part.sub_parts.map((sub_part) => {
                          return (
                            <option key={sub_part.id} value={sub_part.id}>
                              Chapitre {String.fromCharCode(96 + sub_part.position + 1).toUpperCase()}. {sub_part.name}
                            </option>
                          )
                        })}
                      </optgroup>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
          </Grid>
          <Grid item container direction="column" spacing={1}>
            {lessonsQuestions.length > 0 ? (
              lessonsQuestions
                .sort((a, b) => {
                  return questions.map((q) => q.id).indexOf(b.id) - questions.map((q) => q.id).indexOf(a.id)
                })
                .map((qq_question, index) => {
                  return (
                    <QuestionCardShort
                      qq_question={qq_question}
                      key={index}
                      index={index}
                      moveCard={moveCard}
                      isDraggable={
                        !questions.some((qq) => qq.lesson_question.id.toString() === qq_question?.id.toString())
                      }
                    />
                  )
                })
            ) : (
              <Grid item style={{ padding: theme.spacing(1) }}>
                <Typography variant="caption">Aucune question trouvée</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DndProvider>
  )
}

export default QuizDND
