//REACT
import React, { useEffect, useState } from 'react'
// MATERIAL UI
import { dark_theme, light_theme } from './utils/theme'
import { ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
//REDUX
import { useAppSelector } from './hooks/reducerHooks'
//ROUTER
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
//COMPONENTS
import { PrivateRoute, ProtectedRoute, Alert } from './components'
//PAGES
import {
  Login,
  Dashboard,
  Profile,
  Calendar,
  Courses,
  Course,
  Projects,
  Project,
  ProjectGeneralInformations,
  Set,
  Documents,
  Rushes,
  Sets,
  TeamAndShooting,
  Video,
  Teaser,
  Videos,
  Settings,
  Products,
  Product,
  ProductFaq,
  Reviews,
  GeneralInformations,
  VisualManagement,
  CommercialAspects,
  Lessons,
  LessonsVideo,
  LessonsHtml,
  LessonHtml,
  LessonVideo,
  LessonsPDF,
  LessonPDF,
  LessonsText,
  LessonText,
  LessonsTask,
  LessonTask,
  LessonsQuiz,
  LessonQuiz,
  LessonsSynthesis,
  LessonSynthesis,
  LessonsEPP,
  LessonEPP,
  LessonsAll,
  LessonSearch,
  CourseGeneralInformations,
  Feedback,
  Plan,
  CourseSkills,
  CourseQuestions,
  CourseResources,
  Teachers,
  Teacher,
  Catalogs,
  Catalog,
  CatalogGeneralInformations,
  CatalogVisualManagement,
  CatalogFAQ,
  Converter,
  Summaries,
  Program,
  Schemas,
  Reports,
  Report,
  Apprenticeships,
  Apprenticeship,
  ApprenticeshipCourses,
  // Questions,
  // Skills,
  // Resources,
  Test,
  Themes,
} from './pages'
import { useAppDispatch } from './hooks/reducerHooks'
import { toggleTheme } from './store/reducers/userReducer'
// //GQL
// import { HEALTH_QUERY } from './gql/queries'
// import { useQuery } from '@apollo/client'
// //REDUX
// import { setConnected } from './store/reducers/authReducer'
import bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react'
//REDUX
import { store } from './store/store'
import { LessonDactylo, LessonsDactylo } from './pages/CoursesPages/LessonPages'
import Theme from './pages/Theme'

bugsnag.start({
  appType: 'front',
  apiKey: '1121238bf1ab7276616e99dedf28c0f5',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
  onError: (event) => {
    console.log('event.errors[0].errorMessage', event.errors[0].errorMessage)
    if (
      [
        'signal is aborted without reason',
        'accessToken_expired',
        'Failed to fetch',
        'Load failed',
        'Veuillez vous connecter',
      ].includes(event.errors[0].errorMessage)
    ) {
      return false
    }
    const state = store.getState()
    if (state.user.user) {
      event.addMetadata('metadata', state.user.user)
    }
    return true
  },
})

const plugin = bugsnag.getPlugin('react') as BugsnagPluginReactResult

const ErrorBoundary = plugin.createErrorBoundary(React)

const ErrorView = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      background: '#424242',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 0,
      padding: 0,
    }}
  >
    <h1 style={{ color: 'white' }}>Une erreur est survenue.</h1>
  </div>
)

const App = () => {
  const dispatch = useAppDispatch()
  const { isLoggedIn } = useAppSelector((state) => state.auth)
  const { userTheme } = useAppSelector((state) => state.user)
  const { severity, content } = useAppSelector((state) => state.alert)
  const [isFirstLoad, setIsFirstLoad] = useState(false)

  // const { refetch } = useQuery<{
  //   ping: boolean
  // }>(HEALTH_QUERY, {
  //   onCompleted() {
  //     dispatch(setConnected({ status: true }))
  //   },
  // })

  // useEffect(() => {
  //   const check = setInterval(async () => {
  //     if (connected) {
  //       clearInterval(check)
  //     }
  //     await refetch()
  //   }, 1000)

  //   return () => {
  //     clearInterval(check)
  //   }
  // }, [connected])

  useEffect(() => {
    const matches = window.matchMedia('(prefers-color-scheme: dark)').matches
    const colorScheme = matches ? 'dark' : 'light'
    if (colorScheme !== userTheme && isFirstLoad) {
      dispatch(toggleTheme())
      setIsFirstLoad(false)
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      const colorScheme = event.matches ? 'dark' : 'light'
      if (colorScheme !== userTheme) {
        dispatch(toggleTheme())
      }
    })
    window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', (event) => {
      const colorScheme = event.matches ? 'dark' : 'light'
      if (colorScheme !== userTheme) {
        dispatch(toggleTheme())
      }
    })
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <ThemeProvider theme={userTheme === 'light' ? responsiveFontSizes(light_theme) : responsiveFontSizes(dark_theme)}>
        <CssBaseline />
        <Router>
          <Routes>
            {isLoggedIn ? (
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
            ) : (
              <Route path="/" element={<Login />} />
            )}
            <Route
              path="/tmp_converter"
              element={
                <ProtectedRoute roles={['ADMIN']}>
                  <Converter />
                </ProtectedRoute>
              }
            />
            <Route
              path="/calendar/:previous_weeks_count?"
              element={
                <ProtectedRoute
                  roles={['USER', 'ADMIN', 'PRODUCTION', 'PROJECT', 'DIRECTOR', 'EDITOR', 'SALE', 'SEO', 'DESIGN']}
                >
                  <Calendar />
                </ProtectedRoute>
              }
            />

            <Route
              path="/catalogues"
              element={
                <ProtectedRoute roles={['ADMIN', 'PRODUCTION', 'SEO']}>
                  <Catalogs />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/questions"
              element={
                <ProtectedRoute roles={['ADMIN', 'PRODUCTION']}>
                  <Questions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/skills"
              element={
                <ProtectedRoute roles={['ADMIN', 'PRODUCTION']}>
                  <Skills />
                </ProtectedRoute>
              }
            />
            <Route
              path="/resources"
              element={
                <ProtectedRoute roles={['ADMIN', 'PRODUCTION']}>
                  <Resources />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/catalogues/:catalog_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'PRODUCTION', 'SEO']}>
                  <Catalog />
                </ProtectedRoute>
              }
            >
              <Route
                index
                element={
                  <ProtectedRoute roles={['ADMIN', 'PRODUCTION', 'SEO']}>
                    <CatalogGeneralInformations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="general_informations"
                element={
                  <ProtectedRoute roles={['ADMIN', 'PRODUCTION', 'SEO']}>
                    <CatalogGeneralInformations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="visual_management"
                element={
                  <ProtectedRoute roles={['ADMIN', 'PRODUCTION', 'SEO']}>
                    <CatalogVisualManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="faq"
                element={
                  <ProtectedRoute roles={['ADMIN', 'PRODUCTION', 'SEO']}>
                    <CatalogFAQ />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />

            <Route
              path="/formations"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <Courses />
                </ProtectedRoute>
              }
            />

            <Route
              path="/formations/:code_name"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <Course />
                </ProtectedRoute>
              }
            >
              <Route
                index
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <CourseGeneralInformations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="general_informations"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <CourseGeneralInformations />
                  </ProtectedRoute>
                }
              />

              <Route
                path="summaries"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Summaries />
                  </ProtectedRoute>
                }
              />
              <Route
                path="schemas"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Schemas />
                  </ProtectedRoute>
                }
              />
              <Route
                path="feedback"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Feedback />
                  </ProtectedRoute>
                }
              />

              <Route
                path="lessons"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Lessons />
                  </ProtectedRoute>
                }
              >
                <Route
                  index
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsAll />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="video"
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsVideo />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="dactylo"
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsDactylo />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="html"
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsHtml />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="document"
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsPDF />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="text"
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsText />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="task"
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsTask />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="quiz"
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsQuiz />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="synthese"
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsSynthesis />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="epp"
                  element={
                    <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                      <LessonsEPP />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="plan"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Plan />
                  </ProtectedRoute>
                }
              />
              <Route
                path="questions"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <CourseQuestions />
                  </ProtectedRoute>
                }
              />

              <Route
                path="skills"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <CourseSkills />
                  </ProtectedRoute>
                }
              />
              <Route
                path="resources"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <CourseResources />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="/formations/:code_name/lessons/video/search"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonSearch />
                </ProtectedRoute>
              }
            />

            <Route
              path="/formations/:code_name/lessons/video/:lesson_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonVideo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/formations/:code_name/lessons/dactylo/:lesson_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonDactylo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/formations/:code_name/lessons/html/:lesson_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonHtml />
                </ProtectedRoute>
              }
            />
            <Route
              path="/formations/:code_name/lessons/document/:lesson_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonPDF />
                </ProtectedRoute>
              }
            />
            <Route
              path="/formations/:code_name/lessons/quiz/:lesson_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonQuiz />
                </ProtectedRoute>
              }
            />
            <Route
              path="/formations/:code_name/lessons/text/:lesson_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonText />
                </ProtectedRoute>
              }
            />

            <Route
              path="/formations/:code_name/lessons/task/:lesson_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonTask />
                </ProtectedRoute>
              }
            />
            <Route
              path="/formations/:code_name/lessons/epp/:lesson_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonEPP />
                </ProtectedRoute>
              }
            />
            <Route
              path="/formations/:code_name/lessons/synthese/:lesson_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <LessonSynthesis />
                </ProtectedRoute>
              }
            />
            <Route
              path="/projects"
              element={
                <ProtectedRoute
                  roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO', 'FINANCE']}
                >
                  <Projects />
                </ProtectedRoute>
              }
            />
            <Route
              path="/projects/:code_name"
              element={
                <ProtectedRoute
                  roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO', 'FINANCE']}
                >
                  <Project />
                </ProtectedRoute>
              }
            >
              <Route
                path="schemas"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Schemas />
                  </ProtectedRoute>
                }
              />
              <Route
                index
                element={
                  <ProtectedRoute
                    roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO', 'FINANCE']}
                  >
                    <ProjectGeneralInformations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="general_informations"
                element={
                  <ProtectedRoute
                    roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO', 'FINANCE']}
                  >
                    <ProjectGeneralInformations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="sets"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Sets />
                  </ProtectedRoute>
                }
              />
              <Route
                path="videos"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Videos />
                  </ProtectedRoute>
                }
              />
              <Route
                path="rushes"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Rushes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="documents"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO']}>
                    <Documents />
                  </ProtectedRoute>
                }
              />
              <Route
                path="team_shootings"
                element={
                  <ProtectedRoute
                    roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO', 'FINANCE']}
                  >
                    <TeamAndShooting />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/projects/:code_name/sets/:set_index"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <Set />
                </ProtectedRoute>
              }
            />
            <Route
              path="/projects/:code_name/sets/:set_index/videos/:video_number"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <Video />
                </ProtectedRoute>
              }
            />
            <Route
              path="/projects/:code_name/teaser"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <Teaser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/apprenticeships"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                  <Apprenticeships />
                </ProtectedRoute>
              }
            />
            <Route
              path="/apprenticeships/:code_name"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                  <Apprenticeship />
                </ProtectedRoute>
              }
            />
            <Route
              path="/apprenticeships/:code_name/:thematic_id/courses"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                  <ApprenticeshipCourses />
                </ProtectedRoute>
              }
            />

            <Route
              path="/products/:code_name/program"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                  <Program />
                </ProtectedRoute>
              }
            />
            <Route
              path="/products"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                  <Products />
                </ProtectedRoute>
              }
            />
            <Route
              path="/products/:code_name"
              element={
                <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                  <Product />
                </ProtectedRoute>
              }
            >
              <Route
                index
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                    <GeneralInformations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="general_informations"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                    <GeneralInformations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="visual_management"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                    <VisualManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="commercial_aspects"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                    <CommercialAspects />
                  </ProtectedRoute>
                }
              />
              <Route
                path="reviews"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                    <Reviews />
                  </ProtectedRoute>
                }
              />
              <Route
                path="faq"
                element={
                  <ProtectedRoute roles={['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO']}>
                    <ProductFaq />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/settings"
              element={
                <ProtectedRoute roles={['ADMIN']}>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reports/:period?"
              element={
                <ProtectedRoute roles={['ADMIN', 'PRODUCTION']}>
                  <Reports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reports/:period/:date"
              element={
                <ProtectedRoute roles={['ADMIN', 'PRODUCTION']}>
                  <Report />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teachers"
              element={
                <ProtectedRoute roles={['ADMIN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <Teachers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teachers/:teacher_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'PRODUCTION', 'PROJECT', 'SEO']}>
                  <Teacher />
                </ProtectedRoute>
              }
            />
            <Route
              path="/themes"
              element={
                <ProtectedRoute roles={['ADMIN', 'SALE']}>
                  <Themes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/themes/:theme_id"
              element={
                <ProtectedRoute roles={['ADMIN', 'SALE']}>
                  <Theme />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test"
              element={
                <ProtectedRoute roles={['ADMIN']}>
                  <Test />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
        {/* <Status connected={connected} /> */}
        <Alert severity={severity} content={content} />
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
