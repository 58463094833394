import { useState } from 'react'
//MATERIAL UI
import { Grid, Link, IconButton, Menu, MenuItem } from '@material-ui/core'
// ICON
import { MdMoreVert } from 'react-icons/md'
//COMPONENTS
import { DeleteForm } from '../../components/Forms'
import { LessonVideoDiagramForm } from '../../components/Forms/lesson'
import { CustomTable, Error, CustomModal } from '../../components'
//REACT-ROUTER
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
//TYPES
import { ColumnType } from '../../utils/types'
import { LessonVideoDiagramType, SortEnum } from '../../../../lib/sharedTypes'
//GQL
import { SCHEMAS_QUERY } from '../../gql/queries'
import { DELETE_LESSON_VIDEO_DIAGRAM_MUTATION } from '../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
//HOOKS
import { useAlert } from '../../hooks'
// UTILS
import { handleError } from '../../utils/handleError'
import { int2roman, isAuthorized } from '../../utils/misc'

const Schemas = () => {
  const navigate = useNavigate()
  const { code_name } = useParams()
  const [setAlert] = useAlert()
  const [field, setField] = useState<string>('lesson_video.number')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<LessonVideoDiagramType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [schemaModal, setSchemaModal] = useState(false)
  const [deleteLessonVideoDiagramMutation] = useMutation<{
    deleteLessonVideoDiagram: boolean
  }>(DELETE_LESSON_VIDEO_DIAGRAM_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Schéma supprimé` })
    },
  })
  if (!code_name) {
    return <Error error={'code_name inconnu'} />
  }

  const { data: { lessonVideoDiagrams } = {}, refetch } = useQuery<{
    lessonVideoDiagrams: LessonVideoDiagramType[]
  }>(SCHEMAS_QUERY, {
    variables: {
      where: {
        lesson_video: {
          lesson: {
            course: {
              code_name: {
                eq: code_name,
              },
            },
          },
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      take: take,
      skip: skip,
    },
  })

  const lessonColumns: ColumnType<LessonVideoDiagramType>[] = [
    {
      title: 'Numéro',
      field: 'lesson_video.number',
      render: (row: LessonVideoDiagramType) => row.lesson_video.number,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Chapitre',
      field: 'number',
      render: (row: LessonVideoDiagramType) =>
        `Chapitre ${int2roman(
          row?.lesson_video?.lesson?.parts[0]?.parent?.parent?.position + 1 || 1,
        )}.${String.fromCharCode(96 + row.lesson_video?.lesson?.parts[0]?.parent?.position + 1).toUpperCase()}`,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Nom du schéma',
      field: 'name',
      render: (row: LessonVideoDiagramType) => row.name,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Leçon',
      field: 'lesson_video.lesson.name',
      render: (row: LessonVideoDiagramType) => {
        return (
          <Link
            href={`/projects/${row.lesson_video.video_set.project.code_name}/sets/${row.lesson_video.video_set.index}/videos/${row.lesson_video.number}`}
            onClick={() => {
              navigate(
                `/projects/${row.lesson_video.video_set.project.code_name}/sets/${row.lesson_video.video_set.index}/videos/${row.lesson_video.number}`,
                {
                  state: { openModal: true, timeCode: row?.time_code },
                },
              )
            }}
          >
            {row.lesson_video.lesson.name}
          </Link>
        )
      },
      type: 'String',
      sortable: false,
    },
    {
      title: 'Fichier',
      field: 's3_file',
      render: (row: LessonVideoDiagramType) => {
        if (row.s3_file) {
          return (
            <Link href={row.s3_file} target="_blank">
              {row.name}
            </Link>
          )
        } else {
          return '-'
        }
      },
      type: 'CustomRender',
      sortable: false,
    },
    {
      title: 'Créé le',
      field: 'created_at',
      render: (row: LessonVideoDiagramType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: LessonVideoDiagramType) => row.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: LessonVideoDiagramType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  return (
    <Grid container direction="column" justifyContent="flex-start" spacing={1}>
      <Grid style={{ width: '100%' }}>
        <CustomTable
          columns={lessonColumns}
          rows={lessonVideoDiagrams || []}
          field={field}
          sort={sort}
          take={take}
          skip={skip}
          setTake={setTake}
          setSkip={setSkip}
          setSort={setSort}
          setField={setField}
        />
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => {
            setAnchorEl(null)
            setSelectedRow(undefined)
          }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'PROJECT', 'EDITOR'])}
            onClick={() => {
              setSchemaModal(true)
              setAnchorEl(null)
            }}
          >
            Modifier le schéma
          </MenuItem>
          <MenuItem
            disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'PROJECT', 'EDITOR'])}
            onClick={() => {
              setDeleteModal(true)
              setAnchorEl(null)
            }}
          >
            Supprimer le schéma
          </MenuItem>
        </Menu>
      </Grid>
      <CustomModal
        open={schemaModal}
        onClose={() => {
          setSchemaModal(false)
        }}
        animation="slide-up"
      >
        <LessonVideoDiagramForm
          video={selectedRow?.lesson_video}
          handleClose={() => {
            setSchemaModal(false)
          }}
          diagram={selectedRow}
          refetch={refetch}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer le schéma"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteLessonVideoDiagramMutation({ variables: { input: { id: selectedRow?.id } } })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default Schemas
