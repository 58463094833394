import { useState, useEffect } from 'react'
//MATERIAL UI
import { Grid, Menu, MenuItem, Button, IconButton, useTheme } from '@material-ui/core'
//COMPONENTS
import { QuestionForm } from '../../components/Forms/course'
import { DeleteForm } from '../../components/Forms'
import { ImportQuestionForm } from '../../components/Forms/course'
import { CustomTable, Loading, Error, CustomModal, SearchBar, TextEditorJS } from '../../components'
//REACT-ROUTER
import { useParams, useOutletContext, useLocation } from 'react-router-dom'
//ICON
import { MdMoreVert } from 'react-icons/md'
// TYPE
import { CourseType, SortEnum, LessonQuestionType } from '../../../../lib/sharedTypes'
import { ColumnType } from '../../utils/types'
// GQL
import { COURSE_QUESTIONS_QUERY, COURSE_QUESTIONS_SEARCH_QUERY } from '../../gql/queries'
import { DELETE_LESSON_QUESTION_MUTATION } from '../../gql/mutations'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../utils/misc'

type LocationState = {
  state: {
    openModal: boolean
    question: LessonQuestionType
  }
}

const Questions = () => {
  const location = useLocation()
  const theme = useTheme()
  const { refetchCourse } = useOutletContext<{ refetchCourse: () => Promise<any> }>()
  const { code_name } = useParams()
  const [setAlert] = useAlert()
  const [field, setField] = useState<keyof LessonQuestionType>('updated_at')
  const [sort, setSort] = useState<keyof typeof SortEnum>('DESC')
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<LessonQuestionType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [questionModal, setQuestionModal] = useState(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [importModal, setImportModal] = useState<boolean>(false)
  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{ course: CourseType }>(COURSE_QUESTIONS_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      orderItems: {
        field: 'position',
        sort: 'ASC',
      },
      take: take,
      skip: skip,
    },
    async onCompleted() {
      await refetchCourse()
    },
  })

  useEffect(() => {
    const { state } = location as LocationState
    if (state) {
      setSelectedRow(state.question)
      setQuestionModal(state.openModal)
    }
  }, [location])

  const [deleteQuestionMutation] = useMutation<{ deleteResource: boolean }>(DELETE_LESSON_QUESTION_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Question supprimée` })
      await refetch()
    },
  })

  const [searchQuestionsQuery, { data: { course: courseSearch = null } = {} }] = useLazyQuery<{
    course: CourseType
  }>(COURSE_QUESTIONS_SEARCH_QUERY)

  const searchQuestions = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchQuestionsQuery({
      variables: {
        where: {
          code_name: {
            eq: code_name,
          },
        },
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
        orderItems: {
          field: 'position',
          sort: 'ASC',
        },
      },
    })
  }

  const columns: ColumnType<LessonQuestionType>[] = [
    {
      title: 'Nom de la leçon',
      field: 'lesson.name',
      render: (row: LessonQuestionType) => row?.lesson?.name || '',
      type: 'String',
      sortable: true,
    },
    {
      title: 'Enoncé',
      field: 'mc_question.statement',
      render: (row: LessonQuestionType) =>
        row.question_video ? (
          <div>
            <div>Vidéo: {row.question_video.lesson.name}</div>
            <img
              style={{
                objectFit: 'contain',
                maxWidth: '100%',
                height: 150,
              }}
              src={row.question_video.vimeo_thumbnail}
              loading="lazy"
              alt="S3_picture"
            />
          </div>
        ) : (
          row?.mc_question?.statement ||
          row?.ranking_question?.statement || (
            <TextEditorJS
              holderId={`lesson_questions_${row.id}`}
              isReadOnly={true}
              type="gapfill"
              legacy={row?.gapfill_question?.text}
            />
          )
        ),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: LessonQuestionType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: LessonQuestionType) => row.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: LessonQuestionType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (!code_name) {
    return <Error error={'code_name inconnu'} />
  }

  if (loading || !course) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column" justifyContent="flex-start" spacing={1} style={{ width: '100%' }}>
      <Grid item container justifyContent="flex-end" style={{ width: '100%' }}>
        <Button
          color="secondary"
          variant="outlined"
          disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
          onClick={() => {
            setImportModal(true)
          }}
          style={{ marginRight: theme.spacing(1) }}
        >
          Importer
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
          onClick={() => {
            setSelectedRow(undefined)
            setQuestionModal(true)
          }}
        >
          Ajouter une question
        </Button>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <SearchBar placeholder="Rechercher une question" searchQuery={searchQuestions} />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <CustomTable<LessonQuestionType>
          columns={columns}
          rows={searching && courseSearch ? courseSearch.lessons_questions_search : course.lessons_questions}
          field={field}
          sort={sort}
          take={take}
          skip={skip}
          setTake={setTake}
          setSkip={setSkip}
          setSort={setSort}
          setField={setField}
          rowsCount={searching ? courseSearch?.lessons_questions_search.length : course.lessons_questions_count}
          onRowClick={(row: LessonQuestionType) => {
            if (isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION'])) {
              setSelectedRow(row)
              setQuestionModal(true)
            }
          }}
        />

        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => {
            setAnchorEl(null)
          }}
        >
          <MenuItem
            disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
            onClick={() => {
              setDeleteModal(true)
              setAnchorEl(null)
            }}
          >
            Supprimer
          </MenuItem>
        </Menu>
      </Grid>
      <CustomModal
        open={questionModal}
        onClose={() => {
          setQuestionModal(false)
        }}
        animation="slide-up"
      >
        <QuestionForm
          question={selectedRow}
          course={course}
          refetch={refetch}
          handleClose={() => {
            setQuestionModal(false)
            setSelectedRow(undefined)
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer la question"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteQuestionMutation({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Importer les questions"
        open={importModal}
        onClose={() => {
          setImportModal(false)
        }}
      >
        <ImportQuestionForm
          course={course}
          handleClose={() => {
            setImportModal(false)
          }}
          refetch={refetch}
        />
      </CustomModal>
    </Grid>
  )
}

export default Questions
