//REACT
import React, { useState, useEffect } from 'react'
//ASSETS
import defaultTeacher from '../../../assets/defaultTeacher.png'
// MATERIAL UI
import { TextField, Grid, Button, InputLabel, Select, MenuItem, useTheme, FormControl } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../index'
//TYPES
import { TeacherType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { useMutation } from '@apollo/client'
import { CREATE_TEACHER_MUTATION, UPDATE_TEACHER_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { Autocomplete } from '@material-ui/lab'

type TeacherFormPropsType = {
  teacher?: TeacherType
  refetch: () => void
  handleClose: () => void
}

const TeacherForm = ({ teacher, refetch, handleClose }: TeacherFormPropsType) => {
  const theme = useTheme()
  const [stateTeacher, setStateTeacher] = useState<DeepPartial<TeacherType> | undefined>(teacher)
  const [setAlert] = useAlert()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [localImg, setLocalImg] = useState<string>()
  const imgUrl = localImg ? localImg : teacher?.s3_picture
  const [createTeacher] = useMutation<{
    createTeacher: TeacherType
  }>(CREATE_TEACHER_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createTeacher.first_name} créé` })
      refetch()
      handleClose()
    },
  })

  const [updateTeacher] = useMutation<{
    updateTeacher: TeacherType
  }>(UPDATE_TEACHER_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateTeacher.first_name} mis-à-jour` })
      refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log('stateTeacher', stateTeacher)

    const teacherInput = {
      id: stateTeacher?.id,
      first_name: stateTeacher?.first_name,
      last_name: stateTeacher?.last_name,
      title: stateTeacher?.title,
      email: stateTeacher?.email,
      phone_number: stateTeacher?.phone_number,
      mobile_number: stateTeacher?.mobile_number,
      introduction: stateTeacher?.introduction,
      resume_highlights: stateTeacher?.resume_highlights,
      push_score: stateTeacher?.push_score,
      s3_picture: selectedFile,
    }
    if (stateTeacher?.id) {
      await updateTeacher({ variables: { input: teacherInput } })
    } else {
      await createTeacher({ variables: { input: teacherInput } })
    }
  }

  useEffect(() => {
    if (!selectedFile) {
      setLocalImg(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setLocalImg(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  return (
    <FormLayout
      type={stateTeacher?.id ? 'update' : 'create'}
      title={stateTeacher?.id ? 'Mettre à jour un formateur' : 'Créer un formateur'}
      onSubmit={handleSubmit}
      submitDisabled={stateTeacher?.id ? stateTeacher === teacher : stateTeacher === undefined}
    >
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="first_name"
            label="Prénom"
            variant="outlined"
            defaultValue={stateTeacher?.first_name}
            onChange={(event) => {
              setStateTeacher((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="last_name"
            label="Nom"
            variant="outlined"
            defaultValue={stateTeacher?.last_name}
            onChange={(event) => {
              setStateTeacher((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
            required
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="phone_number"
            label="Téléphone fixe"
            variant="outlined"
            defaultValue={stateTeacher?.phone_number}
            onChange={(event) => {
              setStateTeacher((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="mobile_number"
            label="Téléphone portable"
            variant="outlined"
            defaultValue={stateTeacher?.mobile_number}
            onChange={(event) => {
              setStateTeacher((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="email"
            label="Email"
            variant="outlined"
            defaultValue={stateTeacher?.email}
            onChange={(event) => {
              setStateTeacher((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: event.target.value,
                }
              })
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel style={{ paddingRight: theme.spacing(1) }}>Titre</InputLabel>
            <Select
              fullWidth
              id="title"
              label="Titre"
              variant="outlined"
              value={stateTeacher?.title}
              onChange={(event) => {
                setStateTeacher((prevState) => {
                  return {
                    ...prevState,
                    title: event.target.value as string,
                  }
                })
              }}
            >
              <MenuItem value={''} style={{ height: 24 }}></MenuItem>
              {/* <MenuItem value={'M'}>M</MenuItem>
              <MenuItem value={'Mme'}>Mme</MenuItem> */}
              <MenuItem value={'Dr'}>Dr</MenuItem>
              <MenuItem value={'Pr'}>Pr</MenuItem>
              <MenuItem value={'Me'}>Me</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="push_score"
            label="Score de push"
            variant="outlined"
            defaultValue={stateTeacher?.push_score}
            onChange={(event) => {
              setStateTeacher((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: parseInt(event.target.value),
                }
              })
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          minRows={5}
          maxRows={5}
          id="introduction"
          label="Introduction"
          variant="outlined"
          defaultValue={stateTeacher?.introduction}
          onChange={(event) => {
            setStateTeacher((prevState) => {
              return {
                ...prevState,
                [event.target.id]: event.target.value,
              }
            })
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid item>
          <Autocomplete
            multiple
            freeSolo
            defaultValue={stateTeacher?.resume_highlights || []}
            options={stateTeacher?.resume_highlights || []}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) => option || ''}
            onChange={(_, value) => {
              if (value) {
                setStateTeacher({
                  ...stateTeacher,
                  resume_highlights: value,
                })
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" label="Points forts du CV" />
            }}
          />
        </Grid>
      </Grid>
      {!teacher && (
        <>
          <Grid item container justifyContent="center">
            {imgUrl ? (
              <img
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  height: 300,
                }}
                src={imgUrl}
                onError={({ currentTarget }) => {
                  currentTarget.src = defaultTeacher
                }}
                loading="lazy"
                alt="S3_picture"
              />
            ) : (
              <img
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  height: 300,
                }}
                src={defaultTeacher}
                loading="lazy"
                alt="S3_picture"
              />
            )}
          </Grid>
          <Grid container justifyContent="center">
            <Button color="primary" variant="contained" component="label">
              Selectionner
              <input hidden accept=".jpg,.jpeg,.png,.svg" type="file" onChange={onSelectFile} />
            </Button>
          </Grid>
        </>
      )}
    </FormLayout>
  )
}

export default TeacherForm
