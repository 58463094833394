//REACT
import { useState } from 'react'
//MATERIAL UI
import { makeStyles, Grid, Button, ButtonGroup, TextField, alpha } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import moment from 'moment'
import { ProjectType, UserType } from '../../../../../lib/sharedTypes'
import { isAuthorized } from '../../../utils/misc'
//GQL
import {
  CREATE_LEAVE_MUTATION,
  DELETE_LEAVE_MUTATION,
  CREATE_ASSIGNMENT_MUTATION,
  DELETE_ASSIGNMENT_MUTATION,
} from '../../../gql/mutations'
import { handleError } from '../../../utils/handleError'
import { useAlert } from '../../../hooks'
import { useMutation } from '@apollo/client'
//TYPES
import { LeaveType, AssignmentType } from '../../../../../lib/sharedTypes'
import { startOfDay, endOfDay } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  deleteBTN: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.1),
    },
  },
  sucessBtn: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover': {
      background: alpha(theme.palette.success.main, 0.1),
    },
  },
}))

type CalendarFormPropsType = {
  date: Date
  user: UserType
  defaultTab: string
  project?: ProjectType
  projects: ProjectType[]
  onClose: () => void
  refetchAssignments: () => Promise<any>
  refetchLeaves: () => Promise<any>
}

const CalendarForm = ({
  date,
  user,
  project,
  projects,
  onClose,
  defaultTab = 'leave',
  refetchAssignments,
  refetchLeaves,
}: CalendarFormPropsType) => {
  const classes = useStyles()
  const [tab, setTab] = useState(defaultTab)
  const [setAlert] = useAlert()
  const [selectedProject, setSelectedProject] = useState(project)
  const [startDate, setStartDate] = useState(moment(date).format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment(date).format('YYYY-MM-DD'))

  const [createLeave] = useMutation<{
    createLeave: LeaveType
  }>(CREATE_LEAVE_MUTATION, {
    onError(e) {
      const msg = handleError(e)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetchLeaves()
    },
  })

  const [deleteLeave] = useMutation<{
    deleteLeave: boolean
  }>(DELETE_LEAVE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetchLeaves()
    },
  })

  const [createOrUpdateAssignment] = useMutation<{
    createAssignment: AssignmentType
  }>(CREATE_ASSIGNMENT_MUTATION, {
    onError(e) {
      const msg = handleError(e)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetchAssignments()
    },
  })

  const [deleteAssignment] = useMutation<{
    deleteAssignment: boolean
  }>(DELETE_ASSIGNMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetchAssignments()
    },
  })

  const handleDelete = async () => {
    if (tab === 'leave') {
      await deleteLeave({
        variables: {
          input: {
            user: { id: user.id },
            startDate: startOfDay(new Date(startDate)),
            endDate: endOfDay(new Date(endDate)),
          },
        },
      })
    }
    if (tab === 'editing') {
      await deleteAssignment({
        variables: {
          input: {
            user: { id: user.id },
            startDate: startOfDay(new Date(startDate)),
            endDate: endOfDay(new Date(endDate)),
          },
        },
      })
    }
  }

  const handleCreate = async () => {
    if (tab === 'leave') {
      await createLeave({
        variables: {
          input: {
            user: { id: user.id },
            startDate: startOfDay(new Date(startDate)),
            endDate: endOfDay(new Date(endDate)),
          },
        },
      })
    }
    if (['editing', 'marketing'].includes(tab)) {
      await createOrUpdateAssignment({
        variables: {
          input: {
            type: tab.toUpperCase(),
            user: { id: user.id },
            startDate: startOfDay(new Date(startDate)),
            endDate: endOfDay(new Date(endDate)),
            project: { id: selectedProject?.id },
          },
        },
      })
    }
  }

  return (
    <Grid item container direction="column" style={{ height: '100%', width: '100%' }} spacing={2}>
      <Grid item xs={12}>
        <ButtonGroup fullWidth variant="outlined" color="primary">
          <Button
            variant={tab === 'leave' ? 'contained' : 'outlined'}
            fullWidth
            onClick={() => {
              setTab('leave')
            }}
          >
            Absence
          </Button>
          <Button
            variant={tab === 'editing' ? 'contained' : 'outlined'}
            fullWidth
            onClick={() => {
              setTab('editing')
            }}
          >
            Montage
          </Button>
          <Button
            variant={tab === 'marketing' ? 'contained' : 'outlined'}
            fullWidth
            onClick={() => {
              setTab('marketing')
            }}
          >
            Marketing
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item style={{ flexGrow: 1 }}>
          <TextField
            fullWidth
            variant="outlined"
            id="start"
            label="Début"
            type="date"
            defaultValue={startDate}
            InputLabelProps={{
              shrink: true,
            }}
            required
            onChange={(event) => {
              event.preventDefault()
              setStartDate(event.target.value)
            }}
          />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <TextField
            fullWidth
            variant="outlined"
            id="start"
            label="Fin"
            type="date"
            defaultValue={endDate}
            InputLabelProps={{
              shrink: true,
            }}
            required
            onChange={(event) => {
              event.preventDefault()
              setEndDate(event.target.value)
            }}
          />
        </Grid>
      </Grid>
      {['editing', 'marketing'].includes(tab) && (
        <Grid item>
          <Autocomplete
            disabled={!isAuthorized(['ADMIN', 'PRODUCTION'])}
            defaultValue={selectedProject}
            options={projects?.sort((a, b) => a.code_name.localeCompare(b.code_name)) || []}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.code_name}
            onChange={(_, value) => {
              if (!isAuthorized(['ADMIN', 'PRODUCTION'])) return
              if (value) {
                setSelectedProject(value)
              }
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  required
                  label="Projet"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null,
                  }}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )
            }}
          />
        </Grid>
      )}

      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ marginTop: 10 }}>
        <Grid item>
          <Button
            variant="outlined"
            className={classes.sucessBtn}
            onClick={async () => {
              await handleCreate()
              onClose()
            }}
          >
            Enregistrer
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            className={classes.deleteBTN}
            onClick={async () => {
              await handleDelete()
              onClose()
            }}
          >
            Supprimer
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => {
              onClose()
            }}
          >
            Annuler
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CalendarForm
