import { useState, useEffect } from 'react'
//COMPONENTS
import { Layout, Title, Loading, Error, CustomModal } from '../components'
import { CourseChecks } from '../components/Forms/course'
//MATERIAL UI
import { Divider, Grid, Tabs, Tab, useTheme, Button, Typography, makeStyles, Tooltip } from '@material-ui/core'
//GQL
import { COURSE_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'
//REACT-ROUTER
import { useNavigate, useParams, Outlet } from 'react-router-dom'
//TYPES
import { CourseType } from '../../../lib/sharedTypes'
import { useLocation } from 'react-router'
//UTILS
import { isCourseManager, isAuthorized } from '../utils/misc'

const tabs = [
  'informations générales',
  'leçons',
  'séquencier',
  'questions',
  'compétences',
  'ressources',
  'résumés',
  'schémas',
  'feedback',
]

const useStyles = makeStyles((theme) => ({
  publishBTN: {
    color: 'white',
    width: 100,
    background: theme.palette.success.main,
    '&:hover': {
      background: theme.palette.success.light,
    },
  },
}))

const Course = () => {
  const classes = useStyles()
  const theme = useTheme()
  const location = useLocation()
  const { code_name } = useParams()
  const navigate = useNavigate()
  const [publishCheckModal, setPublishCheckModal] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(tabs[0])

  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{
    course: CourseType
  }>(COURSE_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: 'position',
        sort: 'ASC',
      },
    },
  })

  useEffect(() => {
    const tab = location.pathname.split('/').at(-1)
    switch (tab) {
      case 'general_informations':
        setActiveTab('informations générales')
        break
      case 'video':
      case 'text':
      case 'epp':
      case 'document':
      case 'quiz':
      case 'lessons':
      case 'synthese':
      case 'task':
        setActiveTab('leçons')
        break
      case 'plan':
        setActiveTab('séquencier')
        break
      case 'questions':
        setActiveTab('questions')
        break
      case 'skills':
        setActiveTab('compétences')
        break
      case 'resources':
        setActiveTab('ressources')
        break
      case 'summaries':
        setActiveTab('résumés')
        break
      case 'schemas':
        setActiveTab('schémas')
        break
      case 'feedback':
        setActiveTab('feedback')
        break
      default:
        setActiveTab('informations générales')
        break
    }
  }, [location])

  if (loading || !course) {
    return (
      <Layout title={code_name || 'Formation'}>
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title={code_name || 'Formation'}>
        <Error error={error} />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Formation">
        <Error error="Unknown code_name" />
      </Layout>
    )
  }

  const subCourses =
    course.sub_courses.length !== 0 ? [course?.code_name].concat(course?.sub_courses.map((sc) => sc.code_name)) : []

  const handleTabChange = (tab: string) => {
    switch (tab) {
      case 'informations générales':
        navigate('general_informations')
        break
      case 'leçons':
        navigate('lessons')
        break
      case 'séquencier':
        navigate('plan')
        break
      case 'questions':
        navigate('questions')
        break
      case 'compétences':
        navigate('skills')
        break
      case 'ressources':
        navigate('resources')
        break
      case 'résumés':
        navigate('summaries')
        break
      case 'schémas':
        navigate('schemas')
        break
      case 'feedback':
        navigate('feedback')
        break
      default:
        console.error(`Unknown tab ${activeTab}`)
        break
    }
    setActiveTab(tab)
  }

  const sortedPublications = course.publications.sort((a, b) => {
    return new Date(b.published_at).getTime() - new Date(a.published_at).getTime()
  })

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={code_name} />
          </Grid>

          <Grid item style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container>
              <Grid
                item
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  marginRight: theme.spacing(1),
                }}
              >
                <Button
                  component="a"
                  variant="outlined"
                  color="primary"
                  disabled={course.projects.length === 0}
                  onClick={(e: React.MouseEvent) => {
                    if (e.metaKey || e.ctrlKey) {
                      window.open(`/projects/${course.projects[0].code_name}`, '_blank')
                    } else {
                      navigate(`/projects/${course.projects[0].code_name}`)
                    }
                  }}
                  style={{ marginRight: theme.spacing(1) }}
                >
                  Projet
                </Button>
                <Button
                  component="a"
                  variant="outlined"
                  color="primary"
                  disabled={!course.product}
                  onClick={(e: React.MouseEvent) => {
                    if (e.metaKey || e.ctrlKey) {
                      window.open(`/products/${course.product.code_name}`, '_blank')
                    } else {
                      navigate(`/products/${course.product.code_name}`)
                    }
                  }}
                >
                  Produit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.publishBTN}
                  disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
                  onClickCapture={() => setPublishCheckModal(true)}
                >
                  {course?.published_on ? 'Republier' : 'Publier'}
                </Button>
              </Grid>
              <Grid item container direction="column" alignItems="flex-end">
                {sortedPublications.length !== 0 ? (
                  <Tooltip
                    title={`Publié par ${sortedPublications[0]?.user.first_name} ${sortedPublications[0]?.user?.last_name}`}
                  >
                    <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                      Dernière publication:{' '}
                      {sortedPublications.length !== 0
                        ? new Date(sortedPublications[0].published_at).toLocaleString()
                        : 'Non publié'}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                    Dernière publication: Non publiée
                  </Typography>
                )}
                <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                  Dernière modification: {new Date(course.updated_at).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Grid style={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(1), minWidth: '100%' }}>
            <Divider />
            <Tabs value={tabs.indexOf(activeTab)} indicatorColor="primary" scrollButtons="on" variant="scrollable">
              {tabs.map((tab, index) => {
                return (
                  <Tab
                    key={index}
                    label={tab}
                    disabled={
                      subCourses.length !== 0 && ['leçons', 'séquencier', 'ressources', 'questions'].includes(tab)
                    }
                    onClick={() => {
                      handleTabChange(tab)
                    }}
                  />
                )
              })}
            </Tabs>
          </Grid>
        </Grid>
        <Grid item style={{ display: 'flex', flexGrow: 1, width: '100%' }}>
          <Outlet context={{ refetchCourse: refetch }} />
        </Grid>
        <CustomModal
          animation="slide-up"
          open={publishCheckModal}
          onClose={() => {
            setPublishCheckModal(false)
          }}
        >
          <CourseChecks course={course} handleClose={() => setPublishCheckModal(false)} refetch={refetch} />
        </CustomModal>
      </Grid>
    </Layout>
  )
}

export default Course
