import { useState } from 'react'
// MATERIAL UI
import { Autocomplete } from '@material-ui/lab'
import { Grid, TextField } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
import TextEditor from '../../../TextEditor'
//TYPE
import { LessonType, CourseType, LessonDactyloType, LessonVideoType } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { COURSE_LESSONS_VIDEO, COURSES_QUERY } from '../../../../gql/queries'
import { UPDATE_LESSON_DACTYLO_MUTATION, CREATE_LESSON_DACTYLO_MUTATION } from '../../../../gql/mutations'
import { useAlert } from '../../../../hooks'
//UTILS
import { handleError } from '../../../../utils/handleError'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import { DeepPartial } from '../../../../utils/types'

type LessonDactyloFormPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const LessonDactyloForm = ({ lesson, refetch, handleClose }: LessonDactyloFormPropsType) => {
  console.log('lesson', lesson)
  const [setAlert] = useAlert()
  const { code_name } = useParams<{ code_name: string }>()
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const [selectedCourse, setSelectedCourse] = useState<CourseType>()
  const [lessonVideos, setLessonVideos] = useState<LessonVideoType[] | undefined>()

  const { data: { courses = [] } = {} } = useQuery<{
    courses: CourseType[]
  }>(COURSES_QUERY, {
    variables: {
      order: {
        field: 'CODE_NAME',
        sort: 'ASC',
      },
    },
  })

  const { data: { course } = {}, refetch: refetchLessons } = useQuery<{ course: CourseType }>(COURSE_LESSONS_VIDEO, {
    variables: {
      where: {
        code_name: {
          eq: selectedCourse?.code_name ?? lesson?.dactylo.lesson_video.lesson.course.code_name ?? code_name,
        },
      },
      order: {
        field: 'name',
        sort: 'ASC',
      },
    },
    onCompleted(data) {
      setSelectedCourse(data.course)
      setLessonVideos(data.course.video_lessons)
    },
  })

  const [updateLessonDactyloMutation] = useMutation<{
    updateLessonDactylo: LessonDactyloType
  }>(UPDATE_LESSON_DACTYLO_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon dactylo mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const [createLessonDactyloMutation] = useMutation<{
    createLessonDactylo: LessonDactyloType
  }>(CREATE_LESSON_DACTYLO_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createLessonDactylo.lesson.name} créé` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log('stateLesson', stateLesson)
    const lessonDactyloInput: Record<string, any> = {
      id: stateLesson?.dactylo?.id,
      transcript: stateLesson?.dactylo?.transcript,
      lesson_video: { id: stateLesson?.dactylo?.lesson_video?.lesson?.id },
      pausable: stateLesson?.dactylo?.pausable,
    }
    if (stateLesson?.id) {
      await updateLessonDactyloMutation({
        variables: {
          input: lessonDactyloInput,
        },
      })
    } else {
      console.log('CREATE', lessonDactyloInput)
      lessonDactyloInput.lesson = {
        course: { id: course?.id },
        validated: stateLesson?.validated || false,
        summary_required: stateLesson?.summary_required || false,
        name: stateLesson?.name,
        summary_state: stateLesson?.summary_state,
        summary: stateLesson?.summary,
      }
      await createLessonDactyloMutation({ variables: { input: lessonDactyloInput } })
    }
  }

  return (
    <FormLayout
      type={stateLesson?.id ? 'update' : 'create'}
      title={stateLesson?.id ? 'Mettre à jour la leçon dactylo' : 'Créer une leçon dactylo'}
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateLesson) === JSON.stringify(lesson)}
    >
      <Grid item container spacing={2} alignItems="center">
        {!stateLesson?.id && (
          <Grid item xs={12}>
            <TextField
              required
              label="Nom"
              name="name"
              defaultValue={stateLesson?.name}
              onChange={(event) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    ...{ [event.target.name]: event.target.value },
                  }
                })
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={6}>
          {selectedCourse && (
            <Autocomplete
              key={selectedCourse.id}
              fullWidth
              value={selectedCourse}
              options={courses}
              getOptionSelected={(option, value) => option.code_name === value.code_name}
              getOptionLabel={(option) => option?.code_name || ''}
              onChange={async (_, value) => {
                if (value) {
                  setSelectedCourse(value)
                  setLessonVideos(undefined)
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      dactylo: {
                        ...prevState?.dactylo,
                        lesson_video: undefined,
                      },
                    }
                  })
                  await refetchLessons()
                }
              }}
              renderInput={(params) => {
                return <TextField {...params} variant="outlined" label="Formation" />
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {lessonVideos && (
            <Autocomplete
              fullWidth
              value={stateLesson?.dactylo?.lesson_video}
              options={lessonVideos}
              getOptionSelected={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.lesson?.name || ''}
              onChange={(_, value) => {
                if (value) {
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      dactylo: {
                        ...prevState?.dactylo,
                        lesson_video: value,
                      },
                    }
                  })
                }
              }}
              renderInput={(params) => {
                return <TextField {...params} variant="outlined" label="Leçon vidéo" />
              }}
            />
          )}
        </Grid>
        {/* <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateLesson?.dactylo?.pausable}
                  onChange={(event) => {
                    setStateLesson((prevState) => {
                      return {
                        ...prevState,
                        dactylo: {
                          ...prevState?.dactylo,
                          pausable: event.target.checked,
                        },
                      }
                    })
                  }}
                  name="pausable"
                />
              }
              label={stateLesson?.dactylo?.pausable ? 'Possible de pauser la vidéo' : 'Impossible de pauser la vidéo'}
            />
          </FormGroup>
        </Grid> */}
        <Grid item xs={12}>
          <TextEditor
            type="dactylo"
            text={stateLesson?.dactylo?.transcript}
            onChange={(json) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  dactylo: {
                    ...prevState?.dactylo,
                    transcript: json,
                  },
                }
              })
            }}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default LessonDactyloForm
