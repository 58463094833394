import ReactDOM from 'react-dom/client'
import App from './App'
//REDUX
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { store } from './store/store'
import { Provider } from 'react-redux'
//APOLLO
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './utils/apolloClient'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const persistor = persistStore(store)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </PersistGate>
  </Provider>,
)
