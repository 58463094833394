//REACT
import React, { useState, useEffect } from 'react'
//ASSETS
import defaultTeacher from '../../../assets/defaultTeacher.png'
// MATERIAL UI
import { Grid, Button } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../index'
//TYPES
import { TeacherType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { CREATE_TEACHER_MUTATION, UPDATE_TEACHER_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type ImageFormPropsType = {
  teacher?: TeacherType
  refetch: () => void
  handleClose: () => void
}

const ImageForm = ({ teacher, refetch, handleClose }: ImageFormPropsType) => {
  const [setAlert] = useAlert()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [localImg, setLocalImg] = useState<string>()
  const imgUrl = localImg ? localImg : teacher?.s3_picture
  const [createTeacher] = useMutation<{
    createTeacher: TeacherType
  }>(CREATE_TEACHER_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createTeacher.first_name} créé` })
      refetch()
      handleClose()
    },
  })

  const [updateTeacher] = useMutation<{
    updateTeacher: TeacherType
  }>(UPDATE_TEACHER_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateTeacher.first_name} mis-à-jour` })
      refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const teacherInput = {
      id: teacher?.id,
      first_name: teacher?.first_name,
      last_name: teacher?.last_name,
      email: teacher?.email,
      phone_number: teacher?.phone_number,
      mobile_number: teacher?.mobile_number,
      introduction: teacher?.introduction,
      s3_picture: selectedFile,
    }

    if (teacher?.id) {
      await updateTeacher({ variables: { input: teacherInput } })
    } else {
      await createTeacher({ variables: { input: teacherInput } })
    }
  }

  useEffect(() => {
    if (!selectedFile) {
      setLocalImg(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setLocalImg(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  return (
    <FormLayout
      type={teacher?.id ? 'update' : 'create'}
      title={teacher?.id ? 'Mettre à jour la photo de profil' : 'Ajouter la photo de profil'}
      onSubmit={handleSubmit}
      submitDisabled={!localImg}
    >
      <Grid item container justifyContent="center">
        {imgUrl ? (
          <img
            style={{
              objectFit: 'contain',
              maxWidth: '100%',
              height: 450,
            }}
            src={imgUrl}
            onError={({ currentTarget }) => {
              currentTarget.src = defaultTeacher
            }}
            loading="lazy"
            alt="S3_picture"
          />
        ) : (
          <img
            style={{
              objectFit: 'contain',
              maxWidth: '100%',
              height: 450,
            }}
            src={defaultTeacher}
            loading="lazy"
            alt="S3_picture"
          />
        )}
      </Grid>
      <Grid container justifyContent="center">
        <Button color="primary" variant="contained" component="label">
          Selectionner
          <input hidden accept=".jpg,.jpeg,.png,.svg" type="file" onChange={onSelectFile} />
        </Button>
      </Grid>
    </FormLayout>
  )
}

export default ImageForm
