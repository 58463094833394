import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { TMP_LESSON_TEXT } from '../gql/queries'
import { Lesson } from '../../../server/src/entities'
import { TextEditorJS } from '../components'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { UPDATE_LESSON_TEXT_MUTATION } from '../gql/mutations'
import { Button } from '@material-ui/core'

function Converter() {
  const [index, setIndex] = useState(0)
  const {
    loading,
    error,
    data: { lessons = [] } = {},
  } = useQuery<{
    lessons: Lesson[]
  }>(TMP_LESSON_TEXT, {
    variables: {
      where: {
        text: {
          content: {
            ne: null,
          },
        },
      },
    },
  })

  const [selectedLesson, setSelectedLesson] = useState<{
    lesson: Lesson
    html: string
    editorjs: JSON
  } | null>(null)
  const [updateLessonText] = useMutation(UPDATE_LESSON_TEXT_MUTATION, {
    onCompleted: () => {
      handleNext()
    },
  })

  const handleNext = () => {
    if (lessons.length > 0) {
      setSelectedLesson(null)
      setIndex(index + 1)
      setSelectedLesson({
        lesson: lessons[index],
        html: '',
        editorjs: new Object() as JSON,
      })
    }
  }

  useEffect(() => {
    // convert to html if selectedLesson.text.content.ops is not null
    if (selectedLesson?.lesson?.text?.content && (selectedLesson.lesson?.text?.content as Record<string, any>).ops) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const converter = new QuillDeltaToHtmlConverter(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        (selectedLesson.lesson?.text?.content as Record<string, any>).ops,
        {},
      )

      const html = converter.convert()

      setSelectedLesson({
        ...selectedLesson,
        html,
      })
    } else {
      handleNext()
    }
  }, [selectedLesson?.lesson])

  return (
    <div>
      <h1>Converter</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error</p>}
      {lessons.length > 0 && (
        <p>
          {index}/{lessons.length} - {selectedLesson && selectedLesson.lesson && selectedLesson?.lesson.name}
        </p>
      )}
      {/* {<p>{(selectedLesson?.lesson?.text?.content as Record<string, any>).ops === null ? 'oui' : 'non'}</p>} */}

      <div style={{ maxWidth: 700, margin: '0 auto' }}>
        {selectedLesson && selectedLesson.html && selectedLesson.lesson.text.content && (
          <TextEditorJS
            content={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              (selectedLesson.lesson?.text?.content as Record<string, any>).ops &&
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              (selectedLesson.lesson?.text?.content as Record<string, any>).ops.length > 0
                ? selectedLesson?.lesson?.text.content
                : null
            }
            legacy={selectedLesson?.html}
            onChange={(content: JSON) => {
              setSelectedLesson({
                ...selectedLesson,
                editorjs: content,
              })
            }}
          />
        )}
      </div>

      <Button
        onClick={() => {
          handleNext()
        }}
        variant="contained"
        color="primary"
      >
        Next
      </Button>

      <Button
        onClick={async () => {
          await updateLessonText({
            variables: {
              input: {
                id: selectedLesson?.lesson?.text?.id,
                content:
                  selectedLesson?.editorjs && Object.keys(selectedLesson?.editorjs).length === 0
                    ? {
                        time: 1616500000000,
                        blocks: [],
                        version: '2.26.5',
                      }
                    : selectedLesson?.editorjs,
              },
            },
          })
        }}
        disabled={!selectedLesson?.editorjs}
        variant="contained"
        color="primary"
      >
        Update
      </Button>
    </div>
  )
}

export default Converter
