// REACT
import { useState } from 'react'
// MATERIAL UI
import { Grid, Typography, Divider, Button, Paper, useTheme, makeStyles, Chip } from '@material-ui/core'
// COMPONENTS
import { GeneralInformationsForm } from '../../components/Forms/project'
import { Loading, Error, CustomModal } from '../../components'
//GQL
import { PROJECT_INFO_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
// TYPE
import { ProjectType } from '../../../../lib/sharedTypes'
//UTILS
import { isAuthorized, isCourseManager, secondsToDHMS } from '../../utils/misc'
//REACT ROUTER
import { useParams } from 'react-router'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const GeneralInformations = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { code_name } = useParams()
  const [updateModal, setUpdateModal] = useState('')
  const {
    loading,
    error,
    data: { project } = {},
    refetch,
  } = useQuery<{ project: ProjectType }>(PROJECT_INFO_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  if (loading || !project) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column">
      {/* DESCRIPTION */}
      <Paper className={classes.paper}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Description</Typography>
            <Grid
              item
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                marginRight: theme.spacing(1),
              }}
            >
              <Grid item>
                <Button
                  color="default"
                  variant="outlined"
                  onClick={() => {
                    window.open(`https://sync.production.walter-learning.com/${project.storage_name}`, '_blank')
                  }}
                  style={{ marginRight: theme.spacing(1) }}
                >
                  Synchro
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR']) || isCourseManager(project.course))}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setUpdateModal('description')
                  }}
                >
                  Modifier
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item container direction="row">
            <Grid item container direction="column" xs={6}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Titre
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{project.name}</Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={6}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Nom de code
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{project.code_name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item container direction="row">
            <Grid item container direction="column" xs={4}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Durée de production
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{secondsToDHMS(project.duration_production)}</Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={4}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Durée cible
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{secondsToDHMS(project.duration_target)}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={4}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Durée des rushes
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {secondsToDHMS(
                    project.video_sets.reduce((acc, el) => {
                      acc += el.duration
                      return acc
                    }, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Formation
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{project.course.code_name}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.title}>
            Réalisateur(s)
          </Typography>
          <Grid>
            {project?.members
              .filter((user) => user.job.category === 'DIRECTOR')
              .map((member) => (
                <Chip
                  size="small"
                  color="primary"
                  key={member.id}
                  label={member.username}
                  style={{ margin: theme.spacing(1) }}
                />
              ))}
          </Grid>
        </Grid>
      </Paper>
      <CustomModal
        open={updateModal === 'description'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <GeneralInformationsForm
          project={project}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default GeneralInformations
