import { useEffect, useState } from 'react'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  IconButton,
  useTheme,
} from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
import { Error, DurationInput, MultiSelectChips, CustomModal } from '../../index'
// TYPE
import { ProductStatusEnum, ProductType, TeacherType, TeachingMethodType } from '../../../../../lib/sharedTypes'
//GQL
import { PRODUCTS_QUERY, TEACHERS_QUERY, TEACHING_METHODS_QUERY, COURSES_DEMO_QUERY } from '../../../gql/queries'
import { useQuery, useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import moment from 'moment'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import TeachingMethodForm from './TeachingMethodForm'
import { GoPencil } from 'react-icons/go'
import { isAuthorized } from '../../../utils/misc'

type GeneralInformationsFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const GeneralInformationsForm = ({ product, refetch, handleClose }: GeneralInformationsFormPropsType) => {
  const [setAlert] = useAlert()
  const theme = useTheme()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const { error: errorProducts, data: { products } = {} } = useQuery<{ products: ProductType[] }>(PRODUCTS_QUERY, {
    variables: { order: { field: 'code_name', sort: 'ASC' } },
  })
  const { error: errorTeachers, data: { teachers } = {} } = useQuery<{ teachers: TeacherType[] }>(TEACHERS_QUERY)

  const { error: errorDemoAccounts, data: { courses: demoAccounts } = {} } = useQuery<{
    courses: { id: number; name: string; code_name: string }[]
  }>(COURSES_DEMO_QUERY, {
    variables: { where: { code_name: { startsWith: 'DEMO_' } } },
  })

  const [teachingMethod, setTeachingMethodModal] = useState(false)
  const [selectedTeachingMethod, setSelectedTeachingMethod] = useState<TeachingMethodType>()

  useEffect(() => {
    setStateProduct(product)
  }, [product])

  const {
    error: errorTeachingMethods,
    data: { teachingMethods } = {},
    refetch: refetchTM,
  } = useQuery<{ teachingMethods: TeachingMethodType[] }>(TEACHING_METHODS_QUERY, {
    variables: { order: { field: 'position', sort: 'ASC' } },
  })

  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (errorTeachers) {
    return <Error error={errorTeachers} />
  }

  if (errorTeachingMethods) {
    return <Error error={errorTeachingMethods} />
  }

  if (errorProducts) {
    return <Error error={errorProducts} />
  }

  if (errorDemoAccounts) {
    return <Error error={errorDemoAccounts} />
  }

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput: Record<string, any> = {
      ...stateProduct,
      certification: stateProduct?.certification && {
        ...stateProduct.certification,
        certifier: { id: stateProduct?.certification?.certifier?.id },
      },
      courses: stateProduct?.courses?.map((course) => {
        return { id: course.id }
      }),
      funding_organisms: stateProduct?.funding_organisms?.map((fo) => {
        return { id: fo.id }
      }),
      recommendations: stateProduct?.recommendations?.map((reco) => {
        return { id: reco.id }
      }),
      sale_processes: stateProduct?.sale_processes?.map((sp) => {
        return { id: sp.id }
      }),
      teachers: stateProduct?.teachers?.map((teacher) => {
        return { id: teacher.id }
      }),
      skills: stateProduct?.skills?.map((skill) => {
        return { id: skill.id }
      }),
      parts: stateProduct?.parts?.map((part) => {
        return { id: part.id }
      }),
      teaching_methods: stateProduct?.teaching_methods?.map((sp) => {
        return { id: sp.id }
      }),
    }
    if (productInput.catalogs) {
      delete productInput.catalogs
    }
    if (productInput.catalog_funding_organisms) {
      delete productInput.catalog_funding_organisms
    }

    if (productInput.product_catalog_positions) {
      delete productInput.product_catalog_positions
    }

    await updateProductMutation({ variables: { input: productInput } })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStateProduct((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour la description"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProduct) === JSON.stringify(product)}
    >
      <Grid item container direction="row">
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateProduct.status === 'AVAILABLE'}
                  onChange={(event) => {
                    setStateProduct((prevState) => {
                      return {
                        ...prevState,
                        status: event.target.checked ? ProductStatusEnum.AVAILABLE : ProductStatusEnum.NOT_AVAILABLE,
                      }
                    })
                  }}
                />
              }
              label={
                stateProduct.status === 'AVAILABLE'
                  ? 'Le produit est affiché sur le showcase'
                  : "Le produit n'est pas affiché sur le showcase"
              }
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateProduct.available_for_self_registration}
                  onChange={(event) => {
                    setStateProduct((prevState) => {
                      return {
                        ...prevState,
                        available_for_self_registration: event.target.checked,
                      }
                    })
                  }}
                />
              }
              label={
                stateProduct.available_for_self_registration
                  ? "Le produit est disponible à l'inscription autonome"
                  : "Le produit n'est pas disponible à l'inscription autonome"
              }
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            label="Titre"
            name="title"
            value={stateProduct.title}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Sous-titre"
            name="subtitle"
            value={stateProduct.subtitle || ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            helperText="Quelques mots qualifiant la formation."
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <DurationInput
            label="Durée de la formation"
            format="hh"
            defaultValue={stateProduct.duration}
            onChange={(duration) => {
              setStateProduct((prevState) => {
                return {
                  ...prevState,
                  duration: duration,
                }
              })
            }}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="release_date"
              label="Date de sortie"
              type="date"
              defaultValue={product.release_date ? moment(product.release_date).format('YYYY-MM-DD') : undefined}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              required
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              value={stateProduct.format}
              options={['PI', 'FC', 'EPP']}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => `${option}`}
              onChange={(_, value) => {
                if (value) {
                  setStateProduct({
                    ...stateProduct,
                    format: value,
                  })
                }
              }}
              renderInput={(params) => {
                return <TextField {...params} variant="outlined" label="Format" />
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          value={stateProduct.demo_formation_code || null}
          options={demoAccounts?.map((demo) => demo.code_name) || []}
          getOptionSelected={(option, value) => option === value}
          getOptionLabel={(option) => `${option}`}
          onChange={(_, value) => {
            setStateProduct({
              ...stateProduct,
              demo_formation_code: value || null,
            })
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Compte Démo" />
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Titre SMS"
          name="short_name"
          value={stateProduct.short_name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          InputProps={{
            inputProps: { maxLength: 50 },
          }}
          helperText="Titre utilisé pour les SMS (faire au plus court, sans accents)"
        />
      </Grid>

      <Grid item>
        <TextField
          label="Public adressé"
          name="public"
          value={stateProduct.public || ''}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          label="Prérequis"
          multiline
          minRows={5}
          maxRows={5}
          name="prerequisite"
          value={stateProduct.prerequisite || ''}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          label="Description de la formation"
          name="preamble"
          value={stateProduct.preamble || ''}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          multiline
          minRows={4}
        />
      </Grid>
      <Grid item spacing={1} container justifyContent="space-between">
        <Grid item sm={4}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateProduct.marked_as_new}
                  onChange={(event) => {
                    setStateProduct((prevState) => {
                      return {
                        ...prevState,
                        marked_as_new: event.target.checked,
                      }
                    })
                  }}
                />
              }
              label={stateProduct.marked_as_new ? 'Afficher comme nouveau' : 'Ne pas afficher comme nouveau'}
            />
          </FormGroup>
        </Grid>
        <Grid item sm={4}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateProduct.epp_available}
                  onChange={(event) => {
                    setStateProduct((prevState) => {
                      return {
                        ...prevState,
                        epp_available: event.target.checked,
                      }
                    })
                  }}
                />
              }
              label={stateProduct.epp_available ? 'Programme intégré' : 'Formation continue'}
            />
          </FormGroup>
        </Grid>
        <Grid item sm={4}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateProduct.has_cv}
                  onChange={(event) => {
                    setStateProduct((prevState) => {
                      return {
                        ...prevState,
                        has_cv: event.target.checked,
                      }
                    })
                  }}
                />
              }
              label={stateProduct.has_cv ? 'Classe virtuelle' : 'Sans classe virtuelle'}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          label="Score"
          name="push_score"
          type="number"
          inputProps={{ min: 0 }}
          value={stateProduct.push_score || 0}
          onChange={(event) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                push_score: parseInt(event.target.value),
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <Autocomplete
          multiple
          defaultValue={stateProduct.teachers ? stateProduct.teachers : []}
          options={teachers || []}
          getOptionSelected={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => `${option.first_name}  ${option.last_name}`}
          onChange={(_, value) => {
            if (value) {
              setStateProduct({
                ...stateProduct,
                teachers: value,
              })
            }
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                label="Formateur(s)"
                required={!stateProduct.teachers || stateProduct?.teachers?.length === 0}
              />
            )
          }}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          multiple
          defaultValue={stateProduct.recommendations ? stateProduct.recommendations : []}
          options={products || []}
          getOptionSelected={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option.code_name}
          onChange={(_, value) => {
            if (value) {
              setStateProduct({
                ...stateProduct,
                recommendations: value,
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Recommendation(s)" />
          }}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          multiple
          freeSolo
          defaultValue={stateProduct.aliases || []}
          options={stateProduct.aliases || []}
          getOptionSelected={(option, value) => option === value}
          getOptionLabel={(option) => option}
          onChange={(_, value) => {
            if (value) {
              setStateProduct({
                ...stateProduct,
                aliases: value,
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Alias" />
          }}
        />
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Grid>
            <Typography variant="h6">Méthodes pédagogiques</Typography>
            <Typography variant="body2">Sélection des méthodes pédagogiques du produit </Typography>
          </Grid>
          {isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) && (
            <Grid>
              <IconButton
                onClick={() => {
                  setTeachingMethodModal(true)
                }}
              >
                <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <MultiSelectChips
            multiSelectLabel="Méthodes pédagogiques"
            keyToUpdate="teaching_methods"
            sort={false}
            labels={teachingMethods?.map((tm) => tm.name) || []}
            rightChildren={isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) ? <GoPencil /> : null}
            rightClick={(label: string) => {
              if (!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO'])) return
              const tm = teachingMethods?.find((tm) => tm.name === label)
              if (tm) {
                setTeachingMethodModal(true)
                setSelectedTeachingMethod(tm)
              }
            }}
            selectedLabelsList={stateProduct.teaching_methods ? stateProduct.teaching_methods.map((tm) => tm.name) : []}
            handleChange={(key, values) => {
              const value = teachingMethods?.filter((tm: TeachingMethodType) => values.includes(tm.name))
              setStateProduct((prevState) => {
                if (!prevState) return prevState
                return {
                  ...prevState,
                  ...{ [key]: value },
                }
              })
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid item>
            <Autocomplete
              multiple
              freeSolo
              defaultValue={stateProduct.public_keywords || []}
              options={stateProduct.public_keywords || []}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option}
              onChange={(_, value) => {
                if (value) {
                  setStateProduct({
                    ...stateProduct,
                    public_keywords: value,
                  })
                }
              }}
              renderInput={(params) => {
                return <TextField {...params} variant="outlined" label="Public" />
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item>
            <Autocomplete
              multiple
              freeSolo
              defaultValue={stateProduct.key_strengths_formation || []}
              options={stateProduct.key_strengths_formation || []}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option}
              onChange={(_, value) => {
                if (value) {
                  setStateProduct({
                    ...stateProduct,
                    key_strengths_formation: value,
                  })
                }
              }}
              renderInput={(params) => {
                return <TextField {...params} variant="outlined" label="Points forts formation" />
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item>
            <Autocomplete
              multiple
              freeSolo
              defaultValue={stateProduct.key_strengths_walter || []}
              options={stateProduct.key_strengths_walter || []}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option}
              onChange={(_, value) => {
                if (value) {
                  setStateProduct({
                    ...stateProduct,
                    key_strengths_walter: value,
                  })
                }
              }}
              renderInput={(params) => {
                return <TextField {...params} variant="outlined" label="Points forts Walter Learning" />
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <CustomModal
        size="xs"
        title={`${selectedTeachingMethod ? 'Modifier' : 'Ajouter'} une méthode pédagogique`}
        open={teachingMethod}
        onClose={() => {
          setTeachingMethodModal(false)
          setSelectedTeachingMethod(undefined)
        }}
      >
        <TeachingMethodForm
          handleClose={() => {
            setTeachingMethodModal(false)
            setSelectedTeachingMethod(undefined)
          }}
          refetch={async () => {
            await refetch()
            await refetchTM()
          }}
          selectedTeachingMethod={selectedTeachingMethod}
        />
      </CustomModal>
    </FormLayout>
  )
}

export default GeneralInformationsForm
