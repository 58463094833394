import {
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Button,
} from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { useState } from 'react'
import { COURSE_FEEDBACK_QUERY } from '../../gql/queries'
import { CREATE_COURSE_FEEDBACK } from '../../gql/mutations'
import { useMutation, useQuery } from '@apollo/client'
import { CourseFeedbackType, CourseType } from '../../../../lib/sharedTypes'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
//ROUTER
import { useParams } from 'react-router'

type FeedbackFormType = {
  teacher_rating: number
  difficulties: string[]
  other_difficulty: string
  cdp_acdp_rating: number
  teacher_recruiting_improvement: string
  setup_teacher_rating: number
  shooting_rating: number
  shooting_duration_rating: number
  pre_prod_improvement: string
  design: null | boolean
  editor: null | boolean
  project_manager: null | boolean
  project_manager_director_editor_rating: number
  post_production_improvement: string
  course: { id: string }
}

const Feedback = () => {
  const theme = useTheme()
  const { code_name } = useParams()
  const [setAlert] = useAlert()
  const [disabled, setDisabled] = useState(false)
  const [state, setState] = useState<FeedbackFormType>({
    teacher_rating: 0,
    difficulties: [],
    other_difficulty: '',
    cdp_acdp_rating: 0,
    teacher_recruiting_improvement: '',
    setup_teacher_rating: 0,
    shooting_rating: 0,
    shooting_duration_rating: 0,
    pre_prod_improvement: '',
    design: null,
    editor: null,
    project_manager: null,
    project_manager_director_editor_rating: 0,
    post_production_improvement: '',
    course: { id: '' },
  })

  const defaultDifficulties = [
    'Rareté des spécialistes',
    'Problème de rémunération',
    'Impossibilité de former une équipe pédagogique complète',
    'Contraintes de calendrier',
  ]

  const { refetch } = useQuery<{ course: CourseType }>(COURSE_FEEDBACK_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
    onCompleted(data) {
      if (data.course.feedback) {
        setDisabled(true)
        const other_difficulty = data.course.feedback.difficulties.filter((d) => {
          return !defaultDifficulties.includes(d)
        })[0]
        setState((prevState) => {
          return {
            ...prevState,
            teacher_rating: data.course.feedback.teacher_rating,
            difficulties: other_difficulty
              ? [...data.course.feedback.difficulties, 'Autre:']
              : data.course.feedback.difficulties,
            other_difficulty: other_difficulty,
            cdp_acdp_rating: data.course.feedback.cdp_acdp_rating,
            teacher_recruiting_improvement: data.course.feedback.teacher_recruiting_improvement,
            setup_teacher_rating: data.course.feedback.setup_teacher_rating,
            shooting_rating: data.course.feedback.shooting_rating,
            shooting_duration_rating: data.course.feedback.shooting_duration_rating,
            pre_prod_improvement: data.course.feedback.pre_prod_improvement,
            design: data.course.feedback.design,
            editor: data.course.feedback.editor,
            project_manager: data.course.feedback.project_manager,
            project_manager_director_editor_rating: data.course.feedback.project_manager_director_editor_rating,
            post_production_improvement: data.course.feedback.post_production_improvement,
          }
        })
      }
      setState((prevState) => {
        return {
          ...prevState,
          course: { id: `${data.course.id}` },
        }
      })
    },
  })

  const requiredFields = [
    'teacher_rating',
    'difficulties',
    'cdp_acdp_rating',
    'setup_teacher_rating',
    'shooting_rating',
    'shooting_duration_rating',
    'design',
    'editor',
    'project_manager',
    'project_manager_director_editor_rating',
  ]

  const [errors, setErrors] = useState<string[]>([])

  const checkFields = () => {
    const errors: string[] = []
    requiredFields.map((field) => {
      if (field === 'difficulties' && state.difficulties.length === 0) {
        errors.push(field)
        return
      } else if (
        ['design', 'editor', 'project_manager'].includes(field) &&
        state[field as keyof FeedbackFormType] === null
      ) {
        errors.push(field)
        return
      } else {
        if (!state[field as keyof FeedbackFormType] && !['design', 'editor', 'project_manager'].includes(field)) {
          errors.push(field)
          return
        }
      }
    })
    return errors
  }

  const [createFeedback] = useMutation<{
    createFeedback: CourseFeedbackType
  }>(CREATE_COURSE_FEEDBACK, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Feedback enregistré` })
      await refetch()
    },
  })

  const handleSubmit = async () => {
    const errors = checkFields()
    if (errors.length !== 0) {
      setErrors(errors)
      return
    } else {
      const feedbackInput: Partial<FeedbackFormType> = state
      if (feedbackInput.other_difficulty && feedbackInput.difficulties) {
        feedbackInput.difficulties = feedbackInput.difficulties.map((d: string) => {
          if (d === 'Autre:' && feedbackInput.other_difficulty) {
            return feedbackInput.other_difficulty
          } else {
            return d
          }
        })
      }

      delete feedbackInput.other_difficulty
      await createFeedback({ variables: { input: feedbackInput } })
    }
  }

  return (
    <Grid container direction="column" justifyContent="flex-start" spacing={1} style={{ width: '100%' }}>
      <Paper style={{ padding: theme.spacing(2) }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h4">Recueil de l'appréciation - Équipe pédagogique</Typography>
            <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
          </Grid>
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: theme.spacing(2) }}>
              Recherche de formateur
            </Typography>
          </Grid>
          <Grid item container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="body1">
                Sur une échelle de 1 à 5, comment la recherche des formateurs s’est-elle déroulée ?
                <span style={{ color: 'red' }}> *</span>
              </Typography>
              <Rating
                disabled={disabled}
                name="teacher_rating"
                value={state?.teacher_rating}
                onChange={(_, value) => {
                  setState((prevState) => {
                    if (!value) return prevState
                    return {
                      ...prevState,
                      teacher_rating: parseInt(value?.toString()),
                    }
                  })
                }}
              />
              {errors.includes('teacher_rating') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Quelles ont été les difficultés rencontrées ? <span style={{ color: 'red' }}> *</span>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={state.difficulties.indexOf('Rareté des spécialistes') !== -1}
                      onClick={() => {
                        setState((prevState) => {
                          let difficulties = prevState.difficulties
                          if (difficulties.indexOf('Rareté des spécialistes') === -1) {
                            difficulties.push('Rareté des spécialistes')
                          } else {
                            difficulties = difficulties.filter((d) => d !== 'Rareté des spécialistes')
                          }
                          return {
                            ...prevState,
                            difficulties: difficulties,
                          }
                        })
                      }}
                    />
                  }
                  label="Rareté des spécialistes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={state.difficulties.indexOf('Problème de rémunération') !== -1}
                      onClick={() => {
                        setState((prevState) => {
                          let difficulties = prevState.difficulties
                          if (difficulties.indexOf('Problème de rémunération') === -1) {
                            difficulties.push('Problème de rémunération')
                          } else {
                            difficulties = difficulties.filter((d) => d !== 'Problème de rémunération')
                          }
                          return {
                            ...prevState,
                            difficulties: difficulties,
                          }
                        })
                      }}
                    />
                  }
                  label="Problème de rémunération"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={
                        state.difficulties.indexOf('Impossibilité de former une équipe pédagogique complète') !== -1
                      }
                      onClick={() => {
                        setState((prevState) => {
                          let difficulties = prevState.difficulties
                          if (difficulties.indexOf('Impossibilité de former une équipe pédagogique complète') === -1) {
                            difficulties.push('Impossibilité de former une équipe pédagogique complète')
                          } else {
                            difficulties = difficulties.filter(
                              (d) => d !== 'Impossibilité de former une équipe pédagogique complète',
                            )
                          }
                          return {
                            ...prevState,
                            difficulties: difficulties,
                          }
                        })
                      }}
                    />
                  }
                  label="Impossibilité de former une équipe pédagogique complète"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={state.difficulties.indexOf('Contraintes de calendrier') !== -1}
                      onClick={() => {
                        setState((prevState) => {
                          let difficulties = prevState.difficulties
                          if (difficulties.indexOf('Contraintes de calendrier') === -1) {
                            difficulties.push('Contraintes de calendrier')
                          } else {
                            difficulties = difficulties.filter((d) => d !== 'Contraintes de calendrier')
                          }
                          return {
                            ...prevState,
                            difficulties: difficulties,
                          }
                        })
                      }}
                    />
                  }
                  label="Contraintes de calendrier"
                />
                <Grid container direction="row">
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disabled}
                          color="primary"
                          checked={state.difficulties.indexOf('Autre:') !== -1}
                          onClick={() => {
                            setState((prevState) => {
                              let difficulties = prevState.difficulties
                              if (difficulties.indexOf('Autre:') === -1) {
                                difficulties.push('Autre:')
                              } else {
                                difficulties = difficulties.filter((d) => d !== 'Autre:')
                              }
                              return {
                                ...prevState,
                                difficulties: difficulties,
                              }
                            })
                          }}
                        />
                      }
                      label="Autre:"
                    />
                  </Grid>
                  <Grid item xs={8} md={6}>
                    <TextField
                      disabled={disabled}
                      size="small"
                      fullWidth
                      name="other_difficulty"
                      value={state.other_difficulty}
                      onChange={(e) => {
                        if (state.difficulties.indexOf('Autre:') === -1) {
                          state.difficulties.push('Autre:')
                        }
                        setState((prevState) => {
                          return {
                            ...prevState,
                            other_difficulty: e.target.value,
                          }
                        })
                      }}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
              {errors.includes('difficulties') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Sur une échelle de 1 à 5, comment s’est déroulée la collaboration entre l'Assistant CDP et le CDP ?{' '}
                <span style={{ color: 'red' }}> *</span>
              </Typography>
              <Rating
                disabled={disabled}
                name="cdp_acdp_rating"
                value={state?.cdp_acdp_rating}
                onChange={(_, value) => {
                  setState((prevState) => {
                    if (!value) return prevState
                    return {
                      ...prevState,
                      cdp_acdp_rating: parseInt(value?.toString()),
                    }
                  })
                }}
              />
              {errors.includes('cdp_acdp_rating') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Quelles seraient selon toi les pistes d’amélioration pour optimiser le recrutement de formateurs ?
              </Typography>
              <Grid item xs={10} md={8}>
                <TextField
                  disabled={disabled}
                  size="small"
                  fullWidth
                  label="Votre réponse"
                  name="teacher_recruiting_improvement"
                  value={state.teacher_recruiting_improvement}
                  onChange={(e) => {
                    setState((prevState) => {
                      return {
                        ...prevState,
                        teacher_recruiting_improvement: e.target.value,
                      }
                    })
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              style={{ fontWeight: 'bold', marginTop: theme.spacing(4), marginBottom: theme.spacing(2) }}
            >
              Pré-production
            </Typography>
          </Grid>
          <Grid item container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="body1">
                Sur une échelle de 1 à 5, comment se sont déroulées la mise en place du projet et la collaboration avec
                le(s) formateur(s) ? <span style={{ color: 'red' }}> *</span>
              </Typography>
              <Rating
                disabled={disabled}
                name="setup_teacher_rating"
                value={state?.setup_teacher_rating}
                onChange={(_, value) => {
                  setState((prevState) => {
                    if (!value) return prevState
                    return {
                      ...prevState,
                      setup_teacher_rating: parseInt(value?.toString()),
                    }
                  })
                }}
              />
              {errors.includes('setup_teacher_rating') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Sur une échelle de 1 à 5, comment le tournage s’est-il déroulé ?{' '}
                <span style={{ color: 'red' }}> *</span>
              </Typography>
              <Rating
                disabled={disabled}
                name="shooting_rating"
                value={state?.shooting_rating}
                onChange={(_, value) => {
                  setState((prevState) => {
                    if (!value) return prevState
                    return {
                      ...prevState,
                      shooting_rating: parseInt(value?.toString()),
                    }
                  })
                }}
              />
              {errors.includes('shooting_rating') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Les jours de tournages prévus ont-ils permis de capter l’ensemble de la formation ?{' '}
                <span style={{ color: 'red' }}> *</span>
              </Typography>
              <Rating
                disabled={disabled}
                name="shooting_duration_rating"
                value={state?.shooting_duration_rating}
                onChange={(_, value) => {
                  setState((prevState) => {
                    if (!value) return prevState
                    return {
                      ...prevState,
                      shooting_duration_rating: parseInt(value?.toString()),
                    }
                  })
                }}
              />
              {errors.includes('shooting_duration_rating') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Quelles seraient selon toi les pistes d’amélioration pour optimiser la conception pédagogique et/ou le
                tournage ?
              </Typography>
              <TextField
                disabled={disabled}
                size="small"
                fullWidth
                label="Votre réponse"
                name="pre_prod_improvement"
                value={state.pre_prod_improvement}
                onChange={(e) => {
                  setState((prevState) => {
                    return {
                      ...prevState,
                      pre_prod_improvement: e.target.value,
                    }
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              style={{ fontWeight: 'bold', marginTop: theme.spacing(4), marginBottom: theme.spacing(2) }}
            >
              Post-production
            </Typography>
          </Grid>
          <Grid item container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="body1">
                Le Design a-t-il reçu toutes les explications nécessaires pour la création des ressources pédagogiques ?
                <span style={{ color: 'red' }}> *</span>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={state.design === true}
                      onClick={() => {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            design: true,
                          }
                        })
                      }}
                    />
                  }
                  label="Oui"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={state.design === false}
                      onClick={() => {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            design: false,
                          }
                        })
                      }}
                    />
                  }
                  label="Non"
                />
              </FormGroup>
              {errors.includes('design') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Les monteurs ont-ils reçu toutes les explications nécessaires pour le montage des vidéos ?
                <span style={{ color: 'red' }}> *</span>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={state.editor === true}
                      onClick={() => {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            editor: true,
                          }
                        })
                      }}
                    />
                  }
                  label="Oui"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={state.editor === false}
                      onClick={() => {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            editor: false,
                          }
                        })
                      }}
                    />
                  }
                  label="Non"
                />
              </FormGroup>
              {errors.includes('editor') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Le reviewing et les demandes du/de la chef(fe) de projet pédagogique étaient-ils clairs et adaptés ?
                <span style={{ color: 'red' }}> *</span>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={state.project_manager === true}
                      onClick={() => {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            project_manager: true,
                          }
                        })
                      }}
                    />
                  }
                  label="Oui"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      checked={state.project_manager === false}
                      onClick={() => {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            project_manager: false,
                          }
                        })
                      }}
                    />
                  }
                  label="Non"
                />
              </FormGroup>
              {errors.includes('project_manager') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Sur une échelle de 1 à 5, comment s’est passée la collaboration entre le/la chef(fe) de projet, le/la
                réalisateur/réalisatrice et les monteurs ? <span style={{ color: 'red' }}> *</span>
              </Typography>
              <Rating
                disabled={disabled}
                name="project_manager_director_editor_rating"
                value={state?.project_manager_director_editor_rating}
                onChange={(_, value) => {
                  setState((prevState) => {
                    if (!value) return prevState
                    return {
                      ...prevState,
                      project_manager_director_editor_rating: parseInt(value?.toString()),
                    }
                  })
                }}
              />
              {errors.includes('project_manager_director_editor_rating') && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    Cette question est obligatoire
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Quelles seraient selon toi les pistes d’amélioration pour optimiser la post-production ?
              </Typography>
              <TextField
                disabled={disabled}
                size="small"
                fullWidth
                label="Votre réponse"
                name="post_production_improvement"
                value={state.post_production_improvement}
                onChange={(e) => {
                  setState((prevState) => {
                    return {
                      ...prevState,
                      post_production_improvement: e.target.value,
                    }
                  })
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!disabled && (
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(4) }}
          >
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Enregistrer
            </Button>
          </Grid>
        )}
      </Paper>
    </Grid>
  )
}

export default Feedback
