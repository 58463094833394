//MATERIAL UI
import { Avatar, Typography, Tooltip } from '@material-ui/core'
import { UserType } from '../../../lib/sharedTypes'
//UTILS
import { stringToColor, textColor } from '../utils/misc'

type UserAvatarPropsType = {
  user: UserType
}

export default function UserAvatar({ user }: UserAvatarPropsType) {
  const userColor = stringToColor(`${user?.first_name}${user?.last_name}`)
  return (
    <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
      <Avatar
        style={{
          backgroundColor: userColor,
          height: '32px',
          width: '32px',
          fontSize: '14px',
        }}
      >
        <Typography variant="body2" style={{ color: textColor(userColor) }}>
          {user?.first_name && user?.first_name.slice(0, 1).toUpperCase()}
          {user?.last_name && user?.last_name.slice(0, 1).toUpperCase()}
        </Typography>
      </Avatar>
    </Tooltip>
  )
}
