//COMPONENTS
import { ProjectCard } from './index'
import { Error, Loading } from '../index'
import { useAppSelector } from '../../hooks/reducerHooks'
// MATERIAL UI
import { Typography, Grid } from '@material-ui/core'
//GQL
import { DASHBOARD_PROJECTS_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
//TYPES
import { ProjectType, RoleCategoryEnum } from '../../../../lib/sharedTypes'

const Projects = () => {
  const user = useAppSelector((state) => state.user.user)
  const isAdmin = user?.roles.map((role) => role.category).includes(RoleCategoryEnum.ADMIN)

  const queryWhere: Record<string, any> = {
    course: {
      published_on: {
        eq: null,
      },
    },
  }

  if (!isAdmin) {
    queryWhere.members = {
      email: { in: [user?.email] },
    }
  }

  const {
    loading,
    error,
    data: { projects } = {},
  } = useQuery<{ projects: ProjectType[] }>(DASHBOARD_PROJECTS_QUERY, {
    variables: {
      where: queryWhere,
      directorsWhere: {
        job: {
          category: {
            eq: 'DIRECTOR',
          },
        },
      },
      order: {
        sort: 'DESC',
        field: 'created_at',
      },
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid item container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="h5">
          Mes {projects?.length} projet
          {projects?.length !== undefined && projects?.length > 1 && 's'} vidéo en cours
        </Typography>
      </Grid>
      <Grid container item spacing={2}>
        {projects?.map((project: ProjectType) => (
          <ProjectCard project={project} key={project.id} />
        ))}
      </Grid>
    </Grid>
  )
}

export default Projects
