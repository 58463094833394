import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { UserType } from '../../../../lib/sharedTypes'

// Define a type for the slice state
type userState = {
  user: UserType | null
  userTheme: 'dark' | 'light'
}

// Define the initial state using that type
const initialState: userState = {
  user: null,
  userTheme: 'light',
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.userTheme === 'light' ? (state.userTheme = 'dark') : (state.userTheme = 'light')
    },
    setUserAction: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload
    },
    resetUser: () => initialState,
  },
})

export const { setUserAction, resetUser, toggleTheme } = userSlice.actions

export default userSlice.reducer
