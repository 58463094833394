import { useState } from 'react'
//GQL
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { COURSE_DACTYLO_QUERY, COURSE_LESSONS_DACTYLO_SEARCH } from '../../../gql/queries'
import { DELETE_LESSON_MUTATION } from '../../../gql/mutations'
//MATERIAL-UI
import { Grid, IconButton, Menu, MenuItem, useTheme } from '@material-ui/core'
import { useNavigate, useParams, useOutletContext } from 'react-router'
//ICON
import { MdMoreVert, MdOutlineCheckCircleOutline, MdHighlightOff } from 'react-icons/md'
import { CgDetailsMore } from 'react-icons/cg'
//TYPES
import { CourseType, LessonType, SortEnum } from '../../../../../lib/sharedTypes'
import { ColumnType } from '../../../utils/types'
//COMPONENTS
import { Header } from '../../../components/Lesson'
import { CustomTable, Loading, Error, SearchBar, CustomModal } from '../../../components'
import { DeleteForm } from '../../../components/Forms'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../../utils/misc'

const LessonsDactylo = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { code_name } = useParams()
  const [field, setField] = useState<keyof LessonType>('id')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [take, setTake] = useState<number>(10)
  const [skip, setSkip] = useState<number>(0)
  const { refetchCourse } = useOutletContext<{ refetchCourse: () => Promise<any> }>()
  const [searching, setSearching] = useState<boolean>(false)
  const [setAlert] = useAlert()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<LessonType | undefined>(undefined)

  const [deleteLessonMutation] = useMutation<{
    deleteLesson: boolean
  }>(DELETE_LESSON_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      await refetch()
      setAlert({ severity: 'success', content: `Leçon dactylo supprimée` })
    },
  })

  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{ course: CourseType }>(COURSE_DACTYLO_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      lessonWhere: {
        dactylo: {
          id: {
            ne: null,
          },
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
    async onCompleted() {
      await refetchCourse()
    },
  })

  const columns: ColumnType<LessonType>[] = [
    {
      title: 'Nom de la leçon',
      field: 'name',
      render: (row: LessonType) => row.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Vidéo',
      field: 'dactylo.lesson_video.lesson.name',
      render: (row: LessonType) => row.dactylo.lesson_video.lesson.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Statut leçon',
      field: 'validated',
      render: (row: LessonType) =>
        row.validated ? (
          <MdOutlineCheckCircleOutline style={{ color: theme.palette.success.main }} size="22" />
        ) : (
          <MdHighlightOff style={{ color: theme.palette.error.main }} size="22" />
        ),
      type: 'String',
      sortable: true,
    },
    {
      title: 'Creation',
      field: 'created_at',
      render: (row: LessonType) => row.dactylo.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Modification',
      field: 'updated_at',
      render: (row: LessonType) => row.dactylo.updated_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: LessonType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  const [searchLessonQuery, { data: { course: courseSearch = null } = {} }] = useLazyQuery<{ course: CourseType }>(
    COURSE_LESSONS_DACTYLO_SEARCH,
  )

  const searchLessons = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchLessonQuery({
      variables: {
        where: {
          code_name: {
            eq: code_name,
          },
        },
        lessonWhere: {
          dactylo: {
            id: {
              ne: null,
            },
          },
        },
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
        skip: skip,
        take: take,
      },
    })
  }

  if (error) {
    return <Error error={error} />
  }

  if (!code_name) {
    return <Error error={'code_name inconnu'} />
  }

  if (loading || !course) {
    return <Loading />
  }

  return (
    <Grid item container direction="column" justifyContent="flex-start" spacing={1} style={{ width: '100%' }}>
      <Header refetch={refetch} />
      <Grid item container alignItems="center">
        <SearchBar
          placeholder="Rechercher une leçon dactylo"
          searchQuery={searchLessons}
          endAdornment={
            (isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course)) && (
              <IconButton
                onClick={() => {
                  navigate(`/formations/${code_name}/lessons/video/search`)
                }}
              >
                <CgDetailsMore />
              </IconButton>
            )
          }
        />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <CustomTable<LessonType>
          columns={columns}
          rows={searching && courseSearch ? courseSearch.lessons_search : course.lessons}
          field={field}
          sort={sort}
          take={take}
          skip={skip}
          setTake={setTake}
          setSkip={setSkip}
          setSort={setSort}
          setField={setField}
          rowsCount={searching ? courseSearch?.lessons_search.length : course.video_lessons_count}
          onRowClick={(row: LessonType, e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open(`/formations/${code_name}/lessons/dactylo/${row.id}`, '_blank')
            } else {
              navigate(row.id)
            }
          }}
        />
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la leçon dactylo
        </MenuItem>
      </Menu>
      <CustomModal
        size="xs"
        title="Supprimer la leçon dactylo"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteLessonMutation({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default LessonsDactylo
