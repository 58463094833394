//REACT
import { useState } from 'react'
//ICON
import { MdMoreVert } from 'react-icons/md'
// MATERIAL UI
import { Grid, Typography, Divider, Button, Paper, IconButton, Menu, MenuItem } from '@material-ui/core'
// COMPONENTS
import { ReviewForm } from '../../components/Forms/product'
import { DeleteForm } from '../../components/Forms'
import { Loading, Error, CustomModal, CustomTable } from '../../components'
// ICONS
import { makeStyles, useTheme } from '@material-ui/core/styles'
//GQL
import { PRODUCT_REVIEWS_QUERY } from '../../gql/queries'
import { DELETE_PRODUCT_REVIEW_MUTATION } from '../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
// TYPE
import { ColumnType } from '../../utils/types'
import { ProductType, ProductReviewType, SortEnum } from '../../../../lib/sharedTypes'
//REACT ROUTER
import { useParams, useOutletContext } from 'react-router'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
import { isAuthorized } from '../../utils/misc'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const GeneralInformations = () => {
  const { refetchProduct } = useOutletContext<{ refetchProduct: () => Promise<any> }>()
  const { code_name } = useParams()
  const [setAlert] = useAlert()
  const classes = useStyles()
  const theme = useTheme()
  const [modal, setModal] = useState('')
  const [field, setField] = useState<keyof ProductReviewType>('written_at')
  const [sort, setSort] = useState<SortEnum>(SortEnum.ASC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<ProductReviewType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const {
    loading,
    error,
    data: { product, productReviewsCount } = {},
    refetch,
  } = useQuery<{ product: ProductType; productReviewsCount: number }>(PRODUCT_REVIEWS_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      reviewCountWhere: {
        product: {
          code_name: {
            eq: code_name,
          },
        },
      },
    },
    async onCompleted() {
      await refetchProduct()
    },
  })
  const [deleteProductReview] = useMutation<{
    deleteProductReview: boolean
  }>(DELETE_PRODUCT_REVIEW_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Leçon textuelle supprimée` })
    },
  })

  const columns: ColumnType<ProductReviewType>[] = [
    {
      title: 'Prénom',
      field: 'first_name',
      render: (row: ProductReviewType) => row.first_name,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Nom',
      field: 'last_name',
      render: (row: ProductReviewType) => row.last_name,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Avis',
      field: 'feedback',
      render: (row: ProductReviewType) => row.feedback,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Note',
      field: 'rating',
      render: (row: ProductReviewType) => row.rating,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Ecriture',
      field: 'written_at',
      render: (row: ProductReviewType) => row.updated_at,
      type: 'Date',
      sortable: false,
    },
    {
      title: '',
      field: 'id',
      render: (row: ProductReviewType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  if (loading || !product) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column" style={{ width: '100%' }}>
      {/* DESCRIPTION */}
      <Paper className={classes.paper} style={{ width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Avis</Typography>
            <Button
              color="primary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'SEO'])}
              onClick={() => {
                setModal('review')
              }}
            >
              Ajouter
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item>
          <CustomTable<ProductReviewType>
            columns={columns}
            rows={product?.reviews}
            field={field}
            sort={sort}
            skip={skip}
            take={take}
            setSort={setSort}
            setField={setField}
            rowsCount={productReviewsCount}
            setSkip={setSkip}
            setTake={setTake}
          />
        </Grid>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          disabled={!isAuthorized(['ADMIN', 'SEO'])}
          onClick={() => {
            setModal('review')
            setAnchorEl(null)
          }}
        >
          Modifier l'avis
        </MenuItem>
        <MenuItem
          disabled={!isAuthorized(['ADMIN', 'SEO'])}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer l'avis
        </MenuItem>
      </Menu>
      <CustomModal
        size="xs"
        title="Supprimer l'avis"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteProductReview({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
      <CustomModal
        open={modal === 'review'}
        onClose={() => {
          setModal('')
          setSelectedRow(undefined)
        }}
        animation="slide-up"
      >
        <ReviewForm
          product={product}
          product_review={selectedRow}
          refetch={refetch}
          handleClose={() => {
            setModal('')
            setSelectedRow(undefined)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default GeneralInformations
