import { useState } from 'react'
//ASSETS
import NOIMG_banner from '../../../assets/NOIMG_banner.jpg'
// MATERIAL UI
import { Grid, TextField } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
// TYPE
import { CatalogType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_CATALOG_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type PdfForm = {
  catalog: CatalogType
  refetch: () => Promise<any>
  handleClose: () => void
}

const ScreeShotForm = ({ catalog, refetch, handleClose }: PdfForm) => {
  const [setAlert] = useAlert()
  const [stateCatalog, setStateCatalog] = useState<CatalogType>(catalog)

  const [updateCatalog] = useMutation<{ updateCatalog: CatalogType }>(UPDATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCatalog.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCatalog((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const catalogInput = {
      id: stateCatalog.id,
      screenshots_s3link: stateCatalog.screenshots_s3link,
    }
    await updateCatalog({ variables: { input: catalogInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour le screenshot du catalogue"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateCatalog) === JSON.stringify(catalog)}
    >
      <Grid item container justifyContent="center">
        <Grid item>
          <img
            style={{
              objectFit: 'cover',
              maxWidth: '100%',
              maxHeight: '350px',
            }}
            src={`${stateCatalog?.screenshots_s3link}?${new Date().getTime()}`} //force refresh
            onError={({ currentTarget }) => {
              currentTarget.src = NOIMG_banner
            }}
            loading="lazy"
            alt="screenshots_s3link"
          />
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="screenshots_s3link"
          label="Lien S3 du screenshot"
          variant="outlined"
          value={stateCatalog?.screenshots_s3link || ''}
          onChange={handleChange}
          required
        />
      </Grid>
    </FormLayout>
  )
}

export default ScreeShotForm
