//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { Grid, TextField } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
//TYPES
import { DeepPartial } from '../../../../utils/types'
import { LessonType, LessonEppType } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_LESSON_EPP_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//UTILS
import { handleError } from '../../../../utils/handleError'

type EppFormPropsType = {
  type: 'epp_video_iframe' | 'grid_video_iframe'
  lesson: LessonType
  refetch: () => void
  handleClose: () => void
}

const EppForm = ({ lesson, type, refetch, handleClose }: EppFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)

  const [updateLessonEpp] = useMutation<{
    updateLessonEpp: LessonEppType
  }>(UPDATE_LESSON_EPP_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: `Leçon EPP mise-à-jour` })
      refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonEppInput: Record<string, any> = {
      id: stateLesson?.epp?.id,
      epp_video_iframe: stateLesson?.epp?.epp_video_iframe,
      grid_video_iframe: stateLesson?.epp?.grid_video_iframe,
    }

    await updateLessonEpp({
      variables: {
        input: lessonEppInput,
      },
    })
  }

  return (
    <FormLayout
      type="update"
      title={`Mettre à jour la vidéo ${
        type === 'epp_video_iframe' ? 'EPP explicative' : 'explicative de la grille d’audit clinique'
      }`}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id ? JSON.stringify(stateLesson) === JSON.stringify(lesson) : stateLesson === undefined
      }
    >
      {type === 'epp_video_iframe' && (
        <Grid item xs={12}>
          <TextField
            label="EPP video explicative"
            name="epp_video_iframe"
            defaultValue={stateLesson?.epp?.epp_video_iframe}
            onChange={(event) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  epp: {
                    ...prevState?.epp,
                    [event.target.name]: event.target.value,
                  },
                }
              })
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
      )}
      {type === 'grid_video_iframe' && (
        <Grid item xs={12}>
          <TextField
            label="Vidéo explicative de la grille d’audit clinique"
            name="grid_video_iframe"
            defaultValue={stateLesson?.epp?.grid_video_iframe}
            onChange={(event) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  epp: {
                    ...prevState?.epp,
                    [event.target.name]: event.target.value,
                  },
                }
              })
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
      )}
    </FormLayout>
  )
}

export default EppForm
