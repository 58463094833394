import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'

//CUSTOM DARK THEME
export const dark_theme = createMuiTheme({
  typography: {
    fontFamily: ['Work Sans', 'Playfair Display', 'sans-serif'].join(','),
    h6: {
      fontSize: '18px',
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#3E83F5',
      dark: '#083c92',
      light: '#9ec1fa',
    },
    secondary: {
      main: '#F5B03E',
      dark: '#925E08',
      light: '#FAD79E',
    },
  },
  overrides: {
    MuiTableCell: {
      sizeSmall: {
        padding: '4px',
      },
    },
    MuiPopover: {
      root: {
        zIndex: 1300,
      },
    },
  },
})

//CUSTOM LIGHT THEME
export const light_theme = createMuiTheme({
  typography: {
    fontFamily: ['Work Sans', 'Playfair Display', 'sans-serif'].join(','),
    h6: {
      fontSize: '18px',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#3E83F5',
      dark: '#083c92',
      light: '#9ec1fa',
    },
    secondary: {
      main: '#F5B03E',
      dark: '#925E08',
      light: '#FAD79E',
    },
  },
  overrides: {
    MuiTableCell: {
      sizeSmall: {
        padding: '4px',
      },
    },
    MuiPopover: {
      root: {
        zIndex: 1300,
      },
    },
  },
})
