//REACT
import { useState } from 'react'
//MATERIAL UI
import { TableCell, TableRow, makeStyles, useTheme, Grid, Typography } from '@material-ui/core'
//TYPES
import {
  UserType,
  LeaveType,
  ShootingType,
  ProjectType,
  AssignmentType,
  RoleCategoryEnum,
} from '../../../../lib/sharedTypes'
//ICONS
import { GiDirectorChair } from 'react-icons/gi'
import { MdOutlineChecklist, MdOutlineLocalMovies, MdOutlineDesignServices } from 'react-icons/md'
//COMPONENNTS
import { CalendarForm } from '../Forms/calendar'
import { Cell } from './index'
import { CustomModal } from '../index'
import {
  CALENDAR_USER_ASSIGNMENT_QUERY,
  CALENDAR_USER_LEAVES_QUERY,
  CALENDAR_USER_SHOOTINGS_QUERY,
} from '../../gql/queries'
import { useQuery } from '@apollo/client'
//UTILS
import { getMondays, getWeekNumber } from '../../utils/calendar'
import moment from 'moment'
import { isAuthorized } from '../../utils/misc'

const useStyles = makeStyles((theme) => ({
  firstCol: {
    left: 0,
    background: theme.palette.background.default,
    zIndex: 1000,
    position: 'sticky',
    padding: theme.spacing(1),
    minWidth: 250,
    maxWidth: 250,
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  column: {
    minWidth: 150,
    maxWidth: 150,
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRight: `2px solid ${theme.palette.action.disabled}`,
  },
  currentWeek: {
    minWidth: 150,
    maxWidth: 150,
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  dayBox: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 42,
    width: 28,
  },
  option: {
    fontSize: '0.75rem',
    zIndex: 20,
  },
}))

type RowPropsType = {
  user: UserType
  startDate: Date
  endDate: Date
  last: boolean
  projects: ProjectType[]
}

const Row = ({ user, projects, last, startDate, endDate }: RowPropsType) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [date, setDate] = useState<Date>()
  const [project, setProject] = useState<ProjectType>()
  const classes = useStyles()
  const theme = useTheme()

  const startDatePrior = new Date(startDate.toISOString())
  startDatePrior.setDate(startDatePrior.getDate() - 1)

  const { data: { assignments = [] } = {}, refetch: refetchAssignments } = useQuery<{ assignments: AssignmentType[] }>(
    CALENDAR_USER_ASSIGNMENT_QUERY,
    {
      variables: {
        where: {
          user: {
            id: {
              eq: parseInt(user.id.toString()),
            },
          },
          date: {
            between: {
              from: startDatePrior.toISOString(),
              to: endDate.toISOString(),
            },
          },
        },
      },
    },
  )

  const { data: { leaves = [] } = {}, refetch: refetchLeaves } = useQuery<{ leaves: LeaveType[] }>(
    CALENDAR_USER_LEAVES_QUERY,
    {
      variables: {
        where: {
          user: {
            id: {
              eq: parseInt(user.id.toString()),
            },
          },
          date: {
            between: {
              from: startDatePrior.toISOString(),
              to: endDate.toISOString(),
            },
          },
        },
      },
    },
  )

  const { data: { shootings = [] } = {} } = useQuery<{ shootings: ShootingType[] }>(CALENDAR_USER_SHOOTINGS_QUERY, {
    variables: {
      where: {
        crew: {
          id: {
            in: [parseInt(user.id.toString())],
          },
        },
        starts_at: {
          between: {
            from: startDatePrior.toISOString(),
            to: endDate.toISOString(),
          },
        },
        ends_at: {
          between: {
            from: startDatePrior.toISOString(),
            to: endDate.toISOString(),
          },
        },
      },
    },
  })

  const onCellClick = (day: Date, project?: ProjectType) => {
    if (!isAuthorized(['ADMIN', 'PRODUCTION'])) return
    setDate(day)
    setProject(project)
    setModalOpen(true)
  }

  const bgMapping: Record<string, { light: string; dark: string }> = {
    PROJECT: {
      light: theme.palette.grey[600],
      dark: theme.palette.grey[900],
    },
    DIRECTOR: {
      light: theme.palette.grey[400],
      dark: theme.palette.grey[700],
    },
    EDITOR: {
      light: theme.palette.grey[200],
      dark: theme.palette.grey[600],
    },
    DESIGN: {
      light: theme.palette.grey[100],
      dark: theme.palette.grey[500],
    },
  }

  const userRoles = user.roles.map((role) => role.category)
  const userRole = userRoles.includes(RoleCategoryEnum.DIRECTOR)
    ? 'DIRECTOR'
    : userRoles.includes(RoleCategoryEnum.PROJECT)
    ? 'PROJECT'
    : userRoles.includes(RoleCategoryEnum.EDITOR)
    ? 'EDITOR'
    : userRoles.includes(RoleCategoryEnum.DESIGN)
    ? 'DESIGN'
    : 'USER'

  const getStatus = (day: Date) => {
    const dayDate = moment(day).format('YYYY-MM-DD')

    const leavesFiltered = leaves.filter((leave) => {
      return moment(leave.date).format('YYYY-MM-DD') === dayDate
    })
    if (leavesFiltered.length !== 0) {
      return { status: 'off', project: undefined }
    }

    const shootingsFiltered = shootings.filter((shooting) => {
      return (
        dayDate >= moment(shooting.starts_at).format('YYYY-MM-DD') &&
        dayDate <= moment(shooting.ends_at).format('YYYY-MM-DD')
      )
    })

    if (shootingsFiltered.length !== 0) {
      return { status: 'shooting', project: shootingsFiltered[0].project }
    }

    const filteredAssignments = assignments.filter((assignment) => {
      return dayDate === moment(assignment.date).format('YYYY-MM-DD')
    })

    if (filteredAssignments.length !== 0) {
      return { status: filteredAssignments[0].type.toLowerCase(), project: filteredAssignments[0].project }
    }

    return { status: null, project: undefined }
  }

  const currentWeekNumber = getWeekNumber(new Date())

  const getTab = (day: Date) => {
    const dayDate = moment(day).format('YYYY-MM-DD')
    const filteredAssignments = assignments.filter((assignment) => {
      return dayDate === moment(assignment.date).format('YYYY-MM-DD')
    })
    if (filteredAssignments.length !== 0) {
      return filteredAssignments[0].type.toLowerCase()
    }
    return 'leave'
  }

  return (
    <TableRow style={{ borderBottom: last ? `2px solid ${theme.palette.action.disabled}` : 'unset' }}>
      <TableCell
        className={classes.firstCol}
        style={{
          background: bgMapping[userRole][theme.palette.type],
        }}
      >
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            {userRole === 'PROJECT' && <MdOutlineChecklist size={20} />}
            {userRole === 'DIRECTOR' && <GiDirectorChair size={20} />}
            {userRole === 'EDITOR' && <MdOutlineLocalMovies size={20} />}
            {userRole === 'DESIGN' && <MdOutlineDesignServices size={20} />}
          </Grid>
          <Grid item>
            <Typography style={{ color: theme.palette.text.primary }}>
              {user?.first_name} {user?.last_name}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      {getMondays(startDate, endDate).map((monday) => {
        const weekNumber = getWeekNumber(monday)
        const days: Date[] = Array.from({ length: 5 }, (_, index) => {
          return new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + index)
        })
        return (
          <TableCell
            className={currentWeekNumber === weekNumber ? classes.currentWeek : classes.column}
            key={`${monday.getTime()}-${user.first_name}`}
            style={{ padding: 0 }}
          >
            <Grid container alignItems="center">
              {days.map((day, index) => {
                const { status, project } = getStatus(day)
                return (
                  <Cell
                    key={`${day.getTime()}-${user.first_name}`}
                    index={index}
                    status={status}
                    project={project}
                    onCellClick={() => onCellClick(day, project)}
                  />
                )
              })}
            </Grid>
          </TableCell>
        )
      })}
      {date && (
        <CustomModal
          size="xs"
          open={modalOpen}
          onClose={() => {
            setDate(undefined)
            setProject(undefined)
            setModalOpen(false)
          }}
        >
          <CalendarForm
            date={date}
            user={user}
            defaultTab={getTab(date)}
            project={project}
            projects={projects}
            onClose={() => {
              setDate(undefined)
              setProject(undefined)
              setModalOpen(false)
            }}
            refetchAssignments={refetchAssignments}
            refetchLeaves={refetchLeaves}
          />
        </CustomModal>
      )}
    </TableRow>
  )
}

export default Row
