//REACT
import React, { useState } from 'react'
// MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Grid } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from './index'
import { Error } from '../index'
//TYPES
import { CourseType, ProductType } from '../../../../lib/sharedTypes'
import { DeepPartial } from '../../utils/types'
//GQL
import { useQuery, useMutation } from '@apollo/client'
import { PRODUCTS_QUERY } from '../../gql/queries'
import { CREATE_COURSE_MUTATION } from '../../gql/mutations'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
//ROUTER
import { useNavigate } from 'react-router'

type CourseFormPropsType = {
  course?: CourseType
  refetch: () => Promise<any>
  handleClose: () => void
}

const CourseForm = ({ course, refetch, handleClose }: CourseFormPropsType) => {
  const [newCourse, setNewCourse] = useState<DeepPartial<CourseType> | undefined>(course)
  const { error: errorProducts, data: { products } = {} } = useQuery<{ products: ProductType[] }>(PRODUCTS_QUERY, {
    variables: { order: { field: 'code_name', sort: 'ASC' } },
  })
  const navigate = useNavigate()

  const [setAlert] = useAlert()

  const [createCourse] = useMutation<{
    createCourse: CourseType
  }>(CREATE_COURSE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createCourse.code_name} créé` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const newCourseInput = {
      ...newCourse,
      product: { id: newCourse?.product?.id },
    }
    if (newCourse?.id) {
      // await updateCourse({ variables: { input: videoInput } })
    } else {
      const { data } = await createCourse({ variables: { input: newCourseInput } })
      if (data) {
        navigate(data.createCourse.code_name)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCourse((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  if (errorProducts) {
    return <Error error={errorProducts} />
  }

  return (
    <FormLayout
      type="create"
      title="Créer une formation"
      onSubmit={handleSubmit}
      submitDisabled={newCourse === undefined}
    >
      <Grid item>
        <TextField
          fullWidth
          name="name"
          label="Nom"
          variant="outlined"
          value={newCourse?.name || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="code_name"
          label="Nom de code"
          variant="outlined"
          value={newCourse?.code_name || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        {products && (
          <Autocomplete
            fullWidth
            defaultValue={newCourse?.product}
            options={products}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option?.code_name || ''}
            onChange={(_, value) => {
              if (value) {
                setNewCourse({ ...newCourse, product: value })
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" label="Produit" />
            }}
          />
        )}
      </Grid>
    </FormLayout>
  )
}

export default CourseForm
