//REACT
import { useRef, useState } from 'react'
import { Layout, Title, Error, Loading, CustomModal } from '../../components'
//COMPONENTS
import { VideoReviewModal } from '../../components/Project'
// MATERIAL UI
import { Button, Grid, Chip, Typography, alpha, Box, CircularProgress, IconButton, useTheme } from '@material-ui/core'
//ICONS
import { ImVimeo2 } from 'react-icons/im'
//TYPE
import { VideosCaptionsType } from '../../utils/types'
import { ProductType, VideoStateEnum } from '../../../../lib/sharedTypes'

//GQL
import {
  UPLOAD_PRODUCT_TEASER_TO_VIMEO,
  UPDATE_PRODUCT_MUTATION,
  PRODUCT_TEASER_UPLOADED_MUTATION,
} from '../../gql/mutations'
import { PRODUCT_QUERY, PRODUCT_TEASER_UPLOAD_TOKEN } from '../../gql/queries'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
//ROUTER
import { useParams } from 'react-router'
//UTILS
import { WalterMediaUpload } from '../../utils/walterMedia'
import { useAlert } from '../../hooks'
import { handleError } from '../../utils/handleError'
import { videosCaptions } from '../../utils/constants'
import { isAuthorized, isEditorAndToCheck } from '../../utils/misc'

const Teaser = () => {
  const theme = useTheme()
  const { code_name } = useParams()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [setAlert] = useAlert()
  const [statusModal, setStatusModal] = useState(false)
  const [isVideoReviewModalOpen, setVideoReviewModalOpen] = useState(false)
  const [progress, setProgress] = useState(0)

  if (!code_name) {
    return <Error error={'code_name inconnu '} />
  }

  const {
    loading,
    error,
    data: { product } = {},
    refetch,
  } = useQuery<{ product: ProductType }>(PRODUCT_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  const [getToken] = useLazyQuery<{ productTeaserUploadToken: string }>(PRODUCT_TEASER_UPLOAD_TOKEN, {
    variables: { code_name: code_name },
  })

  const [uploadProductTeaserToVimeo, { loading: vimeoUploadLoading }] = useMutation<{
    uploadProductTeaserToVimeo: ProductType
  }>(UPLOAD_PRODUCT_TEASER_TO_VIMEO, {
    onError(e) {
      const msg = handleError(e)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      product?.teaser_vimeo_lastsync
        ? setAlert({ severity: 'success', content: 'Bande-annonce remplacée sur Viméo' })
        : setAlert({ severity: 'success', content: 'Bande-annonce mise-en-ligne sur Viméo' })
      await refetch()
    },
  })

  const [updateProduct] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
    },
  })

  const [productTeaserVideo] = useMutation<{
    productTeaserVideo: boolean
  }>(PRODUCT_TEASER_UPLOADED_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Bande-annonce mise-en-ligne` })
      await refetch()
    },
  })

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { data } = await getToken()

    if (e.target.files && data && data.productTeaserUploadToken) {
      const uploader = new WalterMediaUpload(data.productTeaserUploadToken)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const interval = setInterval(() => {
        setProgress(uploader.progress * 100)
      }, 500)
      try {
        await uploader.uploadFile(e.target.files[0])
        await productTeaserVideo({
          variables: { input: { id: product?.id } },
        })
        setAlert({ severity: 'success', content: 'Bande-annonce mise-en-ligne' })
        await refetch()
        clearInterval(interval)
        setProgress(0)
      } catch (error) {
        setAlert({ severity: 'error', content: 'Erreur lors de la mise-en-ligne' })
      }
    }
  }

  if (error) {
    return (
      <Layout title="Video">
        <Error error={error} />
      </Layout>
    )
  }

  if (loading || !product) {
    return (
      <Layout title="Video">
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout title={`${code_name} - Bande-annonce`}>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Title title={`${code_name} - Bande Annonce`} />
        </Grid>
        <Grid item>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              backgroundColor: alpha(videosCaptions[product.teaser_review_state].color, 0.2),
              borderRadius: '5px',
              padding: theme.spacing(1),
            }}
          >
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item style={{ paddingRight: theme.spacing(1) }}>
                  <Chip
                    label={videosCaptions[product.teaser_review_state].label}
                    style={{ backgroundColor: videosCaptions[product.teaser_review_state].color }}
                    color="primary"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="caption" style={{ color: videosCaptions[product.teaser_review_state].color }}>
                    {videosCaptions[product.teaser_review_state].description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {['WAITING', 'TO_DISCOVER', 'TO_CORRECT', 'TO_CHECK'].includes(product?.teaser_review_state) && (
                <Button
                  size="small"
                  variant="outlined"
                  style={{ borderColor: videosCaptions[product?.teaser_review_state].color, width: 250 }}
                  component="label"
                  disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR', 'DESIGN', 'EDITOR'])}
                >
                  {progress !== undefined && progress !== 0 ? (
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        variant="determinate"
                        size="1.75rem"
                        id="upload_progress"
                        value={progress}
                        style={{ color: 'white' }}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography style={{ fontSize: 8 }} color="textSecondary">{`${Math.round(
                          progress || 0,
                        )}%`}</Typography>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      {product.teaser_review_state === 'WAITING'
                        ? 'Ajouter la bande-annonce'
                        : 'Uploader une bande-annonce'}
                      <input hidden accept=".avi,.mp4,.mov,.flv,.wmv" type="file" onChange={onSelectFile} />
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <video
            src={`${product.teaser_walter_media_url}?${new Date().getTime()}`}
            width="100%"
            height="640px"
            id="video"
            controls
            ref={videoRef}
          />
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item container direction="row" alignItems="center" spacing={1}>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            window.open(product.teaser_vimeo_url, '_blank')
                          }}
                        >
                          {product.teaser_vimeo_url ? (
                            <ImVimeo2 size="22" color="#3E83F5" />
                          ) : (
                            <ImVimeo2 size="22" color="#e0e0e0" />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                          {product.teaser_vimeo_lastsync
                            ? `Dernière publication: ${new Date(product.teaser_vimeo_lastsync).toLocaleString()}`
                            : 'Non publié'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    style={{ width: 140 }}
                    variant="outlined"
                    color="primary"
                    startIcon={<ImVimeo2 />}
                    disabled={
                      product.teaser_review_state !== 'VALIDATED' || !isAuthorized(['ADMIN', 'PRODUCTION', 'DESIGN'])
                    }
                    onClick={async () => {
                      await uploadProductTeaserToVimeo({ variables: { input: { id: product.id } } })
                    }}
                  >
                    {vimeoUploadLoading ? (
                      <CircularProgress variant="indeterminate" size="1.5rem" color="primary" id="upload_progress" />
                    ) : product.teaser_vimeo_url ? (
                      'Republier'
                    ) : (
                      'Publier'
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'PROJECT', 'DESIGN', 'EDITOR'])}
                    onClick={() => setStatusModal(true)}
                  >
                    Changer le statut
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setVideoReviewModalOpen(true)
                    }}
                  >
                    Accès revue
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomModal
        title="Changer le statut de la vidéo"
        size="xs"
        open={statusModal}
        onClose={() => setStatusModal(false)}
      >
        <Grid container spacing={1} justifyContent="space-around">
          {Object.keys(VideoStateEnum)
            .filter((key) => key !== product.teaser_review_state)
            .filter((key) => !isEditorAndToCheck(key))
            .map((key, index) => {
              return (
                <Grid item key={index}>
                  <Button
                    disabled={product.teaser_review_state === key}
                    variant="outlined"
                    style={{
                      borderColor: videosCaptions[key as keyof VideosCaptionsType].color,
                      color: videosCaptions[key as keyof VideosCaptionsType].color,
                    }}
                    onClick={async () => {
                      await updateProduct({ variables: { input: { id: product.id, teaser_review_state: key } } })
                      setStatusModal(false)
                    }}
                  >
                    {videosCaptions[key as keyof VideosCaptionsType].label}
                  </Button>
                </Grid>
              )
            })}
        </Grid>
      </CustomModal>
      <CustomModal animation="slide-up" open={isVideoReviewModalOpen} onClose={() => setVideoReviewModalOpen(false)}>
        <VideoReviewModal
          type="teaser"
          video={product}
          reviews={product.video_reviews}
          diagrams={[]}
          refetch={async () => {
            await refetch()
          }}
          timeCode={0}
        />
      </CustomModal>
    </Layout>
  )
}

export default Teaser
