//COMPONENTS
import { Layout } from '../components'
// MATERIAL UI
import { useTheme } from '@material-ui/core/styles'
import { Grid, Typography, Paper, Chip } from '@material-ui/core'
//UTILS
import { categoryMapping, jobMapping } from '../utils/constants'
import { stringToColor } from '../utils/misc'
// REDUX
import { useAppSelector } from '../hooks/reducerHooks'

const Dashboard = () => {
  const theme = useTheme()
  const user = useAppSelector((state) => state.user.user)

  return (
    <Layout title="Profile">
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Paper style={{ padding: theme.spacing(2) }}>
            <Grid container direction="column" spacing={1} justifyContent="center" alignItems="center">
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1"> Nom d'utilisateur: </Typography>
                <Typography variant="body1"> {user?.username} </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1"> Prénom: </Typography>
                <Typography variant="body1"> {user?.first_name} </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1"> Nom de famille: </Typography>
                <Typography variant="body1"> {user?.last_name} </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1"> Email: </Typography>
                <Typography variant="body1"> {user?.email} </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1"> Travail: </Typography>
                <Typography variant="body1"> {jobMapping[user?.job.category as keyof typeof jobMapping]} </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1"> Roles: </Typography>
                <Grid container spacing={1} justifyContent="space-around">
                  {user?.roles.map((role) => {
                    return (
                      <Grid item key={role.id}>
                        <Chip
                          label={categoryMapping[role.category as keyof typeof categoryMapping]}
                          style={{ color: 'white', backgroundColor: stringToColor(role.category) }}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Dashboard
