//REACT
import { useState, useRef, useEffect } from 'react'
//COMPONENTS
import { FormLayout } from '../../index'
// MATERIAL UI
import { Grid, Button } from '@material-ui/core'
//TYPES
import { LessonType, LessonVideoType } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_LESSON_VIDEO_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//UTILS
import { handleError } from '../../../../utils/handleError'

type ThumbnailFormPropsType = {
  lesson: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

export const ThumbnailForm = ({ lesson, refetch, handleClose }: ThumbnailFormPropsType) => {
  const [setAlert] = useAlert()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [stateLesson, setStateLesson] = useState<LessonType>(lesson)
  const [updateLessonVideoMutation] = useMutation<{
    updateLessonVideo: LessonVideoType
  }>(UPDATE_LESSON_VIDEO_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `Leçon vidéo ${data.updateLessonVideo.number} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonVideoInput = {
      id: stateLesson?.video.id,
      thumbnail_timecode: stateLesson?.video.thumbnail_timecode,
    }
    await updateLessonVideoMutation({ variables: { input: lessonVideoInput } })
  }

  useEffect(() => {
    if (videoRef.current && lesson) {
      videoRef.current.currentTime = lesson.video.thumbnail_timecode
    }
  }, [videoRef, lesson])

  return (
    <FormLayout
      type={'update'}
      title={'Mettre à jour la miniature'}
      onSubmit={handleSubmit}
      submitDisabled={stateLesson?.video.thumbnail_timecode === lesson?.video.thumbnail_timecode}
    >
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.preventDefault()
              videoRef.current?.pause()
              const timecode = videoRef?.current?.currentTime
              if (timecode) {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    video: {
                      ...prevState.video,
                      thumbnail_timecode: timecode,
                    },
                  }
                })
              }
            }}
          >
            Sélectionner miniature
          </Button>
        </Grid>
        <Grid item>
          <video
            src={stateLesson?.video.walter_media_url}
            height="480"
            width="100%"
            id="video"
            controls
            ref={videoRef}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default ThumbnailForm
