//REACT
import { useEffect, useState } from 'react'
//COMPONENTS
import { Error, Loading } from '../../components'
// MATERIAL UI
import {
  Grid,
  Card,
  CardContent,
  Button,
  makeStyles,
  Typography,
  Breadcrumbs,
  Link,
  CircularProgress,
} from '@material-ui/core'
//TYPES
import { ProjectType } from '../../../../lib/sharedTypes'
// ICONS
import { GoFileDirectory } from 'react-icons/go'
import { MdReplayCircleFilled } from 'react-icons/md'
import { ImFileVideo } from 'react-icons/im'
import { AiFillCheckCircle } from 'react-icons/ai'
//GQL
import { CREATE_MEDIA_PROXIES_MUTATION } from '../../gql/mutations'
import { PROJECT_RUSHES_QUERY } from '../../gql/queries'
import { useQuery, useMutation } from '@apollo/client'
//ROUTER
import { useParams } from 'react-router'
//HOOKS
import { useAlert } from '../../hooks'
//utils
import { handleError } from '../../utils/handleError'
import { isAuthorized, isCourseManager } from '../../utils/misc'

const useStyles = makeStyles((theme) => ({
  mediaCard: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
    height: '175px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    justifyContent: 'center',
    alignContent: 'center',
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
}))

type ObjType = {
  name: string
  children: ObjType[]
}

const getTree = (data: string[]) => {
  return data.reduce((tree: Array<ObjType>, p: string) => {
    p.split('/').reduce((t, name: string) => {
      let temp = t.find((q) => q.name === name)
      if (!temp) t.push((temp = { name, children: [] }))
      return temp.children
    }, tree)
    return tree
  }, [])
}

const Rushes = () => {
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [path, setPath] = useState<string | null>(null)
  const [tree, setTree] = useState<ObjType[]>()
  const classes = useStyles()
  const {
    loading,
    error,
    data: { project } = {},
    refetch,
  } = useQuery<{
    project: ProjectType
  }>(PROJECT_RUSHES_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  const [createMediaProxies] = useMutation<{
    createMediaProxies: ProjectType
  }>(CREATE_MEDIA_PROXIES_MUTATION, {
    variables: {
      input: {
        id: project?.id,
      },
    },
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({
        severity: 'success',
        content: `Génération des proxies pour ${data.createMediaProxies.code_name} 
      en cours`,
      })
      await refetch()
    },
  })

  useEffect(() => {
    if (project) {
      const fileTree = getTree(project?.medias.map((media) => media.path)).sort((a, b) => {
        return parseInt(a.name.replace(/\D+/g, '')) - parseInt(b.name.replace(/\D+/g, ''))
      })
      setTree(fileTree)
    }
  }, [project])

  if (error || !code_name) {
    return <Error error={error} />
  }

  if (loading || !project) {
    return <Loading />
  }

  const recLookup = (objArr: ObjType[], path: string): ObjType[] | undefined => {
    const parts = path.split('/')
    if (parts.length == 1) {
      return Object.values(objArr).find((f) => f.name === parts[0])?.children
    }
    const children = Object.values(objArr).find((f) => f.name === parts[0])?.children
    if (children) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return recLookup(children, parts.slice(1).join('/'))
    }
    return undefined
  }

  const getNode = (path: string | null) => {
    if (tree) {
      if (path) {
        const newTree = recLookup(tree, path)
        if (newTree) return Object.values(newTree)
      }
      return Object.values(tree)
    }
    return []
  }

  return (
    <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
      <Grid item container justifyContent="space-between" style={{ width: '100%' }}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPath(null)
              }}
            >
              Rushes
            </Link>
            {path?.split('/').map((bread, index) => (
              <Link
                style={{ cursor: 'pointer' }}
                key={index}
                color={index === path?.split('/').length - 1 ? 'primary' : 'inherit'}
                onClick={() => {
                  setPath(
                    path
                      ?.split('/')
                      .slice(0, index + 1)
                      .join('/'),
                  )
                }}
              >
                {bread}
              </Link>
            ))}
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'DIRECTOR', 'EDITOR']) || isCourseManager(project.course))}
            variant="outlined"
            color="primary"
            onClick={() => createMediaProxies()}
          >
            Générer les fichiers proxy
          </Button>
        </Grid>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Grid container spacing={1}>
          {tree &&
            getNode(path)?.map((f) => {
              const proxyMissing = project?.medias
                ?.filter((media) => media.path.startsWith(path ? path : f.name))
                .some((file) => file.proxy_file_status === 'MISSING')
              const proxyONGOING = project?.medias
                ?.filter((media) => media.path.startsWith(path ? path : f.name))
                .some((file) => file.proxy_file_status === 'ONGOING')
              return (
                <Grid item xs={12} md={3} key={f.name}>
                  <Card
                    className={classes.mediaCard}
                    onClick={() => {
                      if (f.children.length !== 0) {
                        if (path) {
                          setPath(`${path}/${f.name}`)
                        } else {
                          setPath(f.name)
                        }
                      }
                    }}
                  >
                    <CardContent style={{ padding: 0, width: '100%' }}>
                      <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: '100%' }}
                      >
                        <Grid item container justifyContent="flex-end" alignItems="center">
                          <Grid item>
                            {proxyONGOING ? (
                              <CircularProgress variant="indeterminate" size="1.25rem" color="primary" />
                            ) : proxyMissing ? (
                              <MdReplayCircleFilled size={'25'} color="orange" />
                            ) : (
                              <AiFillCheckCircle size={'25'} color="green" />
                            )}
                          </Grid>
                        </Grid>
                        <Grid item style={{ justifyContent: 'center' }}>
                          {f.children.length !== 0 ? (
                            <GoFileDirectory size={'100'} color="#3E83F5" />
                          ) : (
                            <ImFileVideo size={'100'} color="#3E83F5" />
                          )}
                        </Grid>
                        <Grid item style={{ width: '100%' }}>
                          <Typography
                            variant="body1"
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {f.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Rushes
