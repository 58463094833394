//REACT
import React, { useState } from 'react'
//ICONS
import { MdOutlineDelete, MdOutlineAddCircleOutline } from 'react-icons/md'
// MATERIAL UI
import { Grid, Typography, IconButton, Divider, Button, TextField, useTheme } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
import { Error, TextEditor } from '../../../index'
//TYPES
import { DeepPartial } from '../../../../utils/types'
import { LessonType, CourseType, LessonEppType, EpisodeType, CheckpointType } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { COURSE_ID_QUERY } from '../../../../gql/queries'
import { UPDATE_LESSON_EPP_MUTATION, CREATE_LESSON_EPP_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//ROUTER
import { useParams } from 'react-router'
//UTILS
import { handleError } from '../../../../utils/handleError'

type EppFormPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const EppForm = ({ lesson, refetch, handleClose }: EppFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [selectedFile, setSelectedFile] = useState<File>()
  const { code_name } = useParams()
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const { error: errorCourse, data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])

    const url = URL.createObjectURL(e.target.files[0])
    if (url) {
      setStateLesson((prevState) => {
        return {
          ...prevState,
          s3_grid_pdf: url,
        }
      })
    }
  }

  const [createLessonEpp] = useMutation<{
    createLessonEpp: LessonEppType
  }>(CREATE_LESSON_EPP_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createLessonEpp.lesson.name} créée` })
      await refetch()
      handleClose()
    },
  })
  const [updateLessonEpp] = useMutation<{
    updateLessonEpp: LessonEppType
  }>(UPDATE_LESSON_EPP_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon EPP mise-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (errorCourse) {
    return <Error error={errorCourse} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonEppInput: Record<string, any> = {
      id: stateLesson?.epp?.id,
      inclusion_criteria: stateLesson?.epp?.inclusion_criteria,
      exclusion_criteria: stateLesson?.epp?.exclusion_criteria,
      epp_video_iframe: stateLesson?.epp?.epp_video_iframe,
      grid_video_iframe: stateLesson?.epp?.grid_video_iframe,
      s3_grid_pdf: selectedFile ? selectedFile : undefined,
      episodes: stateLesson?.epp?.episodes?.map((episode) => {
        return {
          ...episode,
          id: typeof episode?.id === 'string' ? episode.id : undefined,
          checkpoints: episode?.checkpoints?.map((checkpoint) => {
            return {
              ...checkpoint,
              id: typeof checkpoint?.id === 'string' ? checkpoint.id : undefined,
            }
          }),
        }
      }),
    }

    if (stateLesson?.id) {
      await updateLessonEpp({
        variables: {
          input: lessonEppInput,
        },
      })
    } else {
      lessonEppInput.lesson = {
        course: { id: course?.id },
        validated: stateLesson?.validated || false,
        summary_required: stateLesson?.summary_required || false,
        name: stateLesson?.name,
        summary_state: stateLesson?.summary_state,
        summary: stateLesson?.summary,
      }
      await createLessonEpp({ variables: { input: lessonEppInput } })
    }
  }

  return (
    <FormLayout
      type={lesson ? 'update' : 'create'}
      title={lesson ? 'Mettre à jour la leçon EPP' : 'Ajouter une leçon EPP'}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id ? JSON.stringify(stateLesson) === JSON.stringify(lesson) : stateLesson === undefined
      }
    >
      {!stateLesson?.id && (
        <Grid item container spacing={2} alignItems="center">
          <Grid item container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                required
                label="Nom"
                name="name"
                defaultValue={stateLesson?.name}
                onChange={(event) => {
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      ...{ [event.target.name]: event.target.value },
                    }
                  })
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <TextEditor
              placeholder="Ecrire le résumé de la leçon"
              text={stateLesson?.summary}
              onChange={(json) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    ...{ summary: json },
                  }
                })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="EPP video explicative"
              name="epp_video_iframe"
              defaultValue={stateLesson?.epp?.epp_video_iframe}
              onChange={(event) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    epp: {
                      ...prevState?.epp,
                      [event.target.name]: event.target.value,
                    },
                  }
                })
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Vidéo explicative de la grille d’audit clinique"
              name="grid_video_iframe"
              defaultValue={stateLesson?.epp?.grid_video_iframe}
              onChange={(event) => {
                setStateLesson((prevState) => {
                  return {
                    ...prevState,
                    epp: {
                      ...prevState?.epp,
                      [event.target.name]: event.target.value,
                    },
                  }
                })
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      <Grid item container spacing={2} alignItems="flex-start">
        <Grid item>
          <Button variant="outlined" color="primary" component="label">
            {!stateLesson?.epp?.s3_grid_pdf ? 'Selectionner la grille au format PDF' : 'Selectionner un autre fichier'}
            <input hidden accept="*" type="file" onChange={onSelectFile} multiple={false} />
          </Button>
        </Grid>
        <Grid item>
          {selectedFile ? (
            <object type="application/pdf" data={URL.createObjectURL(selectedFile)} width="100%" height="250px" />
          ) : lesson?.epp.s3_grid_pdf ? (
            <object type="application/pdf" data={lesson.epp.s3_grid_pdf} width="100%" height="250px" />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <TextField
            label="Critères d'inclusion"
            name="inclusion_criteria"
            multiline
            minRows={3}
            maxRows={3}
            defaultValue={stateLesson?.epp?.inclusion_criteria}
            onChange={(event) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  epp: {
                    ...prevState?.epp,
                    inclusion_criteria: event.target.value,
                  },
                }
              })
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <TextField
            label="Critères d'exclusion"
            name="exclusion_criteria"
            multiline
            minRows={3}
            maxRows={3}
            defaultValue={stateLesson?.epp?.exclusion_criteria}
            onChange={(event) => {
              setStateLesson((prevState) => {
                return {
                  ...prevState,
                  epp: {
                    ...prevState?.epp,
                    exclusion_criteria: event.target.value,
                  },
                }
              })
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12} alignItems="center">
        <Grid item>
          <Typography variant="h6">Episodes</Typography>
        </Grid>
        <IconButton
          onClick={() => {
            const episodes = stateLesson?.epp?.episodes || []
            episodes.push({
              id: new Date().getTime(), //ensures unicity
              position: 0,
              title: '',
              checkpoints: [{ id: new Date().getTime(), position: 0, statement: '', advice: '' }],
            } as EpisodeType)

            setStateLesson((prevState) => {
              return {
                ...prevState,
                epp: {
                  ...prevState?.epp,
                  episodes: episodes,
                },
              }
            })
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Grid>
      <Grid item container direction="column" spacing={1} xs={12}>
        {stateLesson?.epp?.episodes?.map((episode, index) => {
          return (
            <Grid
              key={episode?.id}
              item
              container
              direction="column"
              style={{
                border: `1px solid ${theme.palette.action.focus}`,
                marginBottom: theme.spacing(1),
                marginRight: theme.spacing(1),
                marginTop: theme.spacing(1),
                padding: theme.spacing(1),
                borderRadius: 5,
              }}
            >
              <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>{`Episode ${index + 1}`}</Typography>
                </Grid>

                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setStateLesson((prevState) => {
                        return {
                          ...prevState,
                          epp: {
                            ...prevState?.epp,
                            episodes: prevState?.epp?.episodes?.filter((r) => {
                              return r?.id !== episode?.id
                            }),
                          },
                        }
                      })
                    }}
                  >
                    <MdOutlineDelete size={24} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid style={{ marginBottom: theme.spacing(2) }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label={`Titre`}
                  name="episode.title"
                  value={episode?.title}
                  onChange={(event) => {
                    const episodes = stateLesson.epp?.episodes?.map((episode, i) => {
                      if (i === index) {
                        return {
                          ...episode,
                          title: event.target.value,
                        }
                      } else {
                        return episode
                      }
                    })
                    setStateLesson((prevState) => {
                      return {
                        ...prevState,
                        epp: {
                          ...prevState?.epp,
                          episodes: episodes,
                        },
                      }
                    })
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item container alignItems="center" style={{ padding: 0, paddingLeft: theme.spacing(2) }}>
                <Grid item>
                  <Typography variant="body1">Etapes</Typography>
                </Grid>
                <IconButton
                  onClick={() => {
                    const checkpoints = episode?.checkpoints || []
                    checkpoints.push({
                      id: new Date().getTime(), //ensures unicity
                      position: 0,
                      statement: '',
                      advice: '',
                    } as CheckpointType)

                    const updatedEpisode = {
                      ...episode,
                      checkpoints: checkpoints,
                    }
                    const episodes = stateLesson?.epp?.episodes
                    episodes?.splice(index, 1, updatedEpisode)

                    setStateLesson((prevState) => {
                      return {
                        ...prevState,
                        epp: {
                          ...prevState?.epp,
                          episodes: episodes,
                        },
                      }
                    })
                  }}
                >
                  <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
                </IconButton>
              </Grid>
              <Grid
                style={{
                  marginBottom: theme.spacing(2),
                  padding: 0,
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                }}
              >
                <Divider />
              </Grid>
              <Grid item container spacing={2} style={{ padding: 0, paddingLeft: theme.spacing(2) }}>
                {episode?.checkpoints?.map((checkpoint) => {
                  return (
                    <Grid
                      key={checkpoint?.id}
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item style={{ flexGrow: 1 }}>
                        <TextField
                          required
                          multiline
                          minRows={3}
                          maxRows={5}
                          label={`Proposition`}
                          name="episode.checkpoint.statement"
                          value={checkpoint?.statement}
                          onChange={(event) => {
                            const episodes = stateLesson.epp?.episodes?.map((episode, i) => {
                              if (i === index) {
                                const checkpoints = episode?.checkpoints?.map((check) => {
                                  if (check?.id === checkpoint?.id) {
                                    return {
                                      ...checkpoint,
                                      statement: event.target.value,
                                    }
                                  } else {
                                    return check
                                  }
                                })
                                return {
                                  ...episode,
                                  checkpoints: checkpoints,
                                }
                              } else {
                                return episode
                              }
                            })
                            setStateLesson((prevState) => {
                              return {
                                ...prevState,
                                epp: {
                                  ...prevState?.epp,
                                  episodes: episodes,
                                },
                              }
                            })
                          }}
                          variant="outlined"
                          fullWidth
                        />
                        <TextField
                          multiline
                          minRows={3}
                          maxRows={5}
                          required
                          style={{ marginTop: theme.spacing(1) }}
                          label={`Conseil`}
                          name="episode.checkpoint.advice"
                          value={checkpoint?.advice}
                          onChange={(event) => {
                            const episodes = stateLesson.epp?.episodes?.map((episode, i) => {
                              if (i === index) {
                                const checkpoints = episode?.checkpoints?.map((check) => {
                                  if (check?.id === checkpoint?.id) {
                                    return {
                                      ...checkpoint,
                                      advice: event.target.value,
                                    }
                                  } else {
                                    return check
                                  }
                                })
                                return {
                                  ...episode,
                                  checkpoints: checkpoints,
                                }
                              } else {
                                return episode
                              }
                            })
                            setStateLesson((prevState) => {
                              return {
                                ...prevState,
                                epp: {
                                  ...prevState?.epp,
                                  episodes: episodes,
                                },
                              }
                            })
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            const checkpoints = episode?.checkpoints?.filter((c) => {
                              return c?.id !== checkpoint?.id
                            })
                            const updatedEpisode = {
                              ...episode,
                              checkpoints: checkpoints,
                            }
                            const episodes = stateLesson?.epp?.episodes
                            episodes?.splice(index, 1, updatedEpisode)
                            setStateLesson((prevState) => {
                              return {
                                ...prevState,
                                epp: {
                                  ...prevState?.epp,
                                  episodes: episodes,
                                },
                              }
                            })
                          }}
                        >
                          <MdOutlineDelete size={24} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </FormLayout>
  )
}

export default EppForm
