import React, { useState, useEffect } from 'react'
//ASSETS
// import loginBg from '../assets/loginBg.png'
import LightLogo from '../assets/light_logo_WP.svg'
//MATERIAL UI
import { useTheme } from '@material-ui/core/styles'
import { Grid, TextField, Button, Card, Typography, Divider } from '@material-ui/core'
//REDUX
import { useAppDispatch } from '../hooks/reducerHooks'
import { logInAction } from '../store/reducers/authReducer'
//GQL
import { LOGIN_QUERY } from '../gql/queries'
import { useLazyQuery } from '@apollo/client'
//TYPES
import type { TokensType } from '../../../lib/sharedTypes'
import { useLocation, useNavigate } from 'react-router'

const Login = () => {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useAppDispatch()
  const [loginUser, { error, data }] = useLazyQuery<{ login: TokensType }>(LOGIN_QUERY)
  const [ssoError, setSSOError] = useState('')
  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()
    await loginUser({ variables: { email, password } })
  }

  useEffect(() => {
    if (location.search) {
      const params = location.search.split('?')[1].split('&')
      const paramsObj = params.reduce((acc: Record<string, string>, el) => {
        acc[el.split('=')[0]] = el.split('=')[1]
        return acc
      }, {})
      if (Object.keys(paramsObj).includes('error')) {
        setSSOError(
          'Veuillez contacter un administrateur pour procéder à la création de votre compte sur la plateforme',
        )
      }
      if (Object.keys(paramsObj).includes('accessToken') && Object.keys(paramsObj).includes('refreshToken')) {
        const accessToken = params[0].replace('accessToken=', '').replace('Bearer%20', 'Bearer ')
        const refreshToken = params[1].replace('refreshToken=', '').replace('Bearer%20', 'Bearer ')
        dispatch(logInAction({ accessToken, refreshToken, isLoggedIn: true, connected: true }))
        navigate('/')
      }
    }
  }, [location])

  useEffect(() => {
    if (data?.login?.accessToken) {
      dispatch(logInAction({ ...data.login, isLoggedIn: true, connected: true }))
    }
  }, [data, error, dispatch])

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Grid item xs={12} sm={4}>
        <Card raised={true} style={{ borderRadius: 5 }}>
          <Grid component="form" container direction="column" alignItems="center" onSubmit={handleSubmit}>
            <Grid container item xs={12} justifyContent="center" spacing={2} style={{ padding: theme.spacing(2) }}>
              <Grid item>
                <img src={LightLogo} style={{ height: 50, width: '100%' }} />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(4) }}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    style={{ marginBottom: theme.spacing(2) }}
                    id="email"
                    label="Email"
                    required={true}
                    placeholder="Email"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    id="password"
                    label="Mot de passe"
                    required={true}
                    placeholder="Mot de passe"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                item
                xs={12}
                style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}
              >
                <Grid item>
                  <Button color="primary" variant="contained" type="submit" fullWidth>
                    Se connecter
                  </Button>
                </Grid>
                <Grid item>
                  <Grid container item xs={12} alignItems="center" justifyContent="center">
                    <Divider style={{ flexGrow: 1 }} />
                    <Grid style={{ padding: theme.spacing(1) }}>
                      <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                        ou
                      </Typography>
                    </Grid>
                    <Divider style={{ flexGrow: 1 }} />
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    style={{ color: 'white' }}
                    onClick={() => {
                      window.open(
                        'https://walter-learning-adtest.awsapps.com/start/#/saml/default/Walter%20Production/ins-eb7bcf0e654b5278',
                        '_self',
                      )
                    }}
                  >
                    SSO
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Grid item container justifyContent="center" style={{ height: 25, paddingTop: theme.spacing(2) }}>
          <Typography variant="body1" color="error">
            {error && error.message && error.message !== 'Failed to fetch' ? 'Mauvais email ou mot de passe' : ''}
          </Typography>
          <Typography variant="body1" color="error" style={{ textAlign: 'center' }}>
            {ssoError}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Login
