import { useState, Fragment, useEffect } from 'react'
//COMPONENTS
import { Layout, Title, Error, Loading } from '../components'
//MATERIAL UI
import {
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  IconButton,
  Collapse,
} from '@material-ui/core'
import { MdExpandMore, MdExpandLess } from 'react-icons/md'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
//TYPES
import { ProjectType, ReportPeriod, UserType, LeaveType } from '../../../lib/sharedTypes'
import { ProductionReportType } from '../../../lib/sharedTypes'
//GQL
import { PRODUCTION_REPORT_QUERY, LEAVES_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'
//UTILS
import { secondsToDHMS } from '../utils/misc'

const getEndDate = (date: Date, period: ReportPeriod) => {
  switch (period) {
    case 'DAILY':
      return date
    case 'WEEKLY': {
      const endDate = date
      endDate.setDate(endDate.getDate() + 7)
      return endDate
    }
    case 'MONTHLY': {
      const endDate = date
      endDate.setMonth(endDate.getMonth() + 1)
      return endDate
    }
    default:
      return new Date()
  }
}

const EditorRow = (props: {
  editor: UserType
  report: ProductionReportType
  businessDaysCount: number
  startDate: string
  endDate: Date
}) => {
  const { editor, report, businessDaysCount, startDate, endDate } = props

  const [open, setOpen] = useState(false)
  const { data: { leaves = [] } = {} } = useQuery<{
    leaves: LeaveType[]
  }>(LEAVES_QUERY, {
    variables: {
      where: {
        date: {
          between: {
            from: startDate,
            to: endDate,
          },
        },
        user: {
          id: {
            eq: parseInt(`${editor.id}`),
          },
        },
      },
    },
  })

  const editorDurations = report.durations.filter((duration) => {
    return duration.editor.id === editor.id
  })

  const filteredLeaves = [
    ...new Set(
      leaves
        .map((leave) => leave.date.toString().split('T')[0])
        .filter((leave) => {
          const day = new Date(leave).getDay()
          return day !== 6 && day !== 0
        }),
    ),
  ]

  const durationGoal = 900 * (businessDaysCount > 0 ? businessDaysCount - filteredLeaves.length : 1)
  const durationValidated = editorDurations.reduce((acc, el) => {
    acc += el.validated_duration
    return acc
  }, 0)
  const durationUploaded = editorDurations.reduce((acc, el) => {
    acc += el.uploaded_duration
    return acc
  }, 0)

  const projects = editorDurations.reduce((acc: string[], el) => {
    if (!acc.includes(el.project.code_name)) {
      acc.push(el.project.code_name)
    }
    return acc
  }, [])

  return (
    <Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            disabled={projects.length === 0}
            onClick={() => setOpen(!open)}
          >
            {open ? <MdExpandLess /> : <MdExpandMore />}
          </IconButton>
        </TableCell>
        <TableCell>{editor.last_name}</TableCell>
        <TableCell align="right">{editor.first_name}</TableCell>
        <TableCell align="right">{secondsToDHMS(durationGoal, false)}</TableCell>
        <TableCell align="right">{secondsToDHMS(durationValidated, false)}</TableCell>
        <TableCell align="right">{Math.round((durationValidated / durationGoal) * 100)}%</TableCell>
        <TableCell align="right">{secondsToDHMS(durationUploaded, false)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            padding: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Projet
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Durée théorique
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Durée validée
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Atteinte objectif
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Durée mise en ligne
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project) => {
                  return (
                    <TableRow key={project}>
                      <TableCell style={{ padding: 16, visibility: 'hidden' }}>
                        <IconButton aria-label="expand row" size="small">
                          {open ? <MdExpandLess /> : <MdExpandMore />}
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ visibility: 'hidden' }}>{editor.last_name}</TableCell>
                      <TableCell align="right">{project}</TableCell>
                      <TableCell align="right">{secondsToDHMS(durationGoal / projects.length, false)}</TableCell>
                      <TableCell align="right">
                        {secondsToDHMS(
                          editorDurations
                            .filter((duration) => duration.project.code_name === project)
                            .reduce((acc, el) => {
                              acc += el.validated_duration
                              return acc
                            }, 0),
                          false,
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {Math.round(
                          (editorDurations
                            .filter((duration) => duration.project.code_name === project)
                            .reduce((acc, el) => {
                              acc += el.validated_duration
                              return acc
                            }, 0) /
                            (durationGoal / projects.length)) *
                            100,
                        )}
                        %
                      </TableCell>
                      <TableCell align="right">
                        {secondsToDHMS(
                          editorDurations
                            .filter((duration) => duration.project.code_name === project)
                            .reduce((acc, el) => {
                              acc += el.uploaded_duration
                              return acc
                            }, 0),
                          false,
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const ProjectRow = (props: { project: ProjectType; report: ProductionReportType }) => {
  const { project, report } = props
  const [open, setOpen] = useState(false)
  const projectReports = report.project_reports.filter((project_report) => {
    return project_report.project.id === project.id
  })
  const projectDurations = report.durations.filter((duration) => {
    return duration.project.id === project.id
  })
  const durationValidated = projectDurations.reduce((acc, el) => {
    acc += el.validated_duration
    return acc
  }, 0)

  const durationUploaded = projectDurations.reduce((acc, el) => {
    acc += el.uploaded_duration
    return acc
  }, 0)

  const shotDuration = projectReports.reduce((acc, el) => {
    acc += el.shot_duration
    return acc
  }, 0)

  const stockDuration = projectReports.reduce((acc, el) => {
    acc += el.stock_duration
    return acc
  }, 0)

  const editors = report.durations.reduce((acc: UserType[], el) => {
    if (!acc.includes(el.editor) && el.project.id === project.id) {
      acc.push(el.editor)
    }
    return acc
  }, [])

  return (
    <Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            disabled={editors.length === 0}
            onClick={() => setOpen(!open)}
          >
            {open ? <MdExpandLess /> : <MdExpandMore />}
          </IconButton>
        </TableCell>
        <TableCell>{project.code_name}</TableCell>
        <TableCell align="right">{secondsToDHMS(durationValidated, false)}</TableCell>
        <TableCell align="right">{secondsToDHMS(durationUploaded, false)}</TableCell>
        <TableCell align="right">{secondsToDHMS(shotDuration, false)}</TableCell>
        <TableCell align="right">{secondsToDHMS(stockDuration, false)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            padding: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Nom</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Prénom
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Durée validée
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Durée mise en ligne
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {editors.map((editor) => {
                  return (
                    <TableRow key={editor.id}>
                      <TableCell component="th" scope="row">
                        <IconButton aria-label="expand row" size="small" style={{ visibility: 'hidden' }}>
                          {open ? <MdExpandLess /> : <MdExpandMore />}
                        </IconButton>
                      </TableCell>
                      <TableCell>{editor.last_name}</TableCell>
                      <TableCell align="right">{editor.first_name}</TableCell>
                      <TableCell align="right">
                        {secondsToDHMS(
                          report.durations
                            .filter((duration) => {
                              return duration.project.id === project.id && duration.editor.id === editor.id
                            })
                            .reduce((acc, el) => {
                              acc += el.validated_duration
                              return acc
                            }, 0),
                          false,
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {secondsToDHMS(
                          report.durations
                            .filter((duration) => {
                              return duration.project.id === project.id && duration.editor.id === editor.id
                            })
                            .reduce((acc, el) => {
                              acc += el.uploaded_duration
                              return acc
                            }, 0),
                          false,
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const Report = () => {
  const { period, date } = useParams()
  const [businessDaysCount, setBusinessDaysCount] = useState(0)
  const theme = useTheme()

  if (!period || !date) {
    return (
      <Layout title="Rapport">
        <Error error={'Erreur de date ou de période'} />
      </Layout>
    )
  }

  const startDate = date
  const endDate = getEndDate(new Date(date), period as ReportPeriod)

  const {
    loading,
    error,
    data: { productionReport } = {},
  } = useQuery<{
    productionReport: ProductionReportType
  }>(PRODUCTION_REPORT_QUERY, {
    variables: {
      period: period,
      where: {
        report_date: {
          between: {
            from: startDate,
            to: endDate,
          },
        },
      },
    },
  })

  const getBusinessDays = (start: Date, end: Date) => {
    const businessDays: Date[] = []
    let currentDate = start
    while (currentDate < end) {
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
      if (!businessDays.includes(currentDate) && currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        businessDays.push(currentDate)
      }
    }
    setBusinessDaysCount(businessDays.length)
  }

  useEffect(() => {
    getBusinessDays(new Date(startDate), endDate)
  }, [])

  const periodMap: Record<keyof typeof ReportPeriod, string> = {
    DAILY: 'quotidien',
    WEEKLY: 'hebdomadaire',
    MONTHLY: 'mensuel',
  }

  if (error) {
    return (
      <Layout title="Rapport">
        <Error error={error} />
      </Layout>
    )
  }

  if (loading || !productionReport) {
    return (
      <Layout title="Rapport">
        <Loading />
      </Layout>
    )
  }

  const editors = productionReport.durations.reduce((acc: UserType[], duration) => {
    if (!acc.includes(duration.editor) && duration.editor.current) {
      acc.push(duration.editor)
    }
    return acc
  }, [])

  const projects = productionReport.project_reports.reduce((acc: ProjectType[], project_report) => {
    if (!acc.includes(project_report.project)) {
      acc.push(project_report.project)
    }
    return acc
  }, [])

  return (
    <Layout title={`Rapport ${periodMap[period as keyof typeof ReportPeriod]} au ${date}`}>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Title title={`Rapport ${periodMap[period as keyof typeof ReportPeriod]} au ${date}`} />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Paper elevation={3} style={{ padding: theme.spacing(1) }}>
            <Grid item>
              <Typography variant="h5">Informations générales</Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: theme.spacing(1) }}>
                  Nombre de jours ouvrés:
                </Typography>
                <Typography variant="body1">{businessDaysCount}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: theme.spacing(1) }}>
                  Durée filmée:
                </Typography>
                <Typography variant="body1">
                  {secondsToDHMS(
                    productionReport.project_reports.reduce((acc, el) => {
                      acc += el.shot_duration
                      return acc
                    }, 0),
                    false,
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: theme.spacing(1) }}>
                  Durée en stock:
                </Typography>
                <Typography variant="body1">
                  {secondsToDHMS(
                    productionReport.project_reports.reduce((acc, el) => {
                      acc += el.stock_duration
                      return acc
                    }, 0),
                    false,
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: theme.spacing(1) }}>
                  Durée validée:
                </Typography>
                <Typography variant="body1">
                  {secondsToDHMS(
                    productionReport.durations.reduce((acc, el) => {
                      acc += el.validated_duration
                      return acc
                    }, 0),
                    false,
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: theme.spacing(1) }}>
                  Durée mise en ligne:
                </Typography>
                <Typography variant="body1">
                  {secondsToDHMS(
                    productionReport.durations.reduce((acc, el) => {
                      acc += el.uploaded_duration
                      return acc
                    }, 0),
                    false,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid item>
            <Typography variant="h6">Durées par monteur</Typography>
          </Grid>
          <Grid>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Nom</TableCell>
                    <TableCell align="right">Prénom</TableCell>
                    <TableCell align="right">Durée théorique</TableCell>
                    <TableCell align="right">Durée validée</TableCell>
                    <TableCell align="right">Atteinte objectif</TableCell>
                    <TableCell align="right">Durée mise en ligne</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editors.length !== 0 ? (
                    editors.map((editor) => (
                      <EditorRow
                        key={editor.id}
                        editor={editor}
                        report={productionReport}
                        businessDaysCount={businessDaysCount}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        align="center"
                        style={{ height: '250px', color: theme.palette.text.disabled }}
                      >
                        <Grid container direction="column" justifyContent="center">
                          <Grid item>Introuvable</Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <Typography variant="h6">Durées par projet</Typography>
          </Grid>
          <Grid>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Projet</TableCell>
                    <TableCell align="right">Durée validée</TableCell>
                    <TableCell align="right">Durée mise en ligne</TableCell>
                    <TableCell align="right">Durée filmée</TableCell>
                    <TableCell align="right">Durée en stock</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects.length !== 0 ? (
                    projects.map((project) => (
                      <ProjectRow key={project.id} project={project} report={productionReport} />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ height: '250px', color: theme.palette.text.disabled }}
                      >
                        <Grid container direction="column" justifyContent="center">
                          <Grid item>Introuvable</Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Report
