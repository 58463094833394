//TYPES
import { ProjectType } from '../../../../lib/sharedTypes'
//ICONS
import { BiCameraMovie } from 'react-icons/bi'
import { RiMegaphoneLine } from 'react-icons/ri'

//MATERIAL
import { Grid, Tooltip, makeStyles, useTheme } from '@material-ui/core'
//UTILS
import { stringToColor } from '../../utils/misc'

const useStyles = makeStyles((theme) => ({
  firstCol: {
    left: 0,
    background: theme.palette.background.default,
    zIndex: 1000,
    position: 'sticky',
    padding: theme.spacing(1),
    minWidth: 250,
    maxWidth: 250,
    border: `1px solid grey`,
  },
  column: {
    minWidth: 150,
    maxWidth: 150,
    border: `1px solid grey`,
  },
  currentWeek: {
    minWidth: 150,
    maxWidth: 150,
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
    border: `1px solid grey`,
  },
  dayBox: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 42,
    width: 28,
  },
}))

type CellPropsType = {
  status: string | null
  index: number
  onCellClick: () => void
  project?: ProjectType
}

const Cell = ({ index, status, project, onCellClick }: CellPropsType) => {
  const classes = useStyles()
  const theme = useTheme()

  if (status === 'shooting' && project) {
    return (
      <Tooltip key={index} title={`Tournage ${project.code_name}`}>
        <Grid
          item
          className={classes.dayBox}
          style={{
            cursor: 'pointer',
            backgroundColor: stringToColor(project.code_name),
            borderLeft: index !== 0 && index !== 5 ? `1px solid ${theme.palette.action.disabled}` : 'none',
          }}
          onClick={() => {
            onCellClick()
          }}
        >
          <BiCameraMovie size={18} style={{ color: 'white' }} />
        </Grid>
      </Tooltip>
    )
  }

  if (status === 'editing' && project) {
    return (
      <Tooltip key={index} title={`Montage ${project.code_name}`}>
        <Grid
          item
          className={classes.dayBox}
          style={{
            cursor: 'pointer',
            backgroundColor: stringToColor(project.code_name),
            borderLeft: index !== 0 && index !== 5 ? `1px solid ${theme.palette.action.disabled}` : 'none',
          }}
          onClick={() => {
            onCellClick()
          }}
        ></Grid>
      </Tooltip>
    )
  }

  if (status === 'marketing' && project) {
    return (
      <Tooltip key={index} title={`Marketing ${project.code_name}`}>
        <Grid
          item
          className={classes.dayBox}
          style={{
            cursor: 'pointer',
            backgroundColor: stringToColor(project.code_name),
            borderLeft: index !== 0 && index !== 5 ? `1px solid ${theme.palette.action.disabled}` : 'none',
          }}
          onClick={() => {
            onCellClick()
          }}
        >
          <RiMegaphoneLine size={18} style={{ color: 'white' }} />
        </Grid>
      </Tooltip>
    )
  }

  if (status === 'off') {
    return (
      <Tooltip key={index} title="Absence">
        <Grid
          item
          className={classes.dayBox}
          style={{
            cursor: 'pointer',
            borderLeft: index !== 0 && index !== 5 ? `1px solid ${theme.palette.action.disabled}` : 'none',
          }}
          onClick={() => {
            onCellClick()
          }}
        >
          X
        </Grid>
      </Tooltip>
    )
  }

  return (
    <Grid
      key={index}
      item
      className={classes.dayBox}
      style={{
        cursor: 'pointer',
        borderLeft: index !== 0 && index !== 5 ? `1px solid ${theme.palette.action.disabled}` : 'none',
      }}
      onClick={() => {
        onCellClick()
      }}
    ></Grid>
  )
}

export default Cell
