import { useState, useEffect, useRef } from 'react'
// MATERIAL UI
import { Button, Grid, TextField, FormGroup, FormControlLabel, Switch } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
//COMPONENTS
import { FormLayout } from '../../index'
import { TextEditorJS, Error } from '../../../index'
//TYPE
import { DeepPartial } from '../../../../utils/types'
import { CoursePartType, CourseType, LessonTextType, LessonType } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { COURSE_SYNTHESIS_QUERY } from '../../../../gql/queries'
import {
  UPDATE_LESSON_TEXT_MUTATION,
  CREATE_LESSON_TEXT_MUTATION,
  PREVIEW_LESSON_TEXT_MUTATION,
} from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//ROUTER
import { useParams } from 'react-router'
//UTILS
import { handleError } from '../../../../utils/handleError'
// EDITORJS
import '../../../TextEditor.css'
// CONVERT QUILL DELTA
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { int2roman } from '../../../../utils/misc'
import EditorJS from '@editorjs/editorjs'
import { EDITOR_JS_TOOLS } from '../../../Forms/lesson/text/tools/EditorTools'

type LessonSynthesisPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const LessonSynthesis = ({ lesson, refetch, handleClose }: LessonSynthesisPropsType) => {
  const [setAlert] = useAlert()
  const summary_editor_instance = useRef<EditorJS | null>()

  const { code_name } = useParams()
  const [selectedLessonBlocks, setSelectedLessonBlocks] = useState<BlockType[]>()
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const [updateLessonTextMutation] = useMutation<{
    updateLessonText: LessonTextType
  }>(UPDATE_LESSON_TEXT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon synthèse mise-à-jour` })
      await refetch()
      handleClose()
    },
  })

  useEffect(() => {
    summary_editor_instance.current = new EditorJS({
      holder: 'summary_editor',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tools: EDITOR_JS_TOOLS,
      tunes: ['textVariant'],
    })
    return () => {
      if (summary_editor_instance.current) {
        summary_editor_instance.current?.destroy()
      }
    }
  }, [])

  const [createLessonTextMutation] = useMutation<{
    createLessonText: LessonTextType
  }>(CREATE_LESSON_TEXT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon synthèse créée` })
      await refetch()
      handleClose()
    },
  })

  const [previewLessonTextMutation] = useMutation<{
    previewLessonText: string
  }>(PREVIEW_LESSON_TEXT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `Prévisualisation leçon synthèse créée` })
      window.open('https://app.walter-learning.com/preview?s3=' + data.previewLessonText, '_blank')
    },
  })

  const { error: errorCourse, data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_SYNTHESIS_QUERY, {
    variables: {
      where: { code_name: { eq: code_name } },
      order: {
        field: 'position',
        sort: 'ASC',
      },
    },
  })

  if (errorCourse) {
    return <Error error={errorCourse} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonTextInput: Record<string, any> = {
      id: stateLesson?.text?.id,
      content: stateLesson?.text?.content,
      type: 'SYNTHESIS',
    }

    if (stateLesson?.id) {
      await updateLessonTextMutation({ variables: { input: lessonTextInput } })
    } else {
      lessonTextInput.lesson = {
        course: { id: course?.id },
        validated: stateLesson?.validated || false,
        summary_required: stateLesson?.summary_required || false,
        name: stateLesson?.name,
        summary_state: stateLesson?.summary_state,
        summary: stateLesson?.summary,
      }
      await createLessonTextMutation({ variables: { input: lessonTextInput } })
    }
  }

  type OptionType = {
    part: CoursePartType
    sub_part: CoursePartType
    ss_part: CoursePartType
  }

  const options = course?.parts.reduce((acc: OptionType[], part) => {
    part.sub_parts.map((sub_part) => {
      sub_part.sub_parts.map((ss_part) => {
        acc.push({
          part: part,
          sub_part: sub_part,
          ss_part: ss_part,
        })
      })
    })
    return acc
  }, [])

  type BlockType = {
    id: string
    type: string
    data: { text: string; level?: number }
    tunes: { textVariant: string }
  }

  const generateBlocksFromHTML = async (html: string) => {
    console.log('generateBlocksFromHTML', html)
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    const strongTags = tempDiv.querySelectorAll('strong')

    strongTags.forEach((strongTag) => {
      // Check if the <strong> tag is inside an <li> tag
      if (!strongTag.closest('li')) {
        // Create a new <b> element
        const bTag = document.createElement('b')

        // Copy the content of the <strong> tag to the new <b> tag
        bTag.innerHTML = strongTag.innerHTML

        // Replace the <strong> tag with the <b> tag
        strongTag.replaceWith(bTag)
      }
    })

    html = tempDiv.innerHTML
    html = html.replaceAll('<br/>', '<p/>')
    // html = html.replaceAll('<strong>', '<b>')
    // html = html.replaceAll('</strong>', '</b>')
    html = html.replaceAll('<em>', '<i>')
    html = html.replaceAll('</em>', '</i>')

    await summary_editor_instance?.current?.isReady
    await summary_editor_instance?.current?.blocks.renderFromHTML(html)
    const content = await summary_editor_instance?.current?.save()
    console.log('content', content)
    return content?.blocks
  }

  const generateBlocks = async (options: OptionType[]) => {
    console.log('GENERATEBLOCKS', options)
    const blocks: BlockType[] = []
    for await (const option of options) {
      let html = ''
      if (option.ss_part.lesson.summary && (option.ss_part.lesson.summary as Record<string, any>).ops) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const converter = new QuillDeltaToHtmlConverter((option.ss_part.lesson.summary as Record<string, any>).ops, {})
        html = converter.convert()
      }
      const summary_blocks = await generateBlocksFromHTML(html)
      console.log('summary_blocks', summary_blocks)
      blocks.push({
        id: `Title ${option.ss_part.lesson.id}`,
        type: 'paragraph',
        data: { text: `<b>${option.ss_part.lesson.name}</b>` },
        tunes: { textVariant: '' },
      })

      if (summary_blocks) {
        summary_blocks.map((summary_block, index) => {
          summary_block = { ...summary_block, id: `Summary ${option.ss_part.lesson.id}-${index}` }
          blocks.push(summary_block as unknown as BlockType)
        })
      }
    }
    if (blocks && blocks.length !== 0) {
      setSelectedLessonBlocks(blocks)
    }
    summary_editor_instance?.current?.blocks?.clear()
  }

  return (
    <FormLayout
      type={lesson ? 'update' : 'create'}
      title={lesson ? 'Mettre à jour la leçon synthèse' : 'Ajouter une leçon synthèse'}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id
          ? JSON.stringify(stateLesson) === JSON.stringify(lesson)
          : stateLesson === undefined || !stateLesson?.text?.content
      }
    >
      {!stateLesson?.id && (
        <Grid item container spacing={2} alignItems="center">
          <Grid item container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                required
                label="Nom"
                name="name"
                defaultValue={stateLesson?.name}
                onChange={(event) => {
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      ...{ [event.target.name]: event.target.value },
                    }
                  })
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={stateLesson?.text?.downloadable}
                onChange={(event) => {
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      text: {
                        ...prevState?.text,
                        downloadable: event.target.checked,
                      },
                    }
                  })
                }}
                name="downloadable"
              />
            }
            label={stateLesson?.text?.downloadable ? 'Document téléchargeable' : 'Document non-téléchargeable'}
          />
        </FormGroup>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        {options && (
          <Autocomplete
            disableCloseOnSelect={true}
            multiple
            options={options}
            groupBy={(option) => {
              return `Partie ${int2roman(option.part.position + 1)} - Chapitre ${String.fromCharCode(
                96 + option.sub_part.position + 1,
              ).toUpperCase()} - ${option.sub_part.name}`
            }}
            getOptionLabel={(option) => {
              return `${option.ss_part.lesson.name}`
            }}
            getOptionSelected={(option, value) => option?.ss_part?.lesson?.id === value?.ss_part?.lesson?.id}
            renderInput={(params) => <TextField {...params} label="Leçons de la synthèse" variant="outlined" />}
            onChange={async (_, value) => {
              console.log('value', value)
              if (value) {
                await generateBlocks(value)
              }
            }}
          />
        )}
      </Grid>
      <Grid item style={{ width: '100%', height: '100%' }}>
        <TextEditorJS
          content={stateLesson?.text?.content}
          lessonBlocks={selectedLessonBlocks}
          onChange={(content: any) => {
            setStateLesson((prevState) => {
              return {
                ...prevState,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...{ text: { ...prevState?.text, content: content } },
              }
            })
          }}
        />
      </Grid>
      <Grid item container justifyContent="center" alignItems="center">
        <Button
          variant="outlined"
          color="primary"
          disabled={!stateLesson?.text?.content}
          onClick={async () => {
            await previewLessonTextMutation({
              variables: {
                input: {
                  id: stateLesson?.text?.id ?? null,
                  content: stateLesson?.text?.content,
                },
              },
            })
          }}
        >
          Prévisualisation
        </Button>
      </Grid>
      <div id="summary_editor" style={{ display: 'none' }} />
    </FormLayout>
  )
}

export default LessonSynthesis
