import { useState } from 'react'
import { DeepPartial } from '../../../utils/types'
import { LessonQuestionType, CourseType } from '../../../../../lib/sharedTypes'
//MATERIAL UI
import { Grid, Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { COURSE_QUESTIONS_QUERY } from '../../../gql/queries'
import { useQuery } from '@apollo/client'
import { getQuestionCategory, replaceImages } from '../../../utils/misc'

type DuplicateQuestionPropsType = {
  course: CourseType
  setPropositionImages: React.Dispatch<React.SetStateAction<Record<string, File | undefined>>>
  setQuestionType: React.Dispatch<React.SetStateAction<'mcQuestion' | 'gapFillQuestion' | 'rankingQuestion' | null>>
  setStateQuestion: React.Dispatch<React.SetStateAction<DeepPartial<LessonQuestionType> | undefined>>
  handleClose: () => void
}

const DuplicateQuestion = ({
  course,
  setStateQuestion,
  handleClose,
  setQuestionType,
  setPropositionImages,
}: DuplicateQuestionPropsType) => {
  const [selectedQuestion, setSelectedQuestion] = useState<DeepPartial<LessonQuestionType>>()
  const { data } = useQuery<{ course: CourseType }>(COURSE_QUESTIONS_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: course.code_name,
        },
      },
    },
  })

  if (data) {
    return (
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Autocomplete
            fullWidth
            options={data?.course.lessons_questions}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              `${
                option?.mc_question?.statement || option?.ranking_question?.statement || option?.gapfill_question?.text
              }`.slice(0, 150) || ''
            }
            onChange={(_, value) => {
              if (value) {
                setSelectedQuestion(value)
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" label="Question" />
            }}
          />
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            disabled={!selectedQuestion}
            variant="contained"
            color="primary"
            onClick={async () => {
              if (selectedQuestion && getQuestionCategory(selectedQuestion as LessonQuestionType)) {
                setQuestionType(getQuestionCategory(selectedQuestion as LessonQuestionType))
              }

              if (selectedQuestion) {
                delete selectedQuestion?.id
                let newQuestion = await replaceImages<DeepPartial<LessonQuestionType>>(selectedQuestion, 's3_image')

                if (newQuestion?.mc_question?.mc_items) {
                  newQuestion = {
                    ...newQuestion,
                    mc_question: {
                      ...newQuestion.mc_question,
                      id: undefined,
                      mc_items: newQuestion.mc_question.mc_items.map((item) => {
                        const newId = Math.random()
                        if (item) {
                          item.id = newId
                          if (item?.s3_image) {
                            setPropositionImages((prevState) => {
                              return {
                                ...prevState,
                                [newId]: item?.s3_image,
                              }
                            })
                          }
                        }
                        return item
                      }),
                    },
                  }
                }
                if (newQuestion.ranking_question?.ranking_items) {
                  newQuestion = {
                    ...newQuestion,
                    id: undefined,
                    ranking_question: {
                      ...newQuestion.ranking_question,
                      ranking_items: newQuestion.ranking_question?.ranking_items?.map((item) => {
                        if (item) {
                          item.id = new Date().getTime()
                        }
                        return item
                      }),
                    },
                  }
                }
                if (newQuestion.gapfill_question) {
                  newQuestion = {
                    ...newQuestion,
                    id: undefined,
                  }
                }
                setStateQuestion(newQuestion)
                handleClose()
              }
            }}
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    )
  }
  return <></>
}

export default DuplicateQuestion
