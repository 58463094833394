// REACT-HOOKS
import { useRef } from 'react'
// MATERIAL UI
import { Grid, Paper, useTheme, Typography } from '@material-ui/core'
// ICON
import { MdCheckCircle } from 'react-icons/md'
//REACT DND
import { Identifier } from 'dnd-core'
import { useDrag, useDrop } from 'react-dnd'
// TYPES
import { CoursePartType, LessonType } from '../../../../lib/sharedTypes'
import { getLessonCategory } from '../../utils/misc'

type LessonCardShortPropsType = {
  lesson: LessonType
  isUsed: boolean
  pushLesson: (lesson: LessonType, target: CoursePartType, type: string) => void
  canDrag: boolean
}

export const LessonCardShort: React.FC<LessonCardShortPropsType> = ({
  lesson,
  isUsed,
  pushLesson,
  canDrag,
}: LessonCardShortPropsType) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<
    { item: LessonType },
    { item: LessonType },
    { handlerId: Identifier | null; isOver: boolean; canDrop: boolean }
  >(
    {
      accept: ['chapter'],
      drop: () => {
        return { item: lesson }
      },
      collect: (monitor) => {
        return {
          handlerId: monitor.getHandlerId(),
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    },
    [],
  )

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'lesson',
      canDrag: canDrag,
      item: { item: lesson },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{ item: CoursePartType; type: string }>()
        if (item && dropResult) {
          pushLesson(item.item, dropResult.item, dropResult.type)
        }
      },
    }),
    [pushLesson],
  )

  drag(drop(ref))

  return (
    <Grid item ref={ref} data-handler-id={handlerId} style={{ marginTop: theme.spacing(1), width: '100%' }}>
      <Paper
        elevation={3}
        style={{
          borderRadius: '4px',
          opacity: isDragging ? 0 : 1,
          padding: theme.spacing(1),
          cursor: 'grab',
        }}
      >
        <Grid container direction="row"></Grid>
        <Grid container alignItems="center" direction="row">
          <MdCheckCircle size="22" color={isUsed ? theme.palette.warning.main : theme.palette.success.main} />
          <Typography variant="caption" style={{ marginLeft: 5 }}>
            Lesson - {getLessonCategory(lesson)}
          </Typography>
        </Grid>
        <Typography variant="caption">{lesson?.name}</Typography>
      </Paper>
    </Grid>
  )
}

export default LessonCardShort
