//REACT
import { useState } from 'react'
//MATERIAL UI
import { Grid, TextField, useTheme } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../index'
import { Error } from '../../index'
//TYPE
import { ProductType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type ColorFormPropsType = {
  product: ProductType
  refetch: () => void
  handleClose: () => void
}

const ColorForm = ({ product, refetch, handleClose }: ColorFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      refetch()
      handleClose()
    },
  })

  const handleChange = (key: string, value: string) => {
    setStateProduct((prevState) => {
      if (!prevState) return prevState
      return {
        ...prevState,
        ...{ [key]: value },
      }
    })
  }

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: stateProduct.id,
      color: stateProduct.color,
    }
    await updateProductMutation({ variables: { input: productInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour la couleur"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProduct) === JSON.stringify(product)}
    >
      <Grid item container>
        <Grid item container>
          <Grid
            item
            style={{
              width: '100%',
              height: 250,
              backgroundColor: stateProduct.color,
              border: `1px solid ${theme.palette.text.disabled}`,
              borderRadius: 5,
            }}
          ></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          label="Couleur (HEX)"
          name="color"
          value={stateProduct.color}
          onChange={(e) => {
            e.preventDefault()
            handleChange(e.target.name, e.target.value)
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
    </FormLayout>
  )
}

export default ColorForm
