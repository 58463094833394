export class WalterMediaUpload {
  upload_token: string
  progress: number
  constructor(upload_token: string) {
    this.upload_token = upload_token
    this.progress = 0
  }
  async uploadFile(file: File) {
    const defaultChunkSize = 1000000
    let start = 0
    let end = defaultChunkSize
    let completed = false

    while (!completed) {
      const chunk = file.slice(start, end)
      const formdata = new FormData()
      formdata.append('timestamp', new Date().getTime().toString())
      formdata.append('filesize', file.size.toString())
      formdata.append('chunk', chunk, file.name)
      const requestOptions = {
        method: 'POST',
        body: formdata,
        headers: {
          'Access-Control-Request-Private-Network': 'true',
        },
      }
      const response = await fetch(
        `https://media.production.walter-learning.com/upload/${this.upload_token}`,
        requestOptions,
      )
      /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
      const result = await response.json()
      if (result.payload) {
        if (result.payload.completed) {
          this.progress = 1
          completed = result.payload.completed
          return
        }
        start = result.payload.chunkOffset as number
        end = (result.payload.chunkOffset as number) + (result.payload.chunkSize as number)
        this.progress = result.payload.chunkOffset / file.size
      } else {
        throw Error("Erreur lors de l'upload du fichier")
      }
    }
  }
}
