//REACT
import { useState } from 'react'
// MATERIAL UI
import { Paper, Grid, Chip, Typography, Divider, Button, useTheme, makeStyles } from '@material-ui/core'
//COMPONENTS
import { CommercialForm } from '../../components/Forms/product'
import { Loading, Error, CustomModal } from '../../components/index'
// TYPE
import { ProductType } from '../../../../lib/sharedTypes'
//GQL
import { PRODUCT_SALES_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
//REACT ROUTER
import { useParams, useOutletContext } from 'react-router'
//UTILS
import { isAuthorized } from '../../utils/misc'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const CommercialAspects = () => {
  const { refetchProduct } = useOutletContext<{ refetchProduct: () => Promise<any> }>()
  const { code_name } = useParams()
  const classes = useStyles()
  const [updateModal, setUpdateModal] = useState(false)
  const theme = useTheme()
  const {
    loading,
    error,
    data: { product } = {},
    refetch,
  } = useQuery<{
    product: ProductType
  }>(PRODUCT_SALES_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
    async onCompleted() {
      await refetchProduct()
    },
  })

  if (loading || !product) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column">
      {/* DESCRIPTION */}
      <Paper className={classes.paper}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Aspects commerciaux</Typography>
            <Button
              color="primary"
              disabled={!isAuthorized(['ADMIN', 'SALE'])}
              variant="outlined"
              onClick={() => {
                setUpdateModal(true)
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={1}>
          <Grid item container direction="column" sm={4}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Disponible à la vente sur Salesforce
              </Typography>
            </Grid>
            <Grid item>
              {product.is_active ? (
                <Chip
                  size="small"
                  label={'Oui'}
                  style={{
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.success.contrastText,
                  }}
                />
              ) : (
                <Chip
                  size="small"
                  label={'Non'}
                  style={{
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container direction="column" sm={4}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                La modifications sont envoyées vers Salesforce
              </Typography>
            </Grid>
            <Grid item>
              {product.send_to_walter_sf ? (
                <Chip
                  size="small"
                  label={'Oui'}
                  style={{
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.success.contrastText,
                  }}
                />
              ) : (
                <Chip
                  size="small"
                  label={'Non'}
                  style={{
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container direction="column" sm={4}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Le produit peut être offert
              </Typography>
            </Grid>
            <Grid item>
              {product.is_offerable ? (
                <Chip
                  size="small"
                  label={'Oui'}
                  style={{
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.success.contrastText,
                  }}
                />
              ) : (
                <Chip
                  size="small"
                  label={'Non'}
                  style={{
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={1}>
          <Grid item container direction="column" sm={4}>
            <Typography variant="body1" className={classes.title}>
              Arguments de vente
            </Typography>
            <Grid item>
              <Typography variant="body2">{product?.selling_point ?? '-'}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" sm={4}>
            <Typography variant="body1" className={classes.title}>
              Phrase d'accroche
            </Typography>
            <Grid item>
              <Typography variant="body2">{product?.catchphrase ?? '-'}</Typography>
            </Grid>
          </Grid>

          <Grid item container direction="column" sm={4}>
            <Typography variant="body1" className={classes.title}>
              Affiché sur Walter-CRM
            </Typography>
            <Grid item>
              {product.is_crm_displayed ? (
                <Chip
                  size="small"
                  label={'Oui'}
                  style={{
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.success.contrastText,
                  }}
                />
              ) : (
                <Chip
                  size="small"
                  label={'Non'}
                  style={{
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={1}>
          <Grid item container direction="column" sm={4}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Prix affiché
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {product.listing_price
                  ? product.listing_price.toLocaleString('fr', {
                      style: 'currency',
                      currency: 'EUR',
                    })
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" sm={4}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Prix minimum
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {product.minimum_selling_price ? (
                  product.minimum_selling_price.toLocaleString('fr', {
                    style: 'currency',
                    currency: 'EUR',
                  })
                ) : (
                  <Alert severity="warning">Requis pour publier la fiche produit</Alert>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" sm={4}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Indémnités DPC maximales
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {product.dpc_compensation
                  ? product.dpc_compensation.toLocaleString('fr', {
                      style: 'currency',
                      currency: 'EUR',
                    })
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item container direction="column" sm={6}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Coût estimé de la formation
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {product.cogs_estimated
                  ? product.cogs_estimated.toLocaleString('fr', {
                      style: 'currency',
                      currency: 'EUR',
                    })
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" sm={6}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Coût théorique de la formation
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {product.cogs_theoretical
                  ? product.cogs_theoretical.toLocaleString('fr', {
                      style: 'currency',
                      currency: 'EUR',
                    })
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Processus de vente
            </Typography>
          </Grid>
          <Grid item>
            {product.sale_processes.map((sp, index) => (
              <Chip size="small" key={index} label={sp.name} color="primary" style={{ margin: theme.spacing(1) }} />
            ))}
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Id du template Docusign
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{product.docusign_template_id}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <CustomModal
        open={updateModal}
        onClose={() => {
          setUpdateModal(false)
        }}
        animation="slide-up"
      >
        <CommercialForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal(false)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default CommercialAspects
