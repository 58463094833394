import { useState } from 'react'
// COMPONENTS
import ProductThemeForm from '../components/Forms/productTheme/productThemeForm'
import { Layout, Title, Loading, Error, CustomModal } from '../components'
// MATERIAL UI
import {
  CircularProgress,
  Grid,
  Button,
  Divider,
  Typography,
  makeStyles,
  useTheme,
  Paper,
  Chip,
} from '@material-ui/core'
// GQL
import { PRODUCT_THEME_QUERY } from '../gql/queries'
import { PUBLISH_PRODUCT_THEME_MUTATION } from '../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { ProductTheme } from '../../../server/src/entities'
import { isAuthorized } from '../utils/misc'
//HOOKS
import { useAlert } from '../hooks'
import { handleError } from '../utils/handleError'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
  publishBTN: {
    width: 100,
    color: 'white',
    background: theme.palette.success.main,
    '&:hover': {
      background: theme.palette.success.light,
    },
  },
}))

const Theme = () => {
  const [setAlert] = useAlert()
  const navigate = useNavigate()
  const classes = useStyles()
  const theme = useTheme()
  const { theme_id } = useParams()
  const [updateModal, setUpdateModal] = useState('')

  if (!theme_id) {
    return (
      <Layout title="Theme">
        <Error error={'Unknown theme_id'} />
      </Layout>
    )
  }
  const {
    loading,
    error,
    data: { productTheme } = {},
    refetch,
  } = useQuery<{
    productTheme: ProductTheme
  }>(PRODUCT_THEME_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(theme_id.toString()),
        },
      },
    },
  })

  const [publishTheme, { loading: productThemeLoading }] = useMutation<{ publishProductTheme: ProductTheme }>(
    PUBLISH_PRODUCT_THEME_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted(data) {
        console.log('data', data)
        setAlert({ severity: 'success', content: `${data.publishProductTheme.code_name} en cours de publication` })
        await refetch()
      },
    },
  )

  if (loading || !productTheme) {
    return (
      <Layout title={theme_id || 'Theme'}>
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title={theme_id || 'Theme'}>
        <Error error={error} />
      </Layout>
    )
  }

  return (
    <Layout title={`Theme - ${productTheme.code_name}`}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item>
            <Title title={`${productTheme?.code_name}`} />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.publishBTN}
              disabled={!isAuthorized(['ADMIN', 'SALE'])}
              onClick={async () => {
                await publishTheme({ variables: { input: { id: productTheme?.id } } })
              }}
            >
              {productThemeLoading ? (
                <CircularProgress
                  variant="indeterminate"
                  size="1.5rem"
                  style={{ color: 'white' }}
                  id="upload_progress"
                />
              ) : (
                'PUBLIER'
              )}
            </Button>
          </Grid>
        </Grid>

        <Paper className={classes.paper}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Theme</Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('description')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item container direction="row">
              <Grid item container direction="column" xs={3}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Nom de code
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{productTheme?.code_name}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={3}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Titre
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{productTheme?.title ?? '-'}</Typography>
                </Grid>
              </Grid>

              <Grid item container direction="column" xs={3}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Position
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{productTheme?.position ?? '-'}</Typography>
                </Grid>
              </Grid>

              <Grid item container direction="column" xs={3}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Produit par défaut
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={productTheme?.default_product?.code_name}
                    onClick={() => {
                      navigate(`/products/${productTheme?.default_product?.code_name}`)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={12}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Arguments de vente
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{productTheme?.selling_point ?? '-'}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={12}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Description
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{productTheme?.description ?? '-'}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={12}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Phrase d'accroche
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{productTheme?.catchphrase ?? '-'}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              Produits
            </Typography>
            <Grid container spacing={1}>
              {productTheme?.products.map((product) => {
                return (
                  <Grid item key={product.id}>
                    <Chip
                      label={product.code_name}
                      onClick={() => {
                        navigate(`/products/${product.code_name}`)
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <CustomModal
        open={updateModal === 'description'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ProductThemeForm
          productTheme={productTheme}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default Theme
