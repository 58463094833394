//REACT
import React, { useState, useEffect } from 'react'
// MATERIAL UI
import { Grid, Typography, Button, useTheme } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
//TYPES
import { DeepPartial } from '../../../../utils/types'
import { LessonDocumentType, LessonType, LessonTextType } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_LESSON_DOCUMENT_MUTATION, UPDATE_LESSON_TEXT_MUTATION } from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//UTILS
import { handleError } from '../../../../utils/handleError'

type FileFormPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const FileForm = ({ lesson, refetch, handleClose }: FileFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [stateLesson, _setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [localDocument, setLocalDocument] = useState<string>()
  const pdfURL = localDocument ? localDocument : lesson?.document?.s3_file

  const [updateLessonDocumentMutation] = useMutation<{
    updateLessonDocument: LessonDocumentType
  }>(UPDATE_LESSON_DOCUMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Document uploadé` })
      await refetch()
      handleClose()
    },
  })

  const [updateLessonTextMutation] = useMutation<{
    updateLessonText: LessonTextType
  }>(UPDATE_LESSON_TEXT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon synthèse mise-à-jour` })
      await refetch()
      handleClose()
    },
  })

  useEffect(() => {
    if (!selectedFile) {
      setLocalDocument(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setLocalDocument(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (stateLesson?.document) {
      const lessonDocumentInput: Record<string, any> = {
        id: stateLesson?.document?.id,
        s3_file: selectedFile,
        downloadable: stateLesson?.document?.downloadable,
        type: stateLesson?.document?.type,
      }

      await updateLessonDocumentMutation({
        variables: {
          input: lessonDocumentInput,
        },
      })
    } else {
      const lessonTextInput: Record<string, any> = {
        id: stateLesson?.text?.id,
        s3_file: selectedFile,
        downloadable: stateLesson?.text?.downloadable,
        type: stateLesson?.text?.type,
      }
      await updateLessonTextMutation({ variables: { input: lessonTextInput } })
    }
  }

  return (
    <FormLayout
      type={'update'}
      title={'Mettre à jour le fichier'}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id
          ? JSON.stringify(stateLesson) === JSON.stringify(lesson) && !localDocument
          : stateLesson === undefined
      }
    >
      <Grid item container justifyContent="center">
        {pdfURL ? (
          <object data={`${pdfURL}#view=FitV`} width="100%" height="500px">
            <p>{pdfURL}</p>
          </object>
        ) : (
          <Grid
            item
            style={{
              width: '100%',
              height: 250,
              display: 'flex',
              border: `1px solid ${theme.palette.action.disabled}`,
              borderRadius: 5,
              background: theme.palette.background.default,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6" style={{ fontStyle: 'italic' }}>
              Selectionner un document
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="center">
        <Button color="primary" variant="contained" component="label">
          Selectionner
          <input hidden accept=".pdf" type="file" onChange={onSelectFile} />
        </Button>
      </Grid>
    </FormLayout>
  )
}

export default FileForm
