// REACT-HOOKS
import { useRef } from 'react'
// MATERIAL UI
import { Grid } from '@material-ui/core'
// DND
import { useDrag, useDrop } from 'react-dnd'
import type { Identifier } from 'dnd-core'
// TYPES
import { QuizQuestionType } from '../../../../lib/sharedTypes'

type ItemType = {
  qq_question: QuizQuestionType
  id: number
  index: number
  moveCard: (
    dragIndex: number,
    hoverIndex: number,
    item: ItemType,
    idTarget: Identifier | null,
    idItem: Identifier | null,
  ) => void
}

const EmptyCard = ({
  index,
  moveCard,
}: {
  index: number
  moveCard: (dragIndex: number, hoverIndex: number, idTarget: Identifier | null, idItem: Identifier | null) => void
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<ItemType, void, { handlerId: Identifier | null }>({
    accept: ['question_card', 'question_card_short'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop(item: ItemType, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      moveCard(dragIndex, hoverIndex, 'empty_card', monitor.getItemType())
      item.index = hoverIndex
    },
  })

  const [, drag] = useDrag({
    type: 'empty_card',
    canDrag: false,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return <Grid item xs={12} ref={ref} data-handler-id={handlerId} style={{ minHeight: '100%', width: '100%' }} />
}

export default EmptyCard
