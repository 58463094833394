import { useEffect, useState } from 'react'
// MATERIAL UI
import { Grid, TextField, IconButton, useTheme, FormGroup, FormControlLabel, Switch } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
//ICONS
import { MdOutlineAddCircleOutline } from 'react-icons/md'
// COMPONENTS
import { WriterForm } from '.'
import { FormLayout } from '../index'
import { Loading, Error, CustomModal } from '../..'
// TYPE
import { CourseType, WriterAssignmentType, WriterType } from '../../../../../lib/sharedTypes'
//GQL
import { useQuery, useMutation } from '@apollo/client'
import { WRITERS_QUERY } from '../../../gql/queries'
import { CREATE_WRITER_ASSIGNMENT_MUTATION, UPDATE_WRITER_ASSIGNMENT_MUTATION } from '../../../gql/mutations'
//UTILS
import { useAlert } from '../../../hooks'
import { handleError } from '../../../utils/handleError'
import { DeepPartial } from 'src/utils/types'

type WriterAssignmentPropsType = {
  course: CourseType
  writer_assignment?: WriterAssignmentType
  refetch: () => Promise<any>
  handleClose: () => void
}

const WriterAssignment = ({ course, writer_assignment, refetch, handleClose }: WriterAssignmentPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [writerModal, setWriterModal] = useState(false)
  const [stateWriterAssignment, setStateWriterAssignment] = useState<DeepPartial<WriterAssignmentType> | undefined>(
    writer_assignment,
  )
  const [createWriterAssignmentMutation] = useMutation<{ createWriterAssignment: WriterAssignmentType }>(
    CREATE_WRITER_ASSIGNMENT_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Mission créée` })
        await refetch()
        handleClose()
      },
    },
  )
  const [updateWriterAssignmentMutation] = useMutation<{ updateWriterAssignment: WriterAssignmentType }>(
    UPDATE_WRITER_ASSIGNMENT_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Mission mise-à-jour` })
        await refetch()
        handleClose()
      },
    },
  )

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (stateWriterAssignment?.id) {
      await updateWriterAssignmentMutation({
        variables: {
          input: {
            ...stateWriterAssignment,
            writer: { id: stateWriterAssignment?.writer?.id },
            course: { id: course.id },
          },
        },
      })
    } else {
      await createWriterAssignmentMutation({ variables: { input: stateWriterAssignment } })
    }
  }

  useEffect(() => {
    if (!writer_assignment) {
      setStateWriterAssignment({ active: true, course: { id: course.id } })
    }
  }, [writer_assignment])

  const {
    loading,
    error,
    data: { writers } = {},
    refetch: refetchWriters,
  } = useQuery<{ writers: WriterType[] }>(WRITERS_QUERY)

  if (loading) {
    return <Loading />
  }

  if (error || !writers) {
    return <Error error={'Erreur lors de la récupération des rédacteurs'} />
  }

  const startsAt = typeof stateWriterAssignment?.start === 'string' ? new Date(stateWriterAssignment.start) : null
  const endsAt = typeof stateWriterAssignment?.end === 'string' ? new Date(stateWriterAssignment.end) : null
  const localStartsAt = startsAt
    ? new Date(startsAt.getTime() - startsAt.getTimezoneOffset() * 60000).toISOString().split('T')[0]
    : 0
  const localEndsAt = endsAt
    ? new Date(endsAt.getTime() - endsAt.getTimezoneOffset() * 60000).toISOString().split('T')[0]
    : 0

  return (
    <FormLayout
      type={writer_assignment ? 'update' : 'create'}
      title={writer_assignment ? 'Mettre à jour la mission' : 'Ajouter une mission pour rédacteur'}
      onSubmit={handleSubmit}
      submitDisabled={
        writer_assignment
          ? !stateWriterAssignment
          : JSON.stringify(writer_assignment) === JSON.stringify(stateWriterAssignment)
      }
    >
      <Grid item container spacing={2}>
        <Grid item style={{ flexGrow: 1 }}>
          <Autocomplete
            fullWidth
            defaultValue={stateWriterAssignment ? stateWriterAssignment?.writer : null}
            options={writers}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              option && option?.first_name && option.last_name ? `${option?.first_name} ${option?.last_name}` : ''
            }
            onChange={(_, value) => {
              if (value) {
                setStateWriterAssignment((prevState) => {
                  return {
                    ...prevState,
                    writer: { id: value.id },
                  }
                })
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" label="Rédacteur" />
            }}
          />
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              setWriterModal(true)
            }}
          >
            <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              variant="outlined"
              id="start"
              label="Début"
              type="date"
              InputLabelProps={{ shrink: true }}
              defaultValue={localStartsAt}
              onChange={(event) => {
                if (event.target.value) {
                  setStateWriterAssignment((prevState) => {
                    return {
                      ...prevState,
                      start: event.target.value,
                    }
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              id="end"
              label="Fin"
              type="date"
              defaultValue={localEndsAt}
              onChange={(event) => {
                if (event.target.value) {
                  setStateWriterAssignment((prevState) => {
                    return {
                      ...prevState,
                      end: event.target.value,
                    }
                  })
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateWriterAssignment?.active}
                  onChange={(event) => {
                    setStateWriterAssignment((prevState) => {
                      return {
                        ...prevState,
                        active: event.target.checked ? true : false,
                      }
                    })
                  }}
                />
              }
              label={stateWriterAssignment?.active ? 'Mission active' : 'Mission désactivée'}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <CustomModal
        size="sm"
        title={'Ajouter un rédacteur'}
        open={writerModal}
        onClose={() => {
          setWriterModal(false)
        }}
      >
        <WriterForm
          refetch={refetchWriters}
          handleClose={() => {
            setWriterModal(false)
          }}
        />
      </CustomModal>
    </FormLayout>
  )
}

export default WriterAssignment
