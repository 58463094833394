import { useState } from 'react'
//COMPONENTS
import { ProductForm, DeleteForm } from '../components/Forms'
import { Layout, Title, SearchBar, CustomTable, Loading, Error, CustomModal } from '../components'
//GQL
import { COURSES_QUERY, COURSES_SEARCH_QUERY } from '../gql/queries'
import { useQuery, useLazyQuery } from '@apollo/client'
// MATERIAL UI
import { Grid, Button, IconButton, Menu, MenuItem } from '@material-ui/core'
//ICONS
import { MdMoreVert } from 'react-icons/md'
//TYPES
import { ColumnType } from '../utils/types'
import { CourseType, SortEnum } from '../../../lib/sharedTypes'
import { useNavigate } from 'react-router'
import { Outlet } from 'react-router-dom'
//ROUTER
import { useParams } from 'react-router'
//UTILS
import { handleError } from '../utils/handleError'
import { isAuthorized, isCourseManager } from '../utils/misc'
//GQL
import { DELETE_COURSE_MUTATION } from '../gql/mutations'
import { useMutation } from '@apollo/client'
//HOOKS
import { useAlert } from '../hooks'

const ApprenticeshipCourses = () => {
  const [setAlert] = useAlert()
  const { code_name, thematic_id } = useParams<{ code_name: string; thematic_id: string }>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const navigate = useNavigate()
  const [field, setField] = useState<keyof CourseType>('created_at')
  const [sort, setSort] = useState<keyof typeof SortEnum>(SortEnum.DESC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [searching, setSearching] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedRow, setSelectedRow] = useState<CourseType | undefined>(undefined)
  const menuOpen = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState(false)

  const [deleteCourse] = useMutation<{ deleteResource: boolean }>(DELETE_COURSE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Formation supprimée` })
      await refetch()
    },
  })

  const columns: ColumnType<CourseType>[] = [
    {
      title: '',
      field: 's3_card',
      render: (row: CourseType) => row.s3_card,
      type: 'Image',
      sortable: false,
    },
    {
      title: 'Code Name',
      field: 'code_name',
      render: (row: CourseType) => row.code_name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Name',
      field: 'name',
      render: (row: CourseType) => row.name,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Modules',
      field: 'sub_courses_count',
      render: (row: CourseType) => row.sub_courses_count,
      type: 'String',
      sortable: true,
    },
    {
      title: 'Crée le',
      field: 'created_at',
      render: (row: CourseType) => row.created_at,
      type: 'Date',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: CourseType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  const {
    loading,
    error,
    data: { courses = [], coursesCount = 0 } = {},
    refetch,
  } = useQuery<{
    courses: CourseType[]
    coursesCount: number
  }>(COURSES_QUERY, {
    variables: {
      where: {
        thematic: {
          id: {
            eq: thematic_id && parseInt(thematic_id?.toString()),
          },
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
  })
  const [searchFormationsQuery, { error: searchError, data: { coursesSearch = [] } = {} }] = useLazyQuery<{
    coursesSearch: CourseType[]
  }>(COURSES_SEARCH_QUERY, {
    variables: {
      where: {
        thematic: {
          id: {
            eq: thematic_id && parseInt(thematic_id?.toString()),
          },
        },
      },
    },
  })

  const searchFormations = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchFormationsQuery({
      variables: {
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
        take: take,
      },
    })
  }

  if (loading) {
    return (
      <Layout title="Formations">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Formations">
        <Error error={error} />
      </Layout>
    )
  }

  if (searchError) {
    return (
      <Layout title="Formations">
        <Error error={searchError} />
      </Layout>
    )
  }

  return (
    <Layout title="Formations">
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={`Formations - ${code_name as string}`} />
          </Grid>
          <Grid>
            <Button
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION'])}
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenModal(true)
              }}
            >
              Ajouter une formation
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <SearchBar
            placeholder={`Rechercher une formation de ${code_name as string}`}
            searchQuery={searchFormations}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CustomTable<CourseType>
            columns={columns}
            rows={searching ? coursesSearch : courses}
            field={field}
            skip={skip}
            sort={sort}
            take={take}
            setField={setField}
            setSort={setSort}
            setTake={setTake}
            setSkip={setSkip}
            rowsCount={searching ? coursesSearch.length : coursesCount}
            onRowClick={(row: CourseType, e) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(`/formations/${row.code_name}`, '_blank')
              } else {
                navigate(`/formations/${row.code_name}`)
              }
            }}
          />
        </Grid>
      </Grid>
      <Outlet />
      <CustomModal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        animation="slide-up"
      >
        <ProductForm
          type={'thematic'}
          thematic_id={thematic_id}
          product={undefined}
          refetch={refetch}
          handleClose={() => setOpenModal(false)}
        />
      </CustomModal>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          disabled={!(selectedRow || isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(selectedRow))}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la formation
        </MenuItem>
      </Menu>
      <CustomModal
        size="xs"
        title="Supprimer la ressource"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteCourse({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedRow(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
    </Layout>
  )
}

export default ApprenticeshipCourses
