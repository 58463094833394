import { useState } from 'react'
// MATERIAL UI
import { Button, Grid, TextField } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../../index'
import { TextEditorJS, Error } from '../../../index'
//TYPE
import { DeepPartial } from '../../../../utils/types'
import { CourseType, LessonTextType, LessonType } from '../../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { COURSE_ID_QUERY } from '../../../../gql/queries'
import {
  UPDATE_LESSON_TEXT_MUTATION,
  CREATE_LESSON_TEXT_MUTATION,
  PREVIEW_LESSON_TEXT_MUTATION,
} from '../../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../../hooks'
//ROUTER
import { useParams } from 'react-router'
//UTILS
import { handleError } from '../../../../utils/handleError'
// EDITORJS
import '../../../TextEditor.css'
// CONVERT QUILL DELTA
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import mammoth from 'mammoth'

type TextFormPropsType = {
  lesson?: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const TextForm = ({ lesson, refetch, handleClose }: TextFormPropsType) => {
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [html, setHtml] = useState('')
  const [stateLesson, setStateLesson] = useState<DeepPartial<LessonType> | undefined>(lesson)
  const [updateLessonTextMutation] = useMutation<{
    updateLessonText: LessonTextType
  }>(UPDATE_LESSON_TEXT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon texte mise-à-jour` })
      await refetch()
      handleClose()
    },
  })
  const [createLessonTextMutation] = useMutation<{
    createLessonText: LessonTextType
  }>(CREATE_LESSON_TEXT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Leçon texte créée` })
      await refetch()
      handleClose()
    },
  })

  const [previewLessonTextMutation] = useMutation<{
    previewLessonText: string
  }>(PREVIEW_LESSON_TEXT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      setAlert({ severity: 'success', content: `Prévisualisation leçon texte créée` })
      window.open('https://app.walter-learning.com/preview?s3=' + data.previewLessonText, '_blank')
    },
  })

  const { error: errorCourse, data: { course } = {} } = useQuery<{ course: CourseType }>(COURSE_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })

  if (errorCourse) {
    return <Error error={errorCourse} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonTextInput: Record<string, any> = {
      id: stateLesson?.text?.id,
      content: stateLesson?.text?.content,
    }
    if (stateLesson?.id) {
      await updateLessonTextMutation({ variables: { input: lessonTextInput } })
    } else {
      lessonTextInput.lesson = {
        course: { id: course?.id },
        validated: stateLesson?.validated || false,
        summary_required: stateLesson?.summary_required || false,
        name: stateLesson?.name,
        summary_state: stateLesson?.summary_state,
        summary: stateLesson?.summary,
      }
      await createLessonTextMutation({ variables: { input: lessonTextInput } })
    }
  }

  if (stateLesson?.text?.content && (stateLesson?.text?.content as Record<string, any>).ops) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const converter = new QuillDeltaToHtmlConverter((stateLesson?.text?.content as Record<string, any>).ops, {})
    const temp_html = converter.convert()
    setHtml(temp_html)
  }

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }

    const arrayBuffer = await e.target.files[0].arrayBuffer()
    const options = {
      styleMap: ["p[style-name='Chapitre'] => h1:fresh", "p[style-name='Thème'] => h2"],
      // includeDefaultStyleMap: true,
    }
    const result = await mammoth.convertToHtml(
      {
        arrayBuffer: arrayBuffer,
      },
      options,
    )

    if (result.value) {
      setHtml(result.value)
    }
  }

  return (
    <FormLayout
      type={lesson ? 'update' : 'create'}
      title={lesson ? 'Mettre à jour la leçon textuelle' : 'Ajouter une leçon textuelle'}
      onSubmit={handleSubmit}
      submitDisabled={
        stateLesson?.id
          ? JSON.stringify(stateLesson) === JSON.stringify(lesson)
          : stateLesson === undefined || !stateLesson?.text?.content
      }
    >
      {!stateLesson?.id && (
        <Grid item container spacing={2} alignItems="center">
          <Grid item container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                required
                label="Nom"
                name="name"
                defaultValue={stateLesson?.name}
                onChange={(event) => {
                  setStateLesson((prevState) => {
                    return {
                      ...prevState,
                      ...{ [event.target.name]: event.target.value },
                    }
                  })
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Button variant="outlined" color="primary" component="label">
          Importer depuis word
          <input hidden name="word" accept="*" type="file" onChange={onSelectFile} />
        </Button>
      </Grid>
      <Grid item style={{ width: '100%', height: '100%' }} key={html}>
        <TextEditorJS
          content={stateLesson?.text?.content}
          onChange={(content: any) => {
            setStateLesson((prevState) => {
              return {
                ...prevState,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...{ text: { ...prevState?.text, content: content } },
              }
            })
          }}
          legacy={html}
        />
      </Grid>
      <Grid item container justifyContent="center" alignItems="center">
        <Button
          variant="outlined"
          color="primary"
          disabled={!stateLesson?.text?.content}
          onClick={async () => {
            await previewLessonTextMutation({
              variables: {
                input: {
                  id: stateLesson?.text?.id ?? null,
                  content: stateLesson?.text?.content,
                },
              },
            })
          }}
        >
          Prévisualisation
        </Button>
      </Grid>
    </FormLayout>
  )
}

export default TextForm
