//REACT
import { useRef, useState, useEffect } from 'react'
// COMPONENTS
import { DeleteForm } from '../Forms'
import { Tree, AddPartModal, EditPartModal } from './index'
import { CustomModal } from '../index'
//ICONS
import { MdDragIndicator, MdAddCircleOutline, MdOutlineModeEdit } from 'react-icons/md'
import { HiOutlineTrash } from 'react-icons/hi'
//MATERIAL UI
import {
  Link,
  Grid,
  Paper,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from '@material-ui/core'
//REACT DND
import { useDrag, useDrop, XYCoord } from 'react-dnd'
import { Identifier } from 'dnd-core'
//TYPES
import { CoursePartType } from '../../../../lib/sharedTypes'
//UTILS
import { int2roman, secondsToDHMS, getLessonCategory, sluggify } from '../../utils/misc'

type TreeItemPropsType = {
  code_name: string
  index: number
  parent: null | CoursePartType
  part: CoursePartType
  parts: CoursePartType[]
  movePart: (part: CoursePartType, target: CoursePartType) => void
  switchPart: (part: CoursePartType, target: CoursePartType, dragIndex: number, hoverIndex: number) => void
  addPart: (parent: CoursePartType | null, partName: string) => void
  removePart: (part: CoursePartType) => void
  expandAll: boolean
  refetch: () => Promise<any>
  canDrag: boolean
}

const TreeItem: React.FC<TreeItemPropsType> = ({
  index,
  code_name,
  parts,
  parent,
  part,
  movePart,
  switchPart,
  addPart,
  removePart,
  expandAll,
  refetch,
  canDrag,
}: TreeItemPropsType) => {
  const theme = useTheme()
  const [isPartModalOpen, setIsPartModalOpen] = useState<boolean>(false)
  const [isEditPartModalOpen, setIsEditPartModalOpen] = useState<boolean>(false)
  const [isRemovePartModalOpen, setIsRemovePartModalOpen] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(expandAll)
  const [duration, setDuration] = useState<number>(0)
  const ref = useRef<HTMLDivElement>(null)
  const type: 'part' | 'chapter' | 'lesson' =
    parent === null ? 'part' : parent !== null && part.sub_parts ? 'chapter' : 'lesson'

  useEffect(() => {
    switch (type) {
      case 'part': {
        const dur = part.sub_parts.reduce((acc, el) => {
          el.sub_parts.map((sp) => {
            if (sp.lesson && sp.lesson.video) acc += sp.lesson.video.file_duration
          })
          return acc
        }, 0)
        setDuration(dur)
        break
      }
      case 'chapter': {
        const dur = part.sub_parts.reduce((acc, el) => {
          if (el.lesson && el.lesson.video) acc += el.lesson.video.file_duration
          return acc
        }, 0)
        setDuration(dur)
        break
      }
      case 'lesson':
        if (part.lesson && part.lesson.video) {
          setDuration(part.lesson.video.file_duration)
        }
        break
      default:
        console.error('Unknown part type')
        break
    }
  }, [parts, part])

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  const [{ handlerId, isOver, canDrop }, drop] = useDrop<
    { item: CoursePartType; index: number; type: string; parent: CoursePartType | null },
    { item: CoursePartType; index: number; type: string; parent: CoursePartType | null },
    { handlerId: Identifier | null; isOver: boolean; canDrop: boolean }
  >(
    {
      accept: type === 'part' ? ['part', 'chapter'] : type === 'chapter' ? ['chapter', 'lesson'] : ['lesson'],
      drop: () => {
        return { item: part, index: index, type: type, parent: parent }
      },
      collect: (monitor) => {
        const itemType = monitor.getItemType()
        let canDrop = false
        if (itemType) {
          if (type === 'part' && itemType === 'chapter') canDrop = true
          if (type === 'chapter' && itemType === 'lesson') canDrop = true
        }
        return {
          handlerId: monitor.getHandlerId(),
          isOver: monitor.isOver(),
          canDrop: canDrop,
        }
      },
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        if (item.item.id === part.id) {
          return
        }

        const dragIndex = item.index
        const hoverIndex = index

        if (dragIndex === hoverIndex) {
          return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        if ((type === 'part' && item.type === 'part') || (item.parent && parent && item.parent.id === parent.id)) {
          switchPart(item.item, part, dragIndex, hoverIndex)
          item.index = hoverIndex
        }
      },
    },
    [parts, switchPart],
  )

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type,
      item: { item: part, index: index, type: type, parent: parent },
      canDrag: canDrag,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{
          item: CoursePartType
          index: number
          type: string
          parent: CoursePartType | null
        }>()
        if (item && dropResult) {
          if (item.parent !== dropResult.parent) {
            movePart(item.item, dropResult.item)
          }
        }
      },
    }),
    [parts, movePart],
  )

  preview(drop(ref))

  if (type === 'lesson') {
    return (
      <Grid item xs={12}>
        <Paper
          ref={ref}
          id={`part_${part.id.toString()}`}
          data-handler-id={handlerId}
          style={{ borderRadius: '10px', opacity: isDragging ? 0 : 1 }}
        >
          <Grid container direction="row">
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              style={{
                width: '2%',
                borderRadius: '10px 0px 0px 10px',
                cursor: 'grab',
                background: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
              }}
              ref={drag}
            >
              <Grid item>
                <MdDragIndicator />
              </Grid>
            </Grid>
            <Grid item style={{ width: '98%', padding: theme.spacing(1) }}>
              <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body2">
                    <Link
                      href={`/formations/${part?.lesson?.course?.code_name ?? code_name}/lessons/${
                        getLessonCategory(part.lesson) === 'Tâche'
                          ? 'task'
                          : sluggify(getLessonCategory(part.lesson).toLowerCase())
                      }/${part?.lesson?.id}`}
                      target="_blank"
                    >
                      {part.position + 1} - {part.lesson ? getLessonCategory(part.lesson) : 'Unknown'} -{' '}
                      {part?.lesson?.name}
                      <Typography component={'span'} style={{ color: theme.palette.text.disabled }}>
                        {part?.lesson?.course?.code_name !== code_name && ` - ${part?.lesson?.course?.code_name}`}
                      </Typography>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end" alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant="caption"> {duration !== 0 && secondsToDHMS(duration)}</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      style={{ padding: theme.spacing(1) }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsRemovePartModalOpen(true)
                      }}
                    >
                      <HiOutlineTrash size="22" style={{ color: theme.palette.error.main, cursor: 'pointer' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <CustomModal
          size="xs"
          title={`Supprimer - ${part.name}`}
          open={isRemovePartModalOpen}
          onClose={() => {
            setIsRemovePartModalOpen(false)
          }}
        >
          <DeleteForm
            onDelete={() => {
              removePart(part)
              setIsRemovePartModalOpen(false)
            }}
            onCancel={() => {
              setIsRemovePartModalOpen(false)
            }}
          />
        </CustomModal>
      </Grid>
    )
  }

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      data-handler-id={handlerId}
      id={`part_${part.id.toString()}`}
      style={{
        opacity: isDragging ? 0 : 1,
        border: canDrop && isOver ? '1px solid green' : 'unset',
        borderRadius: '10px 10px 10px 10px',
      }}
      ref={ref}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        style={{
          width: '2%',
          borderRadius: '10px 0px 0px 10px',
          cursor: 'grab',
          background: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
        }}
        ref={drag}
      >
        <Grid item>
          <MdDragIndicator />
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          width: '98%',
          borderRadius: '0px 10px 10px 0px',
          borderRight: `1px solid ${theme.palette.background.paper}`,
          borderBottom: `1px solid ${theme.palette.background.paper}`,
        }}
      >
        <Accordion
          expanded={expanded}
          onChange={() => {
            setExpanded(!expanded)
          }}
          style={{ borderRadius: '0px 10px 10px 0px' }}
        >
          <AccordionSummary aria-controls="part-content" id="part-header">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item container alignItems="center" xs={9}>
                <Typography variant={type === 'part' ? 'body1' : 'body2'}>
                  {type === 'part'
                    ? `${int2roman(part.position + 1)} - ${part.name}`
                    : `${String.fromCharCode(96 + part.position + 1).toUpperCase()} - ${part.name}`}
                </Typography>
                <IconButton
                  disabled={typeof part.id === 'number'}
                  style={{ padding: theme.spacing(1) }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsEditPartModalOpen(true)
                  }}
                >
                  <MdOutlineModeEdit size="22" style={{ cursor: 'pointer' }} />
                </IconButton>
              </Grid>
              <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <Tooltip title="Durée réelle">
                    <Typography variant="caption"> {secondsToDHMS(duration)}</Typography>
                  </Tooltip>
                </Grid>
                {part.duration && <div>&nbsp; / &nbsp;</div>}
                <Grid item>
                  <Tooltip title="Durée déclarée auprès du financeur">
                    <Typography variant="caption">{part.duration && `${secondsToDHMS(part.duration)}`}</Typography>
                  </Tooltip>
                </Grid>

                {type === 'part' && (
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      style={{ padding: theme.spacing(1) }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsPartModalOpen(true)
                      }}
                    >
                      <MdAddCircleOutline size="22" style={{ color: theme.palette.primary.main, cursor: 'pointer' }} />
                    </IconButton>
                  </Grid>
                )}
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    style={{ padding: theme.spacing(1) }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsRemovePartModalOpen(true)
                    }}
                  >
                    <HiOutlineTrash size="22" style={{ color: theme.palette.error.main, cursor: 'pointer' }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            style={{
              background: theme.palette.background.default,
            }}
          >
            <Grid item container direction="column">
              {part.sub_parts && expanded && (
                <Tree
                  canDrag={canDrag}
                  code_name={code_name}
                  parent={part}
                  parts={part.sub_parts}
                  movePart={movePart}
                  switchPart={switchPart}
                  removePart={removePart}
                  addPart={addPart}
                  expandAll={expandAll}
                  refetch={refetch}
                />
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <>
        <CustomModal
          size="xs"
          title={
            type === 'part'
              ? `Modifier la partie ${int2roman(part.position + 1)}`
              : `Modifier le chapitre ${String.fromCharCode(96 + part.position + 1).toUpperCase()}`
          }
          open={isEditPartModalOpen}
          onClose={() => {
            setIsEditPartModalOpen(false)
          }}
        >
          <EditPartModal type={type} part={part} setIsEditPartModalOpen={setIsEditPartModalOpen} refetch={refetch} />
        </CustomModal>
        <CustomModal
          size="xs"
          title={
            type === 'part'
              ? `Ajouter un chapitre à la partie ${int2roman(part.position + 1)}`
              : `Ajouter une leçon au chapitre ${String.fromCharCode(96 + part.position + 1).toUpperCase()}`
          }
          open={isPartModalOpen}
          onClose={() => {
            setIsPartModalOpen(false)
          }}
        >
          <AddPartModal
            type={type === 'part' ? 'chapter' : 'lesson'}
            parent={part}
            addPart={addPart}
            setIsPartModalOpen={setIsPartModalOpen}
          />
        </CustomModal>
        <CustomModal
          size="xs"
          title={`Supprimer - ${part.name}`}
          open={isRemovePartModalOpen}
          onClose={() => {
            setIsRemovePartModalOpen(false)
          }}
        >
          <DeleteForm
            onDelete={() => {
              removePart(part)
              setIsRemovePartModalOpen(false)
            }}
            onCancel={() => {
              setIsRemovePartModalOpen(false)
            }}
          />
        </CustomModal>
      </>
    </Grid>
  )
}

export default TreeItem
