import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, Typography, IconButton, useTheme, Divider, MenuItem, InputAdornment } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
// COMPONENTS
import { FormLayout } from '../index'
import { Error } from '../../index'
//ICONS
import { MdOutlineDelete, MdOutlineAddCircleOutline } from 'react-icons/md'
// TYPE
import { CourseType, RequirementType } from '../../../../../lib/sharedTypes'
//GQL
import { COURSES_QUERY } from '../../../gql/queries'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_COURSE_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'
//UTILS
import { handleError } from '../../../utils/handleError'

type ModulesFormPropsType = {
  course: CourseType
  refetch: () => Promise<any>
  handleClose: () => void
}

const ModulesForm = ({ course, refetch, handleClose }: ModulesFormPropsType) => {
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [setAlert] = useAlert()
  const [stateCourse, setStateCourse] = useState<CourseType>(course)
  const { error: errorCourses, data: { courses } = {} } = useQuery<{ courses: CourseType[] }>(COURSES_QUERY, {
    variables: {
      order: { field: 'code_name', sort: 'ASC' },
    },
  })

  const [updateCourseMutation] = useMutation<{ updateCourse: CourseType }>(UPDATE_COURSE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCourse.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (errorCourses) {
    return <Error error={errorCourses} />
  }

  if (!stateCourse) {
    return <Error error={'Formation inconnue'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const courseInput = {
      id: stateCourse.id,
      sub_courses: stateCourse.sub_courses?.map((course) => {
        return { id: course.id }
      }),
      requirements: stateCourse?.requirements?.map((requirement) => {
        if (typeof requirement.id === 'number') {
          return {
            requires: { id: requirement.course.id },
            completion_rate: requirement.completion_rate,
          }
        } else {
          return {
            ...requirement,
            requires: { id: requirement.requires.id },
          }
        }
      }),
    }
    await updateCourseMutation({ variables: { input: courseInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour la formation"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateCourse) === JSON.stringify(course)}
    >
      <Grid item>
        <Autocomplete
          multiple
          defaultValue={stateCourse.sub_courses}
          options={courses || []}
          getOptionSelected={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option?.code_name}
          onChange={(_, value) => {
            if (value) {
              setStateCourse({
                ...stateCourse,
                sub_courses: value,
              })
            }
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" label="Module(s)" />
          }}
        />
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h6">Condition d’accès</Typography>
        </Grid>
        <IconButton
          onClick={() => {
            const requirements = stateCourse.requirements
            requirements.unshift({
              id: new Date().getTime(), //ensures unicity
              course: { id: course.id }, //required by current course
              requires: { id: 0 }, //starts empty
              completion_rate: 1, //default 100% completion_rate
            } as RequirementType)
            setStateCourse((prevState) => {
              return {
                ...prevState,
                requirements: requirements,
              }
            })
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Grid>
      <Grid item container direction="column" spacing={2} ref={animateRef}>
        {stateCourse.requirements.map((requirement, index) => {
          return (
            <Grid key={index} item container direction="column">
              <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>{`Exigence #${index + 1}`}</Typography>
                </Grid>

                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setStateCourse((prevState) => {
                        return {
                          ...prevState,
                          requirements: prevState.requirements.filter((r) => {
                            return r.id !== requirement.id
                          }),
                        }
                      })
                    }}
                  >
                    <MdOutlineDelete size={24} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid style={{ marginBottom: theme.spacing(2) }}>
                <Divider />
              </Grid>
              <Grid item container direction="row" spacing={1}>
                <Grid item xs={8}>
                  {courses && (
                    <TextField
                      select
                      fullWidth
                      id="requirement"
                      label="Formation"
                      variant="outlined"
                      defaultValue={requirement.requires.code_name || ''}
                      value={requirement.requires.code_name || ''}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                      onChange={(event) => {
                        const requirements = stateCourse.requirements.map((requirement, i) => {
                          if (i === index) {
                            return {
                              ...requirement,
                              requires: courses.find((c) => c.code_name === event.target.value) as CourseType,
                            }
                          } else {
                            return requirement
                          }
                        })
                        setStateCourse((prevState) => {
                          return {
                            ...prevState,
                            requirements: requirements,
                          }
                        })
                      }}
                      required
                    >
                      {courses.map((course) => (
                        <MenuItem id="requirement" key={course.id} value={course.code_name}>
                          {course.code_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Taux d'achèvement"
                    name="completion_rate"
                    type="number"
                    required
                    InputProps={{
                      inputMode: 'numeric',
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    inputProps={{ min: 0, max: 100, step: 1 }}
                    value={requirement.completion_rate * 100 || ''}
                    onChange={(event) => {
                      const requirements = stateCourse.requirements.map((requirement, i) => {
                        if (i === index) {
                          return {
                            ...requirement,
                            completion_rate:
                              parseInt(event.target.value) >= 100 ? 1 : parseInt(event.target.value) / 100,
                          }
                        } else {
                          return requirement
                        }
                      })

                      setStateCourse((prevState) => {
                        return {
                          ...prevState,
                          requirements: requirements,
                        }
                      })
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </FormLayout>
  )
}

export default ModulesForm
