import { Grid, Chip, useTheme } from '@material-ui/core'
import { ProductType } from '../../../../lib/sharedTypes'
import { PRODUCT_MANAGERS_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'

type ManagersPropsType = {
  productId: number
}

const Managers = ({ productId }: ManagersPropsType) => {
  const theme = useTheme()
  const { data: { product } = {} } = useQuery<{ product: ProductType }>(PRODUCT_MANAGERS_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(`${productId}`),
        },
      },
    },
  })

  if (!product) return <></>
  return (
    <Grid item>
      {product?.courses?.length > 0 &&
        product?.courses[0].managers.map((manager, index) => (
          <Chip
            key={index}
            size="small"
            label={manager.first_name + ' ' + manager.last_name}
            color="primary"
            style={{ margin: theme.spacing(1) }}
          />
        ))}
    </Grid>
  )
}

export default Managers
