import { CourseType, VideoSetType, LessonType, LessonQuestionType } from '../../../lib/sharedTypes'
import { store } from '../store/store'
import { v4 as uuidv4 } from 'uuid'

export const titelizeWord = (word: string) => {
  return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`
}

export const isEditorAndToCheck = (key: string): boolean => {
  const state = store.getState()
  const user = state.user.user
  if (!user || key === 'TO_CHECK') return false
  return user.roles.map((role) => role.category as string).includes('EDITOR')
}

export const isSetEditor = (videoSet: VideoSetType) => {
  const state = store.getState()
  const user = state.user.user
  if (!user || !videoSet || !videoSet.editor) return false
  return videoSet.editor.id.toString() === user.id.toString()
}

export const isCourseManager = (course: CourseType | undefined) => {
  if (!course) return false
  const state = store.getState()
  const user = state.user.user
  if (!user || !course || !course.managers || course.managers.length === 0) return false
  return course.managers.map((manager) => manager.id.toString()).includes(user.id.toString())
}

export const isAuthorized = (authorizedRoles: string[]) => {
  const state = store.getState()
  const user = state.user.user
  if (!user || !user?.roles || user?.roles.length === 0) return false
  const userRoles = user?.roles.map((role) => role.category as string)
  const intersection = authorizedRoles.filter((role) => userRoles?.includes(role))
  return intersection.length !== 0
}

export function hexToRGBA(hex: string | null, alpha?: number) {
  if (!hex) return { r: 0, g: 0, b: 0 }
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  if (alpha) {
    return { r, g, b, alpha }
  } else {
    return { r, g, b }
  }
}

export const sleep = (s: number) => new Promise((r) => setTimeout(r, s * 1000))

export const secondsToDHMS = (seconds: number, displaySeconds: boolean = true) => {
  /* Take a number of seconds and return a string with format day, hour, minutes, seconds */
  seconds = Math.round(seconds)
  if (isNaN(seconds)) return ' - '
  if (seconds === 0) return '0s'
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)
  const hDisplay = h > 0 ? h.toString() + 'h' : ''
  const mDisplay = m > 0 ? m.toString() + 'm' : ''
  const sDisplay = s > 0 && displaySeconds ? s.toString() + 's' : ''
  const resp = hDisplay + mDisplay + sDisplay
  if (resp === '') return ' - '
  return resp
}

export const countDays = (date_1: Date, date_2: Date): number => {
  /* Return number of days between two dates */
  const difference = new Date(date_2).getTime() - new Date(date_1).getTime()
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
  return TotalDays
}

export const textColor = (bgColor: string) => {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
}

export const stringToColor = (string: string) => {
  return (
    '#' +
    Math.floor(
      Math.abs(
        Math.sin(
          string
            .split('')
            .map((char) => char.charCodeAt(0))
            .reduce((a, b) => a + b, 0),
        ) * 16777215,
      ),
    )
      .toString(16)
      .padStart(6, '0')
  )
}

export const dateToTimeAgo = (date: Date): string => {
  /* Return in string the time ago between now and the date given. */
  // Get the interval in seconds

  date = new Date(date)
  const dateNow = new Date()
  const interval = Math.round(dateNow.getTime() - date.getTime()) / 1000

  const month = Math.floor(interval / 2592000) // 1 month (30days) = 2592000s
  if (month >= 1)
    return date.toLocaleDateString('fr', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

  const day = Math.floor(interval / 86400) // 1 day = 86400s
  if (day >= 1) return `Il y a ${day} jour${day > 1 ? 's' : ''}`

  const hour = Math.floor(interval / 3600) // 1 hour = 3600s
  if (hour >= 1) return `Il y a ${hour} heure${hour > 1 ? 's' : ''}`

  const min = Math.floor(interval / 60) // 1 min = 60s
  if (min >= 1) return `Il y a ${min} minute${min > 1 ? 's' : ''}`

  const second = Math.floor(interval)
  if (second >= 30) return `Il y a ${second} secondes`
  if (second < 30) return "À l'instant"
  return ''
}

export const int2roman = (original: number): string => {
  if (original < 1 || original > 3999) {
    throw new Error('Error: Input integer limited to 1 through 3,999')
  }

  const numerals = [
    ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
    ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
    ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
    ['M', 'MM', 'MMM'], // 1000-3000
  ]

  // TODO: Could expand to support fractions, simply rounding for now
  const digits = Math.round(original).toString().split('')
  let position = digits.length - 1

  return digits.reduce((roman, digit) => {
    if (digit !== '0') {
      roman += numerals[position][parseInt(digit) - 1]
    }

    position -= 1

    return roman
  }, '')
}

export const sluggify = (word: string) => {
  if (!word) return ''
  return word
    .toString()
    .toLowerCase()
    .trim() // delete trailing & leading spaces
    .normalize('NFD') //replace diacritic
    .replace(/[^a-zA-Z0-9_ ]/g, '') //replace all special characters except spaces & underscores
    .replace(/ /g, '_') //replace all spaces by _
}

export const getLessonCategory = (lesson: LessonType) => {
  if (lesson?.document) {
    return 'Document'
  }
  if (lesson?.video) {
    return 'Video'
  }
  if (lesson?.text && lesson.text.type === 'LESSON') {
    return 'Text'
  }
  if (lesson?.text && lesson.text.type === 'SYNTHESIS') {
    return 'Synthèse'
  }
  if (lesson?.epp) {
    return 'Epp'
  }
  if (lesson?.quiz) {
    return 'Quiz'
  }
  if (lesson?.task) {
    return 'Tâche'
  }
  if (lesson?.dactylo) {
    return 'Dactylo'
  }
  return 'Unknown Lesson category'
}

export const getQuestionCategory = (question: LessonQuestionType) => {
  if (question?.mc_question) {
    return 'mcQuestion'
  }
  if (question?.gapfill_question) {
    return 'gapFillQuestion'
  }
  if (question?.ranking_question) {
    return 'rankingQuestion'
  }
  return null
}

// export const filterObject = <T>(obj: Record<string, any>, key: string): T => {
//   const deepClone = JSON.parse(JSON.stringify(obj))
//   delete deepClone[key]
//   for (const value of Object.values(deepClone)) {
//     if (value instanceof Object) {
//       filterObject(value as Record<string, any>, key)
//     }
//   }
//   return deepClone as T
// }

export const replaceImages = async <T>(obj: Record<string, any>, key: string): Promise<T> => {
  if (obj[key]) {
    obj[key] = await fetch(obj[key] as string)
      .then(async (r) => {
        return await r.blob()
      })
      .then((blobFile) => {
        return new File([blobFile], uuidv4(), { type: blobFile.type })
      })
      .catch((err: any) => {
        console.log('ERROR', err)
      })
  }
  for (const value of Object.values(obj)) {
    if (value instanceof Object) {
      await replaceImages(value as Record<string, any>, key)
    }
  }
  return obj as T
}
