//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid } from '@material-ui/core'
//COMPONENTS
import { DurationInput, MultiSelectChips, Error } from '../../index'
import { FormLayout } from '../index'
//TYPES
import { ShootingType, TeacherType, UserType, ProjectType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { TEACHERS_QUERY, CALENDAR_USERS_QUERY, PROJECT_ID_QUERY } from '../../../gql/queries'
import { CREATE_SHOOTING_MUTATION, UPDATE_SHOOTING_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
//ROUTER
import { useParams } from 'react-router'

type ShootingFormPropsType = {
  shooting?: ShootingType
  refetch: () => Promise<any>
  handleClose: () => void
}

const SetForm = ({ shooting, refetch, handleClose }: ShootingFormPropsType) => {
  const { code_name } = useParams()
  const [stateShooting, setStateShooting] = useState<DeepPartial<ShootingType> | undefined>(shooting)
  const [setAlert] = useAlert()
  const { error: errorTeachers, data: { teachers } = {} } = useQuery<{ teachers: TeacherType[] }>(TEACHERS_QUERY)
  const { error: errorProject, data: { project } = {} } = useQuery<{ project: ProjectType }>(PROJECT_ID_QUERY, {
    variables: { where: { code_name: { eq: code_name } } },
  })
  const { error: calendarUsersError, data: { users } = {} } = useQuery<{ users: UserType[] }>(CALENDAR_USERS_QUERY, {
    variables: {
      where: {
        job: {
          category: {
            in: ['DIRECTOR', 'EDITOR', 'PROJECT'],
          },
        },
      },
    },
  })
  const [createShooting] = useMutation<{
    createShooting: ShootingType
  }>(CREATE_SHOOTING_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Tournage créé` })
      await refetch()
      handleClose()
    },
  })

  const [updateShooting] = useMutation<{
    updateShooting: ShootingType
  }>(UPDATE_SHOOTING_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Tournage mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const shootingInput = {
      id: stateShooting?.id,
      starts_at: stateShooting?.starts_at,
      ends_at: stateShooting?.ends_at,
      address: stateShooting?.address,
      duration_target: stateShooting?.duration_target,
      teachers: stateShooting?.teachers?.map((teacher) => {
        return { id: teacher?.id }
      }),
      crew: stateShooting?.crew?.map((user) => {
        return { id: user?.id }
      }),
      project: { id: project?.id },
    }
    if (stateShooting?.id) {
      await updateShooting({ variables: { input: shootingInput } })
    } else {
      await createShooting({ variables: { input: shootingInput } })
    }
  }

  const startsAt = typeof stateShooting?.starts_at === 'string' ? new Date(stateShooting.starts_at) : null
  const endsAt = typeof stateShooting?.ends_at === 'string' ? new Date(stateShooting?.ends_at) : null
  const localStartsAt = startsAt
    ? new Date(startsAt.getTime() - startsAt.getTimezoneOffset() * 60000).toISOString().split('.')[0]
    : 0
  const localEndsAt = endsAt
    ? new Date(endsAt.getTime() - endsAt.getTimezoneOffset() * 60000).toISOString().split('.')[0]
    : 0

  if (errorTeachers) {
    return <Error error={errorTeachers} />
  }

  if (calendarUsersError) {
    return <Error error={calendarUsersError} />
  }

  if (errorProject) {
    return <Error error={errorProject} />
  }

  return (
    <FormLayout
      type={stateShooting?.id ? 'update' : 'create'}
      title={stateShooting?.id ? 'Mettre à jour un tournage' : 'Créer un tournage'}
      onSubmit={handleSubmit}
      submitDisabled={stateShooting?.id ? shooting === stateShooting : stateShooting === undefined}
    >
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            variant="outlined"
            id="starts_at"
            label="Début"
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            defaultValue={localStartsAt}
            onChange={(event) => {
              setStateShooting((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: new Date(event.target.value),
                }
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="ends_at"
            label="Fin"
            type="datetime-local"
            defaultValue={localEndsAt}
            onChange={(event) => {
              setStateShooting((prevState) => {
                return {
                  ...prevState,
                  [event.target.id]: new Date(event.target.value),
                }
              })
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id="address"
          label="Adresse"
          variant="outlined"
          defaultValue={stateShooting?.address}
          onChange={(event) => {
            setStateShooting((prevState) => {
              return {
                ...prevState,
                [event.target.id]: event.target.value,
              }
            })
          }}
          required
        />
      </Grid>
      <Grid item>
        <DurationInput
          required
          label="Durée cible"
          defaultValue={stateShooting?.duration_target}
          onChange={(duration) => {
            setStateShooting((prevState) => {
              return {
                ...prevState,
                duration_target: duration,
              }
            })
          }}
        />
      </Grid>
      <Grid item>
        <MultiSelectChips
          multiSelectLabel="Formateurs"
          keyToUpdate="teachers"
          labels={teachers ? teachers.map((teacher) => `${teacher.first_name} ${teacher.last_name}`) : []}
          selectedLabelsList={
            stateShooting && stateShooting?.teachers
              ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                stateShooting?.teachers?.map((teacher) => `${teacher?.first_name} ${teacher?.last_name}`)
              : []
          }
          handleChange={(_, values) => {
            const value = teachers?.filter((teacher) => values.includes(`${teacher?.first_name} ${teacher?.last_name}`))
            setStateShooting((prevState) => {
              return {
                ...prevState,
                teachers: value,
              }
            })
          }}
        />
      </Grid>
      <Grid item>
        <MultiSelectChips
          multiSelectLabel="Equipe"
          keyToUpdate="crew"
          labels={users ? users.map((user) => user.username) : []}
          selectedLabelsList={
            stateShooting && stateShooting?.crew ? stateShooting?.crew?.map((user) => user?.username) : []
          }
          handleChange={(_, values) => {
            const value = users?.filter((user) => values.includes(user.username))
            setStateShooting((prevState) => {
              return {
                ...prevState,
                crew: value,
              }
            })
          }}
        />
      </Grid>
    </FormLayout>
  )
}

export default SetForm
