import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, MenuItem, FormGroup, FormControlLabel, Switch } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
// TYPE
import { CatalogStatusEnum, CatalogType } from '../../../../../lib/sharedTypes'
//GQL
import { useQuery, useMutation } from '@apollo/client'
import { CATALOGS_QUERY } from '../../../gql/queries'
import { UPDATE_CATALOG_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type GeneralInformationsFormPropsType = {
  catalog: CatalogType
  refetch: () => Promise<any>
  handleClose: () => void
}

const GeneralInformationsForm = ({ catalog, refetch, handleClose }: GeneralInformationsFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateCatalog, setStateCatalog] = useState<CatalogType>(catalog)
  const { data: { catalogs: catalogs = [] } = {} } = useQuery<{ catalogs: CatalogType[] }>(CATALOGS_QUERY)

  const [updateCatalog] = useMutation<{ updateCatalog: CatalogType }>(UPDATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCatalog.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCatalog((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const catalogInput = {
      id: stateCatalog.id,
      name: stateCatalog.name,
      status: stateCatalog.status,
      send_to_walter_sf: stateCatalog.send_to_walter_sf ? true : false,
      title: stateCatalog.title,
      content: stateCatalog.content,
      breadcrumb: stateCatalog.breadcrumb,
      reviews_subtitle: stateCatalog.reviews_subtitle,
      courses_list_title: stateCatalog.courses_list_title,
      courses_list_subtitle: stateCatalog.courses_list_subtitle,
      online_course_title: stateCatalog.online_course_title,
      catalog_pdf_cta: stateCatalog.catalog_pdf_cta,
      parent: stateCatalog?.parent ? { id: stateCatalog?.parent?.id } : null,
      position: stateCatalog?.position ? parseInt(stateCatalog?.position.toString()) : 0,
    }
    await updateCatalog({ variables: { input: catalogInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour la description"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateCatalog) === JSON.stringify(catalog)}
    >
      <Grid item sm={6}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={stateCatalog.status === CatalogStatusEnum.AVAILABLE}
                onChange={(event) => {
                  setStateCatalog((prevState) => {
                    return {
                      ...prevState,
                      status: event.target.checked ? CatalogStatusEnum.AVAILABLE : CatalogStatusEnum.NOT_AVAILABLE,
                    }
                  })
                }}
              />
            }
            label={
              stateCatalog.status === CatalogStatusEnum.AVAILABLE
                ? 'Catalogue visible sur le showcase'
                : 'Catalogue caché sur le showcase'
            }
          />
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={stateCatalog.send_to_walter_sf === true}
                onChange={(event) => {
                  setStateCatalog((prevState) => {
                    return {
                      ...prevState,
                      send_to_walter_sf: event.target.checked ? true : false,
                    }
                  })
                }}
              />
            }
            label={stateCatalog.send_to_walter_sf === true ? 'Envoi vers Walter SF' : 'Ne pas envoyer vers Walter SF'}
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="name"
          label="Nom"
          variant="outlined"
          value={stateCatalog?.name || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="title"
          label="Titre"
          variant="outlined"
          value={stateCatalog?.title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          multiline
          minRows={5}
          maxRows={5}
          name="content"
          label="Contenu"
          variant="outlined"
          value={stateCatalog?.content || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="breadcrumb"
          label="Breadcrumb"
          variant="outlined"
          value={stateCatalog?.breadcrumb || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          type="number"
          inputProps={{ min: 0 }}
          name="position"
          label="Position"
          variant="outlined"
          value={stateCatalog?.position || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="reviews_subtitle"
          label="Titre avis clients"
          variant="outlined"
          value={stateCatalog?.reviews_subtitle || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="courses_list_title"
          label="Titre liste des formations"
          variant="outlined"
          value={stateCatalog?.courses_list_title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="courses_list_subtitle"
          label="Sous-titre liste des formations"
          variant="outlined"
          value={stateCatalog?.courses_list_subtitle || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="online_course_title"
          label="Titre formation en ligne"
          variant="outlined"
          value={stateCatalog?.online_course_title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="catalog_pdf_cta"
          label="CTA catalogue de formation"
          variant="outlined"
          value={stateCatalog?.catalog_pdf_cta || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          select
          fullWidth
          id="parent"
          label="Catalogue parent"
          variant="outlined"
          value={stateCatalog?.parent?.id || ''}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
            },
          }}
          onChange={(event) => {
            const parentCatalog = catalogs.find((catalog) => catalog.id.toString() === event.target.value.toString())
            if (parentCatalog) {
              setStateCatalog({ ...stateCatalog, parent: parentCatalog })
            }
          }}
        >
          {catalogs.map((catalog) => (
            <MenuItem id="job" key={catalog.id} value={catalog.id}>
              {catalog.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </FormLayout>
  )
}

export default GeneralInformationsForm
