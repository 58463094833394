import { useState } from 'react'
//ICON
import { MdMoreVert } from 'react-icons/md'
// COMPONENTS
import { DeleteForm } from '../../components/Forms'
import { FaqGeneralInfoForm, FaqForm } from '../../components/Forms/catalog'
import { Loading, Error, TextEditor, CustomModal } from '../../components'
// MATERIAL UI
import {
  Grid,
  Button,
  Divider,
  Typography,
  makeStyles,
  useTheme,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'
// TYPE
import { CatalogFaqType, CatalogType } from '../../../../lib/sharedTypes'
// GQL
import { CATALOG_FAQ_QUERY } from '../../gql/queries'
import { UPDATE_CATALOG_MUTATION } from '../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const FAQ = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [setAlert] = useAlert()
  const { catalog_id } = useParams()
  const [updateModal, setUpdateModal] = useState('')
  const [selectedFaq, setSelectedFaq] = useState<CatalogFaqType | undefined>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState(false)
  const [updateCatalog] = useMutation<{ updateCatalog: CatalogType }>(UPDATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCatalog.name} mis-à-jour` })
      await refetch()
    },
  })

  if (!catalog_id) {
    return <Error error={'Unknown catalog_id'} />
  }
  const {
    loading,
    error,
    data: { catalog } = {},
    refetch,
  } = useQuery<{
    catalog: CatalogType
  }>(CATALOG_FAQ_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(catalog_id.toString()),
        },
      },
    },
  })

  if (loading || !catalog) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column">
      {/* DESCRIPTION */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Description</Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('description')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container>
            <Grid item>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Titre
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{catalog?.qa_title}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* FAQ */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Questions/réponses</Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('faq')
              }}
            >
              Ajouter
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item style={{ maxHeight: 500, overflowY: 'auto' }}>
            {catalog.faq
              .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
              .map((qa) => {
                return (
                  <Grid item key={qa.id} style={{ margin: theme.spacing(1) }}>
                    <Paper style={{ padding: theme.spacing(1), border: `1px solid ${theme.palette.divider}` }}>
                      <Grid item container justifyContent="space-between">
                        <Typography variant="body1" className={classes.title}>
                          Question{' '}
                          <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                            Dernière mise-à-jour:{' '}
                            {new Date(qa.updated_at).toLocaleString('fr-FR', {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            })}
                          </Typography>
                        </Typography>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation()
                            setAnchorEl(event.currentTarget)
                            setSelectedFaq(qa)
                          }}
                        >
                          <MdMoreVert size="20" />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">{qa.question}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className={classes.title}>
                          Réponse
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TextEditor text={qa.answer} readOnly={true} />
                      </Grid>
                    </Paper>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
      </Paper>
      <CustomModal
        open={updateModal === 'description'}
        onClose={() => {
          setUpdateModal('')
          setSelectedFaq(undefined)
        }}
        animation="slide-up"
      >
        <FaqGeneralInfoForm
          catalog={catalog}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
            setSelectedFaq(undefined)
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'faq'}
        onClose={() => {
          setUpdateModal('')
          setSelectedFaq(undefined)
        }}
        animation="slide-up"
      >
        <FaqForm
          catalog={catalog}
          catalogFaq={selectedFaq}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
            setSelectedFaq(undefined)
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title="Supprimer la question/réponse"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            const catalogInput = {
              id: catalog.id,
              faq: catalog.faq
                .filter((qa) => qa.id !== selectedFaq?.id)
                .map((qa) => {
                  return { id: qa.id, question: qa.question, answer: qa.answer }
                }),
            }

            await updateCatalog({ variables: { input: catalogInput } })
            setSelectedFaq(undefined)
            setDeleteModal(false)
            await refetch()
          }}
          onCancel={() => {
            setSelectedFaq(undefined)
            setDeleteModal(false)
          }}
        />
      </CustomModal>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedFaq(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setUpdateModal('faq')
            setAnchorEl(null)
          }}
        >
          Modifier la question/réponse
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la question/réponse
        </MenuItem>
      </Menu>
    </Grid>
  )
}

export default FAQ
