//REACT
import { useState } from 'react'
// COMPONENTS
import { QuizForm } from '../../../components/Forms/lesson'
import { QuestionForm } from '../../../components/Forms/course'
import { LessonInfo } from '../../../components/Lesson'
import { Layout, Title, Error, Loading, CustomModal, TextEditorJS } from '../../../components'
// ICON
import { MdCheckCircle } from 'react-icons/md'
// MATERIAL UI
import { Grid, Typography, Paper, useTheme, makeStyles, Button, Divider, alpha, Link, Chip } from '@material-ui/core'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
// TYPES
import { LessonType, QuizQuestionType } from '../../../../../lib/sharedTypes'
// GRAPHQL
import { useQuery, useMutation } from '@apollo/client'
import { LESSON_QUIZ_QUERY } from '../../../gql/queries'
import { REGENERATE_PDF_LESSON_QUIZ_MUTATION } from '../../../gql/mutations'
//UTILS
import { isAuthorized, isCourseManager } from '../../../utils/misc'
import { stringToColor } from '../../../utils/misc'
import { quizCategories, quizDiplays } from '../../../utils/constants'
import { useAlert } from '../../../hooks'
import { handleError } from '../../../utils/handleError'

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonQuiz = () => {
  const [setAlert] = useAlert()
  const { code_name, lesson_id } = useParams()
  const theme = useTheme()
  const classes = useStyles()
  const [updateQuizModal, setUpdateQuizModal] = useState(false)
  const [questionModal, setQuestionModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<QuizQuestionType>()
  if (!lesson_id) {
    return (
      <Layout title="Leçon quiz">
        <Error error="lesson_id inconnu" />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Leçon quiz">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  const {
    loading,
    error,
    data: { lesson } = {},
    refetch,
  } = useQuery<{
    lesson: LessonType
  }>(LESSON_QUIZ_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(lesson_id?.toString()),
        },
      },
      qq_order: {
        field: 'position',
        sort: 'ASC',
      },
    },
  })

  const [regenerateQuizPdf] = useMutation<{
    regeneratePdfLessonText: LessonType
  }>(REGENERATE_PDF_LESSON_QUIZ_MUTATION, {
    onError(e) {
      const msg = handleError(e)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: 'PDF Quiz en cours de génération' })
    },
  })

  if (loading || !lesson) {
    return (
      <Layout title="Leçon quiz">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Leçon quiz">
        <Error error={error} />
      </Layout>
    )
  }

  const questionSkills = lesson.quiz.quiz_questions
    .reduce((acc: { name: string; count: number; color: string }[], qq) => {
      const skills = qq.lesson_question.skills
      skills.map((skill) => {
        if (acc.filter((el) => el.name === skill.name).length === 0) {
          acc.push({
            name: skill.name,
            count: 1,
            color: stringToColor(skill.name),
          })
        } else {
          acc.map((el) => {
            if (el.name === skill.name) el.count += 1
          })
        }
      })
      return acc
    }, [])
    .sort((a, b) => {
      return b.count - a.count
    })

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item>
          <Title title={code_name} />
        </Grid>
        {/* LESSON */}
        <LessonInfo
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        {/* QUIZ */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Quiz</Typography>
              <Button
                color="primary"
                variant="outlined"
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                onClick={() => {
                  setUpdateQuizModal(true)
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid xs={12} item container style={{ paddingBottom: theme.spacing(2) }}>
              <Grid container item xs={12} direction="row">
                <Grid item xs={3}>
                  <Typography variant="body1" className={classes.title}>
                    Nom
                  </Typography>
                  <Typography variant="body2">{lesson.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" className={classes.title}>
                    Permettre de réessayer le quiz
                  </Typography>
                  <Typography variant="body2"> {lesson?.quiz?.allow_retry ? 'Oui' : 'Non'}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" className={classes.title}>
                    Type
                  </Typography>
                  <Typography variant="body2">{quizCategories[lesson.quiz.category].label}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" className={classes.title}>
                    Affichage
                  </Typography>
                  <Typography variant="body2">{quizDiplays[lesson.quiz.display].label}</Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ maxHeight: 150, overflow: 'scroll' }}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Répartition
                  </Typography>
                </Grid>
                <Grid item container>
                  <Grid item>
                    {questionSkills.map((questionSkill) => {
                      return (
                        <Grid
                          item
                          key={questionSkill.name}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              height: 10,
                              width: 10,
                              borderRadius: 5,
                              background: questionSkill.color,
                            }}
                          />
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              paddingLeft: theme.spacing(1),
                              paddingRight: theme.spacing(1),
                            }}
                          >
                            {questionSkill.name}:
                          </div>
                          <div style={{ fontWeight: 'bold' }}>{questionSkill.count} question(s)</div>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container spacing={1} style={{ maxHeight: 500, overflowY: 'auto', width: '100%' }}>
              {lesson.quiz.quiz_questions.map((question) => {
                if (question.lesson_question.mc_question) {
                  const correctCount = question.lesson_question.mc_question.mc_items.filter(
                    (mc_item) => mc_item.correct,
                  ).length
                  return (
                    <Grid item key={question.id} style={{ display: 'flex', width: '100%' }}>
                      <Paper
                        style={{
                          display: 'flex',
                          flexGrow: 1,
                          border: `2px solid ${theme.palette.divider}`,
                        }}
                      >
                        <Grid item container direction="row" wrap="nowrap" style={{ display: 'flex', flexGrow: 1 }}>
                          <Grid item style={{ flexGrow: 1 }}>
                            <Grid
                              item
                              container
                              justifyContent="space-between"
                              direction="row"
                              style={{ padding: theme.spacing(1) }}
                            >
                              <Grid item xs={10}>
                                <Typography
                                  component={Link}
                                  variant="caption"
                                  target="_blank"
                                  style={{ cursor: 'pointer' }}
                                  onClick={(e: React.MouseEvent) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setSelectedRow(question)
                                    setQuestionModal(true)
                                  }}
                                >
                                  Question {question.position + 1} -{' '}
                                  {correctCount > 1 ? 'choix multiples' : 'choix unique'}
                                </Typography>
                                <Typography variant="body1">
                                  {question.lesson_question.mc_question.statement}
                                </Typography>
                                {question.lesson_question?.question_video && (
                                  <video
                                    src={`${
                                      question.lesson_question?.question_video.walter_media_url
                                    }?${new Date().getTime()}`}
                                    // width="100%"
                                    height="200px"
                                    id="video"
                                    controls
                                  />
                                )}
                              </Grid>
                              <Grid item xs={2} container justifyContent="center" alignItems="center">
                                Points: {question.points}
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid container direction="column">
                              {question.lesson_question.mc_question.mc_items.map((mc_item) => {
                                return (
                                  <Grid
                                    item
                                    container
                                    key={mc_item.id}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    style={{
                                      minHeight: 50,
                                      padding: theme.spacing(1),
                                      borderLeft: mc_item.correct ? '2px solid green' : 'none',
                                      backgroundColor: mc_item.correct
                                        ? alpha(theme.palette.success.main, 0.2)
                                        : 'none',
                                    }}
                                  >
                                    <Grid item>
                                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                        {mc_item.statement}
                                      </Typography>
                                    </Grid>
                                    <Grid>
                                      <img
                                        src={mc_item?.s3_image}
                                        alt={mc_item?.s3_image?.toString()}
                                        loading="lazy"
                                        style={{
                                          maxWidth: '250px',
                                          maxHeight: '100%',
                                          objectFit: 'contain',
                                        }}
                                      />
                                    </Grid>
                                    <Grid item>
                                      {mc_item.correct && (
                                        <MdCheckCircle size="22" color={theme.palette.success.main} />
                                      )}
                                    </Grid>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                        {question.lesson_question.s3_image && (
                          <img
                            src={question.lesson_question.s3_image}
                            style={{ display: 'block', objectFit: 'cover', maxHeight: '100%', maxWidth: '20%' }}
                          />
                        )}
                      </Paper>
                    </Grid>
                  )
                }
                if (question.lesson_question.gapfill_question) {
                  return (
                    <Grid item key={question.id} style={{ display: 'flex', width: '100%' }}>
                      <Paper
                        style={{
                          display: 'flex',
                          flexGrow: 1,
                          border: `2px solid ${theme.palette.divider}`,
                        }}
                      >
                        <Grid item container direction="row" wrap="nowrap" style={{ display: 'flex', flexGrow: 1 }}>
                          <Grid item style={{ flexGrow: 1 }}>
                            <Grid
                              item
                              container
                              justifyContent="space-between"
                              direction="row"
                              style={{ padding: theme.spacing(1) }}
                            >
                              <Grid item xs={10}>
                                <Typography
                                  component={Link}
                                  variant="caption"
                                  target="_blank"
                                  style={{ cursor: 'pointer' }}
                                  onClick={(e: React.MouseEvent) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setSelectedRow(question)
                                    setQuestionModal(true)
                                  }}
                                >
                                  Question {question.position + 1} - texte à trous
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography style={{ textAlign: 'end' }}>Points: {question.points}</Typography>
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid container direction="column">
                              <TextEditorJS
                                holderId={`question_card_${question.lesson_question.gapfill_question.id}`}
                                isReadOnly={true}
                                type="gapfill"
                                legacy={question.lesson_question.gapfill_question.text}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {question.lesson_question.s3_image && (
                          <img
                            src={question.lesson_question.s3_image}
                            style={{ display: 'block', objectFit: 'cover', maxHeight: '100%', maxWidth: '20%' }}
                          />
                        )}
                      </Paper>
                    </Grid>
                  )
                }
                if (question.lesson_question.ranking_question) {
                  return (
                    <Grid item key={question.id} style={{ display: 'flex', width: '100%' }}>
                      <Paper
                        style={{
                          display: 'flex',
                          flexGrow: 1,
                          border: `2px solid ${theme.palette.divider}`,
                        }}
                      >
                        <Grid item container direction="row" wrap="nowrap" style={{ display: 'flex', flexGrow: 1 }}>
                          <Grid item style={{ flexGrow: 1 }}>
                            <Typography variant="body1">
                              {question.lesson_question.ranking_question.statement}
                            </Typography>
                            {question.lesson_question?.question_video && (
                              <video
                                src={`${
                                  question.lesson_question?.question_video.walter_media_url
                                }?${new Date().getTime()}`}
                                // width="100%"
                                height="200px"
                                id="video"
                                controls
                              />
                            )}
                            <Grid
                              item
                              container
                              justifyContent="space-between"
                              direction="row"
                              style={{ padding: theme.spacing(1) }}
                            >
                              <Grid item xs={10}>
                                <Typography
                                  component={Link}
                                  variant="caption"
                                  target="_blank"
                                  style={{ cursor: 'pointer' }}
                                  onClick={(e: React.MouseEvent) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setSelectedRow(question)
                                    setQuestionModal(true)
                                  }}
                                >
                                  Question {question.position + 1} - classement
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography style={{ textAlign: 'end' }}>Points: {question.points}</Typography>
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid container direction="column">
                              {question.lesson_question.ranking_question?.ranking_items
                                .sort((a, b) => a.correct_position - b.correct_position)
                                .map((ranking_item) => {
                                  return (
                                    <Grid
                                      item
                                      container
                                      key={ranking_item.id}
                                      alignItems="center"
                                      justifyContent="space-between"
                                      style={{
                                        minHeight: 50,
                                        padding: theme.spacing(1),
                                      }}
                                    >
                                      <Grid item>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                          <Chip
                                            size="small"
                                            label={ranking_item.correct_position}
                                            style={{
                                              minWidth: '24px',
                                              color: 'white',
                                              marginRight: theme.spacing(1),
                                              background: theme.palette.success.main,
                                            }}
                                          />
                                          {ranking_item.statement}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          variant="caption"
                                          style={{
                                            marginRight: theme.spacing(1),
                                            color: theme.palette.text.disabled,
                                          }}
                                        >
                                          Position affichée
                                        </Typography>
                                        <Chip
                                          size="small"
                                          label={ranking_item.displayed_position}
                                          color="primary"
                                          style={{
                                            color: 'white',
                                            background: theme.palette.warning.main,
                                            minWidth: '24px',
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )
                                })}
                            </Grid>
                          </Grid>
                        </Grid>
                        {question.lesson_question.s3_image && (
                          <img
                            src={question.lesson_question.s3_image}
                            style={{ display: 'block', objectFit: 'cover', maxHeight: '100%', maxWidth: '20%' }}
                          />
                        )}
                      </Paper>
                    </Grid>
                  )
                }
                return null
              })}
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Grid
                item
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
              >
                <Typography variant="h5" style={{ marginRight: 10 }}>
                  PDF
                </Typography>
                <Link href={lesson.quiz.s3_file} target="_blank">
                  Lien
                </Link>
              </Grid>
              <Grid>
                <Button
                  style={{ marginRight: theme.spacing(1) }}
                  disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                  color="secondary"
                  variant="outlined"
                  onClick={async () => {
                    await regenerateQuizPdf({ variables: { input: { id: lesson.quiz.id } } })
                  }}
                >
                  Regénerer
                </Button>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container justifyContent="center">
              {lesson.quiz.s3_file ? (
                <object data={`${lesson.quiz.s3_file}#view=FitV`} width="100%" height="500">
                  <p>{lesson.quiz.s3_file}</p>
                </object>
              ) : (
                <div>PDF pas encore généré</div>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <CustomModal
        open={updateQuizModal}
        onClose={() => {
          setUpdateQuizModal(false)
        }}
        animation="slide-up"
      >
        <QuizForm
          lesson={lesson}
          refetch={refetch}
          handleClose={() => {
            setUpdateQuizModal(false)
          }}
        />
      </CustomModal>
      <CustomModal
        open={questionModal}
        onClose={() => {
          setQuestionModal(false)
        }}
        animation="slide-up"
      >
        <QuestionForm
          handleClose={() => {
            setQuestionModal(false)
          }}
          question={selectedRow?.lesson_question}
          course={lesson.course}
          refetch={refetch}
        />
      </CustomModal>
    </Layout>
  )
}

export default LessonQuiz
