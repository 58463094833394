//REACT
import { SetStateAction, Dispatch } from 'react'
// ICONS
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { MdSettings, MdExpandMore, MdExpandLess } from 'react-icons/md'
// MATERIAL UI
import { Grid, Typography, useTheme, IconButton } from '@material-ui/core'
import { CatalogType, FundingOrganismType, ProductType, ProductCatalogFoType } from '../../../../lib/sharedTypes'
import { useState } from 'react'

type CatalogRowPropsType = {
  catalog: CatalogType
  index: number
  type: 'display' | 'edit'
  fundingOrganisms: FundingOrganismType[]
  product: ProductType
  setEditingCFO: Dispatch<SetStateAction<ProductCatalogFoType | undefined>>
  setModal: Dispatch<SetStateAction<boolean>>
  onChange: (product: ProductType) => void
}

const CatalogRow = ({
  catalog,
  index,
  product,
  type,
  fundingOrganisms,
  setEditingCFO,
  setModal,
  onChange,
}: CatalogRowPropsType) => {
  const [openAccordeon, setOpenAccordeon] = useState(false)
  const theme = useTheme()
  const isSante = catalog?.parent?.name === 'Santé'
  return (
    <>
      {isSante ? (
        <Grid
          item
          key={catalog.id}
          container
          style={{
            minHeight: 35,
          }}
        >
          <Grid item xs={2}>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: index % 2 ? theme.palette.background.paper : theme.palette.background.default,
                borderRight: `1px solid ${theme.palette.action.disabled}`,
                height: '100%',
              }}
            >
              <Typography variant="body1">{catalog.name.replace('Formations ', '')}</Typography>
              {catalog.professions.length > 1 && (
                <IconButton
                  edge="start"
                  aria-label="menu"
                  style={{ padding: 0 }}
                  onClick={() => {
                    setOpenAccordeon(!openAccordeon)
                  }}
                >
                  {!openAccordeon ? (
                    <MdExpandMore size="32" style={{ color: theme.palette.primary.main }} />
                  ) : (
                    <MdExpandLess size="32" style={{ color: theme.palette.primary.main }} />
                  )}
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={10} justifyContent="space-between">
            {fundingOrganisms?.map((fo) => {
              const catalog_fo = product.catalog_funding_organisms.find((cfo) => {
                return cfo.catalog.id === catalog.id && cfo.funding_organism.id === fo.id
              })
              const catalog_fos = product.catalog_funding_organisms.filter((cfo) => {
                return cfo.catalog.id === catalog.id && cfo.funding_organism.id === fo.id
              })
              return (
                <Grid
                  item
                  key={fo.id}
                  style={{
                    height: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRight: `1px solid ${theme.palette.action.disabled}`,
                    background: index % 2 ? theme.palette.background.paper : theme.palette.background.default,
                  }}
                  onClick={() => {
                    if (type === 'edit' && onChange) {
                      if (catalog_fo) {
                        onChange({
                          ...product,
                          catalog_funding_organisms: product.catalog_funding_organisms.filter(
                            (cfo) => cfo.id !== catalog_fo.id,
                          ),
                        })
                      } else {
                        const newCFO = {
                          id: new Date().getTime(),
                          catalog: catalog,
                          funding_organism: fo,
                        } as ProductCatalogFoType
                        onChange({
                          ...product,
                          catalog_funding_organisms: [...product.catalog_funding_organisms, newCFO],
                        })
                      }
                    }
                  }}
                >
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <AiOutlineCheckCircle
                        size={24}
                        style={{
                          cursor: type === 'edit' && !catalog_fos[0]?.profession ? 'pointer' : 'default',
                          color: !catalog_fos[0]?.profession
                            ? theme.palette.success.main
                            : theme.palette.action.disabled,
                          visibility: catalog_fos.length !== 0 ? 'visible' : 'hidden',
                          marginRight: 25,
                        }}
                      />
                      <MdSettings
                        onClick={(e) => {
                          if (type === 'edit' && !catalog_fos[0].profession) {
                            e.preventDefault()
                            e.stopPropagation()
                            if (catalog_fos[0]) {
                              setEditingCFO(catalog_fos[0])
                              setModal(true)
                            }
                          }
                        }}
                        size={24}
                        style={{
                          cursor: type === 'edit' && !catalog_fos[0]?.profession ? 'pointer' : 'default',
                          color: !catalog_fos[0]?.profession
                            ? catalog_fos[0]?.code_name ||
                              catalog_fos[0]?.listing_price ||
                              catalog_fos[0]?.minimum_selling_price ||
                              catalog_fos[0]?.duration ||
                              catalog_fos[0]?.release_date
                              ? theme.palette.primary.main
                              : theme.palette.grey[500]
                            : theme.palette.action.disabled,
                          visibility: catalog_fos.length !== 0 ? 'visible' : 'hidden',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          key={catalog.id}
          container
          style={{
            // paddingLeft: theme.spacing(1),
            minHeight: 35,
            background: index % 2 ? theme.palette.background.paper : theme.palette.background.default,
          }}
        >
          <Grid item xs={2}>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: index % 2 ? theme.palette.background.paper : theme.palette.background.default,
                borderRight: `1px solid ${theme.palette.action.disabled}`,
                height: '100%',
              }}
            >
              <Typography variant="body1">{catalog.name.replace('Formations ', '')}</Typography>
              {catalog.professions.length > 1 && (
                <IconButton
                  edge="start"
                  aria-label="menu"
                  style={{ padding: 0 }}
                  onClick={() => {
                    setOpenAccordeon(!openAccordeon)
                  }}
                >
                  {!openAccordeon ? (
                    <MdExpandMore size="32" style={{ color: theme.palette.primary.main }} />
                  ) : (
                    <MdExpandLess size="32" style={{ color: theme.palette.primary.main }} />
                  )}
                </IconButton>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={10} style={{ flexGrow: 1, display: 'flex' }}>
            {fundingOrganisms?.map((fo) => {
              const catalog_fos = product.catalog_funding_organisms.filter((cfo) => {
                return cfo.catalog.id === catalog.id && cfo.funding_organism.id === fo.id
              })
              return (
                <Grid
                  item
                  key={fo.id}
                  style={{
                    height: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRight: `1px solid ${theme.palette.action.disabled}`,
                    background: index % 2 ? theme.palette.background.paper : theme.palette.background.default,
                  }}
                  onClick={() => {
                    if (type === 'edit' && onChange) {
                      if (catalog_fos.length === 1) {
                        onChange({
                          ...product,
                          catalog_funding_organisms: product.catalog_funding_organisms.filter(
                            (cfo) => cfo.id !== catalog_fos[0].id,
                          ),
                        })
                      } else {
                        if (catalog_fos.length === 0) {
                          const newCFO = {
                            id: new Date().getTime(),
                            catalog: catalog,
                            funding_organism: fo,
                          } as ProductCatalogFoType
                          onChange({
                            ...product,
                            catalog_funding_organisms: [...product.catalog_funding_organisms, newCFO],
                          })
                        }
                      }
                    }
                  }}
                >
                  <AiOutlineCheckCircle
                    size={24}
                    style={{
                      cursor: type === 'edit' && !catalog_fos[0]?.profession ? 'pointer' : 'default',
                      color: !catalog_fos[0]?.profession ? theme.palette.success.main : theme.palette.action.disabled,
                      visibility: catalog_fos.length !== 0 ? 'visible' : 'hidden',
                      marginRight: 25,
                    }}
                  />
                  <MdSettings
                    onClick={(e) => {
                      if (type === 'edit' && !catalog_fos[0].profession) {
                        e.preventDefault()
                        e.stopPropagation()
                        if (catalog_fos[0]) {
                          setEditingCFO(catalog_fos[0])
                          setModal(true)
                        }
                      }
                    }}
                    size={24}
                    style={{
                      cursor: type === 'edit' && !catalog_fos[0]?.profession ? 'pointer' : 'default',
                      color: !catalog_fos[0]?.profession
                        ? catalog_fos[0]?.code_name ||
                          catalog_fos[0]?.listing_price ||
                          catalog_fos[0]?.minimum_selling_price ||
                          catalog_fos[0]?.duration ||
                          catalog_fos[0]?.release_date
                          ? theme.palette.primary.main
                          : theme.palette.grey[500]
                        : theme.palette.action.disabled,
                      visibility: catalog_fos.length !== 0 ? 'visible' : 'hidden',
                    }}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      )}
      <Grid container item style={{ display: openAccordeon ? 'flex' : 'none' }}>
        <Grid
          xs={2}
          item
          style={{
            background: index % 2 ? theme.palette.background.paper : theme.palette.background.default,
            paddingLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            borderRight: `1px solid ${theme.palette.action.disabled}`,
          }}
        >
          {catalog.professions.map((profession) => {
            return (
              <Grid key={profession.id} item style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                {profession.name}
              </Grid>
            )
          })}
        </Grid>
        <Grid item container xs={10} style={{ flexGrow: 1, display: 'flex' }}>
          {fundingOrganisms?.map((fo) => {
            return (
              <Grid
                item
                key={fo.id}
                style={{
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRight: `1px solid ${theme.palette.action.disabled}`,
                  background: index % 2 ? theme.palette.background.paper : theme.palette.background.default,
                }}
              >
                <Grid container direction="column" style={{ height: '100%' }}>
                  {catalog.professions.map((profession) => {
                    const catalog_fo = product.catalog_funding_organisms.find((cfo) => {
                      return (
                        cfo.catalog.id === catalog.id &&
                        cfo.funding_organism.id === fo.id &&
                        profession?.id === cfo?.profession?.id
                      )
                    })
                    return (
                      <Grid
                        item
                        key={profession.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexGrow: 1,
                        }}
                        onClick={() => {
                          if (type === 'edit' && onChange) {
                            if (catalog_fo) {
                              onChange({
                                ...product,
                                catalog_funding_organisms: product.catalog_funding_organisms.filter(
                                  (cfo) => cfo.id !== catalog_fo.id,
                                ),
                              })
                            } else {
                              const newCFO = {
                                id: new Date().getTime(),
                                catalog: catalog,
                                funding_organism: fo,
                                profession: profession,
                              } as ProductCatalogFoType
                              const filteredCFOS = product.catalog_funding_organisms.filter((cfo) => {
                                if (cfo.funding_organism.id === newCFO.funding_organism.id) {
                                  if (!cfo.profession) return false
                                  return true
                                }
                                return true
                              })
                              onChange({
                                ...product,
                                catalog_funding_organisms: [...filteredCFOS, newCFO],
                              })
                            }
                          }
                        }}
                      >
                        <AiOutlineCheckCircle
                          size={24}
                          style={{
                            cursor: type === 'edit' ? 'pointer' : 'default',
                            color: theme.palette.success.main,
                            visibility: catalog_fo ? 'visible' : 'hidden',
                            marginRight: 25,
                          }}
                        />
                        <MdSettings
                          onClick={(e) => {
                            if (type === 'edit') {
                              e.preventDefault()
                              e.stopPropagation()
                              if (catalog_fo) {
                                setEditingCFO(catalog_fo)
                                setModal(true)
                              }
                            }
                          }}
                          size={24}
                          style={{
                            cursor: type === 'edit' ? 'pointer' : 'default',
                            color:
                              catalog_fo?.code_name ||
                              catalog_fo?.listing_price ||
                              catalog_fo?.minimum_selling_price ||
                              catalog_fo?.duration ||
                              catalog_fo?.release_date
                                ? theme.palette.primary.main
                                : theme.palette.grey[500],
                            visibility: catalog_fo ? 'visible' : 'hidden',
                          }}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </>
  )
}

export default CatalogRow
