import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, MenuItem } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from '../index'
import { Error, TextEditor } from '../../index'
//TYPE
import { LessonType, SummaryStateEnum } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_LESSON_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'
import { lessonStatutMapping } from '../../../utils/constants'

type SummaryFormPropsType = {
  lesson: LessonType
  refetch: () => Promise<any>
  handleClose: () => void
}

const SummaryForm = ({ lesson, refetch, handleClose }: SummaryFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateLesson, setStateLesson] = useState<LessonType>(lesson)

  const [updateLessonMutation] = useMutation<{ updateLesson: LessonType }>(UPDATE_LESSON_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateLesson.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (key: string, value: string | boolean | object) => {
    setStateLesson((prevState) => {
      if (!prevState) return prevState
      return {
        ...prevState,
        ...{ [key]: value },
      }
    })
  }

  if (!stateLesson) {
    return <Error error={'Leçon inconnue'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const lessonInput = {
      id: stateLesson.id,
      summary_state: stateLesson.summary_state,
      summary: stateLesson.summary,
    }
    await updateLessonMutation({ variables: { input: lessonInput } })
  }

  return (
    <FormLayout
      type="update"
      title={`Mettre à jour le résumé - ${stateLesson?.name}`}
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateLesson) === JSON.stringify(lesson)}
    >
      <Grid item container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            id="summary_state"
            label="Statut résumé"
            variant="outlined"
            defaultValue={stateLesson.summary_state}
            value={stateLesson.summary_state || ''}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              },
            }}
            onChange={(event) => {
              setStateLesson((prevState) => {
                if (!prevState) return prevState
                return {
                  ...prevState,
                  ...{ summary_state: event.target.value as SummaryStateEnum },
                }
              })
            }}
            required
          >
            {Object.keys(SummaryStateEnum).map((status) => (
              <MenuItem id="statut" key={status} value={status}>
                {lessonStatutMapping[status as keyof typeof lessonStatutMapping].label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextEditor
            text={stateLesson.summary}
            onChange={(json) => {
              handleChange('summary', json)
            }}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default SummaryForm
