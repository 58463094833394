import { useMemo, useState } from 'react'
//COMPONENTS
import { Row, Weeks, WeekCourses, WeekShootings, WeekProducts, ProjectsTable } from '../components/Calendar'
import { Layout, Title, Loading } from '../components'
// MATERIAL UI
import { Autocomplete } from '@material-ui/lab'
import { Grid, Paper, TableContainer, Table, TableBody, TableHead, TextField } from '@material-ui/core'
//GQL
import { CALENDAR_USERS_QUERY, CALENDAR_PROJECTS_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'
//TYPES
import { ProjectType, RoleCategoryEnum, UserType } from '../../../lib/sharedTypes'
//UTILS
import { useParams } from 'react-router'

const Calendar = () => {
  const { previous_weeks_count } = useParams<{ previous_weeks_count: string }>()
  const [stateUsers, setStateUsers] = useState<UserType[]>()
  const [selectedUsers, setSelectedUsers] = useState<UserType[]>()
  const previousMonday = new Date(new Date().setDate(new Date().getDate() - ((new Date().getDay() + 6) % 7)))
  const startDate = new Date(
    new Date(previousMonday.getTime()).setDate(
      previousMonday.getDate() -
        (previous_weeks_count && !isNaN(parseInt(previous_weeks_count)) ? parseInt(previous_weeks_count) * 7 : 14),
    ),
  )
  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 6, startDate.getDate())

  const { data: { projects = [] } = {} } = useQuery<{ projects: ProjectType[] }>(CALENDAR_PROJECTS_QUERY)

  useQuery<{ users: UserType[] }>(CALENDAR_USERS_QUERY, {
    variables: {
      where: {
        current: { eq: true },
        roles: {
          category: {
            in: ['PROJECT', 'DIRECTOR', 'EDITOR', 'DESIGN'],
          },
        },
      },
    },
    onCompleted(data) {
      setStateUsers(data.users)
      setSelectedUsers(data.users)
    },
  })

  const managers = useMemo(() => {
    return selectedUsers?.filter((user) => {
      return user.roles.map((role) => role.category).includes(RoleCategoryEnum.PROJECT)
    })
  }, [selectedUsers])

  const directors = useMemo(() => {
    return selectedUsers?.filter((user) => {
      return user.roles.map((role) => role.category).includes(RoleCategoryEnum.DIRECTOR)
    })
  }, [selectedUsers])

  const editors = useMemo(() => {
    return selectedUsers?.filter((user) => {
      return user.roles.map((role) => role.category).includes(RoleCategoryEnum.EDITOR) && user.first_name !== 'Titouan'
    })
  }, [selectedUsers])

  const designers = useMemo(() => {
    return selectedUsers?.filter((user) => {
      return (
        user.roles.map((role) => role.category).includes(RoleCategoryEnum.DESIGN) &&
        !user.roles.map((role) => role.category).includes(RoleCategoryEnum.PROJECT)
      )
    })
  }, [selectedUsers])

  if (!stateUsers || !selectedUsers) {
    return (
      <Layout title="Calendrier">
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout title="Calendrier">
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Title title={'Calendrier'} />
        </Grid>
        <Grid item>
          <Autocomplete
            multiple
            limitTags={3}
            defaultValue={selectedUsers}
            options={stateUsers ? stateUsers : []}
            getOptionSelected={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.username || ''}
            onChange={(_, value) => {
              if (value) {
                setSelectedUsers(value)
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" label="Utilisateur(s)" />
            }}
          />
        </Grid>
        <Grid item container wrap={'nowrap'} style={{ overflow: 'auto' }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="calendar table" style={{ tableLayout: 'auto' }}>
              <TableHead>
                <Weeks startDate={startDate} endDate={endDate} />
                <WeekCourses startDate={startDate} endDate={endDate} />
                <WeekProducts startDate={startDate} endDate={endDate} />
                <WeekShootings startDate={startDate} endDate={endDate} />
              </TableHead>
              <TableBody>
                {managers?.map((manager, index) => {
                  return (
                    <Row
                      key={manager.id}
                      startDate={startDate}
                      endDate={endDate}
                      user={manager}
                      last={index === managers.length - 1}
                      projects={projects}
                    />
                  )
                })}
                {directors?.map((director, index) => {
                  return (
                    <Row
                      key={director.id}
                      startDate={startDate}
                      endDate={endDate}
                      user={director}
                      last={index === directors.length - 1}
                      projects={projects}
                    />
                  )
                })}
                {editors?.map((editor, index) => {
                  return (
                    <Row
                      key={editor.id}
                      startDate={startDate}
                      endDate={endDate}
                      user={editor}
                      last={index === editors.length - 1}
                      projects={projects}
                    />
                  )
                })}
                {designers?.map((designer, index) => {
                  return (
                    <Row
                      key={designer.id}
                      startDate={startDate}
                      endDate={endDate}
                      user={designer}
                      last={index === designers.length - 1}
                      projects={projects}
                    />
                  )
                })}
              </TableBody>
              <TableHead>
                <Weeks startDate={startDate} endDate={endDate} />
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
        <ProjectsTable />
      </Grid>
    </Layout>
  )
}

export default Calendar
