// REACT
import React, { useEffect, useState } from 'react'
// ASSETS
import NOIMG_banner from '../../assets/NOIMG_banner.jpg'
import NOIMG_card from '../../assets/NOIMG_card.jpg'
import NOIMG_logo from '../../assets/NOIMG_logo.svg'
//ICONS
import { MdOutlineCheckCircleOutline, MdHighlightOff, MdMoreVert } from 'react-icons/md'
// MATERIAL UI
import {
  Grid,
  Typography,
  Divider,
  Button,
  Paper,
  useTheme,
  makeStyles,
  Chip,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'
// COMPONENTS
import { DeleteForm } from '../../components/Forms'
import {
  GeneralInformationsForm,
  ImageForm,
  ModulesForm,
  VirtualClassRoomForm,
  WriterAssignmentForm,
} from '../../components/Forms/course'
import { Loading, Error, CustomModal, CustomTable } from '../../components'
//GQL
import { DELETE_WRITER_ASSIGNMENT_MUTATION, DELETE_VIRTUAL_CLASSROOM_MUTATION } from '../../gql/mutations'
import { COURSE_INFO_QUERY } from '../../gql/queries'
import { useQuery, useMutation } from '@apollo/client'
// TYPE
import { ColumnType } from '../../utils/types'
import { CourseType, VirtualClassRoomType, WriterAssignmentType } from '../../../../lib/sharedTypes'
//UTILS
import { isAuthorized, isCourseManager, secondsToDHMS } from '../../utils/misc'
//REACT ROUTER
import { useNavigate, useParams, useOutletContext } from 'react-router'
import { useLocation } from 'react-router'
//UTILS
import { handleError } from '../../utils/handleError'
//HOOKS
import { useAlert } from '../../hooks'

type LocationState = {
  state: {
    openModal: string
  }
}

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const GeneralInformations = () => {
  const { refetchCourse } = useOutletContext<{ refetchCourse: () => Promise<any> }>()
  const classes = useStyles()
  const navigate = useNavigate()
  const theme = useTheme()
  const location = useLocation()
  const { code_name } = useParams()
  const [updateModal, setUpdateModal] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState(false)
  const [setAlert] = useAlert()
  const [selectedRow, setSelectedRow] = useState<WriterAssignmentType | undefined>(undefined)
  const [selectedRowVCR, setSelectedRowVCR] = useState<VirtualClassRoomType | undefined>(undefined)

  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{ course: CourseType }>(COURSE_INFO_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
    async onCompleted() {
      await refetchCourse()
    },
  })

  const [deleteWriterAssignment] = useMutation<{ deleteWriterAssignment: boolean }>(DELETE_WRITER_ASSIGNMENT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Mission supprimée` })
      await refetch()
    },
  })

  const [deleteVirtualClassroom] = useMutation<{ deleteVirtualClassroom: boolean }>(DELETE_VIRTUAL_CLASSROOM_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Classe virtuelle supprimée` })
      await refetch()
    },
  })

  useEffect(() => {
    const { state } = location as LocationState
    if (state && state.openModal) {
      setUpdateModal(state.openModal)
    }
  }, [location])

  if (loading || !course) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  const virtualClassRoomscolumns: ColumnType<VirtualClassRoomType>[] = [
    {
      title: 'Nom',
      field: 'name',
      render: (row: VirtualClassRoomType) => row.name,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Description',
      field: 'description',
      render: (row: VirtualClassRoomType) => row.description,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Durée',
      field: 'duration',
      render: (row: VirtualClassRoomType) => row.duration,
      type: 'String',
      sortable: false,
    },
    {
      title: '',
      field: 'id',
      render: (row: VirtualClassRoomType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRowVCR(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  const columns: ColumnType<WriterAssignmentType>[] = [
    {
      title: 'Rédacteur',
      field: 'writer.name',
      render: (row: WriterAssignmentType) => `${row.writer.first_name} ${row.writer.last_name}`,
      type: 'String',
      sortable: false,
    },
    {
      title: 'Début',
      field: 'start',
      render: (row: WriterAssignmentType) => row.start,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Fin',
      field: 'end',
      render: (row: WriterAssignmentType) => row.end,
      type: 'Date',
      sortable: true,
    },
    {
      title: 'Actif',
      field: 'active',
      render: (row: WriterAssignmentType) => {
        if (row.active) {
          return <MdOutlineCheckCircleOutline style={{ color: theme.palette.success.main }} size="22" />
        } else {
          return <MdHighlightOff style={{ color: theme.palette.error.main }} size="22" />
        }
      },
      type: 'String',
      sortable: true,
    },
    {
      title: '',
      field: 'id',
      render: (row: WriterAssignmentType) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSelectedRow(row)
          }}
        >
          <MdMoreVert size="20" />
        </IconButton>
      ),
      type: 'CustomRender',
      sortable: false,
    },
  ]

  return (
    <Grid container direction="column">
      {/* DESCRIPTION */}
      <Paper className={classes.paper}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Description</Typography>
            <Button
              color="primary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION'])}
              onClick={() => {
                setUpdateModal('description')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item container direction="row">
            <Grid item container direction="column" xs={6}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Titre
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{course.name}</Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={6}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Nom de code
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{course.code_name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item container direction="column" xs={3}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Position
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{course.position ? course.position : 'n/a'}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={3}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Durée
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{secondsToDHMS(course.duration)}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={6}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Date de sortie
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {course.premiere_session
                  ? new Date(course.premiere_session).toLocaleString('fr-FR', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })
                  : 'n/a'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.title}>
            Managers
          </Typography>
          <Grid>
            {course?.managers.map((manager, index) => (
              <Chip
                size="small"
                key={index}
                label={manager.username}
                color="primary"
                style={{ margin: theme.spacing(1) }}
              />
            ))}
          </Grid>
        </Grid>
      </Paper>
      {/*MODULES ET CONDITIONS D'ACCES */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Modules et conditions d'accès</Typography>
            <Button
              color="primary"
              variant="outlined"
              disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
              onClick={() => {
                setUpdateModal('modules')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Modules
              </Typography>
              <Grid>
                {course?.sub_courses.map((course, index) => (
                  <Chip
                    onClick={(e: React.MouseEvent) => {
                      if (e.metaKey || e.ctrlKey) {
                        window.open(`/formations/${course.code_name}`, '_blank')
                      } else {
                        navigate(`/formations/${course.code_name}`)
                      }
                    }}
                    size="small"
                    color="primary"
                    key={index}
                    label={course.code_name}
                    style={{ margin: theme.spacing(1) }}
                  />
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Condition d’accès
              </Typography>
              <Grid>
                {course.requirements.map((requirement, index) => (
                  <Chip
                    size="small"
                    key={index}
                    label={`${requirement.requires.code_name} - ${requirement.completion_rate * 100}%`}
                    color="primary"
                    style={{ margin: theme.spacing(1) }}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* Rédacteurs */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Rédacteurs</Typography>
            <Button
              color="primary"
              variant="outlined"
              disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
              onClick={() => {
                setUpdateModal('writers')
              }}
            >
              Ajouter
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid>
            <CustomTable
              columns={columns}
              rows={course.writer_assignments}
              rowsCount={course.writer_assignments.length}
            />
          </Grid>
        </Grid>
      </Paper>
      {/*VIRTUAL CLASS ROOMS */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Classes virtuelles</Typography>
            <Button
              disabled={!(isCourseManager(course) || isAuthorized(['ADMIN', 'PRODUCTION']))}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('virtual_classroom')
              }}
            >
              Ajouter
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid>
            <CustomTable
              columns={virtualClassRoomscolumns}
              rows={course.virtual_classrooms}
              rowsCount={course.virtual_classrooms.length}
            />
          </Grid>
        </Grid>
      </Paper>
      {/* BANNER */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Bannière</Typography>
            <Button
              disabled={true}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('banner')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            <img
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                maxHeight: '350px',
                padding: theme.spacing(1),
              }}
              src={`${course.s3_banner}?${new Date().getTime()}`} //force refresh
              onError={({ currentTarget }) => {
                currentTarget.src = NOIMG_banner
              }}
              loading="lazy"
              alt="S3_picture"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* CARD */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Carte</Typography>
            <Button
              disabled={true}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('card')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            <img
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                maxHeight: '350px',
                padding: theme.spacing(1),
              }}
              src={`${course.s3_card}?${new Date().getTime()}`} //force refresh
              onError={({ currentTarget }) => {
                currentTarget.src = NOIMG_card
              }}
              loading="lazy"
              alt="S3_picture"
            />
          </Grid>
        </Grid>
      </Paper>

      {/* LOGO */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Logo</Typography>
            <Button
              disabled={true}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('logo')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            <img
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                maxHeight: '150px',
                padding: theme.spacing(1),
                background: theme.palette.grey[800],
              }}
              src={`${course.s3_logo}?${new Date().getTime()}`} //force refresh
              onError={({ currentTarget }) => {
                currentTarget.src = NOIMG_logo
              }}
              loading="lazy"
              alt="S3_picture"
            />
          </Grid>
        </Grid>
      </Paper>
      <CustomModal
        open={updateModal === 'virtual_classroom'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <VirtualClassRoomForm
          course={course}
          refetch={refetch}
          virtual_classroom={selectedRowVCR}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'description'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <GeneralInformationsForm
          course={course}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'modules'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ModulesForm
          course={course}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'banner'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ImageForm
          type="banner"
          course={course}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'card'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ImageForm
          type="card"
          course={course}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'logo'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ImageForm
          type="logo"
          course={course}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'writers'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <WriterAssignmentForm
          course={course}
          writer_assignment={selectedRow}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        size="xs"
        title={`Supprimer la ${selectedRow ? 'mission' : 'classe virtuelle'}`}
        open={deleteModal}
        onClose={() => {
          setDeleteModal(true)
          setAnchorEl(null)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            if (selectedRow) {
              await deleteWriterAssignment({
                variables: { input: { id: selectedRow?.id } },
              })
            } else {
              await deleteVirtualClassroom({
                variables: { input: { id: selectedRowVCR?.id } },
              })
            }

            setDeleteModal(false)
            setAnchorEl(null)
            await refetch()
          }}
          onCancel={() => {
            setDeleteModal(false)
            setAnchorEl(null)
          }}
        />
      </CustomModal>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
          setSelectedRow(undefined)
          setSelectedRowVCR(undefined)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            if (selectedRow) {
              setUpdateModal('writers')
            } else {
              setUpdateModal('virtual_classroom')
            }

            setAnchorEl(null)
          }}
        >
          Modifier la {selectedRow ? 'mission' : 'classe virtuelle'}
        </MenuItem>
        <MenuItem
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la {selectedRow ? 'mission' : 'classe virtuelle'}
        </MenuItem>
      </Menu>
    </Grid>
  )
}

export default GeneralInformations
