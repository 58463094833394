import { useState } from 'react'
// MATERIAL UI
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
  makeStyles,
  useTheme,
  CircularProgress,
  Link,
} from '@material-ui/core'
//ICONS
import { ImVimeo2 } from 'react-icons/im'
//COMPONENTS
import { ColorForm, TeaserForm, ImageForm } from '../../components/Forms/product'
import { Loading, Error, CustomModal } from '../../components'
// ASSETS
import NOIMG_banner from '../../assets/NOIMG_banner.jpg'
import NOIMG_card from '../../assets/NOIMG_card.jpg'
import NOIMG_logo from '../../assets/NOIMG_logo.svg'
// TYPE
import { ProductType } from '../../../../lib/sharedTypes'
//GQL
import { PRODUCT_VISUALS_QUERY } from '../../gql/queries'
import { UPLOAD_PRODUCT_TEASER_TO_VIMEO, UPDATE_PROGRAM } from '../../gql/mutations'
import { useQuery, useMutation } from '@apollo/client'
//REACT ROUTER
import { useParams, useOutletContext } from 'react-router'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { isAuthorized } from '../../utils/misc'
import { handleError } from '../../utils/handleError'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const VisualManagement = () => {
  const { refetchProduct } = useOutletContext<{ refetchProduct: () => Promise<any> }>()
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const classes = useStyles()
  const theme = useTheme()
  const [updateModal, setUpdateModal] = useState('')

  const {
    loading,
    error,
    data: { product } = {},
    refetch,
  } = useQuery<{
    product: ProductType
  }>(PRODUCT_VISUALS_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
    async onCompleted() {
      await refetchProduct()
    },
  })

  const [uploadProductTeaserToVimeo, { loading: vimeoUploadLoading }] = useMutation<{
    uploadProductTeaserToVimeo: ProductType
  }>(UPLOAD_PRODUCT_TEASER_TO_VIMEO, {
    onError(e) {
      const msg = handleError(e)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      product?.teaser_vimeo_lastsync
        ? setAlert({ severity: 'success', content: 'Bande-annonce remplacée sur Viméo' })
        : setAlert({ severity: 'success', content: 'Bande-annonce mise-en-ligne sur Viméo' })
      await refetch()
    },
  })

  const [updateProgramMutation] = useMutation<{
    updateProgram: ProductType
  }>(UPDATE_PROGRAM, {
    onError(e) {
      const msg = handleError(e)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted() {
      setAlert({ severity: 'success', content: 'Programme PDF en cours de génération' })
    },
  })

  if (loading || !product) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid item container direction="column" style={{ width: '100%' }}>
      {/* TEASER */}
      <Paper className={classes.paper} style={{ width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Bande-annonce</Typography>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                      {product && product.teaser_vimeo_lastsync
                        ? `Dernière publication: ${new Date(product.teaser_vimeo_lastsync).toLocaleString()}`
                        : 'Non publié'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {product.teaser_vimeo_url && (
                      <Link target="_blank" href={product.teaser_vimeo_url} style={{ cursor: 'pointer' }}>
                        Consulter sur Viméo
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    style={{ width: 140 }}
                    variant="outlined"
                    color="primary"
                    startIcon={<ImVimeo2 />}
                    disabled={
                      product.teaser_review_state !== 'VALIDATED' || !isAuthorized(['ADMIN', 'PRODUCTION', 'DESIGN'])
                    }
                    onClick={async () => {
                      await uploadProductTeaserToVimeo({ variables: { input: { id: product.id } } })
                    }}
                  >
                    {vimeoUploadLoading ? (
                      <CircularProgress variant="indeterminate" size="1.5rem" color="primary" id="upload_progress" />
                    ) : product.teaser_vimeo_url ? (
                      'Republier'
                    ) : (
                      'Publier'
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={!isAuthorized(['ADMIN', 'DESIGN'])}
                    onClick={() => {
                      setUpdateModal('teaser')
                    }}
                  >
                    Modifier
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            <video width="100%" height="360">
              <source src={`${product?.teaser_walter_media_url}?${new Date().getTime()}`} />
            </video>
          </Grid>
        </Grid>
      </Paper>
      {/* BANNER */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Bannière</Typography>
            {!product.s3_banner ? <Alert severity="warning">Requis pour publier la fiche produit</Alert> : null}
            <Button
              color="primary"
              disabled={!isAuthorized(['ADMIN', 'DESIGN'])}
              variant="outlined"
              onClick={() => {
                setUpdateModal('banner')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            <img
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                maxHeight: '350px',
                padding: theme.spacing(1),
              }}
              src={`${product.s3_banner}?${new Date().getTime()}`} //force refresh
              onError={({ currentTarget }) => {
                currentTarget.src = NOIMG_banner
              }}
              loading="lazy"
              alt="S3_picture"
            />
          </Grid>
        </Grid>
      </Paper>

      {/* CARD */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Carte</Typography>
            {!product.s3_card ? <Alert severity="warning">Requis pour publier la fiche produit</Alert> : null}
            <Button
              color="primary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'DESIGN'])}
              onClick={() => {
                setUpdateModal('card')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            <img
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                maxHeight: '350px',
                padding: theme.spacing(1),
              }}
              src={`${product.s3_card}?${new Date().getTime()}`} //force refresh
              onError={({ currentTarget }) => {
                currentTarget.src = NOIMG_card
              }}
              loading="lazy"
              alt="S3_picture"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* LOGO */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Logo</Typography>
            {!product.s3_logo ? <Alert severity="warning">Requis pour publier la fiche produit</Alert> : null}
            <Button
              color="primary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'DESIGN'])}
              onClick={() => {
                setUpdateModal('logo')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            <img
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                maxHeight: '150px',
                padding: theme.spacing(1),
                background: theme.palette.grey[800],
              }}
              src={`${product.s3_logo}?${new Date().getTime()}`} //force refresh
              onError={({ currentTarget }) => {
                currentTarget.src = NOIMG_logo
              }}
              loading="lazy"
              alt="S3_picture"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* COLOR */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Couleur</Typography>
            <Button
              color="primary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'DESIGN'])}
              onClick={() => {
                setUpdateModal('color')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item container>
              <Grid item style={{ width: '100%', height: 250, backgroundColor: product.color }}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* PROGRAM */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Programme</Typography>
            <Button
              color="primary"
              variant="outlined"
              disabled={!isAuthorized(['ADMIN', 'DESIGN', 'PRODUCTION', 'SEO'])}
              onClick={async () => {
                await updateProgramMutation({ variables: { input: { id: product.id } } })
              }}
            >
              Mettre à jour
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center">
            {product.s3_pdf && (
              <object data={`${product.s3_pdf}?update=${new Date().getTime()}#view=FitV`} width="100%" height="500">
                <p>{product.s3_pdf}</p>
              </object>
            )}
          </Grid>
        </Grid>
      </Paper>
      <CustomModal
        open={updateModal === 'teaser'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <TeaserForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'banner'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ImageForm
          type="banner"
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'card'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ImageForm
          type="card"
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'logo'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ImageForm
          type="logo"
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'color'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ColorForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default VisualManagement
