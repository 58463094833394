/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useRef, useEffect, useState } from 'react'
import EditorJS from '@editorjs/editorjs'
import { EDITOR_JS_TOOLS } from './Forms/lesson/text/tools/EditorTools'
import { useTheme } from '@material-ui/core/styles'
import GapFill from '../components/Forms/lesson/text/tools/Gapfill'

type BlockType = {
  id: string
  type: string
  data: { text: string; level?: number }
  tunes: { textVariant: string }
}

type TextEditorJSProps = {
  type?: string
  content?: any
  onChange?: (content: any) => void
  isReadOnly?: boolean
  holderId?: string
  legacy?: string
  lessonBlocks?: BlockType[]
}

function TextEditorJS({
  type,
  content,
  onChange,
  isReadOnly = false,
  holderId = 'editorjs',
  legacy = '',
  lessonBlocks,
}: TextEditorJSProps) {
  const theme = useTheme()
  const [usedLessonBlocks, setUsedLessonBlock] = useState<BlockType[]>([])
  const ejInstance = useRef<EditorJS | null>()

  const initEditor = () => {
    const editor = new EditorJS({
      holder: holderId,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: content,
      onReady: async () => {
        ejInstance.current = editor
        if (legacy) {
          await ejInstance.current.blocks.renderFromHTML(legacy)
        }
      },
      onChange: async () => {
        if (!ejInstance.current || isReadOnly) return
        const content = await ejInstance.current.save()
        if (onChange) onChange(content)
      },
      inlineToolbar: type === 'gapfill' ? ['gapfill'] : ['link', 'bold', 'italic'],
      autofocus: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tools:
        type === 'gapfill'
          ? {
              gapfill: {
                class: GapFill,
                config: {
                  holder: holderId,
                  location: 'left',
                  color: 'white',
                  highlightColor: '#3E83F5',
                  // underline: true,
                  backgroundColor: '#3E83F5',
                  // textColor: '#FDFEFE',
                },
              },
            }
          : EDITOR_JS_TOOLS,
      tunes: type === 'gapfill' ? [] : ['textVariant'],
      readOnly: isReadOnly,
    })
  }

  useEffect(() => {
    if (ejInstance.current && lessonBlocks?.length !== 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      lessonBlocks?.map((block, index) => {
        if (!usedLessonBlocks?.map((block) => block.id).includes(block.id)) {
          ejInstance.current?.blocks.insert(block.type, block.data, {}, index)
          const newState = usedLessonBlocks
          newState.push(block)
          setUsedLessonBlock(newState)
        }
      })
    }
    const newState = usedLessonBlocks.filter((block) => {
      return lessonBlocks?.map((b) => b.id).includes(block.id)
    })
    setUsedLessonBlock(newState)
  }, [lessonBlocks])

  useEffect(() => {
    if (!ejInstance.current) {
      initEditor()
    }
    return () => {
      if (ejInstance.current) {
        ejInstance.current.destroy()
        ejInstance.current = null
      }
    }
  }, [])

  return <div id={holderId} className={`${theme.palette.type} editorjs ${type ? type : 'default'}`}></div>
}

export default TextEditorJS
