import { useState } from 'react'
//COMPONENTS
import { CourseForm } from '../components/Forms'
import { CustomModal, Layout, Title, SearchBar, CustomTable, Loading, Error } from '../components'
//GQL
import { COURSES_QUERY, COURSES_SEARCH_QUERY } from '../gql/queries'
import { useQuery, useLazyQuery } from '@apollo/client'
// MATERIAL UI
import { Grid } from '@material-ui/core'
//TYPES
import { ColumnType } from '../utils/types'
import { CourseType, SortEnum } from '../../../lib/sharedTypes'
import { useNavigate } from 'react-router'
import { Outlet } from 'react-router-dom'
// //UTILS
// import { isAuthorized } from '../utils/misc'

const columns: ColumnType<CourseType>[] = [
  {
    title: '',
    field: 's3_card',
    render: (row: CourseType) => row.s3_card,
    type: 'Image',
    sortable: false,
  },
  {
    title: 'Code Name',
    field: 'code_name',
    render: (row: CourseType) => row.code_name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Name',
    field: 'name',
    render: (row: CourseType) => row.name,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Modules',
    field: 'sub_courses_count',
    render: (row: CourseType) => row.sub_courses_count,
    type: 'String',
    sortable: true,
  },
  {
    title: 'Crée le',
    field: 'created_at',
    render: (row: CourseType) => row.created_at,
    type: 'Date',
    sortable: true,
  },
]

const Courses = () => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [field, setField] = useState<keyof CourseType>('created_at')
  const [sort, setSort] = useState<keyof typeof SortEnum>(SortEnum.DESC)
  const [skip, setSkip] = useState<number>(0)
  const [take, setTake] = useState<number>(10)
  const [searching, setSearching] = useState<boolean>(false)
  const {
    loading,
    error,
    data: { courses = [], coursesCount = 0 } = {},
    refetch,
  } = useQuery<{
    courses: CourseType[]
    coursesCount: number
  }>(COURSES_QUERY, {
    variables: {
      where: {
        thematic: {
          id: {
            eq: null,
          },
        },
        product: {
          is_apprenticeship: {
            eq: false,
          },
        },
      },
      order: {
        field: field,
        sort: sort,
      },
      skip: skip,
      take: take,
    },
  })
  const [searchFormationsQuery, { error: searchError, data: { coursesSearch = [] } = {} }] = useLazyQuery<{
    coursesSearch: CourseType[]
  }>(COURSES_SEARCH_QUERY, {
    variables: {
      where: {
        thematic: {
          id: {
            eq: null,
          },
        },
        product: {
          is_apprenticeship: {
            eq: false,
          },
        },
      },
    },
  })

  const searchFormations = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length > 0) {
      setSearching(true)
    } else {
      setSearching(false)
    }
    await searchFormationsQuery({
      variables: {
        searchQuery: searchQuery,
        order: {
          field: field,
          sort: sort,
        },
        take: take,
      },
    })
  }

  if (loading) {
    return (
      <Layout title="Formations">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Formations">
        <Error error={error} />
      </Layout>
    )
  }

  if (searchError) {
    return (
      <Layout title="Formations">
        <Error error={searchError} />
      </Layout>
    )
  }

  return (
    <Layout title="Formations">
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={'Formations'} />
          </Grid>
          {/* <Grid item>
            <Button
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'PROJECT'])}
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenModal(true)
              }}
            >
              Ajouter une formation
            </Button>
          </Grid> */}
        </Grid>
        <Grid item>
          <SearchBar placeholder="Rechercher une formation" searchQuery={searchFormations} />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CustomTable<CourseType>
            columns={columns}
            rows={searching ? coursesSearch : courses}
            field={field}
            skip={skip}
            sort={sort}
            take={take}
            setField={setField}
            setSort={setSort}
            setTake={setTake}
            setSkip={setSkip}
            rowsCount={searching ? coursesSearch.length : coursesCount}
            onRowClick={(row: CourseType, e) => {
              if (e.metaKey || e.ctrlKey) {
                window.open(`formations/${row.code_name}`, '_blank')
              } else {
                navigate(row.code_name)
              }
            }}
          />
        </Grid>
      </Grid>
      <CustomModal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        animation="slide-up"
      >
        <CourseForm course={undefined} refetch={refetch} handleClose={() => setOpenModal(false)} />
      </CustomModal>
      <Outlet />
    </Layout>
  )
}

export default Courses
