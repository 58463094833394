import { useState } from 'react'
// MATERIAL UI
import { Divider, Grid, IconButton, TextField, Typography, useTheme } from '@material-ui/core'
//ICONS
import { MdOutlineAddCircleOutline, MdOutlineDelete } from 'react-icons/md'
// COMPONENTS
import { Error } from '../../index'
import { FormLayout } from '../index'
// TYPE
import { ProductType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'
//UTILS
import { ProductJobOpportunity } from 'src/utils/types'
import { handleError } from '../../../utils/handleError'

type OpportunityFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const OpportunityForm = ({ product, refetch, handleClose }: OpportunityFormPropsType) => {
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: stateProduct.id,
      job_opportunities: stateProduct.job_opportunities,
    }
    await updateProductMutation({ variables: { input: productInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les débouchés"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProduct) === JSON.stringify(product)}
    >
      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h6">Débouchés</Typography>
        </Grid>
        <IconButton
          onClick={() => {
            const job_opportunities = (stateProduct.job_opportunities as ProductJobOpportunity[]) ?? []
            job_opportunities.push({
              id: new Date().getTime(),
              name: '',
              description: '',
            } as ProductJobOpportunity)

            setStateProduct((prevState) => {
              return {
                ...prevState,
                job_opportunities: job_opportunities,
              }
            })
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Grid>
      <Grid item container direction="column" spacing={2} ref={animateRef}>
        {(stateProduct.job_opportunities as ProductJobOpportunity[])?.map((opportunity, index) => {
          return (
            <Grid key={index} item container direction="column">
              <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>{`Débouché #${index + 1}`}</Typography>
                </Grid>

                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setStateProduct((prevState) => {
                        return {
                          ...prevState,
                          job_opportunities: (prevState.job_opportunities as ProductJobOpportunity[]).filter((job) => {
                            return job.id !== opportunity.id
                          }),
                        }
                      })
                    }}
                  >
                    <MdOutlineDelete size={24} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid style={{ marginBottom: theme.spacing(2) }}>
                <Divider />
              </Grid>
              <Grid item container direction="row" spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label={`Nom`}
                    name="name"
                    value={opportunity.name}
                    onChange={(e) => {
                      e.preventDefault()

                      const job_opportunities = (stateProduct.job_opportunities as ProductJobOpportunity[]).map(
                        (job, i) => {
                          if (i === index) {
                            return {
                              ...job,
                              name: e.target.value,
                            }
                          } else {
                            return job
                          }
                        },
                      )

                      setStateProduct((prevState) => {
                        return {
                          ...prevState,
                          job_opportunities: job_opportunities,
                        }
                      })
                    }}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={5}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Description"
                    name="description"
                    value={opportunity.description}
                    onChange={(e) => {
                      e.preventDefault()
                      const job_opportunities = (stateProduct.job_opportunities as ProductJobOpportunity[]).map(
                        (job, i) => {
                          if (i === index) {
                            return {
                              ...job,
                              description: e.target.value,
                            }
                          } else {
                            return job
                          }
                        },
                      )

                      setStateProduct((prevState) => {
                        return {
                          ...prevState,
                          job_opportunities: job_opportunities,
                        }
                      })
                    }}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={5}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </FormLayout>
  )
}

export default OpportunityForm
