import { forwardRef } from 'react'
//MATERIAL UI
import {
  alpha,
  useTheme,
  Dialog,
  Slide,
  AppBar,
  DialogContent,
  Toolbar,
  IconButton,
  useMediaQuery,
  DialogTitle,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
// ICONS
import { IoMdClose } from 'react-icons/io'

type AnimationType = 'slide-up' | undefined

type CustomModalProps = {
  open: boolean
  onClose: (arg: any) => void
  animation?: AnimationType
  children: React.ReactNode
  title?: string
  size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs'
}

const Transition = forwardRef<unknown, TransitionProps>((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const CustomModal = ({ open, onClose, animation, children, title, size = 'md' }: CustomModalProps) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const displayChildren = (animation: AnimationType) => {
    switch (animation) {
      case undefined:
        return (
          <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            maxWidth={size}
            open={open}
            onClose={onClose}
            // onClick={(event) => {
            //   event.preventDefault()
            //   event.stopPropagation()
            // }}
            // style={{ zIndex: 10000 }}
          >
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent dividers>{children}</DialogContent>
          </Dialog>
        )
      case 'slide-up':
        return (
          <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
              },
            }}
          >
            <AppBar style={{ position: 'relative', backgroundColor: alpha(theme.palette.common.black, 0.8) }}>
              <Toolbar style={{ backgroundColor: alpha(theme.palette.background.default, 0.2) }}>
                <IconButton onClick={onClose} style={{ position: 'absolute', right: '10px' }}>
                  <IoMdClose size="22" style={{ color: 'white' }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            {children}
          </Dialog>
        )
      default:
        console.error(`Unknown animation`)
        return <></>
    }
  }

  return displayChildren(animation)
}

export default CustomModal
