import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField } from '@material-ui/core'
// COMPONENTS
import { TextEditor } from '../../index'
import { FormLayout } from '../index'
// TYPE
import { CatalogType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_CATALOG_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type FundingFormPropsType = {
  catalog: CatalogType
  refetch: () => Promise<any>
  handleClose: () => void
}

const FundingForm = ({ catalog, refetch, handleClose }: FundingFormPropsType) => {
  const [setAlert] = useAlert()
  const [stateCatalog, setStateCatalog] = useState<CatalogType>(catalog)
  const [updateCatalog] = useMutation<{ updateCatalog: CatalogType }>(UPDATE_CATALOG_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateCatalog.name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCatalog((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const catalogInput = {
      id: stateCatalog.id,
      funding_subtitle: stateCatalog.funding_subtitle,
      andpc_compensation_example_amount: stateCatalog.andpc_compensation_example_amount
        ? parseFloat(stateCatalog.andpc_compensation_example_amount.toString())
        : null,
      andpc_compensation_example_description: stateCatalog.andpc_compensation_example_description,
      funding_text: stateCatalog.funding_text,
    }
    await updateCatalog({ variables: { input: catalogInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les informations de financement"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateCatalog) === JSON.stringify(catalog)}
    >
      <Grid item>
        <TextField
          fullWidth
          name="funding_subtitle"
          label="Sous-titre"
          variant="outlined"
          value={stateCatalog?.funding_subtitle || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          type="number"
          name="andpc_compensation_example_amount"
          label="Montant example"
          variant="outlined"
          value={stateCatalog?.andpc_compensation_example_amount || 0}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="andpc_compensation_example_description"
          label="Montant example description"
          variant="outlined"
          value={stateCatalog?.andpc_compensation_example_description || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextEditor
          text={stateCatalog.funding_text}
          onChange={(json) => {
            setStateCatalog((prevState) => {
              return {
                ...prevState,
                funding_text: json,
              }
            })
          }}
        />
      </Grid>
    </FormLayout>
  )
}

export default FundingForm
