//REACT
import { useEffect, useRef, useState } from 'react'
//MATERIAL UI
import { Link } from '@material-ui/core'
//QUILL
import ImageResize from 'quill-image-resize-module-react'
import { useQuill } from 'react-quilljs'
import Parchment from 'parchment'
import './TextEditor.css'
import 'quill/dist/quill.snow.css'
import { useTheme } from '@material-ui/core'
//FORMULAS
import katex from 'katex'
import 'katex/dist/katex.min.css'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.katex = katex

type TextEditorPropsType = {
  type?: string
  placeholder?: string
  text: object | undefined
  readOnly?: boolean
  onChange?: (json: object) => void
}

const tools = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ align: [] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
  [{ background: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['link', 'image', 'formula'],
]

const formatsQuill = [
  'header',
  'formula',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'align',
  'background',
  'code-block',
]

const TextEditor = ({ type, placeholder, text, readOnly = false, onChange }: TextEditorPropsType) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const [maxWidth, setMaxWidth] = useState<number>(0)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { quill, quillRef, Quill } = useQuill({
    placeholder: placeholder,
    modules: {
      toolbar: readOnly ? false : type === 'dactylo' ? ['bold', 'italic', 'underline'] : tools,
      imageResize: readOnly
        ? false
        : {
            parchment: Parchment,
            modules: ['Resize', 'DisplaySize'],
          },
    },
    formats: formatsQuill,
    readOnly: readOnly,
    theme: 'snow',
  })

  if (Quill && !quill) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    Quill.register('modules/imageResize', ImageResize)
  }

  useEffect(() => {
    if (quill) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      quill?.setContents(text)
      quill.on('text-change', () => {
        const jsonContent = quill.getContents()
        if (onChange) {
          onChange(jsonContent)
        }
      })
    }
  }, [quill, Quill])

  useEffect(() => {
    if (ref.current) {
      if (maxWidth === 0) {
        setMaxWidth(ref.current.offsetWidth)
      }
    }
  }, [ref])

  return (
    <div ref={ref} className={theme.palette.type}>
      <div
        className={readOnly ? `textEditorReadOnly` : `textEditor`}
        style={{ maxWidth: maxWidth, height: '100%', color: 'unset' }}
        ref={quillRef}
      />
      {!readOnly && type !== 'dactylo' && (
        <Link href="https://katex.org/docs/supported.html" target="_blank">
          &#9432; KaTex documentation
        </Link>
      )}
    </div>
  )
}

export default TextEditor
